import styled from "styled-components";
import TextField from "@mui/material/TextField"
import TableContainer from "@mui/material/TableContainer"
import {TableContainerLayout} from "../../components/TableContainerLayout";

export const Container = styled.div`
  margin: 30px 20px;
  height: 100%;
`;

export const UpperFilterContainer = styled.div`
  margin-top: 10px;
`;

export const FormControlTooltipWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: baseline;
  margin-right: 10px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  line-height: 32px;
  margin-bottom: 10px;
`;
export const Subtitle = styled.p`
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
`;

export const ContainerFilter = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  margin: 10px 0 10px 0;
`;

export const TitleContainerInputs = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;

  color: #131212;
`;

export const ContainerInputs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.75rem;

  /* @media (min-width: 1000px) {
    width: 14rem;
    height: fit-content;
  } */
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  max-height: 42px;
  gap: 1rem;

  button:nth-of-type(2) {
    @media (max-width: 348px) {
      width: 6.25rem !important;
    }
  }
`;

export const FontHeaderTable = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #131212;
  cursor: pointer;
`;

export const StyledTableContainerLayout = styled.div`
  width: 80%;
  margin: auto;
  background: white;
  @media (max-width: 1680px) {
    width: 100%;
  }
`;
export const ContainerSelectInput = styled.div`
  width: calc(20% - 15px);
  @media (max-width: 1450px) {
    width: calc(25% - 12px);
  }
  @media (max-width: 1250px) {
    width: calc(33% - 9px);
  }
  @media (max-width: 900px) {
    width: calc(50% - 6px);
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const AnchorName = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  //text-decoration-line: underline;
  //color: #ff026f;
`;

export const RegularRow = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #131212;
`;

export const DateRow = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const MaskOfPercentageRow = styled.p`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 0.5rem;

  progress[value] {
    height: 7px;
    width: 53px;

    ::-webkit-progress-bar {
      height: 7px;
    }
    ::-webkit-progress-value {
      height: 7px;
    }
  }
`;

export const MaskOfFontHeaderTable = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const Label = styled.p`
  margin-bottom: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const CustomTextField = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    background: ${({ disabled,  }) => disabled ? "transparent" : "#ffffff"};
  };
  .MuiAutocomplete-endAdornment {
    background: ${({ disabled }) => disabled ? "transparent" : "#ffffff"};
  }
`;