/* eslint-disable no-new */
import { Checkbox } from "@mui/material";
import { t } from "i18next";
import MoneyInputOrange from "../../../components/MoneyInputOrange";
import { SelectOrange } from "../../../components/SelectOrange";
import { IFiltersHome } from "../index";
import * as S from "./styles";

export default function Positions({
  filters,
  setFilters,
  mobile,
  possibleFilters,
  advancedFilters,
}: {
  filters: IFiltersHome;
  setFilters: any;
  mobile: any;
  possibleFilters: any;
  advancedFilters?: boolean;
}) {
  const showPositionFilter = mobile || !filters.headerPositionSelected;

  return (
    <S.ContainerDropdowns>
      <S.ContainerDropdownsCenter>
        <S.ContainerDropdownsMain style={{ alignItems: "flex-end" }}>
          {!mobile && (
            <>
              {!filters.companySelected && (
                <SelectOrange
                  style={{ minWidth: mobile ? "177px" : "220px", width: "calc((100% / 6) - 16px)" }}
                  placeholder={t("home.todasEmpresas")}
                  options={possibleFilters?.companies || []}
                  selected={filters?.positionCompanySelectedPosition}
                  onSelect={(e) => {
                    setFilters({ ...filters, positionCompanySelectedPosition: e });
                  }}
                  onChange={(e) => { e }}
                  listAbsolute
                />
              )}
              {showPositionFilter && (
                <SelectOrange
                  style={{ minWidth: mobile ? "177px" : "220px", width: "calc((100% / 6) - 16px)" }}
                  placeholder={t("home.todosCargos")}
                  options={possibleFilters?.formations || []}
                  selected={filters?.positionPositionSelected}
                  onSelect={(e) => {
                    setFilters({ ...filters, positionPositionSelected: e });
                  }}
                  onChange={(e) => { e }}
                  listAbsolute
                />
              )}

              <SelectOrange
                style={{ minWidth: mobile ? "177px" : "220px", width: "calc((100% / 6) - 16px)" }}
                placeholder={t("home.todasModalidades")}
                options={
                  possibleFilters?.modalities?.map((m) => ({
                    value: m.value, name: t(m.name),
                  })) || []
                }
                selected={filters?.positionModality}
                onSelect={(e) => {
                  setFilters({ ...filters, positionModality: e });
                }}
                onChange={(e) => { e }}
                listAbsolute
              />
              <MoneyInputOrange
                style={{ minWidth: "170px", width: "calc((100% / 6) - 56px)" }}
                label={t("positionsManagement.startMoney")}
                value={filters.positionMinSalary} onChange={(e) => {
                  setFilters({ ...filters, positionMinSalary: e });
                }}
              /><MoneyInputOrange label={t("positionsManagement.endMoney")}
                style={{ minWidth: "170px", width: "calc((100% / 6) - 56px)" }}
                value={filters.positionMaxSalary} onChange={(e) => {
                  setFilters({ ...filters, positionMaxSalary: e });
                }}
              />
              <span style={{
                minWidth: "200px",
                width: "calc((100% / 6) - 16px)",
                display: "flex",
              }}
              >
                <Checkbox
                  checked={filters.positionGetNullSalary}
                  value={filters.positionGetNullSalary}
                  onChange={(e) => {
                    setFilters({
                      ...filters, positionGetNullSalary: e.target.checked,
                    });
                  }}
                /><div style={{ maxWidth: "155px" }}>{t("includeNullValueTranslation")}</div>
              </span>
            </>
          )}

          {mobile && !advancedFilters && (
            <div style={{ marginLeft: "10px", display: "flex", flexDirection: 'row', maxWidth: "100%" }}>
              {!filters.companySelected && (
                <SelectOrange
                  style={{ minWidth: mobile ? "130px" : "220px", width: "calc((100% / 6) - 16px)", marginRight: "10px" }}
                  placeholder={t("home.companies")}
                  options={possibleFilters?.companies || []}
                  selected={filters?.positionCompanySelectedPosition}
                  onSelect={(e) => {
                    setFilters({ ...filters, positionCompanySelectedPosition: e });
                  }}
                  onChange={(e) => { e }}
                  listAbsolute
                />
              )}
              {showPositionFilter && (
                <SelectOrange
                  style={{ minWidth: mobile ? "130px" : "220px", width: "calc((100% / 6) - 16px)", marginRight: "10px" }}
                  placeholder={t("positions.position")}
                  options={possibleFilters?.formations || []}
                  selected={filters?.positionPositionSelected}
                  onSelect={(e) => {
                    setFilters({ ...filters, positionPositionSelected: e });
                  }}
                  onChange={(e) => { e }}
                  listAbsolute
                />
              )}
              <SelectOrange
                style={{ minWidth: mobile ? "155px" : "220px", width: "calc((100% / 6) - 16px)", marginRight: "10px" }}
                placeholder={t("home.modalities")}
                options={
                  possibleFilters?.modalities?.map((m: { value: any; name: any; }) => ({
                    value: m.value, name: t(m.name),
                  })) || []
                }
                selected={filters?.positionModality}
                onSelect={(e) => {
                  setFilters({ ...filters, positionModality: e });
                }}
                onChange={(e) => { e }}
                listAbsolute
              />
            </div>
          )}
        </S.ContainerDropdownsMain>
      </S.ContainerDropdownsCenter>
    </S.ContainerDropdowns>
  )
}
