import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Button } from "../../../components/Button";
import approved from "../../../assets/approved.png";
import * as S from "../style";
import { t } from "i18next";

interface IFormData {
  onClose: () => void;
  openModal: boolean;
}

const Form = ({ onClose, openModal }: IFormData) => {
  const [formData, setFormData] = useState({ code: "" });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.FullModalEmailVerify>
        <S.imageApproved src={approved} />
        <S.ModalTitleEmailVerify>
          {t("passwordResetSuccessModal.reset")}
        </S.ModalTitleEmailVerify>
        <S.ModalSubtitle>
          {t("passwordResetSuccessModal.updated")}
        </S.ModalSubtitle>

        <S.ContainerButtons>
          <S.buttonPink
            onClick={() => {
              handleClose();
            }}
          >
            {t("passwordResetSuccessModal.continue")}
          </S.buttonPink>
        </S.ContainerButtons>
      </S.FullModalEmailVerify>
    </Modal>
  );
};

export default Form;
