import api from "./api";

class ConfigurationsService {

  async getConfigurations(id :number) {
    const response = await api.get(
      `companies/settings/${id}`
    );
    return response.data;
  }

  async putConfigurations(id: string, ConfigurationDTO: any) {
    await api.put(`companies/settings/${id}`, ConfigurationDTO);
  }
}

export default new ConfigurationsService();