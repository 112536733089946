import styled from "styled-components";

export const ContainerInput = styled.div`
  margin-bottom: 2rem;
`;

export const Label = styled.p`
  width: 100%;
  //margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
  gap: 5px;
`;

export const ContainterInputsCreateNewUser = styled.div`
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const ContainterInputsEditUser = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ContainerGap = styled.div`
  padding-top: 12px;
`;

export const ContainerGapDistrict = styled.div`
  padding-top: 12px;
`;

export const InputRow = styled.div<{rowsSize: number[]}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 26px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 10px;
  }
`;

export const InputRowRegister = styled.div<{rowsSize: number[]}>`
  width: 100%;
  padding-left: 15px;
  margin: 1px;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 36px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  font-family: Ubuntu;
  color: #f5222d;
`;

export const ContainerButtonSubmit = styled.div`
  margin-top: 2rem;
  width: 329px;
  max-width: 100%;
`;

export const Form = styled.form`
  margin-top: 2rem;
`;

export const DefaultFormLabel = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;
