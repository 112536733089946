import { Dialog } from '@mui/material';
import { UserCircle } from 'phosphor-react';
import styled from "styled-components";

interface ContainerAttemptsProps {
  hasQuestions: boolean;
}

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    width: 50% !important;
    max-width: none; 
  }

  @media (max-width: 425px) {
    .MuiDialog-paper {
      width: 90% !important;
      max-width: none; 
    }
  }

  @media only screen and (min-width: 426px) and (max-width: 1000px) {
    .MuiDialog-paper {
      width: 80% !important; /* Reduzir a largura para 80% */
      max-width: none;
      margin: 2rem 0 !important;
    }
    margin: 2rem 0 !important;
  } 
`;

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;

  @media (max-width: 425px) {
    padding: 40px 20px;
  }
`;

const SecondaryTypography = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  line-height: 32px;
  margin-bottom: 17px;
`;

export const SubtitleModal = styled(SecondaryTypography)`
  color: #131212;
`;

export const GridSection = styled.div`
  margin-top: 2.25rem;
  position: relative;
  display: grid;
  grid-template-areas:
    "columnUser userName"
    "columnFormation position"
    "columnChallenger challenger"
    "columnResult result"
    "columnAttempts attempts"
    "columnLastUpdate lastUpdate";
  grid-column-gap: 3rem;
  grid-row-gap: 0.75rem;
  grid-template-columns: 1fr 1.25fr;
  align-items: center;

  @media (max-width: 425px) {
    grid-column-gap: 1rem;
  }

  progress[value] {
    height: 7px;

    ::-webkit-progress-bar {
      height: 7px;
    }

    ::-webkit-progress-value {
      height: 7px;
    }
  }
`;

const ColumnFixed = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #131212;
`;

export const ColumnUser = styled(ColumnFixed)`
  grid-area: columnUser;
`;

export const ColumnFormation = styled(ColumnFixed)`
  grid-area: columnFormation;
`;

export const ColumnChallenger = styled(ColumnFixed)`
  grid-area: columnChallenger;
`;

export const ColumnAttempts = styled(ColumnFixed)`
  grid-area: columnAttempts;
`;

export const ColumnResult = styled(ColumnFixed)`
  grid-area: columnResult;
`;

export const ColumnLastUpdate = styled(ColumnFixed)`
  grid-area: columnLastUpdate;
`;

export const UserName = styled.div`
  display: flex;
  grid-area: userName;
  color: #8c8c8c;
  align-items: center;
`;

export const NameUser = styled(SecondaryTypography)`
  color: #8c8c8c;
`;

export const UserIcon = styled(UserCircle)`
  align-items: center;
  margin-right: 4px;
  width: 18px;
  height: 18px;
`;

export const Challenger = styled(SecondaryTypography)`
  grid-area: challenger;
  color: #131212;
`;

export const Result = styled(SecondaryTypography)`
  grid-area: result;
  color: #131212;
`;

export const Attempts = styled(SecondaryTypography)`
  grid-area: attempts;
  color: #131212;
`;

export const LastUpdate = styled(SecondaryTypography)`
  grid-area: lastUpdate;
  color: #131212;
`;

export const Position = styled(SecondaryTypography)`
  grid-area: position;
  color: #131212;
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ContainerAttempts = styled.div<ContainerAttemptsProps>`
  display: grid; 
  grid-template-columns: ${(props) => props.hasQuestions ? '2fr 2fr' : '1fr'}; 
  text-align: center;
  gap: 1.5rem; 

  @media (max-width: 425px) {
    grid-template-columns: 1fr; 
    grid-column-gap: 1rem;
  }
`;

export const EmptyAttempts = styled(SecondaryTypography)`
  color: #131212;
`;
