import api from "./api";

class AddressService {

  private baseUrl = "address";

  async getCurrentCompanyAddresses() {
    const response = await api.get(`${this.baseUrl}/company`);
    const addresses = response.data.map(address => ({
      id: address.id,
      name: `${address.neighborhood}, ${address.city.name} - ${address.state.name}`,
      value: `${address.neighborhood}, ${address.city.name} - ${address.state.name}`,
    }));
    return addresses;
  }
}

export default new AddressService();