import styled from "styled-components";

export const FullModal = styled.div`
  width: 820px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 64px;
  border-radius: 3px;
  text-align: center;
  max-height: 96%;
  overflow-y: scroll;
  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
`;

export const ModalSubtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 28px;
`;

export const ContainerInput = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  width: 92%;
`;

export const TextBold = styled.p`
  font-weight: 700;
  font-size: 18px;
`;
export const ContainerOptions = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-height: 200px;
  overflow-y: scroll;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(90% - 32px - 22px - 18px);
  gap: 30px;
  @media screen and (max-width: 600px) {
    gap: 15px;
  }
`;

export const ContainerInnerOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
`;

export const RowOptionAll = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px;
  width: 100%;
`;

export const RowOptionText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
  margin-left: 4px;
`;

export const CheckboxOutCircle = styled.div<{ selected: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box"
      : "linear-gradient(white, white) padding-box, linear-gradient(90deg, #696969 0%, #696969 100%) border-box"};
  border-radius: 2px;
  border: 2px solid transparent;
`;
