import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Button } from "../../../components/Button";
import userService from "../../../services/UserService";
import * as Yup from "yup";
import * as S from "../style";
import { t } from "i18next";

interface IFormData {
  onSubmit: (email: string) => void;
  onClose: () => void;
  openModal: boolean;
  errorBackend: boolean;
  errorMessageMail: string;
  countErrorMessage: boolean;
}

const Form = ({
  onSubmit,
  onClose,
  openModal,
  errorBackend,
  errorMessageMail,
  countErrorMessage,
}: IFormData) => {
  const [formData, setFormData] = useState({ email: "" });
  const [errors, setErrors] = useState<Record<string, string>>({});  

  var emailInvalid = t("forgetPasswordModal.invalidEmail");
  var emailRequired = t("forgetPasswordModal.requiredEmail");

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(emailInvalid)
      .required(emailRequired),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    schema
      .validate(formData, { abortEarly: false })
      .then(() => onSubmit(formData.email))
      .catch((err) => {
        const newErrors: Record<string, string> = {};
        err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      });
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.FullModalCodePassword>
        <S.formForgetPassword onSubmit={handleSubmit}>
          <S.ModalTitle>{t("forgetPasswordModal.forgetPassword")}</S.ModalTitle>
          <S.ModalSubtitleCodeSecurity data-cy="loginScreenModalEmailForget">
            {countErrorMessage && (
              <p>{t("forgetPasswordModal.typeEmailError")}</p>
            )}
            {t("forgetPasswordModal.typeEmail")}
          </S.ModalSubtitleCodeSecurity>

          <S.LabelCode>{t("forgetPasswordModal.email")}</S.LabelCode>
          <S.inputNewPassword
            id="email"
            name="email"
            placeholder={t("forgetPasswordModal.your@email")}
            value={formData.email}
            onChange={handleChange}
          />

          {errors.email && (
            <S.ErrorMessage variant="body1">{errors.email}</S.ErrorMessage>
          )}
          {errorBackend && (
            <S.ErrorMessage variant="body1">{errorMessageMail}</S.ErrorMessage>
          )}
          <S.ContainerButtons>
            <S.buttonPink type="submit">
              {t("forgetPasswordModal.resetLink")}
            </S.buttonPink>
            <S.buttonWhite onClick={handleClose}>
              {t("forgetPasswordModal.cancel")}
            </S.buttonWhite>
          </S.ContainerButtons>
        </S.formForgetPassword>
      </S.FullModalCodePassword>
    </Modal>
  );
};

export default Form;
