import styled from "styled-components";

export const FullModal = styled.div`
  font-family: "Ubuntu";
  width: 652px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 75px;
  border-radius: 3px;
  text-align: center;
  max-height: 98%;
  overflow-y: auto;
  transition: height 0.5s ease-in-out;

  & > * {
    max-height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  &:focus {
    outline: none !important;
  }

  &.expanded {
    height: 100%;
  }

  &.collapsed {
    height: 750px;
  }

  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const TitleWrapper = styled.div`
  padding-bottom: 36px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
`;

export const containerTermosDeUso = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const ContainerButton = styled.div`
  margin: 30px 0;
  @media screen and (max-width: 1049px) {
    width: 100%;
  }
`;

export const buttonPink = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  line-height: 32px;
  width: 290px;
  height: 52px;
  color: #ffffff;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);

  &:disabled {
    background: #8c8c8c;
    cursor: default;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-top: 34px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
`;
