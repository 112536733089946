import React, {useEffect, useState} from "react";
import {UploadFile} from "../../models/UploadFile";
import * as S from "./styles";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {t} from "i18next";
import {enqueueSnackbar} from "notistack";

interface AvatarProps {
  legend?: string;
  value?: UploadFile;
  onChange?: (uploadFile: UploadFile) => void;
  signUp?: boolean;
}


const AvatarInput = ({legend, value, onChange, signUp}: AvatarProps) => {
  const inputRef = React.useRef(null);
  const [error, setError] = React.useState<string>();
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const allowedExtensions = ["png", "jpeg", "jpg", "bmp", "wbmp"]
  const labels = {
    recomendedSize: t("empresas.recommendedSize"),
    sizePattern: "500x500 px",
    fileFormats: t('empresas.fileFormats'),
    formats: ".png .jpeg .jpg .bmp .wbmp",
    add: t("empresas.add"),
    update: t("empresas.update"),
  };

  const handleVerifyExtensions = (fileName) => {
    const extension = fileName?.toLowerCase()?.split('.')?.pop()
    return allowedExtensions?.map(ext => ext?.toLowerCase())?.includes(extension);
  }

  const handleFileChange = (event) => {
    if (handleVerifyExtensions(event?.target?.files[0]?.name)) {
      setCurrentFile(null);
      if (event?.target?.files && event?.target?.files?.length > 0) {
        const file = event.target.files[0];
        setSelectedFile(file);
        onChange(file);
      }
    } else {
      enqueueSnackbar(t("empresas.fileFormatsError"), {variant: "warning"});
    }
  };

  useEffect(() => {
    setCurrentFile(value?.fileUrl);
  }, [value]);

  return (
    <S.AvatarWrapper data-cy="companyFormAvatarInput">
      <S.AvatarImageWrapper>
        <IconButton>
          <label htmlFor="avatarInput">
            <Avatar
              style={{width: "150px", height: "150px", cursor: "pointer"}}
              src={
                currentFile
                  ? currentFile
                  : selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : ""
              }
            />
          </label>
          <input
            data-cy="ScreenSignUpPhoto3"
            id="avatarInput"
            type="file"
            accept="image/*"
            style={{display: "none"}}
            onChange={handleFileChange}
            ref={inputRef}
          />
        </IconButton>
      </S.AvatarImageWrapper>
      {legend && (
        <S.LegendDiv onClick={() => inputRef?.current?.click()}>
          <span>
            {!value?.id ? labels.add : labels.update}&nbsp;{legend}
          </span>
        </S.LegendDiv>
      )}
      <S.RecomendedSizeDiv>
        <span>{labels.fileFormats}</span>
        <br/>
        <span>{labels.formats}</span>
        <br/>
        <span>{labels.recomendedSize}</span>
        <br/>
        <span>{labels.sizePattern}</span>
        {error && <S.removeImage>{error}</S.removeImage>}
      </S.RecomendedSizeDiv>
    </S.AvatarWrapper>
  );
};

export default AvatarInput;
