import styled from "styled-components";

export const ContainerCard = styled.a`
  min-width: 350px;
  max-width: 350px;
  max-height: 101px;
  min-height: 101px;
  padding: 17px 43px 14px 12px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  background: #ffffff;

  display: grid;
  grid-template-areas:
    "image title"
    "image subtitle";
  grid-template-columns: 25% 70%;

  @media (max-width: 834px) {
    min-width: 329px;
    max-width: 329px;
  }

  @media (max-width: 320px) {
    min-width: 280px;
    max-width: 280px;
    grid-template-columns: 35% 65%;
  }
`;

export const ImageList = styled.img`
  grid-area: "image";
  min-width: 64px;
  max-width: 64px;
  min-height: 71px;
  max-height: 71px;
  object-fit: cover;
`;

export const NameOfArticle = styled.p`
  grid-area: title;

  max-width: 239px;
  min-width: 239px;
  max-height: 37px;
  min-height: 37px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #131212;

  @media (max-width: 834px) {
    min-width: 215px;
    max-width: 215px;
  }

  @media (max-width: 320px) {
    min-width: 165px;
    max-width: 165px;
  }
`;

export const SubtitleOfArticle = styled.p`
  grid-area: subtitle;
  position: relative;
  bottom: 25px;

  max-width: 239px;
  min-width: 239px;
  max-height: 21px;
  min-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #696969;

  @media (max-width: 834px) {
    min-width: 210px;
    max-width: 210px;
  }

  @media (max-width: 320px) {
    min-width: 160px;
    max-width: 160px;
  }
`;

export const CardLink = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 17px;
  color: #8c8c8c;
`;

export const ImageDownload = styled.img``;
