import styled from "styled-components";
import {Fonts} from "design-system-firedev";
import {colors} from "../../../components/utils/colors";

interface IContainerFullPageProps {
  isFullPage: boolean;
}

export const Container2 = styled.form`
  h2 {
    color: var(--text-title);
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  input {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;
    border-radius: 0.25rem;

    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }

    & + input {
      margin-top: 1rem;
    }
  }

  button[type="submit"] {
    width: 100%;

    height: 4rem;
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ViewMore = styled.div`
  font-weight: bold;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  color: #ff026f;
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 16px 0 4%;
`;

export const ContainerDropdowns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 20px 0 32px 0;
`;

export const ContainerDropdownsCenter = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1316px;
  width: 100%;
  gap: 16px;
  @media screen and (max-width: 585px) {
    flex-direction: column
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
  max-width: 650px;
`;

export const ContainerCards = styled.div<any>`
  width: 100%;
  overflow-y: scroll;
  border: 1px solid ${colors.gray100};
  background-color: #fff;
  border-radius: 3px 3px 0 0;

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${colors.gray100};
  }

  ::-webkit-scrollbar-track {
    width: 5px;
    border-left: 1px solid ${colors.gray100};
    border-radius: 0;
    background-color: #fff0;
  }

  max-width: 650px;
  height: calc(100vh - 42px - 31px - 148px - 90px +
  ${({showTop}) => (showTop ? "0px" : "128px")});
  @media screen and (max-width: ${({exist}) => (exist ? 1270 : 999)}px) {
    height: calc(100vh - 84px - 31px - 148px - 90px +
    ${({showTop}) => (showTop ? "0px" : "128px")});
  }
  @media screen and (max-width: ${({exist}) => (exist ? 999 : 724)}px) {
    height: calc(100vh - 126px - 31px - 148px - 90px +
    ${({showTop}) => (showTop ? "0px" : "128px")});
  }
  padding-bottom: ${({showTop}: { showTop?: boolean }) => (showTop ? 0 : 134)}px;
  transition: 0.6s;
`;

export const ContainerFulMainData = styled.div<any>`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  overflow: hidden;
`;

export const ContainerTitle = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({showTop}) => (showTop ? "128px" : "0px")};
  opacity: ${({showTop}) => (showTop ? 1 : 0)};
  transition: opacity 0.2s, height 0.6s;
  width: 100%;
  max-width: 1316px;

  //overflow: hidden;
`;

export const ContainerContent = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const ContainerCardsTotal = styled.div`
  width: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 74px;
  @media screen and (max-width: 745px) {
    display: none;
  }
`;
export const ContainerCardsTwo = styled.div`
  padding: 1rem 0;
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: #131212;
  margin-top: 20px;
  margin-bottom: 1rem;
`;

export const EmptyTrails = styled.p`
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: #131212;
`;
export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: ${colors.primaryOrange};
  border-radius: 0px 0px 3px 3px;
  height: 63px;
  align-items: center;
`;

export const FooterItem = styled.div`
  display: inline-block;
`;

export const FooterItemInfo = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

export const FooterItemCount = styled.h4`
  margin: 0;
  font-family: "Ubuntu";
  font-weight: 500;
  color: #fff;
`;

export const FooterItemLabel = styled.span`
  font-family: "Ubuntu";
  font-size: 0.75rem;
  font-weight: 500;
  color: #fff;
`;
export const Image = styled.img``;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerTrailsMain = styled.div<IContainerFullPageProps>`
  margin-top: 20px;
  width: ${({isFullPage}) =>
          isFullPage ? "calc(100% - 320px - 16px)" : "100%"};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

export const ContainerLoadingTable = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const ContainerDropdownsMain = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 16px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ContainerDropdownScreen = styled.div`
  width: 118px;
  height: 42px;
  @media screen and (max-width: 935px) {
    height: 100px;
  }
  @media screen and (max-width: 585px) {
    width: 100%;
    height: 42px;
  }
`;

export const InputOrange = styled.input`
  width: 100%;
  max-width: 650px;
  background-color: #fff;
  border: 1px solid rgb(232, 92, 32);
  height: 42px;
  outline: none;
  padding: 11px 16px 11px 16px;
  border-radius: 3px;
  color: #131212;
  font-weight: 700;
  font-size: 18px;

  ::placeholder {
    color: #696969;
  }
`;
