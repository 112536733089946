import {Company} from "../models/Company";
import {PageResponse} from "../models/PageResponse";
import {ComapnyFilterDTO, CompanyMinListItem} from "../types/CompanyDTO";
import FileService from "./FileService";
import api from "./api";

class CompanyService {
  private baseUrl = "companies";

  async getAll(search: string): Promise<PageResponse<Company>> {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `${this.baseUrl}/all-no-child?filter=${filter}`
    );
    return response.data;
  }

  async getAllMapped(search: string) {
    const filter = encodeURIComponent(search);
    const result = await api.get(
      `${this.baseUrl}/all-no-child?filter=${filter}`
    );
    return result.data.content
      .map(({id, name}) => {
        return {id: id, value: id, name: name};
      });
  }

  async getPage(
    page: number,
    size: number,
    search: string
  ): Promise<PageResponse<Company>> {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `${this.baseUrl}?page=${page}&size=${size}&filter=${filter}`
    );
    return response.data;
  }

  async getById(id: number): Promise<Company> {
    const response = await api.get(`${this.baseUrl}/${id}`);
    return response.data;
  }

  async deleteById(id: number) {
    await api.delete(`${this.baseUrl}/${id}`);
  }

  async unlockUserById(id: number): Promise<Company> {
    const response = await api.post(`${this.baseUrl}/unlock`, { userId: id });
    return response.data;
  }

  async contactUserById(contactDTO): Promise<Company> {
    const response = await api.post(`${this.baseUrl}/contact-user`, contactDTO);
    return response.data;
  }

  async verifyIfUserAlreadyContacted(userId: number, formationId: number) {
    const response = await api.get(
      `${this.baseUrl}/email-sent/${userId}/${formationId}`
    );
    return response.data;
  }

  async beforeSave(company: Company): Promise<Company> {
    if (company?.avatar?.id) {
      return company;
    }
    if (company.avatar) {
      const fileId = await FileService.sendFile(company.avatar);
      company.avatarId = fileId;
    }
    return company;
  }

  async create(company: Company): Promise<Company> {
    company = await this.beforeSave(company);
    const response = await api.post(`${this.baseUrl}`, company);
    return response.data;
  }

  async update(id: number, company: Company): Promise<void> {
    company.id = id;
    company = await this.beforeSave(company);
    const response = await api.put(`${this.baseUrl}/${id}`, company);
    return response.data;
  }

  async delete(id: number): Promise<void> {
    await api.delete(`${this.baseUrl}/${id}`);
  }

  async getMyCompanies(): Promise<ComapnyFilterDTO[]> {
    const companies = await api.get(`${this.baseUrl}/mine`);
    return companies.data;
  }

  async getCurrentCompanyBalance() {
    const wallet = await api.get(`/companies/balance`);
    return wallet.data.balance;
  }

  async unlockedListUsers(id: number[]) {
    const userIds = {};
    const userList = id.map((id) => {
      return { userId: id };
    });

    const response = await api.post(
      `${this.baseUrl}/unlockListCheckBox`,
      userList
    );
    return response.data;
  }

  formatCompaniesToSelectList(
    companiesListDTO: ComapnyFilterDTO[]
  ): CompanyMinListItem[] {
    const companies = [];
    companiesListDTO?.forEach((company) => {
      companies.push({ name: company.name, id: company.id });
    });

    return companies;
  }
}

export default new CompanyService();
