import { Box } from "@mui/material";
import { Formation } from "../../../../models/Formation";
import { Trail } from "../../../../models/Trail";
import FormationProgressRow from "../FormationProgressRow";

interface IFormationProgress {
  formations?: Formation[];
  trails?: Trail[];
  halfSize?: boolean;
  isLoading?: boolean;
  dataType: "trail" | "position";
}

function FormationProgress(data: IFormationProgress) {
  const isTrail = () => {
    return data.dataType === "trail";
  };

  return (
    <Box>
      {(data.isLoading && (
        <>
          <FormationProgressRow dataType={data.dataType} isLoading />
          <FormationProgressRow dataType={data.dataType} isLoading />
          <FormationProgressRow dataType={data.dataType} isLoading />
        </>
      )) || (
        <>
          {isTrail() ? data.trails.map((trail) => (
            <Box marginTop={3}>
              <FormationProgressRow
                  dataType={data.dataType}
                  trail={trail}
              />
            </Box>
            )
          ) : data.formations.map((formation) => (
            <Box marginTop={3}>
              <FormationProgressRow
                dataType={data.dataType}
                formation={formation}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}

export default FormationProgress;
