import styled from "styled-components";

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputNumber = styled.input`
  width: 400px;
  max-width: 100%;
  height: 40px;
  background: #ffe8e6;
  padding: 12px 17px;
  font-size: 1rem;
  font-weight: 700;

  border: 1px solid #8c8c8c;
  border-radius: 3px;
  outline: none !important;
  -moz-appearance: textfield;
  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 16px;
  }
  &:focus {
    outline: 1px solid #ff026f;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
