import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Backdrop, CircularProgress, MenuItem, Select } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useGoogleLogin } from "@react-oauth/google";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useNavigate } from "react-router-dom";
import {
  GoogleLoginButton,
  LinkedInLoginButton,
} from "react-social-login-buttons";
import approved from "../../assets/approved.png";
import Art from "../../assets/art.png";
import MobileLogo from "../../assets/logo-firedev.png";
import WrittenLogo from "../../assets/sh-logo-color.svg";
import { Button } from "../../components/Button";
import { InputPassword } from "../../components/InputPassword";
import AuthService from "../../services/AuthService";
import userService from "../../services/UserService";
import ModalCodeMessage from "./components/ModalCodeMessage";
import ModalForgetPassword from "./components/ModalForgetPassword";
import ModalResetPassword from "./components/ModalResetPassword";
import PasswordResetSucess from "./components/PasswordResetSucess";
import * as S from "./style";

function redirectBySubscriber(courseId, positionId, company) {
  if (courseId) {
    location.href = `/course/${company}/${courseId}`;
    return true;
  }

  if (positionId) {
    location.href = `/position/${company}/${positionId}`;
    return true;
  }

  return false;
}

interface State {
  password: string;
  email: string;
}

export interface IPasswordRequest {
  password: string;
  confirmPassword: string;
}

export interface IPasswordDTO {
  code: string;
  password: string;
  confirmPassword: string;
  email: string;
}

function LoginPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [isOpenModal, setIsOpenModal] = useState(verifyIfWillOpenModal());
  const [loading, setLoading] = useState(false);

  const userResetEmail = queryParams.get("userEmail") || null;
  const securityCode = queryParams.get("securityCode") || null;
  const courseId = queryParams.get("course") || null;
  const positionId = queryParams.get("position") || null;
  const company = queryParams.get("company") || null;

  React.useEffect(() => {
    UnBlockedAccount();
    if (queryParams.get("securityCode")) {
      setShowModal3(true);
    }
  }, []);

  async function ResendEmailCode() {
    await userService.ResendEmail(email);
  }

  function verifyIfWillOpenModal() {
    return window.document.URL.includes("?openVerification");
  }
  const [countErros, setCountErros] = React.useState(0);

  async function UnBlockedAccount() {
    if (window.document.URL.includes("?token")) {
      try {
        await userService.unBlockedUser(queryParams.get("token"));
        setIsOpenModalSucess(true);
      } catch (e) {
        console.error(e);
      }
    }
  }
  const [isOpenModalSucess, setIsOpenModalSucess] = React.useState(false);

  const [values, setValues] = React.useState<State>({
    email: "",
    password: "",
  });

  function incrementErros() {
    setCountErros(countErros + 1);
  }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  function handleNavigate() {
    navigate("/sign-up");
  }

  async function handleLogin() {
    event.preventDefault();
    const { email } = values;
    const { password } = values;
    setEmail(email);

    try {
      const response = await AuthService.login(email, password);

      if (redirectBySubscriber(courseId, positionId, company)) return;

      if (
        response?.roles?.some((role) =>
          ["ROLE_SYSADMIN"].includes(role.authority)
        )
      ) {
        location.href = "/management";
      } else {
        const lastPageAccessed = localStorage.getItem("lastPageAccessed");
        location.href = lastPageAccessed || "/";
      }
    } catch (err) {
      incrementErros();
      setError(true);
      if (countErros >= 4) {
        setShowModal1(true);
        setCountErrorMessage(true);
      }
    }
  }

  const [showModal1, setShowModal1] = useState<boolean>(false);
  const [errorBackend, setErrorBackend] = useState<boolean>(false);
  const [errorMessageMail, setErrorMessageMail] = useState<string>("");
  const [countErrorMessage, setCountErrorMessage] = useState<boolean>(false);

  function handleOpenModalForgetPassword() {
    setErrorBackend(false);
    setShowModal1(true);
  }

  const handleClose1 = () => {
    setErrorBackend(false);
    setShowModal1(false);
  };

  const handleSendEmailAndshowModal2 = async (email: string) => {
    try {
      await userService.sendEmailWhenForgetPassword(email);
      setShowModal1(false);
      setShowModal2(true);
      setEmail(email);
    } catch (err: any) {
      console.error(err);
    }
  };

  const [showModal2, setShowModal2] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errorBackendCode, setErrorBackendCode] = useState<boolean>(false);
  const [errorMessageCode, setErrorMessageCode] = useState<string>("");
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "pt"
  );
  const sendCodeVerify = async (code: string) => {
    try {
      await userService.verifyCodeResetPassword(code);
      setShowModal2(false);
      setCode(code);
      setShowModal3(true);
    } catch (err: any) {
      console.error(err);
      setErrorBackendCode(true);
      setErrorMessageCode(err.response.data.message);
    }
  };

  const handleChangeLenguage = (value) => {
    setLanguage(value.target.value);
    localStorage.setItem("language", value.target.value);
    window.location.reload();
  };

  const handleClose2 = () => {
    setErrorBackendCode(false);
    setShowModal2(false);
  };

  const [showModal3, setShowModal3] = useState<boolean>(false);
  const [showModal4, setShowModal4] = useState<boolean>(false);

  async function ResendPassword(PasswordRequest: IPasswordRequest) {
    let PasswordDTO: IPasswordDTO = {
      code: code || securityCode,
      password: PasswordRequest.password,
      confirmPassword: PasswordRequest.confirmPassword,
      email: email || userResetEmail,
    };
    await userService.checkIfPasswordsMatch(PasswordDTO);
    setShowModal3(false);
    setShowModal4(true);
  }

  const handleClose3 = () => {
    setShowModal3(false);
  };

  const handleClose4 = () => {
    setShowModal4(false);
  };

  const googleInLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getGoogleData(tokenResponse.access_token);
    },
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_APP_ID,
    scope: "r_liteprofile,r_emailaddress",
    redirectUri: process.env.REACT_APP_LINKEDIN_URL_REDIRECT,
    onSuccess: (code) => {
      getLinkedinData(code);
    },
    onError: (error) => {
      console.error("error", error);
    },
  });

  async function getLinkedinData(token: string) {
    try {
      setLoading(true);
      getUserData(await AuthService.linkedinLogin(token));
    } catch (err: any) {
      setLoading(false);
      enqueueSnackbar(
        t(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "login.error"
        ),
        {
          variant: "error",
        }
      );
    }
  }

  async function getGoogleData(token: string) {
    try {
      setLoading(true);
      getUserData(await AuthService.googleLogin(token));
    } catch (err: any) {
      setLoading(false);
      enqueueSnackbar(
        t(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "login.error"
        ),
        {
          variant: "error",
        }
      );
    }
  }

  async function getUserData(response: any) {
    if (response && response.token) {
      if (
        response?.roles?.some((role) =>
          ["ROLE_SYSADMIN"].includes(role.authority)
        )
      ) {
        location.href = "/management";
      } else {
        const lastPageAccessed = localStorage.getItem("lastPageAccessed");
        location.href = lastPageAccessed || "/";
        return;
      }
    }
    navigate(`/sign-up`, { state: { data: response } });
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div>
        {showModal1 && (
          <ModalForgetPassword
            openModal={showModal1}
            onClose={handleClose1}
            countErrorMessage={countErrorMessage}
            onSubmit={handleSendEmailAndshowModal2}
            errorBackend={errorBackend}
            errorMessageMail={errorMessageMail}
          />
        )}
        {showModal2 && (
          <ModalCodeMessage
            openModal={showModal2}
            onClose={handleClose2}
            onSubmit={sendCodeVerify}
          />
        )}
        {showModal3 && (
          <ModalResetPassword
            openModal={showModal3 || securityCode !== null}
            onClose={handleClose3}
            onSubmit={ResendPassword}
            errorBackend={errorBackendCode}
            errorMessageMail={errorMessageCode}
          />
        )}
        {showModal4 && (
          <PasswordResetSucess
            openModal={showModal4}
            onClose={() => {
              handleClose4();
              navigate("/login");
            }}
          />
        )}
        <S.MainDiv>
          <S.FormDiv>
            <Select
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                ".MuiSelect-select": {
                  display: "flex !important",
                  gap: "5px !important",
                  alignItems: "center !important",
                },
                width: "100px",
                display: "flex !important",
                position: "absolute",
                gap: "5px !important",
                alignItems: "center !important",
                left: "42vw",
                "@media (max-width: 850px)": {
                  left: "48vw",
                },
                "@media (max-width: 767px)": {
                  left: "74vw",
                },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label="Age"
              onChange={handleChangeLenguage}
            >
              <MenuItem
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                value="pt"
              >
                <span>PT-BR</span>
              </MenuItem>
              <MenuItem
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                value="es"
              >
                <span>ES</span>
              </MenuItem>
              <MenuItem
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                value="en"
              >
                <span>EN-US</span>
              </MenuItem>
            </Select>
            <S.LoginFormDiv>
              <S.Logo src={WrittenLogo} alt="Logo Firedev" />
              <S.MobileLogo src={MobileLogo} alt="Logo Firedev" />
              <form
                autoComplete="off"
                onSubmit={() => handleLogin()}
                data-cy="loginForm"
              >
                <S.Title variant="display1">Login</S.Title>
                <S.Subtitle variant="body1">
                  {t("login.loginPhrase")}
                </S.Subtitle>
                <S.ContainerInputs>
                  <S.Label>{t("login.email")}</S.Label>
                  <S.InputEmail
                    value={values?.email}
                    onChange={handleChange("email")}
                    placeholder={t("login.loginEmailInput")}
                    borderColor={error ? "#F5222D" : "#8c8c8c"}
                    data-cy="loginScreenInputEmail"
                  />
                  <InputPassword
                    label={t("login.loginPasswordInput")}
                    placeholder="••••••••••••••••"
                    onChange={handleChange("password")}
                    value={values?.password}
                    height={3.425}
                    style={{ fontSize: 14, fontWeight: 400 }}
                    borderColor={error ? "#F5222D" : "#8c8c8c"}
                    data-cy="loginScreenInputPassword"
                  />
                </S.ContainerInputs>
                <S.containerErrorAndForgetPassword>
                  {error && (
                    <S.ErrorMessageLoginFailed variant="body1">
                      {t("login.loginRecoverPassword")}
                    </S.ErrorMessageLoginFailed>
                  )}
                  <S.SignForgetPassword
                    onClick={handleOpenModalForgetPassword}
                    data-cy="loginScreenForgetPassword"
                  >
                    <S.FontForgetPassword variant="body1">
                      {t("login.loginForgetPassword")}
                    </S.FontForgetPassword>
                  </S.SignForgetPassword>
                </S.containerErrorAndForgetPassword>

                <S.ContainerButton>
                  <S.ButtonLogin
                    type="submit"
                    data-cy="loginScreenAccessButton"
                  >
                    {t("login.loginEnter")}
                  </S.ButtonLogin>
                </S.ContainerButton>
              </form>
              <S.SignUpDiv
                onClick={handleNavigate}
                data-cy="loginScreenCreateAccount"
              >
                <S.SignUpLink variant="body1">
                  {t("login.loginRegister")}
                </S.SignUpLink>
                <S.LinkIcon>
                  <ArrowForwardIcon />
                </S.LinkIcon>
              </S.SignUpDiv>

              <S.FastAccess>
                <S.FastAccess2>
                  <S.Line1></S.Line1>
                  <S.FastAccessTitle variant="body1">
                    {t("login.fastAccess")}
                  </S.FastAccessTitle>
                  <S.Line1></S.Line1>
                </S.FastAccess2>

                <S.ContainerLinkedin>
                  <LinkedInLoginButton
                    text=""
                    style={{
                      borderRadius: "50%",
                      margin: 0,
                      height: 47,
                      width: 45,
                    }}
                    onClick={linkedInLogin}
                  />
                  <GoogleLoginButton
                    text=""
                    style={{
                      borderRadius: "50%",
                      margin: 0,
                      height: 47,
                      width: 45,
                    }}
                    onClick={googleInLogin}
                  />
                </S.ContainerLinkedin>
              </S.FastAccess>
            </S.LoginFormDiv>
          </S.FormDiv>
          <S.LoginArtDiv>
            <img src={Art} alt="studies setup" className="laptop" />
          </S.LoginArtDiv>
        </S.MainDiv>
        <Modal
          open={isOpenModal}
          sx={{
            backdropFilter: "blur(2px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClose={() => {
            setIsOpenModal(false);
          }}
        >
          <S.FullModal>
            <S.ModalTitle>Verificação do e-mail</S.ModalTitle>
            <S.ModalSubtitle>
              Para continuar, verifique o seu endereço de e-mail cadastrado e
              ative a sua conta. Enviamos um link de confirmação para o seu
              e-mail.
            </S.ModalSubtitle>
            <S.EmailRegistred>email@firedev.com.br</S.EmailRegistred>
            <S.ContainerButtons>
              <Button
                style={{
                  width: 290,
                }}
              >
                Reenviar link de verificação
              </Button>
              <Button
                onClick={() => {
                  setIsOpenModal(false);
                  navigate("/login");
                }}
                style={{
                  width: 290,
                  background: "#fff",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "#131212",
                  color: "#131212",
                }}
              >
                Fechar
              </Button>
            </S.ContainerButtons>
          </S.FullModal>
        </Modal>

        {/* Modal de e-mail verificado com sucesso */}
        <Modal
          open={isOpenModalSucess}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          onClose={() => {
            setIsOpenModalSucess(false);
            navigate("/login");
          }}
        >
          <S.FullModalEmailVerify>
            <S.imageApproved src={approved} />
            <S.ModalTitleEmailVerify>
              E-mail verificado!
            </S.ModalTitleEmailVerify>
            <S.ModalSubtitle>
              Seu e-mail foi verificado e sua conta está pronta para uso!
            </S.ModalSubtitle>

            <S.ContainerButtons>
              <S.buttonPink
                style={{
                  width: 290,
                }}
                onClick={() => {
                  setIsOpenModalSucess(false);
                }}
              >
                Continuar
              </S.buttonPink>
            </S.ContainerButtons>
          </S.FullModalEmailVerify>
        </Modal>
      </div>
    </>
  );
}

export default LoginPage;
