import { Alert, Box, Grid, Skeleton, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { ILastAccessedFormation } from "../../../../models/LastAccessedFormation";
import { ILastAccessedTrail } from "../../../../models/LastAccessedTrail";

interface IContinueWhereYouLeft {
  formation?: ILastAccessedFormation;
  trail?: ILastAccessedTrail;
  isLoading?: boolean;
  dataType: "trail" | "position";
}

function ContinueWhereYouLeft(data: IContinueWhereYouLeft) {
  const navigate = useNavigate();

  const generateLink = (formation) => {
    if (formation?.skill == null) {
      return `/position/${formation?.company?.slug}/${formation?.slug}`;
    }

    return `/position/${formation?.company?.slug}/${formation?.slug}/skill-tree/${formation?.skill?.skillTreeSlug}/skill/${formation?.skill?.slug}`;
  };

  const generateLinkTrail = (trail) => {
    if (trail?.skill == null) {
      return `/course/${trail?.company?.slug}/${trail?.slug}`;
    }

    return `/course/${trail?.company?.slug}/${trail?.slug}/skill-tree/${trail?.skill?.skillTreeSlug}/skill/${trail?.skill?.slug}`;
  };

  const isTrail = () => {
    return data.dataType === "trail";
  };

  return (
    <Grid container marginY={3}>
      {!data.isLoading &&
        ((data.dataType === "position" && data?.formation?.percentage === 100) ||
          (data.dataType === "trail" && data?.trail?.percentage === 100 && isTrail())) && (
          <Grid item xs={12} marginBottom={3}>
            <Alert severity="success">
              {isTrail()
                ? t("clientDashboard.continueWhereYouLeftFinishedTrail")
                : t("clientDashboard.continueWhereYouLeftFinishedPosition")}
            </Alert>
          </Grid>
        )}

      {(data.isLoading && (
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Skeleton
            animation="wave"
            variant="circular"
            width={50}
            height={50}
          />
        </div>
      )) || (
        <Grid item sm={1} display={"flex"} alignItems={"center"}>
          <img
            width={"56px"}
            height={"56px"}
            src={
              isTrail()
                ? data?.trail?.company?.avatar?.fileUrl
                : data?.formation?.company?.avatar?.fileUrl
            }
          />
        </Grid>
      )}

      <Grid
        paddingLeft={2}
        display={"flex"}
        alignItems={"center"}
        item
        width={"100%"}
        sm={5}
        xs={10}
      >
        <Box width={data.isLoading ? "80%" : "inherit"}>
          <Typography variant="body2" fontWeight={500}>
            {data.isLoading ? (
              <Skeleton width={"100%"} />
            ) : isTrail() ? (
              data?.trail?.skill?.name
            ) : (
              data?.formation?.skill?.name
            )}
          </Typography>
          <Typography marginTop={0.3} variant="body2" fontWeight={300}>
            {data.isLoading ? (
              <Skeleton width={"100%"} />
            ) : isTrail() ? (
              data?.trail?.name
            ) : (
              data?.formation?.title
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        item
        container
        sm={5}
        xs={12}
      >
        <Grid item xs={6} sm={12} marginTop={1}>
          {(data.isLoading && <Skeleton height={80} />) || (
            <Button
              onClick={() =>
                navigate(
                  isTrail()
                    ? generateLinkTrail(data?.trail)
                    : generateLink(data?.formation)
                )
              }
            >
              {data.dataType === "position" && !data?.formation?.skill
                ? t("clientDashboard.visualizePosition")
                : data.dataType === "position" && data?.formation?.skill
                ? t("clientDashboard.continuePosition")
                : data.dataType === "trail" && !data?.trail?.skill && isTrail()
                ? t("clientDashboard.visualizeTrail")
                : data.dataType === "trail" && data?.trail?.skill && isTrail()
                ? t("clientDashboard.continueTrail")
                : null}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContinueWhereYouLeft;
