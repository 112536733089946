import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { t } from "i18next";

function Textarea({ setChange, change, type, questionIndex }: any) {
  const [textarea, setTextarea] = useState(change || "");

  function handleTextarea(quest) {
    setTextarea(quest);
    setChange(quest);
  }

  useEffect(() => {
    setTextarea(change || "");
  }, [type, questionIndex]);

  return (
    <S.ContainerTextarea>
      <S.InputTextarea
        data-cy="challengePageTextInput"
        placeholder={t("challenge.yourAnswer")}
        onChange={(e) => handleTextarea(e.target.value)}
        value={textarea}
      />
    </S.ContainerTextarea>
  );
}

export default Textarea;
