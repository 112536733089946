import styled from "styled-components";

export const ContainerResponsiveSideBarMobile = styled.div`
  max-width: 100vw;
  width: 100vw;
  height: 70px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  background: #ffffff;
  display: none;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: -2px 0 4px #0005;
  @media screen and (max-width: 600px) {
    display: flex;
  }
`;
export const ContainerTrails = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45px;
  height: 45px;

  img {
    width: 18.75px;
    height: 17.5px;
  }
`;

export const ContainerPlans = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const ContainerUser = styled.div`
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-top: 8px;
  /* position: relative; */
  /* right: 12px; */

  color: #8c8c8c;
`;

export const ContainerManagement = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  img {
    width: 19.58px;
    height: 20px;
  }
`;
