import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import QuestionModal from "../QuestionModal";
import * as S from "./styles";
import { t } from 'i18next';

interface AttemptsChallengersProps {
  title: string;
  date?: string;
  questions: any;
  challengerId: number;
}

export function AttemptsChallengers({ title, date, questions, challengerId }: AttemptsChallengersProps) {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchData = async (challengerId) => {
    if (challengerId) {
      try {
        setLoading(true);
      } catch (err: any) {
        console.error(err?.data?.response?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(challengerId);
  }, [challengerId]);  

  const handleOpenModal = (index) => {
    setSelectedQuestion(index);
  };

  const handleCloseModal = () => {
    setSelectedQuestion(null);
  };

  const removeHTMLTags = (input) => {
    return input.replace(/<[^>]*>/g, '');
  }

  return (
    <S.Container>
      {
        loading ?
          <S.Spinner>
            <CircularProgress />
          </S.Spinner> :
          <div>
          <S.Content>
            <div>{title}</div>
            <S.TextDate>{date}</S.TextDate>
          </S.Content>
          <S.StyledDivider variant="middle" />
          {questions?.map((question, index) => (
            <S.Questions key={index}>
              <S.Link href="#" onClick={() => handleOpenModal(index)}>
                <S.Question>{removeHTMLTags(question.question.content)}</S.Question>
              </S.Link>
              <S.IconContainer>  
              <S.IconText isCorrect={question.rightOption} isIncorrect={!question.rightOption}>
                {question.rightOption ? <S.CorrectIcon /> : <S.IncorrectIcon />}
                  <S.Result>{question.rightOption ? t('detailedChallenger.correct').toLocaleUpperCase() : t('detailedChallenger.incorrect').toLocaleUpperCase() }</S.Result>
                </S.IconText>
              </S.IconContainer>
            </S.Questions>
          ))}
          {selectedQuestion !== null && (
            <QuestionModal
              question={questions[selectedQuestion]?.question?.content}
              
              answerOptions={questions[selectedQuestion]?.questionOptions.length > 0 ?
                questions[selectedQuestion]?.questionOptions[0] :  questions[selectedQuestion]?.uploadFile ?  
                { image: questions[selectedQuestion]?.uploadFile} : {}}

              detailedResult={questions[selectedQuestion]?.textResponse}
              open={selectedQuestion !== null}
              correctAlternative={questions[selectedQuestion]?.question?.rightAnswer}
              onClose={handleCloseModal}
              feedback={questions[selectedQuestion]?.feedback}
              isCorrect={questions[selectedQuestion].rightOption}
            />
          )}
        </div>
      }
    </S.Container>
  )
}