import { zodResolver } from "@hookform/resolvers/zod";
import { Button as ButtonDS } from "design-system-firedev";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import zod from "zod";
import CheckboxMultiselect from "../../../components/Checkboxes";
import { ResponsiveInputText } from "../../../components/ResponsiveInputText";
import { Select } from "../../../components/Select";
import { ImageSuccess as ImageSuccessDS } from "../../../components/assets/icon/alerts/verified";
import { options } from "../../../components/utils/languageOptions";
import FileService from "../../../services/FileService";
import StudyArticleService from "../../../services/StudyArticleService";
import api from "../../../services/api";
import Visibility from "../../../types/enums/Visibility";
import * as S from "./styles";

export interface IStudyArticlesFormItem {
  ID: number;
  id: number;
  name: string;
  url: string;
  file: any;
  type: string;
  uploadFile: any;
  visibility: {
    id: string;
    value: string;
  };
}

export interface IStudyArticleFormItem {
  id: number;
  name: string;
  description: string;
  url: string;
  type: string;
  file: any;
  visibility: {
    id: string;
    value: string;
  };
}

export interface IFormData {
  id: number;
  // embedUrl: string;
  name: string;
  description: string;
  url: string;
  type: {
    id: string;
    value: string;
  };
  file: any;
  fileId: number;
  "": any | string;
  languages: any;
  visibility: {
    id: string;
    value: string;
  };
}

interface IArticlesForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: IStudyArticlesFormItem | undefined;
}

export default function StudyArticlesForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: IArticlesForm) {
  const isEditing = typeof dataToEdit !== "undefined";
  const [originalData, setOriginalData] = useState<IStudyArticleFormItem>();
  const [dataEnum, setDataEnum] = useState<any[]>([]);
  const [nameFile, setNameFile] = useState<string>(null);
  const [newOptions, setNewOptions] = useState(options);
  const [localLanguage, setLocalLanguage] = useState(localStorage.getItem("language"));
  const [typeToSchema, setTypeToSchema] = useState({ id: "", value: "" });

  const initialFormSchema = zod.object({
    type: zod
      .object({
        id: zod.string().min(1, "Selecione uma opção."),
        value: zod.string().optional(),
      })
      .optional()
      .refine(
        (type) => {
          return type && Object.keys(type).length > 0;
        },
        { message: t("artigos.selecioneTipoArtigo2") }
      ),
    name: zod.string().min(1, t('artigos.informeNomeValido')),
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t('artigos.selecioneVisibilidade') }
    ),
    languages: zod.any()
  });

  const newStudyArticleTypeLinkFormSchema = zod.object({
    type: zod
      .object({
        id: zod.string().min(1, "Selecione uma opção."),
        value: zod.string().optional(),
      })
      .optional()
      .refine(
        (type) => {
          return type && Object.keys(type).length > 0;
        },
        { message: t("artigos.selecioneTipoArtigo2") }
      ),
    name: zod.string().min(1, t('artigos.informeNomeValido')),
    url: zod
      .string()
      .min(1, t('artigos.informeUrlValida'))
      .url({
        message: t('artigos.informeUrlValida'),
      }),
    // embedUrl: zod
    //     .string()
    //     .min(1, t('artigos.informeEmbedUrlValida'))
    //     .url({
    //       message:
    //           t('artigos.informeEmbedUrlValida'),
    //     }),
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t('artigos.selecioneVisibilidade') }
    ),
    languages: zod.any()

  });

  const newStudyArticleTypeArticleFormSchema = zod.object({
    type: zod
      .object({
        id: zod.string().min(1, "Selecione uma opção."),
        value: zod.string().optional(),
      })
      .optional()
      .refine(
        (type) => {
          return type && Object.keys(type).length > 0;
        },
        { message: t("artigos.selecioneTipoArtigo2") }
      ),
    name: zod.string().min(1, t('artigos.informeNomeValido')),
    file: zod.any().refine((file) => file, t('artigos.adicioneArquivo')),
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t('artigos.selecioneVisibilidade') }
    ),
    languages: zod.any()

  });

  const updateStudyArticleTypeArticle = zod.object({
    type: zod
      .object({
        id: zod.string().min(1, "Selecione uma opção."),
        value: zod.string().optional(),
      })
      .optional()
      .refine(
        (type) => {
          return type && Object.keys(type).length > 0;
        },
        { message: t("artigos.selecioneTipoArtigo2") }
      ),
    name: zod.string().min(1, t('artigos.informeNomeValido')),
    file: zod.any().refine((file) => file, t('artigos.adicioneArquivo')),
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t('artigos.selecioneVisibilidade') }
    ),
    languages: zod.any()

  });

  const updateStudyArticleTypeLink = zod.object({
    type: zod
      .object({
        id: zod.string().min(1, "Selecione uma opção."),
        value: zod.string().optional(),
      })
      .optional()
      .refine(
        (type) => {
          return type && Object.keys(type).length > 0;
        },
        { message: t("artigos.selecioneTipoArtigo2") }
      ),
    name: zod.string().min(1, t('artigos.informeNomeValido')),
    url: zod
      .string()
      .min(1, t('artigos.informeUrlValida'))
      .url({
        message: t('artigos.informeUrlValida'),
      }),
    // embedUrl: zod
    //     .string()
    // .min(1, t('artigos.informeEmbedUrlValida'))
    // .url({
    //   message:
    //       t('artigos.informeEmbedUrlValida'),
    // }),
    // ,
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t('artigos.selecioneVisibilidade') }
    ),
    languages: zod.any()

  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(
      isEditing && typeToSchema?.value === t("artigos.artigo")
        ? updateStudyArticleTypeArticle
        : isEditing && typeToSchema?.value === t("artigos.link")
          ? updateStudyArticleTypeLink
          : !isEditing && typeToSchema?.value === t("artigos.artigo")
            ? newStudyArticleTypeArticleFormSchema
            : !isEditing && typeToSchema?.value === t("artigos.link")
              ? newStudyArticleTypeLinkFormSchema
              : initialFormSchema
    ),
  });

  const fileControlled = watch("file");
  const typeControlled = watch("type");

  useEffect(() => {
    setLocalLanguage(localStorage.getItem("language"))
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    setTypeToSchema(typeControlled);
  }, [typeControlled]);

  const fetchDataToDropdownEnum = async () => {
    const resultDataEnum = await api.get("study-articles/types");
    setDataEnum(resultDataEnum.data);
  };

  async function fetchDataById() {
    const resultDataEnum = await api.get("study-articles/types");
    setDataEnum(resultDataEnum.data);

    const result = await api.get(`study-articles/${dataToEdit?.id}`);
    setOriginalData(result.data);
    setValue(
      "type",
      result.data?.type === "ARTICLE"
        ? resultDataEnum.data[0]
        : result.data?.type === "LINK"
          ? resultDataEnum.data[1]
          : ""
    );
    setNameFile(result.data?.file?.fileName);
    setValue("name", result.data.name);
    setValue(
      "url",
      result.data.url === null ? (result.data.url = "") : result.data.url
    );
    // setValue(
    //     "embedUrl",
    //     result.data.embedUrl === null
    //         ? (result.data.embedUrl = "")
    //         : result.data.embedUrl
    // );
    setValue("description", result.data.description);
    setValue("file", result.data?.file?.id);
    setValue("visibility", {
      id: result.data.visibility,
      value: t(`skills.${Visibility[result.data.visibility]}`),
    });
    const newOptionsLanguage = newOptions.filter(
      option => result?.data?.languages?.includes(option?.value) && option?.value !== localLanguage
    );
    setValue("languages", newOptionsLanguage)
  }

  useEffect(() => {
    if (dataToEdit?.id) {
      fetchDataById();
    }
  }, [isEditing]);

  useEffect(() => {
    fetchDataToDropdownEnum();
    setNewOptions(options.filter((language) => language.value !== (localLanguage || 'pt')));
  }, []);


  const handleCreateNewStudyArticles = async (data: IFormData) => {
    try {
      data.languages = data?.languages.map((language) => language.value)
      let result;
      let createStudyArticleDTO;
      let updateStudyArticleDTO;

      // const { name, type, visibility, file, url, embedUrl } = data;
      const { name, type, visibility, file, url } = data;

      const commonFields = {
        name,
        visibility: visibility?.id,
        languages: data?.languages
      };

      if (type?.id === "ARTICLE") {
        createStudyArticleDTO = {
          ...commonFields,
          fileId: typeof file === "number" ? file : null,
          url: null,
          embedUrl: null,
          type: "ARTICLE",
        };
        updateStudyArticleDTO = {
          ...commonFields,
          fileId: typeof file === "number" ? file : null,
          url: null,
          embedUrl: null,
          type: "ARTICLE",
        };

        if (createStudyArticleDTO.fileId === null) {
          createStudyArticleDTO.fileId = await FileService.sendFile(file);
        }

        if (updateStudyArticleDTO.fileId === null) {
          updateStudyArticleDTO.fileId = await FileService.sendFile(file);
        }

        if (!isEditing) {
          createStudyArticleDTO.fileId = await FileService.sendFile(file);
          result = await api.post("study-articles", createStudyArticleDTO);
          feedbackAction("success", result.data, "add");
          enqueueSnackbar("Artigo criado com sucesso", { variant: "success" });
        } else {
          if (typeof fileControlled !== "number") {
            updateStudyArticleDTO.fileId = await FileService.sendFile(file);
          }
          result = await StudyArticleService.updateStudyArticle({
            updateStudyArticleDTO,
            id: dataToEdit?.id,
          });
          feedbackAction("success", result.data, "edit");
          enqueueSnackbar(t("artigos.artigoEditadoSucesso"), {
            variant: "success",
          });
        }
      } else if (type?.id === "LINK") {
        createStudyArticleDTO = {
          ...commonFields,
          url,
          // embedUrl,
          type: "LINK",
        };
        updateStudyArticleDTO = {
          ...commonFields,
          url,
          // embedUrl,
          type: "LINK",
        };

        if (!isEditing) {
          result = await StudyArticleService.createNewStudyArticle(
            createStudyArticleDTO
          );
          feedbackAction("success", result.data, "add");
          enqueueSnackbar("Artigo criado com sucesso", { variant: "success" });
        } else {
          result = await StudyArticleService.updateStudyArticle({
            updateStudyArticleDTO,
            id: dataToEdit?.id,
          });
          feedbackAction("success", result.data, "edit");
          enqueueSnackbar(t("artigos.artigoEditadoSucesso"), {
            variant: "success",
          });
        }
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const handleClickInput = (event: any, ref) => {
    event.preventDefault();
    ref.current?.click();
  };

  return (
    <S.Form onSubmit={handleSubmit(handleCreateNewStudyArticles)}>
      <ButtonDS
        onClick={() => onBackPress()}
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        style={{ marginTop: '2.3rem' }}
        text={t("artigos.voltar")}
        type="button"
      />

      <S.ContainerDropdown>
        <Controller
          name="type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              selected={value}
              onSelect={(newValue) => onChange(newValue)}
              placeholder={t("artigos.selecioneTipoArtigo")}
              label={t("artigos.selecioneTipoArtigo")}
              options={dataEnum}
              error={errors.type?.message}
            />
          )}
        />
      </S.ContainerDropdown>

      <S.ContainerGap>
        <Controller
          name="name"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <ResponsiveInputText
              {...field}
              placeholder={t("artigos.nome")}
              label={t("artigos.nome")}
              data-cy="inputNameStudyArticle"
              errorMessage={errors.name?.message}
              borderColor={errors.name ? "#F5222D" : "#8C8C8C"}
            />
          )}
        />
      </S.ContainerGap>
      <S.InputRow rowsSize={[2, 2]}>
        <S.ContainerDropdown data-cy="visibilityDropdownSkillTreeForm">
          <Controller
            name="visibility"
            control={control}
            render={({ field: { value, onChange }, }) => (
              <Select
                showSearch
                selected={value}
                onSelect={(newValue) => onChange(newValue)}
                placeholder={t('artigos.visibilidade')}
                label={t('artigos.visibilidade')}
                error={errors?.visibility?.message}
                options={[
                  {
                    id: "PUBLIC",
                    value: t('skills.public'),
                    name: t('skills.public')
                  },
                  {
                    id: "PRIVATE",
                    value: t('skills.private'),
                    name: t('skills.private')
                  },
                  {
                    id: "PROTECTED",
                    value: t('skills.protected'),
                    name: t('skills.protected')
                  }
                ]}
              />
            )}
          />
        </S.ContainerDropdown>
        <S.ContainerGap>
          <Controller
            name="languages"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div>
                {value ?
                  <CheckboxMultiselect options={newOptions} label={t("languages")}
                    onChange={onChange} value={value} />
                  : <CheckboxMultiselect options={newOptions} label={t("languages")}
                    onChange={onChange} value={[]} />}
              </div>
            )}
          />
        </S.ContainerGap>
      </S.InputRow>
      {typeControlled?.value === "Artigo" && (
        <S.ContainerButtonSendFile data-cy="sendFileButton">
          <Controller
            name="file"
            defaultValue={null}
            control={control}
            render={({ field: { onChange, onBlur, name } }) => (
              <div>
                <ButtonDS
                  colorIcon="#333333"
                  background="transparent"
                  fontVariant="body1"
                  pathIcon="upload"
                  rounded={0}
                  size="custom"
                  text={t('artigos.enviarArquivo')}
                  typeOfButton="custom"
                  height={52}
                  width={217}
                  sizeIcon={16}
                  onClick={(e) => handleClickInput(e, fileRef)}
                  errorMessage={errors?.file?.message}
                  borderColor={errors?.file ? "#F5222D" : "#8C8C8C"}
                />
                <input
                  onChange={(e) => onChange(e.target.files[0])}
                  onBlur={onBlur}
                  name={name}
                  type="file"
                  ref={fileRef}
                  multiple={false}
                  hidden
                />
              </div>
            )}
          />
          {typeof fileControlled?.name !== null && !isEditing ? (
            <div>
              <S.ImageDescription>{fileControlled?.name}</S.ImageDescription>
            </div>
          ) : isEditing && typeof fileControlled === "number" ? (
            <div>
              <S.ImageDescription data-cy="nameFile">
                {nameFile.slice(37)}
              </S.ImageDescription>
              <ImageSuccessDS />
            </div>
          ) : isEditing && fileControlled === null ? (
            <div>
              <S.ImageDescription>{fileControlled?.name}</S.ImageDescription>
            </div>
          ) : (
            <S.ImageDescription>{fileControlled?.name}</S.ImageDescription>
          )}
        </S.ContainerButtonSendFile>
      )}
      {typeControlled?.value === "Link" && (
        <div>
          <S.ContainerGap>
            <Controller
              name="url"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <ResponsiveInputText
                  {...field}
                  title="https://firedev.com.br/"
                  placeholder={t("artigos.url")}
                  label={t("artigos.url")}
                  data-cy="inputUrlStudyArticle"
                  errorMessage={errors?.url?.message}
                  borderColor={errors?.url ? "#F5222D" : "#8C8C8C"}
                />
              )}
            />
          </S.ContainerGap>

          {/*<S.ContainerGap>*/}
          {/*  <Controller*/}
          {/*      name="embedUrl"*/}
          {/*      defaultValue=""*/}
          {/*      control={control}*/}
          {/*      render={({ field }) => (*/}
          {/*          <ResponsiveInputText*/}
          {/*              {...field}*/}
          {/*              title="https://firedev.com.br/"*/}
          {/*              placeholder={t("artigos.embedUrl")}*/}
          {/*              label={t("artigos.embedUrl")}*/}
          {/*              data-cy="inputEmbedUrlStudyArticle"*/}
          {/*              errorMessage={errors.embedUrl?.message}*/}
          {/*              borderColor={errors.embedUrl ? "#F5222D" : "#8C8C8C"}*/}
          {/*          />*/}
          {/*      )}*/}
          {/*  />*/}
          {/*</S.ContainerGap>*/}
        </div>
      )}

      <S.ContainerGap>
        <ButtonDS
          type="submit"
          typeOfButton="primary"
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text={isEditing ? "Salvar" : t("artigos.adicionarArtigo")}
          data-cy="addButtonStudyArticle"
        />
      </S.ContainerGap>
    </S.Form>
  );
}
