import { ButtonHTMLAttributes } from "react";
import * as S from "./styles";
import DefaultLoading from "../Loading/defaultLoading";

interface IButton
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
  children: any;
  disable?: boolean;
  onClick?: () => any;
  loading?: boolean;
  hoverDisabled?: boolean;
}

export function Button({
  children,
  disable,
  onClick = () => {},
  loading,
  hoverDisabled,
  ...props
}: IButton) {
  return (
    <S.ContainerButton
      disabled={(disable || loading) && "disabled"}
      hoverDisabled={hoverDisabled}
      onClick={() => !(disable || loading) && onClick()}
      {...props}
      minHeight={props?.style?.height}
    >
      {loading ? <DefaultLoading /> : children}
    </S.ContainerButton>
  );
}
