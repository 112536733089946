import * as React from 'react';
import * as S from "./styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {enqueueSnackbar} from "notistack";
import {t} from "i18next";

interface ICustomSeparator {
  preview?: boolean;
  currentPage: string;
  previousPage: {
    value: string;
    link: string;
  }[]
}

export default function CustomSeparator({preview = false, currentPage, previousPage}: ICustomSeparator) {
  previousPage[0]. value === t("courseDetails.courses") && localStorage.setItem('prevPage', 'trail')
  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small"/>}
        aria-label="breadcrumb"
      >
        {previousPage.map((page, i) => (
          preview ?
            <Link
              underline="hover"
              key={i}
              color="inherit"
              onClick={() => enqueueSnackbar(t("positions.itsPreview"), {variant: "warning"})}
            >
              {page?.value}
            </Link> :
            <Link
              underline="hover"
              key={i}
              color="inherit"
              href={page?.link}
            >
              {page?.value}
            </Link>
        ))}
        <S.ContainerCurrentPage>
          <Typography>
            {currentPage}
          </Typography>
        </S.ContainerCurrentPage>
      </Breadcrumbs>
    </Stack>
  );
}