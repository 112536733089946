import skillHunterPositionIcon from "../assets/skillHunterPositionIcon.png";
import { objectToURLSearchParams } from "../components/utils/http";
import { ILastAccessedFormation } from "../models/LastAccessedFormation";

import api, { axiosApi } from "./api";

class FormationService {
  async getAllFormation(page: number, search: string) {
    search = encodeURIComponent(search);
    const response = await api.get(
      `formation/management?page=${page}&size=5&search=${search}`
    );
    return response.data;
  }

  async getAllFormationPrivate(page: number, search: string) {
    search = encodeURIComponent(search);
    const response = await api.get(
      `formation/management?page=${page}&size=5&search=${search}&visibility=PRIVATE`
    );
    return response.data;
  }

  async getAllFormationPublic(page: number, search: string) {
    search = encodeURIComponent(search);
    const response = await api.get(
      `formation/management?page=${page}&size=5&search=${search}&visibility=PUBLIC`
    );
    return response.data;
  }

  async getAllFormations() {
    const response = await api.get(
      `formation/management?page=&size=&search=`
    );
    return response.data;
  }

  async getSearchFormation(search: string) {
    const response = await api.get(`formation?filter=${search}&page=0&size=5`);
    return response.data;
  }

  async getFormationProgress(page: number, size: number) {
    const response = await api.get(
      `formation/progress?page=${page}&size=${size}`
    );

    return response.data.map((formation) => {
      return {
        id: formation.formationId,
        title: formation.title,
        percentage: formation.percentage,
        company: {
          id: formation.company.id,
          avatar: {
            fileUrl: formation?.company?.avatar ?? skillHunterPositionIcon,
          },
          companyName: formation.company.name,
          tradeName: formation.company.name,
          name: formation.company.name,
          slug: formation.company.slug,
        },
        createdAt: formation?.createdAt,
        slug: formation?.slug,
      };
    });
  }

  async deleteFormation(id: string) {
    await api.delete(`formation/${id}`);
  }

  async findIdFormation(id: number) {
    const response = await api.get(`formation/${id}`);
    return response.data;
  }

  async editFormation(id: number, FormationDTO: any) {
    const response = await api.put(`formation/${id}`, FormationDTO);
    return response.data;
  }

  async addFormation(FormationDTO: any) {
    const response = await api.post("formation", FormationDTO);
    return response;
  }

  async subscribe(formationSlug: string) {
    const response = await api.post(`formation/${formationSlug}/subscribe`);
    return response;
  }

  async getDashboard(formationSlug: string) {
    let response;
    if (localStorage.getItem("token")) {
      response = await api.get(`formation/dashboard/${formationSlug}`);
    } else {
      response = await axiosApi.get(
        `formation/dashboardNoAuth/${formationSlug}`
      );
    }
    return response;
  }

  async getFormationToDropdown(
    visibilityFilter = null,
    skillHunterPool = null
  ) {
    let url = "formation/summary?";
    const queryParams: any = {
      onlyExternalFormations: visibilityFilter,
      skillHunterPool: skillHunterPool,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultFormationToDropdown = await api.get(
      url + objectToURLSearchParams(filteredParams)
    );

    return resultFormationToDropdown.data.map(({ id, title, skillTrees }) => ({
      id,
      value: title,
      skillTrees: skillTrees,
    }));
  }

  formatSalaryValue(salary) {
    if (salary == null) {
      return "";
    }
    const salaryWithoutSymbol = salary.replace("R$", "").trim();
    const salaryWithoutSeparator = salaryWithoutSymbol
      .replaceAll(".", "")
      .replace(",", ".");
    return parseFloat(salaryWithoutSeparator);
  }
  async getAllPositions(
    categorySelectedId,
    stateSelected,
    citySelected,
    countrySelected,
    headerPositionSelected,
    companyId,
    modality,
    positionSelected,
    minSalary,
    maxSalary,
    getNullSalary
  ) {
    const response = await api.get(
      `formation?categoryId=${categorySelectedId?.id || ""
      }&nullSalary=${getNullSalary}&minSalary=${this.formatSalaryValue(
        minSalary
      )}&maxSalary=${this.formatSalaryValue(maxSalary)}&countryId=${countrySelected || ""
      }&stateId=${stateSelected || ""}&cityId=${citySelected || ""
      }&search=${encodeURIComponent(
        headerPositionSelected || positionSelected || ""
      )}${companyId || ""}&modality=${modality?.value || ""}`
    );
    return response.data;
  }

  async getAllPositionsNoAuth(
    categorySelectedId,
    stateSelected,
    citySelected,
    countrySelected,
    headerPositionSelected,
    companyId,
    modality,
    positionSelected,
    minSalary,
    maxSalary,
    getNullSalary
  ) {
    const response = await axiosApi.get(
      `formation/listNoAuthentication?categoryId=${categorySelectedId?.id || ""
      }&nullSalary=${getNullSalary}&minSalary=${this.formatSalaryValue(
        minSalary
      )}&maxSalary=${this.formatSalaryValue(maxSalary)}&countryId=${countrySelected || ""
      }&stateId=${stateSelected || ""}&cityId=${citySelected || ""
      }&search=${encodeURIComponent(
        headerPositionSelected || positionSelected || ""
      )}${companyId || ""}&modality=${modality?.value || ""}`
    );
    return response.data;
  }

  async getBaseFormationsManagement() {
    const result = await api.get(`formation/base`);
    return result.data;
  }

  async getAllFormationsNoAuth(filters?: any) {
    const queryParams: any = {
      search: filters?.search,
      categoryId: filters?.categoryId,
      companyId: filters?.companyId,
      cityId: filters?.cityId,
      stateId: filters?.stateId,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const result = await axiosApi.get(
      `formation/listNoAuthentication?${objectToURLSearchParams(
        filteredParams
      )}`
    );
    return result.data;
  }

  async getLastAccessedFormation(): Promise<ILastAccessedFormation> {
    const result = await api.get(`formation/last-formation`);
    const formation = result.data;
    return {
      id: formation?.formationId,
      title: formation?.title,
      percentage: formation?.percentage,
      company: {
        id: formation?.company?.id,
        avatar: {
          fileUrl: formation?.company?.avatar ?? skillHunterPositionIcon,
        },
        companyName: formation?.company?.name,
        tradeName: formation?.company?.name,
        name: formation?.company?.name,
        emailDomain: "",
        country: undefined,
        slug: formation?.company?.slug,
      },
      skill: formation?.nextSkill,
      slug: formation?.slug,
    };
  }

  async resyncDatabase() {
    const result = await api.get(`reports/resync`);
    return result;
  }

  async resyncFormation(formationId: number) {
    const result = await api.get(
      `reports/resync-formation?formationId=${formationId}`
    );
    return result;
  }

  async formationDropdown() {
    let result;
    if (localStorage.getItem("token")) {
      result = await api.get(`formation/dropdown`);
    } else {
      result = await axiosApi.get(`formation/dropdown`);
    }
    return result?.data;
  }
}

export default new FormationService();
