import * as S from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import {Plus} from "phosphor-react";
import {
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {t} from "i18next";

interface ITableContainerProps {
  search?: string;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  hideFilter?: boolean;
  totalPages?: number;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  onClickAddButton?: () => void;
  open?: (value: boolean) => void;
  withHeader?: boolean;
  children: any;
  hideAddButton?: boolean;
  hidePagination?: boolean;
  headerStartComponent?: React.ElementRef<any>;
  label?: string;
  infoTable?: string;
  rowsPerPage?: number;
  rowsPerPageOptions?: any;
  buttonDisabled?: boolean;
  // onRowsPerPageChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  setRowsPerPage?: any;
  marginTop?: any;
  style?: any;
  totalItems?: any;
  isTrail?: boolean;
  isSolicitation?: boolean;
}

export function TableContainerLayout({
                                       children,
                                       search,
                                       setSearch,
                                       hideFilter,
                                       totalPages,
                                       page,
                                       setPage,
                                       onClickAddButton,
                                       withHeader = true,
                                       hideAddButton = false,
                                       open,
                                       hidePagination = false,
                                       headerStartComponent,
                                       label,
                                       rowsPerPage,
                                       rowsPerPageOptions,
                                       setRowsPerPage,
                                       infoTable,
                                       marginTop = 50,
                                       style = null,
                                       totalItems = null,
                                       isTrail = false,
                                       isSolicitation = false
                                     }: ITableContainerProps) {

  function clickSolicitation() {
    open(true);
  }

  return (
    <div style={style || {width: "100%", marginTop: label ? 13 : marginTop}}>
      {label && (
        <div
          style={{
            display: "flex",
            width: "min-content",
            flexWrap: "nowrap",
            gap: 6,
          }}
        >
          <S.TableLabel>{label}</S.TableLabel>
          {infoTable && (
            <Tooltip arrow placement="top" title={infoTable}>
              <InfoOutlinedIcon sx={{color: "#8C8C8C"}}/>
            </Tooltip>
          )}
        </div>
      )}
      <S.ContainerTableMUI>
        {withHeader && (
          <S.TableHeader>
            <div
              style={{
                display: "flex",
                width: "50%",
                gap: 8,
              }}
            >

              <div>
                <S.ContainerButtonDesktop>
                  {
                    !hideAddButton &&
                    (
                      !isSolicitation
                        ?
                        (
                          <S.ButtonAddDesktop
                            type="button"
                            onClick={onClickAddButton}
                            data-cy="addButton"
                          >
                            {t("users.add")}
                          </S.ButtonAddDesktop>
                        )
                        :
                        (
                          <S.ButtonSolicitationDesktop
                            type="button"
                            onClick={clickSolicitation}
                            data-cy="addButton"
                          >
                            {isTrail ? t("positionsManagement.solicitationTrail") : t("positionsManagement.solicitationPosition")}
                          </S.ButtonSolicitationDesktop>
                        )
                    )
                  }
                </S.ContainerButtonDesktop>

                <S.ButtonAddResponsive type="button" onClick={onClickAddButton}>
                  <Plus size={15}/>
                </S.ButtonAddResponsive>
              </div>
              {headerStartComponent}
            </div>

            {!hideFilter && (
              <div>
                <S.ContainerInputDesktop>
                  <S.CssTextField
                    data-cy="searchField"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    id="custom-css-outlined-input"
                    size="small"
                    sx={{
                      borderRadius: "5px",
                      fontWeight: "700",
                      border: "2px solid #8C8C8C",
                    }}
                    placeholder={t("users.filterTable")}
                    InputLabelProps={{}}
                    InputProps={{
                      style: {
                        height: "54px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon/>
                        </InputAdornment>
                      ),
                    }}
                  />
                </S.ContainerInputDesktop>
                <S.ContainerInputResponsive>
                  <S.CssTextField
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                    id="custom-css-outlined-input"
                    sx={{
                      borderRadius: "5px",
                      fontWeight: "700",
                      border: "2px solid #8C8C8C",
                      width: "8rem",
                    }}
                    placeholder="Pesquisar"
                    InputLabelProps={{}}
                    InputProps={{
                      style: {
                        height: "100%",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon/>
                        </InputAdornment>
                      ),
                    }}
                  />
                </S.ContainerInputResponsive>
              </div>
            )}
          </S.TableHeader>
        )}
        {
          children
        }
        <S.ContainerTableFooter>
          <div></div>
          {!hidePagination && (
            <Pagination
              size="small"
              count={totalPages}
              page={page}
              onChange={(e, newPage) => setPage(newPage)}
            />
          )}
          {rowsPerPage ? (
            <div
              style={{

                display: "flex",
                alignItems: "center",
                gap: 12,
                marginRight: 24,
              }}
            >
              <S.TableRowsInfo>{t("rowsPerPage")}:</S.TableRowsInfo>
              <Select
                variant="standard"
                title={t("rowsPerPage")}
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                {rowsPerPageOptions !== undefined ?
                  rowsPerPageOptions.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))
                  : [10, 25, 50, 100].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))
                }
              </Select>
              {totalItems && (<S.TableRowsInfo>Total de itens: {totalItems}</S.TableRowsInfo>)}
            </div>
          ) : (
            <div></div>
          )}
        </S.ContainerTableFooter>
      </S.ContainerTableMUI>
    </div>
  )
    ;
}
