import {t} from "i18next";
import styled from "styled-components";

export const ContainerEvaluation = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  line-height: 32px;
  margin-bottom: 17px;
`;
export const Subtitle = styled.p`
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
`;

export const UpperFilterContainer = styled.div`
  margin-top: 16px;
`;

export const FormControlTooltipWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: baseline;
  margin-right: 10px;
`;

export const ContainerFilter = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 19px 32px 26px 23px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  margin: 39px 0 31px 0;
`;

export const TitleContainerInputs = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  color: #131212;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 29px;
  margin-top: 12px;
  gap: 28px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  max-height: 42px;
  gap: 1rem;
`;

export const imgArrow = styled.img`
  cursor: pointer;
  height: 16.5px;
  width: 100%;
`;

export const ButtonImg = styled.button`
  margin-left: 5px;
  height: 17px;
  width: 20px;
  border: none;
`;

export const ContainerTableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  color: #131212;
`;

export const ContainerStyleTable = styled.div`
  .titleColumn {
    border: solid black 1px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #131212;
    line-height: 32px;
  }

  .tableRowCell:nth-child(1) {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .tableRowCell:nth-child(1)::before {
    content: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9.00008%200.55957C4.40008%200.55957%200.666748%204.2929%200.666748%208.8929C0.666748%2013.4929%204.40008%2017.2262%209.00008%2017.2262C13.6001%2017.2262%2017.3334%2013.4929%2017.3334%208.8929C17.3334%204.2929%2013.6001%200.55957%209.00008%200.55957ZM4.89175%2014.1262C5.25008%2013.3762%207.43341%2012.6429%209.00008%2012.6429C10.5667%2012.6429%2012.7584%2013.3762%2013.1084%2014.1262C11.9751%2015.0262%2010.5501%2015.5596%209.00008%2015.5596C7.45008%2015.5596%206.02508%2015.0262%204.89175%2014.1262ZM14.3001%2012.9179C13.1084%2011.4679%2010.2167%2010.9762%209.00008%2010.9762C7.78341%2010.9762%204.89175%2011.4679%203.70008%2012.9179C2.85008%2011.8012%202.33341%2010.4096%202.33341%208.8929C2.33341%205.2179%205.32508%202.22624%209.00008%202.22624C12.6751%202.22624%2015.6667%205.2179%2015.6667%208.8929C15.6667%2010.4096%2015.1501%2011.8012%2014.3001%2012.9179V12.9179ZM9.00008%203.8929C7.38341%203.8929%206.08341%205.1929%206.08341%206.80957C6.08341%208.42624%207.38341%209.72624%209.00008%209.72624C10.6167%209.72624%2011.9167%208.42624%2011.9167%206.80957C11.9167%205.1929%2010.6167%203.8929%209.00008%203.8929ZM9.00008%208.05957C8.30841%208.05957%207.75008%207.50124%207.75008%206.80957C7.75008%206.1179%208.30841%205.55957%209.00008%205.55957C9.69175%205.55957%2010.2501%206.1179%2010.2501%206.80957C10.2501%207.50124%209.69175%208.05957%209.00008%208.05957Z%22%20fill%3D%22%238C8C8C%22%2F%3E%0A%3C%2Fsvg%3E%0A);
    position: relative;
    top: 4px;
    right: 4px;
  }

  .tableRowCell:nth-child(1),
  .tableRowCell:nth-child(6) {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .tableRowCell:nth-child(2),
  .tableRowCell:nth-child(3) {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #131212;
  }

  .tableRowCell:nth-child(4) {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #ff026f;
    cursor: pointer;
  }

  .tableRowCell:nth-child(5)[scope="Aprovado"] {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #00a676;
  }

  .tableRowCell:nth-child(5)[scope="Reprovado"] {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #f5222d;
  }

  .tableRowCell:nth-child(5)[scope="Pendente"] {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #aeaeae;
  }

  .pagination {
    p,
    div {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }

    p:nth-of-type(1) {
      visibility: hidden;
    }

    p:nth-of-type(1)::after {
      visibility: visible;
      content: ${t("rowsPerPage")};
    }
  }
`;

export const ContainerSelect = styled.div`
  @media (min-width: 1000px) {
    width: 14rem;
    height: fit-content;
  }
`;
