import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { t } from 'i18next';
import { X } from "phosphor-react";
import { useEffect, useState } from "react";
import arrowDown from "../../pages/PerformanceReport/assets/arrowDown.svg";
import completeIcon from "../../pages/PerformanceReport/assets/completeIcon.svg";
import pendingIcon from "../../pages/PerformanceReport/assets/pendingIcon.svg";
import UserService from "../../services/UserService";
import {convertToLocalDate} from "../utils/masks";
import { ProgressBar } from "../Progress";
import { DetailedChallenger } from "./DetailedChallenger";
import * as S from "./styles";

export function ProgressModal({open, onClose, userId, positionId, courseId, summarizeBy, progressInfo}) {
  const [expanded, setExpanded] = useState<string | false>("");
  const [formation, setFormation] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [openModalChallenger, setOpenModalChallenger] = useState(false);
  const [challengerSkillId, setChallengerSkillId] = useState(null);
  const [competencyName, setCompetencyName] = useState("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const fetchData = async (userId, positionId, courseId) => {
    if (open) {
      if (summarizeBy === 'FORMATIONS') {
        try {
          setLoading(true);
          const result = await UserService.getPerformanceByPosition(userId, positionId);
          setFormation(result);
        } catch (err: any) {
          console.error(err.data.response.message);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const result = await UserService.getPerformanceByCourse(userId, courseId);
          setFormation(result);
        } catch (err: any) {
          console.error(err.data.response.message);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleOpenModalDetailedChallenger = (skill, competence) => {
    if (skill?.status === "WAITING_EVALUATION" || skill?.status === "UNLOCKED") {
      return;
    }
    setCompetencyName(competence.competenceName)
    setChallengerSkillId(+skill?.challengeDTO?.id);
    setOpenModalChallenger(true);
  }

  useEffect(() => {
    fetchData(userId, positionId, courseId)
  }, [open])

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.FullModal>
        <X
          size={15}
          style={{ float: "right", cursor: "pointer" }}
          onClick={onClose}
        />
        {
          loading ?
            <S.Spinner>
              <CircularProgress />
            </S.Spinner> :
            <div>
              <S.FirstContainer>
                <S.Title>{t('performanceModal.title')}</S.Title>
                <S.SubtitleModal>
                  {t('performanceModal.subTitle')}
                </S.SubtitleModal>
                <S.GridSection>
                  <S.ColumnUser>{t('performanceModal.user')}</S.ColumnUser>
                  <S.ColumnFormation>
                    {
                      courseId === undefined ?
                        t('performanceModal.position') :
                        t('performanceModal.course')
                    }
                  </S.ColumnFormation>
                  <S.ColumnCompletedSkills>
                    {t("performanceReport.skills")}
                  </S.ColumnCompletedSkills>
                  <S.ColumnSubscribedAt>
                    {t("performanceReport.startedIn")}
                  </S.ColumnSubscribedAt>
                  <S.ColumnUpdatedAt>
                    {t("performanceReport.lastUpdate")}
                  </S.ColumnUpdatedAt>
                  <S.ColumnProgress>{t('performanceModal.progress')}</S.ColumnProgress>
                  <S.UserName>{formation?.userName}</S.UserName>
                  <S.Position>{formation?.title}</S.Position>
                  <S.CompletedSkills>{formation?.userSkillsAcquired}/{formation?.skillRequiredForCompletion}</S.CompletedSkills>
                  <S.SubscribedAt>{formation?.subscribedAt ? convertToLocalDate(formation?.subscribedAt) : "-"}</S.SubscribedAt>
                  <S.UpdatedAt>{formation?.updatedAt ? convertToLocalDate(formation?.updatedAt) : "-"}</S.UpdatedAt>
                  <S.Progress>
                    <ProgressBar value={formation?.completionPercentage} max={100} />
                  </S.Progress>
                </S.GridSection>
              </S.FirstContainer>
              {formation?.skillTreePerformances?.map(competence => (
                <Accordion
                  disableGutters
                  expanded={expanded === competence?.competenceName}
                  onChange={handleChange(competence?.competenceName)}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, .125)",
                    "&:not(:last-child)": {
                      borderBottom: 0
                    },
                    "&:before": {
                      display: "none"
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img src={arrowDown} alt="" />}
                    aria-controls={`${competence?.competenceName}d-content`}
                    id={`${competence?.competenceName}d-header`}
                    sx={{
                      width: '100%',
                      backgroundColor:
                        "#FFFFFF",
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(180deg)"
                      },
                      "& .MuiAccordionSummary-content": {
                        marginLeft: '0.75rem'
                      }
                    }}
                  >
                    <S.ContainerTitleAndSubtitle>
                      <S.TitleOfAccordionRow>{competence?.competenceName}</S.TitleOfAccordionRow>
                      <S.ContainterToResponsiveness>
                        <S.NumberOfAccordionRow>
                          {competence?.completedSteps}/{competence?.allSteps}
                        </S.NumberOfAccordionRow>
                        <S.Skills>
                          {t('performanceModal.acquiredSkills')}
                        </S.Skills>
                        <S.ContainerOfProgressBarInAccordionRow>
                          <ProgressBar value={competence?.completePercentage} max={100} />
                        </S.ContainerOfProgressBarInAccordionRow>
                      </S.ContainterToResponsiveness>
                    </S.ContainerTitleAndSubtitle>
                  </AccordionSummary>
                  {competence?.skillsPerformance.map(skill => (
                    <AccordionDetails
                      sx={{
                        padding: '0.75rem',
                        borderTop: "1px solid rgba(0, 0, 0, .125)",
                        backgroundColor: "#FFE8E6",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <S.ContainterToResponsiveness onClick={() => handleOpenModalDetailedChallenger(skill, competence)}>
                        <img src={skill?.status === "ACHIEVED" ? completeIcon : pendingIcon} alt="" />
                        <S.ContainerTitleAndSubtitleExpanded>
                          <S.TitleRowExpanded>{skill?.skillName}</S.TitleRowExpanded>
                        </S.ContainerTitleAndSubtitleExpanded>
                        <S.Status status={skill?.status === "ACHIEVED" ? 'complete' : 'pending'}>
                          {skill?.status === "ACHIEVED" ? t('performanceModal.complete') : t('performanceModal.pending')}
                        </S.Status>
                      </S.ContainterToResponsiveness>
                    </AccordionDetails>
                  ))}
                </Accordion>
              ))}
            </div>
        }
        <DetailedChallenger
          open={openModalChallenger}
          onClose={() => setOpenModalChallenger(false)}
          userId={userId}
          challengerId={challengerSkillId}
          userName={formation?.userName}
          competencyName={competencyName}
        />
      </S.FullModal>
    </Modal>
  )
}