import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { t } from "i18next";

function Numeric({ setChange, change, type, questionIndex }: any) {
  const [numeric, setNumeric] = useState(change || "");

  useEffect(() => {
    setNumeric(change || "");
  }, [change]);

  useEffect(() => {
    setNumeric(change || "");
  }, [type, questionIndex]);

  return (
    <S.ContainerText>
      <S.InputNumber
        data-cy="challengePageNumericInput"
        type="number"
        placeholder={t("challenge.writeNumber")}
        onChange={(e) => {
          setNumeric(e.target.value);
          setChange(e.target.value);
        }}
        value={numeric}
      />
    </S.ContainerText>
  );
}

export default Numeric;
