import styled from "styled-components";
import { Button } from "design-system-firedev";
import {TextField} from "@mui/material";

export const ButtonDS = styled(Button)`
  text-align: center;
  //width: 100%;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
`