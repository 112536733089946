import { Button } from "@mui/material";
import styled from "styled-components";
import { TextField, styled as styledMui } from "@mui/material";

export const ButtonAdd = styled(Button)`
  padding-right: 50px;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  color: white !important;
  font-family: "Ubuntu" !important;
`;

export const UpdatedAt = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  span:nth-of-type(2) {
    color: #8c8c8c;
  }
`;

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const ContainerButtonAndIcons = styled.div`
  padding-top: 20px;
  background-color: white;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContainerIcons = styled.div`
  width: 37%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 30px;
  gap: 20px;

  @media (max-width: 768px) {
    width: 400px;
  }

  @media (max-width: 320px) {
    width: 30px;
    gap: 5px;
  }
`;

export const ContainerButton = styled.div`
  padding-left: 20px;

  @media (max-width: 320px) {
    display: none;
  }
`;

export const ContainerResponsiveAdd = styled.div`
  display: none;

  @media (max-width: 320px) {
    width: 30px;
    height: 30px;
    padding-left: 20px;
    display: flex;
    margin-top: 0px;
  }
`;

export const ContainerInput = styled.div`
  input {
    @media (max-width: 320px) {
      width: 80px;
      height: 5px !important;
    }
  }
`;

export const CssTextField = styledMui(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8C8C8C",
    border: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#8C8C8C",
      border: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8C8C8C",
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Ubuntu",
      },
    },
  },
});

export const ContainerTableQuestions = styled.div`
  tbody tr td:not(tbody tr td:nth-last-of-type(4)) {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 250px !important;
    min-width: 250px !important;
  }

  tbody tr td:nth-of-type(4) {
    min-width: 400px !important;
    margin: 0 !important;
    display: flex;
    justify-content: end;
  }

  @media (max-width: 768px) {
    width: 600px;
  }

  @media (max-width: 320px) {
    width: 260px;
  }
`;
export const TableContainer = styled.div``;

export const ContainerTable = styled.div``;

export const ContainerTableFooter = styled.div`
  width: 100%;
  height: 65px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
