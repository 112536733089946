/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { useState } from "react";

import * as S from "./styles";
import { t } from "i18next";
import { Select } from "../../../components/Select";
import UserFormationStatusService from "../../../services/UserFormationStatusService";
import { enqueueSnackbar } from "notistack";

function ModalStatus({ data, formationId, setShowModalStatus, refetchReport }) {
  const options = [
    {
      id: "NOT_EVALUATED",
      value: "Não analisado",
    },
    {
      id: "HIRED",
      value: "Contratado",
    },
    {
      id: "EVALUATION_APPROVED",
      value: "Aprovado",
    },
    {
      id: "IN_EVALUATION",
      value: "Em análise",
    },
    {
      id: "REPROVED",
      value: "Reprovado",
    },
  ];
  const [status, setStatus] = useState<any>(
    data ? options.filter((e) => e.id === data.value)[0] : {}
  );
  const [id, setId] = useState(data ? data.id : null);
  const [loading, setLoading] = useState(false);

  async function save() {
    try {
      setLoading(true);

      await UserFormationStatusService.save(
        {
          status: status.id,
          formationId: formationId,
          userId: data.userId,
        },
        id
      );
      enqueueSnackbar(t("performanceReport.notificationStatusSuccess"), {
        variant: "success",
      });
      setLoading(false);
      setShowModalStatus(false);
      refetchReport();
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(t("performanceReport.notificationStatusError"), {
        variant: "error",
      });
    }
  }
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open
        onClose={() => setShowModalStatus(false)}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <S.ModalContainer>
          <S.ContainerBodyModal>
            <Typography variant="h4">
              {t("performanceReport.modalStatusTitle")}
            </Typography>
            <S.ContainerSelectInput>
              <Select
                selectOne={false}
                selected={status}
                onSelect={(e) => {
                  setStatus(e);
                }}
                style={{ height: 42 }}
                placeholder="Status"
                options={options}
              />
            </S.ContainerSelectInput>
          </S.ContainerBodyModal>
          <S.ContainerButtonsFooterModal>
            <Button
              variant="contained"
              size="large"
              onClick={() => setShowModalStatus(false)}
              sx={{
                background: "#696969",
                color: "white",
                fontWeight: "700",
                width: "100%",
                height: "53px",
              }}
            >
              {t("performanceReport.cancel")}
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => save()}
              sx={{
                background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
                color: "white",
                fontWeight: "700",
                width: "100%",
                height: "53px",
              }}
            >
              {t("performanceReport.confirm")}
            </Button>
          </S.ContainerButtonsFooterModal>
        </S.ModalContainer>
      </Modal>
    </>
  );
}

export default ModalStatus;
