import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "design-system-firedev";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import zod from "zod";
import CheckboxMultiselect from "../../../components/Checkboxes";
import { Multiselect } from "../../../components/Multiselect";
import { ResponsiveInputText } from "../../../components/ResponsiveInputText";
import { Select } from "../../../components/Select";
import { options } from "../../../components/utils/languageOptions";
import SkillService from "../../../services/SkillService";
import api from "../../../services/api";
import Visibility from "../../../types/enums/Visibility";
import { IStudyArticlesFormItem } from "../../StudyArticles/Form";
import * as S from "./styles";

export interface ISkill {
  id: number;
  level?: number;
  position?: number;
  name?: string;
  description?: string;
  content?: string;
  parentId?: any;
  challengeId?: number;
  skillTreeId?: number;
  studyArticles?: IStudyArticlesFormItem[];
  languages?: ILanguages[];
  visibility?: {
    id?: string;
    value?: string;
  };
}

export interface IFormData {
  id: number;
  name: string;
  content: string;
  parent: {
    id: string;
    value: string;
  };
  studyArticles: any;
  languages: any;
  parentId: number | string;
  challengeId: number;
  visibility: {
    id: string;
    value: string;
  };
}

export interface ILanguages {
  name: string;
  value: string;
}

interface ISkillForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: ISkill | undefined;
}

export default function SkillsForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: ISkillForm) {
  const isEditing = typeof dataToEdit !== "undefined";

  const [originalData, setOriginalData] = useState<ISkill>();
  const [
    optionsSelectedDropdownMultiSelect,
    setOptionsSelectedDropdownMultiSelect,
  ] = useState<any[]>([]);

  const [skills, setSkills] = React.useState<any[]>([]);
  const [studyArticles, setStudyArticles] = React.useState<any[]>([]);
  const [searchCategoryDropdown, setSearchCategoryDropdown] = useState("");
  const [loading, setLoading] = useState(true);
  const [localLanguage, setLocalLanguage] = useState(
    localStorage.getItem("language")
  );
  const [newOptions, setNewOptions] = useState(options);

  async function fetchDataById() {
    const result = await api.get(`/v2/skills/${dataToEdit.id}`);
    setOptionsSelectedDropdownMultiSelect(
      result?.data.studyArticles?.map((item: any) => {
        return {
          id: item?.id,
          value: item?.name,
        };
      })
    );
    setOriginalData(result.data);

    setValue("name", result.data.name);
    setValue("content", result.data.content);
    setValue("parent", result.data.parentId);

    setValue("visibility", {
      id: result.data.visibility,
      value: t(`skills.${Visibility[result.data.visibility]}`),
    });
    const newOptionsLanguage = newOptions.filter(
      (option) =>
        result.data.languages.includes(option.value) &&
        option.value !== localLanguage
    );
    setValue("languages", newOptionsLanguage);
  }

  async function fetchData() {
    const skillRest = await SkillService.getSkillToDropdown(searchCategoryDropdown);
    const studyArticlesRest = await api.get(`v2/study-articles/all`);

    // Cria um conjunto temporário para armazenar os valores únicos
    const uniqueStudyArticles = new Set();

    // Itera sobre os dados recebidos e adiciona apenas os valores únicos ao conjunto
    studyArticlesRest.data.forEach((item) => {
      uniqueStudyArticles.add({
        id: item?.id,
        value: item?.name,
      });
    });

    // Converte o conjunto de volta para um array
    const uniqueStudyArticlesArray = Array.from(uniqueStudyArticles);

    setSkills(skillRest);
    setStudyArticles(uniqueStudyArticlesArray);
  }


  useEffect(() => {
    if (dataToEdit?.id) {
      fetchDataById();
    }
  }, [isEditing]);

  useEffect(() => {
    setLocalLanguage(localStorage.getItem("language"));
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    fetchData();
    if (loading) {
      setNewOptions(
        options.filter((language) => language.value !== (localLanguage || "pt"))
      );
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setValue("studyArticles", optionsSelectedDropdownMultiSelect);
  }, [optionsSelectedDropdownMultiSelect]);

  useEffect(() => {
    const fetchData = async () => {
      const skillRest = await SkillService.getSkillToDropdown(
        searchCategoryDropdown
      );

      setSkills(skillRest);
    };

    fetchData();
  }, [searchCategoryDropdown]);

  const handleCreateOrUpdateSkill = async (data) => {
    data.languages = data?.languages?.map((language) => language.value);
    let createSkillDTO = {
      content: data?.content,
      name: data?.name,
      studyArticles: data.studyArticles.map(({ id }) => id),
      visibility: data.visibility.id,
      languages: data.languages || [],
    };

    let updateSkillDTO = {
      content: data?.content,
      name: data?.name,
      studyArticles: data?.studyArticles.map(({ id }) => id),
      visibility: data.visibility.id,
      languages: data.languages || [],
    };

    try {
      let result;
      let submitAction = "add";
      if (isEditing) {
        result = await api.put(`skills/${dataToEdit?.id}`, updateSkillDTO);
        enqueueSnackbar(t("skills.skillSuccessfullyEdited"), {
          variant: "success",
        });
        submitAction = "edit";
      } else {
        result = await api.post(`skills`, createSkillDTO);
        enqueueSnackbar(t("skills.skillSuccessfullyAdded"), {
          variant: "success",
        });
      }

      feedbackAction("success", result?.data, submitAction);
    } catch (err: any) {
      console.error(err);
    }
  };

  const updateSkillFormSchema = zod.object({
    studyArticles: zod
      .object({
        id: zod.number(),
        value: zod.string(),
      })
      .array(),
    name: zod.string().min(1, t("skills.typeInAValidName")),
    content: zod.string().min(1, t("skills.enterAValidContent")),
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t("skills.selectAVisibility") }
    ),
    languages: zod.any().optional().nullable()
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(updateSkillFormSchema),
  });

  return (
    <S.Form onSubmit={handleSubmit(handleCreateOrUpdateSkill)}>
      <Button
        onClick={() => onBackPress()}
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text={t("skills.back")}
        type="button"
      />

      <S.ContainerGap data-cy="nameFieldSkillForm">
        <Controller
          name="name"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <ResponsiveInputText
              {...field}
              label={t("skills.name")}
              placeholder={t("skills.name")}
              errorMessage={errors?.name?.message}
              borderColor={errors?.name ? "#F5222D" : "#8C8C8C"}
              data-cy="skillFormInputName"
            />
          )}
        />
      </S.ContainerGap>

      <S.ContainerGap data-cy="contentFieldSkillForm">
        <Controller
          name="content"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <div>
              <S.Label>{t("skills.content")}</S.Label>
              <S.TextArea
                {...field}
                placeholder={t("skills.content")}
                borderColor={errors?.content}
                data-cy="skillFormInputContent"
              />
            </div>
          )}
        />
        <S.ErrorMessage data-cy="contentErrorMessageSkillForm">
          {errors?.content?.message}
        </S.ErrorMessage>
      </S.ContainerGap>

      <S.InputRow rowsSize={[2, 2]}>
        <S.ContainerDropDownSimpleSelect data-cy="visibilityDropdownSkillTreeForm">
          <Controller
            name="visibility"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                showSearch
                selected={value}
                onSelect={(newValue) => onChange(newValue)}
                placeholder={t("skills.accessToInformation")}
                label={t("skills.accessToInformation")}
                error={errors?.visibility?.message}
                options={Object.keys(Visibility).map((visibility) => {
                  return {
                    id: visibility,
                    value: visibility,
                    name: t(`skills.${Visibility[visibility]}`),
                  };
                })}
              />
            )}
          />
        </S.ContainerDropDownSimpleSelect>
        <S.ContainerGap>
          <Controller
            name="languages"
            control={control}
            render={({ field: { value, onChange } }) => (
              <div>
                {value ?
                  <CheckboxMultiselect options={newOptions} label={t("languages")}
                    onChange={onChange} value={value} />
                  : <CheckboxMultiselect options={newOptions} label={t("languages")}
                    onChange={onChange} value={[]} />}
              </div >
            )
            }
          />
        </S.ContainerGap >
      </S.InputRow >

      <S.ContainerDropDownSimpleSelect data-cy="studyArticlesDropdownSkillForm">
        <Controller
          name="studyArticles"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Multiselect
              selecteds={value}
              placeholder={t("skills.studyArticles")}
              label={t("skills.studyArticles")}
              onSelect={(newValue) => {
                onChange(newValue);
                setOptionsSelectedDropdownMultiSelect(newValue);
              }}
              options={studyArticles}
              error={errors?.studyArticles?.message}
            />
            // <CheckboxMultiselect control={control} options={studyArticles.map(studyArticle => ({ id: studyArticle.id, name: studyArticle.value }))} label={t("skills.studyArticles")} fieldName={"studyArticles"}/>
          )}
        />
      </S.ContainerDropDownSimpleSelect>

      <Button
        type="submit"
        typeOfButton="primary"
        size="custom"
        height={52}
        width={217}
        fontVariant="body1"
        onClick={handleSubmit}
        text={isEditing ? t("skills.save") : t("skills.add")}
        data-cy="submitButtonSkillForm"
      />
    </S.Form >
  );
}
