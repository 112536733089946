import {objectToURLSearchParams} from "../components/utils/http";
import api from "./api";

class SkillService {
  async getSkill() {
    const resultSkills = await api.get("skills");
    return resultSkills.data;
  }

  async getSkillModal() {
    const resultSkills = await api.get("skills/modal");
    return resultSkills.data;
  }

  async getAverageTime(challengeId) {
    const resultSkills = await api.get(`challenges/average-time/${challengeId}`);
    return resultSkills.data;
  }

  async getSearchSkill(page: number, search: string, size = 5) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `skills/skill-page?page=${page}&size=${size}&filter=${filter}`
    );
    return response.data;
  }

  async getSearchSkillManagement(page: number, search: string, size = 5) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `v2/skills/skill-page?page=${page}&size=${size}&filter=${filter}`
    );
    return response.data;
  }

  async getSkillToDropdownOnlyParent(search: string) {
    const filter = encodeURIComponent(search);
    const resultSkillToDropdown = await api.get(
      `skills/parent/summary?filter=${filter}&size=1000&sort=id%2Cdesc`
    );
    return resultSkillToDropdown.data.content.map(({id, name}) => ({
      id,
      value: name,
    }));
  }

  async getSkillToDropdown(search: string, visibilityFilter = null, skillHunterPool = null) {
    let url = "skills/summary?";
    const queryParams: any = {
      onlyExternalFormations: visibilityFilter,
      skillHunterPool: skillHunterPool,
      filter: search
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultSkillToDropdown = await api.get(url + objectToURLSearchParams(filteredParams));

    return resultSkillToDropdown.data.map(({id, name}) => ({
      id,
      value: name,
    }));
  }

  async getSkillToDropdownAquarium(trailId, courseId, competenceId,  visibilityFilter = null, skillHunterPool = null, filter = "") {
    let url = "/v2/skills/summary-aquarium?";
    const queryParams: any = {
      onlyExternalFormations: visibilityFilter,
      skillHunterPool: skillHunterPool,
      courseId: courseId,
      trailId: trailId,
      competenceId: competenceId,
      filter
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultSkillToDropdown = await api.get(url + objectToURLSearchParams(filteredParams || ""));

    return resultSkillToDropdown.data;
  }


  async getAllSummary(search: string) {
    const filter = encodeURIComponent(search);
    const resultSkillToDropdown = await api.get(
      `skills/all-summary?filter=${filter}`
    );
    return resultSkillToDropdown.data?.map(({id, name}) => ({
      id,
      value: name,
    }));
  }

  async getSkillToMultiSelect(search: string) {
    const filter = encodeURIComponent(search);
    const resultSkillToDropdown = await api.get(
      `v2/skills/summary?filter=${filter}`
    );
    return resultSkillToDropdown.data;
  }

  async findSkillId(id: string) {
    const response = await api.get(`skills/${id}`);
    return response.data;
  }

  async getSkillStatus(id: string) {
    const response = await api.get(`skills/status/${id}`);
    return response.data;
  }

  async getAllSkills(page: number) {
    const response = await api.get(`skills/skill-page?page=${page}&size=5`);
    return response;
  }

  async deleteSkill(id: string) {
    await api.delete(`skills/${id}`);
  }

  async alterSkill(id: string) {
    await api.put(`skills/${id}`);
  }

  async addSkill(skill: string, SkillDTO: any) {
    const response = await api.post(skill, SkillDTO);
    return response;
  }
}

export default new SkillService();
