/* eslint-disable dot-notation */
import EqualizerIcon from "@mui/icons-material/Equalizer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { t } from "i18next";
import { ArrowsDownUp, SortAscending, SortDescending } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultUserIcon from "../../assets/defaultUserIcon.png";
import { Button } from "../../components/Button";
import { ProgressBar } from "../../components/Progress";
import { TableContainerLayout } from "../../components/TableContainerLayout";
import { colors } from "../../components/utils/colors";
import { generateQueryParamsStringFilterReportPageWithPool } from "../../components/utils/http";
import { noAccents } from "../../components/utils/masks";
import {
  getAllAccountants,
  getSuccessRate,
  getTopPositions,
  getTopUsers,
  summaryUsers,
} from "../../services/DashboardService";
import { ReportType, SummaryBy } from "../../services/ReportService";
import * as S from "./styles";

const usersDataBase = {
  activeUsers: 0,
  subscribedUsers: 0,
  createdChallenges: 0,
  registeredUsers: 0,
};

const positionChallengeBase = {
  countAllFormations: 0,
  countPrivateFormations: 0,
  countProtectedFormations: 0,
  countPublicFormations: 0,
  percentagePrivateFormations: 0,
  percentageProtectedFormations: 0,
  percentagePublicFormations: 0,
};

function filterPositions(data, filter) {
  if (filter?.length > 0) {
    const newFilter = noAccents(filter)?.toUpperCase();
    const p: any = Array.from(newFilter).reduce(
      (a: any, v, i) => `${a}[^${newFilter.substr(i)}]*?${v}`,
      ""
    );
    const re = RegExp(p);

    return data.filter((v) =>
      noAccents(v?.position?.title)?.toUpperCase()?.match(re)
    );
  }
  return data;
}

function filterChallenge(data, filter) {
  if (filter?.length > 0) {
    const newFilter = noAccents(filter)?.toUpperCase();
    const p: any = Array.from(newFilter).reduce(
      (a: any, v, i) => `${a}[^${newFilter.substr(i)}]*?${v}`,
      ""
    );
    const re = RegExp(p);

    return data.filter((v) =>
      noAccents(v?.challenge?.title)?.toUpperCase()?.match(re)
    );
  }
  return data;
}

const ordenation = [
  {
    type: "default",
    icon: ArrowsDownUp,
  },
  {
    type: "ascend",
    icon: SortAscending,
  },
  {
    type: "descend",
    icon: SortDescending,
  },
];

function handleSort(sort) {
  switch (sort?.type) {
    case "default":
      return ordenation?.[1];
    case "ascend":
      return ordenation?.[2];
    case "descend":
      return ordenation?.[0];
  }
}

function sort(data, sort, string) {
  if (sort?.type !== "default") {
    return data?.sort((a: any, b: any) => {
      if (sort?.type === "ascend") {
        return a?.[string] > b?.[string] ? 1 : -1;
      } else {
        return b?.[string] > a?.[string] ? 1 : -1;
      }
    });
  }

  return data;
}

function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState(usersDataBase);
  const [topUsers, setTopUsers] = useState([]);
  const [positionChallenge, setPositionChallenge] = useState(
    positionChallengeBase
  );

  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<any>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  // ? Tabela Sucesso de desafios
  const [searchChallenge, setSearchChallenge] = useState(
    JSON.parse(localStorage.getItem("searchChallenge")) || ""
  );
  const [sortRateChallenge, setSortRateChallenge] = useState(
    ordenation?.find(
      (item) =>
        item?.type ===
        JSON.parse(localStorage.getItem("sortRateChallenge"))?.type
    ) || ordenation?.[0]
  );
  const [rowsChallenge, setRowsChallenge] = useState([]);
  const [pageChallenge, setPageChallenge] = useState(
    +JSON.parse(localStorage.getItem("pageChallenge")) || 1
  );
  const newRowsChallenge = filterChallenge(
    sort([...rowsChallenge], sortRateChallenge, "successRate"),
    searchChallenge
  );
  const totalPagesChallenge = Math.ceil(newRowsChallenge.length / 5);
  const rowsChallengeFiltered = [...newRowsChallenge].splice(
    (pageChallenge - 1) * 5,
    5
  );

  // ? Tabela Top cargos
  const [searchPosition, setSearchPosition] = useState(
    JSON.parse(localStorage.getItem("searchPosition")) || ""
  );
  const [positionType, setPositionType] = useState<
    "PUBLIC" | "PRIVATE" | "PROTECTED"
  >(JSON.parse(localStorage.getItem("positionType")) || "PUBLIC");
  const [sortRatePosition, setSortRatePosition] = useState(
    ordenation?.find(
      (item) =>
        item?.type ===
        JSON.parse(localStorage.getItem("sortRatePosition"))?.type
    ) || ordenation?.[0]
  );
  const [rowsPosition, setRowsPosition] = useState([]);
  const [pagePosition, setPagePosition] = useState(
    +JSON.parse(localStorage.getItem("pagePosition")) || 1
  );
  const newRowsPosition = filterPositions(
    sort([...rowsPosition], sortRatePosition, "qualifiedCount"),
    searchPosition
  );
  const totalPagesPosition = Math.ceil(newRowsPosition.length / 5);
  const rowsPositionFiltered = [...newRowsPosition].splice(
    (pagePosition - 1) * 5,
    5
  );

  function navigateReportPage({
    userId,
    userName,
    formationId,
    sk,
    filterCompany,
    filterType,
  }: any) {
    localStorage.setItem("searchPosition", JSON.stringify(searchPosition));
    localStorage.setItem("searchChallenge", JSON.stringify(searchChallenge));

    localStorage.setItem("pagePosition", JSON.stringify(pagePosition));
    localStorage.setItem("pageChallenge", JSON.stringify(pageChallenge));

    localStorage.setItem("sortRatePosition", JSON.stringify(sortRatePosition));
    localStorage.setItem(
      "sortRateChallenge",
      JSON.stringify(sortRateChallenge)
    );

    localStorage.setItem("positionType", JSON.stringify(positionType));

    localStorage.setItem("fromDashboard", "1");

    const queryParams = generateQueryParamsStringFilterReportPageWithPool(
      sk ? SummaryBy.Skill : SummaryBy.Formation,
      formationId || null,
      userId || null,
      userName || null,
      formationId ? ReportType.SkillFocus : ReportType.UserFocus,
      filterCompany || null,
      filterType || null
    );
    navigate(`/management/performance-report?${queryParams}`);
  }

  const getView = (status: any) => {
    switch (status?.toUpperCase()) {
      case "PUBLIC":
        return t("dashboard.public");
      case "PRIVATE":
        return t("dashboard.private");
      case "PROTECTED":
        return t("dashboard.protected");
      default:
        return t("dashboard.public");
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [usersData, allAccountants, usersTop, successRate] =
          await Promise.all([
            summaryUsers(),
            getAllAccountants(),
            getTopUsers(),
            getSuccessRate(),
          ]);

        // ! WITHOUT PAGINATION
        setUsersData(usersData);
        setPositionChallenge(allAccountants);
        setTopUsers(usersTop);

        // ! WITH PAGINATION
        setRowsChallenge(successRate);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const positionsTop = await getTopPositions(positionType);

      setRowsPosition(positionsTop);
    })();
  }, [positionType]);

  return (
    <S.ContainerFullPage>
      <S.TitlePage>{t("dashboard.dashboard")}</S.TitlePage>
      <S.Subtitle data-cy="dashboardScreenSubTitle">
        {t("dashboard.seeRelevantInformationAboutUsersAndPosicions")}
      </S.Subtitle>
      <S.ContainerAllCards>
        <S.ContainerTwoCards>
          <S.ContainerCards>
            <S.ContainerLabel>{t("dashboard.users")}</S.ContainerLabel>
            {(loading && (
              <>
                <Skeleton variant="rectangular" width="100%" />
                <Skeleton
                  variant="rectangular"
                  sx={{ marginTop: 2 }}
                  width="100%"
                />
              </>
            )) || (
              <S.ContainerTextsCard>
                <S.ContainerTextsNumberCard>
                  <S.NumberCard>{usersData?.registeredUsers}</S.NumberCard>
                  <S.TextCard>{t("dashboard.registeredUsers")}</S.TextCard>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("dashboard.companyLinkedUsers")}
                  >
                    <InfoOutlinedIcon sx={{ color: "#8C8C8C" }} />
                  </Tooltip>
                </S.ContainerTextsNumberCard>
                <S.ContainerTextsNumberCard>
                  <S.NumberCard>{usersData?.subscribedUsers}</S.NumberCard>
                  <S.TextCard>{t("dashboard.registeredCandidates")}</S.TextCard>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      "dashboard.candidatesRegisteredForCompanyPositions"
                    )}
                  >
                    <InfoOutlinedIcon sx={{ color: "#8C8C8C" }} />
                  </Tooltip>
                </S.ContainerTextsNumberCard>
                <S.ContainerTextsNumberCard>
                  <S.NumberCard>{usersData?.activeUsers}</S.NumberCard>
                  <S.TextCard>{t("dashboard.activeUsers")}</S.TextCard>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("dashboard.usersWhoHaveDoneChallengesLastMonth")}
                  >
                    <InfoOutlinedIcon sx={{ color: "#8C8C8C" }} />
                  </Tooltip>
                </S.ContainerTextsNumberCard>
                <S.ContainerTextsNumberCard>
                  <S.NumberCard>{usersData?.createdChallenges}</S.NumberCard>
                  <S.TextCard>{t("dashboard.challengeCreated")}</S.TextCard>
                </S.ContainerTextsNumberCard>
              </S.ContainerTextsCard>
            )}
          </S.ContainerCards>

          <S.ContainerCardBase>
            <S.PaddingHorizontal>
              <S.ContainerLabel>
                {t("dashboard.positionsAndChallenges")}
              </S.ContainerLabel>
            </S.PaddingHorizontal>
            {(loading && (
              <>
                <S.PaddingHorizontal>
                  <Skeleton variant="rectangular" width="100%" />
                </S.PaddingHorizontal>{" "}
                <S.PaddingHorizontal>
                  <Skeleton
                    variant="rectangular"
                    sx={{ marginTop: 2 }}
                    width="100%"
                  />
                </S.PaddingHorizontal>
                {/*<S.PaddingHorizontal>*/}
                {/*  <Skeleton*/}
                {/*    variant="rectangular"*/}
                {/*    sx={{ marginTop: 2 }}*/}
                {/*    width="100%"*/}
                {/*  />*/}
                {/*</S.PaddingHorizontal>*/}
                {/*<S.PaddingHorizontal>*/}
                {/*  <Skeleton*/}
                {/*    variant="rectangular"*/}
                {/*    sx={{ marginTop: 2, marginBottom: 2 }}*/}
                {/*    width="100%"*/}
                {/*  />*/}
                {/*</S.PaddingHorizontal>*/}
              </>
            )) || (
              <>
                {/*<S.ContainerTextsNumberCardBase>*/}
                {/*  <S.PaddingCargoDesafio isFirst>*/}
                {/*    <S.NumberCard>*/}
                {/*      {positionChallenge?.countAllFormations}*/}
                {/*    </S.NumberCard>*/}
                {/*    <S.TextCardNoWrap>*/}
                {/*      {t("dashboard.createdPositionsMin")}*/}
                {/*    </S.TextCardNoWrap>*/}
                {/*  </S.PaddingCargoDesafio>*/}
                {/*</S.ContainerTextsNumberCardBase>*/}
                <S.ContainerTextsNumberCardBase>
                  <S.PaddingCargoDesafio>
                    <S.NumberCard>
                      {positionChallenge?.countPublicFormations}
                    </S.NumberCard>
                    <S.TextCardNoWrap>
                      {t("dashboard.publicPositionsMin")}
                    </S.TextCardNoWrap>
                  </S.PaddingCargoDesafio>
                  <ProgressBar
                    value={Math.round(
                      positionChallenge?.percentagePublicFormations || 0
                    )}
                  />
                </S.ContainerTextsNumberCardBase>
                <S.ContainerTextsNumberCardBase>
                  <S.PaddingCargoDesafio>
                    <S.NumberCard>
                      {positionChallenge?.countPrivateFormations}
                    </S.NumberCard>
                    <S.TextCardNoWrap>
                      {t("dashboard.privatePositionsMin")}
                    </S.TextCardNoWrap>
                  </S.PaddingCargoDesafio>
                  <ProgressBar
                    value={Math.round(
                      positionChallenge?.percentagePrivateFormations || 0
                    )}
                  />
                </S.ContainerTextsNumberCardBase>
                {/*<S.ContainerTextsNumberCardBase isLast>*/}
                {/*  <S.PaddingCargoDesafio>*/}
                {/*    <S.NumberCard>*/}
                {/*      {positionChallenge?.countProtectedFormations}*/}
                {/*    </S.NumberCard>*/}
                {/*    <S.TextCardNoWrap>*/}
                {/*      {t("dashboard.protectedPositionsMin")}*/}
                {/*    </S.TextCardNoWrap>*/}
                {/*  </S.PaddingCargoDesafio>*/}
                {/*  <ProgressBar*/}
                {/*    value={Math.round(*/}
                {/*      positionChallenge?.percentageProtectedFormations || 0*/}
                {/*    )}*/}
                {/*  />*/}
                {/*</S.ContainerTextsNumberCardBase>*/}
              </>
            )}
          </S.ContainerCardBase>
        </S.ContainerTwoCards>
        {/* <S.ContainerCardTopUsers>
           <S.PaddingHorizontal>
             <S.ContainerLabel>{t("dashboard.topUsers")}</S.ContainerLabel>
             <Tooltip
               arrow
               placement="top"
               title={t("dashboard.contributorsWithTheMostFinishedPositions")}
             >
               <InfoOutlinedIcon sx={{ color: "#8C8C8C" }} />
             </Tooltip>
           </S.PaddingHorizontal>
           <S.UsersSeparator>
             {(loading && (
               <S.PaddingHorizontal>
                 <div
                   style={{
                     flexDirection: "column",
                     display: "flex",
                     justifyContent: "center",
                   }}
                 >
                   <Skeleton
                     animation="wave"
                     variant="circular"
                     width={40}
                     height={40}
                   />
                 </div>

                 <div
                   style={{
                     display: "flex",
                     flexDirection: "column",
                     width: "100%",
                   }}
                 >
                   <Skeleton
                     variant="rectangular"
                     sx={{ marginTop: 2, marginBottom: 2 }}
                     width="100%"
                   />
                   <Skeleton
                     variant="rectangular"
                     sx={{ marginBottom: 2 }}
                     width="70%"
                   />
                 </div>
               </S.PaddingHorizontal>
             )) || (
               <>
                 {topUsers?.map((item) => (
                   <S.PaddingHorizontal>
                     <S.UserContainer>
                       <S.UserImage src={item?.avatar || defaultUserIcon} />
                       <S.UserTextsContainer>
                         <Tooltip
                           arrow
                           placement="top"
                           title={
                             item?.skills == 0
                               ? t(
                                   "dashboard.reportUnavailableUserChallengesNotCompleted"
                                 )
                               : t("dashboard.visualizeUserReportOnPosition")
                           }
                         >
                           <S.UserName
                             underline={item?.skills == 0 && false}
                             onClick={() =>
                               item?.skills > 0 &&
                               navigateReportPage({
                                 userId: item?.id,
                                 userName: item?.user,
                                 sk: item?.formations == 0,
                                 filterCompany: 1,
                                 filterType: 2,
                               })
                             }
                           >
                             {item?.user}
                           </S.UserName>
                         </Tooltip>

                         <S.UserDesc>
                           <span>{item?.formations}</span>{" "}
                           {t("dashboard.finishedPosition")} <br />
                           <span>{item?.skills}</span>{" "}
                           {t("dashboard.finishedAbilities")}
                         </S.UserDesc>
                       </S.UserTextsContainer>
                     </S.UserContainer>
                     <Tooltip
                       arrow
                       placement="top"
                       title={
                         item?.skills == 0
                           ? t(
                               "dashboard.reportUnavailableUserChallengesNotCompleted"
                             )
                           : t("dashboard.visualizeUserReportOnPosition")
                       }
                     >
                       <IconButton
                         sx={{
                           opacity: item?.skills == 0 ? 0.6 : 1,
                           width: 39,
                           height: 39,
                           alignSelf: "center",
                         }}
                         disableRipple={item?.skills == 0}
                         onClick={() =>
                           item?.skills > 0 &&
                           navigateReportPage({
                             userId: item?.id,
                             userName: item?.user,
                             sk: item?.formations == 0,
                             filterCompany: 1,
                             filterType: 2,
                           })
                         }
                       >
                         <EqualizerIcon />
                       </IconButton>
                     </Tooltip>
                   </S.PaddingHorizontal>
                 ))}
                 {topUsers?.length === 0 && !loading && (
                   <S.NoData>{t("dashboard.noUserFound")}</S.NoData>
                 )}
               </>
             )}
           </S.UsersSeparator>

          <S.CardButtonContainer>
            <Button
              style={{
                background: "#fff",
                color: "#131212",
                border: "2px solid #131212",
                opacity: topUsers?.length === 0 ? 0.6 : 1,
              }}
              disable={topUsers?.length === 0}
              onClick={() => navigate("/management/performance-report")}
            >
              {t("dashboard.generalPerformance")}
            </Button>
          </S.CardButtonContainer>
        </S.ContainerCardTopUsers>*/}
      </S.ContainerAllCards>

      <TableContainerLayout
        search={searchPosition}
        setSearch={(value) => {
          if (pagePosition !== 1) {
            setPagePosition(1);
          }
          setSearchPosition(value);
        }}
        page={pagePosition}
        setPage={setPagePosition}
        totalPages={totalPagesPosition}
        hideAddButton
        label={t("dashboard.topPositions")}
        infoTable={t("dashboard.onlyPositionsWithQualifiedCandidatesDisplayed")}
        headerStartComponent={
          <Select
            value={positionType}
            onChange={(e: any) => {
              setPagePosition(1);
              setPositionType(e?.target?.value);
            }}
            defaultValue="PUBLIC"
            sx={{ width: "100%", maxWidth: 300 }}
          >
            {[
              { label: t("dashboard.publicPositionsMax"), value: "PUBLIC" },
              { label: t("dashboard.privatePositionsMax"), value: "PRIVATE" },
              {
                label: t("dashboard.protectedPositionsMax"),
                value: "PROTECTED",
              },
            ].map((item) => (
              <MenuItem value={item?.value}>{item?.label}</MenuItem>
            ))}
          </Select>
        }
      >
        <TableContainer sx={{ width: "100%", background: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("dashboard.position")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("dashboard.visualization")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.ContainerUpDown
                    onClick={() =>
                      setSortRatePosition(handleSort(sortRatePosition))
                    }
                  >
                    <S.FontHeaderTable>
                      {t("dashboard.qualification")}
                    </S.FontHeaderTable>
                    {React.createElement(sortRatePosition?.icon, {
                      size: 19,
                    })}
                  </S.ContainerUpDown>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("dashboard.majorFit")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <p>{null}</p>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rowsPositionFiltered?.length > 0 &&
                rowsPositionFiltered?.map((item, i) => (
                  <TableRow key={item?.id} id={`tableRowTopCargos-${i}`}>
                    <Tooltip
                      arrow
                      placement="top"
                      PopperProps={{
                        popperRef,
                        anchorEl: {
                          getBoundingClientRect: () => {
                            return new DOMRect(
                              positionRef.current.x,
                              document
                                .getElementById(`tableRowTopCargos-${i}`)
                                .getBoundingClientRect().y,
                              0,
                              0
                            );
                          },
                        },
                      }}
                      followCursor
                      title={t("dashboard.visualizeReportCreatedByPosition")}
                    >
                      <TableCell
                        sx={{
                          color: colors.primaryPink,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onMouseMove={handleMouseMove}
                        onClick={() =>
                          navigateReportPage({
                            formationId: item?.position?.id,
                            filterCompany: 1,
                            filterType: 2,
                          })
                        }
                      >
                        <p style={{ height: "100%" }}>
                          {item?.position?.title}
                        </p>
                      </TableCell>
                    </Tooltip>
                    <TableCell>{getView(item?.visibility)}</TableCell>
                    <TableCell>
                      {item?.qualifiedCount || 0} {t("dashboard.users")}
                    </TableCell>
                    <Tooltip
                      arrow
                      placement="top"
                      PopperProps={{
                        popperRef,
                        anchorEl: {
                          getBoundingClientRect: () => {
                            return new DOMRect(
                              positionRef.current.x,
                              document
                                .getElementById(`tableRowTopCargos-${i}`)
                                .getBoundingClientRect().y,
                              0,
                              0
                            );
                          },
                        },
                      }}
                      title={t("dashboard.visualizeUserReportOnPosition")}
                    >
                      <TableCell
                        onMouseMove={handleMouseMove}
                        sx={{
                          // color: colors.primaryPink,
                          // textDecoration: !(item?.bestFit?.id >= 0)
                          //   ? "none"
                          //   : "underline",
                          // cursor: !(item?.bestFit?.id >= 0)
                          //   ? "default"
                          //   : "pointer",
                        }}
                        // onClick={() =>
                        //   item?.bestFit?.id >= 0 &&
                        //   navigateReportPage({
                        //     userId: item?.bestFit?.id,
                        //     userName: item?.user,
                        //     filterCompany: 1,
                        //     filterType: 2,
                        //   })
                        // }
                      >
                        {item?.bestFit?.name || "-"}
                      </TableCell>
                    </Tooltip>
                    <TableCell sx={{ textAlign: "right" }}>
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          !(item?.bestFit?.id >= 0)
                            ? t(
                                "dashboard.reportUnavailableNoUserCompletedPosition"
                              )
                            : t("dashboard.visualizeReportCreatedByPosition")
                        }
                      >
                        <IconButton
                          sx={{
                            opacity: !(item?.bestFit?.id >= 0) ? 0.6 : 1,
                          }}
                          disableRipple={!(item?.bestFit?.id >= 0)}
                          onClick={() =>
                            item?.bestFit?.id >= 0 &&
                            navigateReportPage({
                              formationId: item?.position?.id,
                              filterCompany: 1,
                              filterType: 2,
                            })
                          }
                        >
                          <EqualizerIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}

              {rowsPositionFiltered?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                    {t("dashboard.noResultsFound")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainerLayout>

      <TableContainerLayout
        search={searchChallenge}
        setSearch={(value) => {
          if (pageChallenge !== 1) {
            setPageChallenge(1);
          }
          setSearchChallenge(value);
        }}
        page={pageChallenge}
        setPage={setPageChallenge}
        totalPages={totalPagesChallenge}
        label={t("dashboard.chalengesSuccess")}
        infoTable={t("dashboard.challengeWithTheHighestSuccessRate")}
        hideAddButton
      >
        <TableContainer sx={{ width: "100%", background: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("dashboard.challenge")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("dashboard.usersThatMadeTheChallenge")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.ContainerUpDown
                    onClick={() =>
                      setSortRateChallenge(handleSort(sortRateChallenge))
                    }
                  >
                    <S.FontHeaderTable style={{ whiteSpace: "nowrap" }}>
                      {t("dashboard.successRate")}
                    </S.FontHeaderTable>
                    {React.createElement(sortRateChallenge?.icon, {
                      size: 19,
                    })}
                  </S.ContainerUpDown>
                </TableCell>
                <TableCell>
                  <p>{null}</p>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(loading && (
                <>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Skeleton variant="rectangular" height={30} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Skeleton variant="rectangular" height={30} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Skeleton variant="rectangular" height={30} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Skeleton variant="rectangular" height={30} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      <Skeleton variant="rectangular" height={30} />
                    </TableCell>
                  </TableRow>
                </>
              )) || (
                <>
                  {rowsChallengeFiltered?.length > 0 &&
                    rowsChallengeFiltered?.map((item) => (
                      <TableRow key={item?.id}>
                        <TableCell sx={{ width: "30%" }}>
                          {item?.challenge?.title}
                        </TableCell>
                        <TableCell>
                          {item?.usersCount || 0} {t("dashboard.users")}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            {Math.round(item?.successRate || 0)}%
                            <ProgressBar
                              style={{ width: 120 }}
                              isChallenge
                              value={item?.successRate || 0}
                            />
                          </div>
                        </TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          <Tooltip
                            arrow
                            placement="top"
                            title={t("dashboard.seeReportFilteredByAbility")}
                          >
                            <IconButton
                              onClick={() =>
                                navigateReportPage({
                                  formationId: item?.challenge?.skill?.id,
                                  sk: true,
                                  filterCompany: 1,
                                  filterType: 2,
                                })
                              }
                            >
                              <EqualizerIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}

              {rowsChallengeFiltered?.length === 0 && !loading && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    {t("dashboard.noResultsFound")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainerLayout>
    </S.ContainerFullPage>
  );
}

export default Dashboard;
