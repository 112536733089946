/* eslint-disable react/no-unstable-nested-components */
import { InfoOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TableContainerLayout } from "../../components/TableContainerLayout";
import FormationService from "../../services/FormationService";
import PlansForm from "./form";
import * as S from "./styles";
import { PositionTable } from "./positionTable";
import { SuggestPositionModal } from "../../components/SuggestPositionModal";
import { SolicitationScreen } from "../Solicitation";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Plans() {
  const userAuthority = JSON.parse(localStorage.getItem("userRoles"))[0].authority;
  const [action, setAction] = useState<{
    data?: any;
    type?: "add" | "edit" | undefined;
  }>({ type: undefined });

  const [search, setSearch] = useState<string>("");
  const [pagePrivate, setPagePrivate] = useState<number>(1);
  const [pagePublic, setPagePublic] = useState<number>(1);
  const [totalPagesPrivate, setTotalPagesPrivate] = useState<number>(0);
  const [totalPagesPublic, setTotalPagesPublic] = useState<number>(0);
  const [positionPublic, setPositionPublic] = useState([]);
  const [positionPrivate, setPositionPrivate] = useState([]);
  const [edited, setEdited] = useState(false);
  const isSysAdmin = userAuthority === "ROLE_SYSADMIN";
  const [openSuggestPositionModal, setOpenSuggestPositionModal] = useState(false)

  const stylesTabs = S.TabStyled();

  const [value, setValue] = useState(0);

  function Checkbox({ visible }) {
    return (
      <S.CheckboxOutCircle selected={visible}>
        {visible && (
          <CheckIcon
            sx={{ fontSize: 16, color: "#fff" }}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              padding: 0,
              margin: 0,
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </S.CheckboxOutCircle>
    );
  }

  async function allFormationsPrivate(pageNumber: number, search: string) {
    const response = await FormationService.getAllFormationPrivate(pageNumber, search);
    setPositionPrivate(response?.content || []);
    setTotalPagesPrivate(response?.totalPages);
  }

  async function allFormationsPublic(pageNumber: number, search: string) {
    const response = await FormationService.getAllFormationPublic(pageNumber, search);
    setPositionPublic(response?.content || []);
    setTotalPagesPublic(response?.totalPages);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0 && newValue !== value) {
      setValue(newValue);
      setPagePublic(1)
      allFormationsPublic(pagePublic - 1, search);
    }

    if (newValue === 1 && newValue !== value) {
      setPagePrivate(1)
      setValue(newValue);
      allFormationsPrivate(pagePrivate - 1, search);
    }
  };

  const handleFeedbackActionFormationPublic = (type: string, submitAction: string) => {
    setEdited(submitAction === "edit");
    allFormationsPublic(pagePublic - 1, search)

  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      allFormationsPublic(pagePublic - 1, search);
      allFormationsPrivate(pagePrivate - 1, search);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search, pagePrivate, pagePublic]);

  const copyLink = (data) => {
    const baseUrl = window.location.origin;
    navigator.clipboard
      .writeText(
        baseUrl + "/position/" + data?.company?.slug + "/" + data?.slug
      )
      .then(() => {
        enqueueSnackbar(t("positionsManagement.copiedToClipboard"), {
          variant: "success",
        });
      });
  };



  const valueDisplay = action?.type === "add" || action?.type === "edit" || isSysAdmin ? "none" : "flex";


  return (
    <S.CenterPage>
      <S.ContainerPage>
        <S.TitlePage>{t("positionsManagement.myPositions")}</S.TitlePage>
        <Box sx={{
          '& .MuiBox-root': {
            padding: 0,
            '.MuiTabs-indicator': {
              backgroundColor: 'rgb(255, 2, 111)'
            },
            '.Mui-selected': {
              color: 'rgb(255, 2, 111)',
            }
          }
        }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: valueDisplay }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab classes={{ root: stylesTabs.span }} label="Internos" {...a11yProps(0)} />
              <Tab classes={{ root: stylesTabs.span }} label="Externos" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {!(action?.type?.length > 0) ? (
              <TableContainerLayout
                search={search}
                setSearch={(e) => {
                  setPagePrivate(1);
                  setSearch(e);
                }}
                page={pagePrivate}
                setPage={setPagePrivate}
                totalPages={totalPagesPrivate}
                hideAddButton={!JSON.parse(localStorage.getItem("tenantObj"))?.internalRecruitment && action?.type?.length == 0}
                onClickAddButton={() => setAction({ type: "add" })}
              >
                <TableContainer sx={{ width: "100%", background: "white" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '25%' }}>
                          <S.FontHeaderTable>{t("positionsManagement.positionName")}</S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <S.FontHeaderTable>
                            {t("positionsManagement.visibility")}
                          </S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <S.FontHeaderTable>
                            {t("positionsManagement.applied")}
                          </S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "end",
                            }}
                          >
                            <S.FontHeaderTable>
                              {t("positionsManagement.qualified")}
                            </S.FontHeaderTable>
                            <Tooltip
                              title={t("positionsManagement.allCompetences")}
                              arrow
                              placement="top"
                            >
                              <InfoOutlined style={{ fontSize: "20px" }} />
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <S.FontHeaderTable>Status</S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: '20%', minWidth: '195px' }}>
                          <S.FontHeaderTable>{null}</S.FontHeaderTable>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <PositionTable
                      data={positionPrivate}
                      isSysAdmin={isSysAdmin}
                      isInternal={true}
                      setAction={setAction}
                      copyLink={copyLink}
                    />
                  </Table>
                </TableContainer>
              </TableContainerLayout>
            ) : (
              <PlansForm
                action={action}
                onClickBack={() => {
                  allFormationsPrivate(pagePrivate - 1, search);
                  setAction({});
                }}
                feedbackAction={handleFeedbackActionFormationPublic}
              />
            )}

          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {positionPublic.length > 0 ? (
              <TableContainerLayout
                search={search}
                open={setOpenSuggestPositionModal}
                setSearch={(e) => {
                  setPagePublic(1);
                  setSearch(e);
                }}
                page={pagePublic}
                setPage={setPagePublic}
                totalPages={totalPagesPublic}
                isSolicitation={!isSysAdmin}
                hideAddButton={!JSON.parse(localStorage.getItem("tenantObj"))?.internalRecruitment && positionPublic.length == 0}
                onClickAddButton={() => isSysAdmin && setAction({ type: "add" })}
              >
                <TableContainer sx={{ width: "100%", background: "white" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '25%' }}>
                          <S.FontHeaderTable>{t("positionsManagement.positionName")}</S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <S.FontHeaderTable>
                            {t("positionsManagement.visibility")}
                          </S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <S.FontHeaderTable>
                            {t("positionsManagement.applied")}
                          </S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "end",
                            }}
                          >
                            <S.FontHeaderTable>
                              {t("positionsManagement.qualified")}
                            </S.FontHeaderTable>
                            <Tooltip
                              title={t("positionsManagement.allCompetences")}
                              arrow
                              placement="top"
                            >
                              <InfoOutlined style={{ fontSize: "20px" }} />
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }}>
                          <S.FontHeaderTable>Status</S.FontHeaderTable>
                        </TableCell>
                        <TableCell sx={{ width: '20%', minWidth: '195px' }}>
                          <S.FontHeaderTable>{null}</S.FontHeaderTable>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <PositionTable
                      data={positionPublic}
                      isSysAdmin={isSysAdmin}
                      isInternal={false}
                      setAction={setAction}
                      copyLink={copyLink}
                    />
                  </Table>
                </TableContainer>
              </TableContainerLayout>
            ) : (
              <SolicitationScreen trail={false} open={setOpenSuggestPositionModal}></SolicitationScreen>
            )}

          </CustomTabPanel>
        </Box>
      </S.ContainerPage>
      <SuggestPositionModal
        trail={false}
        open={openSuggestPositionModal}
        onClose={() => setOpenSuggestPositionModal(false)}
      />
    </S.CenterPage>
  );
}

export default Plans;