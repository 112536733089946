/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, useRef, CSSProperties } from "react";
import * as S from "./styles";
import SetaFillDownIcon from "../../assets/setaFillDown.svg";
import SetaFillUpIcon from "../../assets/setaFillUp.svg";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import { Autocomplete, TextField } from "@mui/material";


interface IMultiselect {
  options: {
    id?: number | string;
    value?: string | number;
    name?: string;
  }[];
  selecteds?: {
    id?: number | string;
    value?: string | number;
    name?: string;
  }[];
  onSelect?: (_: any) => any;
  onLastSelected?: (_: any) => any;
  placeholder: string;
  showSearch?: boolean;
  showAmount?: boolean;
  error?: any;
  sortAlphabetical?: boolean;
  listAbsolute?: boolean;
  label?: any;
  style?: CSSProperties;
}



function Multiselect({
  options,
  selecteds,
  onSelect,
  onLastSelected,
  showSearch = true,
  showAmount = true,
  placeholder,
  error,
  sortAlphabetical = true,
  listAbsolute = true,
  label,
  style,
}: IMultiselect) {
  const [text, setText] = useState("");
  const stylesAutoComplete = S.OverrideMuiStyles();

  const isError = typeof error === "string" && error?.length > 0;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const convertItem = (item) => item?.name || item?.value;
  function equalObject(obj1, obj2) {
    obj1 = {
      id: obj1.id,
      name: obj1.name || obj1.value,
      value: obj1.value || obj1.name,
    };
    obj2 = {
      id: obj2.id,
      name: obj2.name || obj2.value,
      value: obj2.value || obj2.name,
    };
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  const selectedsIsArray =
    typeof selecteds === "object" && selecteds?.length >= 0;
  const [selectedsFilter, setSelectedsFilter] = useState<any>(
    selectedsIsArray ? selecteds : []
  );
  const ref = useRef(null);
  const inputRef = useRef(null);


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isFocused && ref.current && !ref.current.contains(e.target)) {
        inputRef.current.blur();
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isFocused]);

  function sortAlphabeticalOptions(a, b) {
    if (sortAlphabetical) {
      return convertItem(a).toString().localeCompare(convertItem(b).toString());
    }
  }

  useEffect(() => {
    selectedsIsArray && setSelectedsFilter(selecteds);
  }, [selecteds]);

  const isOptionEqualToValue = (option, value) => {
    return option?.id === value?.id;
  };

  return (
    <div>
      <S.Label>{label}</S.Label>
      <Autocomplete
        multiple
        isOptionEqualToValue={isOptionEqualToValue}
        options={options}
        classes={{
          root: stylesAutoComplete.MuiAutocompleteRoot
        }}
        clearIcon={false}
        getOptionLabel={(option) => convertItem(option) || ''}
        value={selectedsFilter}
        onChange={(event, newValue) => {
          setSelectedsFilter(newValue);
          onSelect && onSelect(newValue);
          onLastSelected && onLastSelected(newValue[newValue.length - 1]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // isError={isError}
            data-cy="inputMultiselect"
            onClick={(e) => {
              setIsFocused(!isFocused);
              if (isFocused) {
                inputRef.current.blur();
              } else {
                inputRef.current.focus();
              }
            }}
            sx={{
              borderColor: 'rgb(140, 140, 140)',
              "& fieldset": {
                boxShadow: '0 0 0 0',
                border: '0 none',
                outline: 0,
              },
              "&.Mui-focused fieldset": {
                boxShadow: '0 0 0 0',
                borderColor: 'rgb(140, 140, 140)',
                outline: 0,
              }
            }}
            value={text}
            ref={inputRef}
            onChange={(e) => showSearch && setText(e.target.value)}
            placeholder={placeholder}
            style={{
              ...style,
              color: 'rgb(19, 18, 18)',
              backgroundColor: '#FFF',
              borderRadius: '5px',
            }}
            inputProps={{
              ...params.inputProps,
              color: 'rgb(19, 18, 18)',
              style: S.inputStyles,
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {showAmount && (
                    <S.AmountContainer
                      onClick={(e) => {
                        setIsFocused(!isFocused);
                        if (isFocused) {
                          inputRef.current.blur();
                        } else {
                          inputRef.current.focus();
                        }
                      }}
                    >
                      <S.AmountText>{selectedsFilter.length}</S.AmountText>
                    </S.AmountContainer>
                  )}
                  {params.InputProps.endAdornment}
                  {isError && (
                    <S.ErrorText data-cy="errorMessageMultiSelect">{error}</S.ErrorText>
                  )}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <MenuItem
            {...props}
            data-cy="multiselectOptions"
            className={stylesAutoComplete.checkbox}
            sx={{
              p: 0,
              m: 0,
            }}
            style={{
              cursor: 'pointer',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: 19,
              gap: 13,
            }}
          >
            <S.CheckboxOutCircle
              selected={selectedsFilter.some((slct) =>
                equalObject(slct, option)
              )}
            >
              {selectedsFilter.some((slct) =>
                equalObject(slct, option)
              ) && (
                  <CheckIcon
                    sx={{ fontSize: 16, color: "#fff" }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      padding: 0,
                      margin: 0,
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
            </S.CheckboxOutCircle>
            <S.OptionText>{convertItem(option)}</S.OptionText>
          </MenuItem>
        )}
        style={S.customInputStyle}
      />

    </div>
  );
}

export { Multiselect };
