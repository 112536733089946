import { t } from "i18next";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  border: 2px solid #FF026F; /* Cor da borda */
  border-radius: 10px; /* Raio das bordas para arredondamento */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  background-color: #ffffff; /* Cor de fundo da div */
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #131212;
  line-height: 32px;
  margin-bottom: 17px;
`;
export const Subtitle = styled.p`
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #131212;
`;

export const ContainerButton = styled.div`
  /* width: 180px; */
  margin: 30px 0;
  @media screen and (max-width: 1049px) {
    width: 100%;
  }  
`;