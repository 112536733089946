import React from "react";

function ImageIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
      className={className}
    >
      <path fill="#fff" d="M0 0H42V42H0z"></path>
      <path
        fill="#AEAEAE"
        d="M16.333 25.667h8.334a.403.403 0 00.375-.23A.367.367 0 0025 25l-2.292-3.063a.396.396 0 00-.333-.166.396.396 0 00-.333.166l-2.167 2.896-1.542-2.062a.396.396 0 00-.333-.167.396.396 0 00-.333.167L16 25a.367.367 0 00-.042.438c.084.152.209.229.375.229zM14.667 29c-.459 0-.851-.163-1.178-.49A1.607 1.607 0 0113 27.334V15.667c0-.459.163-.851.49-1.178.326-.326.718-.489 1.177-.489h11.666c.459 0 .851.163 1.178.49.326.326.489.718.489 1.177v11.666c0 .459-.163.851-.49 1.178-.326.326-.718.489-1.177.489H14.667z"
      ></path>
    </svg>
  );
}

export default ImageIcon;
