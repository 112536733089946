/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "dayjs/locale/pt-br";
import {Button as ButtonDS} from "design-system-firedev";
import * as React from "react";
import * as T from "../../Global/Typography";
import {TableContainerLayout} from "../../components/TableContainerLayout";
import {Company} from "../../models/Company";
import CompanyService from "../../services/CompanyService";
import CompanyForm from "./Form";
import * as S from "./style";
import {enqueueSnackbar} from "notistack";
import {t} from "i18next";
import UserService from "../../services/UserService";
interface IAlert {
  show: boolean;
  type: undefined | "success" | "error";
  message: string;
}

const defaultAlert: IAlert = {
  show: false,
  type: undefined,
  message: "",
};

export default function Companies() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [action, setAction] = React.useState("list");
  const [selected, setSelected] = React.useState<Company>(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [addButton, setAddButton] = React.useState(false);
  const [search, setSearch] = React.useState<string>("");
  const [totalPages, setTotalPages] = React.useState(0);
  const [idSelected, setIdSelected] = React.useState<number>();
  const [page, setPage] = React.useState<number>(1);
  const [filial, setFilial] = React.useState(true)
  const size = 5;

  const messages = {
    deleteConfirmation: t("empresas.deleteConfirmation"),
    deletionAlert: t("empresas.deleteAlert"),
    company: {
      deleteSuccess: t("empresas.deleteSuccess"),
      save: {
        insertSuccess: t("empresas.companyAddedSuccessfully"),
        editSuccess: t("empresas.companyUpdatedSuccessfully"),
        error: t("empresas.errorAddCompany"),
      },
    },
  };

  const labels = {
    add: t("empresas.add"),
    pageTitle: t("empresas.companies"),
    company: {
      id: "Id",
      cnpj: t("empresas.crn"),
      companyName: t("empresas.companyLegalName"),
      tradeName: t("empresas.companyTradeName"),
      emailDomain: t("empresas.emailDomain"),
      parent: t("empresas.parentCompany"),
    },
  };

  const rowsMapper = (item: Company) => {
    return {
      id: item?.id,
      cnpj: item?.cnpj,
      companyName: item?.companyName,
      tradeName: item?.tradeName,
      emailDomain: item?.emailDomain,
      parent: item?.parent?.name,
      actionsButtons: (
        <div>
          <IconButton>
            <EqualizerIcon />
          </IconButton>
          <IconButton>
            <EditOutlinedIcon
              data-cy="companyTableEditButton"
              onClick={() => handleEdit(item)}
            />
          </IconButton>

          {/*<IconButton onClick={() => handleDelete(item)}>*/}
          {/*  <DeleteOutlinedIcon />*/}
          {/*</IconButton>*/}
        </div>
      ),
    };
  };

  const fetchData = async () => {
    const resultCurrentUser = await UserService.getUser();
    if(!resultCurrentUser?.company?.parent) {
      setFilial(false)
    }
    const result = await CompanyService.getPage(page - 1, size, search);
    const newRows = result.content.map((item: Company) => rowsMapper(item));
    setTotalPages(result.totalPages);
    setRows(newRows);
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleFeedbackAction = (
    type: undefined | "success" | "error",
    newData: any,
    submitAction: string
  ) => {
    fetchData();
    setAction(type === "success" ? "list" : "form");
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteData = async () => {
    try {
      await CompanyService.delete(idSelected);
      enqueueSnackbar(t("empresas.successDeleteCompany"), {variant: "success"});
      fetchData();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (item: Company) => {
    setSelected(item);
    setTimeout(() => {
      setAction("form");
      setIdSelected(item.id);
    }, 150);
  };

  const handleDelete = (item: Company) => {
    setSelected(item);
    setOpenDeleteDialog(true);
    setIdSelected(item.id);
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
    setAddButton(true);
  };

  const height = 54;

  return (
    <S.ContainerFullPage>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {messages.deleteConfirmation}: <br />
          <b>{typeof selected !== "undefined" ? selected.name : ""}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{messages.deletionAlert}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("empresas.cancel")}
            onClick={() => {
              setSelected(undefined);
              setOpenDeleteDialog(false);
            }}
          />
          <ButtonDS
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("empresas.delete")}
            onClick={deleteData}
          />
        </DialogActions>
      </Dialog>
      <T.Display>{labels.pageTitle}</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          hideAddButton={filial}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{width: "100%", background: "white"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <S.FontHeaderTable>{labels.company.id}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{labels.company.cnpj}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>
                      {labels.company.companyName}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>
                      {labels.company.tradeName}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>
                      {labels.company.emailDomain}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>
                      {labels.company.parent}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell data-cy="companyTableCNPJ">
                        {item.cnpj}
                      </TableCell>
                      <TableCell data-cy="companyTableName">
                        {item.companyName}
                      </TableCell>
                      <TableCell data-cy="companyTableTradeName">
                        {item.tradeName}
                      </TableCell>
                      <TableCell data-cy="companyTableEmail">
                        {item.emailDomain}
                      </TableCell>
                      <TableCell data-cy="companyTableParent">
                        {item.parent}
                      </TableCell>
                      <TableCell>{item.actionsButtons}</TableCell>
                    </TableRow>
                  ))}
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{textAlign: "center"}}>
                      {t("empresas.noResultsFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (selected || addButton) && (
        <CompanyForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          dataToEdit={selected}
        />
      )}
    </S.ContainerFullPage>
  );
}
