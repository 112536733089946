import api, {axiosApi} from "./api";

class TrailCategoryService {
  async getTrailCategoryPaginated(page, search) {
    const filter = encodeURIComponent(search);
    const resultTrailsPaginated = await api.get(
      `trail_category?page=${page}&size=5&filter=${filter}`
    );
    return resultTrailsPaginated.data;
  }

  async getTrailCategoryToDropdown(search = "") {
    const filter = encodeURIComponent(search);
    const resultTrailCategoryToDropdown = await api.get(
      `trail_category?size=9999&sort=id%2Cdesc&filter=${filter}`
    );
    return resultTrailCategoryToDropdown.data.data.map(({ id, name }) => ({
      id,
      value: name,
    }));
  }

  async getTrailCategoryToSignUp(search = "") {
    const filter = encodeURIComponent(search);
    const result = await axiosApi.get(
        `trail_category/no-auth?filter=${filter}`
    );
    return result.data.map(({ id, name }) => ({
      id,
      value: name,
    }));
  }
}
export default new TrailCategoryService();
