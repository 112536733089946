import React, { useState, useEffect } from "react";
import uploadIcon from "../../../../assets/uploadIcon.svg";
import * as S from "./styles";
import { t } from "i18next";

function File({ setChange, change, questionIndex, type }: any) {
  const [file, setFile] = useState<any>(change);

  function handleFile(quest) {
    if (
      quest?.target?.files[0]?.size > 0 &&
      quest?.target?.files[0]?.type?.length > 0
    ) {
      const newFile = quest?.target?.files[0];
      setFile(newFile);
      setChange(newFile);
    }
  }

  useEffect(() => {
    setFile(change);
  }, [type, questionIndex]);

  return (
    <S.ContainerFile>
      <S.ContainerSelectFile>
        <S.ContainerIconUpload>
          <S.IconUploadImage src={uploadIcon} />
        </S.ContainerIconUpload>
        <S.ContainerTexts>
          <S.PinkTextUploadFile>
            {t("challenge.selectArchive")}
          </S.PinkTextUploadFile>
          <S.TextUploadFile>{t("challenge.sentDocuments")}</S.TextUploadFile>
        </S.ContainerTexts>
        <S.InputFile
          data-cy="challengePageFileUpload"
          type="file"
          onChange={(e) => handleFile(e)}
        />
      </S.ContainerSelectFile>
      <S.ContainerFilesType data-cy="challengePageFileName">
        {file &&
          (() => {
            const fileFullName = file?.name;
            const typeFile = fileFullName?.split(".")?.pop();
            return (
              <S.ContainerFileTypesName
                onClick={() => {
                  setFile(undefined);
                  setChange(undefined);
                }}
              >
                <S.ContainerFileTypes>
                  <S.TextFileType>{typeFile}</S.TextFileType>
                </S.ContainerFileTypes>
                <S.TextFileName>{fileFullName}</S.TextFileName>
              </S.ContainerFileTypesName>
            );
          })()}
      </S.ContainerFilesType>
    </S.ContainerFile>
  );
}

export default File;
