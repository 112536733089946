import skillHunterPositionIcon from "../assets/skillHunterPositionIcon.png";
import {objectToURLSearchParams} from "../components/utils/http";
import {ILastAccessedTrail} from "../models/LastAccessedTrail";
import api, {axiosApi} from "./api";

class TrailService {
  async getTrail() {
    const resultTrails = await api.get("trails");
    return resultTrails.data.content;
  }

  async getTrailProgress(page: number, size: number) {
    const response = await api.get(`trails/progress?page=${page}&size=${size}`);

    return response.data.map((trail) => {
      return {
        id: trail.trailId,
        name: trail.name,
        percentage: trail.percentage,
        company: {
          id: trail.company.id,
          avatar: {
            fileUrl: trail?.company?.avatar ?? skillHunterPositionIcon,
          },
          companyName: trail.company.name,
          tradeName: trail.company.name,
          name: trail.company.name,
          slug: trail.company.slug,
        },
        createdAt: trail?.createdAt,
        slug: trail?.slug,
        category: {
          name: trail.category
        }
      };
    });
  }

  async getLastAccessedTrail(): Promise<ILastAccessedTrail> {
    const result = await api.get(`trails/last-trail`);
    const trail = result.data;
    return {
      id: trail?.trailId,
      name: trail?.name,
      percentage: trail?.percentage,
      company: {
        id: trail?.company?.id,
        avatar: {
          fileUrl: trail?.company?.avatar ?? skillHunterPositionIcon,
        },
        companyName: trail?.company?.name,
        tradeName: trail?.company?.name,
        name: trail?.company?.name,
        emailDomain: "",
        country: undefined,
        slug: trail?.company?.slug,
      },
      skill: trail?.nextSkill,
      slug: trail?.slug,
      image: trail?.image,
    };
  }

  async getAllTrail(search, categoryCourseSelected) {
    return await api.get(
      `trails/name?filter=${search || ""}}&categoryId=${categoryCourseSelected?.id || ""
      }`
    );
  }

  async getAllTrailNotPaged(search, categoryCourseSelected) {
    const response = await api.get(
      `trails/name-not-paged?filter=${search || ""}&categoryId=${categoryCourseSelected?.id || ""
      }`
    );
    return response.data;
  }

  async getSearchTrail(search: string) {
    const response = await api.get(`trails?filter=${search}&page=0&size=5`);
    return response.data;
  }

  async getTrailPaginated(page, search) {
    const filter = encodeURIComponent(search);
    const resultTrailsPaginated = await api.get(
      `trails/table?page=${page}&size=5&filter=${filter}`
    );
    return resultTrailsPaginated.data;
  }

  async getTrailManagement(page, search) {
    search = encodeURIComponent(search);
    const resultTrailsPaginated = await api.get(
      `v2/trails/management?page=${page}&size=5&search=${search}`
    );
    return resultTrailsPaginated.data;
  }

  async getTrailManagementPrivate(page: number, search: string) {
    search = encodeURIComponent(search);
    const resultTrailsPaginated = await api.get(
      `v2/trails/management?page=${page}&size=5&search=${search}&visibility=PRIVATE`
    );
    return resultTrailsPaginated.data;
  }

  async getTrailManagementPublic(page: number, search: string) {
    search = encodeURIComponent(search);
    const resultTrailsPaginated = await api.get(
      `v2/trails/management?page=${page}&size=5&search=${search}&visibility=PUBLIC`
    );
    return resultTrailsPaginated.data;
  }

  async editTrail(id: number, TrailDTO: any) {
    const response = await api.put(`v2/trails/${id}`, TrailDTO);
    return response.data;
  }

  async addTrail(TrailDTO: any) {
    const response = await api.post("v2/trails", TrailDTO);
    return response.data;
  }

  async getTrailToDropdown(visibilityFilter = null, skillHunterPool = null) {
    let url = "trails/summary?";
    const queryParams: any = {
      onlyExternalFormations: visibilityFilter,
      skillHunterPool: skillHunterPool,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultTrailToDropdown = await api.get(
      url + objectToURLSearchParams(filteredParams)
    );
    return resultTrailToDropdown.data.map(({ id, name, skillTrees }) => ({
      id,
      value: name,
      skillTrees: skillTrees,
    }));
  }

  async getTrailDashboard(slug) {
    let result;
    let token = localStorage.getItem("token");
    if (token) {
      result = await api.get(`trails/dashboard/${slug}`);
    } else {
      result = await axiosApi.get(`trails/dashboard-no-login/${slug}`);
    }
    return result;
  }

  async subscribe(slug) {
    return await api.post(`v2/trail/${slug}/subscribe`);
  }

  async getBaseTrail() {
    const result = await api.get(`v2/trails/base`);
    return result.data;
  }

  async findIdTrail(id: number) {
    const response = await api.get(`v2/trails/${id}`);
    return response.data;
  }
}

export default new TrailService();
