import * as T from "../../Global/Typography";
import * as S from "./style";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LoadingTable from "../../components/Loading/LoadingTable";
import { getTransactions } from "../../services/TransactionsService";
import moment from "moment";


interface Column {
  id: "status" | "date" | "value" | "userName";
  label: string;
  minWidth?: number;
  align?: "right";
}

export default function Transactions() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalElements, setTotalElements] = useState(0);
  const [balance, setBalance] = useState(0);

  const labels = {
    send: t("users.confirmPayment"),
    title: t("payment.transactions"),
    subTitle: t("payment.transactionsSubtitle"),
    selectPacks: t("payment.selectOneOfPackages"),
    package: t("payment.package"),
    unclocks: t("payment.unclocks"),
  };

  const options = {
    style: 'currency',
    currency: 'BRL'
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage])

  async function getData() {
    const resp = await getTransactions(page, rowsPerPage);
    setData(resp?.transactions?.content);
    setTotalElements(resp?.transactions?.totalElements);
    setBalance(resp?.balance);
  }

  const columns: readonly Column[] = [
    {
      id: "userName",
      label: "payment.user",
      minWidth: 170,
    },
    {
      id: "date",
      label: "payment.date",
      minWidth: 170,
    },
    {
      id: "status",
      label: "payment.status",
      minWidth: 120,
    },
    {
      id: "date",
      label: "payment.value",
      minWidth: 170,
    }
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const getTranslationStatus = (status: string) => {
    if (status == null) {
      return ""
    }
    switch (status) {
      case "APPROVED":
        return t("payment.statusApproved");
      case "WAITING_FOR_PAYMENT":
        return t("payment.statusWaitingForPaymnet");
      case "REVERSED":
        return t("payment.statusReversed");
      case "DISAPPROVED":
        return t("payment.statusDisapproved");
      case "CHARGEBACK_WAITING":
        return t("payment.statusChargebackWaiting");
      case "CHARGEBACK":
        return t("payment.statusChargeback");
      case "CANCELED_PURCHASE":
        return t("payment.statusCanceledPurchase");
      default:
        return "";
    }
  }

  function convertDate(date: any) {
    return moment(date, ['YYYY/MM/DD HH:mm:ss', 'MM/DD/YYYY HH:mm:ss']).subtract(new Date().getTimezoneOffset(), 'minutes').format(t("dateFormat"))
  }

  return (
    <S.ContainerFullPage>
      <T.Display>{labels.title}</T.Display>
      <S.ContainerGap>
        <S.TextInfo>
          {labels.subTitle}
        </S.TextInfo>
      </S.ContainerGap>

      <S.ContainerWallet>
        <T.Heading2>
          Saldo da empresa
        </T.Heading2>

        <S.ContainerBalance1>
          <S.Balance>
            {balance} créditos
          </S.Balance>
          <S.ContainerBalance>
            <Link style={{ color: '#FF026F' }} to="/management/credits" id="l">Realizar recarga</Link>
          </S.ContainerBalance>
        </S.ContainerBalance1>
      </S.ContainerWallet>

      <S.ContainerHistoric>
        <T.Heading2>
          Historico
        </T.Heading2>
      </S.ContainerHistoric>

      <S.ContainerStyleTable>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className="titleColumn">
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      <S.ContainerTableRow>
                        {t(column.label)}
                      </S.ContainerTableRow>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {totalElements === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                      {loading ? (
                        <LoadingTable />
                      ) : (
                        t("evaluations.noResultsFound")
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {totalElements > 0 &&
                  data.map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <TableCell
                          className="tableRowCell"
                        >
                          {row.userName}
                        </TableCell>
                        <TableCell
                          className="tableRowCell"
                        >
                          {convertDate(row.createdAt)}
                        </TableCell>
                        <TableCell
                          className="tableRowCell"
                        >
                          {getTranslationStatus(row.status)}
                        </TableCell>
                        <TableCell
                          className="tableRowCell"
                        >
                          {row.value.toLocaleString('pt-BR', options)}
                        </TableCell>


                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="pagination"
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </S.ContainerStyleTable>
    </S.ContainerFullPage>
  );
}
