import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {ButtonDS} from "../../pages/Login/style";
import {useState} from "react";
import {t} from "i18next";

export function BlackListModal({
  id,
  title,
  content,
  confirmContent,
  onClose,
  open,
  handleBlackList,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <b>{content}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions data-cy="confirmEditionButtonSkills">
        <ButtonDS
          typeOfButton="secondary"
          size="custom"
          height={40}
          width={140}
          fontVariant="body1"
          text={t("performanceReport.cancel")}
          onClick={onClose}
        />
        <ButtonDS
          typeOfButton="primary"
          size="custom"
          height={40}
          width={140}
          fontVariant="body1"
          text={confirmContent}
          onClick={() => handleBlackList(id)}
          // onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
}
