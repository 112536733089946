import api from "./api";

class QuestionService {
    async getQuestions(search: string, page: number) {
      let response;
      if(search) {
        response = await api.get(`questions?filter=${search}&page=${page}&size=5`)
        return response.data;
      }
      response = await api.get(`questions?page=${page}&size=5`)
      return response.data;
    }

    async deactivate(id: string) {
      await api.delete(`questions/${id}`)
    }

    async findIdQuestion(id: string) {
      const response = await api.get(`questions/${id}`)
      return response.data;
    }

    async editQuestion(id, QuestionDTO) {
      const response = await api.put(`questions/${id}`, QuestionDTO)
      return response.data;
    }

    async addQuestion(QuestionDTO: any) {
      const response = await api.post("questions", QuestionDTO)
      return response;
    }

    async activate(id: number) {
      const response = await api.put(`questions/${id}/activate`)
      return response;
    }
  }
  
  export default new QuestionService();