import * as S from "../../styles";

type FormDataEvaluationTypeText = {
  content: string;
  textResponse: string;
  id: number;
};

export function EvaluationFormTypeText({
  content,
  id,
  textResponse,
}: FormDataEvaluationTypeText) {
  return (
    <div>
      <S.ContainerQuestion>
        <S.NumberOfQuestion>Desafio {id}</S.NumberOfQuestion>
        <S.BodyOfQuestion dangerouslySetInnerHTML={{ __html: content }} />
      </S.ContainerQuestion>
      <S.ContainerOfAnswerUser>
        <S.AnswerOfUserTypeText>{textResponse}</S.AnswerOfUserTypeText>
      </S.ContainerOfAnswerUser>
    </div>
  );
}
