import React from "react";
import Radio from "./types/radio";
import Checkbox from "./types/checkbox";
import Numeric from "./types/numeric";
import Textarea from "./types/textarea";
import File from "./types/file";

function handleQuestions(question, change, setChange, questionIndex) {
  switch (question?.type.toLowerCase()) {
    case "radio":
    case "select":
      return (
        <Radio
          options={question?.options}
          change={change}
          setChange={setChange}
          type={question?.type}
          questionIndex={questionIndex}
        />
      );
      break;
    case "checkbox":
    case "multiselect":
      return (
        <Checkbox
          options={question?.options}
          change={change}
          type={question?.type}
          setChange={setChange}
          questionIndex={questionIndex}
        />
      );
      break;
    case "numeric":
      return (
        <Numeric
          setChange={setChange}
          change={change}
          type={question?.type}
          questionIndex={questionIndex}
        />
      );
      break;
    case "textarea":
      return (
        <Textarea
          setChange={setChange}
          change={change}
          type={question?.type}
          questionIndex={questionIndex}
        />
      );
      break;
    case "file":
      return (
        <File
          setChange={setChange}
          change={change}
          type={question?.type}
          questionIndex={questionIndex}
        />
      );
      break;
  }
}

export default handleQuestions;
