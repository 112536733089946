import {Checkbox, FormControlLabel, Modal, Typography} from "@mui/material";
import * as S from "./styles";
import React, { useState } from "react";
import userService from "../../services/UserService";

export function UserAcceptTermsModal({
  onClose,
  open,
}) {
  const [isCheckedUseTerms, setIsCheckedUseTerms] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckedUseTerms(!isCheckedUseTerms);
  };

  const handleAcceptTerms = async () => {
      const result = await userService.acceptTerms();
      if(result?.status == 200) {
          onClose();
      }
  }

  return (
    <Modal
      open={open}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.FullModal>
        <S.TitleWrapper>
          <S.Title>Atualização dos termos de uso</S.Title>
          <S.Subtitle>
            Para continuar usando a plataforma Skill Hunter, você deve aceitar os novos termos de uso e políticas de privacidade.
          </S.Subtitle>
        </S.TitleWrapper>
        <S.ContentWrapper>
          <S.containerTermosDeUso>
            <FormControlLabel
                control={
                  <Checkbox
                      checked={isCheckedUseTerms}
                      onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography data-cy="ScreenSignUpTerms">
                    Li e aceito os{" "}
                    <a
                        href="https://skillhunter.com.br/use-terms.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Termos de Uso
                    </a>{" "}
                    e as{" "}
                    <a
                        href="https://skillhunter.com.br/privacy-policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      Políticas de Privacidade
                    </a>
                  </Typography>
                }
            />
          </S.containerTermosDeUso>
            <S.ContainerButton>
                <S.buttonPink disabled={!isCheckedUseTerms} onClick={handleAcceptTerms}>
                    Enviar
                </S.buttonPink>
            </S.ContainerButton>
        </S.ContentWrapper>
      </S.FullModal>
    </Modal>
  );
}
