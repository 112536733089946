import styled from "styled-components";

export const ContainerTextarea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputTextarea = styled.textarea`
  max-width: 100%;
  resize: none;
  height: 150px;
  background: #ffe8e6;
  padding: 12px 17px;
  font-size: 1rem;
  font-weight: 700;
  text-align: start;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  outline: none !important;
  -moz-appearance: textfield;
  &::placeholder {
    /* position: absolute; */
    color: #8c8c8c;
    font-weight: 700;
    font-size: 16px;
  }
  &:focus {
    outline: 1px solid #ff026f;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ContainerMaxLength = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MaxLengthText = styled.p<{ error?: boolean }>`
  color: ${({ error }) => (error ? "#FF026F" : "#8c8c8c")};
  font-size: 12px;
  font-weight: 400;
`;
