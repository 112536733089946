import React from "react";

function UploadIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      fill="none"
      viewBox="0 0 17 16"
      className={className}
    >
      <path
        fill="#131212"
        d="M14.5 11v3h-12v-3h-2v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-11-6l1.41 1.41L7.5 3.83V12h2V3.83l2.59 2.58L13.5 5l-5-5-5 5z"
      ></path>
    </svg>
  );
}

export default UploadIcon;
