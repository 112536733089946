import {
  Autocomplete, Backdrop, CircularProgress,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, IconButton, Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {ButtonDS} from "../../pages/Login/style";
import {t} from "i18next";
import {Controller, useForm} from "react-hook-form";
import {positionsListEN, positionsListES, positionsListPT} from "./positions";
import {zodResolver} from "@hookform/resolvers/zod";
import * as zod from "zod";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import * as S from "../../pages/Plans/form/styles";
import React, {useEffect, useState} from "react";
import SolicitationService from "../../services/SolicitationService";
import CloseIcon from "@mui/icons-material/Close";
import MobileLogo from "../../assets/logo-firedev.png";

interface ISuggestPosition {
  trail?: boolean,
  onClose: () => void,
  open: boolean,
  positionName?: string
}

export function SuggestPositionModal({
                                       trail = false,
                                       onClose,
                                       open,
                                       positionName
                                     }: ISuggestPosition) {
  const [positionsList, setPositionsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [suggestionSent, setSuggestionSent] = useState(false)

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const filter = createFilterOptions();

  const schema = zod.object({
    position: zod
    .union([
      zod.object({
        id: zod.number().optional().nullable(),
        name: zod.string().refine((val) => val.trim().length > 0, {
          message: trail ? t("positionsList.selectAddTrail") : t("positionsList.selectAddPosition"),
        }),
      }),
      zod.null(),
    ])
    .refine(
      (val) => val !== null,
      trail ? t("positionsList.selectAddTrail") : t("positionsList.selectAddPosition")
    ),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      position: {id: null, name: ""},
    },
  })

  const convertItem = (item) => item?.name;

  function sortAlphabeticalOptions(a, b) {
    return convertItem(a)?.toString()?.localeCompare(convertItem(b)?.toString());
  }

  const filteredOptions = positionsList?.sort((a, b) => sortAlphabeticalOptions(a, b))

  useEffect(() => {
    const language = localStorage.getItem("language")
    language === 'en' ? setPositionsList(positionsListEN) :
      language === 'es' ? setPositionsList(positionsListES) :
        setPositionsList(positionsListPT)
  }, []);

  const handleClose = () => {
    onClose()
    setValue('position', {id: null, name: ""})
    setSuggestionSent(false)
  }

  const handleSuggestPosition = async () => {
    try {
      setLoading(true)
      trail ? await SolicitationService.postSolicitation(getValues('position').name, 'TRAIL') :
        await SolicitationService.postSolicitation(getValues('position').name, 'POSITION')
      setSuggestionSent(true)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    positionName?.length > 1 && setValue('position', {id: -3, name: positionName})
  }, [positionName]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {
        suggestionSent ?
          <div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 0.5,
                top: 0.5,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
              <img src={MobileLogo} alt="Skill Hunter Logo"
                style={{width: '34px', height: '42px'}}
              />
              {t('solicitation.created')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {trail ? t('solicitation.receivedTrail') : t('solicitation.receivedPosition')}
                {getValues('position').name}
                {t('solicitation.contact')}
              </DialogContentText>
            </DialogContent>
          </div>
          :
          <div>
            <DialogTitle
              id="alert-dialog-title">{trail ? t("positionsList.trailRequest") : t("positionsList.positionRequest")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" sx={{marginBottom: '1rem'}}>
                <b>{trail ? t("positionsList.selectTrail") : t("positionsList.selectPosition")}</b>
              </DialogContentText>

              <Controller
                name="position"
                control={control}
                render={({field: {value, onChange}}) => (
                  <Autocomplete
                    id="title"
                    noOptionsText={t("noOptionsMui")}
                    options={filteredOptions}
                    value={value}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#fff",
                        fontWeight: 700,
                        fontSize: 18,
                        color: "#000",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          border: "1px solid",
                          borderColor: "#8C8C8C",
                        },
                        "& fieldset": {
                          border: "1px solid",
                          borderColor: "#8C8C8C",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid",
                          borderColor: "#8C8C8C",
                        },
                      },
                    }}
                    onChange={(event, newValue) => {
                      onChange(
                        newValue?.id !== -2
                          ? newValue
                          : {id: newValue.id, name: newValue.inputValue}
                      );
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const {inputValue} = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `${t("add")} ${inputValue}`,
                          id: -2,
                        });
                      }

                      return filtered;
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(params, opt) => (
                      <Typography
                        {...params}
                        key={opt.id}
                        sx={{
                          p: 0,
                          m: 0,
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingTop: "19px",
                          paddingBottom: "19px",
                          backgroundColor: "auto",
                          gap: 13,
                          opacity: 1,
                          fontFamily: "Ubuntu",
                          fontWeight: 500,
                          fontSize: 16,
                          color: "#8c8c8c",
                        }}
                      >
                        {opt?.title ? opt?.title : opt?.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: 0,
                          width: "100%",
                          height: "100%",
                          fontSize: 16,
                          fontWeight: 500,
                          outline: "none",
                          verticalAlign: "middle",
                          color: "#131212",
                          background: "#ffffff",
                        }}
                        placeholder={t("positionsManagement.positionName")}
                        value={value ? value.name : ""}
                      />
                    )}
                  />
                )}
              />
              <S.ErrorText>
                {errors?.position?.message || errors?.position?.name?.message}
              </S.ErrorText>
            </DialogContent>
            <DialogActions sx={{padding: 2}}>
              <ButtonDS
                typeOfButton="secondary"
                size="custom"
                height={40}
                width={140}
                fontVariant="body1"
                text={t("performanceReport.cancel")}
                onClick={handleClose}
              />
              <ButtonDS
                typeOfButton="primary"
                size="custom"
                height={40}
                width={140}
                fontVariant="body1"
                text={t("positionsList.request")}
                onClick={handleSubmit(handleSuggestPosition)}
              />
            </DialogActions>
          </div>
      }
      {
        loading &&
          <Backdrop
              sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
              open={loading}
              onClick={null}
          >
              <CircularProgress color="inherit"/>
          </Backdrop>
      }
    </Dialog>
  );
}
