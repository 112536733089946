import { Company } from "../models/Company";
import api from "./api";

class TenantService {
  private baseUrl = "tenant";

  async getMyCompanies(): Promise<Array<Company>> {
    const result = await api.get(`${this.baseUrl}/my-companies`);
    return result.data;
  }
}

export default new TenantService();
