import {t} from "i18next"

export interface IPosition {
  id: number;
  name: string;
}

export const positionsListPT: IPosition[] = [
  {id: 1, name: "Advogado(a) Ambiental"},
  {id: 2, name: "Advogado(a) Criminalista"},
  {id: 3, name: "Advogado(a) Trabalhista"},
  {id: 4, name: "Advogado(a) de Direitos Humanos"},
  {id: 5, name: "Advogado(a) de Propriedade Intelectual"},
  {id: 6, name: "Advogado(a) Tributarista"},
  {id: 7, name: "Agente de Atendimento ao Cliente"},
  {id: 8, name: "Agroecólogo(a)"},
  {id: 9, name: "Agropecuarista"},
  {id: 10, name: "Analista de Business Development"},
  {id: 11, name: "Analista de Business Intelligence"},
  {id: 12, name: "Analista de Comércio Exterior"},
  {id: 13, name: "Analista de Dados"},
  {id: 14, name: "Analista de Mercado"},
  {id: 15, name: "Analista de Qualidade"},
  {id: 16, name: "Analista de Redes Sociais"},
  {id: 17, name: "Analista de Segurança da Informação"},
  {id: 18, name: "Analista de Suporte ao Cliente"},
  {id: 19, name: "Analista de Suporte Técnico"},
  {id: 20, name: "Analista de Sistemas de Controle"},
  {id: 21, name: "Analista de Sistemas Embarcados"},
  {id: 22, name: "Analista de Desenvolvimento Sustentável"},
  {id: 23, name: "Analista de E-commerce"},
  {id: 24, name: "Analista de Engenharia de Produto"},
  {id: 25, name: "Analista de Finanças Corporativas"},
  {id: 26, name: "Analista de Logística"},
  {id: 27, name: "Analista de Marketing Digital"},
  {id: 28, name: "Analista de Recursos Humanos"},
  {id: 29, name: "Analista de Risco de Crédito"},
  {id: 30, name: "Analista ESG"},
  {id: 31, name: "Analista Financeiro"},
  {id: 32, name: "Analista Tributário"},
  {id: 33, name: "Arqueólogo(a)"},
  {id: 34, name: "Arqueólogo(a) Subaquático(a)"},
  {id: 35, name: "Arquiteto(a)"},
  {id: 36, name: "Arquiteto(a) Paisagista"},
  {id: 37, name: "Arquiteto de Soluções"},
  {id: 38, name: "Artista Plástico(a)"},
  {id: 39, name: "Assistente Administrativo"},
  {id: 40, name: "Assistente de Logística"},
  {id: 41, name: "Assistente de Recursos Humanos"},
  {id: 42, name: "Assistente de Supermercado"},
  {id: 43, name: "Atendente de Restaurante"},
  {id: 44, name: "Ator"},
  {id: 45, name: "Bibliotecário(a)"},
  {id: 46, name: "Biólogo(a)"},
  {id: 47, name: "Biólogo de Pesquisa"},
  {id: 48, name: "Caixa de Supermercado"},
  {id: 49, name: "Cenógrafo(a)"},
  {id: 50, name: "Chefe de Cozinha"},
  {id: 51, name: "Cientista de Dados"},
  {id: 52, name: "Coach"},
  {id: 53, name: "Comissário(a) de Bordo"},
  {id: 54, name: "Contador(a)"},
  {id: 55, name: "Coreógrafo(a)"},
  {id: 56, name: "Coveiro(a)"},
  {id: 57, name: "Criminólogo(a)"},
  {id: 58, name: "Cronobiólogo(a)"},
  {id: 59, name: "Curador(a) de Arte"},
  {id: 60, name: "Curador(a) de Museu"},
  {id: 61, name: "Dançarino(a)"},
  {id: 62, name: "Dermatologista"},
  {id: 63, name: "Desenvolvedor Back-end C# Junior"},
  {id: 64, name: "Desenvolvedor Back-end C# Pleno"},
  {id: 65, name: "Desenvolvedor Back-end C# Senior"},
  {id: 66, name: "Desenvolvedor Back-end Go Junior"},
  {id: 67, name: "Desenvolvedor Back-end Go Pleno"},
  {id: 68, name: "Desenvolvedor Back-end Go Senior"},
  {id: 69, name: "Desenvolvedor Back-end Java Junior"},
  {id: 70, name: "Desenvolvedor Back-end Java Pleno"},
  {id: 71, name: "Desenvolvedor Back-end Java Senior"},
  {id: 72, name: "Desenvolvedor Back-end Kotlin Junior"},
  {id: 73, name: "Desenvolvedor Back-end Kotlin Pleno"},
  {id: 74, name: "Desenvolvedor Back-end Kotlin Senior"},
  {id: 75, name: "Desenvolvedor Back-end Node.js Junior"},
  {id: 76, name: "Desenvolvedor Back-end Node.js Pleno"},
  {id: 77, name: "Desenvolvedor Back-end Node.js Senior"},
  {id: 78, name: "Desenvolvedor Back-end Python Junior"},
  {id: 79, name: "Desenvolvedor Back-end Python Pleno"},
  {id: 80, name: "Desenvolvedor Back-end Python Senior"},
  {id: 81, name: "Desenvolvedor Back-end Ruby Junior"},
  {id: 82, name: "Desenvolvedor Back-end Ruby Pleno"},
  {id: 83, name: "Desenvolvedor Back-end Ruby Senior"},
  {id: 84, name: "Desenvolvedor Back-end Rust Junior"},
  {id: 85, name: "Desenvolvedor Back-end Rust Pleno"},
  {id: 86, name: "Desenvolvedor Back-end Rust Senior"},
  {id: 87, name: "Desenvolvedor Back-end Swift Junior"},
  {id: 88, name: "Desenvolvedor Back-end Swift Pleno"},
  {id: 89, name: "Desenvolvedor Back-end Swift Senior"},
  {id: 90, name: "Desenvolvedor Front-end Angular Junior"},
  {id: 91, name: "Desenvolvedor Front-end Angular Pleno"},
  {id: 92, name: "Desenvolvedor Front-end Angular Senior"},
  {id: 93, name: "Desenvolvedor Front-end React Junior"},
  {id: 94, name: "Desenvolvedor Front-end React Pleno"},
  {id: 95, name: "Desenvolvedor Front-end React Senior"},
  {id: 96, name: "Desenvolvedor Front-end Vue.js Junior"},
  {id: 97, name: "Desenvolvedor Front-end Vue.js Pleno"},
  {id: 98, name: "Desenvolvedor Front-end Vue.js Senior"},
  {id: 99, name: "Desenvolvedor Full stack Angular + Node Junior"},
  {id: 100, name: "Desenvolvedor Full stack Angular + Node Pleno"},
  {id: 101, name: "Desenvolvedor Full stack Angular + Node Senior"},
  {id: 102, name: "Desenvolvedor Full stack Angular + Spring Boot Junior"},
  {id: 103, name: "Desenvolvedor Full stack Angular + Spring Boot Pleno"},
  {id: 104, name: "Desenvolvedor Full stack Angular + Spring Boot Senior"},
  {id: 105, name: "Desenvolvedor Full stack Angular + Swift Junior"},
  {id: 106, name: "Desenvolvedor Full stack Angular + Swift Pleno"},
  {id: 107, name: "Desenvolvedor Full stack Angular + Swift Senior"},
  {id: 108, name: "Desenvolvedor Full stack React + Node Junior"},
  {id: 109, name: "Desenvolvedor Full stack React + Node Pleno"},
  {id: 110, name: "Desenvolvedor Full stack React + Node Senior"},
  {id: 111, name: "Desenvolvedor Full stack React + Spring Boot Junior"},
  {id: 112, name: "Desenvolvedor Full stack React + Spring Boot Pleno"},
  {id: 113, name: "Desenvolvedor Full stack React + Spring Boot Senior"},
  {id: 114, name: "Desenvolvedor Full stack React + Swift Junior"},
  {id: 115, name: "Desenvolvedor Full stack React + Swift Pleno"},
  {id: 116, name: "Desenvolvedor Full stack React + Swift Senior"},
  {id: 117, name: "Desenvolvedor Full stack Vue.js + Node Junior"},
  {id: 118, name: "Desenvolvedor Full stack Vue.js + Node Pleno"},
  {id: 119, name: "Desenvolvedor Full stack Vue.js + Node Senior"},
  {id: 120, name: "Desenvolvedor Full stack Vue.js + Spring Boot Junior"},
  {id: 121, name: "Desenvolvedor Full stack Vue.js + Spring Boot Pleno"},
  {id: 122, name: "Desenvolvedor Full stack Vue.js + Spring Boot Senior"},
  {id: 123, name: "Desenvolvedor Full stack Vue.js + Swift Junior"},
  {id: 124, name: "Desenvolvedor Full stack Vue.js + Swift Pleno"},
  {id: 125, name: "Desenvolvedor Full stack Vue.js + Swift Senior"},
  {id: 126, name: "Designer de Moda"},
  {id: 127, name: "Designer de Produto"},
  {id: 128, name: "Designer de UI/UX"},
  {id: 129, name: "Designer de Interiores"},
  {id: 130, name: "Designer Gráfico"},
  {id: 131, name: "Designer Industrial"},
  {id: 132, name: "Despachante Aduaneiro"},
  {id: 133, name: "Despachante de Voo"},
  {id: 134, name: "Despachante Documentalista"},
  {id: 135, name: "Detetive Particular"},
  {id: 136, name: "Diplomata"},
  {id: 137, name: "Diretor(a) Artístico"},
  {id: 138, name: "Diretor(a) de Cinema"},
  {id: 139, name: "Diretor(a) de Fotografia"},
  {id: 140, name: "Diretor(a) de Marketing"},
  {id: 141, name: "Diretor(a) de Operações"},
  {id: 142, name: "Diretor(a) de Teatro"},
  {id: 143, name: "Diretor(a) de TV"},
  {id: 144, name: "Diretor(a) Executivo(a)"},
  {id: 145, name: "Diretor Acadêmico"},
  {id: 146, name: "Diretor de Arte"},
  {id: 147, name: "Diretor de Compras"},
  {id: 148, name: "Diretor de Conteúdo"},
  {id: 149, name: "Diretor de Criação"},
  {id: 150, name: "Diretor de Design"},
  {id: 151, name: "Diretor de Fotografia"},
  {id: 152, name: "Diretor de Operações Bancárias"},
  {id: 153, name: "Diretor de Recursos Humanos"},
  {id: 154, name: "Diretor de Vendas"},
  {id: 155, name: "Diretor(a) Financeiro(a)"},
  {id: 156, name: "Diretor(a) Jurídico(a)"},
  {id: 157, name: "Diretor(a) Técnico(a)"},
  {id: 158, name: "Economista"},
  {id: 159, name: "Economista Agrícola"},
  {id: 160, name: "Economista Comportamental"},
  {id: 161, name: "Economista do Desenvolvimento"},
  {id: 162, name: "Economista Financeiro"},
  {id: 163, name: "Economista Industrial"},
  {id: 164, name: "Economista Sênior"},
  {id: 165, name: "Editor(a)"},
  {id: 166, name: "Editor(a) de Fotografia"},
  {id: 167, name: "Editor(a) de Vídeo"},
  {id: 168, name: "Educador(a) Físico"},
  {id: 169, name: "Educador Infantil"},
  {id: 170, name: "Eletricista"},
  {id: 171, name: "Empreendedor(a)"},
  {id: 172, name: "Enfermeiro(a)"},
  {id: 173, name: "Engenheiro(a) Agrícola"},
  {id: 174, name: "Engenheiro(a) Ambiental"},
  {id: 175, name: "Engenheiro(a) Biomédico"},
  {id: 176, name: "Engenheiro(a) Civil"},
  {id: 177, name: "Engenheiro(a) de Alimentos"},
  {id: 178, name: "Engenheiro(a) de Automação"},
  {id: 179, name: "Engenheiro(a) de Dados"},
  {id: 180, name: "Engenheiro(a) de Materiais"},
  {id: 181, name: "Engenheiro(a) de Minas"},
  {id: 182, name: "Engenheiro(a) de Produção"},
  {id: 183, name: "Engenheiro(a) de Segurança do Trabalho"},
  {id: 184, name: "Engenheiro(a) de Software"},
  {id: 185, name: "Engenheiro(a) de Telecomunicações"},
  {id: 186, name: "Engenheiro(a) Eletricista"},
  {id: 187, name: "Engenheiro(a) Florestal"},
  {id: 188, name: "Engenheiro(a) Industrial"},
  {id: 189, name: "Engenheiro(a) Mecânico"},
  {id: 190, name: "Engenheiro(a) Químico"},
  {id: 191, name: "Epidemiologista"},
  {id: 192, name: "Escritor(a)"},
  {id: 193, name: "Escritor(a) Criativo(a)"},
  {id: 194, name: "Especialista em Agricultura Sustentável"},
  {id: 195, name: "Especialista em Comércio Exterior"},
  {id: 196, name: "Especialista em ESG"},
  {id: 197, name: "Especialista em Saúde Pública"},
  {id: 198, name: "Especialista em Segurança Cibernética"},
  {id: 199, name: "Farmacêutico(a)"},
  {id: 200, name: "Físico(a)"},
  {id: 201, name: "Fisioterapeuta"},
  {id: 202, name: "Fotógrafo(a)"},
  {id: 203, name: "Fotógrafo(a) de Moda"},
  {id: 204, name: "Fotógrafo(a) de Natureza"},
  {id: 205, name: "Fotógrafo(a) de Produto"},
  {id: 206, name: "Fotógrafo(a) de Publicidade"},
  {id: 207, name: "Fotógrafo(a) de Viagem"},
  {id: 208, name: "Fotógrafo(a) Documental"},
  {id: 209, name: "Fotógrafo(a) Esportivo(a)"},
  {id: 210, name: "Fotógrafo(a) Social"},
  {id: 211, name: "Gastrônomo(a)"},
  {id: 212, name: "Geofísico(a)"},
  {id: 213, name: "Geógrafo(a)"},
  {id: 214, name: "Geólogo(a)"},
  {id: 215, name: "Gerente Administrativo(a)"},
  {id: 216, name: "Gerente Comercial"},
  {id: 217, name: "Gerente de Compras"},
  {id: 218, name: "Gerente de Conteúdo"},
  {id: 219, name: "Gerente de Desenvolvimento de Negócios"},
  {id: 220, name: "Gerente de E-commerce"},
  {id: 221, name: "Gerente de Eventos"},
  {id: 222, name: "Gerente de Finanças"},
  {id: 223, name: "Gerente de Frota"},
  {id: 224, name: "Gerente de Hospitalidade"},
  {id: 225, name: "Gerente de Marketing"},
  {id: 226, name: "Gerente de Operações"},
  {id: 227, name: "Gerente de Produto"},
  {id: 228, name: "Gerente de Projetos"},
  {id: 229, name: "Gerente de Recursos Humanos"},
  {id: 230, name: "Gerente de Relações Públicas"},
  {id: 231, name: "Gerente de Restaurante"},
  {id: 232, name: "Gerente de Supply Chain"},
  {id: 233, name: "Gerente de Tecnologia da Informação"},
  {id: 234, name: "Gerente Educacional"},
  {id: 235, name: "Gerente Financeiro(a)"},
  {id: 236, name: "Gerente Operacional"},
  {id: 237, name: "Gestor de Qualidade"},
  {id: 238, name: "Historiador(a)"},
  {id: 239, name: "Ilustrador(a)"},
  {id: 240, name: "Influenciador(a) Digital"},
  {id: 241, name: "Jardineiro(a)"},
  {id: 242, name: "Jornalista"},
  {id: 243, name: "Juiz(a)"},
  {id: 244, name: "Lavador(a) de Pratos"},
  {id: 245, name: "Lavador(a) de Carros"},
  {id: 246, name: "Leiloeiro(a)"},
  {id: 247, name: "Líder de Projeto"},
  {id: 248, name: "Linguista"},
  {id: 249, name: "Maquiador(a)"},
  {id: 250, name: "Marceneiro(a)"},
  {id: 251, name: "Médico(a)"},
  {id: 252, name: "Médico(a) Cardiologista"},
  {id: 253, name: "Médico(a) Cirurgião(ã)"},
  {id: 254, name: "Médico(a) Dermatologista"},
  {id: 255, name: "Médico(a) Endocrinologista"},
  {id: 256, name: "Médico(a) Gastroenterologista"},
  {id: 257, name: "Médico(a) Geriatra"},
  {id: 258, name: "Médico(a) Hematologista"},
  {id: 259, name: "Médico(a) Homeopata"},
  {id: 260, name: "Médico(a) Infectologista"},
  {id: 261, name: "Médico(a) Nefrologista"},
  {id: 262, name: "Médico(a) Neurocirurgião(ã)"},
  {id: 263, name: "Médico(a) Neurologista"},
  {id: 264, name: "Médico(a) Obstetra"},
  {id: 265, name: "Médico(a) Oftalmologista"},
  {id: 266, name: "Médico(a) Oncologista"},
  {id: 267, name: "Médico(a) Ortopedista"},
  {id: 268, name: "Médico(a) Otorrinolaringologista"},
  {id: 269, name: "Médico(a) Patologista"},
  {id: 270, name: "Médico(a) Pneumologista"},
  {id: 271, name: "Médico(a) Psiquiatra"},
  {id: 272, name: "Médico(a) Radiologista"},
  {id: 273, name: "Médico(a) Reumatologista"},
  {id: 274, name: "Médico(a) Urologista"},
  {id: 275, name: "Mestre de Cerimônias"},
  {id: 276, name: "Meteorologista"},
  {id: 277, name: "Militar"},
  {id: 278, name: "Modelo"},
  {id: 279, name: "Músico(a)"},
  {id: 280, name: "Músico(a) de Orquestra"},
  {id: 281, name: "Músico(a) de Rua"},
  {id: 282, name: "Músico(a) de Sessão"},
  {id: 283, name: "Músico(a) de Estúdio"},
  {id: 284, name: "Músico(a) Educador(a)"},
  {id: 285, name: "Músico(a) Freelancer"},
  {id: 286, name: "Músico(a) Instrumentista"},
  {id: 287, name: "Nadador(a)"},
  {id: 288, name: "Naturalista"},
  {id: 289, name: "Neurocientista"},
  {id: 290, name: "Zelador(a)"},
  {id: 291, name: "Nutricionista"},
  {id: 292, name: "Oceanógrafo(a)"},
  {id: 293, name: "Odontólogo(a)"},
  {id: 294, name: "Office Manager"},
  {id: 295, name: "Oftalmologista"},
  {id: 296, name: "Operador(a) de Caixa"},
  {id: 297, name: "Operador(a) de Guindaste"},
  {id: 298, name: "Operador(a) de Máquinas"},
  {id: 299, name: "Operador(a) de Telemarketing"},
  {id: 300, name: "Operador(a) de Voo"},
  {id: 301, name: "Ortodontista"},
  {id: 302, name: "Osteopata"},
  {id: 303, name: "Padeiro(a)"},
  {id: 304, name: "Paleontólogo(a)"},
  {id: 305, name: "Pedagogo(a)"},
  {id: 306, name: "Pediatra"},
  {id: 307, name: "Personal Trainer"},
  {id: 308, name: "Pescador(a)"},
  {id: 309, name: "Pesquisador(a)"},
  {id: 310, name: "Pianista"},
  {id: 311, name: "Piloto"},
  {id: 312, name: "Pintor(a)"},
  {id: 313, name: "Pizzaiolo(a)"},
  {id: 314, name: "Podcaster"},
  {id: 315, name: "Policial"},
  {id: 316, name: "Político(a)"},
  {id: 317, name: "Porteiro(a)"},
  {id: 318, name: "Prefeito(a)"},
  {id: 319, name: "Produtor(a) de Cinema"},
  {id: 320, name: "Produtor(a) de Eventos"},
  {id: 321, name: "Produtor(a) de Moda"},
  {id: 322, name: "Produtor(a) de Música"},
  {id: 323, name: "Produtor(a) de TV"},
  {id: 324, name: "Professor(a)"},
  {id: 325, name: "Professor(a) de Arte"},
  {id: 326, name: "Professor(a) de Biologia"},
  {id: 327, name: "Professor(a) de Dança"},
  {id: 328, name: "Professor(a) de Educação Física"},
  {id: 329, name: "Professor(a) de Filosofia"},
  {id: 330, name: "Professor(a) de Física"},
  {id: 331, name: "Professor(a) de Geografia"},
  {id: 332, name: "Professor(a) de História"},
  {id: 333, name: "Professor(a) de Idiomas"},
  {id: 334, name: "Professor(a) de Informática"},
  {id: 335, name: "Professor(a) de Matemática"},
  {id: 336, name: "Professor(a) de Música"},
  {id: 337, name: "Professor(a) de Português"},
  {id: 338, name: "Professor(a) de Química"},
  {id: 339, name: "Professor(a) de Teatro"},
  {id: 340, name: "Professor(a) de Yoga"},
  {id: 341, name: "Programador(a)"},
  {id: 342, name: "Programador(a) de Jogos"},
  {id: 343, name: "Programador(a) Web"},
  {id: 344, name: "Psicanalista"},
  {id: 345, name: "Psicólogo(a)"},
  {id: 346, name: "Psicopedagogo(a)"},
  {id: 347, name: "Publicitário(a)"},
  {id: 348, name: "Quiroprático(a)"},
  {id: 349, name: "Radialista"},
  {id: 350, name: "Recrutador(a)"},
  {id: 351, name: "Relações Públicas"},
  {id: 352, name: "Restaurador(a)"},
  {id: 353, name: "Roteirista"},
  {id: 354, name: "Sapateiro(a)"},
  {id: 355, name: "Saúde e Assistência Social"},
  {id: 356, name: "Secretário(a)"},
  {id: 357, name: "Segurança"},
  {id: 358, name: "Segurança Cibernética"},
  {id: 359, name: "Segurança do Trabalho"},
  {id: 360, name: "Segurança Patrimonial"},
  {id: 361, name: "Segurança Pessoal"},
  {id: 362, name: "Serviços Gerais"},
  {id: 363, name: "Social Media Manager"},
  {id: 364, name: "Sociólogo(a)"},
  {id: 365, name: "Soldador(a)"},
  {id: 366, name: "Sonoplasta"},
  {id: 367, name: "Tatuador(a)"},
  {id: 368, name: "Taxidermista"},
  {id: 369, name: "Técnico(a) de Enfermagem"},
  {id: 370, name: "Técnico(a) de Informática"},
  {id: 371, name: "Técnico(a) de Manutenção"},
  {id: 372, name: "Técnico(a) de Som"},
  {id: 373, name: "Técnico(a) em Radiologia"},
  {id: 374, name: "Técnico(a) em Segurança do Trabalho"},
  {id: 375, name: "Terapeuta"},
  {id: 376, name: "Terapeuta Holístico(a)"},
  {id: 377, name: "Terapeuta Ocupacional"},
  {id: 378, name: "Tradutor(a)"},
  {id: 379, name: "Urbanista"},
  {id: 380, name: "Veterinário(a)"},
  {id: 381, name: "Vigilante"},
  {id: 382, name: "Web Designer"},
  {id: 383, name: "Youtube"},
];

export const positionsListES: IPosition[] = [
  {id: 1, name: "Abogado(a) Ambiental"},
  {id: 2, name: "Abogado(a) Penalista"},
  {id: 3, name: "Abogado(a) Laboral"},
  {id: 4, name: "Abogado(a) de Derechos Humanos"},
  {id: 5, name: "Abogado(a) de Propiedad Intelectual"},
  {id: 6, name: "Abogado(a) Tributario"},
  {id: 7, name: "Agente de Servicio al Cliente"},
  {id: 8, name: "Agroecólogo(a)"},
  {id: 9, name: "Agricultor(a)"},
  {id: 10, name: "Analista de Desarrollo de Negocios"},
  {id: 11, name: "Analista de Inteligencia de Negocios"},
  {id: 12, name: "Analista de Comercio Internacional"},
  {id: 13, name: "Analista de Datos"},
  {id: 14, name: "Analista de Mercado"},
  {id: 15, name: "Analista de Calidad"},
  {id: 16, name: "Analista de Redes Sociales"},
  {id: 17, name: "Analista de Seguridad de la Información"},
  {id: 18, name: "Analista de Soporte al Cliente"},
  {id: 19, name: "Analista de Soporte Técnico"},
  {id: 20, name: "Analista de Sistemas de Control"},
  {id: 21, name: "Analista de Sistemas Embebidos"},
  {id: 22, name: "Analista de Desarrollo Sostenible"},
  {id: 23, name: "Analista de E-commerce"},
  {id: 24, name: "Analista de Ingeniería de Productos"},
  {id: 25, name: "Analista de Finanzas Corporativas"},
  {id: 26, name: "Analista de Logística"},
  {id: 27, name: "Analista de Marketing Digital"},
  {id: 28, name: "Analista de Recursos Humanos"},
  {id: 29, name: "Analista de Riesgo de Crédito"},
  {id: 30, name: "Analista ESG"},
  {id: 31, name: "Analista Financiero"},
  {id: 32, name: "Analista Tributario"},
  {id: 33, name: "Arqueólogo(a)"},
  {id: 34, name: "Arqueólogo(a) Subacuático(a)"},
  {id: 35, name: "Arquitecto(a)"},
  {id: 36, name: "Arquitecto(a) Paisajista"},
  {id: 37, name: "Arquitecto(a) de Soluciones"},
  {id: 38, name: "Artista Visual"},
  {id: 39, name: "Asistente Administrativo"},
  {id: 40, name: "Asistente de Logística"},
  {id: 41, name: "Asistente de Recursos Humanos"},
  {id: 42, name: "Asistente de Supermercado"},
  {id: 43, name: "Atendiente de Restaurante"},
  {id: 44, name: "Actor"},
  {id: 45, name: "Bibliotecario(a)"},
  {id: 46, name: "Biólogo(a)"},
  {id: 47, name: "Biólogo(a) de Investigación"},
  {id: 48, name: "Cajero(a) de Supermercado"},
  {id: 49, name: "Diseñador(a) de Escenografía"},
  {id: 50, name: "Chef de Cocina"},
  {id: 51, name: "Científico de Datos"},
  {id: 52, name: "Coach"},
  {id: 53, name: "Auxiliar de Vuelo"},
  {id: 54, name: "Contador(a)"},
  {id: 55, name: "Coreógrafo(a)"},
  {id: 56, name: "Sepulturero(a)"},
  {id: 57, name: "Criminólogo(a)"},
  {id: 58, name: "Cronobiólogo(a)"},
  {id: 59, name: "Curador(a) de Arte"},
  {id: 60, name: "Curador(a) de Museo"},
  {id: 61, name: "Bailarín(a)"},
  {id: 62, name: "Dermatólogo(a)"},
  {id: 63, name: "Desarrollador Back-end C# Junior"},
  {id: 64, name: "Desarrollador Back-end C# Pleno"},
  {id: 65, name: "Desarrollador Back-end C# Senior"},
  {id: 66, name: "Desarrollador Back-end Go Junior"},
  {id: 67, name: "Desarrollador Back-end Go Pleno"},
  {id: 68, name: "Desarrollador Back-end Go Senior"},
  {id: 69, name: "Desarrollador Back-end Java Junior"},
  {id: 70, name: "Desarrollador Back-end Java Pleno"},
  {id: 71, name: "Desarrollador Back-end Java Senior"},
  {id: 72, name: "Desarrollador Back-end Kotlin Junior"},
  {id: 73, name: "Desarrollador Back-end Kotlin Pleno"},
  {id: 74, name: "Desarrollador Back-end Kotlin Senior"},
  {id: 75, name: "Desarrollador Back-end Node.js Junior"},
  {id: 76, name: "Desarrollador Back-end Node.js Pleno"},
  {id: 77, name: "Desarrollador Back-end Node.js Senior"},
  {id: 78, name: "Desarrollador Back-end Python Junior"},
  {id: 79, name: "Desarrollador Back-end Python Pleno"},
  {id: 80, name: "Desarrollador Back-end Python Senior"},
  {id: 81, name: "Desarrollador Back-end Ruby Junior"},
  {id: 82, name: "Desarrollador Back-end Ruby Pleno"},
  {id: 83, name: "Desarrollador Back-end Ruby Senior"},
  {id: 84, name: "Desarrollador Back-end Rust Junior"},
  {id: 85, name: "Desarrollador Back-end Rust Pleno"},
  {id: 86, name: "Desarrollador Back-end Rust Senior"},
  {id: 87, name: "Desarrollador Back-end Swift Junior"},
  {id: 88, name: "Desarrollador Back-end Swift Pleno"},
  {id: 89, name: "Desarrollador Back-end Swift Senior"},
  {id: 90, name: "Desarrollador Front-end Angular Junior"},
  {id: 91, name: "Desarrollador Front-end Angular Pleno"},
  {id: 92, name: "Desarrollador Front-end Angular Senior"},
  {id: 93, name: "Desarrollador Front-end React Junior"},
  {id: 94, name: "Desarrollador Front-end React Pleno"},
  {id: 95, name: "Desarrollador Front-end React Senior"},
  {id: 96, name: "Desarrollador Front-end Vue.js Junior"},
  {id: 97, name: "Desarrollador Front-end Vue.js Pleno"},
  {id: 98, name: "Desarrollador Front-end Vue.js Senior"},
  {id: 99, name: "Desarrollador Full stack Junior Angular + Node"},
  {id: 100, name: "Desarrollador Full stack Pleno Angular + Node"},
  {id: 101, name: "Desarrollador Full stack Senior Angular + Node"},
  {id: 102, name: "Desarrollador Full stack Junior Angular + Spring Boot"},
  {id: 103, name: "Desarrollador Full stack Pleno Angular + Spring Boot"},
  {id: 104, name: "Desarrollador Full stack Senior Angular + Spring Boot"},
  {id: 105, name: "Desarrollador Full stack Junior Angular + Swift"},
  {id: 106, name: "Desarrollador Full stack Pleno Angular + Swift"},
  {id: 107, name: "Desarrollador Full stack Senior Angular + Swift"},
  {id: 108, name: "Desarrollador Full stack Junior React + Node"},
  {id: 109, name: "Desarrollador Full stack Pleno React + Node"},
  {id: 110, name: "Desarrollador Full stack Senior React + Node"},
  {id: 111, name: "Desarrollador Full stack Junior React + Spring Boot"},
  {id: 112, name: "Desarrollador Full stack Pleno React + Spring Boot"},
  {id: 113, name: "Desarrollador Full stack Senior React + Spring Boot"},
  {id: 114, name: "Desarrollador Full stack Junior React + Swift"},
  {id: 115, name: "Desarrollador Full stack Pleno React + Swift"},
  {id: 116, name: "Desarrollador Full stack Senior React + Swift"},
  {id: 117, name: "Desarrollador Full stack Junior Vue.js + Node"},
  {id: 118, name: "Desarrollador Full stack Pleno Vue.js + Node"},
  {id: 119, name: "Desarrollador Full stack Senior Vue.js + Node"},
  {id: 120, name: "Desarrollador Full stack Junior Vue.js + Spring Boot"},
  {id: 121, name: "Desarrollador Full stack Pleno Vue.js + Spring Boot"},
  {id: 122, name: "Desarrollador Full stack Senior Vue.js + Spring Boot"},
  {id: 123, name: "Desarrollador Full stack Junior Vue.js + Swift"},
  {id: 124, name: "Desarrollador Full stack Pleno Vue.js + Swift"},
  {id: 125, name: "Desarrollador Full stack Senior Vue.js + Swift"},
  {id: 126, name: "Diseñador(a) de Moda"},
  {id: 127, name: "Diseñador(a) de Producto"},
  {id: 128, name: "Diseñador(a) de UI/UX"},
  {id: 129, name: "Diseñador(a) de Interiores"},
  {id: 130, name: "Diseñador(a) Gráfico"},
  {id: 131, name: "Diseñador(a) Industrial"},
  {id: 132, name: "Agente de Aduanas"},
  {id: 133, name: "Despachador(a) de Vuelo"},
  {id: 134, name: "Despachador(a) Documental"},
  {id: 135, name: "Detective Privado"},
  {id: 136, name: "Diplomático(a)"},
  {id: 137, name: "Director(a) Artístico(a)"},
  {id: 138, name: "Director(a) de Cine"},
  {id: 139, name: "Director(a) de Fotografía"},
  {id: 140, name: "Director(a) de Marketing"},
  {id: 141, name: "Director(a) de Operaciones"},
  {id: 142, name: "Director(a) de Teatro"},
  {id: 143, name: "Director(a) de Televisión"},
  {id: 144, name: "Director(a) Ejecutivo(a)"},
  {id: 145, name: "Director(a) Académico(a)"},
  {id: 146, name: "Director(a) de Arte"},
  {id: 147, name: "Director(a) de Compras"},
  {id: 148, name: "Director(a) de Contenido"},
  {id: 149, name: "Director(a) Creativo(a)"},
  {id: 150, name: "Director de Diseño"},
  {id: 151, name: "Director de Fotografía"},
  {id: 152, name: "Director de Operaciones Bancarias"},
  {id: 153, name: "Director de Recursos Humanos"},
  {id: 154, name: "Director de Ventas"},
  {id: 155, name: "Director(a) Financiero(a)"},
  {id: 156, name: "Director(a) Legal"},
  {id: 157, name: "Director(a) Técnico(a)"},
  {id: 158, name: "Economista"},
  {id: 159, name: "Economista Agrícola"},
  {id: 160, name: "Economista Conductual"},
  {id: 161, name: "Economista de Desarrollo"},
  {id: 162, name: "Economista Financiero"},
  {id: 163, name: "Economista Industrial"},
  {id: 164, name: "Economista Senior"},
  {id: 165, name: "Editor(a)"},
  {id: 166, name: "Editor(a) de Fotografía"},
  {id: 167, name: "Editor(a) de Video"},
  {id: 168, name: "Educador(a) Físico(a)"},
  {id: 169, name: "Educador(a) de la Primera Infancia"},
  {id: 170, name: "Electricista"},
  {id: 171, name: "Emprendedor(a)"},
  {id: 172, name: "Enfermero(a)"},
  {id: 173, name: "Ingeniero(a) Agrícola"},
  {id: 174, name: "Ingeniero(a) Ambiental"},
  {id: 175, name: "Ingeniero(a) Biomédico"},
  {id: 176, name: "Ingeniero(a) Civil"},
  {id: 177, name: "Ingeniero(a) de Alimentos"},
  {id: 178, name: "Ingeniero(a) de Automatización"},
  {id: 179, name: "Ingeniero(a) de Datos"},
  {id: 180, name: "Ingeniero(a) de Materiales"},
  {id: 181, name: "Ingeniero(a) de Minas"},
  {id: 182, name: "Ingeniero(a) de Producción"},
  {id: 183, name: "Ingeniero(a) de Seguridad Laboral"},
  {id: 184, name: "Ingeniero(a) de Software"},
  {id: 185, name: "Ingeniero(a) de Telecomunicaciones"},
  {id: 186, name: "Ingeniero(a) Eléctrico(a)"},
  {id: 187, name: "Ingeniero(a) Forestal"},
  {id: 188, name: "Ingeniero(a) Industrial"},
  {id: 189, name: "Ingeniero(a) Mecánico"},
  {id: 190, name: "Ingeniero(a) Químico(a)"},
  {id: 191, name: "Epidemiólogo(a)"},
  {id: 192, name: "Escritor(a)"},
  {id: 193, name: "Escritor(a) Creativo(a)"},
  {id: 194, name: "Especialista en Agricultura Sostenible"},
  {id: 195, name: "Especialista en Comercio Internacional"},
  {id: 196, name: "Especialista en ESG"},
  {id: 197, name: "Especialista en Salud Pública"},
  {id: 198, name: "Especialista en Seguridad Cibernética"},
  {id: 199, name: "Farmacéutico(a)"},
  {id: 200, name: "Físico(a)"},
  {id: 201, name: "Fisioterapeuta"},
  {id: 202, name: "Fotógrafo(a)"},
  {id: 203, name: "Fotógrafo(a) de Moda"},
  {id: 204, name: "Fotógrafo(a) de Naturaleza"},
  {id: 205, name: "Fotógrafo(a) de Productos"},
  {id: 206, name: "Fotógrafo(a) Publicitario(a)"},
  {id: 207, name: "Fotógrafo(a) de Viajes"},
  {id: 208, name: "Fotógrafo(a) Documental"},
  {id: 209, name: "Fotógrafo(a) Deportivo(a)"},
  {id: 210, name: "Fotógrafo(a) Social"},
  {id: 211, name: "Gastrónomo(a)"},
  {id: 212, name: "Geofísico(a)"},
  {id: 213, name: "Geógrafo(a)"},
  {id: 214, name: "Geólogo(a)"},
  {id: 215, name: "Gerente Administrativo(a)"},
  {id: 216, name: "Gerente de Ventas"},
  {id: 217, name: "Gerente de Compras"},
  {id: 218, name: "Gerente de Contenido"},
  {id: 219, name: "Gerente de Desarrollo de Negocios"},
  {id: 220, name: "Gerente de E-commerce"},
  {id: 221, name: "Gerente de Eventos"},
  {id: 222, name: "Gerente de Finanzas"},
  {id: 223, name: "Gerente de Flota"},
  {id: 224, name: "Gerente de Hospitalidad"},
  {id: 225, name: "Gerente de Marketing"},
  {id: 226, name: "Gerente de Operaciones"},
  {id: 227, name: "Gerente de Producto"},
  {id: 228, name: "Gerente de Proyectos"},
  {id: 229, name: "Gerente de Recursos Humanos"},
  {id: 230, name: "Gerente de Relaciones Públicas"},
  {id: 231, name: "Gerente de Restaurante"},
  {id: 232, name: "Gerente de Cadena de Suministro"},
  {id: 233, name: "Gerente de Tecnología de la Información"},
  {id: 234, name: "Gerente Educativo(a)"},
  {id: 235, name: "Gerente Financiero(a)"},
  {id: 236, name: "Gerente Operacional"},
  {id: 237, name: "Gerente de Calidad"},
  {id: 238, name: "Historiador(a)"},
  {id: 239, name: "Ilustrador(a)"},
  {id: 240, name: "Influencer Digital"},
  {id: 241, name: "Jardinero(a)"},
  {id: 242, name: "Periodista"},
  {id: 243, name: "Juez(a)"},
  {id: 244, name: "Lavaplatos"},
  {id: 245, name: "Lavador(a) de Autos"},
  {id: 246, name: "Subastador(a)"},
  {id: 247, name: "Líder de Proyecto"},
  {id: 248, name: "Lingüista"},
  {id: 249, name: "Maquillador(a)"},
  {id: 250, name: "Carpintero(a)"},
  {id: 251, name: "Médico(a)"},
  {id: 252, name: "Médico(a) Cardiólogo(a)"},
  {id: 253, name: "Médico(a) Cirujano(a)"},
  {id: 254, name: "Médico(a) Dermatólogo(a)"},
  {id: 255, name: "Médico(a) Endocrinólogo(a)"},
  {id: 256, name: "Médico(a) Gastroenterólogo(a)"},
  {id: 257, name: "Médico(a) Geriatra"},
  {id: 258, name: "Médico(a) Hematólogo(a)"},
  {id: 259, name: "Médico(a) Homeópata"},
  {id: 260, name: "Médico(a) Infectólogo(a)"},
  {id: 261, name: "Médico(a) Nefrólogo(a)"},
  {id: 262, name: "Médico(a) Neurocirujano(a)"},
  {id: 263, name: "Médico(a) Neurólogo(a)"},
  {id: 264, name: "Médico(a) Obstetra"},
  {id: 265, name: "Médico(a) Oftalmólogo(a)"},
  {id: 266, name: "Médico(a) Oncólogo(a)"},
  {id: 267, name: "Médico(a) Ortopedista"},
  {id: 268, name: "Médico(a) Otorrinolaringólogo(a)"},
  {id: 269, name: "Médico(a) Patólogo(a)"},
  {id: 270, name: "Médico(a) Neumólogo(a)"},
  {id: 271, name: "Médico(a) Psiquiatra"},
  {id: 272, name: "Médico(a) Radiólogo(a)"},
  {id: 273, name: "Médico(a) Reumatólogo(a)"},
  {id: 274, name: "Médico(a) Urólogo(a)"},
  {id: 275, name: "Maestro(a) de Ceremonias"},
  {id: 276, name: "Meteorólogo(a)"},
  {id: 277, name: "Personal Militar"},
  {id: 278, name: "Modelo"},
  {id: 279, name: "Músico(a)"},
  {id: 280, name: "Músico(a) de Orquesta"},
  {id: 281, name: "Músico(a) Callejero(a)"},
  {id: 282, name: "Músico(a) de Sesión"},
  {id: 283, name: "Músico(a) de Estudio"},
  {id: 284, name: "Educador(a) Musical"},
  {id: 285, name: "Músico(a) Freelance"},
  {id: 286, name: "Músico(a) Instrumentista"},
  {id: 287, name: "Nadador(a)"},
  {id: 288, name: "Naturalista"},
  {id: 289, name: "Neurocientífico(a)"},
  {id: 290, name: "Conserje"},
  {id: 291, name: "Nutricionista"},
  {id: 292, name: "Oceanógrafo(a)"},
  {id: 293, name: "Dentista"},
  {id: 294, name: "Gerente de Oficina"},
  {id: 295, name: "Oftalmólogo(a)"},
  {id: 296, name: "Cajero(a)"},
  {id: 297, name: "Operador(a) de Grúa"},
  {id: 298, name: "Operador(a) de Máquina"},
  {id: 299, name: "Operador(a) de Telemarketing"},
  {id: 300, name: "Operador(a) de Vuelo"},
  {id: 301, name: "Ortodontista"},
  {id: 302, name: "Osteópata"},
  {id: 303, name: "Panadero(a)"},
  {id: 304, name: "Paleontólogo(a)"},
  {id: 305, name: "Pedagogo(a)"},
  {id: 306, name: "Pediatra"},
  {id: 307, name: "Entrenador(a) Personal"},
  {id: 308, name: "Pescador(a)"},
  {id: 309, name: "Investigador(a)"},
  {id: 310, name: "Pianista"},
  {id: 311, name: "Piloto"},
  {id: 312, name: "Pintor(a)"},
  {id: 313, name: "Pizzero(a)"},
  {id: 314, name: "Podcaster"},
  {id: 315, name: "Oficial de Policía"},
  {id: 316, name: "Político(a)"},
  {id: 317, name: "Portero(a)"},
  {id: 318, name: "Alcalde/Alcaldesa"},
  {id: 319, name: "Productor(a) de Cine"},
  {id: 320, name: "Productor(a) de Eventos"},
  {id: 321, name: "Productor(a) de Moda"},
  {id: 322, name: "Productor(a) de Música"},
  {id: 323, name: "Productor(a) de TV"},
  {id: 324, name: "Profesor(a)"},
  {id: 325, name: "Profesor(a) de Arte"},
  {id: 326, name: "Profesor(a) de Biología"},
  {id: 327, name: "Profesor(a) de Danza"},
  {id: 328, name: "Profesor(a) de Educación Física"},
  {id: 329, name: "Profesor(a) de Filosofía"},
  {id: 330, name: "Profesor(a) de Física"},
  {id: 331, name: "Profesor(a) de Geografía"},
  {id: 332, name: "Profesor(a) de Historia"},
  {id: 333, name: "Profesor(a) de Idiomas"},
  {id: 334, name: "Profesor(a) de Informática"},
  {id: 335, name: "Profesor(a) de Matemáticas"},
  {id: 336, name: "Profesor(a) de Música"},
  {id: 337, name: "Profesor(a) de Portugués"},
  {id: 338, name: "Profesor(a) de Química"},
  {id: 339, name: "Profesor(a) de Teatro"},
  {id: 340, name: "Profesor(a) de Yoga"},
  {id: 341, name: "Programador(a)"},
  {id: 342, name: "Programador(a) de Videojuegos"},
  {id: 343, name: "Programador(a) Web"},
  {id: 344, name: "Psicoanalista"},
  {id: 345, name: "Psicólogo(a)"},
  {id: 346, name: "Psicopedagogo(a)"},
  {id: 347, name: "Publicista"},
  {id: 348, name: "Quiropráctico(a)"},
  {id: 349, name: "Locutor(a) de Radio"},
  {id: 350, name: "Reclutador(a)"},
  {id: 351, name: "Relaciones Públicas"},
  {id: 352, name: "Restaurador(a)"},
  {id: 353, name: "Guionista"},
  {id: 354, name: "Zapatero(a)"},
  {id: 355, name: "Salud y Atención Social"},
  {id: 356, name: "Secretario(a)"},
  {id: 357, name: "Seguridad"},
  {id: 358, name: "Ciberseguridad"},
  {id: 359, name: "Seguridad Laboral"},
  {id: 360, name: "Seguridad Patrimonial"},
  {id: 361, name: "Seguridad Personal"},
  {id: 362, name: "Servicios Generales"},
  {id: 363, name: "Gerente de Redes Sociales"},
  {id: 364, name: "Sociólogo(a)"},
  {id: 365, name: "Soldador(a)"},
  {id: 366, name: "Ingeniero(a) de Sonido"},
  {id: 367, name: "Tatuador(a)"},
  {id: 368, name: "Taxidermista"},
  {id: 369, name: "Técnico(a) en Enfermería"},
  {id: 370, name: "Técnico(a) en Informática"},
  {id: 371, name: "Técnico(a) de Mantenimiento"},
  {id: 372, name: "Técnico(a) de Sonido"},
  {id: 373, name: "Técnico(a) en Radiología"},
  {id: 374, name: "Técnico(a) en Seguridad Laboral"},
  {id: 375, name: "Terapeuta"},
  {id: 376, name: "Terapeuta Holístico(a)"},
  {id: 377, name: "Terapeuta Ocupacional"},
  {id: 378, name: "Traductor(a)"},
  {id: 379, name: "Urbanista"},
  {id: 380, name: "Veterinario(a)"},
  {id: 381, name: "Guardia de Seguridad"},
  {id: 382, name: "Diseñador(a) Web"},
  {id: 383, name: "Youtube"},
];

export const positionsListEN: IPosition[] = [
  {id: 1, name: "Environmental Lawyer"},
  {id: 2, name: "Criminal Lawyer"},
  {id: 3, name: "Labor Lawyer"},
  {id: 4, name: "Human Rights Lawyer"},
  {id: 5, name: "Intellectual Property Lawyer"},
  {id: 6, name: "Tax Lawyer"},
  {id: 7, name: "Customer Service Agent"},
  {id: 8, name: "Agroecologist"},
  {id: 9, name: "Farmer"},
  {id: 10, name: "Business Development Analyst"},
  {id: 11, name: "Business Intelligence Analyst"},
  {id: 12, name: "International Trade Analyst"},
  {id: 13, name: "Data Analyst"},
  {id: 14, name: "Market Analyst"},
  {id: 15, name: "Quality Analyst"},
  {id: 16, name: "Social Media Analyst"},
  {id: 17, name: "Information Security Analyst"},
  {id: 18, name: "Customer Support Analyst"},
  {id: 19, name: "Technical Support Analyst"},
  {id: 20, name: "Control Systems Analyst"},
  {id: 21, name: "Embedded Systems Analyst"},
  {id: 22, name: "Sustainable Development Analyst"},
  {id: 23, name: "E-commerce Analyst"},
  {id: 24, name: "Product Engineering Analyst"},
  {id: 25, name: "Corporate Finance Analyst"},
  {id: 26, name: "Logistics Analyst"},
  {id: 27, name: "Digital Marketing Analyst"},
  {id: 28, name: "Human Resources Analyst"},
  {id: 29, name: "Credit Risk Analyst"},
  {id: 30, name: "ESG Analyst"},
  {id: 31, name: "Financial Analyst"},
  {id: 32, name: "Tax Analyst"},
  {id: 33, name: "Archaeologist"},
  {id: 34, name: "Underwater Archaeologist"},
  {id: 35, name: "Architect"},
  {id: 36, name: "Landscape Architect"},
  {id: 37, name: "Solutions Architect"},
  {id: 38, name: "Visual Artist"},
  {id: 39, name: "Administrative Assistant"},
  {id: 40, name: "Logistics Assistant"},
  {id: 41, name: "Human Resources Assistant"},
  {id: 42, name: "Supermarket Assistant"},
  {id: 43, name: "Restaurant Attendant"},
  {id: 44, name: "Actor"},
  {id: 45, name: "Librarian"},
  {id: 46, name: "Biologist"},
  {id: 47, name: "Research Biologist"},
  {id: 48, name: "Supermarket Cashier"},
  {id: 49, name: "Set Designer"},
  {id: 50, name: "Head Chef"},
  {id: 51, name: "Data Scientist"},
  {id: 52, name: "Coach"},
  {id: 53, name: "Flight Attendant"},
  {id: 54, name: "Accountant"},
  {id: 55, name: "Choreographer"},
  {id: 56, name: "Grave Digger"},
  {id: 57, name: "Criminologist"},
  {id: 58, name: "Chronobiologist"},
  {id: 59, name: "Art Curator"},
  {id: 60, name: "Museum Curator"},
  {id: 61, name: "Dancer"},
  {id: 62, name: "Dermatologist"},
  {id: 63, name: "Junior C# Backend Developer"},
  {id: 64, name: "Mid-level C# Backend Developer"},
  {id: 65, name: "Senior C# Backend Developer"},
  {id: 66, name: "Junior Go Backend Developer"},
  {id: 67, name: "Mid-level Go Backend Developer"},
  {id: 68, name: "Senior Go Backend Developer"},
  {id: 69, name: "Junior Java Backend Developer"},
  {id: 70, name: "Mid-level Java Backend Developer"},
  {id: 71, name: "Senior Java Backend Developer"},
  {id: 72, name: "Junior Kotlin Backend Developer"},
  {id: 73, name: "Mid-level Kotlin Backend Developer"},
  {id: 74, name: "Senior Kotlin Backend Developer"},
  {id: 75, name: "Junior Node.js Backend Developer"},
  {id: 76, name: "Mid-level Node.js Backend Developer"},
  {id: 77, name: "Senior Node.js Backend Developer"},
  {id: 78, name: "Junior Python Backend Developer"},
  {id: 79, name: "Mid-level Python Backend Developer"},
  {id: 80, name: "Senior Python Backend Developer"},
  {id: 81, name: "Junior Ruby Backend Developer"},
  {id: 82, name: "Mid-level Ruby Backend Developer"},
  {id: 83, name: "Senior Ruby Backend Developer"},
  {id: 84, name: "Junior Rust Backend Developer"},
  {id: 85, name: "Mid-level Rust Backend Developer"},
  {id: 86, name: "Senior Rust Backend Developer"},
  {id: 87, name: "Junior Swift Backend Developer"},
  {id: 88, name: "Mid-level Swift Backend Developer"},
  {id: 89, name: "Senior Swift Backend Developer"},
  {id: 90, name: "Junior Angular Frontend Developer"},
  {id: 91, name: "Mid-level Angular Frontend Developer"},
  {id: 92, name: "Senior Angular Frontend Developer"},
  {id: 93, name: "Junior React Frontend Developer"},
  {id: 94, name: "Mid-level React Frontend Developer"},
  {id: 95, name: "Senior React Frontend Developer"},
  {id: 96, name: "Junior Vue.js Frontend Developer"},
  {id: 97, name: "Mid-level Vue.js Frontend Developer"},
  {id: 98, name: "Senior Vue.js Frontend Developer"},
  {id: 99, name: "Junior Angular + Node Fullstack Developer"},
  {id: 100, name: "Mid-level Angular + Node Fullstack Developer"},
  {id: 101, name: "Senior Angular + Node Fullstack Developer"},
  {id: 102, name: "Junior Angular + Spring Boot Fullstack Developer"},
  {id: 103, name: "Mid-level Angular + Spring Boot Fullstack Developer"},
  {id: 104, name: "Senior Angular + Spring Boot Fullstack Developer"},
  {id: 105, name: "Junior Angular + Swift Fullstack Developer"},
  {id: 106, name: "Mid-level Angular + Swift Fullstack Developer"},
  {id: 107, name: "Senior Angular + Swift Fullstack Developer"},
  {id: 108, name: "Junior React + Node Fullstack Developer"},
  {id: 109, name: "Mid-level React + Node Fullstack Developer"},
  {id: 110, name: "Senior React + Node Fullstack Developer"},
  {id: 111, name: "Junior React + Spring Boot Fullstack Developer"},
  {id: 112, name: "Mid-level React + Spring Boot Fullstack Developer"},
  {id: 113, name: "Senior React + Spring Boot Fullstack Developer"},
  {id: 114, name: "Junior React + Swift Fullstack Developer"},
  {id: 115, name: "Mid-level React + Swift Fullstack Developer"},
  {id: 116, name: "Senior React + Swift Fullstack Developer"},
  {id: 117, name: "Junior Vue.js + Node Fullstack Developer"},
  {id: 118, name: "Mid-level Vue.js + Node Fullstack Developer"},
  {id: 119, name: "Senior Vue.js + Node Fullstack Developer"},
  {id: 120, name: "Junior Vue.js + Spring Boot Fullstack Developer"},
  {id: 121, name: "Mid-level Vue.js + Spring Boot Fullstack Developer"},
  {id: 122, name: "Senior Vue.js + Spring Boot Fullstack Developer"},
  {id: 123, name: "Junior Vue.js + Swift Fullstack Developer"},
  {id: 124, name: "Mid-level Vue.js + Swift Fullstack Developer"},
  {id: 125, name: "Senior Vue.js + Swift Fullstack Developer"},
  {id: 126, name: "Fashion Designer"},
  {id: 127, name: "Product Designer"},
  {id: 128, name: "UI/UX Designer"},
  {id: 129, name: "Interior Designer"},
  {id: 130, name: "Graphic Designer"},
  {id: 131, name: "Industrial Designer"},
  {id: 132, name: "Customs Broker"},
  {id: 133, name: "Flight Dispatcher"},
  {id: 134, name: "Documentary Dispatcher"},
  {id: 135, name: "Private Detective"},
  {id: 136, name: "Diplomat"},
  {id: 137, name: "Artistic Director"},
  {id: 138, name: "Film Director"},
  {id: 139, name: "Cinematographer"},
  {id: 140, name: "Marketing Director"},
  {id: 141, name: "Operations Director"},
  {id: 142, name: "Theater Director"},
  {id: 143, name: "TV Director"},
  {id: 144, name: "Executive Director"},
  {id: 145, name: "Academic Director"},
  {id: 146, name: "Art Director"},
  {id: 147, name: "Purchasing Director"},
  {id: 148, name: "Content Director"},
  {id: 149, name: "Creative Director"},
  {id: 150, name: "Design Director"},
  {id: 151, name: "Photography Director"},
  {id: 152, name: "Banking Operations Director"},
  {id: 153, name: "HR Director"},
  {id: 154, name: "Sales Director"},
  {id: 155, name: "Financial Director"},
  {id: 156, name: "Legal Director"},
  {id: 157, name: "Technical Director"},
  {id: 158, name: "Economist"},
  {id: 159, name: "Agricultural Economist"},
  {id: 160, name: "Behavioral Economist"},
  {id: 161, name: "Development Economist"},
  {id: 162, name: "Financial Economist"},
  {id: 163, name: "Industrial Economist"},
  {id: 164, name: "Senior Economist"},
  {id: 165, name: "Editor"},
  {id: 166, name: "Photo Editor"},
  {id: 167, name: "Video Editor"},
  {id: 168, name: "Physical Educator"},
  {id: 169, name: "Early Childhood Educator"},
  {id: 170, name: "Electrician"},
  {id: 171, name: "Entrepreneur"},
  {id: 172, name: "Nurse"},
  {id: 173, name: "Agricultural Engineer"},
  {id: 174, name: "Environmental Engineer"},
  {id: 175, name: "Biomedical Engineer"},
  {id: 176, name: "Civil Engineer"},
  {id: 177, name: "Food Engineer"},
  {id: 178, name: "Automation Engineer"},
  {id: 179, name: "Data Engineer"},
  {id: 180, name: "Materials Engineer"},
  {id: 181, name: "Mining Engineer"},
  {id: 182, name: "Production Engineer"},
  {id: 183, name: "Safety Engineer"},
  {id: 184, name: "Software Engineer"},
  {id: 185, name: "Telecom Engineer"},
  {id: 186, name: "Electrical Engineer"},
  {id: 187, name: "Forestry Engineer"},
  {id: 188, name: "Industrial Engineer"},
  {id: 189, name: "Mechanical Engineer"},
  {id: 190, name: "Chemical Engineer"},
  {id: 191, name: "Epidemiologist"},
  {id: 192, name: "Writer"},
  {id: 193, name: "Creative Writer"},
  {id: 194, name: "Sustainable Agriculture Specialist"},
  {id: 195, name: "International Trade Specialist"},
  {id: 196, name: "ESG Specialist"},
  {id: 197, name: "Public Health Specialist"},
  {id: 198, name: "Cybersecurity Specialist"},
  {id: 199, name: "Pharmacist"},
  {id: 200, name: "Physicist"},
  {id: 201, name: "Physiotherapist"},
  {id: 202, name: "Photographer"},
  {id: 203, name: "Fashion Photographer"},
  {id: 204, name: "Nature Photographer"},
  {id: 205, name: "Product Photographer"},
  {id: 206, name: "Advertising Photographer"},
  {id: 207, name: "Travel Photographer"},
  {id: 208, name: "Documentary Photographer"},
  {id: 209, name: "Sports Photographer"},
  {id: 210, name: "Social Photographer"},
  {id: 211, name: "Gastronomist"},
  {id: 212, name: "Geophysicist"},
  {id: 213, name: "Geographer"},
  {id: 214, name: "Geologist"},
  {id: 215, name: "Administrative Manager"},
  {id: 216, name: "Sales Manager"},
  {id: 217, name: "Purchasing Manager"},
  {id: 218, name: "Content Manager"},
  {id: 219, name: "Business Development Manager"},
  {id: 220, name: "E-commerce Manager"},
  {id: 221, name: "Events Manager"},
  {id: 222, name: "Finance Manager"},
  {id: 223, name: "Fleet Manager"},
  {id: 224, name: "Hospitality Manager"},
  {id: 225, name: "Marketing Manager"},
  {id: 226, name: "Operations Manager"},
  {id: 227, name: "Product Manager"},
  {id: 228, name: "Project Manager"},
  {id: 229, name: "HR Manager"},
  {id: 230, name: "Public Relations Manager"},
  {id: 231, name: "Restaurant Manager"},
  {id: 232, name: "Supply Chain Manager"},
  {id: 233, name: "IT Manager"},
  {id: 234, name: "Educational Manager"},
  {id: 235, name: "Financial Manager"},
  {id: 236, name: "Operational Manager"},
  {id: 237, name: "Quality Manager"},
  {id: 238, name: "Historian"},
  {id: 239, name: "Illustrator"},
  {id: 240, name: "Digital Influencer"},
  {id: 241, name: "Gardener"},
  {id: 242, name: "Journalist"},
  {id: 243, name: "Judge"},
  {id: 244, name: "Dishwasher"},
  {id: 245, name: "Car Washer"},
  {id: 246, name: "Auctioneer"},
  {id: 247, name: "Project Leader"},
  {id: 248, name: "Linguist"},
  {id: 249, name: "Makeup Artist"},
  {id: 250, name: "Carpenter"},
  {id: 251, name: "Doctor"},
  {id: 252, name: "Cardiologist Doctor"},
  {id: 253, name: "Surgeon Doctor"},
  {id: 254, name: "Dermatologist Doctor"},
  {id: 255, name: "Endocrinologist Doctor"},
  {id: 256, name: "Gastroenterologist Doctor"},
  {id: 257, name: "Geriatrician Doctor"},
  {id: 258, name: "Hematologist Doctor"},
  {id: 259, name: "Homeopath Doctor"},
  {id: 260, name: "Infectious Disease Doctor"},
  {id: 261, name: "Nephrologist Doctor"},
  {id: 262, name: "Neurosurgeon Doctor"},
  {id: 263, name: "Neurologist Doctor"},
  {id: 264, name: "Obstetrician Doctor"},
  {id: 265, name: "Ophthalmologist Doctor"},
  {id: 266, name: "Oncologist Doctor"},
  {id: 267, name: "Orthopedist Doctor"},
  {id: 268, name: "Otolaryngologist Doctor"},
  {id: 269, name: "Pathologist Doctor"},
  {id: 270, name: "Pulmonologist Doctor"},
  {id: 271, name: "Psychiatrist Doctor"},
  {id: 272, name: "Radiologist Doctor"},
  {id: 273, name: "Rheumatologist Doctor"},
  {id: 274, name: "Urologist Doctor"},
  {id: 275, name: "Master of Ceremonies"},
  {id: 276, name: "Meteorologist"},
  {id: 277, name: "Military Personnel"},
  {id: 278, name: "Model"},
  {id: 279, name: "Musician"},
  {id: 280, name: "Orchestra Musician"},
  {id: 281, name: "Street Musician"},
  {id: 282, name: "Session Musician"},
  {id: 283, name: "Studio Musician"},
  {id: 284, name: "Music Educator"},
  {id: 285, name: "Freelance Musician"},
  {id: 286, name: "Instrumentalist Musician"},
  {id: 287, name: "Swimmer"},
  {id: 288, name: "Naturalist"},
  {id: 289, name: "Neuroscientist"},
  {id: 290, name: "Janitor"},
  {id: 291, name: "Nutritionist"},
  {id: 292, name: "Oceanographer"},
  {id: 293, name: "Dentist"},
  {id: 294, name: "Office Manager"},
  {id: 295, name: "Ophthalmologist"},
  {id: 296, name: "Cashier"},
  {id: 297, name: "Crane Operator"},
  {id: 298, name: "Machine Operator"},
  {id: 299, name: "Telemarketer"},
  {id: 300, name: "Flight Operator"},
  {id: 301, name: "Orthodontist"},
  {id: 302, name: "Osteopath"},
  {id: 303, name: "Baker"},
  {id: 304, name: "Paleontologist"},
  {id: 305, name: "Educator"},
  {id: 306, name: "Pediatrician"},
  {id: 307, name: "Personal Trainer"},
  {id: 308, name: "Fisherman"},
  {id: 309, name: "Researcher"},
  {id: 310, name: "Pianist"},
  {id: 311, name: "Pilot"},
  {id: 312, name: "Painter"},
  {id: 313, name: "Pizzaiolo"},
  {id: 314, name: "Podcaster"},
  {id: 315, name: "Police Officer"},
  {id: 316, name: "Politician"},
  {id: 317, name: "Doorman"},
  {id: 318, name: "Mayor"},
  {id: 319, name: "Film Producer"},
  {id: 320, name: "Events Producer"},
  {id: 321, name: "Fashion Producer"},
  {id: 322, name: "Music Producer"},
  {id: 323, name: "TV Producer"},
  {id: 324, name: "Teacher"},
  {id: 325, name: "Art Teacher"},
  {id: 326, name: "Biology Teacher"},
  {id: 327, name: "Dance Teacher"},
  {id: 328, name: "Physical Education Teacher"},
  {id: 329, name: "Philosophy Teacher"},
  {id: 330, name: "Physics Teacher"},
  {id: 331, name: "Geography Teacher"},
  {id: 332, name: "History Teacher"},
  {id: 333, name: "Language Teacher"},
  {id: 334, name: "Informatics Teacher"},
  {id: 335, name: "Math Teacher"},
  {id: 336, name: "Music Teacher"},
  {id: 337, name: "Portuguese Teacher"},
  {id: 338, name: "Chemistry Teacher"},
  {id: 339, name: "Theater Teacher"},
  {id: 340, name: "Yoga Teacher"},
  {id: 341, name: "Programmer"},
  {id: 342, name: "Game Programmer"},
  {id: 343, name: "Web Programmer"},
  {id: 344, name: "Psychoanalyst"},
  {id: 345, name: "Psychologist"},
  {id: 346, name: "Psychopedagogist"},
  {id: 347, name: "Advertiser"},
  {id: 348, name: "Chiropractor"},
  {id: 349, name: "Radio Broadcaster"},
  {id: 350, name: "Recruiter"},
  {id: 351, name: "Public Relations"},
  {id: 352, name: "Restorer"},
  {id: 353, name: "Screenwriter"},
  {id: 354, name: "Shoemaker"},
  {id: 355, name: "Health and Social Care"},
  {id: 356, name: "Secretary"},
  {id: 357, name: "Security"},
  {id: 358, name: "Cybersecurity"},
  {id: 359, name: "Occupational Safety"},
  {id: 360, name: "Property Security"},
  {id: 361, name: "Personal Security"},
  {id: 362, name: "General Services"},
  {id: 363, name: "Social Media Manager"},
  {id: 364, name: "Sociologist"},
  {id: 365, name: "Welder"},
  {id: 366, name: "Sound Engineer"},
  {id: 367, name: "Tattoo Artist"},
  {id: 368, name: "Taxidermist"},
  {id: 369, name: "Nursing Technician"},
  {id: 370, name: "IT Technician"},
  {id: 371, name: "Maintenance Technician"},
  {id: 372, name: "Sound Technician"},
  {id: 373, name: "Radiology Technician"},
  {id: 374, name: "Occupational Safety Technician"},
  {id: 375, name: "Therapist"},
  {id: 376, name: "Holistic Therapist"},
  {id: 377, name: "Occupational Therapist"},
  {id: 378, name: "Translator"},
  {id: 379, name: "Urbanist"},
  {id: 380, name: "Veterinarian"},
  {id: 381, name: "Security Guard"},
  {id: 382, name: "Web Designer"},
  {id: 383, name: "Youtube"},
];