import { TextField } from '@mui/material';
import styled from 'styled-components';

export const ContainerInput = styled(TextField)`
  resize: none;
  outline: none !important;
  width: 100%;
  margin-bottom: 10px;
  background: white;
  border: 1px solid #8c8c8c;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &.MuiInputBase-root {
    background: #fff;
    font-weight: 700;
    font-size: 18;
    color: #000;
  };
  
  &.MuiOutlinedInput-root {
    &:hover fieldset {
      border: 1px solid;
      border-color: #8C8C8C;
    };
    & fieldset {
      border: 1px solid;
      border-color: #8C8C8C;
    };
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #8C8C8C;
    };
  };

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;