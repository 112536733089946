import styled from "styled-components";

export const Container = styled.div<{
  isChallenge?: boolean;
  smallSize?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  progress[value] {
    width: ${({ isChallenge }) => (isChallenge ? "100%" : "calc(100% - 42px)")};
    height: ${({ smallSize }) => (smallSize ? "7px" : "10px")};
    appearance: none;
    border: none;

    ::-webkit-progress-bar {
      height: ${({ smallSize }) => (smallSize ? "7px" : "10px")};
      border-radius: 10px;
      overflow: hidden;
      background-color: #d9d9d9;
    }
    ::-webkit-progress-value {
      height: ${({ smallSize }) => (smallSize ? "7px" : "10px")};
      border-radius: 10px;
      background: linear-gradient(80deg, #ff6423 0%, #ff026f 100%);
    }
    &::-moz-progress-bar {
      height: ${({ smallSize }) => (smallSize ? "7px" : "10px")};
      border-radius: 10px;
      border: none;
      overflow: hidden;
      background: linear-gradient(80deg, #ff6423 0%, #ff026f 100%);
    }
    &::-moz-progress-value {
      border: none;
      height: ${({ smallSize }) => (smallSize ? "7px" : "10px")};
      border-radius: 10px;
      background: linear-gradient(80deg, #ff6423 0%, #ff026f 100%);
    }
  }
`;

export const Span = styled.span`
  color: #ff026f;
  font-weight: 500;
  text-align: right;
  font-size: 16px;
  width: 49px;
  margin-top: -4px;
`;
