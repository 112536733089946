import {TextField, FormControl, styled as styledMui} from "@mui/material";
import styled from "styled-components";
import {IInputResponsiveProps} from "../../components/ResponsiveInputText";

interface IPropsTextArea {
  borderColor: any;
}

export const FullScreen = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormCenter = styled.form`
  width: 100%;
  max-width: 1300px;
  padding: 40px 4%;
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 25px;
  background: white;
  border: 1px solid ${({borderColor}) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #8c8c8c;
  }
`;

export const ContainerGap = styled.div`
margin-top: 10px;
`;
export const ContainerButton = styled.div`
  width: 180px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 1049px) {
    width: 100%;
  }
`;

export const buttonPink = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  line-height: 32px;
  width: 290px;
  height: 52px;
  color: #ffffff;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);

  &:disabled {
    background: #8c8c8c;
    cursor: default;
  }
`;

export const ContainerDropDownSimpleSelect = styled.div`
  margin-bottom: 0.3rem;
  /* 
  img {
    position: relative;
    left: 60px;
    top: 5px;
  } */
`;

export const BoxSelect = styled.div`
  min-height: 40px;
  width: 42%;
  display: flex;
  margin: 10px;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 38px;
  p {
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 10px;
    color: #696969;
    border: 1px solid #8c8c8c;
    margin-top: 10px;
    display: flex;
    gap: 6px;
    margin-bottom: 12px;
    span {
      margin-top: 2px;
    }
  }
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
  gap: 5px;
`;

export const PageTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  margin-top: 30px;
`;

export const TextInfo = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 12px;
`;

export const PageSubtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
  margin-top: 16px;
  margin-bottom: 45px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: center;
  @media screen and (max-width: 1049px) {
    flex-direction: column;
    align-items: none;
    justify-content: center;
  }
`;

export const InputRow = styled.div<{rowsSize: number[]}>`
  margin-top: 10px;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 36px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const RemainSpace = styled.div`
  width: 100%;
`;

export const containerTermosDeUso = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
`;

export const ErrorMessage = styled.p`
  margin-top: 0.3rem;
  font-size: 14px;
  font-weight: 400;
  color: #f5222d;
`;

export const HeaderFullContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
`;

export const CenterByMaxSize = styled.div`
  width: 100%;
  padding: 0 4%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px;
`;

export const Logo = styled.img`
  height: 32px;
  @media screen and (max-width: 999px) {
    display: none;
  }
`;

export const LogoMin = styled.img`
  display: none;
  height: 32px;
  align-self: center;
  justify-self: center;
  @media screen and (max-width: 999px) {
    display: block;
  }
`;

export const FormMui = styledMui(FormControl)({
  width: "100%",
  height: "3.5rem",
  marginBottom: "2.3rem",
});

export const ButtonContainer = styled.div`
  margin-left: -8px;
  padding: 6px 8px;
  display: inline-flex;
  gap: 0.7rem;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  font-size: 1rem;
  color: #ff026f;
  font-weight: 500;
`;

export const CssTextField = styledMui(TextField)({
  borderRadius: "5px",
  fontWeight: "700",
  backgroundColor: "#fff",

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Ubuntu",
      },
    },
  },
});

export const UploadInput = styled.input`
  background-color: white;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 10;
  opacity: 0;
`;

export const UploadImageButton = styled.button`
  background-color: white;
  display: flex;
  gap: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 52px;
  border: 1px solid #131212;
  position: relative;
  background-color: #0000;

  .uploadIcon {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 425px) {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const ContainerOptions = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  grid-template-areas:
    "imageAndButton inputAndCheckbox"
    "imageAndButton inputAndCheckbox";
  margin-bottom: 2rem;

  .inputQuestionContent {
    width: 20.5rem;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 0.8fr 1.2fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1.2fr 0.8fr;
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

export const UploadButtonText = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #131212;
`;

export const IconRemoveImage = styled.img`
  cursor: pointer;
  width: 15px;
`;

export const ContainerRemoveImage = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
`;

export const containerCurriculo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonVisualizar = styled.button`
  cursor: pointer;
  display: flex;
  gap: 14px;
  flex-direction: row;
  margin-top: 31.5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  position: relative;
  border: solid #8c8c8c 1px;
  border-radius: 5px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #131313;
`;

export const UploadCurriculoButton = styled.button`
  display: flex;
  gap: 14px;
  flex-direction: row;
  margin-top: 31.5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  position: relative;
  border: solid #8c8c8c 1px;
  border-radius: 5px;

  .uploadIcon {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 425px) {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const removeImage = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  margin-left: 3px;
  color: red;
  font-size: 14px;
  cursor: pointer;
`;

export const FormControlTooltipWrapper = styled.div`
  max-width: 100%;
  display: flex;
  align-items: baseline;
  margin-right: 10px;
`;
