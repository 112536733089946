import { t } from 'i18next';
import downloadImg from "../../assets/download.svg";
import skillHunterImg from "../../assets/logo-firedev.png";
import openExternalWindowImg from "../../assets/openExternalWindow.svg";
import { IStudyArticle } from '../../models/StudyArticle';
import { acessToken, axiosApi } from '../../services/api';
import * as S from './style';

interface IStudyArticleItem {
    article: IStudyArticle
}

const StudyArticleItem = (data: IStudyArticleItem) => {

    const { article } = data;

    function toggleTypeOfArticle(articleType) {
        switch (articleType) {
            case "ARTICLE":
                return t("challenge.article");

            case "LINK":
                return "Link";
        }
    }

    async function handleDownload({ studyArticleId, fileName }) {
        const result = await axiosApi({
            url: `study-articles/${studyArticleId}/downloadFile/`,
            method: "get",
            responseType: "blob",
            headers: {
                Authorization: acessToken(),
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
        return result;
    }

    return (
        <S.ContainerCard
            data-cy="studyarticle-card"
            onClick={
                article.type === "ARTICLE"
                    ? () =>
                        handleDownload({
                            studyArticleId: article.id,
                            fileName: article?.file?.fileName,
                        })
                    : () => null
            }
            href={article?.type === "LINK" ? article?.url : null}
            target="blank"
        >
            <S.ImageList
                src={
                    article?.file?.fileUrl
                        ? article?.file?.fileUrl
                        : skillHunterImg
                }
                onError={(event) =>
                    (event.currentTarget.src = skillHunterImg)
                }
            />
            <S.NameOfArticle title={article?.name}>
                {article?.name}
            </S.NameOfArticle>
            <S.SubtitleOfArticle>
                {toggleTypeOfArticle(article?.type)}
            </S.SubtitleOfArticle>
            <S.CardLink>
                <S.ImageDownload
                    src={
                        article?.type === "LINK"
                            ? openExternalWindowImg
                            : downloadImg
                    }
                />
            </S.CardLink>
        </S.ContainerCard>)
}

export default StudyArticleItem;