/* eslint-disable class-methods-use-this */
import axios from "axios";
import { axiosApi } from "./api";
import UrlService from "./UrlService";
import TenantService from "./TenantService";

const expiresAt = 60 * 24;

class AuthService {
  async login(email: string, password: string) {
    localStorage.removeItem("tenant");
    localStorage.removeItem("tenantObj");
    const response = await axios.post(UrlService.loginUrl(), {
      email,
      password,
    });
    if (response.data.token) {
      await this.setValuesStorage(response.data);
    }
    return response.data;
  }

  async logout() {
    const token = localStorage.getItem("token")
    const language = localStorage.getItem("language") || "pt"
    localStorage.clear()
    localStorage.setItem("language", language)
    const axiosResponse = await axiosApi.put("/auth/logout", {}, { headers: { Authorization: token } });
    return axiosResponse;

  }

  async linkedinLogin(token: string) {
    const response = await axios.post(UrlService.linkedinUrl(), {
      token,
    });
    if (response.data.token) {
      this.setValuesStorage(response.data);
    }
    return response.data;
  }

  async googleLogin(token: string) {
    const response = await axios.post(UrlService.googleUrl(), {
      token,
    });
    if (response.data.token) {
      this.setValuesStorage(response.data);
    }
    return response.data;
  }

  private async setValuesStorage(data: any) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("userRoles", JSON.stringify(data.roles));
    const tenantsRes = await TenantService.getMyCompanies();
    localStorage.setItem("tenant", tenantsRes[0].id.toString());
    localStorage.setItem("tenantObj", JSON.stringify(tenantsRes[0]));
  }
}

export default new AuthService();
