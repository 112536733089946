import styled from "styled-components";

import { TextField, styled as styledMui } from "@mui/material";

export const ContainerStyleTable = styled.div`
  .titleColumn {
    border: solid black 1px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #131212;
    line-height: 32px;
  }

  .tableRowCell:nth-child(1) {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
  }

  .tableRowCell:nth-child(1)::before {
    content: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9.00008%200.55957C4.40008%200.55957%200.666748%204.2929%200.666748%208.8929C0.666748%2013.4929%204.40008%2017.2262%209.00008%2017.2262C13.6001%2017.2262%2017.3334%2013.4929%2017.3334%208.8929C17.3334%204.2929%2013.6001%200.55957%209.00008%200.55957ZM4.89175%2014.1262C5.25008%2013.3762%207.43341%2012.6429%209.00008%2012.6429C10.5667%2012.6429%2012.7584%2013.3762%2013.1084%2014.1262C11.9751%2015.0262%2010.5501%2015.5596%209.00008%2015.5596C7.45008%2015.5596%206.02508%2015.0262%204.89175%2014.1262ZM14.3001%2012.9179C13.1084%2011.4679%2010.2167%2010.9762%209.00008%2010.9762C7.78341%2010.9762%204.89175%2011.4679%203.70008%2012.9179C2.85008%2011.8012%202.33341%2010.4096%202.33341%208.8929C2.33341%205.2179%205.32508%202.22624%209.00008%202.22624C12.6751%202.22624%2015.6667%205.2179%2015.6667%208.8929C15.6667%2010.4096%2015.1501%2011.8012%2014.3001%2012.9179V12.9179ZM9.00008%203.8929C7.38341%203.8929%206.08341%205.1929%206.08341%206.80957C6.08341%208.42624%207.38341%209.72624%209.00008%209.72624C10.6167%209.72624%2011.9167%208.42624%2011.9167%206.80957C11.9167%205.1929%2010.6167%203.8929%209.00008%203.8929ZM9.00008%208.05957C8.30841%208.05957%207.75008%207.50124%207.75008%206.80957C7.75008%206.1179%208.30841%205.55957%209.00008%205.55957C9.69175%205.55957%2010.2501%206.1179%2010.2501%206.80957C10.2501%207.50124%209.69175%208.05957%209.00008%208.05957Z%22%20fill%3D%22%238C8C8C%22%2F%3E%0A%3C%2Fsvg%3E%0A);
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

export const SpanValidation = styled.span`
  color: red !important;
  font-family: "Ubuntu" !important;
  padding: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

export const ContainerTabs = styled.span`
  display: flex;
  flex-direction: row;
  margin-top: 75px;
`;

export const NameTableRow = styled.span`
  text-decoration-line: underline;
  color: #ff026f;
`;

export const CompanyIcon = styled.img`
  width: 25px;
  height: 25px;
  left: 0px;
  top: 29.48px;
  object-fit: cover;
  border-radius: 50%;
`;

export const UpdatedAt = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  span:nth-of-type(2) {
    color: #8c8c8c;
  }
`;

export const ContainerIcons = styled.div`
  width: 37%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 30px;
  gap: 15px;
`;

export const ImageTable = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const ContainerButtonAndIcons = styled.div`
  padding-top: 20px;
  background-color: white;
  margin-top: 50px;
  width: 914px;
  display: flex;
  justify-content: space-between;
`;

export const ContainerButton = styled.div`
  padding-left: 20px;
`;

export const ContainerTableMUI = styled.div`
  tbody tr td:nth-of-type(3) {
    white-space: pre-line;
  }

  tbody tr td:not(tbody tr td:nth-of-type(3)) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .MuiTableFooter-root {
    width: 914px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
`;

export const CssTextField = styledMui(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8C8C8C",
    border: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#8C8C8C",
      border: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8C8C8C",
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Ubuntu",
      },
    },
  },
});
