import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useReducer, useState } from "react";
import * as S from "./styles";

export type Card = {
  createdAt?: string;
  id: number;
  level?: number;
  position?: number;
  name?: string;
  description?: string;
  status?: string;
  score?: number;
  mandatory?: boolean;
  slug?: string;
};

export const exampleList: Card[] = [
  {
    id: 1,
    level: 1,
    position: 1,
    name: "HTML",
  },
  {
    id: 2,
    level: 1,
    position: 2,
    name: "CSS",
  },
  { id: 3, level: 2, position: 1, name: "Semantic HTML" },
  { id: 4, level: 2, position: 2, name: "HTML Forms" },
  { id: 5, level: 2, position: 3, name: "Accessibility" },
  { id: 6, level: 3, position: 1, name: "Box Model" },
  { id: 7, level: 3, position: 2, name: "Layouts" },
  {
    id: 8,
    level: 4,
    position: 1,
    name: "Preprocessors",
  },
  { id: 9, level: 5, position: 1, name: "Sass" },
  { id: 10, level: 5, position: 2, name: "Less" },
];

type Levels = {
  [k: number]: Card[];
};

function groupBy(arr: Card[], col: string): Levels {
  if (!arr?.length) {
    return {};
  }
  return arr.reduce((result, currentValue) => {
    (result[currentValue[col]] = result[currentValue[col]] || []).push(
      currentValue
    );
    return result;
  }, {});
}

function sortGroup(group: Levels | null): Levels {
  const sorted = Object.entries(group).sort((a: any, b: any) => a[0] - b[0]);
  sorted.forEach((level, idx) => {
    const levelNumber = idx + 1;
    level[0] = levelNumber.toString();
    level[1].forEach((skill) => {
      skill.level = levelNumber;
    });
  });
  return Object.fromEntries(sorted);
}

function sortBy(arr: Card[] | null, col: string) {
  if (!arr) {
    return [];
  }
  return arr.sort((a, b) => (a[col] > b[col] ? 1 : -1));
}

export function reorderLevel(skills: Card[], level: number) {
  let pos = 1;
  const reorder = sortBy(
    skills.filter((skill) => skill.level === level),
    "position"
  );
  reorder.forEach((skill) => {
    skill.position = pos;
    pos += 1;
  });
}

const statusColor = {
  ACHIEVED: "#00A676",
  UNLOCKED: "#302382",
  STARTED: "#302382",
  WAITING_EVALUATION: "#FF9900",
  LOCKED: "#C4C4C4",
  FAILED: "#ef3250",
  undefined: "#696969",
  null: "#696969",
};

type Props = {
  skills: Card[] | null;
  draggable?: boolean;
  onChange?: (newArr: Card[]) => void;
  onClick?: (card: Card) => void;
  onRemove?: (card: Card) => void;
  onMandatory?: (card: Card) => void;
  isEdit?: boolean;
};

function CardTree({
  skills,
  draggable,
  onChange,
  onClick,
  onRemove,
  onMandatory,
  isEdit,
}: Props) {
  const [draggingCard, setDraggingCard] = useState<Card>(null);
  const forceUpdate = useReducer(() => ({}), {})[1] as () => void;

  const handleDragStart = (card: Card) => {
    setDraggingCard(card);
  };

  const handleDragEnd = () => {
    setDraggingCard(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDropOnLevel = (
    event: any,
    tLevel: number,
    tlevelSize: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!draggable) {
      return;
    }
    if (draggingCard !== null && tLevel !== null) {
      const dLevel = draggingCard.level;
      if (tLevel !== dLevel) {
        draggingCard.position = tlevelSize + 1;
        draggingCard.level = tLevel;
      } else {
        draggingCard.position =
          sortGroup(groupBy(skills, "level"))[tLevel].length + 1;
      }
      reorderLevel(skills, tLevel);
      if (dLevel !== tLevel) {
        reorderLevel(skills, dLevel);
      }
      forceUpdate();
    }
  };

  const handleDrop = (
    event,
    tLevel: number,
    tPosition: number,
    targetCard: Card
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (!draggable) {
      return;
    }
    if (draggingCard !== null && targetCard !== null) {
      const dLevel = draggingCard.level;
      const dPosition = draggingCard.position;
      if (tLevel !== dLevel) {
        targetCard.position += 1;
        draggingCard.position = tPosition;
        draggingCard.level = tLevel;
      } else {
        if (dPosition < tPosition) {
          skills
            .filter(
              (skill) => skill.level === tLevel && skill.position <= tPosition
            )
            .forEach((s) => {
              s.position -= 1;
            });
        } else {
          skills
            .filter(
              (skill) => skill.level === tLevel && skill.position >= tPosition
            )
            .forEach((s) => {
              s.position += 1;
            });
        }
        draggingCard.position = tPosition;
      }
      reorderLevel(skills, tLevel);
      if (dLevel !== tLevel) {
        reorderLevel(skills, dLevel);
      }
      forceUpdate();
    }
  };

  function getLastLevel(skill: Card[]) {
    return skill.reduce(
      (lastLevel, s) => (s.level > lastLevel ? s.level : lastLevel),
      0
    );
  }

  useEffect(() => {
    if (skills) {
      reorderLevel(skills, 1);
    }
  }, []);

  useEffect(() => {
    if (skills) {
      reorderLevel(skills, getLastLevel(skills));
    }
  }, [skills]);

  return (
    <S.ContainerAllCards>
      {onChange && (
        <S.RearrangeMessage>
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            {t("cardTree.dragCards")}
          </p>
        </S.RearrangeMessage>
      )}
      <>
        {Object.entries(sortGroup(groupBy(skills, "level")))?.map(
          (row, levelIndex) => {
            const levelArr = row[1];
            const allSkills = sortBy(levelArr, "position");
            const allStatus = [
              { status: "ACHIEVED", color: "#00A676" },
              { status: "UNLOCKED", color: "#302382" },
              { status: "FAILED", color: "#f13251" },
              { status: "STARTED", color: "#302382" },
              { status: "WAITING_EVALUATION", color: "#FF9900" },
              { status: "LOCKED", color: "#C4C4C4" },
            ];
            let lineStatusColor = "#696969";
            allStatus?.map((status) => {
              if (allSkills.every((item) => item?.status === status?.status)) {
                lineStatusColor = status?.color;
              }
            });
            return (
              <S.AbaLineRowCards
                key={`r-${row[0]}`}
                onDragOver={(event) => handleDragOver(event)}
                onDragEnter={(event) =>
                  handleDropOnLevel(event, levelIndex + 1, levelArr.length)
                }
                onDrop={() => onChange && onChange(skills)}
                className="list-container"
              >
                {levelArr.length > 0 ? (
                  <>
                    <S.ContainerLineCircle>
                      <S.LineStatusCircle color={lineStatusColor} />
                      {levelIndex <
                        Object.entries(sortGroup(groupBy(skills, "level")))
                          .length -
                          1 && <S.VerticalLine />}
                    </S.ContainerLineCircle>
                    <S.CardsLineContainer data-cy="skillTreeSkill">
                      {allSkills.map((skill, positionIndex) => (
                        <S.Card
                          data-cy="positionCard"
                          onClick={() => onClick && onClick(skill)}
                          key={skill.id}
                          className={`card card-${skill.id}`}
                          borderColor={statusColor[`${skill?.status}`]}
                          hasQuestions
                          draggable={draggable}
                          onDragEnter={(event) =>
                            handleDrop(
                              event,
                              levelIndex + 1,
                              positionIndex + 1,
                              skill
                            )
                          }
                          onDragStart={() => handleDragStart(skill)}
                          onDragEnd={() => handleDragEnd()}
                          onDragOver={(event) => handleDragOver(event)}
                          onDrop={() => onChange && onChange(skills)}
                        >
                          <>
                            <S.CardTitleContainer>
                              <S.CardTitle
                                color={statusColor[`${skill?.status}`]}
                              >
                                {skill?.name}
                              </S.CardTitle>
                              <div style={{ display: "flex" }}>
                                {onMandatory && (
                                  <S.RemoveButton
                                    onClick={() =>
                                      onMandatory && onMandatory(skill)
                                    }
                                  >
                                    <Tooltip
                                      title={t(
                                        "positionsManagement.markSkillMandatory"
                                      )}
                                      arrow
                                      placement="top"
                                    >
                                      {skill?.mandatory ? (
                                        <StarIcon />
                                      ) : (
                                        <StarOutlineIcon />
                                      )}
                                    </Tooltip>
                                  </S.RemoveButton>
                                )}
                                {onRemove && (
                                  <S.RemoveButton
                                    onClick={() => onRemove && onRemove(skill)}
                                  >
                                    <CloseIcon />
                                  </S.RemoveButton>
                                )}
                              </div>
                            </S.CardTitleContainer>
                            <S.CardDescription
                              color={statusColor[`${skill?.status}`]}
                            >
                              {skill?.description}
                            </S.CardDescription>
                          </>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <S.CardStatus
                              data-cy="cardStatus"
                              color={statusColor[`${skill?.status}`]}
                            >
                              {skill?.status === "ACHIEVED" ? (
                                `${t("courseDetails.concluded")}`
                              ) : skill?.status === "UNLOCKED" ? (
                                t("courseDetails.available")
                              ) : skill?.status === "STARTED" ? (
                                t("courseDetails.started")
                              ) : skill?.status === "WAITING_EVALUATION" ? (
                                t("courseDetails.inRevision")
                              ) : skill?.status === "LOCKED" ? (
                                t("courseDetails.dependent")
                              ) : skill?.status === "FAILED" ? (
                                <Tooltip
                                  title={
                                    "Muitas tentativas falhas, aguarde para tentar novamente."
                                  }
                                >
                                  <span>
                                    {t("courseDetails.failed")}
                                    <br />
                                    {skill?.createdAt}
                                  </span>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </S.CardStatus>
                            {skill?.mandatory &&
                              (skill?.status === "LOCKED" ||
                                skill?.status === "UNLOCKED") && (
                                <S.CardStatus
                                  color={statusColor[`${skill?.status}`]}
                                >
                                  {t("courseDetails.mandatory")}
                                </S.CardStatus>
                              )}
                            {skill?.status === "ACHIEVED" && !isEdit && (
                              <S.CardStatus
                                color={statusColor[`${skill?.status}`]}
                              >
                                {skill?.score}/10
                              </S.CardStatus>
                            )}
                          </div>
                        </S.Card>
                      ))}
                    </S.CardsLineContainer>
                  </>
                ) : (
                  <S.WithoutSkills data-cy="atLeastOneSkillMessage">
                    {t("cardTree.selectSkill")}
                  </S.WithoutSkills>
                )}
              </S.AbaLineRowCards>
            );
          }
        )}
      </>
      {onChange && (
        <S.NewLevel
          onDragOver={(event) => handleDragOver(event)}
          onDrop={(event) => {
            handleDropOnLevel(
              event,
              +Object.entries(sortGroup(groupBy(skills, "level"))).length + 1,
              1
            );
            if (onChange) {
              onChange(skills);
            }
          }}
        >
          {t("cardTree.dragHere")}
          <AddIcon />
        </S.NewLevel>
      )}
    </S.ContainerAllCards>
  );
}

export default CardTree;
