import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Box, Button, Chip, CircularProgress, Container } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import * as T from "../../Global/Typography";
import SetaGray from "../../assets/setaGray.svg";
import DefaultLoading from "../../components/Loading/defaultLoading";
import * as AITranslationService from "../../services/AITranslationService";
import * as S from "./styles";

interface EditingTranslationData {
  id: number;
  content?: string;
  description?: string;
  options?: {
    id: number;
    content: string;
  }[];
  name?: string;
  title?: string;
}

export default function ApproveTranslations() {
  const DataType = {
    TYPE_SKILL: t("courseDetails.skill"),
    TYPE_COMPETENCE: t("courseDetails.competence"),
    TYPE_ARTICLE: t("artigos.artigosDeEstudo"),
    TYPE_QUESTION: t("sidebar.questions"),
    TYPE_POSITION: t("sidebar.positions"),
  };

  const Languages = {
    PORTUGUESE: "Português",
    SPANISH_AR: "Español",
    ENGLISH: "English",
  };

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("");
  const [accordionList, setAccordionList] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const fetchPendingData = async () => {
      const promises = [
        await AITranslationService.getAllSkillsPending(),
        await AITranslationService.getAllSkillTreesPending(),
        await AITranslationService.getAllArticlesPending(),
        await AITranslationService.getAllFormationsPending(),
        await AITranslationService.getAllQuestionsPending(),
      ];

      return Promise.all(promises);
    };

    const [
      pendingSkills,
      pendingSkillTrees,
      pendingArticles,
      pendingPositions,
      pendingQuestions,
    ] = await fetchPendingData();

    const accordions = [
      { type: DataType.TYPE_SKILL, data: pendingSkills },
      { type: DataType.TYPE_COMPETENCE, data: pendingSkillTrees },
      { type: DataType.TYPE_ARTICLE, data: pendingArticles },
      { type: DataType.TYPE_POSITION, data: pendingPositions },
      { type: DataType.TYPE_QUESTION, data: pendingQuestions },
    ];

    setAccordionList(accordions);

    setLoading(false);
  }, [
    DataType.TYPE_ARTICLE,
    DataType.TYPE_COMPETENCE,
    DataType.TYPE_POSITION,
    DataType.TYPE_QUESTION,
    DataType.TYPE_SKILL,
  ]);

  async function approveTranslation(type, data) {
    const serviceMapping = {
      [DataType.TYPE_SKILL]: AITranslationService.approveSkillTranslation,
      [DataType.TYPE_COMPETENCE]:
        AITranslationService.approveSkillTreesTranslation,
      [DataType.TYPE_ARTICLE]: AITranslationService.approveArticlesTranslation,
      [DataType.TYPE_QUESTION]:
        AITranslationService.approveQuestionsTranslation,
      [DataType.TYPE_POSITION]:
        AITranslationService.approveFormationsTranslation,
    }[type];

    if (serviceMapping) {
      await serviceMapping(data);
    }

    await fetchData();
  }

  async function retryTranslation(type, id) {
    const serviceMapping = {
      [DataType.TYPE_SKILL]: AITranslationService.retrySkillTranslation,
      [DataType.TYPE_COMPETENCE]:
        AITranslationService.retrySkillTreesTranslation,
      [DataType.TYPE_ARTICLE]: AITranslationService.retryArticlesTranslation,
      [DataType.TYPE_QUESTION]: AITranslationService.retryQuestionsTranslation,
      [DataType.TYPE_POSITION]: AITranslationService.retryFormationsTranslation,
    }[type];

    if (serviceMapping) {
      await serviceMapping(id);
    }

    await fetchData();
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // #### Bora fazer isso editável ####
  // State to manage edit mode

  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState<EditingTranslationData>({
    id: null,
    content: "",
    options: [],
    name: "",
    title: "",
    description: "",
  });

  // Function to handle edit
  const handleEditTranslation = (translationData, type) => {
    if (type === DataType.TYPE_QUESTION) {
      setEditingData({
        id: translationData.translation.id,
        content: translationData.translation.content,
        options: translationData.options.map((option) => ({
          id: option.translation.id,
          content: option.translation.content,
        })),
      });
    }

    if (type === DataType.TYPE_SKILL) {
      setEditingData({
        id: translationData.translation.id,
        content: translationData.translation.content,
        name: translationData.translation.name,
      });
    }

    if (type === DataType.TYPE_POSITION) {
      setEditingData({
        id: translationData.translation.id,
        description: translationData.translation.description,
        title: translationData.translation.title,
      });
    }

    if (type === DataType.TYPE_ARTICLE || type === DataType.TYPE_COMPETENCE) {
      setEditingData({
        id: translationData.translation.id,
        name: translationData.translation.name,
      });
    }

    setIsEditing(true);
  };

  // Function to handle text change
  const handleContentChange = (event) => {
    setEditingData((prevData) => ({
      ...prevData,
      content: event.target.value,
    }));
  };

  const handleDescriptionChange = (event) => {
    setEditingData((prevData) => ({
      ...prevData,
      description: event.target.value,
    }));
  };

  const handleNameChange = (event) => {
    setEditingData((prevData) => ({
      ...prevData,
      name: event.target.value,
    }));
  };

  const handleTitleChange = (event) => {
    setEditingData((prevData) => ({
      ...prevData,
      title: event.target.value,
    }));
  };

  const handleOptionChange = (id, content) => {
    setEditingData((prevData) => ({
      ...prevData,
      options: prevData.options.map((option) =>
        option.id === id ? { id, content } : option,
      ),
    }));
  };

  // Function to handle save
  const handleSave = (translationType) => {
    setIsEditing(false);

    setAccordionList((prevList) => {
      const updatedList = [...prevList];
      const questionIndex = updatedList.findIndex(
        (item) => item.type === translationType,
      );
      if (questionIndex !== -1) {
        const questionContentIndex = updatedList[
          questionIndex
        ].data.content.findIndex(
          (contentItem) => contentItem.translation.id === editingData.id,
        );
        if (questionContentIndex !== -1) {
          if (
            translationType === DataType.TYPE_SKILL ||
            translationType === DataType.TYPE_QUESTION
          ) {
            updatedList[questionIndex].data.content[
              questionContentIndex
            ].translation = {
              ...updatedList[questionIndex].data.content[questionContentIndex]
                .translation,
              content: editingData.content,
            };

            if (translationType === DataType.TYPE_QUESTION) {
              updatedList[questionIndex].data.content[
                questionContentIndex
              ].options = editingData.options.map((option, index) => ({
                ...updatedList[questionIndex].data.content[questionContentIndex]
                  .options[index],
                translation: {
                  ...updatedList[questionIndex].data.content[
                    questionContentIndex
                  ].options[index].translation,
                  content: option.content,
                },
              }));
            }
          }

          if (
            translationType === DataType.TYPE_SKILL ||
            translationType === DataType.TYPE_COMPETENCE ||
            translationType === DataType.TYPE_ARTICLE
          ) {
            updatedList[questionIndex].data.content[
              questionContentIndex
            ].translation = {
              ...updatedList[questionIndex].data.content[questionContentIndex]
                .translation,
              name: editingData.name,
            };
          }

          if (translationType === DataType.TYPE_POSITION) {
            updatedList[questionIndex].data.content[
              questionContentIndex
            ].translation = {
              ...updatedList[questionIndex].data.content[questionContentIndex]
                .translation,
              title: editingData.title,
            };

            updatedList[questionIndex].data.content[
              questionContentIndex
            ].translation = {
              ...updatedList[questionIndex].data.content[questionContentIndex]
                .translation,
              description: editingData.description,
            };
          }

          updatedList[questionIndex].data.content[
            questionContentIndex
          ].modified = true;
        }
      }

      return updatedList;
    });
  };

  const isCurrentTranslationBeingEdited = (id) =>
    isEditing && editingData.id === id;

  return (
    <S.ContainerFullPage>
      <T.Display>{t("translations.approveTranslations")}</T.Display>
      <S.ContainerAccordions>
        {accordionList.map((accordion) => (
          <Accordion
            disableGutters
            expanded={expanded === accordion.type}
            onChange={handleChange(accordion.type)}
          >
            <AccordionSummary>
              <S.ContainerAccordionSummary>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  {accordion.type}
                  {accordion?.data?.totalElements > 0 && (
                    <Chip
                      label={accordion?.data?.totalElements}
                      color="warning"
                    />
                  )}
                </div>
                <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                  <S.AccordionSummarySetaImg
                    up={expanded === accordion.type}
                    src={SetaGray}
                    alt="Seta"
                  />
                </div>
              </S.ContainerAccordionSummary>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, m: 0, backgroundColor: "#f1f1f1" }}>
              {loading ? (
                <S.ContainerAccordionDetailsLoading>
                  <DefaultLoading color="#898989" />
                  <S.LoadingText>{t("positions.loading")}...</S.LoadingText>
                </S.ContainerAccordionDetailsLoading>
              ) : (
                <Box>
                  {accordion?.data?.content?.map((data) => (
                    <>
                      <Container>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            verticalAlign: "middle",
                          }}
                        >
                          <div style={{ width: "45%", padding: "5px" }}>
                            <h4>
                              {t("translations.original")} -{" "}
                              {Languages[data?.original?.language]}
                            </h4>
                            <br />
                            {(data?.original?.name ||
                              data?.original?.title) && (
                              <div>
                                <h5>{t("translations.title")}</h5>
                                <S.Paragraph>
                                  {data?.original?.name ||
                                    data?.original?.title}
                                </S.Paragraph>
                                <br />
                              </div>
                            )}
                            {(data?.original?.content ||
                              data?.original?.description) && (
                              <div>
                                <h5>
                                  {data?.original?.content
                                    ? t("translations.content")
                                    : t("translations.description")}
                                </h5>
                                <S.Paragraph
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      data?.original?.content ||
                                      data?.original?.description,
                                  }}
                                />
                                <br />
                              </div>
                            )}
                            {data?.options && (
                              <div>
                                <h5>{t("translations.options")}</h5>
                                {data?.options?.map((option) => (
                                  <div>
                                    <li style={{ marginTop: "2px" }}>
                                      {option?.original?.content}
                                    </li>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <Divider
                            orientation="vertical"
                            flexItem
                            variant="middle"
                          />
                          <div style={{ width: "45%", padding: "5px" }}>
                            <h4>
                              {t("translations.translation")} -{" "}
                              {Languages[data?.translation?.language]}
                            </h4>
                            <br />
                            {(data?.translation?.name ||
                              data?.translation?.title) && (
                              <div>
                                <h5>{t("translations.title")}</h5>
                                {isCurrentTranslationBeingEdited(
                                  data?.translation?.id,
                                ) ? (
                                  <S.EditContentTextArea
                                    value={
                                      data?.translation?.name
                                        ? editingData.name
                                        : editingData.title
                                    }
                                    onChange={
                                      data?.translation?.name
                                        ? handleNameChange
                                        : handleTitleChange
                                    }
                                  />
                                ) : (
                                  <S.Paragraph>
                                    {data?.translation?.name ||
                                      data?.translation?.title}
                                  </S.Paragraph>
                                )}
                                <br />
                              </div>
                            )}
                            {(data?.translation?.content ||
                              data?.translation?.description) && (
                              <div style={{ position: "relative" }}>
                                <h5>
                                  {data?.translation?.content
                                    ? t("translations.content")
                                    : t("translations.description")}
                                </h5>
                                {isCurrentTranslationBeingEdited(
                                  data?.translation?.id,
                                ) ? (
                                  <S.EditContentTextArea
                                    value={
                                      data?.translation?.content
                                        ? editingData.content
                                        : editingData.description
                                    }
                                    onChange={
                                      data?.translation?.content
                                        ? handleContentChange
                                        : handleDescriptionChange
                                    }
                                  />
                                ) : (
                                  <S.Paragraph
                                    dangerouslySetInnerHTML={{
                                      __html: data?.translation?.content
                                        ? data?.translation?.content
                                        : data?.translation?.description,
                                    }}
                                  />
                                )}
                                <br />
                              </div>
                            )}
                            {data?.options && (
                              <div>
                                <h5>{t("translations.options")}</h5>
                                {data?.options?.map((option, index) => (
                                  <div>
                                    {isCurrentTranslationBeingEdited(
                                      data?.translation?.id,
                                    ) ? (
                                      <S.EditOptionTextArea
                                        value={
                                          editingData.options[index].content
                                        }
                                        onChange={(e) =>
                                          handleOptionChange(
                                            option?.translation?.id,
                                            e.target.value,
                                          )
                                        }
                                      />
                                    ) : (
                                      <li>{option?.translation?.content}</li>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          {data?.translation?.approvalStatus === "PENDING" && (
                            <Box
                              style={{
                                minWidth: "120px",
                                width: "10%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box sx={{ padding: "5px" }}>
                                <Tooltip
                                  title={t("translations.reproveTranslation")}
                                  arrow
                                  placement="top"
                                >
                                  <Button
                                    variant="contained"
                                    color={
                                      isCurrentTranslationBeingEdited(
                                        data?.translation?.id,
                                      )
                                        ? "success"
                                        : "info"
                                    }
                                    onClick={
                                      isCurrentTranslationBeingEdited(
                                        data?.translation?.id,
                                      )
                                        ? () => handleSave(accordion.type)
                                        : () =>
                                            handleEditTranslation(
                                              data,
                                              accordion.type,
                                            )
                                    }
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              </Box>
                              {!isCurrentTranslationBeingEdited(
                                data?.translation?.id,
                              ) && (
                                <Box sx={{ padding: "5px" }}>
                                  <Tooltip
                                    title={t("translations.approveTranslation")}
                                    arrow
                                    placement="top"
                                  >
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={() => {
                                        approveTranslation(
                                          accordion.type,
                                          data,
                                        );
                                      }}
                                    >
                                      <DoneIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                              )}
                            </Box>
                          )}
                          {(data?.translation?.approvalStatus === "ERROR" ||
                            data?.translation?.approvalStatus ===
                              "REPROVED") && (
                            <Box
                              style={{
                                minWidth: "120px",
                                width: "10%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Box sx={{ padding: "5px" }}>
                                <Tooltip
                                  title={t("translations.retry")}
                                  arrow
                                  placement="top"
                                >
                                  <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => {
                                      retryTranslation(
                                        accordion.type,
                                        data?.translation?.id,
                                      );
                                    }}
                                  >
                                    <RefreshIcon />
                                  </Button>
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                          {data?.translation?.approvalStatus ===
                            "PROCESSING" && (
                            <Box
                              style={{
                                minWidth: "120px",
                                width: "10%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <CircularProgress />
                              <Box sx={{ padding: "5px" }}>
                                <Tooltip
                                  title="Atualizar"
                                  arrow
                                  placement="top"
                                >
                                  <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => {
                                      fetchData();
                                    }}
                                  >
                                    <RefreshIcon />
                                  </Button>
                                </Tooltip>
                              </Box>
                            </Box>
                          )}
                        </div>
                      </Container>
                      <Divider />
                    </>
                  ))}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </S.ContainerAccordions>
    </S.ContainerFullPage>
  );
}
