import styled from "styled-components";

export const ContainerCardsTrainingPlans = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: min-content;
  background: ${({equal}: { equal: boolean }) => equal ? '#FFF1DD' : '#ffffff'};
  transition: background 0.2s;
  padding-bottom: 12px;
  border-bottom: 1px solid #aeaeae;
  cursor: pointer;
  overflow: hidden;
  justify-content: space-between;
`;
export const ContentCard = styled.div`
  padding: 20px 25px 0 25px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const ContentCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentCardSubtitle = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const Title = styled.p`
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  font-size: 16px;
  color: #131212;
`;

export const Visible = styled.div`
  color: #696969;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CompanyName = styled.p`
  font-weight: 400;
  font-size: 14px;
`;

export const SubTitle = styled.p`
  font-weight: 300;
  font-size: 14px;
  max-width: 100%;
  color: #131212;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const CompanyIcon = styled.img`
  width: 25px;
  height: 25px;
  left: 0px;
  top: 29.48px;
  object-fit: cover;
  border-radius: 50%;
`;

export const FooterCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 25px 0 25px;
  height: min-content;
  justify-content: space-between;
`;

export const ContainerLocation = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: #8C8C8C;
`;

export const ContainerHover = styled.span`
  :hover {
    text-decoration: underline;
  }
`;
