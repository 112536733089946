import api from "./api";

class EvaluationService {
  async getAllEvaluations({ pageSize, pageNumber }) {
    const resultEvaluation = await api.get(
      `challenges/users?${`page=${pageNumber}&size=${pageSize}`}`
    );
    return {
      data: resultEvaluation.data.content.map((item: any) => {
        return {
          challengeId: item.challenge.id,
          user: item.user.name,
          userId: item.user.id,
          challenge: item.challenge.title,
          skill: item.challenge.skill.name,
          trail: item?.trail?.name || item?.formation?.title,
          result: item.result,
          finishDate: item.finishDate,
        };
      }),
      totalPages: resultEvaluation.data.totalPages,
      totalElements: resultEvaluation.data.totalElements,
      number: resultEvaluation.data.number,
      currentPage: resultEvaluation.data.pageable.pageNumber,
    };
  }

  async getFilteredEvaluations({
    userSelected,
    trailSelected,
    resultSelected,
    skillSelected,
    challengeSelected,
    visibilitySelected,
    pageNumber,
    pageSize,
    finishDateSelected,
    positionSelected,
    dateASC,
    dateDESC,
  }) {

    const resultEvaluation = await api.get(
      `challenges/users?${`page=${pageNumber}&size=${pageSize}`}${
        dateASC ? `&sort=finishDate,ASC` : ""
      }${dateDESC ? `&sort=finishDate,DESC` : ""}${
        challengeSelected ? `&challengeId=${challengeSelected}` : ""
      }${(visibilitySelected !== null && typeof visibilitySelected != 'undefined') ? `&visibility=${visibilitySelected}` : ""
      }${finishDateSelected ? `&sendDate=${finishDateSelected}` : ""}${
        positionSelected ? `&formationId=${positionSelected}` : ""
      }${resultSelected ? `&result=${resultSelected}` : ""}${
        skillSelected ? `&skillId=${skillSelected}` : ""
      }${trailSelected ? `&trailId=${trailSelected}` : ""}${
        userSelected ? `&user=${userSelected}` : ""
      }`
    );

    return {
      data: resultEvaluation.data.content.map((item: any) => {
        return {
          skillChallengeId: item?.id,
          challengeId: item.challenge.id,
          user: item.user.name,
          userId: item.user.id,
          challenge: item.challenge.title,
          skill: item.challenge.skill.name,
          trail: item?.trail?.name || item?.formation?.title,
          result: item.result,
          finishDate: item.finishDate,
          evaluationDate: item.evaluationDate,
        };
      }),
      totalPages: resultEvaluation.data.totalPages,
      totalElements: resultEvaluation.data.totalElements,
      number: resultEvaluation.data.number,
      currentPage: resultEvaluation.data.pageable.pageNumber,
    };
  }

  async getChallengeByIdAndUserId(skillChallengeId) {
    const resultChallengeByIdAndUserId = await api.get(
      `challenges/findSkillChallengeToCorrect/${skillChallengeId}`
    );
    return resultChallengeByIdAndUserId.data;
  }

  async getEvaluations() {
    const resultAllChallenges = await api.get(`challenges/users`);
    return resultAllChallenges.data;
  }

  async getAIResponsesForEvaluation(challengeId, userId) {
    try {
      const resultGeneratedFeedbacks = await api.post(
        `challenges/auto-generate-evaluation/${challengeId}/${userId}`
      );

      return resultGeneratedFeedbacks.data;
    } catch (err) {
      return {
        success: true,
        remainingUsages: null,
        errorInfo: "Erro no momento da geração. Tente novamente mais tarde",
        questions: [],
        generalFeedback: "",
      };
    }
  }
}

export default new EvaluationService();
