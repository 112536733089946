/* eslint-disable no-unsafe-optional-chaining */
function verifyNext(question, change) {
  switch (question?.type.toLowerCase()) {
    case "radio":
    case "select":
      return +change?.id >= 0 ? true : false;
      break;
    case "checkbox":
    case "multiselect":
      return change?.length > 0 ? true : false;
      break;
    case "numeric":
      return change ? true : false;
      break;
    case "textarea":
      return change?.trim()?.length > 0 && change?.trim()?.length <= 20003
        ? true
        : false;
      break;
    case "file":
      return change?.type ? true : false;
      break;
  }
}

export default verifyNext;
