import * as S from "../../styles";
import { Button } from "design-system-firedev";
import { t } from "i18next";
import { File } from "phosphor-react";

type FormDataEvaluationTypeFile = {
  content: string;
  uploadFile: {
    fileName: string;
    fileUrl: string;
  };
  id: number;
};

export function EvaluationFormTypeFile({
  content,
  id,
  uploadFile,
}: FormDataEvaluationTypeFile) {
  return (
    <div>
      <S.ContainerQuestion>
        <S.NumberOfQuestion>Desafio {id}</S.NumberOfQuestion>
        <S.BodyOfQuestion dangerouslySetInnerHTML={{ __html: content }} />
      </S.ContainerQuestion>
      <S.ContainerOfAnswerUser>
        <S.ContainerAnsweOfUserTypeFile>
          <File size={24} />
          <S.TitleOfFile>{uploadFile?.fileName?.slice(37)}</S.TitleOfFile>
          <S.AnchorButton target="blank" href={uploadFile?.fileUrl}>
            <Button
              className="buttonShowFile"
              background="transparent"
              borderColor="#ffffff"
              color="#ff026f"
              fontVariant="body1"
              height={25}
              size="custom"
              text={t("evaluate.viewFile")}
              width={135}
              type="button"
            />
          </S.AnchorButton>
        </S.ContainerAnsweOfUserTypeFile>
      </S.ContainerOfAnswerUser>
    </div>
  );
}
