import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {createGlobalStyle} from "styled-components";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import i18n from "./services/i18n";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
i18n.init();

const GlobalStyle = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Ubuntu";
  }

  .ql-editor.ql-blank::before {
    font-family: 'Ubuntu' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    color: #8C8C8C !important;
    line-height: 18px;
  }
`;
const theme = createTheme({
  palette: {
    primary: {
      main: "#696969",
    },
    error: {
      main: "#F5222D",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          fontSize: "15px",
          "&:hover": {
            outline: "none",
          },
          div: {
            background: "#FFF",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          fontSize: "15px",
          "&:hover": {
            outline: "none",
          },
          borderWidth: "2px",
        },
        input: {
          fontWeight: "600",
          fontSize: "15px",
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (localStorage.getItem("language") == "null") {
  localStorage.setItem("language", "pt");
}

const client = new QueryClient({
  defaultOptions: {queries: {suspense: false}},
});

root.render(
  <div>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={client}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID}>
          <App />
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </div>
);

reportWebVitals();
