import { Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Controller } from "react-hook-form";
import TreeWhiteIcon from "../../../../assets/TreeWhiteIcon.svg";
import * as S from "./styles";
import CardTree from "../../../../components/CardTree";
import { X } from "phosphor-react";
import { t } from "i18next";

const InfoSkillsModal = ({ open, handleClose, name, skills }) => {

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={handleClose} sx={{ textAlign: 'center', minWidth: '' }}>
      <DialogTitle fontSize={"2.25rem"}>
      {t("competenceList.competenceSummary")}
        <IconButton sx={{ position: "absolute", top: "2vh", right: "1vw" }} size="medium" onClick={handleClose}>
          <X size={27} style={{ float: "right" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <S.ContainerAccordions>
            <Accordion expanded={true}>
              <AccordionSummary>
                <S.ContainerAccordionSummary>
                  <S.ContainerAccordionSummaryLeft>
                    <S.AccordionSummaryCircleImg color={"#302382"}>
                      <S.AccordionSummaryImg src={TreeWhiteIcon} alt="TreeIcon" />
                    </S.AccordionSummaryCircleImg>
                    <S.ContainerAccordionSummaryLeftTexts>
                      <S.TitleAccordionSummary>
                        {name}
                      </S.TitleAccordionSummary>
                    </S.ContainerAccordionSummaryLeftTexts>
                  </S.ContainerAccordionSummaryLeft>
                </S.ContainerAccordionSummary>
              </AccordionSummary>
              {open && (
                <AccordionDetails sx={{ p: 0, m: 0 }}>
                  <S.ContainerAccordionDetails>
                    <Controller
                      name="skills"
                      control={skills}
                      render={({ field: { value } }) => (
                        <CardTree skills={value} />
                      )}
                    />
                  </S.ContainerAccordionDetails>
                </AccordionDetails>
              )}
            </Accordion>
          </S.ContainerAccordions>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default InfoSkillsModal;