import { ChangeEvent, useEffect, useState } from "react";
import api from "../../../services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import zod from "zod";
import { Controller, useForm } from "react-hook-form";
import * as S from "./styles";
import {
  InputDefault,
  Button as ButtonDS,
  Button,
} from "design-system-firedev";
import { ResponsiveInputText } from "../../../components/ResponsiveInputText";
import PositionCategoryService from "../../../services/PositionCategoryService";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";

export interface ICategory {
  id: string;
  name: string;
}

interface ICategoriesForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: ICategory | undefined;
}

export default function CategoriesForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: ICategoriesForm) {
  const isEditing = Boolean(dataToEdit);

  useEffect(() => {
    const fetchData = async () => {
      const result = await PositionCategoryService.getPositionCategoryById(
        dataToEdit.id
      );
      setValue("name", result.name);
    };
    if (isEditing) {
      fetchData();
    }
  }, [dataToEdit]);

  const schema = zod.object({
    name: zod.string().min(1, t('categories.validName')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICategory>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: dataToEdit?.name ?? "",
    },
  });

  const onSubmit = async (data: ICategory) => {
    const formationCategoryRequest = { name: data.name };
    try {
      const result = isEditing
        ? await PositionCategoryService.putPositionCategory(
            formationCategoryRequest,
            dataToEdit.id
          )
        : await PositionCategoryService.postPositionCategory(
            formationCategoryRequest
          );
      feedbackAction("success", result.data, isEditing ? "edit" : "add");
      enqueueSnackbar(isEditing ? t("categories.courseEdited") : t("categories.courseAdded"), { variant: "success" });
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Button
        onClick={onBackPress}
        type="button"
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text={t('categories.goBack')}
      />
      <S.ContainerGap>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <ResponsiveInputText
              {...field}
              placeholder={t('categories.name')}
              label={t('categories.name')}
              errorMessage={errors.name?.message}
              borderColor={errors.name ? "#F5222D" : "#8C8C8C"}
              data-cy="categoryFormInputName"
            />
          )}
        />
      </S.ContainerGap>

      <S.ContainerGap>
        <Button
          type="submit"
          typeOfButton="primary"
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text={isEditing ? t("save") : t('add')}
          data-cy="categoryFormAddButton"
        />
      </S.ContainerGap>
    </S.Form>
  );
}
