import React, { useState, useEffect } from "react";
import * as S from "./styles";
import MenuItem from "@mui/material/MenuItem";

function Radio({ options, setChange, change, questionIndex, type }: any) {
  const [radio, setRadio] = useState<any>(change);

  function handleRadio(quest) {
    setRadio(quest);
    setChange(quest);
  }

  useEffect(() => {
    setRadio(change);
  }, [questionIndex, type]);

  return (
    <S.ContainerRadios data-cy="challengePageRadioOptions">
      {options?.map((quest, index) => (
        <S.RadioContainer key={index} onClick={() => handleRadio(quest)}>
          <MenuItem
            style={{
              minWidth: 32,
              maxWidth: 32,
              minHeight: 32,
              maxHeight: 32,
              borderRadius: 32,
            }}
            sx={{
              color: "rgba(236, 73, 144)",
              p: 0,
              m: 0,
              ":hover": {
                backgroundColor: "rgba(236, 73, 144, 0.15)",
              },
            }}
          >
            <S.RadioOutCircle selected={radio?.id === quest?.id}>
              {radio?.id === quest?.id && <S.RadioInnerCircle />}
            </S.RadioOutCircle>
          </MenuItem>
          {quest?.image ?
            <S.RadioLabel>
              <img src={quest.image.fileUrl} alt={quest.image.fileName} />
              {quest.content ? quest.content : ""}
            </S.RadioLabel>
          :
            <S.RadioLabel>
              {quest.content}
            </S.RadioLabel>
          }
        </S.RadioContainer>
      ))}
    </S.ContainerRadios>
  );
}

export default Radio;
