import { Cancel, CheckCircle } from '@mui/icons-material';
import { Divider } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 1rem 0;
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`;

export const TextDate = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const Question = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FF318A;
  max-width: 150px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Link = styled.a`
  color: #FF318A;
`;

export const Result = styled.p`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const CorrectIcon = styled(CheckCircle)`
  color: #04A575;
  width: 22px;
  height: 22px;
  margin-right: 4px;
`;

export const IncorrectIcon = styled(Cancel)`
  color: red;
  width: 22px;
  height: 22px;
  margin-right: 4px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconText = styled.div<{
  isCorrect: boolean;
  isIncorrect: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isCorrect ? '#04A575' : props.isIncorrect ? 'red' : 'inherit')};
  margin-right: 4px;
`;

export const StyledDivider = styled(Divider)`
  && {
    margin-left: auto;
    margin-right: auto;
    width: 89%;
    color: #8c8c8c;
  }
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  @media (max-width: 425px) {
    grid-column-gap: 1rem;
  }
`;