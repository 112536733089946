import api from "./api";

class StudyArticleService {
  async getStudyArticle() {
    const resultStudyArticles = await api.get('study-articles');
    return resultStudyArticles.data;
  }

  async getSearchStudyArticle(page: number, search: string) {
    const filter = encodeURIComponent(search)
    const response = await api.get(`study-articles?page=${page}&size=5&filter=${filter}`)
    return response.data;
  }

  async getStudyArticleToDropdown(search: string) {
    const filter = encodeURIComponent(search)
    const resultStudyArticleToDropdown = await api.get(`study-articles/summary?filter=${filter}&size=1000&sort=id%2Cdesc`);
    return resultStudyArticleToDropdown.data.content.map(({ id, name }) => ({
      id,
      value: name,
    }));
  }

  async findStudyArticleId(id: string) {
    const response = await api.get(`study-articles/${id}`)
    return response.data;
  }

  async getStudyArticleStatus(id: string) {
    const response = await api.get(`study-articles/status/${id}`)
    return response.data;
  }

  async getAllStudyArticle(page: number, search: string) {
    const filter = encodeURIComponent(search)
    const response = await api.get(`study-articles?filter=${filter}&page=${page}&size=5`)
    return response.data;
  }

  async getAllStudyArticleManagement(page: number, search: string) {
    const filter = encodeURIComponent(search)
    const response = await api.get(`v2/study-articles?filter=${filter}&page=${page}&size=5`)
    return response.data;
  }


  async deleteStudyArticle(id: string){
      const result = await api.delete(`study-articles/${id}`)
      return result
  }

  async createNewStudyArticle(createStudyArticleDTO) {
    const result = await api.post('study-articles', createStudyArticleDTO)
    return result;
  }

  async updateStudyArticle({updateStudyArticleDTO, id}) {
    const result = await api.put(`study-articles/${id}`, updateStudyArticleDTO)
    return result;
  }

}
export default new StudyArticleService();
