/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import * as S from "./style";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  Alert,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {t} from "i18next";
import {Cancel, CheckCircleOutlineOutlined} from "@mui/icons-material";
import * as T from "../../Global/Typography";
import {TableContainerLayout} from "../../components/TableContainerLayout";
import ChallengeService from "../../services/ChallengeService";
import QuestionService from "../../services/QuestionService";
import {ButtonDS} from "../Login/style";
import QuestionsForm from "./Form";

const montaMensagemDeAlerta = (
  alertType: String,
  deleted: boolean,
  customMessage: String,
  submitAction: String | null
) => {
  if (alertType === "success") {
    if (deleted) {
      return "Desafio deletado com sucesso";
    }
    return submitAction === "add"
      ? t("questions.registrationSuccessMessage")
      : t("questions.editSuccessMessage");
  }

  return customMessage;
};

export default function Questions() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>("");
  const [action, setAction] = React.useState("list");
  const [selected, setSelected] = React.useState<any>();
  const [deleted, setDeleted] = React.useState(false);
  const [challenges, setChallenges] = React.useState<any[]>([]);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [modalAction, setModalAction] = React.useState<
    "activate" | "deactivate"
  >(null);
  const [openSwitchStatusDialog, setOpenSwitchStatusDialog] =
    React.useState<boolean>(false);

  const fetchData = async () => {
    const resultQuestion = await QuestionService.getQuestions(search, page - 1);

    setTotalPages(resultQuestion.totalPages);

    setRows(
      resultQuestion?.content?.map((row: any) => {
        return {
          content: row.content,
          input: row.input,
          id: row.id,
          active: row.active,
          challenge: row.challenge.title,
        };
      })
    );
  };

  const fetchChallengeData = async () => {
    const challengeResult = await ChallengeService.getAllChallenge(
      page - 1,
      search
    );
    setChallenges(
      challengeResult.content.map((item: any) => {
        return {
          id: item?.id,
          title: item?.title,
          questions: item?.questions,
        };
      })
    );
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
      fetchChallengeData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  React.useEffect(() => {
    fetchData();
    fetchChallengeData();
  }, [page]);

  React.useEffect(() => {
    if (action === "list") fetchChallengeData();
  }, [action]);

  const handleFeedbackAction = async (
    type: string,
    newData: any,
    submitAction: string,
    alertMessage: string | null
  ) => {
    setAction(type === "success" ? "list" : "form");
    if (type === "success") {
      await fetchData();
    }
  };

  const handleEdit = (id: number, content: string) => {
    setSelected({id, content});
    setAction("form");
    setDeleted(false);
    setOpenEditDialog(false);
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
    setDeleted(false);
  };

  const handleOpenEditDialog = (id, content) => {
    setSelected({id, content});
    setOpenEditDialog(true);
  };

  const handleOpenSwitchStatusDialog = (id, content, active) => {
    setModalAction(active === true ? "deactivate" : "activate");
    setSelected({id, content, active});
    setOpenSwitchStatusDialog(true);
  };

  const handleCloseEditDialog = () => {
    setSelected(undefined);
    setOpenEditDialog(false);
  };

  const handleCloseSwitchStatusDialog = () => {
    setModalAction(null);
    setSelected(undefined);
    setOpenSwitchStatusDialog(false);
  };

  const handleConfirmSwitchStatus = () => {
    if (selected.active === true) {
      QuestionService.deactivate(selected.id);
    } else {
      QuestionService.activate(selected.id);
    }

    setRows(
      rows.map((row) => {
        if (row.id === selected.id) {
          row.active = !row.active;
        }

        return row;
      })
    );

    setOpenSwitchStatusDialog(false);
  };

  return (
    <S.ContainerFullPage>
      <Dialog
        open={openSwitchStatusDialog}
        onClose={handleCloseSwitchStatusDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {(modalAction == "activate" && (
              <div>{t("questions.activeQuestion")}</div>
            )) ||
            (modalAction == "deactivate" && (
              <div>{t("questions.deactivateQuestion")}</div>
            ))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {(modalAction == "activate" && (
                <b>{t("questions.toActiveChallenge")}</b>
              )) ||
              (modalAction == "deactivate" && (
                <b>{t("questions.challengeWontShowInNextTest")}</b>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("questions.cancel")}
            onClick={handleCloseSwitchStatusDialog}
          />
          <ButtonDS
            data-cy="questionScreenEditButton"
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("questions.confirm")}
            onClick={() => handleConfirmSwitchStatus()}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("questions.confirmEditMessage")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{t("questions.editWarningMessage")}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("questions.cancel")}
            onClick={handleCloseEditDialog}
          />
          <ButtonDS
            data-cy="questionScreenEditButton"
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("questions.edit")}
            onClick={() => handleEdit(selected.id, selected.content)}
          />
        </DialogActions>
      </Dialog>
      <T.Display data-cy="questionsH1">{t("questions.questions")}</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{width: "100%", background: "white"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: "20%"}}>
                    <S.FontHeaderTable>
                      {t("questions.skill")}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "50%"}}>
                    <S.FontHeaderTable>
                      {t("questions.content")}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "14%"}}>
                    <S.FontHeaderTable>
                      {t("questions.input")}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "29%"}}>
                    <S.FontHeaderTable>Status</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => {
                  const htmlStr = row?.content?.replace(/<br\s*\/?>/gi, " ");
                  const newStr = htmlStr?.replace(/(<([^>]+)>)/gi, "");
                  const contentStr = newStr?.replaceAll("&nbsp;", "");
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.challenge}</TableCell>
                      <TableCell data-cy="questionsH1">{contentStr}</TableCell>
                      <TableCell>{row.input}</TableCell>
                      <TableCell>
                        {(row.active === true && (
                          <Chip label={t("questions.active")} color="success"/>
                        )) || (
                          <Chip label={t("questions.disabled")} color="error"/>
                        )}
                      </TableCell>
                      <TableCell data-cy="questionTableEditButton">
                        <div>
                          <IconButton>
                            <EditOutlinedIcon
                              onClick={() =>
                                handleOpenEditDialog(row.id, row.content)
                              }
                            />
                          </IconButton>
                          <IconButton>
                            {(row.active === true && (
                              <Cancel
                                color="warning"
                                onClick={() =>
                                  handleOpenSwitchStatusDialog(
                                    row.id,
                                    row.content,
                                    row.active
                                  )
                                }
                              />
                            )) || (
                              <CheckCircleOutlineOutlined
                                color="success"
                                onClick={() =>
                                  handleOpenSwitchStatusDialog(
                                    row.id,
                                    row.content,
                                    row.active
                                  )
                                }
                              />
                            )}
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} sx={{textAlign: "center"}}>
                      {t("questions.noResultFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (
        <QuestionsForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          dataToEdit={selected}
        />
      )}
    </S.ContainerFullPage>
  );
}
