import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin-left: -8px;
  padding: 6px 8px;
  display: inline-flex;
  gap: 0.7rem;
  cursor: pointer;
  width: min-content;
`;

export const ButtonText = styled.p`
  font-size: 1rem;
  color: #ff026f;
  font-weight: 500;
  white-space: nowrap;
`;
