import styled from "styled-components";

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const Form = styled.form`
  width: 90%;
  max-width: 1300px;
`;

export const ContainerGap = styled.div`
  margin-top: 1rem;

  p {
    font-size: 14px;
  }
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const TextArea = styled.textarea`
  resize: none;
  outline: none !important;
  width: 100%;
  height: 150px;
  padding: 14px 16px;
  background: white;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;

export const ContainerButton = styled.div`
  width: 180px;
  margin: 30px 0;
  @media screen and (max-width: 1049px) {
    width: 100%;
  }
`;

export const TextInfo = styled.p`
  font-weight: 700;
  font-size: 20px !important;
  margin-bottom: 12px;
`;

export const ErrorMessage = styled.p`
  margin: 0.3rem 0;
  font-size: 14px;
  font-weight: 400;
  color: #f5222d;
`;

export const InputRow = styled.div<{ rowsSize: number[] }>`
  //margin-top: 20px;
  display: grid;
  grid-template-columns: ${({ rowsSize }) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 36px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const FirstLabelPackage = styled.span`
  font-size: 18px !important;
  font-weight: 700;
`;

export const SecondLabelPackage = styled.span`
  font-size: 18px !important;
  color: #8C8C8C;
  font-weight: 700;
`;

export const ThirdLabelPackage = styled.span`
  font-size: 30px !important;
  color: #FF026F;
  margin-bottom: 6px;
  margin-left: 5px;
  font-weight: 700;
`;

export const Packages = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  margin-top: 1rem;
`;

export const ContainerPackage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SecondContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const PackageLabel = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;