let apiDomain = process.env.REACT_APP_BASE_URL;

class UrlService {
  static loginUrl() {
    return `${apiDomain}auth/signIn`;
  }

  static signUpUrl() {
    return `${apiDomain}auth/signUp`;
  }

  static userVerifyAdmin() {
    return `${apiDomain}user/adminVerify`;
  }

  static linkedinUrl() {
    return `${apiDomain}auth/linkedin`;
  }

  static googleUrl() {
    return `${apiDomain}auth/google`;
  }
}

export default UrlService;
