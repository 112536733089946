import React, {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, useForm} from "react-hook-form";
import zod from "zod";
import TreeOrangeIcon from "../../../assets/TreeOrangeIcon.svg";
import Seta from "../../../assets/seta.svg";
import BackButton from "../../../components/BackButton";
import {Button} from "../../../components/Button";
import CardTree, {reorderLevel} from "../../../components/CardTree";
import {Multiselect} from "../../../components/Multiselect";
import {ResponsiveInputText} from "../../../components/ResponsiveInputText";
import {Select} from "../../../components/Select";
import SkillService from "../../../services/SkillService";
import SkillTreeService from "../../../services/SkillTreeService";
import api from "../../../services/api";
import Visibility from "../../../types/enums/Visibility";
import {ISkill} from "../../Skills/Form";
import * as S from "./styles";
import {enqueueSnackbar} from "notistack";
import {t} from "i18next";
import CheckboxMultiselect from "../../../components/Checkboxes";
import {options} from "../../../components/utils/languageOptions";
import CompanyService from "../../../services/CompanyService";
import FormationService from "../../../services/FormationService";

export interface ISkillTree {
  id: number | string;
  name?: string;
  description: string;
  skills: ISkill[];
  formations?: any;
  company?: {
    id: string;
    value: string;
    name: string;
  };
  visibility: {
    id: string;
    value: string;
  };
}

interface ISkillTreeForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit?: ISkillTree | undefined;
  isEdit?: boolean;
}

interface IFormData {
  id: string | number;
  name: string;
  skills: any;
  languages?: any;
  formations?: any;
  company?: {
    id: string;
    value: string;
    name: string;
  };
  visibility: {
    id: string;
    value: string;
  };
}

export default function SkillTreeForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
  isEdit,
}: ISkillTreeForm) {
  const isEditing = typeof dataToEdit !== "undefined";
  const [name, setName] = useState("");
  const [skillsWithoutQuestions, setSkillsWithoutQuestions] = useState([]);
  const [newOptions, setNewOptions] = useState(options)
  const [localLanguage, setLocalLanguage] = useState(localStorage.getItem("language"));

  const [positionsSelected, setPositionsSelected] = useState([]);
  const [formationsMultiselect, setFormationsMultiselect] = useState<any>([]);

  const [skills, setSkills] = useState<any>([]);
  const [skillsMultiselect, setSkillsMultiselect] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [skillsSelecteds, setSkillsSelecteds] = useState([]);
  const [
    optionsSelectedDropdownMultiSelect,
    setOptionsSelectedDropdownMultiSelect,
  ] = useState<any>();
  const [companies, setCompanies] = useState([]);
  const user = JSON.parse(localStorage.getItem("userRoles"));
  const isCurrentUserSysAdmin = user[0].authority === "ROLE_SYSADMIN";

  const allCompanies = async () => {
    const result = await CompanyService.getAll("");
    let parentsArr = result.content
        .map(({ id, name }) => {
          return { id: id, value: id, name: name };
        })
    setCompanies(parentsArr);
  };

  useEffect(() => {
    setLocalLanguage(localStorage.getItem("language") || 'pt')
  }, [localStorage.getItem("language")]);

  async function fetchData() {
    const skillRest = await SkillService.getSkillToMultiSelect("");
    setSkills(skillRest);
    setSkillsMultiselect(
      skillRest.map((item) => ({ id: item.id, value: item.name }))
    );
  }

  async function fetchDataById() {
    const result = await SkillTreeService.findIdSkillTree(
      dataToEdit.id.toString()
    );

    const formationPromises = result?.formations.map(async (formation) => {
      return await findFormation(formation);
    });

    const resolvedFormations = await Promise.all(formationPromises);

    setName(result.name);
    setValue("skills", result?.skills);
    setValue("name", result.name);
    if (isCurrentUserSysAdmin) {
      setValue("visibility", {
        id: result.visibility,
        value: t(`skills.${Visibility[result.visibility]}`),
      });
    }
    setValue("company", {
      id: result?.company?.id,
      value: result?.company?.id,
      name: result?.company?.name,
    });
    setSkillsSelecteds(
      result.skills?.map((item) => ({ id: item.id, value: item.name }))
    );

    setPositionsSelected(
      resolvedFormations.map((item) => ({ id: item.id, value: item.title }))
    );

    const newOptionsLanguage = newOptions.filter(
      option => result?.languages?.includes(option.value) && option?.value !== localLanguage
    );
    setValue("languages", newOptionsLanguage)

  }



  async function getAllFormations() {
    const response = await FormationService.getAllFormations();

    const formattedFormations = response?.content.map((item) => ({
      id: item.id,
      value: item.title,
      companyId: item.company.id
    }));

    setFormationsMultiselect(formattedFormations);

    const selectedFormations = formattedFormations.filter((formation) =>
      positionsSelected.some((selected) => selected.id === formation.id)
    );

  }

  useEffect(() => {
    fetchData();
    getAllFormations();
    setNewOptions(options.filter((language) => language.value !== (localLanguage || 'pt')))
  }, []);

  async function findFormation(id: number) {
    const result = await FormationService.findIdFormation(id);
    return result;
  }

  useEffect(() => {
    if (isEditing) {
      fetchDataById();
    }
  }, [isEditing]);

  useEffect(() => {
    if (isCurrentUserSysAdmin) {
      allCompanies();
    } else {
      setValue("visibility", {
        id: "PRIVATE",
        value: t(`dashboard.private`),
      });
    }
  }, []);


  async function handleCreateNewSkillTree(data) {
    let { name, visibility, skills, languages , company} = data;
    const formationsInSkillTree = positionsSelected.map((position) => position.id);
    languages = languages?.map((language) => language.value)
    try {
      setLoading(true);
      let result;
      let submitAction = "add";
      if (isEditing) {
        const updateSkillTreeRequest = {
          name,
          skills,
          visibility: visibility.id,
          companyId: company.id,
          languages,
          formations: formationsInSkillTree,
        };
        result = await api.put(
          `skill_trees/${dataToEdit.id}`,
          updateSkillTreeRequest
        );
        submitAction = "edit";
        enqueueSnackbar(t("formCompetence.successfullyEdited"), {
          variant: "success",
        });
      }
      if (!isEditing) {
        const createSkillTreeRequest = {
          name,
          skills,
          visibility: visibility.id,
          companyId: company?.id || null,
          languages,
          formations: formationsInSkillTree,
        };

        result = await api.post("skill_trees", createSkillTreeRequest);
        enqueueSnackbar(t("formCompetence.successfullyEdited"), {
          variant: "success",
        });
      }
      setLoading(false);
      feedbackAction("success", result.data, submitAction);
    } catch (err: any) {
      if (optionsSelectedDropdownMultiSelect) {
        console.error(err);
        setLoading(false);
      }
    }
  }

  const newTrailFormSchema = zod.object({
    company: zod.any().refine((val) => {
      if (isCurrentUserSysAdmin) {
        return val !== undefined && val !== null && val.id !== null;
      }
      return true;
    }, {
      message: t("positionsManagement.selectAddCompany"),
    }),
    name: zod
      .string()
      .min(1, t("formCompetence.validName"))
      .max(255, t("formCompetence.maxSizeName")),
    skills: zod.any(),
    visibility: zod.object(
      {
        id: zod.string(),
        value: zod.string(),
      },
      { required_error: t("formCompetence.selectVisibility") }
    ),
    languages: zod.any()
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(newTrailFormSchema),
  });

  return (
    <div>
      <S.Form onSubmit={handleSubmit(handleCreateNewSkillTree)}>
        <S.BackButtonContainer>
          <BackButton onClick={onBackPress}>
            {t("formCompetence.back")}
          </BackButton>
        </S.BackButtonContainer>
        {isCurrentUserSysAdmin && (
          <S.ContainerGap>
            <Controller
                name="company"
                defaultValue={null}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <div >
                      <Select
                          showSearch
                          options={companies}
                          selected={value}
                          onSelect={(newValue) => onChange(newValue)}
                          placeholder={t("positionsManagement.company")}
                          label={t("positionsManagement.company")}
                          error={errors?.company?.message}
                      />
                    </div>
                )}
            />
          </S.ContainerGap>
        )}
        <S.ContainerGap>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            render={({ field: { onChange, value } }) => (
              <ResponsiveInputText
                data-cy="nameFieldSkillTreeForm"
                value={value}
                label={t("formCompetence.name")}
                onChange={(e) => {
                  setName(e.target.value);
                  onChange(e.target.value);
                }}
                placeholder={t("formCompetence.name")}
                errorMessage={errors.name?.message}
                borderColor={errors.name ? "#F5222D" : "#8C8C8C"}
              />
            )}
          />
        </S.ContainerGap>
        <S.InputRow rowsSize={[2, 2]}>

          <S.ContainerDropDownSimpleSelect data-cy="visibilityDropdownSkillTreeForm">
            <Controller
              name="visibility"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  showSearch
                  selected={value}
                  disabled={!isCurrentUserSysAdmin}
                  onSelect={(newValue) => onChange(newValue)}
                  placeholder={t("formCompetence.visibility")}
                  label={t("formCompetence.visibility")}
                  error={errors?.visibility?.message}
                  options={Object.keys(Visibility).map((visibility) => {
                    return {
                      id: visibility,
                      value: visibility,
                      name: t(`skills.${Visibility[visibility]}`),
                    };
                  })}
                />
              )}
            />
          </S.ContainerDropDownSimpleSelect>
          <S.ContainerGap>
            <Controller
              name="languages"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  {value ?
                    <CheckboxMultiselect options={newOptions} label={t("languages")}
                      onChange={onChange} value={value} />
                    : <CheckboxMultiselect options={newOptions} label={t("languages")}
                      onChange={onChange} value={[]} />}
                </div>
              )}
            />
          </S.ContainerGap>
        </S.InputRow>
        <S.ContainerDropDownSimpleSelect data-cy="skillsDropdownSkillTreeForm">
          <Controller
            name="skills"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Multiselect
                selecteds={skillsSelecteds}
                placeholder={t("formCompetence.skills")}
                label={t("formCompetence.skills")}
                onSelect={(e) => {
                  e = e?.map((item: any) => {
                    const stored = skills?.find((s) => s.id === item.id);
                    const alreadyInArr = value?.find((s) => s.id === item.id);
                    let selected = stored ? { ...stored } : {};
                    if (alreadyInArr) {
                      selected = { ...selected, ...alreadyInArr };
                    }
                    if (!selected.level) {
                      selected.level = 1;
                    }
                    if (!selected.position) {
                      selected.position = 1;
                    }
                    return selected;
                  });
                  reorderLevel(e, 1);
                  onChange(e);
                }}
                options={skillsMultiselect}
                error={errors?.skills?.message}
              />
            )}
          />
        </S.ContainerDropDownSimpleSelect>

          <S.ContainerDropDownSimpleSelect data-cy="skillsDropdownSkillTreeForm">
            <Controller
              name="formations"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Multiselect
                  selecteds={positionsSelected}
                  placeholder={t("formCompetence.formations")}
                  label={t("formCompetence.formations")}
                  onSelect={(e) => {
                    setPositionsSelected(e)
                    reorderLevel(e, 1);
                    onChange(e)
                  }}
                  options={formationsMultiselect}
                />
              )}
            />
          </S.ContainerDropDownSimpleSelect>



        <S.ContainerFullTree>
          <S.ContainerAba>
            <S.AbaImageTitle>
              <S.AbaImageContainer>
                <S.AbaImage src={TreeOrangeIcon} />
              </S.AbaImageContainer>
              <S.AbaTitleSubtitle>
                <S.AbaTitle data-cy="skillTreeName">
                  {name || t("formCompetence.name")}
                </S.AbaTitle>
              </S.AbaTitleSubtitle>
            </S.AbaImageTitle>
            <S.ImageSeta src={Seta} up />
          </S.ContainerAba>

          <Controller
            name="skills"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CardTree
                skills={value}
                draggable
                onChange={onChange}
                isEdit={isEdit}
              />
            )}
          />
        </S.ContainerFullTree>
        <S.ErrorMessage data-cy="skillWithoutQuestionErrorMessageSkillTreeForm">
          {!!skillsWithoutQuestions.length && (
            <div>
              <p>{t("formCompetence.skillsLinkedToAnotherTree")}</p>
              <ul>
                {skillsWithoutQuestions.map((skill) => (
                  <li key={skill.id}>{skill.name}</li>
                ))}
              </ul>
            </div>
          )}
        </S.ErrorMessage>

        <S.ButtonContainer>
          <Button
            disable={!!skillsWithoutQuestions.length || loading}
            data-cy="submitSkillTreeForm"
          >
            {t("formCompetence.confirm")}
          </Button>
        </S.ButtonContainer>
      </S.Form>
    </div>
  );
}
