import CircleIcon from "@mui/icons-material/Circle";
import { Divider } from "@mui/material";
import { t } from "i18next";
import { IQuestionAnswered } from "../Index";

import * as S from "../styles";

interface ISummaryResponse {
  answers: IQuestionAnswered[];
  isFinal: boolean;
}

export default function SummaryResponse(data: ISummaryResponse) {
  const answers = data.answers;
  return (
    <>
      {answers.map((answer, i) => (
        <S.ContainerQuestions isFinal={data.isFinal}>
          {!!i && <Divider />}
          <S.QuestionTitle>{answer?.title}</S.QuestionTitle>
          <S.QuestionText>
            <S.DescriptionData
              dangerouslySetInnerHTML={{
                __html: answer?.description,
              }}
            />
          </S.QuestionText>

          <S.QuestionTitle>{t("challenge.answer")}</S.QuestionTitle>
          <S.QuestionText>
            {(answer?.type === "CHECKBOX" &&
              answer.answer.map((content, i) => (
                <div>
                  {i > 0 && <Divider variant="middle" />}
                  <S.CheckboxLabel>
                    <CircleIcon sx={{ fontSize: "10px" }} />
                    {content?.image?.fileUrl && (
                      <img
                        src={content?.image?.fileUrl}
                        alt={content?.content}
                      />
                    )}
                    {content?.content && content?.content}
                  </S.CheckboxLabel>
                </div>
              ))) ||
              (answer?.type === "RADIO" && (
                <S.CheckboxLabel>
                  {answer?.answer?.image?.fileUrl && (
                    <img
                      src={answer?.answer?.image?.fileUrl}
                      alt={answer?.answer?.content}
                    />
                  )}
                  {answer?.answer?.content && answer?.answer?.content}
                </S.CheckboxLabel>
              )) ||
              answer?.answer?.name ||
              answer?.answer}
          </S.QuestionText>
        </S.ContainerQuestions>
      ))}
    </>
  );
}
