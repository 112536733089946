import styled from "styled-components";

export const ContainerFile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerSelectFile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 2.75rem 0.5rem;
  background-color: #fff;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  margin-bottom: 1.5rem;
`;

export const ContainerFilesType = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 30px;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ContainerIconUpload = styled.div`
  background-color: #d9d9d9;
  position: relative;
  width: 66px;
  height: 66px;
  border-radius: 50%;
`;

export const IconUploadImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 31px;
`;

export const PinkTextUploadFile = styled.p`
  font-size: 16px;
  color: #ff026f;
  font-weight: 500;
`;

export const TextUploadFile = styled.p`
  font-size: 14px;
  color: #8c8c8c;
  font-weight: 400;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InputFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #f005;
`;

export const ContainerFileTypesName = styled.div`
  cursor: pointer;
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const ContainerFileTypes = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  padding: 0 3px;
  background-color: #f5222d;
  border-radius: 2px;
`;

export const TextFileType = styled.p`
  margin-top: -2px;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
`;

export const TextFileName = styled.p`
  font-size: 14px;
  color: #131212;
  font-weight: 400;
`;
