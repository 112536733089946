import { X } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button";
import * as S from "./styles";
import { t } from "i18next";

function ModalSubscribe({
  onClose,
  isCourse,
  company,
  id,
}: {
  onClose: any;
  isCourse?: boolean;
  company: string;
  id: string;
}) {
  const navigate = useNavigate();

  const redirectUrl = isCourse ? "course" : "position";

  const handleLogin = () => {
    navigate(`/login?${redirectUrl}=${id}&company=${company}`);
  };
  const handleRegistrar = () => {
    navigate("/sign-up");
  };
  return (
    <S.FullModal>
      <X
        style={{
          position: "absolute",
          top: 12,
          right: 12,
          cursor: "pointer",
        }}
        size={19}
        color="#696969"
        onClick={onClose && onClose}
      />
      <S.Title data-cy="subscribeModalTitle">{isCourse ? t("courses.ModalSubscribe.title") :  t("positions.ModalSubscribe.title")}</S.Title>
      <S.Subtitle>
      {    isCourse ? t("courses.ModalSubscribe.subTitle") : t("positions.ModalSubscribe.subTitle")
       }
      </S.Subtitle>
      <S.RowContainerButtons>
        <Button
          data-cy="registerButton"
          style={{
            background: "#fff",
            color: "#131212",
            border: "2px solid #131212",
          }}
          onClick={handleRegistrar}
        >
          {t("users.register")}
        </Button>
        <Button data-cy="loginButton" onClick={handleLogin}>
        {t("sidebar.login")}
        </Button>
      </S.RowContainerButtons>
    </S.FullModal>
  );
}

export default ModalSubscribe;
