/* eslint-disable react/no-unstable-nested-components */
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import * as S from "./styles";

function SkillTreeNameModal({ data, onClose, onSend }) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(data.label.replace("Adicionar ", ""))
  }, [])

  return (
    <S.FullModal>
      <S.ModalTitle>Deseja alterar o nome da competência {data?.label.replace("Adicionar ", "")}?</S.ModalTitle>
      <S.ContainerInput>
        <TextField
          sx={{
            borderRadius: 0,
            width: "100%",
            height: "100%",
            fontSize: 16,
            fontWeight: 500,
            outline: "none",
            verticalAlign: "middle",
            color: "#131212",
            background: "#ffffff",
          }}
          value={value}
          onChange={(e) => { setValue(e.target.value) }}
          placeholder="Nome da competência"
        />
      </S.ContainerInput>
      <S.ContainerButton>
        <Button
          style={{
            background: "#fff",
            borderWidth: 1,
            borderColor: "#131212",
            borderStyle: "solid",
            color: "#131212",
          }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onSend(value);
            onClose();
          }}
        >
          Confirmar
        </Button>
      </S.ContainerButton>
    </S.FullModal>
  );
}

export default SkillTreeNameModal;
