import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as S from './styles';
import {useController} from "react-hook-form";
import {useEffect, useState} from "react";
import remove from 'lodash/remove';
import {t} from "i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export default function CheckboxMultiselect({
                                              disabled = false,
                                              multiple = true,
                                              options,
                                              label,
                                              onChange,
                                              noOptionsText = null,
                                              value,
                                            }) {

  return (
    <Autocomplete
      multiple={multiple}
      limitTags={1}
      disabled={disabled}
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      noOptionsText={
         noOptionsText || t("noOptionsMui")
      }
      onChange={(e, selectedOptions, _, optionSelected) => {

        if (multiple) {
          if (selectedOptions?.filter(item => item.value === optionSelected.option?.value).length > 1) {
            remove(selectedOptions, (n: any) => n?.value === optionSelected.option?.value);
          }
        }
        onChange(selectedOptions)
      }}
      autoComplete={false}
      renderOption={(props, option, {selected}) => (
        <li {...props} key={option.value}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{marginRight: 8}}
            checked={value.some(slct => slct.value === option.value)}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <div>
          <S.Label>{label}</S.Label>
          <TextField
            {...params}
            sx={{
              borderRadius: "5px",
              width: "100%",
              fontSize: 16,
              fontWeight: 500,
              outline: "none",
              color: "#131212",
              background: disabled ? "transparent" : "#ffffff",
              ".MuiInputBase-root": {
                background: disabled ? "transparent" : "#ffffff",
              },
              ".MuiAutocomplete-endAdornment": {
                background: disabled ? "transparent" : "#ffffff",
              },

            }}
            autoComplete="off"
          />
        </div>
      )}
    />
  );
}