import {Button as MuiButton, IconButton, Modal, Tooltip} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {t} from "i18next";
import {X} from "phosphor-react";
import {useEffect, useState} from "react";
import defaultUsericon from "../../assets/defaultUserIcon.png";
import CompanyService from "../../services/CompanyService";
import FileService from "../../services/FileService";
import UserService from "../../services/UserService";
import {Button} from "../Button";
import NotesModal from "../NotesModal";
import * as S from "./styles";

export function UserDetailsModal({
                                   userId,
                                   positionId,
                                   trailId,
                                   onClose,
                                   messageSent,
                                   open,
                                   isSysAdmin,
                                   onAdd,
                                 }) {
  const [user, setUser] = useState<any>();
  const [contactUserTextIsOpen, setContactUserTextIsOpen] =
    useState<boolean>(false);
  const [contactMessage, setContactMessage] = useState<String>("");
  const [alreadySent, setAlreadySent] = useState<Boolean>(false);
  const [loading, setLoading] = useState(false);


  const unlockUserEmailDTO = {
    userId: userId,
    message: contactMessage,
    positionId: positionId,
    trailId: trailId
  };

  const fetchData = async (userId) => {
    if (open) {
      try {
        setLoading(true);
        const result = await UserService.getUserById(userId);
        setUser(result);
      } catch (err: any) {
        console.error(err.data.response.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const unlockUser = async (userId) => {
    try {
      await CompanyService.unlockUserById(userId);
      fetchData(userId);
      onAdd();
    } catch (err) {
      // Do something here
    }
  };
  const contactUser = async () => {
    try {
      setLoading(true);
      const result = await CompanyService.contactUserById(unlockUserEmailDTO);
      setContactMessage("");
      setAlreadySent(true);
      messageSent();
      onClose();
    } catch (err: any) {
      console.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const openContactUser = async () => {
    try {
      const result = await CompanyService.verifyIfUserAlreadyContacted(
        userId,
        positionId != null ? positionId : trailId
      );
      setAlreadySent(result);
      setContactUserTextIsOpen(true);
    } catch (err) {
      // Do something here
    }
  };

  useEffect(() => {
    setUser(null);
    fetchData(userId);
  }, [open]);

  return (
    <Modal
      open={open}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={() => {
        onClose();
        setContactUserTextIsOpen(false);
      }}
    >
      <S.FullModal>
        <IconButton
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
          size="small"
        >
          <X size={24} style={{float: "right"}} onClick={onClose}/>
        </IconButton>
        <S.TitleWrapper>
          <S.Title>{t("performanceReport.contactDetails")}</S.Title>
          <S.Subtitle>{t("performanceReport.viewDetailedInfo")}</S.Subtitle>
        </S.TitleWrapper>
        <S.AvatarWrapper>
          <S.AvatarImageWrapper>
            <S.AvatarImage
              src={
                user?.avatar?.fileUrl && user?.userUnlocked
                  ? user?.avatar?.fileUrl
                  : defaultUsericon
              }
            />
          </S.AvatarImageWrapper>
        </S.AvatarWrapper>
        <S.ContentWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.fullName")}</S.LabelText>
            <S.ContentText>
              {user?.name} {user?.lastName}
            </S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.company")}</S.LabelText>
            <S.ContentText>{user?.company?.tradeName}</S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.email")}</S.LabelText>
            <S.ContentText>
              {user?.userUnlocked ? user?.email : "********************"}
            </S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.phoneNumber")}</S.LabelText>
            <S.ContentText>
              {user?.userUnlocked ? user?.phone : "********************"}
            </S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.city")}</S.LabelText>
            <S.ContentText>{user?.city}</S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.state")}</S.LabelText>
            <S.ContentText>{user?.state}</S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.age")}</S.LabelText>
            <S.ContentText>{user?.age === 0 || user?.age === null ? t("performanceReport.notInformed") : user?.age}</S.ContentText>
          </S.LineWrapper>
          <S.LineWrapper>
            <S.LabelText>{t("performanceReport.curriculum")}</S.LabelText>
            {user?.curriculo != null &&
              (user.userUnlocked || isSysAdmin ? (
                <S.LinkText
                  linkDisable={!isSysAdmin && !user?.userUnlocked}
                  onClick={() => {
                    FileService.downloadFile(
                      user.curriculo.id,
                      `${user?.name} ${user?.lastName}`
                    );
                  }}
                >
                  {t("performanceReport.seeCurriculum")}
                </S.LinkText>
              ) : (
                <div>{t("performanceReport.blocked")}</div>
              ))}
            {user?.curriculo == null && t("performanceReport.notInformed")}
          </S.LineWrapper>
        </S.ContentWrapper>
        {contactUserTextIsOpen ? (
          <S.MessageContainer>
            {alreadySent && (
              <S.AlreadySent>
                Um e-mail já foi enviado para esse usuário.
              </S.AlreadySent>
            )}
            <S.TextArea
              placeholder={t("performanceReport.yourMessageToUser")}
              onChange={(event) => setContactMessage(event.target.value)}
            />
            <S.UnlockButtonWrapper>
              <Button onClick={contactUser} loading={loading}>
                {t("performanceReport.send")}
              </Button>
            </S.UnlockButtonWrapper>
          </S.MessageContainer>
        ) : isSysAdmin || user?.userUnlocked ? (
          <S.UnlockButtonWrapper>
            <Button onClick={openContactUser} loading={loading}>
              {t("performanceReport.contact")}
            </Button>
          </S.UnlockButtonWrapper>
        ) : (
          <S.UnlockButtonWrapper>
            <Button onClick={() => unlockUser(user?.id)} loading={loading}>
              {t("performanceReport.unlock")}
            </Button>
          </S.UnlockButtonWrapper>
        )}
      </S.FullModal>
    </Modal>
  );
}
