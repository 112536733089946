import styled from "styled-components";
import { Button } from "design-system-firedev";

export const ButtonDS = styled(Button)`
  text-align: center;
  //width: 100%;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
`

export const ContainerInput = styled.div`
  resize: none;
  outline: none !important;
  width: 100%;
  background: white;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;