/* eslint-disable react/no-danger */
/* eslint-disable no-unsafe-optional-chaining */
import { Button } from "design-system-firedev";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { ProgressBar } from "../../components/Progress";
import { IStudyArticle } from "../../models/StudyArticle";
import { UploadFile } from "../../models/UploadFile";
import ChallengeService from "../../services/ChallengeService";
import FileService from "../../services/FileService";
import { ChallengeResponse } from "./Components/ChallengeResponse";
import SummaryResponse from "./Components/SummaryResponse";
import handleQuestions from "./handleQuestions";
import * as S from "./styles";
import verifyNext from "./verifyNext";

export interface IObjectiveAnswer {
  id: number;
  content: string;
  image: null | UploadFile;
}
export interface IQuestionAnswered {
  id: number;
  type: "RADIO" | "TEXTAREA" | "NUMERIC" | "CHECKBOX" | "MULTISELECT" | "FILE";
  questionId: number;
  answer: string | IObjectiveAnswer | any;
  description: string;
  title: string;
}

// const testResultsForSuccess = [
//   { id: 1, title: "Desafio 1", success: true, show: false },
//   { id: 2, title: "Desafio 2", success: true, show: false },
//   { id: 3, title: "Desafio 3", success: true, show: false },
//   { id: 4, title: "Desafio 4", success: false, show: false },
//   { id: 5, title: "Desafio 5", success: true, show: false },
// ];

// const testResultsForError = [
//   { id: 1, title: "Desafio 1", success: false, show: false },
//   { id: 2, title: "Desafio 2", success: true, show: false },
//   { id: 3, title: "Desafio 3", success: true, show: false },
//   { id: 4, title: "Desafio 4", success: false, show: false },
//   { id: 5, title: "Desafio 5", success: false, show: false },
// ];

// const testStudyArticles: IStudyArticle[] = [
//   {
//     id: "1",
//     name: "Python para leigos",
//     type: "LINK",
//     file: {
//       fileName: "teste",
//       fileUrl: "https://www.facom.ufu.br/~william/Disciplinas%202019-1/BIOTCH-GBT017-IntoducaoInformatica/285173966-aprendendo-python-pdf.pdf",
//     },
//     url: "https://www.facom.ufu.br/~william/Disciplinas%202019-1/BIOTCH-GBT017-IntoducaoInformatica/285173966-aprendendo-python-pdf.pdf"
//   },
//   {
//     id: "2",
//     name: "10 Dicas para melhorar a sua lógica de programação",
//     type: "ARTICLE",
//     file: {
//       fileName: "teste",
//       fileUrl: "https://www.facom.ufu.br/~william/Disciplinas%202019-1/BIOTCH-GBT017-IntoducaoInformatica/285173966-aprendendo-python-pdf.pdf",
//     },
//     url: "https://www.facom.ufu.br/~william/Disciplinas%202019-1/BIOTCH-GBT017-IntoducaoInformatica/285173966-aprendendo-python-pdf.pdf"
//   }
// ];

export function Challenge() {
  const navigate = useNavigate();
  const [isFinal, setIsFinal] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const { state } = useLocation();
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [questions, setQuestions] = useState<IQuestionAnswered[]>([]);
  const [data, setData] = useState<any>();
  const [change, setChange] = useState<any>();
  const [answers, setAnswers] = useState<any[]>([]);
  const [statusResponse, setStatusResponse] = useState<"ACHIEVED" | "FAILED" | "WAITING_EVALUATION">();
  const [numberOfTries, setNumberOfTries] = useState<number>(0);
  const [triesLeft, setTriesLeft] = useState<number>(0);
  const [daysToNextAttempt, setDaysToNextAttempt] = useState<number>(0);
  const [formationName, setFormationName] = useState<string>("");
  const [skillTreeName, setSkillTreeName] = useState<string>("");
  const [formationProgress, setFormationProgress] = useState<number>(0);
  const [skillTreeProgress, setSkillTreeProgress] = useState<number>(0);
  const [skillGrade, setSkillGrade] = useState<number>(0);
  const [testResults, setTestResults] = useState<any[]>([]);
  const [studyArticles, setStudyArticles] = useState<IStudyArticle[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const challengeId = state?.challengeId;
  const type = state?.type;
  const id = state?.id;
  const lastQuestion = questionIndex === +questions?.length - 1;

  async function handleNext(newChange) {
    if (verifyNext(questions?.[questionIndex], newChange) || isSummary) {
      if (!isSummary) {
        const addChange = {
          type: questions?.[questionIndex].type,
          questionId: questions?.[questionIndex].id,
          answer: newChange,
          description: questions?.[questionIndex].description,
          title: `${t("challenge.challenge")} ${questionIndex + 1}`,
        };

        answers.push(addChange);
        await setChange(undefined);
        lastQuestion && setIsSummary(true);
      }
      if (isSummary) {
        const filteredAnswers = answers.map(async (item) => {
          if (["TEXTAREA", "NUMERIC"].includes(item?.type)) {
            return {
              questionId: item?.questionId,
              text: item.answer,
            };
          } else if (["CHECKBOX", "MULTISELECT"].includes(item?.type)) {
            return {
              questionId: item?.questionId,
              questionOption: item.answer.map((item) => item.id),
            };
          } else if (["RADIO", "SELECT"].includes(item?.type)) {
            return {
              questionId: item?.questionId,
              questionOption: [item.answer.id],
            };
          } else if (item.type === "FILE") {
            const filesId = await FileService.sendFile(item.answer);
            return {
              questionId: item?.questionId,
              fileId: filesId,
            };
          }
        });
        setIsLoading(true);
        Promise.all(filteredAnswers).then(async (response) => {
          const result = await ChallengeService.sendChallengeAnswers({
            answers: response,
            type: type,
            id: id,
            challengeID: data?.challengeId,
          });
          setStatusResponse(result?.data?.status);

          setNumberOfTries(result?.data?.numberOfTries || 0)
          setTriesLeft(result?.data?.triesLeft || 0)
          setDaysToNextAttempt(result?.data?.daysToNextAttempt || 0)

          if (result?.data?.studyArticles) {
            setStudyArticles(result.data.studyArticles);
          }

          if (result?.data?.challengeQuestionResults) {
            const challengeResultsMap = new Map();
            result.data.challengeQuestionResults.forEach((item: any) => {
              challengeResultsMap.set(item.questionId, item.success);
            });

            const updatedTestResults = response.map((item: any, index: number) => ({
              id: item.questionId,
              title: "Desafio " + (index + 1),
              success: challengeResultsMap.get(item.questionId) || false,
              show: false,
            }));

            setTestResults(updatedTestResults);
          }
          setFormationName(result?.data?.formationName);
          setSkillTreeName(result?.data?.skillTreeName);
          setFormationProgress(result?.data?.formationProgress);
          setSkillGrade(result?.data?.skillGrade * 10); // o resultado da nota vem base10
          setSkillTreeProgress(result?.data?.skillTreeProgress);
          setIsFinal(true);
          setIsLoading(false);
        });
      } else {
        setQuestionIndex(questionIndex + 1);
      }
    }
  }

  function handleBack() {
    const lastChange = answers.pop();
    setChange(lastChange?.answer);
    setQuestionIndex(questionIndex - 1);
    setIsSummary(false);
  }

  useEffect(() => {
    (async () => {
      setQuestionIndex(0);
      setAnswers([]);
      setChange(undefined);
      setData(undefined);
      setQuestions([]);
      const response = await ChallengeService.getChallengeByID(challengeId);
      setQuestions(response?.questions);
      setData(response);
    })();
  }, []);

  // @ts-ignore
  useEffect(() => {
    async function onBlurOrClose() {
      await ChallengeService.patchChallengeCount(challengeId);
    }

    window.addEventListener("blur", onBlurOrClose);

    return async () => {
      await onBlurOrClose();
      window.removeEventListener("blur", onBlurOrClose);
    };
  }, []);

  return (
    <div>
      <S.CenterPage>
        <S.Content>
          <BackButton onClick={() => navigate(-2)}>
            {t("challenge.backPageButton")}
          </BackButton>
          <S.ContainerTexts>
            <S.Title>{t("challenge.challenge")}</S.Title>
            <S.Subtitle>{data?.title}</S.Subtitle>
          </S.ContainerTexts>
          <S.CenterContainerContent>
            <S.ContainerContent>
              {!isFinal && (
                <ProgressBar
                  isChallenge
                  value={questionIndex}
                  max={+questions?.length - 1}
                />
              )}

              <S.ContainerQuestions isFinal={isFinal}>
                <S.QuestionTitle>
                  {isSummary
                    ? `${t("challenge.summary")}`
                    : `${t("challenge.challenge")} ${questionIndex + 1}`}
                </S.QuestionTitle>
                <S.QuestionText>
                  <S.DescriptionData
                    data-cy="challengePageQuestionData"
                    dangerouslySetInnerHTML={{
                      __html: questions?.[questionIndex]?.description,
                    }}
                  />
                </S.QuestionText>
                {handleQuestions(
                  questions?.[questionIndex],
                  change,
                  setChange,
                  questionIndex
                )}
              </S.ContainerQuestions>
              {isSummary && (
                <SummaryResponse answers={answers} isFinal={isFinal} />
              )}
              <S.ContainerButtons isFinal={isFinal}>
                {questionIndex > 0 && !isLoading && (
                  <Button
                    data-cy="challengePageBackButton"
                    height={52}
                    size="custom"
                    onClick={handleBack}
                    text={t("challenge.backButton")}
                    typeOfButton="primary"
                    width={182}
                    style={{
                      background: "#fff0",
                      color: "#000",
                      maxWidth: "50%",
                    }}
                  />
                )}
                <Button
                  data-cy="challengePageSubmitButton"
                  height={52}
                  size="custom"
                  onClick={() => {
                    (async () => {
                      if (
                        verifyNext(questions?.[questionIndex], change) ||
                        isSummary
                      ) {
                        await setChange(undefined);
                        await handleNext(change);
                      }
                    })();
                  }}
                  text={
                    isSummary
                      ? t("challenge.submitButton")
                      : t("challenge.nextButton")
                  }
                  typeOfButton="primary"
                  disabled={isLoading}
                  width={182}
                  style={{
                    maxWidth: "50%",
                    ...(() => {
                      if (
                          (!verifyNext(questions?.[questionIndex], change) &&
                        !isSummary) || isLoading
                      ) {
                        return {
                          background: "#C4C4C4",
                          cursor: "default",
                        };
                      }
                      return {};
                    })(),
                  }}
                />
              </S.ContainerButtons>
              {isFinal && <ChallengeResponse
                status={statusResponse}
                questionsResults={testResults}
                formationName={formationName}
                formationProgress={formationProgress}
                skillGrade={skillGrade}
                skillTreeName={skillTreeName}
                skillTreeProgress={skillTreeProgress}
                studyArticles={studyArticles}
                numberOfTries={numberOfTries}
                daysToNextAttempt={daysToNextAttempt}
                triesLeft={triesLeft}
              />}
            </S.ContainerContent>
          </S.CenterContainerContent>
        </S.Content>
      </S.CenterPage>
    </div>
  );
}
