import React, { CSSProperties } from "react";
import * as S from "./styles";
import ArrowBackIcon from "../../assets/arrowback.svg";

interface IBackButton {
  children?: any;
  onClick?: any;
  style?: CSSProperties;
}

function BackButton({ children, onClick, style }: IBackButton) {
  return (
    <S.ButtonContainer style={style} onClick={onClick}>
      <img src={ArrowBackIcon} alt="Arrow_Back_Icon" />
      <S.ButtonText>{children}</S.ButtonText>
    </S.ButtonContainer>
  );
}

export default BackButton;
