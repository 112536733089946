/* eslint-disable react/no-unstable-nested-components */
import {useEffect, useRef, useState} from "react";
import {
  Alert,
  Autocomplete,
  MenuItem,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import {ResponsiveInputText} from "../../../components/ResponsiveInputText";
import * as S from "./styles";
import CheckIcon from "@mui/icons-material/Check";
import {Button} from "../../../components/Button";
import DefaultLoading from "../../../components/Loading/defaultLoading";
import SkillService from "../../../services/SkillService";
import SkillTreeService from "../../../services/SkillTreeService";
import {t} from "i18next";

function SkillsModal({data, onClose, onAdd, skillTree}) {
  const [array, setArray] = useState([]);
  const [autocompleteArray, setAutocompleteArray] = useState([]);
  const [skillsSelecteds, setSkillsSelecteds] = useState(
    skillTree?.skills?.map((sk) => ({
      ...sk,
      selected: sk?.selected || true,
    })) || []
  );
  const [loading, setLoading] = useState(true);
  const filter = createFilterOptions();
  const serviceRef = useRef(null);
  const [value, setValue] = useState("");
  const [subtractedValue, setSubtractedValue] = useState(-2);

  function decreaseSubtractedValue() {
    setSubtractedValue((value) => value - 1);
  }

  useEffect(() => {
    function decreaseSubtractedValueForNegativeIds() {
      setSkillsSelecteds((prevSkills) => {
        const updatedSkills = prevSkills.map((skill) => {
          if (skill.id < 0) {
            return { ...skill, selected: false };
          }
          return skill;
        });
        const negativeIdsCount = prevSkills.filter((skill) => skill.id < 0).length;
        setSubtractedValue((prevValue) => prevValue - negativeIdsCount);
        return updatedSkills;
      });
    }

    decreaseSubtractedValueForNegativeIds();
  }, []);



  function Checkbox({data, index}) {
    return (
      <MenuItem
        onClick={() => {
          let newArray;
          const newSkillsSelecteds = [...skillsSelecteds];
          if (index === -1) {
            const isAllSelected = data?.selected;
            newArray = array.map((item) =>
              isAllSelected
                ? {...item, selected: false}
                : {...item, selected: true}
            );
          } else {
            newArray = array.map((item, i) =>
              data.id === item.id ? {...item, selected: !item.selected} : item
            );
            const existingIndex = newSkillsSelecteds.findIndex(
              (item) => item.id === data.id
            );
            if (existingIndex !== -1) {
              newSkillsSelecteds.splice(existingIndex, 1);
            }
          }

          newArray.forEach((item) => {
            if (item.selected && !newSkillsSelecteds.includes(item)) {
              newSkillsSelecteds.push(item);
            }
          });

          setSkillsSelecteds(newSkillsSelecteds);
          setArray(newArray);
        }}
        style={{
          minWidth: 32,
          maxWidth: 32,
          minHeight: 32,
          maxHeight: 32,
          borderRadius: 32,
        }}
        sx={{
          color: "rgba(236, 73, 144)",
          p: 0,
          m: 0,
          ":hover": {
            backgroundColor: "rgba(236, 73, 144, 0.15)",
          },
        }}
      >
        <S.CheckboxOutCircle selected={data.selected}>
          {data.selected && (
            <CheckIcon
              sx={{fontSize: 16, color: "#fff"}}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                padding: 0,
                margin: 0,
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </S.CheckboxOutCircle>
      </MenuItem>
    );
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await SkillService.getSkillModal();
        setAutocompleteArray(response.sort((a, b) => b.id - a.id));
        setLoading(false);
        if (data.id >= 0) {
          const result = await SkillTreeService.getCompetenceSkills([data.id]);
          setArray([
            ...skillsSelecteds,
            ...result.filter(
              (item) => !skillsSelecteds.some((item2) => item.id === item2.id)
            ),
          ]);
        } else {
          setArray([...skillsSelecteds]);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <S.FullModal>
      <S.ModalTitle>
        {t("positionsManagement.infoSelectSkill") + " "}
        {data.label.replace(`${t("positionsManagement.add")} `, "")}
      </S.ModalTitle>
      <S.ModalSubtitle>{t("positionsManagement.doYouWish")}</S.ModalSubtitle>
      {data.id < 0 && (
        <Alert severity="warning" sx={{marginTop: 1, marginBottom: 1}}>
          {t("positionsManagement.causeItsCompetence")}
        </Alert>
      )}
      {array.find((i) => i.id < 0) && (
        <Alert
          severity="warning"
          sx={{width: "100%", marginTop: 1, marginBottom: 1}}
        >
          {t("positionsManagement.itsNecessary")}
        </Alert>
      )}
      <S.ContainerInput>
        <Autocomplete
          ref={serviceRef}
          disablePortal
          options={autocompleteArray.sort((a, b) => b.id - a.id)}
          noOptionsText={t("noOptionsMui")}
          sx={{
            "& .MuiInputBase-root": {
              background: "#fff",
              fontWeight: 700,
              fontSize: 18,
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
              "& fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
            },
          }}
          onChange={(event, value) => {
            const isExisting = array.some((item) => item?.name === value?.name);

            if(value){
              if(value.id < 0) {
                decreaseSubtractedValue();
              }
              if (!isExisting) {
                setValue("");
                setArray([
                  ...array,
                  {
                    ...value,
                    name: value?.name?.replace(`${t("positionsManagement.add")} `, ""),
                    selected: true,
                  },
                ]);
              }
            }
          }}
          inputValue={value}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const {inputValue} = params;
            const isExisting = options.some(
              (option) => inputValue === option.name
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `${t("positionsManagement.add")} ${inputValue}`,
                id: subtractedValue,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(params, opt) => (
            <Typography
              {...params}
              key={opt.id}
              sx={{
                p: 0,
                m: 0,
              }}
              style={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "19px",
                paddingBottom: "19px",
                backgroundColor: "auto",
                gap: 13,
                opacity: 1,
                fontFamily: "Ubuntu",
                fontWeight: 500,
                fontSize: 16,
                color: "#8c8c8c",
              }}
            >
              {opt?.name}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                borderRadius: 0,
                width: "100%",
                height: "100%",
                fontSize: 16,
                fontWeight: 500,
                outline: "none",
                verticalAlign: "middle",
                color: "#131212",
                background: "#ffffff",
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              placeholder={t("positionsManagement.skill")}
            />
          )}
        />
      </S.ContainerInput>

      <S.TextBold>{t("positionsManagement.sugestion")}</S.TextBold>
      <S.ContainerOptions>
        {!loading ? (
          <div>
            {array.length > 0 ? (
              <S.RowOptionAll>
                <Checkbox
                  data={{
                    selected: array.every((i) => !!i.selected),
                  }}
                  index={-1}
                />
                <S.RowOptionText>
                  {t("positionsManagement.selectAll")}
                </S.RowOptionText>
              </S.RowOptionAll>
            ) : (
              <S.RowOptionText>
                {t("positionsManagement.noSkillFound")}
              </S.RowOptionText>
            )}
            <S.ContainerInnerOptions>
              {array.map((item, index) => (
                <S.RowOptionAll data-cy='skillCheckbox'>
                  <Checkbox data={item} index={index} />
                  <S.RowOptionText>{item.name}</S.RowOptionText>
                </S.RowOptionAll>
              ))}
            </S.ContainerInnerOptions>
          </div>
        ) : (
          <div>
            <DefaultLoading color="#696969" />
            <p>{t("positionsManagement.loading")}</p>
          </div>
        )}
      </S.ContainerOptions>

      <S.ContainerButton>
        <Button
          style={{
            background: "#fff",
            borderWidth: 1,
            borderColor: "#131212",
            borderStyle: "solid",
            color: "#131212",
          }}
          onClick={onClose}
        >
          {t("positionsManagement.cancel")}
        </Button>
        <Button
          data-cy='confirmSkill'
          onClick={() => {
            onAdd(array.filter((item) => !!item.selected));
            onClose();
          }}
        >
          {t("positionsManagement.confirm")}
        </Button>
      </S.ContainerButton>
    </S.FullModal>
  );
}

export default SkillsModal;
