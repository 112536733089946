/* eslint-disable react/no-unstable-nested-components */
import {Alert} from "@mui/material";
import {useState} from "react";
import {Button} from "../../../components/Button";
import * as S from "./styles";
import {t} from "i18next";

function ConfirmationModal({data, onClose, onAdd, formationPayload, isCurrentUserSysAdmin}) {
  const [skillsSelecteds, setSkillsSelecteds] = useState(data || []);

  return (
    <S.FullModal>
      <S.ModalTitle>{t("positionsManagement.skillsWithoutChallenges")}</S.ModalTitle>
      <p style={{marginTop: 50, marginBottom: 50}}>
        {t("positionsManagement.noChallengesAssociated")}
      </p>
      <S.ContainerOptions>
        <ul
          style={{
            textAlign: "left",
            fontSize: 18,
            width: "100%",
            listStyleType: "none"
          }}
        >
          {skillsSelecteds?.length > 0 &&
            skillsSelecteds.map((skill) => {
              return (
                <>
                  <li
                    style={{
                      fontWeight: "bold",
                      padding: "8px"
                    }}
                  >
                    {skill.name}
                  </li>
                  <hr/>
                </>
              );
            })}
        </ul>
      </S.ContainerOptions>
      {isCurrentUserSysAdmin &&
          <div>
              <Alert style={{width: "100%", textAlign: "left"}} severity="info">
                  <div dangerouslySetInnerHTML={
                    {__html: t('positionsManagement.automaticChallengeGeneration', {interpolation: {escapeValue: false}})}
                  }/>
              </Alert>
              <Alert
                  style={{width: "100%", textAlign: "left", marginTop: "20px"}}
                  severity="warning"
              >
                  <div dangerouslySetInnerHTML={
                    {__html: t('positionsManagement.cannotEditOrPublish', {interpolation: {escapeValue: false}})}
                  }/>
                  <div dangerouslySetInnerHTML={
                    {__html: t('positionsManagement.realTimeProcessMonitoring', {interpolation: {escapeValue: false}})}
                  }/>
              </Alert>
          </div>
      }
      <Alert
        style={{width: "100%", textAlign: "left", marginTop: "20px"}}
        severity="error"
      >
        <div dangerouslySetInnerHTML={
          {__html: t('positionsManagement.manualChallengeCreation', {interpolation: {escapeValue: false}})}
        }/>
      </Alert>
      <S.TextBold style={{marginTop: 50, marginBottom: 50}}></S.TextBold>
      <S.ContainerButton>
        <Button
          style={{
            background: "#fff",
            borderWidth: 1,
            borderColor: "#131212",
            borderStyle: "solid",
            color: "#131212"
          }}
          onClick={() => {
            formationPayload.generateByAI = false;
            onAdd(formationPayload);
            onClose();
          }}
        >
          {t("positionsManagement.saveAsDraft")}
        </Button>
        {
          isCurrentUserSysAdmin &&
            <Button
                onClick={() => {
                  formationPayload.generateByAI = true;
                  onAdd(formationPayload);
                  onClose();
                }}
            >
              {t("positionsManagement.generateAutomatically")}
            </Button>
        }
      </S.ContainerButton>
    </S.FullModal>
  );
}

export default ConfirmationModal;
