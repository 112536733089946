import * as React from "react";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as S from "./style";

import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from "@mui/material";

import * as T from "../../Global/Typography";
import SkillTreeForm from "./Form";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SkillTreeService from "../../services/SkillTreeService";
import { TableContainerLayout } from "../../components/TableContainerLayout";
import { ButtonDS } from "../Login/style";
import { generateQueryParamsStringFilterReportPage } from "../../components/utils/http";
import { ReportType, SummaryBy } from "../../services/ReportService";
import { t } from "i18next";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import { useForm } from "react-hook-form";
import InfoSkillsModal from "./Modals/informationSkills";

export default function SkillTrees() {
  const [page, setPage] = React.useState<number>(1);
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<5 | 10 | 25>(
    5
  );
  const [action, setAction] = React.useState("list");
  const [selected, setSelected] = React.useState<any>();
  const [search, setSearch] = React.useState<string>("");
  const [totalPages, setTotalPages] = React.useState(0);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const { control, setValue } = useForm();

  const fetchData = async () => {
    const result = await SkillTreeService.getSkillTreeManagement(page - 1, rowsPerPage, search);
    const totalRows = result?.content?.map((row: any) => {
      return {
        id: row?.id,
        name: row?.name,
        description: row?.description,
        company: row?.company,
        companyLogo: row?.companyLogo,
        isSkillHunter: row?.isSkillHunter
      };
    })
    setTotalPages(result.totalPages);
    setRows(totalRows);
  };


  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search, rowsPerPage]);

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleFeedbackAction = async (
    type: string,
    newData: any,
    submitAction: string
  ) => {
    setAction(type === "success" ? "list" : "form");
    if (submitAction === "edit") {
      setRows(
        rows.map((row) => {
          if (row.ID === newData.ID) {
            row.name = newData.name;
          }
          return row;
        })
      );
    } else {
      const newRows = [newData, ...rows];
      setRows(newRows);
    }
    await fetchData();
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
  };

  const handleEdit = (id) => {
    setSelected({ id });
    setAction("form");
    setOpenEditDialog(false);
  };

  const handleOpenDialog = async (id, type) => {
    setSelected(id);
    if (type === "infoSkill") {
      setOpenInfoDialog(true);
      const result = await SkillTreeService.findIdSkillTree(id);
      setName(result.name);
      setValue("skills", result?.skills);
    }
    if (type === "edit") {
      setOpenEditDialog(true);
    }
  };

  const handleCloseDialog = (type) => {
    setSelected(undefined);
    if (type === "infoSkill") {
      setOpenInfoDialog(false);
    }
    if (type === "edit") {
      setOpenEditDialog(false);
    }
  };

  const handleFollowToReportPage = (id) => {
    const queryParams = generateQueryParamsStringFilterReportPage(
      SummaryBy.SkillTree,
      id,
      null,
      ReportType.SkillFocus
    );
    navigate(`/management/performance-report?${queryParams}`);
  };

  return (
    <>
      <S.ContainerFullPage>
        <Dialog
          open={openEditDialog}
          onClose={() => handleCloseDialog("edit")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("formCompetence.confirmEditCompetence")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>{t("formCompetence.editingMayAffectCourses")}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions data-cy="skillTreeConfirmEdit">
            <ButtonDS
              typeOfButton="secondary"
              size="custom"
              height={40}
              width={140}
              fontVariant="body1"
              text={t("formCompetence.cancel")}
              onClose={() => handleCloseDialog("edit")}
            />
            <ButtonDS
              typeOfButton="primary"
              size="custom"
              height={40}
              width={140}
              fontVariant="body1"
              text={t("formCompetence.edit")}
              onClick={() => handleEdit(selected)}
            />
          </DialogActions>
        </Dialog>
        <InfoSkillsModal open={openInfoDialog} handleClose={() => handleCloseDialog("infoSkill")} name={name} skills={control} />
        <T.Display data-cy="skillTreeH1">{t("competenceList.competences")}</T.Display>
        {action === "list" && (
          <TableContainerLayout
            search={search}
            setSearch={setSearch}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            setRowsPerPage={(newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage);
            }}
            totalPages={totalPages}
            onClickAddButton={onClickAddButton}
          >
            <TableContainer sx={{ width: "100%", background: "white" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "65%" }}>
                      <S.FontHeaderTable>{t("competenceList.name")}</S.FontHeaderTable>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <S.FontHeaderTable>{t("skills.company")}</S.FontHeaderTable>
                    </TableCell>
                    <TableCell sx={{ width: "15%" }}>
                      <S.FontHeaderTable>{null}</S.FontHeaderTable>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.length > 0 &&
                    rows.map((item) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell data-cy="skillTreeTableName">
                            <span style={{ whiteSpace: "normal" }}>{item.name}</span>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <S.CompanyIcon
                                src={item?.companyLogo || skillHunterPositionIcon}
                                alt=""
                              />
                              {item?.company ||
                                "SkillHunter"}
                            </div>
                          </TableCell>
                          <TableCell data-cy="skillTreeTableEditButton">
                            <div>
                            <IconButton onClick={() => handleOpenDialog(item.id, "infoSkill")}>
                              <Tooltip arrow placement="top" title={t("competenceList.aboutCompetence")}>
                                <InfoOutlinedIcon fontSize="medium" />
                              </Tooltip>
                            </IconButton>
                            {!item?.isSkillHunter && (
                              <IconButton
                                onClick={() => handleOpenDialog(item.id, "edit")}
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                            )}
                          </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}

                  {rows.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                        {t("formCompetence.noResultFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableContainerLayout>
        )}
        {action === "form" && (
          <SkillTreeForm
            feedbackAction={handleFeedbackAction}
            onBackPress={() => {
              setAction("list");
              setSelected(undefined);
            }}
            dataToEdit={selected}
            isEdit
          />
        )}
      </S.ContainerFullPage>

    </>
  );
}
