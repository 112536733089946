import { ReportType, SummaryBy } from "../../services/ReportService";

export function objectToURLSearchParams(obj: any) {
  return new URLSearchParams(obj).toString();
}

export function generateQueryParamsStringFilterReportPage(
  summaryBy: SummaryBy,
  skillFocusId: number,
  userId: number,
  reportType: ReportType,
  userName?: string,
) {
  const queryParams: any = {
    summarizeBy: summaryBy,
    skillFocusId: skillFocusId,
    userId: userId,
    userName: userName,
    reportType: reportType,
  };

  const filteredParams = Object.fromEntries(
    Object.entries(queryParams).filter(([_, value]) => value != null)
  );

  return objectToURLSearchParams(filteredParams);
}

export function generateQueryParamsStringFilterReportPageWithPool(
  summaryBy: SummaryBy,
  skillFocusId: number,
  userId: number,
  userName: string,
  reportType: ReportType,
  filterCompany: number,
  filterType: number,
) {
  const queryParams: any = {
    summarizeBy: summaryBy,
    skillFocusId: skillFocusId,
    userId: userId,
    userName: userName,
    reportType: reportType,
    selectedPool: filterCompany,
    filterType: filterType,
  };

  const filteredParams = Object.fromEntries(
    Object.entries(queryParams).filter(([_, value]) => value != null)
  );

  return objectToURLSearchParams(filteredParams);
}
