import api from "./api";

class BlackListService {

  async getBlackList(search: string, page: number) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `blacklist?filter=${filter}&page=${page}&size=5`
    );
    return response.data;
  }

  async removeBlackList(id: string) {
    await api.delete(`blacklist/${id}`);
  }

  async addBlackList(id: string) {
    await api.post(`blacklist/${id}`);
  }
}

export default new BlackListService();