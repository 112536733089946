import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import RoleType from "../types/enums/RoleType";
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";

interface PrivateRouteProps {
  role?: RoleType;
  hasAccess?: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
                                                            role = RoleType.ROLE_SYSADMIN,
                                                            children,
                                                            hasAccess,
                                                          }) => {
  if (!UserService.hasAccess(role) && !hasAccess) {
    localStorage.setItem("noAccess", "1");
    return <Navigate to="/"/>;
  }
  return children ? <div>{children}</div> : <Outlet/>;
};
