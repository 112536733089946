/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, useRef, CSSProperties } from "react";
import * as S from "./styles";
import SetaFillDownIcon from "../../assets/setaFillDownWhite.svg";
import SetaFillUpIcon from "../../assets/setaFillUpWhite.svg";
import MenuItem from "@mui/material/MenuItem";
import { t } from "i18next";
import { Autocomplete, TextField } from "@mui/material";

interface ISelect {
  options: {
    id?: number | string;
    value?: string | number;
    name?: string;
  }[];
  selected?: {
    id?: number | string;
    value?: string | number;
    name?: string;
  };
  onSelect: (_: any) => any;
  placeholder: string;
  showSearch?: boolean;
  error?: any;
  sortAlphabetical?: boolean;
  listAbsolute?: boolean;
  label?: any;
  disabled?: boolean;
  style?: CSSProperties;
  withoutNoSelect?: boolean;
  onChange: (value: string) => void;
}

function SelectOrange({
  options,
  selected,
  onSelect,
  showSearch = true,
  placeholder,
  error,
  sortAlphabetical = true,
  listAbsolute = false,
  disabled,
  withoutNoSelect,
  style,
  onChange,
}: ISelect) {
  const [styles, setStyles] = useState(style)
  const stylesAutoComplete = S.OverrideStylesMui();

  let newPlaceholder = placeholder;
  if (!withoutNoSelect) {
    options = [
      {
        id: null,
        value: `${newPlaceholder}`,
      },
      ...options,
    ];
  }
  const convertItem = (item) => item?.name || item?.value;
  const isError = typeof error === "string" && error?.length > 0;

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const equalObject = (obj1, obj2) => {
    function convertObj(obj) {
      return {
        ...(obj?.id ? { id: obj.id } : {}),
        ...(obj?.name ? { name: obj.name } : {}),
        ...(obj?.value ? { value: obj.value } : {}),
      };
    }

    return (
      JSON.stringify(convertObj(obj1)) === JSON.stringify(convertObj(obj2))
    );
  };

  const [selectedFilter, setSelectedFilter] = useState<any>(selected);
  const [text, setText] = useState("");

  const ref = useRef(null);
  const inputRef = useRef(null);

  const filteredOptions = options
    ?.filter((opt) => (text.length > 0 ? !(opt.id === null) : !!opt))
    ?.filter((opt) =>
      convertItem(opt)
        ?.toString()
        ?.trim()
        ?.toLocaleUpperCase()
        ?.includes(text?.trim()?.toLocaleUpperCase())
    )
    ?.sort((a, b) => sortAlphabeticalOptions(a, b))
    ?.sort((a) => (equalObject(selectedFilter, a) ? -1 : 1))
    ?.sort((a) => (a.id === null ? -1 : 1));

  const [selectedOption, setSelectedOption] = useState(selectedFilter);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isFocused && ref.current && !ref.current.contains(e.target)) {
        inputRef.current.blur();
        setIsFocused(false);
        setText("");
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isFocused]);

  function sortAlphabeticalOptions(a, b) {
    if (sortAlphabetical) {
      return convertItem(a).toString().localeCompare(convertItem(b).toString());
    }
  }

  useEffect(() => {
    setSelectedFilter(selected);
  }, [selected]);

  useEffect(() => {
    setStyles(style)
  }, [style])

  return (
    <S.Container style={{ ...styles, height: undefined }} ref={ref}>
      <Autocomplete
        options={filteredOptions}
        classes={{
          root: stylesAutoComplete.MuiAutocompleteRoot
        }}
        getOptionLabel={(option) => convertItem(option) || ''}
        value={selectedOption}
        onChange={(event, newValue) => {
          setSelectedOption(newValue);
          onSelect(newValue);
        }}
        noOptionsText={t("noOptionsMui")}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={(e) => {
              setIsFocused(!isFocused);
              if (isFocused) {
                inputRef.current.blur();
              } else {
                inputRef.current.focus();
              }
            }}
            sx={{
              borderColor: '#E85C20',
              color: "#FFF",
              "& fieldset": {
                boxShadow: '0 0 0 0',
                border: '0 none',
                outline: 0,
              },
            }}
            ref={inputRef}
            onChange={(e) => { showSearch && setText(!isFocused ? "  " : e.target.value); onChange(e.target.value) }}
            value={text}
            disabled={disabled}
            placeholder={convertItem(selectedFilter) || placeholder}
            style={{
              ...styles,
              backgroundImage: `url(${isFocused ? SetaFillUpIcon : SetaFillDownIcon
                })`,
            }}
            inputProps={{
              ...params.inputProps,
              style: S.customInputPropsStyle,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {isError && (
                    <S.ErrorText data-cy="selectErrorMessage">{error}</S.ErrorText>
                  )}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <>
            {option.id !== null && (
              <MenuItem
                {...props}
                sx={{
                  p: 0,
                  m: 0,
                  color: option.id === null && !selectedFilter ? '#0000' : 'default',
                  ':hover': {
                    background:
                      option.id === null && !selectedFilter ? '#0000' : 'default',
                  },
                }}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 19,
                  backgroundColor: equalObject(option, selectedFilter)
                    ? '#00000012'
                    : 'auto',
                  gap: 13,
                  opacity: option.id === null && !selectedFilter ? 0.4 : 1,
                }}
              >
                <S.OptionText>{convertItem(option)}</S.OptionText>
              </MenuItem>
            )}
          </>
        )}
        style={S.customInputStyle}
      />
    </S.Container>
  );
}

export { SelectOrange };
