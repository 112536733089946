import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { StyledLink } from "../../../../Global/Typography";
import { ProgressBar } from "../../../../components/Progress";
import { Formation } from "../../../../models/Formation";
import { Trail } from "../../../../models/Trail";

interface IFormationProgressRow {
  formation?: Formation;
  trail?: Trail;
  halfSize?: boolean;
  isLoading?: boolean;
  dataType: "trail" | "position";
}

function FormationProgressRow(data: IFormationProgressRow) {
  const formation = data.formation;
  const trail = data.trail;
  const isTrail = () => {
    return data.dataType === "trail";
  };
  return (
    <Grid container marginBottom={3}>
      <Grid item xs={1} display={"flex"}>
        {(data.isLoading && (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={data.halfSize ? 30 : 40}
              height={data.halfSize ? 30 : 40}
            />
          </div>
        )) || (
          <img
            width={data.halfSize ? "36px" : "100%"}
            height={data.halfSize ? "36px" : "inherit"}
            style={{ objectFit: "contain" }}
            src={
              isTrail()
                ? trail?.company?.avatar?.fileUrl
                : formation?.company?.avatar?.fileUrl
            }
          />
        )}
      </Grid>
      <Grid
        paddingLeft={2}
        display={"flex"}
        alignItems={"center"}
        item
        width={"100%"}
        sm={data.halfSize ? 10 : 5}
        xs={10}
      >
        <Box width={data.isLoading ? "80%" : "inherit"}>
          <StyledLink
            to={
              isTrail()
                ? `/course/${trail?.company?.slug}/${trail?.slug}`
                : `/position/${formation?.company?.slug}/${formation?.slug}`
            }
          >
            {(data.isLoading && <Skeleton width={"100%"} />) || (
              <>{isTrail() ? trail?.name : formation?.title}</>
            )}
          </StyledLink>
          <Typography
            fontSize={data.halfSize ? "0.8rem" : "inherit"}
            marginTop={0.3}
            variant="body2"
            fontWeight={300}
          >
            {(data.isLoading && <Skeleton width={"100%"} />) || (
              <>
                {isTrail()
                  ? trail?.company.tradeName
                  : formation?.company.tradeName}
              </>
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid
        display={"flex"}
        alignItems={"center"}
        item
        container
        sm={data.halfSize ? 11 : 5}
        marginTop={data.halfSize ? 2 : 0}
        xs={12}
      >
        <Box marginTop={1} width={"100%"}>
          {(data.isLoading && <Skeleton width={"90%"} />) || (
            <ProgressBar
              smallSize
              value={isTrail() ? trail?.percentage : formation?.percentage}
              max={100}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default FormationProgressRow;
