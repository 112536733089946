/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import * as React from "react";
import { TableContainerLayout } from "../../components/TableContainerLayout";
import * as T from "../../Global/Typography";
import ChallengeService from "../../services/ChallengeService";
import { ButtonDS } from "../Login/style";
import ChallengesForm from "./Form";
import * as S from "./style";

export default function Challenges() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [action, setAction] = React.useState("list");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [selected, setSelected] = React.useState(undefined);
  const [deleted, setDeleted] = React.useState(false);
  const [addButton, setAddButton] = React.useState(false);
  const [search, setSearch] = React.useState<string>("");
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState<number>(1);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const fetchData = async () => {
    const result = await ChallengeService.getAllChallenge(page - 1, search);
    setTotalPages(result.totalPages);
    setRows(
      result.content.map((item: any) => {
        return {
          id: item?.id,
          title: item?.title,
          skill: item?.skill.name,
          questions: item?.questions?.length,
          actionsButtons: (
            <div>
              <IconButton onClick={() => handleOpenEditDialog(item.id)}>
                <EditOutlinedIcon />
              </IconButton>
            </div>
          ),
        };
      })
    );
  };

  React.useEffect(() => {
    setPage(1);
    fetchData();
  }, [search]);

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const handleFeedbackAction = (
    type: string,
    newData: any,
    submitAction: string
  ) => {
    setShowAlert(true);
    setAction(type === "success" ? "list" : "form");
    setAlertType(type === "success" ? "success" : "error");
    if (submitAction === "edit") {
      setRows(
        rows.map((row) => {
          if (row.id === newData.id) {
            row.title = newData.title;
          }
          return row;
        })
      );
    } else {
      fetchData();
    }
  };

  const handleEdit = (id) => {
    setSelected({ id });
    setAction("form");
    setDeleted(false);
    setOpenEditDialog(false);
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
    setAddButton(true);
    setDeleted(false);
  };

  const handleOpenEditDialog = (id) => {
    setSelected(id);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setSelected(undefined);
    setOpenEditDialog(false);
  };

  return (
    <S.ContainerFullPage>
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Você tem certeza que deseja editar este desafio?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>A edição pode afetar o funcionamento das trilhas relacionadas</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text="Cancelar"
            onClick={handleCloseEditDialog}
          />
          <ButtonDS
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text="Editar"
            onClick={() => handleEdit(selected)}
            data-cy="acceptEdit"
          />
        </DialogActions>
      </Dialog>
      <T.Display data-cy="challengesH1">Desafios</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{ width: "100%", background: "white" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <S.FontHeaderTable>Id</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: "31%" }}>
                    <S.FontHeaderTable>Título</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: "31%" }}>
                    <S.FontHeaderTable>Habilidade</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: "31%" }}>
                    <S.FontHeaderTable>Nº Desafios</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length > 0 &&
                  rows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell data-cy="challengeTitle">
                        {item.title}
                      </TableCell>
                      <TableCell>{item.skill}</TableCell>
                      <TableCell>{item.questions}</TableCell>
                      <TableCell data-cy='challengesTableEditButton'>{item.actionsButtons}</TableCell>
                    </TableRow>
                  ))}
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{ textAlign: "center" }}
                      data-cy="challengeTitleNotFound"
                    >
                      Nenhum resultado encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (selected || addButton) && (
        <ChallengesForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          dataToEdit={selected}
          data-cy="challengesScreenEditButton"
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType === "success" ? "success" : "error"}
          sx={{ width: "100%" }}
          data-cy="challengesAlerts"
        >
          {alertType === "success"
            ? deleted
              ? "Desafio deletado com sucesso"
              : selected
              ? "Desafio editado com sucesso"
              : "Desafio inserido com sucesso"
            : "Erro ao inserir desafio"}
        </Alert>
      </Snackbar>
    </S.ContainerFullPage>
  );
}
