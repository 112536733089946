/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import {useEffect, useState} from "react";

import {
  Alert,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import {useNavigate} from "react-router-dom";
import * as T from "../../Global/Typography";
import "dayjs/locale/pt-br";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import BlackListService from "../../services/BlackListService";
import {t} from "i18next";
import * as S from "./style";
import {TableContainerLayout} from "../../components/TableContainerLayout";
import {ReportType, SummaryBy} from "../../services/ReportService";
import {generateQueryParamsStringFilterReportPage} from "../../components/utils/http";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import {BlackListModal} from "../../components/BlackListModal";
import {enqueueSnackbar} from "notistack";

export default function Blacklist() {
  const [rows, setRows] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [showBlackListModal, setShowBlackListModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  const handleFollowToReportPage = (id) => {
    const queryParams = generateQueryParamsStringFilterReportPage(
      SummaryBy.Formation,
      null,
      id,
      ReportType.UserFocus
    );
    navigate(`/management/performance-report?${queryParams}`);
  };

  const fetchData = async () => {
    const resultFormation = await BlackListService.getBlackList(
      search,
      page - 1
    );

    setTotalPages(resultFormation.totalPages);
    setRows(
      resultFormation.content.map((item: any) => {
        return {
          id: item?.id,
          name: item?.user?.name,
          company: item?.company,
          actionsButtons: (
            <IconButton
              onClick={() => {
                setSelectedId(item?.id);
                setShowBlackListModal(true);
              }}
            >
              <RemoveCircleOutlineOutlinedIcon />
            </IconButton>
          ),
        };
      })
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleRemoveBlackList = async (id) => {
    await BlackListService.removeBlackList(id);
    enqueueSnackbar(t("blacklist.usuarioRemovidoBlacklist"), {
      variant: "success",
    });
    setShowBlackListModal(false);
    setSelectedId(null);
    fetchData();
  };

  return (
    <S.ContainerFullPage>
      <T.Display>{t("blacklist.blacklist")}</T.Display>
      <TableContainerLayout
        search={search}
        setSearch={setSearch}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        hideAddButton
      >
        <TableContainer sx={{width: "100%", background: "white"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{width: "50%"}}>
                  <S.FontHeaderTable>
                    {t("blacklist.nomeUsuario")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell sx={{width: "50%"}}>
                  <S.FontHeaderTable>
                    {t("blacklist.empresa")}
                  </S.FontHeaderTable>
                </TableCell>

                <TableCell>
                  <S.FontHeaderTable>{null}</S.FontHeaderTable>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.length > 0 &&
                rows.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <S.NameTableRow>{item.name}</S.NameTableRow>
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <S.CompanyIcon
                          src={item?.company?.avatar || skillHunterPositionIcon}
                          alt={skillHunterPositionIcon}
                        />
                        {item?.company?.tradeName ||
                          item?.company?.name ||
                          item?.company?.companyName ||
                          "SkillHunter"}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={t("blacklist.removerBlacklist")}
                        arrow
                        placement="top"
                      >
                        {item.actionsButtons}
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}

              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} sx={{textAlign: "center"}}>
                    {t("blacklist.nenhumResultadoEncontrado")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainerLayout>
      <BlackListModal
        open={showBlackListModal}
        onClose={() => {
          setShowBlackListModal(false);
          setSelectedId(null);
        }}
        title={t("blacklist.confirmarRemocaoBlacklist")}
        content={t("blacklist.usuarioExibidoResultados")}
        handleBlackList={handleRemoveBlackList}
        confirmContent={t("blacklist.remover")}
        id={selectedId}
      />
    </S.ContainerFullPage>
  );
}
