import styled from "styled-components";

export const FatherItem = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0 2rem;
  gap: 0.75rem;
  overflow: hidden;
  @media screen and (max-width: 999px) {
    padding: 0 1rem;
    gap: 0.55rem;
  }
`;

export const ChildItemsContainer = styled.div<{ open: boolean }>`
  width: 100%;
  height: ${({ open }) => (open ? 280 : 0)}px;
  transition: height 0.2s;
  overflow: hidden;
`;

export const TextSideBar = styled.p`
  font-weight: 300;
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FullItemContainer = styled.div`
  width: 100%;
  min-width: 265px;
  display: flex;
  flex-direction: column;
`;
