import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { Button, InputDefault } from "design-system-firedev";
import { ContainerDropdown, ContainerGap } from "./style";
import { zodResolver } from "@hookform/resolvers/zod";
import zod from "zod";
import { Controller, useForm } from "react-hook-form";
import { ISkill } from "../../Skills/Form";
import SkillService from "../../../services/SkillService";
import { Select } from "../../../components/Select";
import * as S from "./style";
import { ResponsiveInputText } from "../../../components/ResponsiveInputText";
import { t } from "i18next";
interface IChallenge {
  id: number;
  title: string;
  skillId: number;
  questions?: string[];
}

interface IChallengesForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: IChallenge | undefined;
}

interface IValidation {
  name: string;
  title: string;
  skill: {
    id: number;
    name: string;
  };
}

interface IOriginalDataProps {
  id: number;
  title: string;
  questions: number[];
  skill: {
    id: number;
    name: string;
  };
}

interface IDataDropDown {
  id: number | string;
  value: string;
}

export default function ChallengesForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: IChallengesForm) {
  const isEditing = typeof dataToEdit !== "undefined";

  const [originalData, setOriginalData] = useState<IOriginalDataProps>(
    {} as IOriginalDataProps
  );
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [searchDropdown, setSearchDropdown] = useState("");

  const fetchData = async () => {
    const resultGetDataSkills = await SkillService.getSkillToDropdown(
      searchDropdown
    );
    setSkills(resultGetDataSkills);

    const resultGetByIdChallenges = await api.get(
      `challenges/${dataToEdit.id}`
    );

    setOriginalData(resultGetByIdChallenges.data);
    setValue("title", resultGetByIdChallenges.data.title);
    setValue("skill", resultGetByIdChallenges.data.skill);
  };

  const fetchSkillDropdown = async () => {
    const resultGetDataSkills = await SkillService.getSkillToDropdown(
      searchDropdown
    );
    setSkills(resultGetDataSkills);
  };

  useEffect(() => {
    fetchData();
  }, [isEditing]);

  useEffect(() => {
    fetchSkillDropdown();
  }, [searchDropdown]);

  const handleCreateNewChallenge = async (data: any) => {
    let { title } = data;
    let skillId = data.skill.id;

    try {
      let result;
      let submitAction = "add";

      if (isEditing) {
        const updateChallengeDTO = {
          title,
          skillId,
        };

        result = await api.put(
          `challenges/${dataToEdit.id}`,
          updateChallengeDTO
        );
        submitAction = "edit";
      } else {
        const createChallengeRequest = {
          title,
          skillId,
        };
        result = await api.post("challenges", createChallengeRequest);
      }

      feedbackAction("success", result.data, submitAction);
    } catch (err: any) {
      console.error(err);
    }
  };

  const newChallengeFormSchema = zod.object({
    title: zod
      .string()
      .min(1, "Informe um título válido.")
      .max(255, "O título deve conter no máximo 255 caracteres."),
    skill: zod
      .object({
        id: zod.number().positive("Selecione um item"),
        name: zod.string().optional(),
      })
      .optional()
      .refine(
        (skill) => {
          return skill && Object.keys(skill).length > 0;
        },
        { message: t("questions.selectSkill") }
      ),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IValidation>({
    resolver: zodResolver(newChallengeFormSchema),
  });

  return (
    <S.Form onSubmit={handleSubmit(handleCreateNewChallenge)}>
      <Button
        type="button"
        onClick={() => onBackPress()}
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text="Voltar"
      />
      <S.ContainerToResponsiveInput data-cy="titleFieldChallengeForm">
        <Controller
          name="title"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <ResponsiveInputText
              {...field}
              width={800}
              height={57}
              placeholder="Título"
              label="Título"
              errorMessage={errors.title?.message}
              borderColor={errors.title ? "#F5222D" : "#8c8c8c"}
              data-cy="challengeFormInputTitle"
            />
          )}
        />
      </S.ContainerToResponsiveInput>
      <ContainerDropdown data-cy="skillDropdownChallengeForm">
        <Controller
          name="skill"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              label="Habilidade"
              showSearch
              selected={value}
              onSelect={(newValue) => onChange(newValue)}
              placeholder="Habilidade"
              options={skills}
              error={errors.skill?.message}
            />
          )}
        />
      </ContainerDropdown>
      <S.ContainerGap>
        <Button
          type="submit"
          typeOfButton="primary"
          onClick={handleSubmit}
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text={isEditing ? "Salvar" : "Adicionar"}
          data-cy="submitButtonChallengeForm"
        />
      </S.ContainerGap>
    </S.Form>
  );
}
