import {yupResolver} from "@hookform/resolvers/yup";
import {Autocomplete, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {Button as ButtonDS} from "design-system-firedev";
import {t} from "i18next";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {enqueueSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {
  formatPhoneNumber, isPossiblePhoneNumber, isValidPhoneNumber
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import * as Yup from "yup";
import {Button} from "../../../components/Button";
import {ResponsiveInputText} from "../../../components/ResponsiveInputText";
import {Select} from "../../../components/Select";
import {axiosApi} from "../../../services/api";
import CompanyService from "../../../services/CompanyService";
import UserService from "../../../services/UserService";
import {ComapnyFilterDTO} from "../../../types/CompanyDTO";
import {
  formatUserFirstRoleFromDto, formatUserRolesFromDto, formatUserRolesToDto,
  IUserRole, IUsers
} from "../../../types/UserDTO";
import * as S from "./styles";

interface IUsersForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: IUsers | undefined;
}

const labels = {
  user: {
    name: t("users.name"),
    lastName: t("users.surname"),
    phone: t("users.phoneNumber"),
    email: "E-mail",
    userInterests: "Interesses",
    aboutMe: "Sobre mim",
    avatarId: "Foto de perfil",
    birthDate: "Data de Nascimento",
    stateId: t("users.state"),
    cityId: t("users.city"),
  },
};

const renderParams = (field) => {
  return {
    ...field,
    height: 3.375,
    placeholder: labels.user[field.name],
    label: labels.user[field.name],
  };
};

const inputParams = (control, fieldName: string) => {
  return {
    name: fieldName,
    defaultValue: "",
    control: control,
  };
};

export default function UsersForm({
                                    onBackPress,
                                    feedbackAction,
                                    dataToEdit,
                                  }: IUsersForm) {
  const isEditing = typeof dataToEdit !== "undefined";
  const [id, setId] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateSelected, setStateSelected] = useState("");
  const [errorEmail, setErrorEmail] = useState<string>("");
  const [validRoles, setValidRoles] = useState([]);
  const [validCompanies, setValidCompanies] = useState<ComapnyFilterDTO[]>([]);
  const [disableAddress, setDisableAddress] = useState<boolean>(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [loading, setLoading] = useState(false);

  async function getCep(text) {
    try {
      const cep = text.replace("-", "");
      const prevCep = String(getValues("addressZipCode")).replace("-", "");
      if (cep?.length === 8) {
        setLoadingCep(true);
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );
        setLoadingCep(false);
        const state = await states.find(
          (item) => item.fu.toLowerCase() === response.data.state.toLowerCase()
        );
        setValue("stateId", state?.id);
        const city = await cities.find(
          (item) => item.name.toLowerCase() === response.data.city.toLowerCase()
        );
        setValue("cityId", city?.id);
        setDisableAddress(true);
        return true;
      } else if (cep?.length === 7) {
        if (prevCep?.length === 8) {
          setValue("stateId", "");
          setValue("cityId", "");
        }
        return false;
      }
    } catch (err) {
      setDisableAddress(false);
      setLoadingCep(false);
      return false;
    }
  }

  async function getStates() {
    try {
      const statesResponse = await axiosApi.get(`/address/states`);
      setStates(statesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  async function getCities() {
    try {
      const citiesResponse = await axiosApi.get(
        `/address/cities?stateId=${stateSelected}`
      );

      setCities(citiesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getCities();
  }, [stateSelected]);

  function formatarData(data) {
    if (data) {
      const dia = data.slice(0, 2);
      const mes = data.slice(2, 4);
      const ano = data.slice(4, 8);

      return data.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$3-$2-$1');
    }
  }

  const getUserById = async () => {
    const resultGetUserByID = await UserService.getUserById(dataToEdit.id);
    const newCompany = {
      ...resultGetUserByID.company,
      name:
        resultGetUserByID.company.name || resultGetUserByID.company.tradeName,
    };
    const statesResponse = await axiosApi.get(`/address/states`);
    setStates(statesResponse?.data);

    const selectedState = statesResponse?.data?.find(
      (item) =>
        item?.name?.toLowerCase() == resultGetUserByID?.state?.toLowerCase()
    )?.id;

    const citiesResponse = await axiosApi.get(
      `/address/cities?stateId=${stateSelected}`
    );
    setCities(citiesResponse?.data);

    const selectedCity = citiesResponse?.data?.find(
      (item) =>
        item?.name?.toLowerCase() == resultGetUserByID.city?.toLowerCase()
    )?.id;
    setValue("name", resultGetUserByID.name);
    setValue("lastName", resultGetUserByID.lastName);
    setValue("email", resultGetUserByID.email);
    setValue("phone", parsePhoneNumber(resultGetUserByID.phone));
    setValue("birthDate", formatarData(resultGetUserByID.birthDate));
    setValue("office", resultGetUserByID.office);
    setValue("roles", formatUserFirstRoleFromDto(resultGetUserByID.roles));
    setValue("company", newCompany);
    setValue("addressZipCode", resultGetUserByID.addressZipCode);
    (resultGetUserByID.addressZipCode !== null && setDisableAddress(true))
    setValue("cityId", selectedCity);
    setValue("stateId", selectedState);
    setValue("addressDistrict", resultGetUserByID.addressDistrict);
    setValue("addressSecond", resultGetUserByID.addressSecond);
  };

  const fetchValidRoles = async () => {
    const validRoles = await UserService.getValidRoles();
    setValidRoles(formatUserRolesFromDto(validRoles));
  };

  const fetchValidCompanies = async () => {
    const validCompanies = await CompanyService.getMyCompanies();
    setValidCompanies(
      CompanyService.formatCompaniesToSelectList(validCompanies)
    );
  };

  React.useEffect(() => {
    if (dataToEdit?.id) {
      getUserById();
    }
    fetchValidRoles();
    fetchValidCompanies();
  }, [isEditing]);

  function parsePhoneNumber(phone: any) {
    if (phone) {
      const phoneNumber = parsePhoneNumberFromString(phone, "BR");
      return phoneNumber?.number;
    }
    return ""
  }

  const handleCreateOrUpdateUser = async (data: IUsers) => {
    let birthDate: string | undefined = data.birthDate;
    if (birthDate) {
      const [year, month, day] = birthDate.split("-");
      birthDate = `${day}/${month}/${year}`;
    }

    let rolesList = [];
    rolesList.push(data.roles as IUserRole);

    let userDTO: IUsers = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      birthDate: birthDate,
      office: data.office,
      aboutMe: "",
      roles: formatUserRolesToDto(rolesList),
      company: data.company,
      cityId: data.cityId,
      addressZipCode: data.addressZipCode,
      stateId: data.stateId,
      addressDistrict: data.addressDistrict,
      addressSecond: data.addressSecond,
    };
    let result;

    try {
      setLoading(true);
      if (isEditing) {
        const userPayload: any = {...userDTO};
        userPayload.phone = formatPhoneNumber(userPayload.phone);
        if (
          userPayload.company != null &&
          typeof userPayload.company !== "undefined"
        ) {
          delete userPayload.company;
          userPayload.companyId = data.company.id;
        }

        result = await UserService.updateUser(userPayload, `${dataToEdit?.id}`);
        feedbackAction("success", result, "edit");
        enqueueSnackbar(t("users.userSuccessfullyEdited"), {
          variant: "success",
        });
      } else {
        const userPayload: any = {...userDTO};
        userPayload.phone = formatPhoneNumber(userPayload.phone);
        delete userPayload.company;
        userPayload.companyId = data.company?.id;

        result = await UserService.createNewUser(userPayload);
        feedbackAction("success", result, "add");
        enqueueSnackbar(t("users.userSuccessfullyRegistered"), {
          variant: "success",
        });
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.error(err);
    }
  };

  const newUserFormSchema = Yup.object({
    name: Yup.string()
    .min(1, t("users.validName"))
    .max(255, t("users.nameMaxCharacters"))
    .optional(),
    lastName: Yup.string()
    .min(1, t("users.validSurname"))
    .max(255, t("users.lastNameMaxCharacters"))
    .optional(),
    stateId: Yup.string().min(1, t("signUp.selectState")),
    cityId: Yup.string().min(1, t("signUp.selectCity")),
    addressZipCode: Yup.string().min(8, t("users.validZipCode")),
    addressDistrict: Yup.string()
    .min(1, t("users.validBorough"))
    .required(t("users.validBorough")),
    addressSecond: Yup.string().optional().nullable(),
    email: Yup.string()
    .email(t("users.validEmail"))
    .required(t("users.validEmail"))
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("users.validEmail"))
    .max(255, t("users.emailMaxCharacters")),
    phone: Yup.string()
    .max(28, t("users.phoneMaxCharacters"))
    .min(13, t("users.validPhoneNumber"))
    .test("is-valid-phone", t("users.validPhoneNumber"), (value) => {
      return isPossiblePhoneNumber(value) && isValidPhoneNumber(value);
    })
    .max(15, t("users.validPhoneNumber")),
    birthDate: Yup.string()
    .optional()
    .test("signUp.is-valid", t("signUp.validDateOfBirth"), (value) => {
      if (!value) {
        return true;
      }
      const date = new Date(value);
      return !isNaN(date.getTime());
    })
    .test("signUp.is-past", t("signUp.validDateOfBirth"), (value) => {
      if (!value) {
        return true;
      }
      const [year, month, day] = value.split("-");
      let birthDate = `${year}-${month}-${day}T00:00:00`;
      const date = new Date(birthDate);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return currentDate > date;
    }),
    // office: Yup.string()
    //   .min(1, t("users.mandatoryPosition"))
    //   .max(255, t("users.descriptionMaxCharacters")),
    roles: Yup.object({
      id: Yup.string().required(t("users.mandatoryPosition")),
      name: Yup.string().required(t("users.mandatoryPosition")),
    }).test("is-valid", t("users.mandatoryPosition"), (value) => {
      return value?.id != null;
    }),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<IUsers>({
    resolver: yupResolver(newUserFormSchema),
  });

  return (
    <S.Form onSubmit={handleSubmit(handleCreateOrUpdateUser)}>
      <ButtonDS
        type="button"
        onClick={() => onBackPress()}
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text={t("users.back")}
      />
      {!isEditing && (
        <div>
          <Grid container>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={12} md={5}>
                <S.ContainerInput>
                  <S.Label>{t("users.name")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="name"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        errorMessage={errors.name?.message}
                        borderColor={errors.name ? "#F5222D" : "#8C8C8C"}
                        title="Nome"
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
              <Grid item xs={12} md={5}>
                <S.ContainerInput>
                  <S.Label>{t("users.surname")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="lastName"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        errorMessage={errors.lastName?.message}
                        borderColor={errors.lastName ? "#F5222D" : "#8C8C8C"}
                        title="Sobrenome"
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
              <Grid item style={{width: "180px"}}>
                <S.ContainerInput>
                  <S.Label>{t("users.dateOfBirth")}</S.Label>
                  <Controller
                    name="birthDate"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        errorMessage={errors.birthDate?.message}
                        borderColor={errors.birthDate ? "#F5222D" : "#8C8C8C"}
                        title="dd-mm-aaaa"
                        type="date"
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
            </Grid>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={12} md={7}>
                <S.ContainerInput>
                  <S.Label>{t("users.email")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        borderColor={errors.email ? "#F5222D" : "#8C8C8C"}
                        title="exemplo@exemplo.com"
                      />
                    )}
                  />
                  <S.ErrorMessage>
                    {errorEmail ? errorEmail : errors.email?.message}
                  </S.ErrorMessage>
                </S.ContainerInput>
              </Grid>
              <Grid item xs={12} md={5}>
                <S.ContainerInput>
                  <S.Label style={{marginBottom: "0.3rem"}}>{t("users.phoneNumber")} <span
                    style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="phone"
                    defaultValue=""
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <PhoneInput
                        value={value}
                        country="BR"
                        defaultCountry="BR"
                        onChange={(val) => onChange(val || "")}
                        style={{
                          width: "100%",
                          height: "3.5rem",
                          border:
                            errors?.phone?.message?.length > 0
                              ? "1px solid #F5222D"
                              : "1px solid #8c8c8c",
                          borderRadius: "5px",
                          fontSize: "16px",
                          padding: "0 1rem",
                          fontWeight: "500",
                          outline: "none",
                        }}
                      />
                    )}
                  />
                  <S.ErrorMessage>{errors?.phone?.message}</S.ErrorMessage>
                </S.ContainerInput>
              </Grid>
            </Grid>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={12} md={6} className="form-field">
                <S.ContainerInput>
                  <S.Label>{t("users.company")}</S.Label>
                  <Controller
                    name="company"
                    control={control}
                    defaultValue={0}
                    render={({field: {onChange}}) => (
                      <Select
                        options={validCompanies}
                        placeholder={t("users.company")}
                        onSelect={onChange}
                        sortAlphabetical={false}
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
              <Grid item xs={12} md={6} className="form-field">
                <S.ContainerInput>
                  <S.Label>{t("users.positions")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="roles"
                    control={control}
                    render={({field: {onChange}}) => (
                      <Select
                        options={validRoles}
                        placeholder={t("users.positions")}
                        onSelect={onChange}
                        sortAlphabetical={false}
                        error={errors?.roles?.message}
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
            </Grid>

            <Grid container spacing={2} wrap="nowrap">
              <S.InputRowRegister rowsSize={[1]}>
                <S.ContainerInput>
                  <S.Label>{t("users.address")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    {...inputParams(control, "addressDistrict")}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...renderParams(field)}
                        errorMessage={errors.addressDistrict?.message}
                        title={t("users.borough")}
                        borderColor={
                          errors.addressDistrict ? "#F5222D" : "#8C8C8C"
                        }
                      />
                    )}
                  />
                </S.ContainerInput>
              </S.InputRowRegister>
            </Grid>

            <Grid container spacing={2} wrap="nowrap">
              <S.InputRowRegister rowsSize={[1]}>
                <S.ContainerInput>
                  <S.Label>{t("users.addressSecond")}</S.Label>
                  <Controller
                    {...inputParams(control, "addressSecond")}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...renderParams(field)}
                        errorMessage={errors.addressSecond?.message}
                        title={t("users.borough")}
                        borderColor={
                          errors.addressSecond ? "#F5222D" : "#8C8C8C"
                        }
                      />
                    )}
                  />
                </S.ContainerInput>
              </S.InputRowRegister>
            </Grid>

            <Grid container spacing={2} wrap="nowrap">
              <S.InputRowRegister rowsSize={[4, 4, 4]}>
                <S.ContainerInput>
                  <S.Label>{t("users.zipCode")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    {...inputParams(control, "addressZipCode")}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...renderParams(field)}
                        disabled={loadingCep}
                        autoComplete={false}
                        onChange={(e) => {
                          getCep(e.target.value);
                          field.onChange(e.target.value);
                        }}
                        value={field.value}
                        maxLength={field?.value?.includes("-") ? 9 : 8}
                        errorMessage={errors.addressZipCode?.message}
                        title={t("users.zipCode")}
                        borderColor={
                          errors.addressZipCode ? "#F5222D" : "#8C8C8C"
                        }
                      />
                    )}
                  />
                </S.ContainerInput>

                <S.ContainerInput>
                  <Controller
                    {...inputParams(control, "cityId")}
                    render={({field}) => {
                      const selectedCity =
                        cities.find((option) => option.id === field.value) ||
                        null;

                      const handleCityChange = (event, value) => {
                        field.onChange(value ? value.id : "");
                      };

                      const filterCityOptions = (options, {inputValue}) => {
                        const filteredOptions = options.filter((option) =>
                          option.name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                        );

                        return filteredOptions;
                      };

                      return (
                        <div>
                          <S.Label style={{marginBottom: "0.3rem"}}>{t("users.city")} <span
                            style={{color: 'red'}}> *</span></S.Label>
                          <Autocomplete
                            id="cities"
                            options={cities}
                            getOptionLabel={(option) => option.name}
                            value={selectedCity}
                            onChange={handleCityChange}
                            filterOptions={filterCityOptions}
                            noOptionsText={t("noOptionsMui")}
                            disabled={disableAddress || !stateSelected}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  borderRadius: 0,
                                  width: "100%",
                                  height: "100%",
                                  fontSize: 16,
                                  fontWeight: 500,
                                  outline: "none",
                                  verticalAlign: "middle",
                                  color: "#131212",
                                  background: "#ffffff",
                                }}
                                placeholder={t("users.city")}
                              />
                            )}
                          />
                        </div>
                      );
                    }}
                  />
                  <S.ErrorMessage style={{paddingTop: "0.2rem"}}>{errors?.cityId?.message}</S.ErrorMessage>
                </S.ContainerInput>
                <S.ContainerInput>
                  <Controller
                    {...inputParams(control, "stateId")}
                    render={({field}) => {
                      const selectedState =
                        states.find((option) => option.id === field.value) ||
                        null;

                      const handleStateChange = (event, value) => {
                        field.onChange(value ? value.id : "");
                        setStateSelected(value ? value.id : "");
                      };

                      const filterStateOptions = (options, {inputValue}) => {
                        const filteredOptions = options.filter(
                          (option) =>
                            option.fu
                            .toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                            option.name
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        );

                        return filteredOptions;
                      };

                      return (
                        <div>
                          <S.Label style={{marginBottom: "0.3rem"}}>{t("users.state")} <span
                            style={{color: 'red'}}> *</span></S.Label>
                          <Autocomplete
                            id="states"
                            options={states}
                            getOptionLabel={(option) => option.fu?.toUpperCase()}
                            value={selectedState}
                            onChange={handleStateChange}
                            filterOptions={filterStateOptions}
                            autoComplete={false}
                            noOptionsText={t("noOptionsMui")}
                            disabled={disableAddress}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  borderRadius: 0,
                                  width: "100%",
                                  height: "100%",
                                  fontSize: 16,
                                  fontWeight: 500,
                                  outline: "none",
                                  verticalAlign: "middle",
                                  color: "#131212",
                                  background: "#ffffff",
                                }}
                                placeholder={t("users.state")}
                                autoComplete="off"
                              />
                            )}
                          />
                        </div>
                      );
                    }}
                  />
                  <S.ErrorMessage style={{paddingTop: "0.2rem"}}>{errors?.stateId?.message}</S.ErrorMessage>
                </S.ContainerInput>
              </S.InputRowRegister>
            </Grid>
          </Grid>
        </div>
      )}
      {isEditing && (
        <div>
          <Grid container>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={12} md={5}>
                <S.ContainerInput>
                  <S.Label>{t("users.name")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="name"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        disabled
                        errorMessage={errors.name?.message}
                        borderColor={errors.name ? "#F5222D" : "#8C8C8C"}
                        title={t("users.name")}
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
              <Grid item xs={12} md={5}>
                <S.ContainerInput>
                  <S.Label>{t("users.surname")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="lastName"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        disabled
                        errorMessage={errors.lastName?.message}
                        borderColor={errors.lastName ? "#F5222D" : "#8C8C8C"}
                        title={t("users.surname")}
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
              <Grid item style={{width: "165px"}}>
                <S.ContainerInput>
                  <S.Label>{t("users.dateOfBirth")}</S.Label>
                  <Controller
                    name="birthDate"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        disabled
                        errorMessage={errors.birthDate?.message}
                        borderColor={errors.birthDate ? "#F5222D" : "#8C8C8C"}
                        title="dd-mm-aaaa"
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
            </Grid>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={12} md={7}>
                <S.ContainerInput>
                  <S.Label>{t("users.email")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...field}
                        borderColor={errors.email ? "#F5222D" : "#8C8C8C"}
                        title="seu@email.com"
                      />
                    )}
                  />
                  <S.ErrorMessage>
                    {errorEmail ? errorEmail : errors.email?.message}
                  </S.ErrorMessage>
                </S.ContainerInput>
              </Grid>
              <Grid item xs={12} md={5}>
                <S.Label style={{marginBottom: "0.3rem"}}>{t("users.phoneNumber")} <span
                  style={{color: 'red'}}> *</span></S.Label>
                <S.ContainerInput>
                  <Controller
                    name="phone"
                    defaultValue=""
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <PhoneInput
                        value={value}
                        country="BR"
                        defaultCountry="BR"
                        onChange={(val) => onChange(val || "")}
                        style={{
                          width: "100%",
                          height: "3.5rem",
                          border:
                            errors?.phone?.message?.length > 0
                              ? "1px solid #F5222D"
                              : "1px solid #8c8c8c",
                          borderRadius: "5px",
                          fontSize: "16px",
                          padding: "0 1rem",
                          fontWeight: "500",
                          outline: "none",
                        }}
                      />
                    )}
                  />
                  <S.ErrorMessage>{errors?.phone?.message}</S.ErrorMessage>
                </S.ContainerInput>
              </Grid>
            </Grid>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item xs={12} md={6} className="form-field">
                <S.ContainerInput>
                  <S.Label>{t("users.company")}</S.Label>
                  <Controller
                    name="company"
                    control={control}
                    defaultValue={0}
                    render={({field: {onChange, value}}) => (
                      <Select
                        options={validCompanies}
                        placeholder={t("users.company")}
                        onSelect={onChange}
                        sortAlphabetical={false}
                        selected={value}
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
              <Grid item xs={12} md={6} className="form-field">
                <S.ContainerInput>
                  <S.Label>{t("users.positions")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="roles"
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Select
                        options={validRoles}
                        placeholder={t("users.positions")}
                        onSelect={onChange}
                        selected={value}
                        sortAlphabetical={false}
                      />
                    )}
                  />
                </S.ContainerInput>
              </Grid>
            </Grid>

            <S.InputRow rowsSize={[1]}>
              <S.ContainerGapDistrict>
                <S.Label>{t("users.address")} <span style={{color: 'red'}}> *</span></S.Label>
                <Controller
                  {...inputParams(control, "addressDistrict")}
                  render={({field}) => (
                    <ResponsiveInputText
                      {...renderParams(field)}
                    />
                  )}
                />
              </S.ContainerGapDistrict>
            </S.InputRow>
            <S.InputRow rowsSize={[1]}>
              <S.ContainerGapDistrict>
                <S.Label>{t("users.addressSecond")}</S.Label>
                <Controller
                  {...inputParams(control, "addressSecond")}
                  render={({field}) => (
                    <ResponsiveInputText
                      {...renderParams(field)}
                    />
                  )}
                />
              </S.ContainerGapDistrict>
            </S.InputRow>
            <S.InputRow rowsSize={[4, 4, 4]}>
              <S.ContainerGap>
                <S.Label>{t("users.zipCode")} <span style={{color: 'red'}}> *</span></S.Label>
                <Controller
                  {...inputParams(control, "addressZipCode")}
                  render={({field}) => (
                    <ResponsiveInputText
                      {...renderParams(field)}
                      disabled={loadingCep}
                      autoComplete={false}
                      onChange={(e) => {
                        getCep(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      value={field.value}
                      maxLength={field?.value?.includes("-") ? 9 : 8}
                    />
                  )}
                />
              </S.ContainerGap>

              <S.ContainerGap>
                <Controller
                  {...inputParams(control, "cityId")}
                  render={({field}) => {
                    const selectedCity =
                      cities.find((option) => option.id === field.value) ||
                      null;

                    const handleCityChange = (event, value) => {
                      field.onChange(value ? value.id : "");
                    };

                    const filterCityOptions = (options, {inputValue}) => {
                      const filteredOptions = options.filter((option) =>
                        option.name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                      );

                      return filteredOptions;
                    };

                    return (
                      <div>
                        <S.Label style={{marginBottom: "0.3rem"}}>{t("users.city")} <span
                          style={{color: 'red'}}> *</span></S.Label>
                        <Autocomplete
                          id="cities"
                          options={cities}
                          getOptionLabel={(option) => option.name}
                          value={selectedCity}
                          noOptionsText={t("noOptionsMui")}
                          onChange={handleCityChange}
                          filterOptions={filterCityOptions}
                          disabled={disableAddress || !stateSelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: 0,
                                width: "100%",
                                height: "100%",
                                fontSize: 16,
                                fontWeight: 500,
                                outline: "none",
                                verticalAlign: "middle",
                                color: "#131212",
                                background: "#ffffff",
                              }}
                              placeholder={t("users.city")}
                            />
                          )}
                        />
                      </div>
                    );
                  }}
                />
                <S.ErrorMessage style={{paddingTop: "0.2rem"}}>{errors?.cityId?.message}</S.ErrorMessage>
              </S.ContainerGap>

              <S.ContainerGap>
                <Controller
                  {...inputParams(control, "stateId")}
                  render={({field}) => {
                    const selectedState =
                      states.find((option) => option.id === field.value) ||
                      null;

                    const handleStateChange = (event, value) => {
                      field.onChange(value ? value.id : "");
                      setStateSelected(value ? value.id : "");
                    };

                    const filterStateOptions = (options, {inputValue}) => {
                      const filteredOptions = options.filter(
                        (option) =>
                          option.fu
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                          option.name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      );

                      return filteredOptions;
                    };

                    return (
                      <div>
                        <S.Label style={{marginBottom: "0.3rem"}}>{t("users.state")} <span
                          style={{color: 'red'}}> *</span></S.Label>
                        <Autocomplete
                          id="states"
                          options={states}
                          getOptionLabel={(option) => option.fu?.toUpperCase()}
                          value={selectedState}
                          noOptionsText={t("noOptionsMui")}
                          onChange={handleStateChange}
                          filterOptions={filterStateOptions}
                          autoComplete={false}
                          disabled={disableAddress}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: 0,
                                width: "100%",
                                height: "100%",
                                fontSize: 16,
                                fontWeight: 500,
                                outline: "none",
                                verticalAlign: "middle",
                                color: "#131212",
                                background: "#ffffff",
                              }}
                              placeholder="Estado"
                              autoComplete="off"
                            />
                          )}
                        />
                      </div>
                    );
                  }}
                />
                <S.ErrorMessage style={{paddingTop: "0.2rem"}}>{errors?.stateId?.message}</S.ErrorMessage>
              </S.ContainerGap>
            </S.InputRow>
          </Grid>
        </div>
      )}
      <S.ContainerButtonSubmit>
        <Button
          type={"submit"}
          loading={loading}
        >{isEditing ? (
          t("users.save")
        ) : (
          t("users.add")
        )}</Button>
      </S.ContainerButtonSubmit>
    </S.Form>
  );
}
