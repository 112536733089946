import styled from "styled-components";
import InputMask from "react-input-mask";

interface IInputResponsiveProps {
  width?: number;
  height?: number;
  backgroundColor?: string;
  borderColor?: string;
  errorMessageColor?: string;
  customContainerHeight?: string;
  disabled?: boolean;
}

export const ContainerResponsiveInputText = styled.div<IInputResponsiveProps>`
  width: 100%;
  height: ${({customContainerHeight}) =>
    customContainerHeight ? customContainerHeight : "3.5rem"};
  background: ${({backgroundColor, disabled}) =>
    disabled ? "transparent" : backgroundColor ? backgroundColor : "white"};
  border: ${({borderColor}) =>
    borderColor ? `1px solid ${borderColor}` : "1px solid #8c8c8c"};
  border-radius: 5px;
`;

export const InputResponsiveText = styled(InputMask)`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  vertical-align: middle;
  color: #131212;

  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const ErrorMessage = styled.p<IInputResponsiveProps>`
  margin: 0.3rem 0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: ${({errorMessageColor}) =>
    errorMessageColor ? `${errorMessageColor}` : "#F5222D"};
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;
