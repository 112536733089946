import styled from "styled-components";
import {Fonts} from "design-system-firedev";
import {colors} from "../../components/utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 4%;
`;

export const Label = styled.p`
  margin: 0.3rem 0;
  font-size: 14px;
  color: #8c8c8c;
`;
export const ContainerContent = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const ContainerTrailsMain = styled.div<any>`
  margin-top: 20px;
  width: ${({isFullPage}) =>
          isFullPage ? "calc(100% - 320px - 16px)" : "100%"};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

export const ContainerLoadingTable = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const ContainerTitle = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1316px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: #131212;
  //margin-top: 20px;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const ContainerFulMainData = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
`;
export const ContainerFullNoResults = styled.div<any>`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;
export const ContainerCards = styled.div<any>`
  width: 100%;
  border: 1px solid ${colors.gray100};
  background-color: #fff;
  border-radius: 3px 3px 3px 3px;
  position: relative;
  max-width: 650px;
  margin-bottom: 10px;
`;

// export const ContainerInfoCard = styled.div<any>`
//   width: 100%;
//   border: 1px solid ${colors.gray100};
//   overflow-y: scroll;
//   background-color: #f0f;
//
//   //border-radius: 3px 3px;
//   position: sticky;
//   top: 110px;
//   height: calc(100vh - 90px - 20px - 68px - 94px);
//   max-width: 650px;
// `;

export const ViewMore = styled.div`
  font-weight: bold;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 20px;
  color: #ff026f;
  display: flex;
  height: min-content;
`;

export const EmptyTrails = styled.p`
  text-align: center;
  padding: 32px 8px;
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 18px;
  color: #131212;
`;

export const ContainerSliderHorizontal = styled.div`
display: flex;
 width: 100%;
 overflow-X: auto;
 WebkitOverflowScrolling: touch;

 &::-webkit-scrollbar-thumb {
  background-color: transparent; 
}

&::-webkit-scrollbar-track {
  background-color: transparent;
}
`;
