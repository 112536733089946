export interface IUsers {
  ID?: number;
  id?: string | number;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  cityId: any;
  addressZipCode: string;
  stateId: any;
  addressDistrict: string;
  addressSecond: string;
  office: string;
  birthDate: string;
  aboutMe: string;
  confirmPassword?: string;
  roles?: any | IUserRole;
  validRoles?: IUserRole[];
  company: any;
}

export interface IUserRole {
  name: string;
  id: string | number;
}

export function formatUserRolesToDto(userRoles: IUserRole[]): string[] {
  const userRolesDto = [];

  if (userRoles?.length > 0) {
    userRoles.map((role) => {
      userRolesDto.push(role?.id);
    });
  }

  return userRolesDto;
}

export function formatUserRolesFromDto(userRolesDto: any[]): IUserRole[] {
  const userRoles = [];
  userRolesDto?.forEach((role) => {
    let roleName: any = role.name?.split("_")[1];
    const roleNameFistChar = roleName.charAt(0);
    roleName = roleNameFistChar + roleName.slice(1).toLowerCase();
    userRoles.push({ name: roleName, id: role?.name });
  });

  return userRoles;
}

export function formatUserFirstRoleFromDto(userRolesDto: any[]): IUserRole[] {
  const userRoles = [];
  userRolesDto?.forEach((role) => {
    let roleName: any = role.name?.split("_")[1];
    const roleNameFistChar = roleName.charAt(0);
    roleName = roleNameFistChar + roleName.slice(1).toLowerCase();
    userRoles.push({ name: roleName, id: role?.name });
  });
  return userRoles[0];
}
