import styled, { css } from "styled-components";

export const Container = styled.main`
  padding: 41px 80px 41px 80px;
  height: 100%;
  width: 100vw;

  @media (max-width: 834px) {
    padding: 43px 80px 40px 80px;
  }

  @media (max-width: 428px) {
    padding: 41px 44px 40px 44px;
  }

  @media (max-width: 375px) {
    padding: 41px 14px 40px 14px;
  }

  h1,
  h3,
  h4,
  p {
    font-family: "Ubuntu";
    font-style: normal;
  }
`;

export const BodyOfSkill = styled.div`
  min-height: auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 1rem;
`;

export const TitleOfSkill = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: 2.25rem;
  color: #131212;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AverageTimeText = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #696969;
`;

export const SubtitleofSkill = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: 1.125rem;
  color: #696969;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
`;

export const DescriptionOfSkill = styled.p.attrs(
  (props: { open: boolean }) => props
)`
  width: 100%;
  max-width: 260ch;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 25px;
  color: #696969;
  overflow-wrap: break-word;

  ${(props) =>
    !props.open &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    `}
`;

export const SeeMoreButtonDiv = styled.div`
  display: flex;
  justify-content: center;

  button {
    text-decoration: underline #ff026f;
  }
`;

export const BoxLists = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 38px;
  gap: 1rem;
  max-height: 280px;
  max-width: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid #c4c4c4;
  }

  @media (max-width: 834px) {
    gap: 0.5rem;
  }

  @media (max-width: 425px) {
    max-height: 400px;
  }
`;

export const ContainerCard = styled.a`
  min-width: 350px;
  max-width: 350px;
  max-height: 101px;
  min-height: 101px;
  padding: 17px 43px 14px 12px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  background: #ffffff;

  display: grid;
  grid-template-areas:
    "image title"
    "image subtitle";
  grid-template-columns: 25% 70%;

  @media (max-width: 834px) {
    min-width: 329px;
    max-width: 329px;
  }

  @media (max-width: 320px) {
    min-width: 280px;
    max-width: 280px;
    grid-template-columns: 35% 65%;
  }
`;

export const ImageList = styled.img`
  grid-area: "image";
  min-width: 64px;
  max-width: 64px;
  min-height: 71px;
  max-height: 71px;
  object-fit: cover;
`;

export const NameOfArticle = styled.p`
  grid-area: title;

  max-width: 239px;
  min-width: 239px;
  max-height: 37px;
  min-height: 37px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #131212;

  @media (max-width: 834px) {
    min-width: 215px;
    max-width: 215px;
  }

  @media (max-width: 320px) {
    min-width: 165px;
    max-width: 165px;
  }
`;
export const SubtitleOfArticle = styled.p`
  grid-area: subtitle;
  position: relative;
  bottom: 25px;

  max-width: 239px;
  min-width: 239px;
  max-height: 21px;
  min-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #696969;

  @media (max-width: 834px) {
    min-width: 210px;
    max-width: 210px;
  }

  @media (max-width: 320px) {
    min-width: 160px;
    max-width: 160px;
  }
`;

export const FooterArticles = styled.footer`
  margin-top: 18px;

  button {
    position: fixed;
    bottom: 10px;

    @media (max-width: 600px) {
      bottom: 90px;
    }
  }
`;

export const TitleOfFooterArticles = styled.p`
  font-weight: 700;
  font-size: 1.125rem;
  color: #131212;
  margin-bottom: 21px;
`;

export const MotivationsMessage = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #696969;
`;

export const ImageDownload = styled.img``;

export const CardLink = styled.div`
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 17px;
  color: #8c8c8c;
`;
