import styled from "styled-components";

interface IPropsContainerButtonsRightOrWrong {
  rightAnswer?: boolean;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
  background: transparent;
  padding-left: 15vw;
  padding-right: 15vw;

  @media (max-width: 1024px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
`;

export const Title = styled.h1`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  margin: 0;
  margin-top: 59px;
  margin-bottom: 23px;

  color: #131212;
`;

export const Subtitle = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 47px;

  color: #696969;
`;
export const SubtitleResultOfChallenge = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 47px;

  position: relative;
  top: 40px;

  color: #696969;
`;

export const ContainerEvaluationChallenge = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;

  border: 1px solid #c4c4c4;
  border-radius: 3px;
  background: #ffffff;
  padding: 43px 46px 44px 55px;

  .buttonSubmitted {
    float: right;
  }
`;

export const ContainerButtonsRightOrWrong = styled.div<IPropsContainerButtonsRightOrWrong>`
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 14px;
  margin-bottom: 1rem;

  button:disabled {
    opacity: 1 !important;
  }

  button:nth-of-type(1) {
    border: 2px solid;
    div svg {
      filter: ${({ rightAnswer }) =>
        rightAnswer === true
          ? "invert(35%) sepia(100%) saturate(1435%) hue-rotate(139deg) brightness(95%) contrast(101%)"
          : "none"};
    }
  }

  button:nth-of-type(2) {
    border: 2px solid;
    div svg {
      filter: ${({ rightAnswer }) =>
        rightAnswer === false
          ? "invert(24%) sepia(59%) saturate(6807%) hue-rotate(347deg) brightness(98%) contrast(96%)"
          : "none"};
    }
  }
`;

export const ContainerQuestion = styled.div`
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NumberOfQuestion = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;

  color: #131212;
`;

export const BodyOfQuestion = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  color: #131212;
  overflow-wrap: break-word;

  p:has(img) {
    width: 100%;

    img {
      max-width: 100%;
      max-height: 400px;
      margin: 16px 0;
      display: block;
    }
  }
`;

export const ContainerAnsweOfUserTypeFile = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #8c8c8c;
  }
`;

export const TitleOfFile = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  overflow-wrap: break-word;

  color: #131212;
  flex: 1;
`;

export const ContainerOfAnswerUser = styled.div`
  width: 100%;
  min-height: 64px;
  margin: 10px 0 10px 0;
`;

export const AnswerOfUserTypeText = styled.p`
  max-width: 100%;
  margin: 0;
  overflow-wrap: break-word;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: #696969;
`;

export const Form = styled.form``;

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 25px;
  background: #ffe8e6;
  border: 1px solid #8c8c8c;
  border-radius: 3px;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: 1px solid #ff026f;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #8c8c8c;
  }
`;

export const CountOfTextIntoTextArea = styled.p`
  float: right;
  margin: 0;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 32px;
  margin-bottom: 10px;

  color: #8c8c8c;
`;

export const ContainerButtonsPrevandNext = styled.div`
  margin-top: 30px;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 24px;
`;

export const ContainerSubmittedEvaluation = styled.div`
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageApproved = styled.img`
  max-width: 86px;
  min-width: 86px;
  max-height: 86px;
  min-height: 86px;
`;

export const SubtitleSubmitted = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #131212;
`;

export const ContainerButtonSubmitted = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: end;

  margin-top: 97px;
`;

export const ContainerResultOfChallenge = styled.div`
  width: 100%;
  margin-top: -50px;
`;

export const AnchorButton = styled.a`
  text-decoration: none;
`;

export const ContainerTextAreaEvaluated = styled.div``;

export const Comment = styled.p`
  border-top: 1px solid #696969;
  padding-top: 21px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #696969;
`;

export const UserEvaluted = styled.p`
  display: flex;
  align-items: center;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;

  &::before {
    content: url(data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9.00008%200.55957C4.40008%200.55957%200.666748%204.2929%200.666748%208.8929C0.666748%2013.4929%204.40008%2017.2262%209.00008%2017.2262C13.6001%2017.2262%2017.3334%2013.4929%2017.3334%208.8929C17.3334%204.2929%2013.6001%200.55957%209.00008%200.55957ZM4.89175%2014.1262C5.25008%2013.3762%207.43341%2012.6429%209.00008%2012.6429C10.5667%2012.6429%2012.7584%2013.3762%2013.1084%2014.1262C11.9751%2015.0262%2010.5501%2015.5596%209.00008%2015.5596C7.45008%2015.5596%206.02508%2015.0262%204.89175%2014.1262ZM14.3001%2012.9179C13.1084%2011.4679%2010.2167%2010.9762%209.00008%2010.9762C7.78341%2010.9762%204.89175%2011.4679%203.70008%2012.9179C2.85008%2011.8012%202.33341%2010.4096%202.33341%208.8929C2.33341%205.2179%205.32508%202.22624%209.00008%202.22624C12.6751%202.22624%2015.6667%205.2179%2015.6667%208.8929C15.6667%2010.4096%2015.1501%2011.8012%2014.3001%2012.9179V12.9179ZM9.00008%203.8929C7.38341%203.8929%206.08341%205.1929%206.08341%206.80957C6.08341%208.42624%207.38341%209.72624%209.00008%209.72624C10.6167%209.72624%2011.9167%208.42624%2011.9167%206.80957C11.9167%205.1929%2010.6167%203.8929%209.00008%203.8929ZM9.00008%208.05957C8.30841%208.05957%207.75008%207.50124%207.75008%206.80957C7.75008%206.1179%208.30841%205.55957%209.00008%205.55957C9.69175%205.55957%2010.2501%206.1179%2010.2501%206.80957C10.2501%207.50124%209.69175%208.05957%209.00008%208.05957Z%22%20fill%3D%22%238C8C8C%22%2F%3E%0A%3C%2Fsvg%3E%0A);
    padding-right: 5px;
    margin-top: 5px;
  }
`;

export const FeedbackEvaluated = styled.p`
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  height: auto;
  word-wrap: break-word;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;
