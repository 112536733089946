import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Button } from "../../../components/Button";
import userService from "../../../services/UserService";
import * as Yup from "yup";
import * as S from "../style";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
interface IFormData {
  onSubmit: (code: string) => void;
  onClose: () => void;
  openModal: boolean;
}
const schema = Yup.object().shape({
  code: Yup.string().min(6, t("backend.invalidCode")),
});

const Form = ({ onSubmit, onClose, openModal }: IFormData) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.FullModalCodePassword>
        <S.formForgetPassword onSubmit={handleSubmit((e:any) => onSubmit(e.code))}>
          <S.ModalTitle>{t("codeMessageModal.forgetPassword")}</S.ModalTitle>
          <S.ModalSubtitleCodeSecurity data-cy="loginScreenModalEmailForget">
            {t("codeMessageModal.checkEmailCode")}
          </S.ModalSubtitleCodeSecurity>
          <S.LabelCode>{t("codeMessageModal.code")}</S.LabelCode>
          <Controller
            control={control}
            name="code"
            render={({ field }) => <S.inputCode {...field} />}
          />
          {errors?.code?.message ? (
            <S.ErrorMessage variant="body1">
              {errors.code.message}
            </S.ErrorMessage>
          ) : null}
          <S.ContainerButtons>
            <S.buttonPink type="submit">
              {t("codeMessageModal.confirm")}
            </S.buttonPink>
            <S.buttonWhite onClick={handleClose}>
              {t("codeMessageModal.cancel")}
            </S.buttonWhite>
          </S.ContainerButtons>
        </S.formForgetPassword>
      </S.FullModalCodePassword>
    </Modal>
  );
};

export default Form;
