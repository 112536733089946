/* eslint-disable react/no-unstable-nested-components */
import {zodResolver} from "@hookform/resolvers/zod";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {t} from "i18next";
import {enqueueSnackbar} from "notistack";
import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as zod from "zod";
import TreeWhiteIcon from "../../../assets/TreeWhiteIcon.svg";
import SetaGray from "../../../assets/setaGray.svg";
import BackButton from "../../../components/BackButton";
import {Button} from "../../../components/Button";
import CardTree from "../../../components/CardTree";
import CheckboxMultiselect from "../../../components/Checkboxes";
import InputMoney from "../../../components/InputMoney";

import {X} from "phosphor-react";
import ClearConfirmationModal from "../../../components/ConfirmationModal";
import {Select} from "../../../components/Select";
import {options} from "../../../components/utils/languageOptions";
import AddressService from "../../../services/AddressService";
import FormationService from "../../../services/FormationService";
import SkillTreeService from "../../../services/SkillTreeService";
import {axiosApi} from "../../../services/api";
import ConfirmationModal from "../confirmModal";
import SkillTreeNameModal from "../skillTreeNameModal";
import SkillsModal from "../skillsModal";
import * as S from "./styles";

import {InfoOutlined} from "@mui/icons-material";
import CompanyService from "../../../services/CompanyService";

interface IPlansForm {
  onClickBack: () => any;
  action?: {
    data?: any;
    type?: "add" | "edit";
  };
  feedbackAction: CallableFunction;
}

function PlansForm({ onClickBack, action, feedbackAction }: IPlansForm) {
  const [showSkillsModal, setShowSkillsModal] = useState<boolean>(false);
  const [showSkillTreeNameModal, setShowSkillTreeNameModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [modalData, setModalData] = useState();
  const [search, setSearch] = useState<string>("");
  const [selectedSkillTree, setSelectedSkillTree] = useState<any>({});
  const [subtractedValue, setSubtractedValue] = useState(-2);
  const [allCompetencesOptions, setAllCompetencesOptions] = useState([]);
  const [loadingCompetences, setLoadingCompetences] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [isContract, setIsContract] = useState(false);
  const [searchCompetences, setSearchCompetences] = useState("");
  const [openSkillTrees, setOpenSkillTrees] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(null);
  const [allTrees, setAllTrees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formationPayloadToSave, setFormationPayloadToSave] = useState(null);
  const [selectedSkillsWithoutChallenges, setSelectedSkillsWithoutChallenges] =
    useState([]);
  const [isGeneratingChallenges, setIsGeneratingChallenges] = useState(false);
  const [AIChallengeGenerationCompletion, setAIChallengeGenerationCompletion] =
    useState(0);
  const [hasMinCharacters, setHasMinCharacters] = useState(false);
  const [allPositions, setAllPositions] = useState([]);
  const [companyAddresses, setCompanyAddresses] = useState([]);
  const serviceRef = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [
    optionsSelectedDropdownMultiSelect,
    setOptionsSelectedDropdownMultiSelect,
  ] = useState([]);
  const [newOptions, setNewOptions] = useState(options);
  const [localLanguage, setLocalLanguage] = useState(
    localStorage.getItem("language")
  );
  const [clearConfirmationModal, setClearConfirmationModal] = useState(false);
  const filter = createFilterOptions();
  const [isHidden, setIsHidden] = useState(false);


  const modalitiesTranslations = {
    HOME_OFFICE: t("positionsManagement.homeoffice"),
    ONSITE: t("positionsManagement.onsite"),
    HYBRID: t("positionsManagement.hybrid"),
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getColor = (status: any) => {
    switch (status) {
      case "ACHIEVED":
        return "#00A676";
      case "UNLOCKED":
        return "#302382";
      case "WAITING_EVALUATION":
        return "#FF9900";
      default:
        return "#C4C4C4";
    }
  };

  const user = JSON.parse(localStorage.getItem("userRoles"));
  const isSysAdmin = user[0].authority === "ROLE_SYSADMIN";

  const schema = zod.object({
    company: zod.any().refine((val) => {
      if (isSysAdmin) {
        return val !== undefined && val !== null && val.id !== null;
      }
      return true;
    }, {
      message: t("positionsManagement.selectAddCompany"),
    }),
    title: zod
      .union([
        zod.object({
          id: zod.number().optional(),
          title: zod.string().refine((val) => val.trim().length > 0, {
            message: t("positionsManagement.selectAddPosition"),
          }),
        }),
        zod.null(),
      ])
      .refine(
        (val) => val !== null,
        t("positionsManagement.selectAddPosition")
      ),
    about: zod.string().min(30, t("positionsManagement.descriptionMinLength")),
    address: zod.object({
      name: zod.string().nullable(),
      cityId: zod.number().nullable(),
      stateId: zod.number().nullable(),
      countryId: zod.number().nullable(),
    }),
    modality: zod.any().refine((val) => val !== null, {
      message: t("positionsManagement.invalidModality"),
    }),
    visibility: zod.object({
      id: zod.number(),
      name: zod.string(),
    }),
    published: zod.boolean(),
    startMoney: zod.any(),
    endMoney: zod.any(),
    languages: zod.any(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      title: { id: null, title: "" },
      about: "",
      address: {
        cityId: null,
        name: "",
        stateId: null,
        countryId: null,
      },
      company: { id: null, value: null, name: "" },
      startMoney: "",
      endMoney: "",
      modality: null,
      competences: [],
      skillTreeIds: [],
      skills: [],
      languages: [],
      visibility: {
        id: null,
        name: "",
      },
      published: null,
    },
  });

  function interceptAddressError() {
    if (
      getValues("modality") !== null ||
      getValues("startMoney") !== null ||
      getValues("endMoney") !== null
    ) {
      if (
        getValues("modality")?.id !== "HOME_OFFICE" &&
        getValues("address").cityId == null
      ) {
        setError("address", {
          type: "custom",
          message: t("positionsManagement.addressRequired"),
        });
      } else if (
        formatSalaryValue(getValues("startMoney")) >
        formatSalaryValue(getValues("endMoney"))
      ) {
        setError("startMoney", {
          type: "custom",
          message: t("positionsManagement.startMoneyError"),
        });
      } else {
        handleSubmit(sendData)();
      }
    } else {
      handleSubmit(sendData)();
    }
  }

  function formatSalaryValue(salary) {
    const salaryWithoutSymbol = salary.replace("R$", "").trim();
    const salaryWithoutSeparator = salaryWithoutSymbol
      .replaceAll(".", "")
      .replace(",", ".");
    const salaryNumber = parseFloat(salaryWithoutSeparator);
    return salaryNumber;
  }

  function formatCurrency(value) {
    if (value == null) {
      return "";
    }
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  }

  function Checkbox({
    visible,
    onClick,
    disabled = false,
  }: {
    visible: boolean;
    onClick?: (any) => any;
    disabled?: boolean;
  }) {
    return (
      <MenuItem
        onClick={onClick && onClick}
        disabled={disabled}
        style={{
          minWidth: 32,
          maxWidth: 32,
          minHeight: 32,
          maxHeight: 32,
          borderRadius: 32,
        }}
        sx={{
          color: "rgba(236, 73, 144)",
          p: 0,
          m: 0,
          ":hover": {
            backgroundColor: "rgba(236, 73, 144, 0.15)",
          },
        }}
      >
        <S.CheckboxOutCircle selected={visible}>
          {visible && (
            <CheckIcon
              sx={{ fontSize: 16, color: "#fff" }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                padding: 0,
                margin: 0,
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </S.CheckboxOutCircle>
      </MenuItem>
    );
  }

  async function getAllCompetences() {
    setLoadingCompetences(true);
    const response = await SkillTreeService.getSkillTreeToDropdownWithCompany(
      search
    );

    const responseMap =
      response?.map((item) => ({
        ...item,
        label: item?.value,
        id: item.id,
        description: item?.description,
      })) || [];
    const ListIds = responseMap.map((item) => item.id);
    ordersCompetences(responseMap, ListIds);
    setLoadingCompetences(false);
  }

  async function getAllPositionsToDropdown() {
    const response = await FormationService.getBaseFormationsManagement();
    setAllPositions(response.sort((a, b) => b.id - a.id));
  }

  function decreaseSubtractedValue() {
    setSubtractedValue((value) => value - 1);
  }

  async function addSkills(competencesSelected) {
    if (selectedSkillTree.id === subtractedValue) {
      decreaseSubtractedValue();
    }

    if (allTrees.some((tree) => tree.id === selectedSkillTree?.id)) {
      let newAllTrees = allTrees.map((tree) =>
        tree.id === selectedSkillTree?.id
          ? {
            ...tree,
            skills: competencesSelected,
          }
          : tree
      );
      setAllTrees(newAllTrees);
    } else {
      allTrees.push({ ...selectedSkillTree, skills: competencesSelected });
    }

    if (!openSkillTrees) {
      setOpenSkillTrees(true);
    }
  }

  function changeCompetenceName(newName) {
    let newCompetenceName = allTrees.map((tree) =>
      tree.id === selectedSkillTree?.id
        ? {
          ...tree,
          value: newName,
          label: newName,
        }
        : tree
    );
    setAllTrees(newCompetenceName);
  }

  function removeSkill(skillToRemove, tree) {
    const updatedSkills = tree.skills.filter(
      (skill) => skill.id !== skillToRemove.id
    );
    let newSkillTrees = allTrees.map((t) =>
      t.id === tree.id ? { ...t, skills: updatedSkills } : t
    );
    setAllTrees(newSkillTrees);
  }

  async function mandatorySkill(skillMandatory, tree) {
    const updatedSkills = tree.skills.map((skill) =>
      skill.id === skillMandatory?.id
        ? {
          ...skill,
          mandatory: !skill.mandatory,
        }
        : skill
    );
    let newSkillTrees = allTrees.map((t) =>
      t.id === tree.id ? { ...t, skills: updatedSkills } : t
    );
    setAllTrees(newSkillTrees);
  }

  async function sendData(data) {
    try {
      if (data.title?.title === action?.data?.title && action?.type === "add") {
        enqueueSnackbar(t('positionsManagement.sameTitleWarning'), {variant: "warning"});
        return
      }
      data.languages = data?.languages.map((language) => language.value);
      allTrees.forEach((t) => {
        if (t.skills.length <= 0) {
          enqueueSnackbar(
            `${t("positionsManagement.competence")} ${t.label} ${t(
              "positionsManagement.noSelectedSkills"
            )}`,
            { variant: "warning" }
          );
          throw new Error(t("positionsManagement.competenceNoSelectedSkills"));
        }
      });

      let skillsWithoutChallenge = [];

      allTrees.map((st) => {
        skillsWithoutChallenge = skillsWithoutChallenge.concat(
          st.skills.filter(
            (s) =>
              s.numberQuestions === 0 ||
              s.numberQuestions === null ||
              s.id === -2
          )
        );
      });

      setIsLoading(true);
      const formationDTO = {
        title: data.title?.title,
        baseFormationId: data?.title?.id >= 0 ? data.title.id : null,
        description: data.about,
        skillTrees: allTrees.map((tree) => ({
          id: tree?.id,
          name: tree?.value || tree.label,
          skills: tree.skills.map((skill) => ({
            ...skill,
            id: skill?.id < 0 ? null : skill?.id,
            mandatory: skill?.mandatory || false,
          })),
          description: tree.description,
          mandatory: tree?.skills?.every((sk) => sk.mandatory) || false,
        })),
        cityId: data.address.cityId,
        stateId: data.address.stateId,
        countryId: data.address.countryId,
        formationModality: data.modality.id,
        visibility: data.visibility.id === 1 ? "PUBLIC" : "PRIVATE",
        published: data.published,
        hiring: isContract,
        languages: data?.languages,
        initialSalary: formatSalaryValue(data?.startMoney),
        finalSalary: formatSalaryValue(data?.endMoney),
        companyId: isSysAdmin ? data?.company?.id : null,
        generateByAI: false,
        hide: isHidden,
      };

      if (skillsWithoutChallenge.length > 0) {
        setSelectedSkillsWithoutChallenges(skillsWithoutChallenge);
        setShowConfirmationModal(true);
        setFormationPayloadToSave(formationDTO);
        return;
      }

      await saveFormation(formationDTO);
      feedbackAction("success", action?.type);
      onClickBack();
    } catch (err: any) {
      setIsLoading(false);
    }
  }

  async function saveFormation(formationDTO: {
    companyId: any;
    title: any;
    description: any;
    baseFormationId: any;
    skillTrees: { id: any; name: any; skills: any; description: any }[];
    visibility: string;
    published: boolean;
    hiring: boolean;
    generateByAI?: boolean;
    initialSalary: Number;
    finalSalary: Number;
    hide: boolean;
  }) {
    setIsLoading(true);

    if (action?.type === "add") {
      const result = await FormationService.addFormation(formationDTO);
      enqueueSnackbar(t("positionsManagement.positionSuccessfullyAdded"), {
        variant: "success",
      });
      await FormationService.resyncFormation(result?.data?.id);
    } else if (action?.type === "edit") {
      await FormationService.editFormation(action?.data?.id, formationDTO);
      enqueueSnackbar(t("positionsManagement.positionSuccessfullyEdited"), {
        variant: "success",
      });
      await FormationService.resyncFormation(action?.data?.id);
    }

    setIsLoading(false);
    onClickBack();
  }

  async function setEditData() {
    const response = await FormationService.findIdFormation(action?.data?.id);
    setIsGeneratingChallenges(response?.generatingChalenges);
    setAIChallengeGenerationCompletion(response?.generationPercentage);
    if (action?.type === "edit") {
      setValue(
        "title",
        response?.baseFormation || {
          id: response?.baseFormation?.id,
          title: response.title,
        }
      );
    }
    setValue("about", response?.description);
    setValue("startMoney", formatCurrency(response?.initialSalary));
    setValue("endMoney", formatCurrency(response?.finalSalary));

    let cityName = response.city?.name ? response.city?.name + ", " : "";
    let stateName = response.state?.name ? response.state?.name + ", " : "";
    let countryName = response.country?.name ? response.country?.name : "";

    let name = cityName + stateName + countryName;

    // Remover a vírgula final se o país for nulo
    if (!response.country) {
      name = name.substring(0, name.length - 2);
    }

    // Se todos são nulos, o nome deve ser vazio
    if (!response.city && !response.state && !response.country) {
      name = "";
    }

    setValue("address", {
      cityId: response.city?.id || null,
      countryId: response.country?.id || null,
      stateId: response.state?.id || null,
      name: name,
    });
    setValue("modality", {
      id: response.formationModality,
      name: modalitiesTranslations[response.formationModality],
    });
    setValue("visibility", {
      id: response?.visibility === "PUBLIC" ? 1 : 0,
      name:
        response?.visibility === "PUBLIC"
          ? t("performanceReport.externalRecruitment")
          : t("performanceReport.internalRecruitment"),
    });
    setValue("company", {
      id: response?.company.id,
      name: isSysAdmin ? response?.company?.name : null,
      value: response?.company.id,
    });
    setIsDraft(!response.published);
    setIsHidden(response?.hide);
    setIsContract(response?.hiring);
    setAllTrees(
      response?.skillTrees.map((tree) => ({
        id: tree?.id,
        label: tree?.name,
        skills: tree?.skills,
        description: tree?.description,
        mandatory: tree?.mandatory,
      }))
    );
    const newOptionsLanguage = newOptions.filter(
      (option) =>
        response?.languages?.includes(option?.value) &&
        option?.value !== localLanguage
    );
    setValue("languages", newOptionsLanguage);
    setOpenSkillTrees(true);
  }

  async function allCompanies() {
    const result = await CompanyService.getAllMapped("");
    setCompanies(result);
  }
  async function getBaseSkillTrees(id: number) {
    const response = await FormationService.findIdFormation(id);
    setAllTrees(
      response?.skillTrees.map((tree) => ({
        id: tree?.id,
        label: tree?.name,
        skills: tree?.skills,
        description: tree?.description,
        mandatory: tree?.mandatory,
      }))
    );
    setOpenSkillTrees(true);
  }

  useEffect(() => {
    if (allTrees.length > 0) {
      setExpanded(allTrees[0]?.id);
    }
  }, [allTrees]);

  async function getAllAddresses() {
    const response = await AddressService.getCurrentCompanyAddresses();
    setCompanyAddresses(response);
  }

  useEffect(() => {
    setLocalLanguage(localStorage.getItem("language"));
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    if(isSysAdmin){
      allCompanies();
    }
    if(!isSysAdmin && !action?.data) {
      setValue("visibility", {
        id: 0,
        name: t("performanceReport.internalRecruitment")
      })
    }
    getAllAddresses();
    getAllCompetences();
    getAllPositionsToDropdown();
    if (!!action?.data) {
      setEditData();
    }
    setNewOptions(
      options.filter((language) => language.value !== (localLanguage || "pt"))
    );
  }, []);

  useEffect(() => {
    getAllPositionsToDropdown();
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula",
    // 'image'
    // 'video'
  ];

  const [searchState, setSearchState] = useState("");
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (searchState.length > 2) {
      getStates();
    }
  }, [searchState]);

  async function getStates() {
    try {
      const statesResponse = await axiosApi.get(
        `/address?filter=${searchState}&onlyCities=${getValues("modality").id !== "HOME_OFFICE"
        }`
      );
      setStates(statesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  const stateRef = useRef(null);
  const [disable, setDisable] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  async function ordersCompetences(initialValue, ids) {
    const responseIds = await SkillTreeService.findIdSkillTree(ids);
    const tenantObj = JSON.parse(localStorage.getItem("tenantObj"));

    const sortByCompany = responseIds.sort((a, b) =>
      a.skills?.[0]?.company?.tradeName.includes(tenantObj.name)
        ? -1
        : b.skills?.[0]?.company?.tradeName.includes(tenantObj.name)
          ? 1
          : 0
    );
    const addLabelsByCompany = sortByCompany.map((item) => ({
      ...initialValue.find((valueItem) => valueItem.id === item.id),
      label: `${item.name} - ${item.skills?.[0]?.company?.tradeName}`,
    })); //, value: `${item.name} - ${item.skills?.[0]?.company?.tradeName}`
    setAllCompetencesOptions(addLabelsByCompany);
  }

  return (
    <S.ContainerPlansForm>
      <BackButton
        style={{ marginTop: 36, marginBottom: 36, width: 30 }}
        onClick={onClickBack}
      >
        {t("positionsManagement.back")}
      </BackButton>
      {isGeneratingChallenges === true && (
        <div>
          <Alert
            style={{ width: "100%", textAlign: "left", marginTop: "20px" }}
            severity="warning"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t("positionsManagement.cannotEditOrPublish", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />{" "}
            <b>{t("positionsManagement.generationProgress")}</b>
          </Alert>
          <S.ContainerProgressBar>
            <Box sx={{ width: "100%", mr: 1 }}>
              <S.BorderLinearProgress
                variant="buffer"
                value={AIChallengeGenerationCompletion}
                valueBuffer={AIChallengeGenerationCompletion}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                AIChallengeGenerationCompletion
              )}%`}</Typography>
            </Box>
          </S.ContainerProgressBar>
        </div>
      )}
      <div>
        {isSysAdmin && (
          <Controller
            name="company"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div >
                <Select
                  showSearch
                  options={companies}
                  selected={value}
                  onSelect={(newValue) => onChange(newValue)}
                  placeholder={t("positionsManagement.company")}
                  label={t("positionsManagement.company")}
                  error={errors?.company?.message}
                />
              </div>
            )}
          />
        )}
        <S.Label>{t("positionsManagement.positionName")}</S.Label>
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              data-cy="positionTitle"
              id="title"
              noOptionsText={t("noOptionsMui")}
              options={allPositions}
              value={value}
              sx={{
                "& .MuiInputBase-root": {
                  background: "#fff",
                  fontWeight: 700,
                  fontSize: 18,
                  color: "#000",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    border: "1px solid",
                    borderColor: "#8C8C8C",
                  },
                  "& fieldset": {
                    border: "1px solid",
                    borderColor: "#8C8C8C",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: "#8C8C8C",
                  },
                },
              }}
              onChange={(event, newValue) => {
                if (newValue.id !== -2) {
                  getBaseSkillTrees(newValue.id);
                }
                onChange(
                  newValue?.id !== -2
                    ? newValue
                    : { id: newValue.id, title: newValue.inputValue }
                );
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `${t("add")} ${inputValue}`,
                    id: -2,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(params, opt) => (
                <Typography
                  {...params}
                  key={opt.id}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: "19px",
                    paddingBottom: "19px",
                    backgroundColor: "auto",
                    gap: 13,
                    opacity: 1,
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8c8c8c",
                  }}
                >
                  {opt?.title}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    borderRadius: 0,
                    width: "100%",
                    height: "100%",
                    fontSize: 16,
                    fontWeight: 500,
                    outline: "none",
                    verticalAlign: "middle",
                    color: "#131212",
                    background: "#ffffff",
                  }}
                  placeholder={t("positionsManagement.positionName")}
                  value={value ? value.title : ""}
                />
              )}
            />
          )}
        />
        <S.ErrorText>
          {errors?.title?.title?.message || errors?.title?.message}
        </S.ErrorText>
      </div>
      <div>
        <Controller
          name="about"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <S.Label>{t("positionsManagement.aboutPosition")}</S.Label>
              <S.ContainerInput borderColor={errors?.about}>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  onChange={(value) => {
                    setValue("about", value);
                    setHasMinCharacters(value.trim().length > 30);
                  }}
                  placeholder={t("positionsManagement.aboutPosition")}
                  value={value}
                  formats={formats}
                />
              </S.ContainerInput>
            </div>
          )}
        />
        {!hasMinCharacters && (
          <S.ErrorText>{errors?.about?.message}</S.ErrorText>
        )}
      </div>
      <S.InputRow rowsSize={[2, 2]}>
        <Controller
          name="modality"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ marginTop: "5px", marginBottom: "2px" }}>
              <Select
                showSearch
                selected={value}
                onSelect={(newValue) => onChange(newValue)}
                placeholder={t("positionsManagement.modality")}
                label={t("positionsManagement.modality")}
                error={errors?.modality?.message}
                options={[
                  {
                    id: "HOME_OFFICE",
                    name: t("positionsManagement.homeoffice"),
                  },
                  {
                    id: "ONSITE",
                    name: t("positionsManagement.onsite"),
                  },
                  {
                    id: "HYBRID",
                    name: t("positionsManagement.hybrid"),
                  },
                ]}
              />
            </div>
          )}
        />
        <Controller
          name="visibility"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ marginTop: "5px", marginBottom: "2px" }}>
              <Select
                showSearch
                selected={value}
                onSelect={(newValue) => onChange(newValue)}
                placeholder={t("positionsManagement.recruitmentType")}
                label={t("positionsManagement.recruitmentType")}
                disabled={!isSysAdmin}
                error={errors?.modality?.message}
                options={[
                  {
                    id: 0,
                    name: t("performanceReport.internalRecruitment"),
                  },
                  {
                    id: 1,
                    name: t("performanceReport.externalRecruitment"),
                  },
                ]}
              />
            </div>
          )}
        />
      </S.InputRow>

      <S.InputRow rowsSize={[2, 2]}>
        <Controller
          name="address"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <S.Label>{t("positionsManagement.address")}</S.Label>
              <Autocomplete
                id="states"
                filterOptions={(x) => (searchState.length > 2 ? states : [])}
                options={searchState.length > 2 ? states : []}
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(event, value) => {
                  if (value === null) {
                    setValue("address", {
                      cityId: null,
                      name: "",
                      stateId: null,
                      countryId: null,
                    });
                    return;
                  }
                  setValue("address", value);
                }}
                ref={stateRef}
                disabled={disable}
                sx={{ flex: 0.75 }}
                autoComplete={false}
                noOptionsText={
                  searchState.length > 2 ? t("noOptionsMui") : t("typeToSearch")
                }
                renderOption={(params, opt) => (
                  <Typography
                    {...params}
                    key={`${opt.countryId}&${opt.stateId}&${opt.cityId}`}
                    sx={{
                      p: 0,
                      m: 0,
                    }}
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingTop: "19px",
                      paddingBottom: "19px",
                      backgroundColor: "auto",
                      gap: 13,
                      opacity: 1,
                      fontFamily: "Ubuntu",
                      fontWeight: 500,
                      fontSize: 16,
                      color: "#8c8c8c",
                    }}
                  >
                    {opt?.name}
                  </Typography>
                )}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      value={searchState}
                      onChange={(e) => setSearchState(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      size="medium"
                      sx={{
                        borderRadius: "5px",
                        width: "100%",
                        fontSize: 16,
                        fontWeight: 500,
                        outline: "none",
                        color: "#131212",
                        background: "#ffffff",
                      }}
                      placeholder={t("positionsManagement.address")}
                      autoComplete="off"
                    />
                  );
                }}
              />
              <S.ErrorText>{errors?.address?.message}</S.ErrorText>
            </div>
          )}
        />
        <Controller
          name="languages"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              {value ? (
                <CheckboxMultiselect
                  options={newOptions}
                  label={t("languages")}
                  onChange={onChange}
                  value={value}
                />
              ) : (
                <CheckboxMultiselect
                  options={newOptions}
                  label={t("languages")}
                  onChange={onChange}
                  value={[]}
                />
              )}
            </div>
          )}
        />
      </S.InputRow>

      <S.InputRow rowsSize={[2, 2]}>
        <Controller
          name="startMoney"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <S.Label>{t("positionsManagement.startMoney")}</S.Label>
              <InputMoney value={value} onChange={(e: any) => onChange(e)} />
              <S.ErrorText>{errors?.startMoney?.message}</S.ErrorText>
            </div>
          )}
        />
        <Controller
          name="endMoney"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <S.Label>{t("positionsManagement.endMoney")}</S.Label>
              <InputMoney value={value} onChange={(e: any) => onChange(e)} />
              <S.ErrorText>{errors?.endMoney?.message}</S.ErrorText>
            </div>
          )}
        />
      </S.InputRow>

      <div>
        <S.Label>{t("positionsManagement.addCompetence")}</S.Label>
        <Autocomplete
          data-cy="positionCompetence"
          id="competence"
          ref={serviceRef}
          handleHomeEndKeys
          selectOnFocus
          clearOnBlur
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={isGeneratingChallenges}
          noOptionsText={t("noOptionsMui")}
          onChange={(e, value) => {
            setModalData(value);
            if (value?.label?.length > 0) {
              setSelectedSkillTree(value);
              setShowSkillsModal(true);
              setIsFocused(false);
            }
          }}
          open={isFocused}
          options={allCompetencesOptions}
          loading={loadingCompetences}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);

            let { inputValue } = params;
            let isExisting = options.some(
              (option) => inputValue === option.value
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                label: `${t("add")} ${inputValue}`,
                id: subtractedValue,
              });
            }
            return filtered;
          }}
          sx={{
            "& .MuiInputBase-root": {
              background: "#fff",
              fontWeight: 700,
              fontSize: 18,
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
              "& fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
            },
          }}
          renderOption={(params, opt) => (
            <Typography
              {...params}
              sx={{
                p: 0,
                m: 0,
              }}
              style={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "19px",
                paddingBottom: "19px",
                backgroundColor: "auto",
                gap: 13,
                opacity: 1,
                fontFamily: "Ubuntu",
                fontWeight: 500,
                fontSize: 16,
                color: "#8c8c8c",
              }}
            >
              {opt?.label}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => setSearchCompetences(e.target.value)}
              value={searchCompetences}
              placeholder={t("positionsManagement.addCompetence")}
            />
          )}
        />
        <S.ErrorText>{errors?.competences?.message}</S.ErrorText>
      </div>

      <S.ContainerAccordions>
        {allTrees.length > 0 && (
          <S.AccordionsLabelDiv>
            <p style={{ fontSize: "1rem" }}>
              {t("positionsManagement.competenceLinked")}
            </p>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                width: "fit-content",
                height: 40,
                background: "#fff0",
                borderWidth: 1,
                borderStyle: "solid",
                color: "#131212",
                fontSize: 14,
                fontWeight: 400,
              }}
              onClick={() => setClearConfirmationModal(true)}
            >
              <X size={17} /> {t("positionsManagement.clean")}
            </Button>
          </S.AccordionsLabelDiv>
        )}
        {allTrees.map((comp, i) => (
          <Accordion
            disableGutters
            expanded={expanded === comp?.id}
            onChange={handleChange(comp?.id)}
          >
            <AccordionSummary
              aria-controls={`${comp?.id}d-content`}
              id={`${comp?.id}d-header`}
            >
              <S.ContainerAccordionSummary>
                <S.ContainerAccordionSummaryLeft>
                  <S.AccordionSummaryCircleImg color={getColor("UNLOCKED")}>
                    <S.AccordionSummaryImg src={TreeWhiteIcon} alt="TreeIcon" />
                  </S.AccordionSummaryCircleImg>
                  <S.ContainerAccordionSummaryLeftTexts>
                    <S.TitleAccordionSummary>
                      {comp?.value || comp?.label}
                    </S.TitleAccordionSummary>
                  </S.ContainerAccordionSummaryLeftTexts>
                </S.ContainerAccordionSummaryLeft>
                <Tooltip
                  title={t("positionsManagement.markCompetenceMandatory")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setAllTrees(
                        allTrees.map((item) =>
                          item.id === comp?.id
                            ? {
                              ...item,
                              skills: item?.skills?.every(
                                (sk) => sk.mandatory
                              )
                                ? item?.skills?.map((sk) => ({
                                  ...sk,
                                  mandatory: false,
                                }))
                                : item?.skills?.map((sk) => ({
                                  ...sk,
                                  mandatory: true,
                                })),
                            }
                            : item
                        )
                      );
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 198 },
                      left: { xs: 55, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    {comp?.skills?.every((sk) => sk.mandatory) ? (
                      <StarIcon />
                    ) : (
                      <StarOutlineIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t("positionsManagement.editCompetenceName")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalData(comp);
                      if (comp?.label?.length > 0) {
                        setSelectedSkillTree(comp);
                        setShowSkillTreeNameModal(true);
                      }
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 152 },
                      left: { xs: 101, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t("positionsManagement.addSkills")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalData(comp);
                      if (comp?.label?.length > 0) {
                        setSelectedSkillTree(comp);
                        setShowSkillsModal(true);
                      }
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 106 },
                      left: { xs: 147, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t("positionsManagement.deleteCompetence")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={() => {
                      setAllTrees(
                        allTrees.filter((item) => item.id !== comp?.id)
                      );
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 60 },
                      left: { xs: 193, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <S.AccordionSummarySetaImg
                  up={expanded === comp?.slug}
                  src={SetaGray}
                  alt="Seta"
                />
              </S.ContainerAccordionSummary>
            </AccordionSummary>
            {openSkillTrees && (
              <AccordionDetails sx={{ p: 0, m: 0 }}>
                <S.ContainerAccordionDetails>
                  <Controller
                    name="competences"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CardTree
                        skills={comp?.skills}
                        draggable
                        onRemove={(sk) => removeSkill(sk, comp)}
                        onMandatory={(sk) => mandatorySkill(sk, comp)}
                        onChange={onChange}
                      />
                    )}
                  />
                </S.ContainerAccordionDetails>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </S.ContainerAccordions>

      <S.CheckboxContainer data-cy="activatePosition">
        <Checkbox
          disabled={isGeneratingChallenges}
          visible={isHidden}
          onClick={() => setIsHidden(!isHidden)}
        />
        <S.CheckboxText>
          {t("positionsManagement.hide")}
          <Tooltip
            sx={{ p: 0.4, m: 0, ml: 0.4, mb: -1 }}
            title={t("positionsManagement.hideTooltip")}
            placement="top"
          >
            <InfoOutlined />
          </Tooltip>
        </S.CheckboxText>
      </S.CheckboxContainer>

      <S.ListButtonContainer>
        <Grid container gap={2}>
          <Grid item lg={2} xl={2} md={5} xs={12} sm={12}>
            <Button
              data-cy="savePosition"
              onClick={() => {
                setValue("published", true);
                interceptAddressError();
              }}
              disable={isLoading || isGeneratingChallenges}
            >
              {isDraft
                ? t("positionsManagement.saveAndPublish")
                : t("positionsManagement.save")}
            </Button>
          </Grid>
          {!isLoading && !isGeneratingChallenges && (
            <Grid item lg={2} xl={2} md={5} xs={12} sm={12}>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#fff0",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "#131212",
                  color: "#131212",
                }}
                onClick={() => {
                  setValue("published", false);
                  interceptAddressError();
                }}
              >
                {t("positionsManagement.saveAsDraft")}
              </Button>
            </Grid>
          )}
        </Grid>
      </S.ListButtonContainer>
      <Modal
        open={showSkillsModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowSkillsModal(false);
          setSearchCompetences("");
          setModalData(undefined);
          setSelectedSkillTree(null);
          const clearIndicator = serviceRef?.current?.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )?.[0];
          if (clearIndicator) {
            clearIndicator?.click();
          }
        }}
      >
        <SkillsModal
          onClose={() => {
            setShowSkillsModal(false);
            setSearchCompetences("");
            setModalData(undefined);
            setExpanded(selectedSkillTree.id);
            setSelectedSkillTree(null);
            const autocompleteInput =
              serviceRef?.current?.querySelector('input[type="text"]');
            const clearIndicator = serviceRef?.current?.getElementsByClassName(
              "MuiAutocomplete-clearIndicator"
            )?.[0];
            if (clearIndicator) {
              clearIndicator?.click();
              autocompleteInput.blur();
            }
          }}
          onAdd={addSkills}
          data={modalData}
          skillTree={selectedSkillTree}
        />
      </Modal>
      <Modal
        open={showConfirmationModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowConfirmationModal(false);
          setModalData(undefined);
          setIsLoading(false);
        }}
      >
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
            setModalData(undefined);
          }}
          isCurrentUserSysAdmin={isSysAdmin}
          onAdd={saveFormation}
          data={selectedSkillsWithoutChallenges}
          formationPayload={formationPayloadToSave}
        />
      </Modal>
      <Modal
        open={showSkillTreeNameModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowSkillTreeNameModal(false);
          setModalData(undefined);
        }}
      >
        <SkillTreeNameModal
          onClose={() => {
            setShowSkillTreeNameModal(false);
            setModalData(undefined);
          }}
          onSend={changeCompetenceName}
          data={modalData}
        />
      </Modal>
      <ClearConfirmationModal
        title={t("positionsManagement.attention")}
        message={t("positionsManagement.clearMessage")}
        open={clearConfirmationModal}
        onAgree={() => {
          setAllTrees([]);
          setClearConfirmationModal(false);
        }}
        onDisagree={() => {
          setClearConfirmationModal(false);
        }}
      />
    </S.ContainerPlansForm>
  );
}

export default PlansForm;
