import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import userService from "../../../services/UserService";
import * as S from "./style";

interface IFormData {
  emailparam?: string;
  onClose: () => void;
  openModal?: boolean;
}

const Form = ({ onClose, openModal, emailparam }: IFormData) => {
  const handleClose = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    setEmail(emailparam || "");
  }, [emailparam]);

  async function ResendEmailCode() {
    await userService.ResendEmail(email);
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.FullModal>
        <S.ModalTitle>Verificação do e-mail</S.ModalTitle>
        <S.ModalSubtitle>
          Para continuar, verifique o seu endereço de e-mail cadastrado e ative
          a sua conta. Enviamos um link de confirmação para o seu e-mail.
        </S.ModalSubtitle>
        <S.EmailRegistred>{emailparam}</S.EmailRegistred>
        <S.ContainerButtons>
          <S.buttonPink
            style={{
              width: 290,
            }}
            onClick={() => {
              ResendEmailCode();
            }}
          >
            Reenviar link de verificação
          </S.buttonPink>
          <S.buttonWhite
            data-cy="modalVerifyAccount"
            onClick={() => {
              navigate("/login");
            }}
            style={{
              width: 290,
              background: "#fff",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#131212",
              color: "#131212",
            }}
          >
            Fechar
          </S.buttonWhite>
        </S.ContainerButtons>
      </S.FullModal>
    </Modal>
  );
};

export default Form;
