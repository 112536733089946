import { Button, DialogActions, DialogContent, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import { EvaluationFormTypeFile } from '../../../pages/EvaluationChallenge/components/EvaluationFormTypeFile';
import * as S from "./styles";
import { Button as ButtonFire } from "design-system-firedev";
import { t } from "i18next";

interface QuestionModalProps {
  question: string;
  detailedResult: string;
  open: boolean;
  onClose: () => void;
  answerOptions?: any;
  feedback?: string;
  correctAlternative?: string;
  isCorrect: boolean;
}

const QuestionModal: React.FC<QuestionModalProps> = ({
  question,
  open,
  onClose,
  answerOptions,
  detailedResult,
  feedback,
  isCorrect,
}) => {
  return (
    <S.DialogContainer
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogContent>
        <S.Question>Questão:</S.Question>
        <S.Text dangerouslySetInnerHTML={{__html: question}}></S.Text>

        <S.ContainerResult>
          {answerOptions?.image && <S.AnchorButton target="blank" href={answerOptions?.image?.fileUrl}>
            <ButtonFire
              className="buttonShowFile"
              background="transparent"
              borderColor="#ffffff"
              color="#ff026f"
              fontVariant="body1"
              height={25}
              size="custom"
              text={t("evaluate.viewFile")}
              width={135}
              type="button"
            />
          </S.AnchorButton>}

          <S.Title>{detailedResult || answerOptions?.content}</S.Title>
          <S.IconContainer>
            <S.IconText isCorrect={isCorrect} isIncorrect={!isCorrect}>
            {isCorrect ? <S.CorrectIcon /> : <S.IncorrectIcon />}
              <S.Result>{isCorrect ? t('detailedChallenger.correct').toLocaleUpperCase() : t('detailedChallenger.incorrect').toLocaleUpperCase() }</S.Result>
            </S.IconText>
          </S.IconContainer>
        </S.ContainerResult>
        {!answerOptions && <S.Text>{feedback}</S.Text>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </S.DialogContainer>
  );
};

export default QuestionModal;