import styled from "styled-components";
import {colors} from "../../../components/utils/colors";

interface IPropsTextArea {
  borderColor: any;
}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const BackButtonContainer = styled.div`
  margin-top: 2.3rem;
`;

export const ButtonContainer = styled.div`
  width: 217px;
  margin-top: 5rem;
`;

export const ContainerDropDownSimpleSelect = styled.div`
  margin-top: 37px;
`;


export const InputRow = styled.div<{ rowsSize: number[] }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
          rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 16px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const BoxSelect = styled.div`
  min-height: 40px;
  width: 42%;
  display: flex;
  margin: 10px;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 38px;

  p {
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 10px;
    color: #696969;
    border: 1px solid #8c8c8c;
    margin-top: 10px;
    display: flex;
    gap: 6px;
    margin-bottom: 12px;

    span {
      margin-top: 2px;
    }
  }
`;

export const ContainerGap = styled.div`
  margin-top: 2.3rem;

  p {
    font-size: 14px;
  }
`;
export const ContainerFullTree = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  flex-direction: column;
  border: 1px solid ${colors.darkGray300};
  border-radius: 3px;
  margin-top: 37px;
`;

export const ContainerAba = styled.div<any>`
  border: none;
  outline: none !important;
  cursor: default;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #ff6423;
  padding: 1rem 2rem;
  border-bottom: none;
`;

export const AbaImageTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const AbaImageContainer = styled.div`
  width: 47px;
  height: 47px;
  border-radius: 47px;
  background-color: #fff;
  position: relative;
`;

export const AbaImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AbaTitleSubtitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.125rem;
`;

export const AbaTitle = styled.p`
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const WithoutSkills = styled.p`
  color: #131212;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const AbaSubtitle = styled.p`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ImageSeta = styled.img<any>`
  transform: ${(props) => (props.up ? "" : "rotate(180deg)")};
`;

export const ContainerAllCards = styled.div<any>`
  width: 100%;
  display: ${({visible}) => (visible ? "flex" : "none")};
  overflow: hidden;
  flex-direction: column;
  padding: 2rem 1.5rem;
  min-width: 302px;
  gap: 1.5rem;
  background: linear-gradient(180deg,
  rgba(255, 93, 40, 0.18) 48.93%,
  rgba(255, 0, 80, 0.18) 100%),
  url(.jpg);
`;

export const AbaCard = styled.div<{
  borderColor: string;
  minQuestions: boolean;
}>`
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffe8e6;
  border: 2px solid ${({borderColor}) => borderColor};
  border-radius: 3px;
  padding: 0.75rem;
  height: 128px;
  width: 267px;
  opacity: ${({minQuestions}) => (minQuestions ? 1 : 0.4)};
  /* margin: 0 16px 16px 0; */
  min-width: 267px;
  max-width: 100%;
  @media screen and (max-width: 767px) {
    width: 164px;
    min-width: 164px;
  }
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const AbaLineRowCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  /* align-items: center; */
`;

export const CardsLineContainer = styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ff6423aa;
  }

  max-width: 100%;
  gap: 1rem;
  align-items: center;
`;

export const LineStatusCircle = styled.div<{ color?: string }>`
  /* margin-top: 55px; */
  /* position: relative; */
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  z-index: 2;
  background-color: ${({color}) => color};
`;

export const CardTitle = styled.p<{ color: any }>`
  font-size: 1rem;
  text-align: left;
  font-weight: 500;
  color: ${({color}) => color || "#131212"};
`;

export const CardDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
  color: ${({color}) => color || "#696969"};
  text-align: left;
  max-width: 250px;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const CardStatus = styled.p<{ color: string }>`
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: ${({color}) => color};
`;

export const VerticalLine = styled.div`
  position: absolute;
  left: 50%;
  top: 9px;
  transform: translate(-50%, 0);
  height: calc(100% + 80px);
  width: 2px;
  z-index: 0;
  background-color: #696969;
`;

export const ContainerLineCircle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: visible;
  margin-top: 55px;
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 25px;
  background: white;
  border: 1px solid ${({borderColor}) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: 1px solid #ff026f;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #8c8c8c;
  }
`;

export const ErrorMessage = styled.p`
  color: #f5222d;
  font-size: 14px;
  position: relative;
`;
