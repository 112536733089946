import { Alert, Button as ButtonMaterial } from "@mui/material";
import { Button } from "design-system-firedev";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import SkillService from "../../../services/SkillService";

import { t } from "i18next";
import StudyArticleItem from "../../../components/StudyArticleItem";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import { IStudyArticle } from "../../../models/StudyArticle";
import SkillTreeService from "../../../services/SkillTreeService";
import api from "../../../services/api";
import { AlertModal } from "./AlertModal";
import * as S from "./styles";

interface DataStateProps {
  challengeId: number;

  map(arg0: (article: any) => JSX.Element): import("react").ReactNode;

  length: number;
  id: string;
  name: string;
  description: string;
  content: string;
  studyArticles: IStudyArticle[];
}

export default function SkillTrail() {
  const ref = useRef();
  const { width } = useContainerDimensions(ref);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { competencyId, skillTreeId, skillId, competencyType } = useParams();
  const [seeMore, setSeeMore] = useState(false);
  const [moreLess, setMoreLess] = useState(t("positions.seeMore"));
  const [days, setDays] = useState("0");
  const [hours, setHours] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const competencyEndpoint =
    competencyType === "course" ? "trails" : "formation";
  const competencyTypeForStatus =
    competencyType === "course" ? "trail" : "formation";

  const [state, setState] = useState({
    dataSkillTrail: {} as DataStateProps,
    trailName: "",
    skillStatus: "",
    unlockedIn: "",
    timeBlocked: 0,
    numberOfTries: 0,
  });

  async function fetchData() {
    const dataSkillTrail = await SkillService.findSkillId(skillId);
    const resultTrails = await api.get(
      `${competencyEndpoint}/dashboard/${competencyId}`
    );
    const trailName =
      competencyType === "course"
        ? resultTrails.data.name
        : resultTrails.data.title;
    const skillStatus =
      await SkillTreeService.getSkillStatusBySkillTreeAndSkill(
        competencyTypeForStatus,
        skillTreeId,
        competencyId,
        skillId
      );
    const dataAverageTime = await SkillService.getAverageTime(
      dataSkillTrail?.challengeId
    );

    await convertMinutes(dataAverageTime?.averageTime);

    setState({
      dataSkillTrail,
      trailName,
      skillStatus: skillStatus?.status,
      unlockedIn: skillStatus?.lockedUntil,
      timeBlocked: skillStatus?.timeBlocked,
      numberOfTries: skillStatus?.numberOfTries,
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function handleSeeMore() {
    if (seeMore) {
      setSeeMore(false);
      setMoreLess(t("positions.seeMore"));
    } else {
      setSeeMore(true);
      setMoreLess(t("positions.seeLess"));
    }
  }

  async function registerStart() {
    await api.patch(
      `challenges/start-challenge/${dataSkillTrail?.challengeId}`
    );
    navigate("/challenge", {
      state: {
        ...location?.state,
        id: competencyId,
        type: competencyType,
        challengeId: dataSkillTrail?.challengeId,
      },
    });
  }

  const { dataSkillTrail, trailName, skillStatus } = state;

  async function convertMinutes(totalMinutes: number) {
    const days = Math.floor(totalMinutes / 1440);
    const hours = Math.floor((totalMinutes % 1440) / 60);
    const minutes = totalMinutes % 60;

    await setDays(days.toString());
    await setHours(hours.toString());
    await setMinutes(minutes.toString());
    return;
  }

  return (
    <div>
      <S.Container>
        <Button
          onClick={() => {
            navigate(-1);
          }}
          background="transparent"
          borderColor="transparent"
          height={30}
          width="auto"
          color="#FF026F"
          colorIcon="#FF026F"
          fontVariant="heading1"
          pathIcon="arrow"
          size="custom"
          text={t("challenge.backTo")}
          justifyContent="left"
        />

        <S.BodyOfSkill>
          <S.TitleOfSkill title={dataSkillTrail?.name}>
            {dataSkillTrail.name}
          </S.TitleOfSkill>
          <S.DescriptionOfSkill open={seeMore} ref={ref}>
            {dataSkillTrail?.content}
          </S.DescriptionOfSkill>
          {dataSkillTrail?.content &&
            (dataSkillTrail?.content.split("\n").length > 6 ||
              dataSkillTrail?.content?.length / (width / 6) > 6) && (
              <S.SeeMoreButtonDiv>
                <Button
                  onClick={handleSeeMore}
                  background="transparent"
                  borderColor="transparent"
                  height={30}
                  width="auto"
                  color="#FF026F"
                  fontVariant="heading1"
                  size="custom"
                  text={moreLess}
                  justifyContent="center"
                  box-shadow="none"
                />
              </S.SeeMoreButtonDiv>
            )}
          {skillStatus == "FAILED" && (
            <Alert severity="error" variant={"filled"}>
              Você precisa aguardar até {state.unlockedIn} para tentar
              novamente.
            </Alert>
          )}
          {(+days > 0 || +hours > 0 || +minutes > 0) && (
            <S.AverageTimeText>
              {t("challenge.averageTime")}{" "}
              {+days > 0 ? `${days} ${t("challenge.days")}` : ""}{" "}
              {+hours > 0
                ? `${hours} ${t("challenge.hours")} ${t("challenge.and")}`
                : ""}{" "}
              {+minutes > 0 ? `${minutes} ${t("challenge.minutes")}` : ""}
            </S.AverageTimeText>
          )}
        </S.BodyOfSkill>
        {dataSkillTrail?.studyArticles?.length > 0 && <hr />}
        <S.FooterArticles>
          {dataSkillTrail?.studyArticles?.length > 0 && (
            <div>
              <S.TitleOfFooterArticles>
                {t("challenge.forStudy")}
              </S.TitleOfFooterArticles>
              <S.BoxLists>
                {dataSkillTrail.studyArticles.map((article) => (
                  <StudyArticleItem
                    article={article}
                  />
                ))}
              </S.BoxLists>
            </div>
          )}
          <ButtonMaterial
            disableRipple
            variant="contained"
            size="large"
            sx={{
              animation: "none",
              borderRadius: "3px",
              boxShadfow: "none",
              background: "#C4C4C4",
              color: "white",
              fontWeight: "700",
              width: "228px",
              height: "52px",
              cursor: "not-allowed",
              "&:hover": { backgroundColor: "#C4C4C4" },
            }}
          >
            {t("challenge.startChallenge")}
          </ButtonMaterial>
          {skillStatus == "UNLOCKED" || skillStatus == "STARTED" ? (
            <ButtonMaterial
              data-cy="skillTrailPageChallengeButton"
              disableRipple
              variant="contained"
              size="large"
              sx={{
                animation: "none",
                borderRadius: "3px",
                boxShadow: "none",
                background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
                color: "white",
                fontWeight: "700",
                width: "228px",
                height: "52px",
                cursor: "pointer",
                "&:hover": { backgroundColor: "#C4C4C4" },
              }}
              onClick={() => {
                setOpenAlertModal(true);
              }}
            >
              {skillStatus == "STARTED"
                ? t("challenge.continueChallenge")
                : t("challenge.startChallenge")}
            </ButtonMaterial>
          ) : (
            <ButtonMaterial
              disableRipple
              variant="contained"
              size="large"
              sx={{
                animation: "none",
                borderRadius: "3px",
                boxShadfow: "none",
                background: "#C4C4C4",
                color: "white",
                fontWeight: "700",
                width: "228px",
                height: "52px",
                cursor: "not-allowed",
                "&:hover": { backgroundColor: "#C4C4C4" },
              }}
            >
              {t("challenge.startChallenge")}
            </ButtonMaterial>
          )}
        </S.FooterArticles>
        <AlertModal
          open={openAlertModal}
          onClose={() => setOpenAlertModal(false)}
          registerStart={() => registerStart()}
          data={state}
          confirmText={
            skillStatus == "STARTED"
              ? t("challenge.continueChallenge")
              : t("challenge.startChallenge")
          }
        />
      </S.Container>
    </div>
  );
}
