import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableContainer,
} from "@mui/material";
import { t } from "i18next"
import * as T from "../../Global/Typography";
import * as S from "./style";
import StudyArticlesForm from "./Form";
import StudyArticleService from "../../services/StudyArticleService";
import { TableContainerLayout } from "../../components/TableContainerLayout";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";

export default function StudyArticles() {
  const [rows, setRows] = useState<any[]>([]);
  let [selected, setSelected] = useState<any>();
  const [action, setAction] = useState("list");
  const [addButton, setAddButton] = useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>("");
  const [totalPages, setTotalPages] = React.useState(0);

  const fetchData = async () => {
    const result = await StudyArticleService.getAllStudyArticleManagement(
      page - 1,
      search
    );
    setTotalPages(result.totalPages);
    setRows(result.content);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFeedbackAction = (
    type: string,
    newData: any,
    submitAction: string
  ) => {
    setAction(type === "success" ? "list" : "form");
    if (submitAction === "edit") {
      setRows(
        rows.map((row) => {
          if (row.id === newData.id) {
            row.name = newData.name;
          }
          return row;
        })
      );
    } else {
      const newRows = [newData, ...rows];
      setRows(newRows);
    }
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  const handleEdit = (id: number, name: string) => {
    setSelected({ id, name });
    setAction("form");
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
    setAddButton(true);
  };

  return (
    <S.ContainerFullPage>
      <T.Display data-cy="articlesH1">{t('sidebar.articles')}</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{ width: "100%", background: "white" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <S.FontHeaderTable>{t('artigos.nome')}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{t('artigos.url')}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{t('empresas.company')}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length > 0 &&
                  rows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell data-cy="articlesNameTable"><span style={{whiteSpace: "normal"}}>{item.name}</span></TableCell>
                      <TableCell><a href={item.url} target="_blank">{item.url}</a></TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <S.CompanyIcon
                            src={item?.companyLogo || skillHunterPositionIcon}
                            alt=""
                          />
                          {item?.company ||
                            "SkillHunter"}
                        </div>
                      </TableCell>
                      <TableCell sx={{textAlign: 'end', width: "5%"}}>
                        <div>
                        {item?.editable && (
                          <IconButton data-cy='editArticleButton'>
                            <EditOutlinedIcon
                              onClick={() => handleEdit(item.id, item.name)}
                            />
                          </IconButton>
                        )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}

                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                      Nenhum resultado encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (selected || addButton) && (
        <StudyArticlesForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          dataToEdit={selected}
        />
      )}
    </S.ContainerFullPage>
  );
}
