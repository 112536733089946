import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { t } from "i18next";

export const PositionTable = ({ data, isSysAdmin, isInternal, setAction, copyLink }) => {
  function formatType(type) {
    switch (type) {
      case "PRIVATE":
        return t("positionsManagement.private");
      case "PROTECTED":
        return t("positionsManagement.protected");
      case "PUBLIC":
        return t("positionsManagement.public");
      default:
        return "-";
    }
  }
  const internalRecruitment = JSON.parse(localStorage.getItem("tenantObj"))?.internalRecruitment;
  return (
    <TableBody>
      {data.length > 0 &&
        data.map((position, i) => (
          <TableRow key={position.id}>
            <TableCell>
              <span style={{ whiteSpace: "normal" }}>
                {position?.title}
              </span>
            </TableCell>
            <TableCell>{formatType(position?.visibility)}</TableCell>
            <TableCell>
              {position?.subscribedCount +
                " " +
                t("positionsManagement.candidates")}
            </TableCell>
            <TableCell>{`${position?.qualifiedCount} ${t(
              "positionsManagement.candidates"
            )}`}</TableCell>
            <TableCell>
              {position?.published
                ? t("positionsManagement.published")
                : t("positionsManagement.draft")}
            </TableCell>
            <TableCell>
              {(isSysAdmin || isInternal) && (
                <Tooltip
                  title={t("positionsManagement.copyToNewFormation")}
                >
                  <IconButton
                    onClick={() => setAction({ data: position, type: "add" })}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip
                title={t("positionsManagement.copyFormationLink")}
              >
                <IconButton onClick={() => copyLink(position)}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("positions.preview")}>
                <IconButton onClick={() => window.open(`/position-preview/${position?.company?.slug}/${position?.slug}`)}>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
              {(isSysAdmin || isInternal) && (
                <>
                  {!position?.published ? (
                    <Tooltip title={t("positionsManagement.draftJob")}>
                      <IconButton
                        onClick={() =>
                          setAction({ data: position, type: "edit" })
                        }
                        disabled={!internalRecruitment}
                      >
                        <DriveFileRenameOutlineIcon
                          style={{ color: "orange" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={() => setAction({ data: position, type: "edit" })}
                      disabled={!internalRecruitment}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        ))}

      {data.length === 0 && (
        <TableRow>
          <TableCell colSpan={6} sx={{ textAlign: "center" }}>
            {t("positionsManagement.noResultsFound")}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};
