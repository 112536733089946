import { styled as MUIStyled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Display = styled.h1`
  font-family: "Ubuntu";
  font-size: 36px;
  font-weight: bold;
`;

export const Heading1 = styled.h1`
  font-family: "Ubuntu";
  font-size: 20px;
  font-weight: bold;
`;

export const Heading2 = styled.h2`
  font-family: "Ubuntu";
  font-size: 18px;
  font-weight: bold;
`;

export const Heading3 = styled.h2`
  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: bold;
`;

export const Heading4 = styled.h2`
  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 500;
`;

export const Heading5 = styled.h2`
  font-family: "Ubuntu";
  font-size: 15px;
  font-weight: bold;
`;

export const Body1 = styled.p`
  font-family: "Ubuntu";
  font-size: 14px;
`;

export const Body2 = styled.p`
  font-family: "Ubuntu";
  font-size: 14px;
  font-weight: 100;
`;

export const Body3 = styled.p`
  font-family: "Ubuntu";
  font-size: 12px;
`;

export const Subtitle = MUIStyled(Typography)({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#8c8c8c",
});

export const StyledLink = styled(Link)({
  color: "#FF026F",
  fontWeight: 500,
});

export default { SkillInfoTitle: Display };
