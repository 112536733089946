import styled, {css} from "styled-components";
import {colors} from "../utils/colors";
import backgroundImage from "../../assets/backgroundSkillTree.png";

export const FullCardContainer = styled.div<any>`
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid ${colors.gray100};
  border-radius: 3px;
  position: sticky;
  height: calc(100vh - 110px);
  top: 100px;

  @media screen and (max-width: 999px) {
    display: none;
  }
`;
export const DescriptionOfPosition = styled.div.attrs(
  (props: { open: boolean, size: number }) => props
)`
  width: 100%;
  max-width: 260ch;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 25px;
  color: #696969;
  overflow-wrap: break-word;

  ${(props) =>
  !props.open &&
  css`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      min-height: calc(${props.size} * 1px);
      -webkit-box-orient: vertical;
    `}
`;

export const SeeMoreButtonDiv = styled.div`
  display: flex;
  justify-content: center;

  button {
    text-decoration: underline #ff026f;
  }
`;
export const LoadingContainer = styled.div`
  width: 100%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerAccordions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerAccordionSummary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 6px 0;
`;

export const ContainerAccordionSummaryLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`;

export const AccordionSummaryCircleImg = styled.div<{ color?: string }>`
  width: 36px;
  height: 36px;
  background-color: ${({color}) => color};
  border-radius: 999px;
  position: relative;
`;

export const AccordionSummaryImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
`;

export const ContainerAccordionSummaryLeftTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleAccordionSummary = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #131212;
`;

export const AccordionSummarySetaImg = styled.img<{ up?: boolean }>`
  width: 20px;
  margin-right: 1%;
  transform: ${({up}) => (up ? "" : "rotate(180deg)")};
`;

export const ContainerAccordionDetailsLoading = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px 16px 16px;
  gap: 12px;
  background: #fff0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoadingText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #131212;
`;

export const ContainerAccordionDetails = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 302px;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 0;
  background: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: center;
`;

export const WithoutSkills = styled.p`
  color: #131212;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const SubtitleAccordionSummary = styled.p<{ color?: string }>`
  font-size: 16px;
  font-weight: 500;
  color: ${({color}) => color};
`;

export const CardContent = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${colors.gray100};
  }

  ::-webkit-scrollbar-track {
    border-left: 1px solid ${colors.gray100};
    border-radius: 0;
    background-color: #fff0;
  }

  flex-direction: column;
`;

export const CardContentTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  color: ${colors.gray100};
`;

export const Content = styled.div`
  overflow-wrap: break-word;
  ul {
    margin-left: 17px;
  }

  ol {
    margin-left: 17px;
  }
`;

export const CardHeaderContainer = styled.div`
  width: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid ${colors.gray100};
`;

export const LineFlex = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerIconTreeWithCompetences = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const ButtonConclude = styled.button<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 22px;
  gap: 10px;
  cursor: default;

  width: 100%;
  height: 46px;

  background: #00a676;
  border-radius: 3px;
  border: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  text-align: center;
  color: #ffffff;
`;

export const ContainerCompetences = styled.p`
  color: #696969;
  padding-left: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

export const NumberSkillTree = styled.span`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const CompanyGap = styled.div`
  display: flex;
  //cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  margin-left: -8px;
  padding: 6px 8px;
  display: inline-flex;
  align-self: start;
  align-items: flex-end;
  flex-wrap: nowrap;
  gap: 0.7rem;
  cursor: pointer;
`;

export const CompanyImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
`;

export const ImageArrow = styled.img`
  transform: rotate(180deg);
`;

export const CompanyName = styled.p`
  font-size: 1rem;
`;

export const Title = styled.p`
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
`;

export const NoDataText = styled.p`
  font-size: 16px;
  padding: 32px 8px;
  text-align: center;
  font-weight: 500;
  color: #131212;
`;
export const ContainerProgressCard = styled.div`
  padding: 0.875rem 1rem 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin: 2rem 0;
  border: 1px solid #c4c4c4;
  gap: 1rem;
`;

export const ContainerProgressStarText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.875rem;
`;

export const ProgressTitleSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-right: 8px;
`;

export const TitleProgress = styled.h1`
  color: #131212;
  font-size: 1rem;
  font-weight: 700;
`;

export const ProgressSubtitle = styled.p`
  color: #696969;
  font-size: 0.875rem;
  font-weight: 300;
`;

export const TitleAboutPosition = styled.h4`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #131212;
  margin-bottom: 14px;
`;

export const TextCompany = styled.h2`
  font-family: "Ubuntu";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  //text-decoration-line: underline;
  //color: #ff026f;
`;
export const PercentText = styled.p`
  color: #ff026f;
  font-weight: 700;
  font-size: 1.25rem;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
`;

export const ContainerTitlePercent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
