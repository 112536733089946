import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { t } from "i18next";
import { ProgressBar } from "../../../../components/Progress";
import IUserStatistic from "../../../../models/UserStatistic";
interface IUserStatisticsCard {
  statistics: IUserStatistic[];
  isLoading?: boolean;
}

const showStatisticValue = (statistic: IUserStatistic): string => {
  if (statistic.isFlatValue) {
    return statistic.flatValue.toString() + " ";
  }

  return `${statistic.achievedValue}/${statistic.comparisonValue} `;
};

function UserStatisticsCard(data: IUserStatisticsCard) {
  return (
    <Box>
      {(data.isLoading && (
        <>
          <Grid container marginY={1}>
            <Grid item sm={11} marginTop={2} marginBottom={2} xs={12}>
              <Skeleton />
            </Grid>
            <Grid item sm={11} marginBottom={2} xs={12}>
              <Skeleton />
            </Grid>
            <Grid item sm={11} marginBottom={2} xs={12}>
              <Skeleton />
            </Grid>
            <Grid item sm={11} marginBottom={2} xs={12}>
              <Skeleton />
            </Grid>
          </Grid>
        </>
      )) || (
        <>
          {data.statistics.map((userStatistic) => (
            <Grid container marginY={1}>
              <Grid item sm={6} xs={12}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  color={"#FF026F"}
                  fontSize={"1.1rem"}
                >
                  {showStatisticValue(userStatistic)}
                  <span
                    style={{
                      color: "#8c8c8c",
                      fontWeight: 400,
                      fontSize: "1rem",
                    }}
                  >
                    {t(`clientDashboard.statisticsData.${userStatistic.name}`)}
                  </span>
                </Typography>
              </Grid>
              {!userStatistic.isFlatValue && (
                <Grid
                  item
                  sm={5}
                  xs={12}
                  display={"flex"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Box width={"100%"}>
                    <ProgressBar
                      smallSize
                      value={
                        userStatistic.comparisonValue > 0
                          ? (userStatistic.achievedValue /
                              userStatistic.comparisonValue) *
                            100
                          : 0
                      }
                      max={100}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          ))}
        </>
      )}
    </Box>
  );
}

export default UserStatisticsCard;
