import * as T from "../../Global/Typography";
import * as S from "./style";
import {t} from "i18next";
import React, {useState} from "react";
import Unlock from "../../assets/unlock.png";
import {useNavigate} from "react-router-dom";
import {Backdrop, Box, Button, CircularProgress, Modal, TextField, Typography} from "@mui/material";
import {sendCustomValue} from "../../services/TransactionsService";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import AvatarInput from "../../components/AvatarInput";

export default function Credits() {
  const navigate = useNavigate();
  const labels = {
    send: t("users.confirmPayment"),
    title: t("sidebar.credits"),
    subTitle: t("payment.creditsSubtitle"),
    selectPacks: t("payment.selectOneOfPackages"),
    package: t("payment.package"),
    unclocks: t("payment.unclocks"),
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [customQuantity, setCustomQuantity] = useState('');
  const [loading, setLoading] = useState(false);
  const [customSelected, setCustomSelected] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [data, setData] = useState<any>([
    {
      value: "100,00",
      quantity: 5,
      op: 0.5,
      selected: false,
      label: labels.package + 1
    },
    {
      value: "150,00",
      quantity: 10,
      op: 0.5,
      selected: false,
      label: labels.package + 2
    },
    {
      value: "200,00",
      quantity: 20,
      op: 0.5,
      selected: false,
      label: labels.package + 3
    },
  ]);

  const schema = Yup.object({
    quantityRequest: Yup.number().min(21, t('payment.quantityError'))
  })

  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });

  function updateData(indexMap: any) {
    const updatedData = data.map((d: any, index: number) => {
      if (index === indexMap) {
        setCustomSelected(false);
        return {...d, op: 1, selected: true};
      }
      if (indexMap === 4) {
        setCustomSelected(true);
      }
      return {...d, op: 0.5, selected: false};
    });
    setData(updatedData);
    setDataSelected(updatedData)
  }

  async function sendValueRequest() {
    try {
      setLoading(true);
      await sendCustomValue(customQuantity);
      setIsModalOpen(false);
      setIsSuccessModalOpen(true);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  function goToPayment() {
    const it = data.filter((item) => item.selected === true)
    navigate("/management/checkout", {state: {value: parseInt(it[0].value), quantity: it[0].quantity}});
  }

  const handleOpenModal = () => {
    setCustomQuantity("0");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <S.ContainerFullPage>
      <T.Display>{labels.title}</T.Display>
      <S.ContainerGap>
        <S.TextInfo>
          {labels.subTitle}
        </S.TextInfo>
      </S.ContainerGap>

      <S.ContainerPackages>
        <S.TextInfo>{labels.selectPacks}</S.TextInfo>

        <S.DivPackage>
          {data?.map((item: any, index: number) => (
            <S.Package
              style={{opacity: item.op}}
              onClick={() => updateData(index)}
            >
              <S.DivLabelPackage>
                <S.TitlePackage>{item.label}</S.TitlePackage>
              </S.DivLabelPackage>
              <S.ContainerPackage>
                <S.FirstLabelPackage>
                  <img
                    src={Unlock}
                    alt="Unlock_Back_Icon"
                    style={{marginRight: 5, marginTop: 3}}
                  />
                  {item.quantity} {labels.unclocks}
                </S.FirstLabelPackage>
                <S.SecoundContainer>
                  <S.SecoundLabelPackage>R$</S.SecoundLabelPackage>
                  <S.ThirdLabelPackage>
                    {item.value.toString()}
                  </S.ThirdLabelPackage>
                </S.SecoundContainer>
              </S.ContainerPackage>
            </S.Package>
          ))}
          <S.Package
            style={customSelected ? {opacity: 1} : {opacity: 0.5}}
            onClick={() => updateData(4)}
          >
            <S.DivLabelPackage>
              <S.TitlePackage>{t("payment.customPackage")}</S.TitlePackage>
            </S.DivLabelPackage>
            <S.ContainerPackage>
              <S.CustomPackage>
                {t("payment.customValue")}
              </S.CustomPackage>
            </S.ContainerPackage>
          </S.Package>
        </S.DivPackage>
      </S.ContainerPackages>

      <S.ContainerButton>
        <Button
          variant="contained"
          size="large"
          disabled={dataSelected === null}
          onClick={() => {
            if (customSelected) {
              handleOpenModal();
            } else {
              goToPayment();
            }
          }}
          sx={{
            background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
            color: "white",
            fontWeight: "700",
            width: "155px",
            height: "53px",
          }}
        >
          {labels.send}
        </Button>
      </S.ContainerButton>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4
        }}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            {t("payment.desiredQuantity")}
          </Typography>
          <Typography sx={{fontSize: '0.9rem'}} gutterBottom>
            *{t('payment.quantityWarning')}
          </Typography>
          <Controller
            name='quantityRequest'
            defaultValue={21}
            control={control}
            render={({field:{onChange, value}}) => (
              <TextField
                variant="outlined"
                fullWidth
                value={value}
                onChange={(e) => {
                  const value = e.target.value;
                  onChange(value)
                  if (/^\d+$/.test(value) && parseInt(value) > 20) {
                    setCustomQuantity(value);
                  }
                }}
                type="number"
              />
            )}
          />
          {
            errors?.quantityRequest?.message &&
              <Typography sx={{fontSize: '0.8rem', color: '#f5222d'}}>
                {errors?.quantityRequest?.message}
              </Typography>
          }

          <Button
            variant="contained"
            disabled={loading}
            sx={{
              background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
              color: "white",
              mt: 2
            }}
            onClick={handleSubmit(sendValueRequest)}
          >
            {t("evaluate.confirm")}
          </Button>
          {
            loading &&
              <Backdrop
                  sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                  open={loading}
              >
                  <CircularProgress color="inherit"/>
              </Backdrop>
          }
        </Box>
      </Modal>
      <Modal open={isSuccessModalOpen} onClose={() => setIsModalOpen(false)}>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px'
        }}>
          <Typography variant="h6">{t("payment.thanks")}</Typography>
          <Typography variant="body1">{t("payment.contactSoon")}</Typography>
          <Button variant="contained"
                  color="primary"
                  sx={{
                    background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
                    color: "white",
                    marginTop: "10px",
                  }}
                  onClick={() => setIsSuccessModalOpen(false)}>
            {t("evaluate.confirm")}
          </Button>
        </div>
      </Modal>
    </S.ContainerFullPage>
  );
}
