import styled from "styled-components";

export const ContainerButton = styled.button<any>`
  width: 100%;
  padding: 8px 22px;
  background-color: #f00;
  border-radius: 3px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "54px")};
  //text-transform: capitalize;
  background: ${({ disabled }) =>
    disabled ? "#C4C4C4" : "linear-gradient(90deg, #ff6423 0%, #ff026f 100%)"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: 0.1s;

  :hover {
    box-shadow: ${({ disabled, hoverDisabled }) => ((disabled || hoverDisabled ) ? "none" : "0 2px 4px #0004")};
    transition: 0.1s;
  }
`;
