import { ICity } from "../../components/utils/cities";
import { IState } from "../../components/utils/states";

export const initialFiltersState = {
  onlyUnlockedUser: null,
  stateSelected: [],
  selectedPosition: null,
  selectedCourse: null,
  selectedCompetence: null,
  selectedSkill: null,
  citySelected: [],
  selectStatus: null,
  userSelected: [],
};

type DefaultSelected = { name: string, value: number } | null

export interface StateFilters {
  onlyUnlockedUser: { name: string, value: boolean } | null;
  stateSelected: IState[] | null;
  selectedPosition: DefaultSelected;
  selectedCourse: DefaultSelected;
  selectedCompetence: DefaultSelected;
  selectedSkill: DefaultSelected;
  citySelected: ICity[];
  selectStatus: DefaultSelected;
  userSelected: DefaultSelected[];
}

type ActionType = "onChangeValue" | "resetState" | "onChangeValues";

interface BaseAction<T extends ActionType> {
  type: T;
}

interface OnChangeValueAction extends BaseAction<"onChangeValue"> {
  payload: {
    name: keyof StateFilters;
    value: StateFilters[keyof StateFilters];
  };
}

interface OnChangeValuesAction extends BaseAction<"onChangeValues"> {
  payload: {
    name: keyof StateFilters;
    value: StateFilters[keyof StateFilters];
  }[];
}

interface ResetStateAction extends BaseAction<"resetState"> {
  payload: null;
}

type Action = OnChangeValueAction | OnChangeValuesAction | ResetStateAction;

export function filtersReducer(state: StateFilters, action: Action) {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case "onChangeValue":
      return {
        ...state,
        [payload.name]: payload.value
      };
    case "onChangeValues": {
      const mapValues = payload.reduce((acc, curr) => {
        return {
          ...acc, 
          [curr.name]: curr.value
        }
      }, {})

      return {
        ...state,
        ...mapValues
      }
    }
    case "resetState":
      return initialFiltersState;
    default:
      return state;
  }
}