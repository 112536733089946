import { TextFieldProps } from '@mui/material';
import accounting from 'accounting';
import { ContainerInput } from "./styles";

const InputMoney = (props: TextFieldProps) => {
  const handleChange = (event: any) => {
    const inputValue = event.target.value
    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, '')) / 100
    const formattedValue: any = accounting.formatMoney(numericValue, 'R$ ', 2, '.', ',')
    props.onChange(formattedValue)
  }
  return <ContainerInput {...props} type="text" value={props.value} onChange={handleChange} placeholder="R$ 0,00" />
}
export default InputMoney;
