import api from "./api";

const uri = "question-batch";
class QuestionsBatchesService {
  async getQuestionsBatches(search: string, page: number) {
    let response;
    if (search) {
      response = await api.get(
        `${uri}?filter=${search}&page=${page}&size=5`
      );
      return response.data;
    }
    response = await api.get(`${uri}?page=${page}&size=5`);
    return response.data;
  }

  async findIdQuestionBatch(id: number) {
    const response = await api.get(`${uri}/${id}`);
    return response.data;
  }

  async reviewGeneratedQuestions(likes: number[], dislikes: number[]) {
    const response = await api.put(`${uri}`, { likes, dislikes });
    return response.data;
  }
}

export default new QuestionsBatchesService();
