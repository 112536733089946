import { InputHTMLAttributes, useState } from "react";
import * as S from "./styles";
import { Eye, EyeSlash } from "phosphor-react";

export interface IInputPasswordProps
  extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: any;
  errorMessageColor?: string;
  label?: string;
  borderColor?: string;
  backgroundColor?: string;
  width?: number | string;
  height?: number | string;
  colorIcon?: string;
}

export function InputPassword({
  errorMessage,
  backgroundColor,
  borderColor,
  colorIcon,
  width,
  height,
  label,
  ...props
}: IInputPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);

  function handleToggleVisibilityOfPassword() {
    setShowPassword((prevState) => !prevState);
  }

  return (
    <div>
      <S.Label>{label}</S.Label>
      <S.ContainerPassword height={height} width={width}>
        <S.ContainerInputPassword
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          <S.ContainerInputAndIcon>
            <S.InputPassword
              {...props}
              type={showPassword ? "text" : "password"}
            />

            <S.ButtonToggleVisibilityOfPassword
              type="button"
              onClick={() => handleToggleVisibilityOfPassword()}
              colorIcon={colorIcon}
            >
              {showPassword ? <Eye size={25} /> : <EyeSlash size={25} />}
            </S.ButtonToggleVisibilityOfPassword>
          </S.ContainerInputAndIcon>

          <S.ErrorMessagem>{errorMessage}</S.ErrorMessagem>
        </S.ContainerInputPassword>
      </S.ContainerPassword>
    </div>
  );
}
