import { Dispatch, SetStateAction } from "react";
import * as S from "../../styles";
import { t } from 'i18next'

interface IResultOfChallenge {
  feedback: string;
  wasEvaluted: boolean;
  setFeedback: Dispatch<SetStateAction<string>>;
}

export function ResultOfChallenge({
  wasEvaluted,
  feedback,
  setFeedback
}: IResultOfChallenge) {
  return (
    //pendente = false
    //reprovado = true
    <S.ContainerResultOfChallenge>
      <S.SubtitleResultOfChallenge>
        {t('evaluate.challengeFeedback')}
      </S.SubtitleResultOfChallenge>
      <S.TextArea
        value={feedback}
        placeholder={t('evaluate.comment')}
        onChange={(event) => setFeedback(event.target.value)}
        rows={5}
        maxLength={400}
        //required={wasEvaluted ? false : true}
        disabled={wasEvaluted ? true : false}
      />
    </S.ContainerResultOfChallenge>
  );
}
