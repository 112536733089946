/* eslint-disable no-new */
import React, {useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Fab,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuList,
  MenuItem,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {t} from "i18next";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import EmailIcon from '@mui/icons-material/Email';
import ContactModal from "../../components/ContactModal";

interface HelpTopic {
  title: string;
  content: string;
}

const helpTopics: HelpTopic[] = [
  {
    title: 'O que são “Aquários”?',
    content: 'Aquário é como chamamos os Bancos de Talentos na SkillHunter. Nos ' +
      'Aquários você pode “pescar‘ os melhores candidatos pré-qualificados para a ' +
      'posição desejada com desafios criados por quem realmente entende do ' +
      'assunto utilizando dos mais diversos filtros disponibilizados.',
  },
  {
    title: 'Qual a diferença entre Trilha e Cargo?',
    content: 'Cargos: São voltados a encontrar candidatos para posições/vagas internas ' +
      'ou externas, direcionados a posições de trabalho na organização. Podem ser ' +
      'utilizados para preencher posições internas com colaboradores da própria ' +
      'empresa, descrevendo as necessidades e filtrando de acordo com as ' +
      'habilidades.<br /><br />' +
      'Diferem-se de vagas por estarem sempre abertos e recebendo ' +
      'candidatos para quando necessário ”Pesca-los”.<br /><br />' +
      'Trilhas: Funcionam de forma semelhante aos cargos, mas tem o objetivo ' +
      'apenas de pré-filtrar e/ou comprovar conhecimento, não voltado ' +
      'necessariamente ao preenchimento de uma vaga ou posição. Pode ser ' +
      'utilizada para comprovar conhecimento após um curso, para aprimoramento ' +
      'técnico, para onboarding, etc.',
  },
  {
    title: 'Quem cria os Desafios vinculados às Habilidades?',
    content: 'Todos os desafios vinculados às habilidades são criados cuidadosamente por ' +
      'um time especializado com conhecimento técnico e com domínio no assunto, ' +
      'para gerar a maior assertividade possível e garantir que os candidatos ' +
      'realmente sejam pré-filtrados com qualidade. ',
  },
  {
    title: 'Qual a diferença entre competência e habilidade?',
    content: 'Na Skillhunter as competências são conjuntos de habilidades, ou seja, são ' +
      'uma visão macro de conhecimentos que o candidato precisa se desafiar para ' +
      'provar que possui. As habilidades são subdivisões mais específicas destas ' +
      'competências, dividindo o conhecimento em porções menores. Um cargo ' +
      'possui várias competências, e uma competência possui várias habilidades.',
  },
  {
    title: 'Como vejo as habilidades e competências exatas que o candidato ' +
      'completou?',
    content: 'É muito simples, basta clicar no ícone “Ver progresso do usuário”, indicado ' +
      'pela letra “i” ao lado direito das informações de cada candidato no aquário. ' +
      'Na tela que irá surgir você consegue analisar o progresso individual por ' +
      'competência e por habilidade do candidato.',
  },
  {
    title: 'Como vejo as respostas que o candidato deu para cada desafio?',
    content: 'Caso você precise de mais detalhes quanto ao desempenho do candidato, ' +
      'você pode ver cada pergunta feita e a resposta dada pelo candidato nos ' +
      'desafios. Basta clicar no ícone “Ver progresso do usuário”, indicado pela letra ' +
      '“i” ao lado direito das informações de cada candidato no aquário e após clicar ' +
      'na habilidade desejada. Irá abrir uma tela com as informações das questões, ' +
      'respostas e acertos do candidato de forma completa.',
  },
  {
    title: 'O que é “Status do RH”?',
    content: 'O Status do RH é uma forma de facilitar seu processo de seleção. Através do ' +
      'ícone de edição do status ao lado direito de cada candidato você consegue ' +
      'selecionar Status em que o candidato se encontra em seu processo interno, ' +
      'como por exemplo, “Em Análise”. Assim, sempre que retomar o processo ' +
      'será mais fácil filtrar os candidatos de acordo com cada etapa, utilizando o ' +
      'filtro “Status do RH”.',
  },
  {
    title: 'O que é o ícone de “Adicionar a Blocklist”?',
    content: 'Através deste ícone você pode “remover” candidatos da sua lista de seleção ' +
      'por qualquer motivo que seja, assim ele não será mais exibido nas ' +
      'pesquisas. Você pode remover o candidato da lista através do menu Minhas ' +
      'Empresas > Blocklist na esquerda de seu portal.',
  },
  {
    title: 'Onde vejo os candidatos vinculados a meus cargos e trilhas internas?',
    content: 'Basta selecionar o nome de sua empresa na seleção de Aquários, indicado ' +
      'por “Selecione o aquário de talentos a ser filtrado”. Assim, os filtros serão ' +
      'aplicados apenas a cargos e trilhas criadas para sua empresa. ',
  },
  {
    title: 'Eu consigo analisar o currículo dos candidatos?',
    content: 'Sim, você poderá analisar o currículo dos candidatos, quando enviados por ' +
      'estes, assim que desbloqueá-los, através da opção “Ver dados do usuário” ' +
      'ao lado direito na lista de candidatos.',
  },
  {
    title: 'Eu pago para pesquisar candidatos dos Aquários?',
    content: 'Não há cobranças para pesquisar candidatos dos Aquários, você pode ' +
      'visualizar o progresso de todos os candidatos vinculados aos cargos sem ' +
      'custos. Você investirá apenas após decidir qual ou quais candidatos deseja ' +
      'desbloquear. Comprando créditos e os utilizando para desbloqueio.',
  },
  {
    title: 'Não achei o cargo ou trilha que eu queria, o que eu faço?',
    content: 'Estamos constantemente evoluindo nossa base de cargos e junto a eles de ' +
      'candidatos pré-qualificados de todas as áreas, mas não se preocupe, se ' +
      'precisa de um cargo específico e pensado exclusivamente para você, fale ' +
      'conosco! Temos uma consultoria especializada pronta para lhe atender e ' +
      'oferecer o serviço de criação de um cargo externo. Solicite a criação através ' +
      'do Aquário, no filtro de cargos, ou clicando no menu Cargos à direita do ' +
      'Portal e após em “Solicitar cargo personalizado”.',
  },
  {
    title: 'Posso criar meu próprio cargo?',
    content: 'Caso você tenha a opção de criação de cargos internos habilitada, acesse ' +
      'nosso Manual - Criação de cargos internos para ver em detalhes como pode ' +
      'criar seus próprios cargos para seleção interna de sua empresa. Caso ' +
      'precise de cargos externos próprios solicite a criação através do Aquário, no ' +
      'filtro de cargos, ou clicando no menu Cargos à direita do Portal e após em ' +
      '“Solicitar cargo personalizado”.',
  },
  {
    title: 'O que é recrutamento interno e externo?',
    content: 'Recrutamento interno se refere ao que você pode criar cargos e trilhas ' +
      'direcionados a colaboradores de sua empresa, para movimentações ou ' +
      'testes de conhecimentos internos apenas, sem candidatos do mercado. O ' +
      'recrutamento externo é relacionado a cargos para preenchimento de ' +
      'posições de sua empresa, que recebem candidatos do mercado.',
  },
  {
    title: 'O que são créditos de desbloqueio?',
    content: 'Para desbloquear candidatos nos Aquários você precisa de créditos, estes ' +
      'créditos são a moeda de troca entre a empresa contratante e a SkillHunter, é ' +
      'uma quantidade específica de candidatos que você pode desbloquear de ' +
      'acordo com o pacote comprado de créditos. Para comprar créditos é muito ' +
      'simples, basta acessar o menu da esquerda no portal Minhas Empresas > ' +
      'Gestão de Créditos e escolher o pacote desejado ou entrar em contato ' +
      'conosco para um valor personalizado!',
  },
  {
    title: 'Como verificar minhas transações e quantos créditos eu tenho?',
    content: 'Acesse o menu Minhas Empresas > Transações. Nesta área você pode ' +
      'verificar o número exato de créditos de desbloqueio que tem disponível, além ' +
      'de verificar seu histórico de transações, analisando suas compras.',
  },
  {
    title: 'O que é o Dashboard?',
    content: 'Esta é uma tela de apoio para você recrutador. Nela você tem acesso a ' +
      'dados rápidos e estatísticas sobre as movimentações dentro da SkillHunter, ' +
      'como o número de candidatos, a taxa de sucesso em desafios e os cargos ' +
      'mais acessados. Utilize a tela para tomar decisões rápidas e assertivas. ',
  },
  {
    title: 'Como edito os dados da minha empresa?',
    content: 'Acesse a opção Minhas Empresas > Empresas no menu da esquerda no ' +
      'portal. Nesta área você consegue verificar os dados de suas empresas ' +
      'cadastradas, além de poder editar seus dados através do ícone do lápis ao ' +
      'lado direito de cada uma.',
  },
];


function Help() {

  const [openContactModal, setOpenContactModal] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openIconMenu = Boolean(anchorEl);

  const handleOpenIconMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleCloseIconMenu = () => {
    setAnchorEl(null);
  }
  return (
    <Container style={{marginBottom: "2rem", marginTop: "1rem"}}>
      <Typography variant="h4">
        FAQ - Dúvidas mais Frequentes
      </Typography>
      {helpTopics.map((topic, index) => (
        <div key={index} id={`topic-${index}`} style={{marginTop: '20px'}}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{topic.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography dangerouslySetInnerHTML={{__html: topic.content}}>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <Fab
        onClick={handleOpenIconMenu}
        color="secondary"
        aria-label="help"
        variant="extended"
        sx={{
          position: 'fixed',
          bottom: {xs: '80px', lg: '20px'},
          right: {xs: '10px', lg: '20px'},
        }}
      >
        <HelpOutlineOutlinedIcon sx={{fontSize: '30px', mr: 1}}/>
        {t('helpMenu.manualsContact')}
      </Fab>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openIconMenu}
        onClose={handleCloseIconMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              window.open('/SkillHunter - Criando Cargos ou Trilhas Internas.pdf', '_blank')
              handleCloseIconMenu()
            }}
          >
            <ListItemIcon>
              <ArticleIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{t("helpMenu.positionManual")}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.open('/SkillHunter - Pescando Candidatos.pdf', '_blank')
              handleCloseIconMenu()
            }}
          >
            <ListItemIcon>
              <ArticleIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{t("helpMenu.fishing")}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenContactModal(true)
              handleCloseIconMenu()
            }}
          >
            <ListItemIcon>
              <EmailIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>{t("helpMenu.getInTouch")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <ContactModal
        open={openContactModal}
        onClose={() => setOpenContactModal(false)}
      />
    </Container>
  );
}

export default Help;