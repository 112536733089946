import { InfoOutlined } from "@mui/icons-material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Tooltip } from "@mui/material";
import { t } from "i18next";
import moment from "moment";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import * as S from "./styles";

export function CardsPositions({
  item,
  onClick,
  equal,
  isCourse,
  ref,
}: {
  equal?: boolean;
  onClick?: any;
  item?: any;
  isCourse?: boolean;
  ref?: any;
}) {
  const timePassed = (createdAt) => {
    const createdAtMoment = moment(createdAt, "DD/MM/YYYY HH:mm:ss");
    const today = moment();
    const diff = today.diff(createdAtMoment, "days"); // Calcula a diferença em dias
    const weeks = Math.floor(diff / 7); // Calcula o número de semanas completas

    if (weeks > 0) {
      return `Há ${weeks} semana(s)`;
    } else {
      return `Há ${diff} dia(s)`;
    }
  };

  const renderAddresses = (city, state) => {
    const mainAddress = `${city !== null ? city?.name + "," : ""} ${
      state !== null ? state?.fu?.toString().toUpperCase() : ""
    }`;

    return (
      <S.ContainerLocation>
        <LocationOnOutlinedIcon />
        {mainAddress}
      </S.ContainerLocation>
    );
  };

  return (
    <S.ContainerCardsTrainingPlans ref={ref} equal={equal} onClick={onClick}>
      <S.ContentCard>
        <S.ContentCardTitle>
          <S.Title>{item?.title}</S.Title>
          <S.Visible>
            <S.CompanyIcon
              src={
                !isCourse
                  ? item?.company?.avatar || skillHunterPositionIcon
                  : item?.company?.icon?.fileUrl || skillHunterPositionIcon
              }
              alt=""
            />
            <S.CompanyName>
              {item?.company?.name || "Skill Hunter"}
              {item?.company?.id === -1 && (
                <Tooltip
                  sx={{ p: 0.4, m: 0, ml: 0.4, mb: -1 }}
                  title={t("positions.skillhunterTooltip")}
                  placement="top"
                >
                  <InfoOutlined />
                </Tooltip>
              )}
            </S.CompanyName>
          </S.Visible>
        </S.ContentCardTitle>
        <S.ContentCardSubtitle>
          <S.SubTitle>{item?.category?.name}</S.SubTitle>
        </S.ContentCardSubtitle>
      </S.ContentCard>

      {!isCourse && (
        <S.FooterCard>
          {item?.formationModality === "ONSITE" ? (
            item?.address?.length === 0 ? (
              <S.ContainerLocation>
                <LocationOnOutlinedIcon />
                {t("positions.onsite")}
              </S.ContainerLocation>
            ) : (
              renderAddresses(item?.city, item?.state)
            )
          ) : item?.formationModality === "HYBRID" ? (
            item?.address?.length === 0 ? (
              <S.ContainerLocation>
                <LocationOnOutlinedIcon />
                {t("positions.hybrid")}
              </S.ContainerLocation>
            ) : (
              renderAddresses(item?.city, item?.state)
            )
          ) : (
            <S.ContainerLocation>
              <LocationOnOutlinedIcon />
              {t("positions.homeOffice")}
            </S.ContainerLocation>
          )}
          <S.ContainerLocation>
            {timePassed(item?.createdAt)}
          </S.ContainerLocation>
        </S.FooterCard>
      )}
    </S.ContainerCardsTrainingPlans>
  );
}
