import {InfoOutlined} from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import rightButton from "../../components/assets/rightButton.svg";
import treeBlackIcon from "../../assets/TreeBlackIcon.svg";
import TreeWhiteIcon from "../../assets/TreeWhiteIcon.svg";
import SetaGray from "../../assets/setaGray.svg";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import Breadcrumb from "../../components/Breadcrumb/index";
import {Button} from "../../components/Button";
import CardTree from "../../components/CardTree";
import DefaultLoading from "../../components/Loading/defaultLoading";
import {ProgressBar} from "../../components/Progress";
import FormationService from "../../services/FormationService";
import SkillTreesService from "../../services/SkillTreeService";
import star from "./images/star.svg";
import * as S from "./style";
import {TextCompany} from "./style";
import StarIcon from "@mui/icons-material/Star";
import {t} from "i18next";
import {enqueueSnackbar} from "notistack";

export default function PositionPreview() {
  const {positionSlug} = useParams();
  const [isSubscribed, setIsSubscribed] = useState<any>(false);
  const [resultDashBoard, setResultDashBoard] = useState<any>();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [subscribing, setSubscribing] = useState<boolean>(false);

  const progress = resultDashBoard?.progress;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handlePreview = () => {
    enqueueSnackbar(t("positions.itsPreview"), {variant: "warning"})
  }

  const getColor = (status: any) => {
    switch (status) {
      case "ACHIEVED":
        return "#00A676";
      case "UNLOCKED":
      case "STARTED":
        return "#302382";
      case "WAITING_EVALUATION":
        return "#FF9900";
      default:
        return "#C4C4C4";
    }
  };

  const fetchData = async () => {
    const restDashboard = await FormationService.getDashboard(positionSlug);
    setResultDashBoard(restDashboard?.data);
    setIsSubscribed(restDashboard?.data?.isSubscribed ?? false);
  };

  useEffect(() => {
    fetchData();
  }, [isSubscribed]);

  const [treeLoading, setTreeLoading] = useState(false);

  const [competence, setCompetence] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setTreeLoading(true);
        if (expanded && expanded.toString() !== "panel1") {
          const result = await SkillTreesService.getSkillTreeSkillsByFormation(
            expanded,
            positionSlug
          );
          setCompetence(result.skills);
        }
      } catch (err) {
        setTreeLoading(false);
        console.error(err);
      }
      setTreeLoading(false);
    })();
  }, [expanded, isSubscribed]);

  function formatCurrency(value) {
    if (value == null) {
      return "";
    }
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  }

  return (
    <S.Container>
      <S.ContainerTotal>
        <S.ContainerNavigatePositions>
          <Breadcrumb
            preview
            currentPage={resultDashBoard?.title}
            previousPage={[{value: t("positions.position"), link: "/home"}]}
          />
        </S.ContainerNavigatePositions>

        <S.ContainerButtonAndTitle>
          <S.TitlePosition>{resultDashBoard?.title}</S.TitlePosition>
          <S.MiniButtonContainer data-cy="trailPageSubscribeButton">
            {!isSubscribed && (
              <Button
                loading={subscribing}
                onClick={handlePreview}
                data-cy="subscribeButton"
              >
                {t("positions.enroll")}
              </Button>
            )}
            {isSubscribed && progress !== 100 && (
              <Button disable data-cy="subscribeButton">
                {t("positions.enrolled")}
              </Button>
            )}
            {progress === 100 && (
              <S.ButtonConclude data-cy="subscribeButton">
                  <span>
                    <img src={rightButton} alt=""/>
                  </span>
                {t("positions.concluded")}
              </S.ButtonConclude>
            )}
          </S.MiniButtonContainer>
        </S.ContainerButtonAndTitle>
        <TextCompany>
          {t("positions.modality")}: {t(resultDashBoard?.jobModality)}
        </TextCompany>
        <br/>
        {resultDashBoard?.company?.id != -1 ? (
          <S.EmployPosition>
            <S.ImageCompany
              src={
                resultDashBoard?.company?.avatar || skillHunterPositionIcon
              }
              alt=""
            />
            <TextCompany>{resultDashBoard?.company?.name}</TextCompany>
          </S.EmployPosition>
        ) : (
          <S.EmployPosition>
            <S.ImageCompany src={skillHunterPositionIcon} alt=""/>
            <TextCompany>Skill Hunter</TextCompany>
            <Tooltip
              title={t("positions.skillhunterTooltip")}
              placement="top"
            >
              <InfoOutlined sx={{opacity: 0.5}}/>
            </Tooltip>
          </S.EmployPosition>
        )}

        <S.ContainerButton>
          {!isSubscribed && (
            <Button
              loading={subscribing}
              onClick={handlePreview}
              data-cy="subscribeButton"
            >
              {t("positions.enroll")}
            </Button>
          )}
          {isSubscribed && progress !== 100 && (
            <Button disable>{t("positions.enrolled")}</Button>
          )}
          {progress === 100 && isSubscribed && (
            <S.ButtonConclude>
                <span>
                  <img src={rightButton} alt=""/>
                </span>
              {t("positions.concluded")}
            </S.ButtonConclude>
          )}
        </S.ContainerButton>

        {resultDashBoard?.categories?.map((item) => {
          return <S.CategoriesPosition>{item?.name}</S.CategoriesPosition>;
        })}

        <S.ContainerIconTreeWithCompetences>
          <img
            src={treeBlackIcon}
            alt="Imagem da competência"
            style={{height: 20}}
          />
          <S.containerCompetences>
            <S.NumberSkillTree>
              {resultDashBoard?.skillTreesCount}
            </S.NumberSkillTree>
            <p>{t("positions.competences")}</p>
          </S.containerCompetences>
        </S.ContainerIconTreeWithCompetences>

        <S.TitleAboutPosition>{t("positions.about")}</S.TitleAboutPosition>

        <S.DescriptionPosition
          dangerouslySetInnerHTML={{__html: resultDashBoard?.description}}
        />
        {(resultDashBoard?.positionMinSalary ||
          resultDashBoard?.positionMaxSalary) && (
          <>
            <S.TitleAboutPosition>{t("positionsManagement.salaryRange")}</S.TitleAboutPosition>
            <p>
              {formatCurrency(resultDashBoard?.positionMinSalary)} -{" "}
              {formatCurrency(resultDashBoard?.positionMaxSalary)}
            </p>
          </>
        )}
        <S.TitleAboutPosition>
          {t("positions.jobLocations")}
        </S.TitleAboutPosition>
        {resultDashBoard?.adressess &&
          resultDashBoard?.adressess.map((address) => {
            return <S.TextCompany>{address}</S.TextCompany>;
          })}

        <S.ContainerProgressCard>
          <S.ContainerProgressStarText>
            <img src={star} alt="star_progress_icon" style={{width: 50}}/>
            <S.ContainerTitlePercent>
              <S.ProgressTitleSubtitle>
                <S.Title>{t("positions.overallProgress")}</S.Title>
                <S.ProgressSubtitle>
                  {t("positions.complete")}
                </S.ProgressSubtitle>
              </S.ProgressTitleSubtitle>
              <S.PercentText>{resultDashBoard?.progress}%</S.PercentText>
            </S.ContainerTitlePercent>
          </S.ContainerProgressStarText>
          <ProgressBar
            value={resultDashBoard?.progress || 0}
            max={100}
            isChallenge
          />
        </S.ContainerProgressCard>
        <S.ContainerAccordions>
          {resultDashBoard?.skillTrees.map((comp, i) => (
            <Accordion
              disableGutters
              expanded={expanded === comp?.slug}
              onChange={handleChange(comp?.slug)}
            >
              <AccordionSummary
                data-cy="positionAcordion"
                aria-controls={`${comp?.id}d-content`}
                id={`${comp?.id}d-header`}
              >
                <S.ContainerAccordionSummary>
                  <S.ContainerAccordionSummaryLeft>
                    <S.AccordionSummaryCircleImg
                      color={getColor(comp?.status || "UNLOCKED")}
                    >
                      <S.AccordionSummaryImg
                        src={TreeWhiteIcon}
                        alt="TreeIcon"
                      />
                    </S.AccordionSummaryCircleImg>
                    <S.ContainerAccordionSummaryLeftTexts>
                      <S.TitleAccordionSummary>
                        {comp?.name}
                      </S.TitleAccordionSummary>

                      <S.SubtitleAccordionSummary
                        color={getColor(comp?.status || "UNLOCKED")}
                      >
                        {t("positions.progress")}: {comp?.progress || 0}%
                      </S.SubtitleAccordionSummary>
                    </S.ContainerAccordionSummaryLeftTexts>
                  </S.ContainerAccordionSummaryLeft>
                  <div
                    style={{display: "flex", gap: 12, alignItems: "center"}}
                  >
                    {comp?.mandatory && (
                      <Tooltip
                        title={t("positions.mandatory")}
                        placement="top"
                      >
                          <span>
                            <StarIcon sx={{color: "gold"}}/>
                          </span>
                      </Tooltip>
                    )}
                    <S.AccordionSummarySetaImg
                      up={expanded === comp?.id}
                      src={SetaGray}
                      alt="Seta"
                    />
                  </div>
                </S.ContainerAccordionSummary>
              </AccordionSummary>
              <AccordionDetails sx={{p: 0, m: 0}}>
                {treeLoading ? (
                  <S.ContainerAccordionDetailsLoading>
                    <DefaultLoading color="#898989"/>
                    <S.LoadingText>{t("positions.loading")}...</S.LoadingText>
                  </S.ContainerAccordionDetailsLoading>
                ) : (
                  <S.ContainerAccordionDetails>
                    {competence?.length > 0 ? (
                      <CardTree
                        skills={competence}
                        onClick={handlePreview}
                      />
                    ) : (
                      <S.WithoutSkills>
                        {t("positions.noChallenge")}
                      </S.WithoutSkills>
                    )}
                  </S.ContainerAccordionDetails>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </S.ContainerAccordions>
      </S.ContainerTotal>
    </S.Container>
  );
}
