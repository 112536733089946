import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  margin-bottom: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: #7e7d7d;
`;

export const ContainerInput = styled.input<any>`
  cursor: pointer;
  outline: none !important;
  height: ${({style}) => style?.height || '42px'};
  background-color: #E85C20;
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border-radius: 3px 3px 0 0;
  padding: 11px 36px 11px 16px;
  background-position: calc(100% - 8px) 50%;
  background-repeat: no-repeat;
  ::placeholder {
    color: #FFFFFF;
  }
`;
