import { Cancel, CheckCircle } from "@mui/icons-material";
import { Checkbox, Dialog, Radio } from "@mui/material";
import styled from "styled-components";

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    width: 45% !important;
    max-width: none; 
  }

  @media (max-width: 425px) {
    .MuiDialog-paper {
      width: 90% !important;
      max-width: none; 
    }
  }

  @media only screen and (min-width: 426px) and (max-width: 1000px) {
    .MuiDialog-paper {
      width: 70% !important;
      max-width: none;
    }
  }
`;

export const AnchorButton = styled.a`
  text-decoration: none;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const Question = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FF318A;
`;

export const ContainerResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconText = styled.div<{
  isCorrect: boolean;
  isIncorrect: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isCorrect ? '#04A575' : props.isIncorrect ? 'red' : 'inherit')};
  margin-right: 4px;
`;

export const Result = styled.p`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const RadioStyled = styled(Radio)`
  color: #FF318A !important;
`;

export const CheckboStyled = styled(Checkbox)`
  color: #FF318A !important;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0.75rem
`;

export const CorrectIcon = styled(CheckCircle)`
  color: #04A575;
  width: 22px;
  height: 22px;
  margin-right: 4px;
`;

export const IncorrectIcon = styled(Cancel)`
  color: red;
  width: 22px;
  height: 22px;
  margin-right: 4px;
`;