import styled from "styled-components";

interface IPropsTextArea {
  borderColor: any;
}

export const Form = styled.form`
  button[type="button"] {
    /* margin-top: 2.3rem;
    margin-bottom: 2.3rem; */
  }

  button[type="submit"] {
    margin-top: 2.3rem;
  }
`;

export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 32px;
`;

export const FullModal = styled.div`
  font-family: "Ubuntu";
  width: 852px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 42px;
  border-radius: 3px;
  text-align: center;
  max-height: 98%;
  overflow-y: auto;
  transition: height 0.5s ease-in-out;

  & > * {
    max-height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  &:focus {
    outline: none !important;
  }

  &.expanded {
    height: 100%;
  }

  &.collapsed {
    height: 750px;
  }

  @media screen and (max-width: 600px) {
    padding: 25px 21px;
  }
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 25px;
  background: white;
  border: 1px solid ${({borderColor}) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #8c8c8c;
  }
`;

export const ContainerGap = styled.div`
  margin-top: 2.3rem;

  p {
    font-size: 14px;
  }
`;

export const ContainerDropDownSimpleSelect = styled.div`
  margin-top: 37px;
  margin-bottom: 37px;

  p {
    font-size: 14px;
  }

  img {
    position: relative;
    left: 60px;
    top: 5px;
  }
`;

export const BoxSelect = styled.div`
  min-height: 40px;
  width: 42%;
  display: flex;
  margin: 10px;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 38px;
  p {
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 10px;
    color: #696969;
    border: 1px solid #8c8c8c;
    margin-top: 10px;
    display: flex;
    gap: 6px;
    margin-bottom: 12px;
    span {
      margin-top: 2px;
    }
  }
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const Row = styled.div`
  display: flex;
`;

export const DefaultFormLabel = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 14px;
  text-align: start;
  line-height: 20px;
  color: #8c8c8c;
`;

export const RowModal = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1049px) {
    margin-bottom: 12px;
  }
`;

export const ErrorMessage = styled.p`
  margin: 0.3rem 0;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #f5222d;
`;

export const RowModalGap = styled.div`
  margin-top: 24px;
  width: 100%;
  gap: 16px;
  display: flex;
`;

export const RemainSpace = styled.div`
  width: 100%;
`;

export const InputRow = styled.div<{rowsSize: number[]}>`
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 16px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;
