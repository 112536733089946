import {yupResolver} from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import {t} from "i18next";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {enqueueSnackbar} from "notistack";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import PhoneInput, {
  formatPhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input/input";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import AvatarInput from "../../components/AvatarInput";
import {ResponsiveInputText} from "../../components/ResponsiveInputText";
import {Select} from "../../components/Select";
import {UploadFile} from "../../models/UploadFile";
import {User} from "../../models/User";
import api, {axiosApi} from "../../services/api";
import FileService from "../../services/FileService";
import UserService from "../../services/UserService";
import {IUserClient} from "../../types/UserClient";
import UploadIcon from "./assets/UploadIcon";
import wrong from "./assets/wrong.png";
import ModalDeleteAccount from "./components/ModalDeleteAccount";

import * as S from "./style";
import {kStringMaxLength} from "buffer";

export interface IUsers {
  aboutMe: string;
  addressZipCode: string;
  birthDate: string;
  name: string;
  lastName: string;
  email: string;
  phone: string;
  avatarId?: UploadFile;
  curriculoId?: UploadFile;
  code?: String;
  userInterests?: any[];
}

enum ITypeInterest {
  TRAIL = "TRAIL",
  POSITION = "POSITION",
}

interface Iinterests {
  id: number;
  name: string;
  InterestType: ITypeInterest;
}

export default function UserEdit() {
  const [loadingUser, setLoadingUser] = useState(false);
  const navigate = useNavigate();
  const [loadingCep, setLoadingCep] = useState(false);
  const [interests, setInterests] = useState([]);
  const [user, setUser] = useState<any>();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [curriculoWithFile, setCurriculoWithFile] = useState<boolean>(false);
  const [fileId, setFileId] = useState<number>();
  const [imageDataUrl, setImageDataUrl] = useState<string>("");
  const [stateSelected, setStateSelected] = useState("");
  const [citySelected, setCitySelected] = useState<any>();
  const [disableAddress, setDisableAddress] = useState<boolean>(true);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [receiveEmails, setReceiveEmails] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [oldPhone, setOldPhone] = useState("");
  const [modalDeleteAccount, setModalDeleteAccount] = useState(false);
  const [countrySelected, setCountrySelected] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [responseCity, setResponseCity] = useState('')

  const labels = {
    goBack: t("signUp.backToLogin"),
    add: t("users.save"),
    user: {
      id: "ID",
      userInterests: t("signUp.interests"),
      aboutMe: t("signUp.aboutMe"),
      password: t("signUp.password"),
      confirmPassword: t("signUp.confirmPassword"),
      avatar: t("signUp.personalPhoto"),
      birthDate: t("signUp.dateOfBirth"),
      code: t("signUp.code"),
      stateId: t("signUp.state"),
      curriculoId: t("signUp.resume"),
    },
  };

  async function getCep(text) {
    try {
      const cep = text.replace("-", "");
      const prevCep = String(getValues("addressZipCode")).replace("-", "");

      if (cep?.length === 8) {
        setLoadingCep(true);
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );
        setResponseCity(response.data?.city?.toLowerCase())
        setLoadingCep(false);
        const state = states.find(
          (item) =>
            item.fu?.toLowerCase() === response.data?.state?.toLowerCase()
        );
        setStateSelected(state?.id?.toString());
        setValue("stateId", state?.id || getValues("stateId"));
        setDisableAddress(true);
        return true;
      } else if (cep?.length === 7) {
        if (prevCep?.length === 8) {
          setValue("stateId", "");
          setValue("cityId", "");
        }
        return false;
      }
    } catch (err) {
      console.error(err);
      setDisableAddress(false);
      setLoadingCep(false);
      return false;
    }
  }

  async function getCities() {
    try {
      if (stateSelected) {
        const citiesResponse = await axiosApi.get(
          `/address/cities?stateId=${stateSelected}`
        );
        setCities(citiesResponse?.data);
        return citiesResponse?.data;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function getCountries() {
    try {
      const countriesResponse = await api.get(`/address/countries`);
      setCountries(countriesResponse?.data);
      return countriesResponse?.data;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  const getEthniciyOptions = () => {
    return [
      {
        id: "NAO_INFORMADO",
        name: t('users.noInform'),
      },
      {
        id: "BRANCA",
        name: t('users.white'),
      },
      {
        id: "PRETA",
        name: t('users.black'),
      },
      {
        id: "PARDA",
        name: t('users.brown'),
      },
      {
        id: "AMARELA",
        name: t('users.asian'),
      },
      {
        id: "INDIGENA",
        name: t('users.indigenous'),
      },
    ]
  }

  const getGenderOptions = () => {
    return [
      {
        id: "NAO_INFORMADO",
        name: t('users.noInform'),
        value: 1
      },
      {
        id: "MASCULINO",
        name: t('users.male'),
        value: 2
      },
      {
        id: "FEMININO",
        name: t('users.female'),
        value: 3
      },
      {
        id: "OUTRO",
        name: t('users.others'),
        value: 4
      },
    ];
  };

  function parsePhoneNumber(phone: any) {
    if (phone) {
      const phoneNumber = parsePhoneNumberFromString(phone, countrySelected?.id === 10 ? "AR" : "BR");
      return phoneNumber?.number;
    }
    return "";
  }

  const schema = Yup.object({
    name: Yup.string()
    .min(1, t("signUp.validFirstName"))
    .max(255, t("users.nameMaxCharacters"))
    .required(t("signUp.validFirstName")),
    lastName: Yup.string()
    .min(1, t("signUp.validLastName"))
    .max(255, t("users.lastNameMaxCharacters"))
    .required(t("signUp.validLastName")),
    addressDistrict: Yup.string().required(t("users.validAddress")),
    addressSecond: Yup.string().optional().nullable(),
    phone: Yup.string()
    .max(28, t("users.phoneMaxCharacters"))
    .required(t("users.validPhoneNumber"))
    .test("is-valid-phone", t("users.validPhoneNumber"), (value) => {
      return (
        value &&
        isPossiblePhoneNumber(value, countrySelected?.id === 10 ? "AR" : "BR") &&
        isValidPhoneNumber(value, countrySelected?.id === 10 ? "AR" : "BR")
      );
    }),
    email: Yup.string()
    .email(t("users.validEmail"))
    .max(255, t("users.emailMaxCharacters"))
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("users.validEmail"))
    .required(t("users.validEmail")),
    aboutMe: Yup.string().optional(),
    birthDate: Yup.string()
    .nullable()
    .test("signUp.is-valid", t("signUp.validDateOfBirth"), (value) => {
      if (!value) {
        return true;
      }
      const date = new Date(value);
      return !isNaN(date.getTime());
    })
    .test("signUp.is-past", t("signUp.validDateOfBirth"), (value) => {
      if (!value) {
        return true;
      }
      const [year, month, day] = value.split("-");
      let birthDate = `${year}-${month}-${day}T00:00:00`;
      const date = new Date(birthDate);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return currentDate > date;
    }),
    addressZipCode: Yup.string()
    .min(countrySelected?.id === 10 ? 4 : 8, t("users.validZipCode"))
    .required(t("users.validZipCode")),
    state: Yup.string(),
    city: Yup.string(),
    code: Yup.string().min(6, t("backend.invalidCode")),
    country: Yup.object().required(t("users.validCountry")),
  });

  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: {errors},
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  function formatarData(data) {
    if (data) {
      const dia = data.slice(0, 2);
      const mes = data.slice(2, 4);
      const ano = data.slice(4, 8);

      return data.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, "$3-$2-$1");
    }
  }

  const handleEdit = async (data: any) => {
    let birthDate = data.birthDate;
    let avatarId = data?.avatarId;
    let curriculoId;

    if (data?.avatarId === null || data?.avatarId === undefined) {
      avatarId = null;
    } else {
      try {
        const resultImages: any = await FileService.imagesUpload([avatarId]);
        avatarId = resultImages?.data[0];
      } catch (e) {
        return;
      }
    }
    if (!data?.curriculoId) {
      curriculoId = null;
    } else if (typeof getValues("curriculoId") !== "object") {
      curriculoId = getValues("curriculoId");
    } else {
      const [curriculoResponse] = await FileService.sendFileDocument([
        data?.curriculoId?.newBlob || getValues("curriculoId").newBlob,
      ]);
      curriculoId = curriculoResponse;
    }
    const userDTO: IUserClient = {
      name: data.name,
      lastName: data.lastName,
      email: data.email,
      phone: parsePhoneNumber(data.phone),
      code: data.code,
      avatarId: avatarId,
      birthDate: birthDate,
      aboutMe: data.aboutMe,
      cityId: data.cityId,
      stateId: data.stateId,
      curriculoId: curriculoId,
      addressZipCode: data.addressZipCode,
      addressDistrict: data.addressDistrict,
      addressSecond: data.addressSecond,
      gender: data?.gender?.id,
      ethnicity: data?.ethnicity?.id,
      countryId: data?.country?.id,
      receiveEmails: receiveEmails,
    };

    try {
      setLoading(true)
      const userPayload: any = {...userDTO};
      if (
        userPayload?.company != null &&
        typeof userPayload?.company !== "undefined"
      ) {
        delete userPayload?.company;
        userPayload.companyId = data.company.id;
      }
      await UserService.updateClient(userPayload, `${user.id}`);
      navigate(-1);
      enqueueSnackbar(t("users.userSuccessfullyEdited"), {
        variant: "success",
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false)
    }

  };

  const renderParams = (field) => {
    return {
      ...field,
      height: 3.375,
      placeholder: labels.user[field.name],
      label: labels.user[field.name],
      errorMessage: errors[field.name]?.message,
      borderColor: errors[field.name] ? "#F5222D" : "#8c8c8c",
    };
  };

  const inputParams = (control, fieldName: string) => {
    return {
      name: fieldName,
      defaultValue: "",
      control: control,
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getCities();
  }, [stateSelected]);

  useEffect(() => {
    if (cities) {
      const newSelectedCity = cities.find(
        (item) => item?.name?.toLowerCase() === user?.city?.toLowerCase() || item?.name?.toLowerCase() === responseCity
      )?.id;
      setCitySelected(newSelectedCity);
      setValue("cityId", newSelectedCity);
    }
  }, [cities, user?.city]);

  useEffect(() => {
    setStateSelected(null);
    getStates(countrySelected?.id)
    setDisableAddress(false)
    if (!loadingUser) {
      setValue("addressDistrict", "");
      setValue("addressSecond", "");
      setValue("addressZipCode", "");
      setValue("stateId", null);
      setValue("cityId", null);
    }
  }, [countrySelected]);

  const fetchData = async () => {
    setLoadingUser(true);
    const userEdit = await UserService.getUser();
    setUser(userEdit);

    const countryId = userEdit?.country?.id;
    const statesResponse = await getStates(countryId);
    const allCountries = await getCountries();
    const allGenders = getGenderOptions();
    const allEthnicities = getEthniciyOptions();

    const selectedState = statesResponse?.find(
      (item) => item?.name?.toLowerCase() == userEdit?.state?.toLowerCase()
    )?.id;

    setStateSelected(selectedState?.toString())

    const userCity = cities?.find(
      (item) => item?.name?.toLowerCase() == userEdit.city?.toLowerCase()
    )?.id;

    const selectedCountry = allCountries.find(
      (item) => item?.name?.toLowerCase() == userEdit.country?.name?.toLowerCase()
    );

    const selectedGender = allGenders.find(
      (item) => item?.id?.toLowerCase() == userEdit.gender?.toLowerCase()
    );

    const selectedEthnicity = allEthnicities.find(
      (item) => item?.id?.toLowerCase() == userEdit.ethnicity?.toLowerCase()
    );

    setValue("name", userEdit.name);
    setValue("lastName", userEdit.lastName);
    setValue("birthDate", formatarData(userEdit.birthDate));
    setValue("email", userEdit.email);
    setValue("phone", userEdit.phone ? parsePhoneNumber(userEdit.phone) : "");
    setOldPhone(userEdit.phone ? parsePhoneNumber(userEdit.phone) : "");
    setNewPhone(userEdit.phone ? parsePhoneNumber(userEdit.phone) : "");
    setValue("aboutMe", userEdit.aboutMe || "");
    setValue("avatarId", userEdit.avatar);
    setValue("cityId", userCity || citySelected);
    setValue("addressDistrict", userEdit.addressDistrict);
    setValue("addressSecond", userEdit.addressSecond);
    setValue("addressZipCode", userEdit.addressZipCode);
    setValue("stateId", selectedState);
    setValue("country", selectedCountry);
    setValue("gender", selectedGender);
    setValue("ethnicity", selectedEthnicity);

    setReceiveEmails(userEdit.receiveEmails);
    setFileId(userEdit?.curriculo?.id);
    if (userEdit?.curriculo) {
      setCurriculoWithFile(true);
      setValue("curriculoId", userEdit?.curriculo?.id);
    }

    if (selectedState) {
      setDisableAddress(true)
    }

    setLoadingUser(false);
  };

  function removeCurriculo() {
    setCurriculoWithFile(false);
    setValue("curriculoId", null);
  }

  async function getStates(countryId) {
    try {
      let statesResponse;
      if (!countryId) {
        statesResponse = await axiosApi.get(`/address/states`);
      } else {
        statesResponse = await axiosApi.get(`/address/states/${countryId}`);
      }
      setStates(statesResponse?.data);
      return statesResponse?.data;
    } catch (err) {
      return false;
    }
  }

  function alterCurriculo(DataUrl: string) {
    setImageDataUrl(DataUrl);
    setCurriculoWithFile(true);
  }

  async function openImageInNewTab() {
    event.preventDefault();
    await FileService.downloadFile(fileId);
  }

  async function sendCode() {
    await UserService.sendConfirmationPhone(newPhone);
    setModalConfirmation(true);
  }

  async function handleConfirmation() {
    try {
      setLoading(true)
      trigger().then((result) => {
        if (result) {
          sendCode()
        }
      })
    } catch (err: any) {
      console.error(err);
    }

  }

  function handleCountryChange(value) {
    setCountrySelected(value);
  }

  return (
    <div>
      <S.FullScreen>
        <S.FormCenter>
          <S.PageTitle>{t("signUp.profile")}</S.PageTitle>
          <S.PageSubtitle>{t("signUp.editGeneralInfo")}</S.PageSubtitle>
          <S.TextInfo>{t("signUp.basicInfo")}</S.TextInfo>
          <S.Row>
            <Controller
              {...inputParams(control, "avatarId")}
              render={({field}) => (
                <AvatarInput
                  legend={labels.user[field.name]}
                  value={field.value}
                  onChange={field.onChange}
                  signUp
                />
              )}
            />
            <S.RemainSpace>
              <S.InputRow rowsSize={[1, 1]}>
                <S.ContainerGap>
                  <S.Label>
                    {t("signUp.firstName")} <span style={{color: 'red'}}> *</span>
                  </S.Label>
                  <Controller
                    {...inputParams(control, "name")}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...renderParams(field)}
                        value={field.value}
                        title="name"
                        maxLength="30"
                      />
                    )}
                  />
                </S.ContainerGap>
                <S.ContainerGap>
                  <S.Label>
                    {t("signUp.lastName")} <span style={{color: 'red'}}> *</span>
                  </S.Label>
                  <Controller
                    {...inputParams(control, "lastName")}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...renderParams(field)}
                        value={field.value}
                        title="lastName"
                        maxLength="30"
                      />
                    )}
                  />
                </S.ContainerGap>
              </S.InputRow>
              <S.InputRow rowsSize={[6, 5, 4]}>
                <S.ContainerGap>
                  <S.Label style={{marginBottom: 0}}>
                    {t("signUp.personalEmail")} <span style={{color: 'red'}}> * </span>
                    <Tooltip
                      sx={{fontSize: "14px"}}
                      arrow
                      title={t("users.keyEmail")}
                      placement="top"
                    >
                      <InfoOutlinedIcon/>
                    </Tooltip>
                  </S.Label>
                  <Controller
                    {...inputParams(control, "email")}
                    render={({field}) => (
                      <ResponsiveInputText
                        {...renderParams(field)}
                        title="email"
                        value={field.value}
                      />
                    )}
                  />
                </S.ContainerGap>
                <S.ContainerGap>
                  <S.Label>
                    {t("signUp.phoneNumber")} <span style={{color: 'red'}}> * </span>
                    <Tooltip
                      sx={{fontSize: "14px"}}
                      arrow
                      title={t("users.keyPhone")}
                      placement="top"
                    >
                      <InfoOutlinedIcon/>
                    </Tooltip>
                  </S.Label>
                  <Controller
                    name="phone"
                    defaultValue=""
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <PhoneInput
                        value={value}
                        country={countrySelected?.id === 10 ? "AR" : "BR"}
                        defaultCountry="BR"
                        onChange={(val) => {
                          onChange(val || "")
                          setNewPhone(val || "")
                        }}
                        style={{
                          width: "100%",
                          height: "3.5rem",
                          border:
                            errors?.phone?.message?.length > 0
                              ? "1px solid #F5222D"
                              : "1px solid #8c8c8c",
                          borderRadius: "5px",
                          fontSize: "16px",
                          padding: "0 1rem",
                          fontWeight: "500",
                          outline: "none",
                        }}
                      />
                    )}
                  />
                  <S.ErrorMessage>{errors?.phone?.message}</S.ErrorMessage>
                </S.ContainerGap>
                <S.ContainerGap>
                  <S.Label>{labels.user.birthDate}</S.Label>
                  <Controller
                    name="birthDate"
                    defaultValue=""
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <S.CssTextField
                        data-cy="ScreenSignUpBirthDate"
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        type="date"
                        error={!!errors?.birthDate?.message}
                        placeholder={t("signUp.dateFormat")}
                        sx={{
                          width: "100%",
                          border:
                            errors?.birthDate?.message?.length > 0
                              ? "1px solid #F5222D"
                              : "1px solid #C4C4C4",
                        }}
                      />
                    )}
                  />
                  <S.ErrorMessage>{errors?.birthDate?.message}</S.ErrorMessage>
                </S.ContainerGap>
              </S.InputRow>

              <S.InputRow rowsSize={[1]}>
                <S.ContainerGap>
                  <S.Label>{t("users.country")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    name="country"
                    control={control}
                    render={({field: {name, value, onChange}}) => (
                      <Select
                        showSearch
                        selected={value}
                        onSelect={(newValue) => {
                          handleCountryChange(newValue)
                          onChange(newValue)
                        }}
                        placeholder={t("users.country")}
                        error={errors[name]?.message}
                        options={countries}
                      />
                    )}
                  />
                </S.ContainerGap>
              </S.InputRow>

              <S.InputRow rowsSize={[6, 5, 4]}>
                <S.ContainerGap>
                  <S.Label style={{marginBottom: 0}}>
                    {countrySelected?.id === 10 ? t("signUp.postalCode") : t("signUp.zipcode")} <span
                    style={{color: 'red'}}> *</span>
                  </S.Label>
                  <Controller
                    name="addressZipCode"
                    control={control}
                    {...inputParams(control, "addressZipCode")}
                    render={({field}) => (
                      <ResponsiveInputText
                        placeholder={countrySelected?.id === 10 ? t("signUp.postalCode") : t("signUp.zipcode")}
                        errorMessage={errors?.addressZipCode?.message}
                        disabled={loadingCep || (!countrySelected && !getValues("country"))}
                        onChange={(e) => {
                          if (countrySelected?.id !== "10") {
                            getCep(e.target.value);
                          }
                          field.onChange(e.target.value);
                        }}
                        value={field.value}
                        maxLength={countrySelected?.id === 10 ? 4 : field?.value?.includes("-") ? 9 : 8}
                      />
                    )}
                  />
                </S.ContainerGap>

                <S.ContainerGap>
                  <S.Label>{t("signUp.state")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    {...inputParams(control, "stateId")}
                    render={({field: {value, onChange}}) => {
                      const selectedState =
                        states.find((option) => option?.id === value) || null;

                      const handleStateChange = (event, value) => {
                        onChange(value ? value.id : "");
                        setStateSelected(value ? value.id : "");
                      };

                      const filterStateOptions = (options, {inputValue}) => {
                        const filteredOptions = options.filter(
                          (option) =>
                            option.fu
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase()) ||
                            option.name
                            ?.toLowerCase()
                            .includes(inputValue.toLowerCase())
                        );

                        return filteredOptions;
                      };

                      return (
                        <Autocomplete
                          id="states"
                          options={states}
                          getOptionLabel={(option) => {
                            return countrySelected?.id === 10 ? option.name : option.fu.toUpperCase()
                          }}
                          value={selectedState}
                          noOptionsText={t("noOptionsMui")}
                          onChange={handleStateChange}
                          filterOptions={filterStateOptions}
                          autoComplete={false}
                          disabled={disableAddress || !countrySelected}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: 0,
                                width: "100%",
                                height: "100%",
                                fontSize: 16,
                                fontWeight: 500,
                                outline: "none",
                                verticalAlign: "middle",
                                color: "#131212",
                                background: "#ffffff",
                              }}
                              placeholder={labels.user.stateId}
                              autoComplete="off"
                            />
                          )}
                        />
                      );
                    }}
                  />
                </S.ContainerGap>

                <S.ContainerGap>
                  <S.Label>{t("signUp.city")} <span style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    {...inputParams(control, "cityId")}
                    render={({field}) => {
                      const selectedCity =
                        cities.find((option) => option?.id === field?.value) ||
                        null;

                      const handleCityChange = (event, value) => {
                        field.onChange(value ? value.id : "");
                      };

                      const filterCityOptions = (options, {inputValue}) => {
                        const filteredOptions = options.filter((option) =>
                          option.name
                          ?.toLowerCase()
                          .includes(inputValue?.toLowerCase())
                        );

                        return filteredOptions;
                      };

                      return (
                        <Autocomplete
                          id="cities"
                          options={cities}
                          getOptionLabel={(option) => option.name}
                          value={selectedCity}
                          noOptionsText={t("noOptionsMui")}
                          onChange={handleCityChange}
                          filterOptions={filterCityOptions}
                          disabled={(disableAddress || (!stateSelected || !countrySelected)) && !!getValues('cityId')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                borderRadius: 0,
                                width: "100%",
                                height: "100%",
                                fontSize: 16,
                                fontWeight: 500,
                                outline: "none",
                                verticalAlign: "middle",
                                color: "#131212",
                                background: "#ffffff",
                              }}
                              placeholder={t("signUp.city")}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </S.ContainerGap>
              </S.InputRow>

              <S.InputRow rowsSize={[1]}>
                <S.ContainerGap>
                  <S.Label style={{marginBottom: 0}}>{t("users.address")} <span
                    style={{color: 'red'}}> *</span></S.Label>
                  <Controller
                    {...inputParams(control, "addressDistrict")}
                    render={({field}) => (
                      <ResponsiveInputText {...renderParams(field)} />
                    )}
                  />
                </S.ContainerGap>
              </S.InputRow>

              <S.InputRow rowsSize={[1]}>
                <S.ContainerGap>
                  <S.Label style={{marginBottom: 0}}>{t("users.addressSecond")}</S.Label>
                  <Controller
                    {...inputParams(control, "addressSecond")}
                    render={({field}) => (
                      <ResponsiveInputText {...renderParams(field)} />
                    )}
                  />
                </S.ContainerGap>
              </S.InputRow>

              <S.InputRow rowsSize={[1, 1]}>
                <S.ContainerGap>
                  <S.Label>{t("users.gender")}</S.Label>
                  <Controller
                    name="gender"
                    control={control}
                    render={({field: {name, value, onChange}}) => (
                      <Select
                        showSearch
                        sortAlphabetical={false}
                        selected={value}
                        onSelect={(newValue) => onChange(newValue)}
                        placeholder={t("users.gender")}
                        error={errors[name]?.message}
                        options={getGenderOptions()}
                      />
                    )}
                  />
                </S.ContainerGap>
                <S.ContainerGap>
                  <S.Label>
                    {t("users.ethnicity")}
                    <Tooltip
                      sx={{fontSize: "14px"}}
                      arrow
                      title={t("users.ibgeEthnicity")}
                      placement="top"
                    >
                      <InfoOutlinedIcon/>
                    </Tooltip>
                  </S.Label>
                  <Controller
                    name="ethnicity"
                    control={control}
                    render={({field: {name, value, onChange}}) => (
                      <Select
                        showSearch
                        sortAlphabetical={false}
                        selected={value}
                        onSelect={(newValue) => onChange(newValue)}
                        placeholder={t("users.ethnicity")}
                        error={errors[name]?.message}
                        options={getEthniciyOptions()}
                      />
                    )}
                  />
                </S.ContainerGap>
              </S.InputRow>
            </S.RemainSpace>
          </S.Row>
          <S.InputRow rowsSize={[1, 2]}>
            <S.containerCurriculo>
              {!curriculoWithFile ? (
                <S.UploadCurriculoButton>
                  <Controller
                    {...inputParams(control, "curriculoId")}
                    render={({field}) => (
                      <div>
                        <S.UploadInput
                          data-cy="questionFormImageUpload"
                          accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                          type="file"
                          onChange={async (e) => {
                            const selectedFile = e.target.files[0];
                            field.onChange({newBlob: selectedFile});
                            try {
                              const [curriculoResponse] =
                                await FileService.sendFileDocument([
                                  selectedFile,
                                ]);
                              setCurriculoWithFile(true);
                              setFileId(curriculoResponse);
                            } catch (err: any) {
                              console.error(err);
                            }
                          }}
                          id="enviar-arquivo"
                        />
                        <UploadIcon className="uploadIcon"/>
                        <S.UploadButtonText>
                          {t("signUp.sendResume")}
                        </S.UploadButtonText>
                      </div>
                    )}
                  />
                </S.UploadCurriculoButton>
              ) : (
                <div>
                  <S.ButtonVisualizar onClick={() => openImageInNewTab()}>
                    {t("signUp.seeResume")}
                  </S.ButtonVisualizar>

                  <S.ContainerRemoveImage
                    onClick={() => {
                      removeCurriculo();
                    }}
                  >
                    <S.IconRemoveImage
                      src={wrong}
                      alt={t("signUp.deleteResume")}
                    />
                    <S.removeImage>{t("signUp.deleteResume")}</S.removeImage>
                  </S.ContainerRemoveImage>
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={receiveEmails}
                    onChange={() => setReceiveEmails((prevState) => !prevState)}
                  />
                }
                label={
                  <Typography data-cy="ScreenSignUpReceiveEmails">
                    {t("signUp.receiveEmails")}
                  </Typography>
                }
              />
            </S.containerCurriculo>

            <S.ContainerGap>
              <S.TextInfo>{labels.user.aboutMe}</S.TextInfo>
              <Controller
                {...inputParams(control, "aboutMe")}
                render={({field: {name, value, onChange}}) => (
                  <div>
                    <S.TextArea
                      name={labels.user.aboutMe}
                      defaultValue=""
                      value={value}
                      onChange={onChange}
                      placeholder={labels.user.aboutMe}
                      borderColor={errors[name]?.message}
                    />
                    <S.ErrorMessage data-cy="errorMessage">
                      {errors[name]?.message}
                    </S.ErrorMessage>
                  </div>
                )}
              />
            </S.ContainerGap>
          </S.InputRow>

          <Modal
            open={modalConfirmation}
            onClose={() => {
              setModalConfirmation(false)
              setLoading(false)
            }}
            sx={{
              backdropFilter: "blur(2px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <S.FullModalCodePassword>
              <S.ModalTitle>{t("codeMessageModal.validatePhone")}</S.ModalTitle>
              <S.ModalSubtitleCodeSecurity data-cy="loginScreenModalEmailForget">
                {t("codeMessageModal.sentTo") + formatPhoneNumber(newPhone)}
              </S.ModalSubtitleCodeSecurity>
              <S.LabelCode>{t("codeMessageModal.code")}</S.LabelCode>
              <Controller
                control={control}
                name="code"
                render={({field: {onChange, value}}) => (
                  <S.inputCode onChange={onChange}/>
                )}
              />
              {errors?.code?.message ? (
                <S.ErrorMessage>{errors.code.message}</S.ErrorMessage>
              ) : null}
              <S.ContainerButtons>
                <S.buttonPink onClick={handleSubmit(handleEdit)}>
                  {t("codeMessageModal.confirm")}
                </S.buttonPink>
                <S.buttonWhite onClick={() => {
                  setModalConfirmation(false)
                  setLoading(false)
                }}>
                  {t("codeMessageModal.cancel")}
                </S.buttonWhite>
              </S.ContainerButtons>
            </S.FullModalCodePassword>
          </Modal>
          <S.Label><span style={{color: 'red'}}>* </span>{t("signUp.mandatoryField")}</S.Label>

          <S.ContainerButton>
            <Button
              variant="contained"
              size="large"
              disabled={loading}
              onClick={
                newPhone === oldPhone ||
                user?.roles.some((role) => role.name !== "ROLE_CLIENT")
                  ? handleSubmit(handleEdit)
                  : handleConfirmation
              }
              sx={{
                background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
                color: "white",
                fontWeight: "700",
                width: "155px",
                height: "53px",
              }}
            >
              {labels.add}
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => setModalDeleteAccount(true)}
              sx={{
                background: "#696969",
                color: "white",
                fontWeight: "700",
                height: "53px",
              }}
            >
              {t("signUp.deleteAccount")}
            </Button>
          </S.ContainerButton>
        </S.FormCenter>
      </S.FullScreen>
      <ModalDeleteAccount
        modalDeleteAccount={modalDeleteAccount}
        setModalDeleteAccount={setModalDeleteAccount}
        userId={user?.id}
      />
    </div>
  );
}
