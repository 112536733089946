import * as S from "../../styles";
import { Button } from "design-system-firedev";
import imgApproved from "../../../../assets/approved.png";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export function EvaluationChallengeLastPage() {
  const navigate = useNavigate();

  return (
    <S.ContainerSubmittedEvaluation>
      <S.ImageApproved src={imgApproved} alt="" />
      <S.Title>{t("evaluate.challengesEvaluated")}</S.Title>
      <S.SubtitleSubmitted>
        {t("evaluate.yourChallengesUploaded")}
      </S.SubtitleSubmitted>
      <S.ContainerButtonSubmitted>
        <Button
          className="buttonSubmitted"
          height={52}
          size="custom"
          text={t("evaluate.backToEvaluation")}
          onClick={() => navigate("/management/evaluation")}
          typeOfButton="primary"
          width={239}
        />
      </S.ContainerButtonSubmitted>
    </S.ContainerSubmittedEvaluation>
  );
}
