import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";
import backgroundImage from "../../assets/backgroundSkillTree.png";

export const StyledEditIcon = styled(EditIcon)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export const EditContentTextArea = styled.textarea`
  width: 100%;
  padding-left: 5px;
  padding-top: 5px;
  min-height: 50px;
`;

export const EditOptionTextArea = styled.textarea`
  width: 100%;
  padding-left: 5px;
  padding-top: 5px;
  height: 27px;
`;

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const ContainerAccordions = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  overflow: hidden;
`;

export const Paragraph = styled.p`
  padding: 4px;
  text-indent: 1em;
`;

export const ContainerAccordionSummary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 6px 0;
`;

export const ContainerAccordionSummaryLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`;

export const AccordionSummaryCircleImg = styled.div<{ color?: string }>`
  width: 36px;
  height: 36px;
  background-color: ${({ color }) => color};
  border-radius: 999px;
  position: relative;
`;

export const AccordionSummaryImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
`;

export const AccordionSummarySetaImg = styled.img<{ up?: boolean }>`
  width: 20px;
  margin-right: 1%;
  transform: ${({ up }) => (up ? "" : "rotate(180deg)")};
`;

export const ContainerAccordionSummaryLeftTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleAccordionSummary = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #131212;
`;

export const ContainerAccordionDetails = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 302px;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 0;
  background: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: center;
`;

export const ContainerAccordionDetailsLoading = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px 16px 16px;
  gap: 12px;
  background: #fff0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoadingText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #131212;
`;
