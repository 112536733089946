import styled from "styled-components";

interface IPropsTextArea {
  borderColor: any;
}

export const Form = styled.form`
  button[type="submit"] {
    margin-top: 2.3rem;
  }
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  outline: none !important;
  width: 100%;
  height: 150px;
  padding: 14px 16px;
  background: white;
  border: 1px solid
    ${({ borderColor }) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;

export const ContainerGap = styled.div`
  margin-top: 2.3rem;

  p {
    font-size: 14px;
  }
`;

export const ErrorMessage = styled.p`
  color: #f5222d;
  font-size: 14px;
  position: relative;
`;

export const ContainerDropDownSimpleSelect = styled.div`
  margin-top: 37px;

  p {
    font-size: 14px;
  }

  img {
    position: relative;
    left: 60px;
    top: 5px;
  }
`;

export const BoxSelect = styled.div`
  min-height: 40px;
  width: 42%;
  display: flex;
  margin: 10px;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 38px;
  p {
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 10px;
    color: #696969;
    border: 1px solid #8c8c8c;
    margin-top: 10px;
    display: flex;
    gap: 6px;
    margin-bottom: 12px;
    span {
      margin-top: 2px;
    }
  }
`;

export const InputRow = styled.div<{rowsSize: number[]}>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
  rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 16px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;
