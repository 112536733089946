/* eslint-disable no-new */
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardsPositions } from "../../components/CardsTrainingPlans";
import InfoPosition from "../../components/InfoPosition";
import PositionsLayout from "../../components/PositionsLayout";
import FormationService from "../../services/FormationService";
import TrailService from "../../services/TrailService";
import UserService from "../../services/UserService";
import Courses from "./Courses";
import Positions from "./Positions";
import * as S from "./styles";
import { Tune } from "@mui/icons-material";
import AdvancedFilters from "./Positions/AdvancedFilters";

export interface IFiltersHome {
  screen: number;
  stateSelected: any;
  citySelected: any;
  countrySelected: any;
  headerPositionSelected: any;
  positionCompanySelectedPosition: any;
  positionCategorySelectedId: any;
  positionFilterTrainingPlans: any;
  positionPositionSelected: any;
  positionModality: any;
  positionMaxSalary: any;
  positionMinSalary: any;
  positionGetNullSalary: any;
  companySelected: any;
  courseCategoryCourseSelected: any;
  courseSelected: any;
}

export default function Home() {
  const screens = [
    { name: t("positions.position"), value: 0 },
    { name: t("courses.courses"), value: 1 },
  ];
  const [user, setUser] = useState();

  const [filters, setFilters] = useState<IFiltersHome>({
    screen: 0,
    stateSelected: null,
    citySelected: null,
    countrySelected: null,
    headerPositionSelected: null,
    positionCompanySelectedPosition: null,
    positionCategorySelectedId: null,
    positionFilterTrainingPlans: null,
    positionPositionSelected: null,
    positionModality: null,
    positionMaxSalary: null,
    positionMinSalary: null,
    positionGetNullSalary: true,
    companySelected: null,
    courseCategoryCourseSelected: null,
    courseSelected: null,
  });

  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState({ id: null });
  const [mobile, setMobile] = useState(returnIfIsMobile());
  const navigate = useNavigate();
  const [possibleFilters, setPossibleFilters] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const [activateAllFilters, setActivateAllFilters] = useState(false);

  async function fetchData() {
    try {
      let response;
      let change = false;
      if (filters.screen == 0 && localStorage.getItem('prevPage') === null) {
        let companyId = "";
        if (typeof filters.positionCompanySelectedPosition != null) {
          companyId = `&companyId=${filters.companySelected?.id ||
            filters.companySelected ||
            filters.positionCompanySelectedPosition?.id ||
            filters.positionCompanySelectedPosition ||
            ""
          }`;
        }

        if (localStorage.getItem("token") != null) {
          response = await FormationService.getAllPositions(
            filters.positionCategorySelectedId,
            filters.stateSelected,
            filters.citySelected,
            filters.countrySelected,
            filters.headerPositionSelected,
            companyId,
            filters.positionModality,
            filters.positionPositionSelected?.name,
            filters.positionMinSalary,
            filters.positionMaxSalary,
            filters.positionGetNullSalary
          );
        } else {
          response = await FormationService.getAllPositionsNoAuth(
            filters.positionCategorySelectedId,
            filters.stateSelected,
            filters.citySelected,
            filters.countrySelected,
            filters.headerPositionSelected,
            companyId,
            filters.positionModality,
            filters.positionPositionSelected?.name,
            filters.positionMinSalary,
            filters.positionMaxSalary,
            filters.positionGetNullSalary
          );
        }
      } else {
        response = await TrailService.getAllTrailNotPaged(
          filters.courseSelected,
          filters.courseCategoryCourseSelected
        );
      }
      setData(response?.data);
      setCurrentData(response?.data[0]);
      setPossibleFilters(response?.possibleFilters);
      setRequestCount((prevState) => prevState - 1);
    } catch (err) {
      setRequestCount((prevState) => prevState - 1);
      console.error(err);
    }
  }

  useEffect(() => {
    setRequestCount((prevState) => prevState + 1);
    fetchData();
  }, [filters]);

  function returnIfIsMobile() {
    const width = window.document.body.offsetWidth;
    return width <= 999;
  }

  const cityState = (value) => {
    setFilters({
      ...filters,
      citySelected: value.city,
      stateSelected: value.state,
      countrySelected: value.country,
      positionCompanySelectedPosition: null,
      positionCategorySelectedId: null,
      positionFilterTrainingPlans: null,
      positionPositionSelected: null,
      positionModality: null,
      positionMaxSalary: null,
      positionMinSalary: null,
      positionGetNullSalary: true,
      courseCategoryCourseSelected: null,
    });
  };

  const handleClickCard = (item) => {
    const link = !filters.screen ? "/position/" : "/course/";
    navigate(`${link}${item?.company?.slug}/${item?.slug}`);
  };

  function onClickCard(item) {
    if (mobile) {
      handleClickCard(item);
    } else {
      setCurrentData(item);
    }
  }

  useEffect(() => {
    function setDropdownHeightEffect() {
      setMobile(returnIfIsMobile());
    }

    window.addEventListener("resize", setDropdownHeightEffect);
    setActivateAllFilters(false);
    return () => window.removeEventListener("resize", setDropdownHeightEffect);
  }, [mobile]);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("token")) {
        const currentUser = user || (await UserService.getUser());
        if (currentUser?.id) {
          setUser(currentUser);
        }
      }
    })();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={requestCount > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PositionsLayout
        mainScreen
        setCityState={cityState}
        setFilters={setFilters}
        filters={filters}
        advancedFilters={activateAllFilters}
        isTrainingPlans
        sidebarActive={false}
        screen={filters.screen}
        setScreen={(value) => setFilters({ ...filters, screen: value })}
        noScroll={false}
      >
        <>
          {mobile &&(
            <AdvancedFilters
              filters={filters}
              setFilters={setFilters}
              mobile={mobile}
              possibleFilters={possibleFilters}
              advancedFilters={activateAllFilters}
              setAdvancedFilters={() => setActivateAllFilters(false)} />
          )}
          <S.Container style={{ display: activateAllFilters ? "none" : ''}} >
            <S.ContainerContent>
              <S.ContainerTrailsMain>
                <S.ContainerTitle>
                  <S.Title>
                    {!filters.screen
                      ? t("positions.position")
                      : t("courses.courses")}
                  </S.Title>
                  <S.Subtitle data-cy="subTitlePositionPage">
                    {!filters.screen
                      ? t("positions.positionSubtitle")
                      : t("courses.whatToLearn")}
                  </S.Subtitle>
                </S.ContainerTitle>

                {!filters.screen ? (
                  <S.ContainerSliderHorizontal>
                    {mobile && (
                      <Tooltip title={t("home.advancedSearches")} placement="top" onClick={() => setActivateAllFilters(!activateAllFilters)}>
                        <Tune style={{ marginTop: "26px", marginLeft: "1%", fontSize: "30px", color: "gray", cursor: 'pointer' }} />
                      </Tooltip>
                    )}
                    <Positions
                      filters={filters}
                      setFilters={setFilters}
                      mobile={mobile}
                      possibleFilters={possibleFilters}
                      advancedFilters={activateAllFilters} />
                  </S.ContainerSliderHorizontal>
                ) : (
                  <Courses
                    filter={filters}
                    setFilter={setFilters}
                    categoriesData={possibleFilters || []} />
                )}
                {data.length > 0 ? (
                  <S.ContainerFulMainData>
                    <S.ContainerCards
                      isCourse={!!filters.screen}
                      data-cy="dashboardTrailCard"
                    >
                      {data?.map((item) => {
                        return (
                          <CardsPositions
                            isCourse={!!filters.screen}
                            equal={currentData?.id === item?.id}
                            item={item}
                            onClick={() => onClickCard(item)} />
                        );
                      })}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <S.Subtitle>{t("positions.endOfList")}</S.Subtitle>
                      </div>
                    </S.ContainerCards>
                    <InfoPosition
                      user={user}
                      isCourse={!!filters.screen}
                      mobile={mobile}
                      item={!!currentData ? currentData : null} />
                  </S.ContainerFulMainData>
                ) : (
                  <S.ContainerFullNoResults>
                    <S.Title>{t("positions.endOfList")}</S.Title>
                    <S.Subtitle>
                      {!filters.screen
                        ? t("positions.noResults")
                        : t("positions.noResultsCourses")}
                    </S.Subtitle>
                  </S.ContainerFullNoResults>
                )}
              </S.ContainerTrailsMain>
            </S.ContainerContent>
          </S.Container>
        </>
      </PositionsLayout>
    </>
  );
}
