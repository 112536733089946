import styled from "styled-components";

export const FullScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #ffe8e6;
`;

export const DesktopContainer = styled.div`
  display: none;
  @media screen and (min-width: 1000px) {
    display: block;
  }
`;
export const TabletContainer = styled.div`
  display: none;
  @media screen and (max-width: 999px) and (min-width: 601px) {
    display: block;
  }
`;
export const MobileContainer = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const HeaderFullContainer = styled.header`
  padding: 0 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  z-index: 999;
  width: 100%;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  position: fixed;
  @media screen and (max-width: 999px) {
    height: 70px;
    padding: 0 15px;
  }
`;

export const CenterByMaxSize = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  position: relative;
`;

export const DesktopHeader = styled.div`
  width: 100%;
  height: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 999px) {
    display: none;
  }
`;
export const TabletMobileHeader = styled.div`
  /* position: fixed;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  display: none;
  @media screen and (max-width: 999px) {
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 40px;
`;
export const MenusContainer = styled.div`
  margin: 0 2rem;
  display: flex;
  gap: 8px;
  flex-direction: row;
`;

export const MenusText = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: right;
  flex: 1;
  cursor: pointer;
`;

export const UserImage = styled.img`
  border-radius: 999px;
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

export const UserText = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
`;

export const CompanyText = styled.p`
  color: #fff;
  font-size: 13px;
  font-weight: 300;
`;

export const BlankSpace = styled.span`
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const MenuIconContainer = styled.div`
  border: none;
  padding: 0;
  outline: none !important;
  background-color: #0000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TabletMobileLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  height: 32px;
`;

export const TabletSearchImage = styled.img`
  width: 27.69px;
  height: 27.69px;
`;

export const ContainerInput = styled.div`
  padding-right: 1rem;
  flex: 1;
`;

export const ContentSidebar = styled.div<any>`
  position: fixed;
  top: 90px;
  left: 0;
  height: calc(100vh - 90px);
  width: 17rem;
  z-index: 10 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  @media screen and (max-width: 999px) {
    height: calc(100vh - 70px);
    top: 70px;
    left: 0;
    width: ${({ show }) => (show ? 280 : 0)}px;
    transition: width 0.2s;
  }
  @media screen and (max-width: 600px) {
    height: calc(100vh - 140px);
  }
`;

export const ContainerSideBar = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0 2rem;
  gap: 0.75rem;
  overflow: hidden;
  @media screen and (max-width: 999px) {
    padding: 0 1rem;
    gap: 0.55rem;
  }
`;

export const ContainerSideBarInfo = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 1rem 2rem;
  gap: 0.75rem;
  overflow: hidden;
  border-top: 1px solid #f8e7e6;
  @media screen and (max-width: 999px) {
    padding: 1rem 1rem;
    gap: 0.55rem;
  }
`;

export const TitleSideBar = styled.p`
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
`;

export const TextSideBar = styled.p`
  font-weight: 300;
  font-size: 15px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TemporaryInput = styled.input`
  margin: 1rem 0;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  width: 90%;
  height: 50px;
  padding: 14px;
  background-color: #fff;
  color: #444444;
  font-weight: 700;
  font-size: 16px;
  ::placeholder {
    color: #a2a2a2;
    font-weight: 700;
    font-size: 16px;
  }
`;

export const ContainerTemporaryInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerHeaderSideBar = styled.div`
  padding: 1.75rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 999px) {
    padding: 1.75rem 1rem;
  }

  h3 {
    color: #000000;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
    color: #696969;
    font-weight: 400;
    font-style: normal;
  }
`;

export const ChildrenBlack = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #13121244;
  z-index: 8;
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

export const ChildrenContainer = styled.div<any>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: ${({ onlyHeader, withSidebar }) =>
    onlyHeader ? 0 : withSidebar ? 265 : 0}px;
  padding-top: 90px;
  z-index: 0 !important;

  @media screen and (max-width: 999px) {
    padding-bottom: 0;
    padding-right: ${({ onlyHeader }) => (onlyHeader ? 0 : 112)}px;
    padding-left: 0;
  }
  @media screen and (max-width: 600px) {
    padding-top: 70px;
    padding-bottom: ${({ onlyHeader }) => (onlyHeader ? 0 : 70)}px;
    padding-right: 0;
  }
`;

export const CenterChildren = styled.main<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 999px) {
    padding-bottom: 50px;
  }
`;
