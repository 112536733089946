import styled from "styled-components";

export const CenterPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerPage = styled.div`
  width: 100%;
  padding: 4rem 5%;
`;

export const TitlePage = styled.h1`
  font-size: 36px;
  font-weight: 700;
`;

export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const CheckboxOutCircle = styled.div<{ selected: boolean }>`
  position: relative;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  background: ${({ selected }) =>
          selected
                  ? "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box"
                  : "linear-gradient(white, white) padding-box, linear-gradient(90deg, #696969 0%, #696969 100%) border-box"};
  border-radius: 2px;
  border: 2px solid transparent;
`;

export const CheckboxInnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 50%;
`;
