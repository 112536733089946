import { objectToURLSearchParams } from "../components/utils/http";
import api, { axiosApi } from "./api";

class SkillTreeService {
  async getAllSkillTree(page: number, search: string) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `skill_trees?page=${page}&size=5&filter=${filter}`
    );
    return response.data;
  }

  async getSkillTreeManagement(
    page: number,
    rowsPerPage: number,
    search: string
  ) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `/v2/skill_tree?page=${page}&size=${rowsPerPage}&filter=${filter}`
    );
    return response.data;
  }

  async deleteSkillTree(id: string) {
    await api.delete(`skill_trees/${id}`);
  }

  async findIdSkillTree(id: string | string[]) {
    const identifiesParameter = Array.isArray(id);
    const ids = identifiesParameter ? id : [id];
    const promisses = ids.map((id) => api.get(`skill_trees/${id}`));
    const responses = await Promise.all(promisses);
    return identifiesParameter
      ? responses.map((response) => response.data)
      : responses[0].data;
  }

  async editSkillTree(id: string, SkillTreeDTO: any) {
    const response = await api.put(`skill_trees/${id}`, SkillTreeDTO);
    return response.data;
  }

  async addSkillTree(SkillTree: string, SkillTreeDTO: any) {
    const response = await api.post(SkillTree, SkillTreeDTO);
    return response;
  }

  async getSkillTreeToDropdown({
    trailId = "",
    formationId = "",
    skillId = "",
  }: {
    trailId?: string;
    formationId?: string;
    skillId?: string;
  }) {
    try {
      if (!trailId && !formationId) return [];

      const response = await api.get(
        `/v2/skill_tree/summary-aquarium?trailId=${trailId}&formationId=${formationId}&skillId=${skillId}`
      );
      return response?.data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getSkillTreeToDropdownWithCompany(
    search: string,
    visibilityFilter = null,
    skillHunterPool = null
  ) {
    let url = "skill_trees/summary-company?";
    const queryParams: any = {
      onlyExternalFormations: visibilityFilter,
      skillHunterPool: skillHunterPool,
      filter: search,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultSkillTreeToDropdown = await api.get(
      url + objectToURLSearchParams(filteredParams)
    );

    return resultSkillTreeToDropdown.data.map(({ id, name, description }) => ({
      id,
      value: name,
      description: description,
    }));
  }

  async getSkillTreeSkills(id: any) {
    const resultSkillTreeToDropdown = await api.get(`skill_trees/skill/${id}`);
    return resultSkillTreeToDropdown.data;
  }

  async getSkillTreeSkillsByTrail(slug: string, trailSlug: string) {
    let response;
    if (localStorage.getItem("token")) {
      response = await api.get(
        `skill_trees/skill-tree/${slug}/trail/${trailSlug}`
      );
    } else {
      response = await axiosApi.get(
        `skill_trees/no-auth/skill-tree/${slug}/trail/${trailSlug}`
      );
    }
    return response.data;
  }

  async getSkillTreeSkillsByTrailNoAuth(slug: string, trailSlug: string) {
    const resultSkillTreeToDropdown = await axiosApi.get(
      `skill_trees/no-auth/skill-tree/${slug}/trail/${trailSlug}`
    );
    return resultSkillTreeToDropdown.data;
  }

  async getSkillTreeSkillsByFormation(slug: string, formationSlug: string) {
    let response;
    if (localStorage.getItem("token")) {
      response = await api.get(
        `skill_trees/skill-tree/${slug}/formation/${formationSlug}`
      );
    } else {
      response = await axiosApi.get(
        `skill_trees/no-auth/skill-tree/${slug}/formation/${formationSlug}`
      );
    }
    return response.data;
  }

  async getSkillStatusBySkillTreeAndSkill(
    competencyType: "formation" | "trail",
    skillTreeSlug: string,
    competencySlug: string,
    skillSlug: string
  ) {
    const resultSkillTreeToDropdown = await api.get(
      `skill_trees/skill-tree/${skillTreeSlug}/${competencyType}/${competencySlug}/skill/${skillSlug}`
    );
    return resultSkillTreeToDropdown.data;
  }

  async getCompetenceSkills(skillTreeId: any[]) {
    let listId = "";
    skillTreeId?.forEach((id) => {
      listId += `&skillTreeId=${id}`;
    });
    const response = await api.get(`skill_trees/competence-skills?${listId}`);
    return response.data;
  }
}

export default new SkillTreeService();
