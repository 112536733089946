import styled from "styled-components";
import { colors } from "../../components/utils/colors";

export const ContainerTotal = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    padding: 2.5rem 5rem 2.5rem 5rem;
  }

  @media (min-width: 1001px) {
    padding: 2.5rem 8rem 2.5rem 8rem;
  }

  @media (max-width: 425px) {
    padding: 2.5rem 2rem 2.5rem 2rem;
  }
`;

export const ContainerTitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin: 36px 0;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: #131212;
`;

export const Subtitle = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const TitleCards = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #131212;

  margin: 36px 0;
`;

export const ContainerInput = styled.div`
  .inputMui {
    background: #ffffff;
    border: 1.5px solid #8c8c8c;
    border-radius: 3px;
    height: 54px;
    padding-left: 17px;
    outline: none;
  }

  input {
    &::placeholder {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 32px;
      color: #8c8c8c;
    }
  }
`;

export const BoxOfListCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 1440px) {
    padding: 0;
  }

  @media (max-width: 1024px) {
    padding: 0 90px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 425px) {
    padding: 0;
  }
`;

export const ContainerFulMainData = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
`;

export const ContainerFullNoResults = styled.div<any>`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerCards = styled.div<any>`
  width: 100%;
  border: 1px solid ${colors.gray100};
  background-color: #fff;
  border-radius: 3px 3px 3px 3px;
  position: relative;
  max-width: 650px;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 4%;
`;

export const ContainerContent = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

export const ContainerTrailsMain = styled.div<any>`
  margin-top: 20px;
  width: ${({ isFullPage }) =>
    isFullPage ? "calc(100% - 320px - 16px)" : "100%"};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;
