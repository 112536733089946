import styled from "styled-components";

interface IPropsQuestions {
  isFinal: boolean;
}

export const CenterPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  padding: 2.5rem 5%;
  display: flex;
  flex-direction: column;
  width: 1300px;
  max-width: 100%;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

export const Title = styled.p`
  font-size: 2rem;
  font-weight: 700;
  color: #131212;
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: #696969;
`;

export const CenterContainerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerContent = styled.div`
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  width: 1000px;
  max-width: 100%;
`;

export const ContainerQuestions = styled.div<IPropsQuestions>`
  margin-top: 2rem;
  gap: 1.5rem;
  flex-direction: column;
  width: 98%;
  display: ${({ isFinal }) => (isFinal ? "none" : "flex")};
`;

export const QuestionTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: #131212;
`;

export const QuestionText = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: #131212;
  overflow-wrap: break-word;
`;

export const CheckboxLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 400; 
  display: flex; 
  align-items: center;
  gap: 5px;
  img {
    width: 300px;
    max-height: 300px;
    margin-bottom: 0.5rem; 
  }
`;

export const ContainerButtons = styled.div<IPropsQuestions>`
  display: ${({ isFinal }) => (isFinal ? "none" : "flex")};
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
`;

export const DescriptionData = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  
  p:has(img) { 
      width: 100%;

      img {
        max-width: 100%;
        max-height: 400px;
        margin: 16px 0;
        display: block;
      }
  }

  iframe {
    display: flex;
    align-self: center;
    max-width: 100%;
    max-height: 400px;
    margin: 16px 0;
  }

  ul, ol {
    margin-left: 20px;
  }
`