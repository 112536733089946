import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { t } from "i18next";
import * as S from "./styles";

export function AlertModal({
  onClose,
  open,
  registerStart,
  data,
  confirmText,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <S.InfoIcon />
        {t("positionsManagement.attention")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <S.AlertMessage>
            {data?.numberOfTries} {t("challenge.triesLeft")} {data?.timeBlocked}{" "}
            {data?.timeBlocked > 1
              ? t("challenge.daysBlocked")
              : t("challenge.dayBlocked")}
            .
            <p>
              <b>
                <u> {t("challenge.wontBeBlockedForOtherSkills")}</u>
              </b>
            </p>
          </S.AlertMessage>
          <S.AlertMessage>{t("challenge.wontBeSave")}</S.AlertMessage>
          <S.AlertMessage>
            {t("challenge.rightAnswersByCompany")}
          </S.AlertMessage>
          <S.AlertMessage>{t("challenge.numberOfTimesTried")}</S.AlertMessage>
          <S.AlertMessage>{t("challenge.timeToFinish")}</S.AlertMessage>
          <S.MotivationsMessage>
            {t("challenge.goodLucky")}
          </S.MotivationsMessage>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <S.CancelButton onClick={onClose}>
          {t("performanceReport.cancel")}
        </S.CancelButton>
        <S.ConfirmButton onClick={() => registerStart()}>
          {confirmText}
        </S.ConfirmButton>
      </DialogActions>
    </Dialog>
  );
}
