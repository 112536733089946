import styled from "styled-components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const InfoIcon = styled(InfoOutlinedIcon)`
  height: 5rem !important;
  width: 5rem !important;
  color: #ea8181;
`;

export const MotivationsMessage = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: #696969;
`;

export const AlertMessage = styled.li`
  padding-bottom: 1rem;
`;

export const ConfirmButton = styled.button`
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 3px;
  padding: 10px;
  width: max-content;
  /* H2 - Heading 2 */

  font-family: "Ubuntu";
  font-style: normal;
  //font-weight: 700;
  font-size: 16px;
  //line-height: 32px;
  /* identical to box height, or 178% */

  text-align: center;

  /* White */

  color: #ffffff;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000021;

  &:active {
    /* background-color: #3e8e41; */
    box-shadow: 0px 0px 0px 0px;

    transform: translateY(2px);
  }
`;

export const CancelButton = styled.button`
  background: transparent;
  border-radius: 3px;
  padding: 9px;
  width: max-content;
  /* H2 - Heading 2 */

  font-family: "Ubuntu";
  font-style: normal;
  //font-weight: 700;
  font-size: 16px;
  //line-height: 32px;
  /* identical to box height, or 178% */

  text-align: center;

  /* White */

  color: #000000;
  border: 1px solid black;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000021;

  &:active {
    /* background-color: #3e8e41; */
    box-shadow: 0px 0px 0px 0px;

    transform: translateY(2px);
  }
`;