import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 580px;
  height: 370px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-radius: 3px;
  max-height: 96%;
  overflow-y: auto;
  outline: none;
`;

export const ContainerBodyModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: .5rem;
  margin-bottom: 1rem;
`;

export const ContainerSelectInput = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  @media (min-width: 1000px) {
    width: 17.5rem;
  }
`;

export const ContainerButtonsFooterModal = styled.div`
  display: flex;
  width: 100%;
  column-gap: 1rem;
`;