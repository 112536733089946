import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding: 7.625rem 0 0 0;
`;

export const Image = styled.img`
  width: 86px;
  height: 86px;
  margin-bottom: 3rem;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: #131212;
  margin-bottom: 2.125rem;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #131212;
  text-align: center;
`;

export const ButtonBack = styled.button`
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 3px;
  width: 239px;
  height: 52px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2.875rem;
`;
