import { CircularProgress, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import { t } from 'i18next';
import { ArrowLeft, X } from "phosphor-react";
import { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { AttemptsChallengers } from "../AttemptsChallengers";
import * as S from "./styles";

interface DetailedChallengerProps {
  open: boolean;
  onClose: () => void;
  userId: string;
  challengerId: number;
  competencyName: string;
  userName: string;
}

export function DetailedChallenger({ open, onClose, userId, challengerId, userName, competencyName }: DetailedChallengerProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [challengeName, setChallengeName] = useState("");

  async function getData (userId, challengerId) {
    if (open) {
      try {
        setLoading(true);
        const result: any = await UserService.getQuestionsResponse(userId, challengerId);
        
        setQuestions(result);
        setChallengeName(result[0].challenge?.title)        

        if (Array.isArray(result)) {
          setIsApproved(result?.some((item) => item?.status === "ACHIEVED"));
        }
      } catch (err: any) {
        console.error(err?.data?.response?.message);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getData(userId, challengerId);
  }, [open])

  return (
    <S.DialogContainer
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogContent>
        <X
          size={20}
          style={{ float: "right", cursor: "pointer" }}
          onClick={onClose}
        />
        <ArrowLeft
          size={20}
          style={{ float: "left", cursor: "pointer" }}
          onClick={onClose}/>
          {
            loading ?
              <S.Spinner>
                <CircularProgress />
              </S.Spinner> :
              <div>
                <S.FirstContainer>
                  <S.Title>{t('detailedChallenger.title')}</S.Title>
                  <S.SubtitleModal>
                    {t('detailedChallenger.subtitle')}
                  </S.SubtitleModal>
                  <S.GridSection>
                    <S.ColumnUser>{t('detailedChallenger.user')}</S.ColumnUser>
                    <S.ColumnFormation>
                      {t('detailedChallenger.planFormation')}
                    </S.ColumnFormation>
                    <S.ColumnChallenger>{t('detailedChallenger.challenger')}</S.ColumnChallenger>
                    <S.ColumnResult>{t('detailedChallenger.result')}</S.ColumnResult>
                    <S.ColumnAttempts>{t('detailedChallenger.attempts')}</S.ColumnAttempts>
                    <S.UserName>
                      <S.UserIcon />
                      <S.NameUser>{userName}</S.NameUser>
                    </S.UserName>
                    <S.Position>{competencyName}</S.Position>
                  <S.Challenger>{challengeName}</S.Challenger>
                  <S.Result>{isApproved ? t('detailedChallenger.approved') : t('detailedChallenger.reproved')}</S.Result>
                  <S.Attempts>{Array.isArray(questions) ? questions?.length : 0}</S.Attempts>
                  </S.GridSection>
                </S.FirstContainer>
                <S.ContainerAttempts hasQuestions={questions.length !== 0 && Array.isArray(questions)}>
                  {questions.length !== 0 && Array.isArray(questions) ? (
                    questions.map((attempt, index) => (
                      <AttemptsChallengers
                        key={index}
                        title={`Tentativa ${index + 1}`}
                        questions={attempt?.questions}
                        challengerId={challengerId}
                      />
                    ))
                  ) : (
                    <div>
                      <S.EmptyAttempts>{t("performanceReport.emptyAttempt")}</S.EmptyAttempts>
                    </div>
                  )}
                </S.ContainerAttempts>
              </div>
          }
      </DialogContent>
    </S.DialogContainer>
  )
} 