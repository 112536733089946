import styled from "styled-components";

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const ContainerGap = styled.div`
  margin-top: 1rem;
  p {
    font-size: 14px;
  }
`;

export const ContainerPackages = styled.div`
  width: 100%;
  height: 100%;
  margin: 30px 0;
  margin-top: 60px;
  @media screen and (max-width: 1049px) {
    width: 100%;
  }
`;

export const Package = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 300px;
  height: 150px !important;
  margin: 30px 15px;
  border: 1px solid #FF026F;
  border-radius: 3px;
`;

export const TextInfo = styled.p`
  font-size: 16px !important;    
`;

export const ContainerButton = styled.div`
  width: 500px;
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media screen and (max-width: 1049px) {
    width: 100%;
  }  
`;


export const DivPackage = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 750px) {
    justify-content: center;
    display: grid !important;
  }

`;

export const DivLabelPackage = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 25px;
  background-color: #FF6423;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerPackage = styled.div`
  height: 67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const FirstLabelPackage = styled.span`
  font-size: 18px !important;
  font-weight: 700;
`;

export const SecoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SecoundLabelPackage = styled.span`
  font-size: 18px !important;
  color: #8C8C8C;
  font-weight: 700;
`;

export const ThirdLabelPackage = styled.span`
  font-size: 30px !important;
  color: #FF026F;
  margin-bottom: 6px;
  margin-left: 5px;
  font-weight: 700;
`;

export const CustomPackage = styled.p`
  font-size: 18px !important;
  font-weight: 700;
  text-align: center;
`;


export const TitlePackage = styled.span`
  font-size: 16px !important;
  color: #FFFFFF;
`;
