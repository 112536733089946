/* eslint-disable no-restricted-syntax */
/* eslint-disable no-inner-declarations */
import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from "@mui/material";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import SkillBlackIcon from "../../assets/SkillBlackIcon.svg";
import TreeBlackIcon from "../../assets/TreeBlackIcon.svg";
import TreeWhiteIcon from "../../assets/TreeWhiteIcon.svg";
import artImg from "../../assets/art.png";
import SetaGray from "../../assets/setaGray.svg";
import Breadcrumb from "../../components/Breadcrumb/index";
import { Button } from "../../components/Button";
import CardTree from "../../components/CardTree";
import {
  default as DefaultLoading,
  default as Loading,
} from "../../components/Loading/defaultLoading";
import ModalSubscribe from "../../components/ModalSubscribe";
import { ProgressBar } from "../../components/Progress";
import rightButton from "../../components/assets/rightButton.svg";
import SkillTreesService from "../../services/SkillTreeService";
import TrailService from "../../services/TrailService";
import UserService from "../../services/UserService";
import api from "../../services/api";
import { ButtonDS } from "../Login/style";
import star from "./star.svg";
import * as S from "./style";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import {TextCompany} from "../PositionTrail/style";
import Tooltip from "@mui/material/Tooltip";
import {InfoOutlined} from "@mui/icons-material";

const EvolutionTrail = (props) => {
  const navigate = useNavigate();
  const { trailSlug } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [trail, setTrail] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [treeLoading, setTreeLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [competence, setCompetence] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const progress = trail?.progress;

  const handleSubscribe = async () => {
    try {
      let token = localStorage.getItem("token");
      if (token) {
        const result = await api.get(`trails/subscribed/${trailSlug}`);
        setIsSubscribed(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getColor = (status: any) => {
    switch (status) {
      case "ACHIEVED":
        return "#00A676";
      case "UNLOCKED":
      case "STARTED":
        return "#302382";
      case "WAITING_EVALUATION":
        return "#FF9900";
      default:
        return "#C4C4C4";
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  async function subscribe() {
    setLoading(true);
    if (!localStorage.getItem("token")) {
      setShowModal(true);
      return;
    }

    const isComplete = await UserService.verifyIfProfileIsComplete();

    if (!isComplete) {
      setOpenEditDialog(true);
    } else {
      await TrailService.subscribe(trailSlug);
      setIsSubscribed(true);
    }
    setLoading(false);
  }

  async function fetchData() {
    setLoading(true);
    const result = await TrailService.getTrailDashboard(trailSlug);
    setTrail(result?.data);
    setLoading(false);
  }

  useEffect(() => {
    handleSubscribe();
    fetchData();
  }, [isSubscribed]);

  useEffect(() => {
    (async () => {
      try {
        setTreeLoading(true);
        if (expanded && expanded !== "panel1") {
          if (localStorage.getItem("token")) {
            const result = await SkillTreesService.getSkillTreeSkillsByTrail(
              expanded,
              trailSlug
            );
            setCompetence(result.skills);
          } else {
            const result =
              await SkillTreesService.getSkillTreeSkillsByTrailNoAuth(
                expanded,
                trailSlug
              );
            setCompetence(result.skills);
          }
        }
      } catch (err) {
        setTreeLoading(false);
        console.error(err);
      }
      setTreeLoading(false);
    })();
  }, [expanded, isSubscribed]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <S.Container>
        {loading ? (
          <Loading color="#ff727d" size={60} />
        ) : (
          <S.ContainerTotal>
            <Breadcrumb
              currentPage={trail?.category?.name}
              previousPage={[
                { value: t("courseDetails.courses"), link: "/home" },
              ]}
            />
            <S.ContainerTitleButton>
                <S.Title>{trail?.name}</S.Title>
              <S.MiniButtonContainer data-cy="trailPageSubscribeButton">
                {!isSubscribed && (
                  <Button disable={loading} onClick={subscribe}>
                    {t("courseDetails.enroll")}
                  </Button>
                )}
                {isSubscribed && progress !== 100 && (
                  <Button disable>{t("courseDetails.enrolled")}</Button>
                )}
                {progress === 100 && isSubscribed && (
                  <S.ButtonConclude>
                    <span>
                      <img src={rightButton} alt="" />
                    </span>
                    {t("courseDetails.concluded")}
                  </S.ButtonConclude>
                )}
              </S.MiniButtonContainer>
            </S.ContainerTitleButton>
            <S.Subtitle>{trail?.category?.name}</S.Subtitle>
            <br />
            {trail?.company?.id != -1 ? (
              <S.EmployPosition>
                <S.ImageCompany
                  src={
                    trail?.company?.avatar || skillHunterPositionIcon
                  }
                  alt=""
                />
                <TextCompany>{trail?.company?.name}</TextCompany>
              </S.EmployPosition>
            ) : (
              <S.EmployPosition>
                <S.ImageCompany src={skillHunterPositionIcon} alt="" />
                <TextCompany>Skill Hunter</TextCompany>
                <Tooltip
                  title={t("courses.skillhunterTooltip")}
                  placement="top"
                >
                  <InfoOutlined sx={{ opacity: 0.5 }} />
                </Tooltip>
              </S.EmployPosition>
            )}
            <S.ContainerCards>
              <S.ContainerTwo>
                <S.ContainerLineButton>
                  <S.ContainerButton>
                    {!isSubscribed && (
                      <Button disable={loading} onClick={subscribe}>
                        {t("courseDetails.enroll")}
                      </Button>
                    )}
                    {isSubscribed && progress !== 100 && (
                      <Button disable>{t("courseDetails.enrolled")}</Button>
                    )}

                    {progress === 100 && (
                      <S.ButtonConclude mobile>
                        <span>
                          <img src={rightButton} alt="" />
                        </span>
                        {t("courseDetails.concluded")}
                      </S.ButtonConclude>
                    )}
                  </S.ContainerButton>
                </S.ContainerLineButton>
                <S.ContainerThreeSkills>
                  <S.FooterItem>
                    <img src={TreeBlackIcon} alt="tree" />
                    <S.FooterItemInfo>
                      <S.FooterItemCount>
                        {trail?.skillTreesCount}
                      </S.FooterItemCount>
                      <S.FooterItemLabel>
                        {t("courseDetails.competence")}
                      </S.FooterItemLabel>
                    </S.FooterItemInfo>
                  </S.FooterItem>
                  <S.FooterItem>
                    <img src={SkillBlackIcon} alt="skills" />
                    <S.FooterItemInfo>
                      <S.FooterItemCount>
                        {trail?.skillsCount}
                      </S.FooterItemCount>
                      <S.FooterItemLabel>
                        {t("courseDetails.skill")}
                      </S.FooterItemLabel>
                    </S.FooterItemInfo>
                  </S.FooterItem>
                </S.ContainerThreeSkills>
                <S.AboutTree>{t("courseDetails.aboutCourse")}</S.AboutTree>
                <S.DescriptionTree dangerouslySetInnerHTML={{ __html: trail?.description }}></S.DescriptionTree>
              </S.ContainerTwo>
            </S.ContainerCards>
            <S.ContainerProgressCard>
              <S.ContainerProgressStarText>
                <img
                  src={star}
                  alt="star_progress_icon"
                  style={{ width: 50 }}
                />
                <S.ContainerTitlePercent>
                  <S.ProgressTitleSubtitle>
                    <S.ProgressTitle>{t("courseDetails.overallProgress")}</S.ProgressTitle>
                    <S.ProgressSubtitle>
                      {t("courseDetails.completeThisCourse")}
                    </S.ProgressSubtitle>
                  </S.ProgressTitleSubtitle>
                  <S.PercentText>{trail?.progress || 0}%</S.PercentText>
                </S.ContainerTitlePercent>
              </S.ContainerProgressStarText>
              <ProgressBar value={trail?.progress || 0} max={100} isChallenge />
            </S.ContainerProgressCard>

            <S.ContainerAccordions>
              {trail?.skillTrees.map((comp, i) => (
                <Accordion
                  disableGutters
                  expanded={expanded === comp?.slug}
                  onChange={handleChange(comp?.slug)}
                >
                  <AccordionSummary
                    data-cy="trailPageAccordion"
                    aria-controls={`${comp?.id}d-content`}
                    id={`${comp?.id}d-header`}
                  >
                    <S.ContainerAccordionSummary>
                      <S.ContainerAccordionSummaryLeft>
                        <S.AccordionSummaryCircleImg
                          color={getColor(comp?.status || "UNLOCKED")}
                        >
                          <S.AccordionSummaryImg
                            src={TreeWhiteIcon}
                            alt="TreeIcon"
                          />
                        </S.AccordionSummaryCircleImg>
                        <S.ContainerAccordionSummaryLeftTexts>
                          <S.TitleAccordionSummary>
                            {comp?.name}
                          </S.TitleAccordionSummary>
                          <S.SubtitleAccordionSummary
                            color={getColor(comp?.status || "UNLOCKED")}
                          >
                            {t("courseDetails.progress")}:{" "}
                            {isSubscribed ? comp?.progress : 0}%
                          </S.SubtitleAccordionSummary>
                        </S.ContainerAccordionSummaryLeftTexts>
                      </S.ContainerAccordionSummaryLeft>
                      <S.AccordionSummarySetaImg
                        up={expanded === comp?.id}
                        src={SetaGray}
                        alt="Seta"
                      />
                    </S.ContainerAccordionSummary>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, m: 0 }}>
                    {treeLoading ? (
                      <S.ContainerAccordionDetailsLoading>
                        <DefaultLoading color="#898989" />
                        <S.LoadingText>
                          {t("courseDetails.loading")}...
                        </S.LoadingText>
                      </S.ContainerAccordionDetailsLoading>
                    ) : (
                      <S.ContainerAccordionDetails>
                        {competence?.length > 0 ? (
                          <CardTree
                            skills={competence}
                            onClick={(skill) => {
                              if (localStorage.getItem("token")) {
                                navigate(
                                  `skill-tree/${expanded}/skill/${skill.slug}`
                                );
                              } else {
                                setShowModal(true);
                              }
                            }}
                          />
                        ) : (
                          <S.WithoutSkills>
                            {t("courseDetails.noChallenges")}
                          </S.WithoutSkills>
                        )}
                      </S.ContainerAccordionDetails>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </S.ContainerAccordions>
          </S.ContainerTotal>
        )}
      </S.Container>
      <Modal
        open={showModal}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalSubscribe
          onClose={handleClose}
          isCourse
          company={trail?.company?.slug}
          id={trailSlug}
        />
      </Modal>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("courses.completeProfile")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{t("courses.doThisNow")}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("no")}
            onClick={() => setOpenEditDialog(false)}
          />
          <ButtonDS
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("yes")}
            onClick={() => navigate("/EditUser")}
            data-cy="acceptEdit"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export { EvolutionTrail };
