import styled from "styled-components";

export const FullContainer = styled.div`
  font-family: "Ubuntu";
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 28px;
  border-radius: 3px;
  padding: 35px 37.5px 80%;
`;

export const RowContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.p`
  font-size: 25px;
  min-width: 100%;
  line-height: 30px;
  margin-right: 55%;
  color: #131212;
  
`;