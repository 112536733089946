import React from "react";

function SucessOutlined({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <g clipPath="url(#clip0_6041_727)">
        <path
          fill="#00A676"
          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        ></path>
      </g>
      <path
        fill="#00A676"
        d="M10.666 14.391l-2.195-2.195-.943.942 3.138 3.138 6.472-6.471-.943-.943-5.529 5.529z"
      ></path>
      <defs>
        <clipPath id="clip0_6041_727">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SucessOutlined;
