import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { IconButton, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";

import { t } from "i18next";

export const TrailTable = ({ data, isSysAdmin, isInternal, setAction, copyLink }) => {
  function formatType(type) {
    switch (type) {
      case "PRIVATE":
        return t("positionsManagement.private");
      case "PROTECTED":
        return t("positionsManagement.protected");
      case "PUBLIC":
        return t("positionsManagement.public");
      default:
        return "-";
    }
  }

  const internalRecruitment = JSON.parse(localStorage.getItem("tenantObj"))?.internalRecruitment;

  return (
    <>
      <TableBody>
        {data.length > 0 &&
          data.map((trail, i) => (
            <TableRow key={trail.id}>

              <TableCell>
                <span style={{ whiteSpace: "normal" }}>
                  {trail?.title}
                </span>
              </TableCell>

              <TableCell>{formatType(trail?.visibility)}</TableCell>

              <TableCell>
                {trail?.subscribedCount +
                  " " +
                  t("positionsManagement.candidates")}
              </TableCell>

              <TableCell>{`${trail?.qualifiedCount} ${t(
                "positionsManagement.candidates"
              )}`}
              </TableCell>

              <TableCell>
                {trail?.published
                  ? t("positionsManagement.published")
                  : t("positionsManagement.draft")}
              </TableCell>

              <TableCell>
                <Tooltip
                  title={t("coursesManagement.copyTrailLink")}
                >
                  <IconButton onClick={() => copyLink(trail)}>
                    <ShareIcon />
                  </IconButton>
                </Tooltip>

                {(isSysAdmin || isInternal) && (
                  <>
                    {!trail?.published ? (
                      <Tooltip title={t("positionsManagement.draftJob")}>
                        <IconButton
                          disabled={!internalRecruitment}
                          onClick={() =>
                            setAction({ data: trail, type: "edit" })
                          }
                        >
                          <DriveFileRenameOutlineIcon
                            style={{ color: "orange" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        onClick={() => setAction({ data: trail, type: "edit" })}
                        disabled={!internalRecruitment}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </TableCell>

            </TableRow>
          ))}

        {data.length === 0 && (
          <TableRow>
            <TableCell colSpan={6} sx={{ textAlign: "center" }}>
              {t("positionsManagement.noResultsFound")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
}