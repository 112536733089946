import React from "react";
import { Alert, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as S from "./style";

interface IContactPJData {
  onClose: () => void;
  openModal?: boolean;
}

const ContactPJ = ({ onClose, openModal }: IContactPJData) => {
  const handleClose = () => {
    navigate("/login");
    onClose();
  };
  const navigate = useNavigate();

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <S.FullModalPJ>
        <S.ModalTitlePJ>Entraremos em contato</S.ModalTitlePJ>
        <Alert severity="info">
          Entraremos em contato com você para finalizar o cadastro de sua
          empresa.
        </Alert>
        <S.ContainerButtons>
          <S.buttonWhite
            data-cy="modalVerifyCompany"
            onClick={() => {
              navigate("/login");
            }}
            style={{
              width: 290,
              background: "#fff",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "#131212",
              color: "#131212",
            }}
          >
            OK
          </S.buttonWhite>
        </S.ContainerButtons>
      </S.FullModalPJ>
    </Modal>
  );
};

export default ContactPJ;
