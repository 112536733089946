import SearchIcon from "@mui/icons-material/Search";
import { Container } from "@mui/material";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { CardsPositions } from "../../components/CardsTrainingPlans";
import InfoPosition from "../../components/InfoPosition";
import Layout from "../../components/Layout";
import { noAccents } from "../../components/utils/masks";
import FormationService from "../../services/FormationService";
import UserService from "../../services/UserService";
import * as S from "./style";

export default function PositionList() {
  const [trainingPlans, setTrainingPlans] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const [currentData, setCurrentData] = useState({ id: null });
  const [mobile, setMobile] = useState(returnIfIsMobile());
  const [user, setUser] = useState();

  const handleClickCard = (item) => {
    navigate(`/position/${item?.company?.slug}/${item?.slug}`);
  };

  async function fetchData() {
    const resultTrail = await FormationService.getFormationProgress(0, 1000);
    setTrainingPlans(
      resultTrail
        .map((formation) => {
          const formattedItem = { ...formation };
          formattedItem.company.avatar =
            formation?.company?.avatar?.fileUrl ?? null;
          return formattedItem;
        })
        .sort((a, b) => (a.percentage < b.percentage ? -1 : 1))
    );
  }

  function returnIfIsMobile() {
    const width = window.document.body.offsetWidth;
    return width <= 999;
  }

  function onClickCard(item) {
    if (mobile) {
      handleClickCard(item);
    } else {
      setCurrentData(item);
    }
  }

  const setCurrentUser = async () => {
    if (localStorage.getItem("token")) {
      const currentUser = user || (await UserService.getUser());
      if (currentUser?.id) {
        setUser(currentUser);
      }
    }
  };

  useEffect(() => {
    setCurrentUser();
    if (search === "") {
      fetchData();
    } else {
      setTrainingPlans(
        trainingPlans?.filter((item) =>
          noAccents(item.title.toLowerCase()).includes(
            noAccents(search).toLowerCase()
          )
        )
      );
    }
  }, [search]);

  useEffect(() => {
    function setDropdownHeightEffect() {
      setMobile(returnIfIsMobile());
    }

    window.addEventListener("resize", setDropdownHeightEffect);
    return () => window.removeEventListener("resize", setDropdownHeightEffect);
  }, []);

  return (
    <Layout onlyHeader>
      <S.ContainerTotal>
        <Container maxWidth={"lg"} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <BackButton onClick={() => navigate("/dashboard")}>
            {t("positions.goBack")}
          </BackButton>

          <S.ContainerTitleAndSubtitle>
            <S.Title>{t("positions.progress")}</S.Title>
            <S.Subtitle>{t("positions.lastVisit")}</S.Subtitle>
          </S.ContainerTitleAndSubtitle>

          <S.ContainerInput>
            <Input
              className="inputMui"
              color="warning"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={(event) => setSearch(event.target.value)}
              placeholder={t("positions.searchPositions")}
              sx={{ width: "100%" }}
            />
          </S.ContainerInput>
          <S.TitleCards>{t("positions.enrolledPositions")}</S.TitleCards>
        </Container>

        {trainingPlans.length > 0 ? (
          <S.ContainerFulMainData>
            <S.ContainerCards isCourse={false} data-cy="dashboardTrailCard">
              {trainingPlans?.map((item) => {
                return (
                  <CardsPositions
                    isCourse={false}
                    equal={currentData?.id === item?.id}
                    item={item}
                    onClick={() => onClickCard(item)}
                  />
                );
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <S.Subtitle>{t("positions.endOfList")}</S.Subtitle>
              </div>
            </S.ContainerCards>
            <InfoPosition
              user={user}
              isCourse={false}
              mobile={mobile}
              item={!!currentData ? currentData : null}
            />
          </S.ContainerFulMainData>
        ) : (
          <S.ContainerFullNoResults>
            <S.Title>{t("positions.endOfList")}</S.Title>
            <S.Subtitle>{t("positions.noResults")}</S.Subtitle>
          </S.ContainerFullNoResults>
        )}
      </S.ContainerTotal>
    </Layout>
  );
}
