import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import RoleType from "../types/enums/RoleType";

interface PrivateRouteRolesProps {
  roles?: RoleType[];
  redirect?: string;
  isPublic?: boolean;
  hasAccess?: boolean;
}

export const PrivateRouteRoles: React.FC<PrivateRouteRolesProps> = ({
  roles = [RoleType.ROLE_SYSADMIN],
  redirect,
  children,
  isPublic,
  hasAccess,
}) => {
  const logged = localStorage.getItem("token");
  const userRoles = localStorage.getItem("userRoles");

  if (!isPublic && (!logged || !userRoles || hasAccess)) {
    localStorage.setItem("noAccess", "1");
    AuthService.logout();
    return <Navigate to="/" />;
  }

  if (!UserService.hasAccessFiltered(roles)) {
    return <Navigate to={redirect ? redirect : "/"} />;
  }
  return children ? <div>{children}</div> : <Outlet />;
};
