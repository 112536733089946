/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import * as React from "react";
import {
  Alert,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { t } from "i18next";
import * as T from "../../Global/Typography";
import * as S from "./style";
import CategoriesForm from "./Form";
import TrailCategoryService from "../../services/TrailCategoryService";
import { TableContainerLayout } from "../../components/TableContainerLayout";

export default function Categories() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [action, setAction] = React.useState("list");
  const [selected, setSelected] = React.useState<any>();
  const [search, setSearch] = React.useState<string>("");
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState<number>(1);

  const fetchData = async () => {
    const result = await TrailCategoryService.getTrailCategoryPaginated(
      page - 1,
      search
    );
    setTotalPages(result.totalPages);
    setRows(
      result.data.map((row) => {
        return {
          id: row.id,
          name: row.name,
        };
      })
    );
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    setPage(1);
    fetchData();
  }, [search]);

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleFeedbackAction = (
    type: string,
    newData: any,
    submitAction: string
  ) => {
    setAction(type === "success" ? "list" : "form");
    if (submitAction === "edit") {
      setRows(
        rows.map((row) => {
          if (row.id === newData.id) {
            row.name = newData.name;
          }
          return row;
        })
      );
    } else {
      const newRows = [newData, ...rows];
      setRows(newRows);
    }
  };

  const handleEdit = (id: number) => {
    setSelected({ id });
    setAction("form");
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
  };

  return (
    <S.ContainerFullPage>
      <T.Display>{t('categories.categories')}</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{ width: "100%", background: "white" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 150 }}>
                    <S.FontHeaderTable>{t('categories.id')}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: "80%" }}>
                    <S.FontHeaderTable>{t('categories.name')}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length > 0 &&
                  rows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell data-cy="categoryScreenItemName">
                        {item.name}
                      </TableCell>
                      <TableCell>
                        <div>
                          <IconButton>
                            <EqualizerIcon />
                          </IconButton>
                          <IconButton>
                            <EditOutlinedIcon
                              data-cy="categoryScreenEditIcon"
                              onClick={() => handleEdit(item.id)}
                            />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}

                {rows.length === 0 && (
                  <TableRow>
                    <TableCell
                      data-cy="categoryScreenNoResultsFound"
                      colSpan={5}
                      sx={{ textAlign: "center" }}
                    >
                      Nenhum resultado encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (
        <CategoriesForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => setAction("list")}
          dataToEdit={selected}
        />
      )}
    </S.ContainerFullPage>
  );
}
