import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";

export const acessToken = () => localStorage.getItem("token");

const apiUrl = process.env.REACT_APP_BASE_URL;
export const axiosApi = axios.create({
  baseURL: apiUrl,
});

const api = {
  get: (url: string, config = {}) =>
    acessToken() == null
      ? axiosApi.get(url, { headers: config })
      : axiosApi.get(url, {
          headers: { ...config, Authorization: acessToken() },
        }),

  put: (url: string, data = {}, config = {}) =>
    acessToken() == null
      ? axiosApi.put(url, data, {
          headers: config,
        })
      : axiosApi.put(url, data, {
          headers: { ...config, Authorization: acessToken() },
        }),

  post: (url: string, data = {}, config = {}) =>
    acessToken() == null
      ? axiosApi.post(url, data, {
          headers: config,
        })
      : axiosApi.post(url, data, {
          headers: { ...config, Authorization: acessToken() },
        }),

  delete: (url: string, config = {}) =>
    acessToken() == null
      ? axiosApi.delete(url, {
          headers: config,
        })
      : axiosApi.delete(url, {
          headers: { ...config, Authorization: acessToken() },
        }),

  patch: (url: string, data = {}, config = {}) =>
    acessToken() == null
      ? axiosApi.patch(url, data, {
          headers: config,
        })
      : axiosApi.patch(url, data, {
          headers: { ...config, Authorization: acessToken() },
        }),
};

axiosApi.interceptors.request.use(
  (config) => {
    const tenant = localStorage.getItem("tenant");
    const language = localStorage.getItem("language")||"pt";
    config.headers["x-tenant-id"] = tenant;
    config.headers["x-language"] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function getErrorMessageFromBackend(errorMessage: any) {
  if (errorMessage.endsWith(". ")) {
    return t(errorMessage.substring(0, errorMessage.length-2))
  }
  let parts = errorMessage.split("&")
  parts = parts.map((part) => {
    if (part.startsWith("$")) {
      return part.substring(1);
    }
    return t(part);
  });
  return parts.join("");
}

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 403) {
      localStorage.clear();
      localStorage.setItem("noAccess", "1");
      window.location.href = "/login";
      return Promise.reject(error);
    }
    const errorMessage = error?.response?.data?.message;
    if (errorMessage) {
      switch (error.response.status) {
        case 400:
          enqueueSnackbar(
            getErrorMessageFromBackend(errorMessage), {
              variant: "warning",
              className: 'warningNotification',
              autoHideDuration: 8000
            })
          break;
        case 404:
          enqueueSnackbar(
            getErrorMessageFromBackend(errorMessage), {
            variant: "warning",
            className: 'warningNotification',
            autoHideDuration: 8000
          })
          break;
        case 500:
          if (errorMessage?.includes("JWT")) {
            if (!window.location.href.includes("/login")) {
              window.location.href = "/login";
            }
          } else {
            enqueueSnackbar(getErrorMessageFromBackend(errorMessage), {variant: "error"});
          }
          break;
        case 401:
          if (!window.location.href.includes("/login")) {
            window.location.href = "/login";
            localStorage.removeItem("token");
          }
          break;
      }
    } else {
        let tempApi = axios.create({
            baseURL: apiUrl,
        })
        try {
            const response = await tempApi.get("/healthCheck")
            if (response?.data?.status != "Alive") {
                window.location.href = "/maintence";
            }
        } catch (e) {
            console.error(e)
            window.location.href = "/maintence";
        }
    }

    return Promise.reject(error);
  }
);

export default api;
