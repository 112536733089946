import { Button, TextField, styled as estyledMui } from "@mui/material";
import styled from "styled-components";

export const ButtonAdd = styled(Button)`
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  color: white !important;
  font-family: "Ubuntu" !important;
`;

export const ContainerTableMUI = styled.div`
  tbody tr td:nth-of-type(3) {
    white-space: pre-line;
  }

  tbody tr td:not(tbody tr td:nth-of-type(3)) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .MuiTableFooter-root {
    width: 914px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
`;

export const ContainerIcons = styled.div`
  width: 44%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 30px;
`;

export const CssTextField = estyledMui(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8C8C8C",
    border: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#8C8C8C",
      border: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8C8C8C",
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Ubuntu",
      },
    },
  },
});

export const ContainerButtonAndIcons = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  margin-top: 50px;
  width: 914px;
  display: flex;
  justify-content: space-between;
`;

export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const ContainerButton = styled.div`
  padding-left: 20px;
`;
