/* eslint-disable class-methods-use-this */
import { objectToURLSearchParams } from "../components/utils/http";
import { IPasswordDTO } from "../types/UserClient";
import RoleType from "../types/enums/RoleType";
import api, { axiosApi } from "./api";

class UserService {
  async sendCorporateLead(data) {
    await axiosApi.post("user/leadMail", data);
  }

  async sendEmailWhenForgetPassword(email: string) {
    await axiosApi({
      method: "post",
      url: `user/forgetPassword/${email}`,
    });
  }

  async verifyCodeResetPassword(code: string) {
    const result = await axiosApi({
      method: "post",
      url: `user/verifyCodeResetPassword/${code}`,
    });
    return result;
  }

  async checkIfPasswordsMatch(passwordsDTO: IPasswordDTO) {
    await axiosApi({
      method: "put",
      url: `user/checkIfPasswordsMatch`,
      data: passwordsDTO,
    });
  }

  async verifyAdmin() {
    const response = await api.get(`user/admin-verify`);
    return response.data;
  }

  async getUsers(search: string, page: number) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `user/page?filter=${filter}&page=${page}&size=5`
    );
    return response.data;
  }

  async signUp(userDTO) {
    const result = await axiosApi.post("user/client", userDTO);
    return result.data;
  }

  async deleteUser(id: string) {
    await api.delete(`user/${id}`);
  }

  async unBlockedUser(code: string) {
    const result = await axiosApi({
      method: "post",
      url: `user/unblocked/${code}`,
    });
    return result.data;
  }

  async ResendEmail(email: string) {
    const result = await axiosApi({
      method: "post",
      url: `user/resendEmail/${email}`,
    });
    return result.data;
  }

  async getUser() {
    const resultUser = await api.get("user");
    return resultUser.data;
  }

  async getUserNoAuthentication() {
    const resultUser = await axiosApi.get("user");
    return resultUser.data;
  }

  async getUserById(idUser) {
    const resultGetUserById = await api.get(`user/${idUser}`);
    return resultGetUserById.data;
  }

  async getUserNotes(idUser, idPosition) {
    const resultGetUserById = await api.get(`user/${idUser}/notes?formationId=${idPosition}`);
    return resultGetUserById.data;
  }

  async getPerformanceByPosition(idUser, idPosition) {
    const resultGetPerformanceByPosition = await api.get(
      `v2/performance/position/${idPosition}/${idUser}`
    );
    return resultGetPerformanceByPosition.data;
  }

  async getPerformanceByCourse(idUser, idCourse) {
    const resultGetPerformanceByCourse = await api.get(
      `v2/performance/course/${idCourse}/${idUser}`
    );
    return resultGetPerformanceByCourse.data;
  }

  async getQuestionsResponse(idUser, idCourse) {
    const resultGetQuestionsResponse = await api.get(`/challenges/attempts/${idUser}/${idCourse}`)
    return resultGetQuestionsResponse.data;
  }

  async getQuestionsResponseDetailed(idCourse) {
    const resultGetQuestionsResponseDetailed = await api.get(`/questions/response/${idCourse}`)
    return resultGetQuestionsResponseDetailed.data;
  }

  async createNewUser(userDTO) {
    const resultCreateNewUser = await api.post("user", userDTO);
    return resultCreateNewUser.data;
  }

  async updateUser(userDTO, id) {
    const resultUpdateUser = await api.put(`user/${id}`, userDTO);
    return resultUpdateUser.data;
  }
  async updateUserNotes(notesDTO) {
    const resultUpdateUser = await api.post(`user/notes`, notesDTO);
    return resultUpdateUser.data;
  }

  async updateClient(userDTO, id) {
    const resultUpdateUser = await api.put(`user/client/${id}`, userDTO);
    return resultUpdateUser.data;
  }
  async getUserToDropdown(visibilityFilter = null, skillHunterPool = null) {
    const url = "user/summary-aquarium?";
    const queryParams: any = {
      onlyExternalFormations: visibilityFilter,
      skillHunterPool: skillHunterPool,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultUserToDropdown = await api.get(
      url + objectToURLSearchParams(filteredParams)
    );

    return resultUserToDropdown.data.map(({ id, name }) => ({
      id,
      value: name,
    }));
  }

  async verifyIfProfileIsComplete() {
    const result = await api.get("user/check-profile-is-complete");
    return result.data;
  }

  async getValidRoles() {
    const resultValidRoles = await api.get("user/valid-roles");
    return resultValidRoles.data;
  }

  hasAccess(role, internalRecruitment?: boolean) {
    if (internalRecruitment) {
      return true;
    }
    const userRoles = this.getRoleFromStorage();
    return userRoles.some((userRole: { name: RoleType }) => {
      const userRoleValue = RoleType[userRole.name];
      return userRoleValue <= role;
    });
  }

  hasAccessFiltered(roles) {
    const userRoles = this.getRoleFromStorage();
    return userRoles.some((userRole: { name: RoleType }) => {
      const userRoleValue = RoleType[userRole.name];
      return roles.includes(userRoleValue);
    });
  }

  getRoleFromStorage() {
    try {
      return (
        JSON.parse(localStorage.getItem("userRoles")) || [
          { name: RoleType[RoleType.ROLE_CLIENT] },
        ]
      );
    } catch (error) {
      return [{ name: RoleType[RoleType.ROLE_CLIENT] }];
    }
  }

  async sendConfirmationPhone(newPhone: string) {
    return api.get(`user/confirm-phone?newPhone=${newPhone}`);
  }

  async acceptTerms() {
    return api.patch("user/accept-terms");
  }

  async updateLastLogin() {
    return api.patch("user/login");
  }

  async deleteClient(id: string) {
    return api.delete(`user/client/${id}`);
  }
}

export default new UserService();
