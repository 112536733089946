/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import * as React from "react";
import {
  Alert,
  Snackbar,
  Table,
  IconButton,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import {useNavigate} from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import * as T from "../../Global/Typography";
import api from "../../services/api";
import {UpdatedAt} from "./style";
import SkillsForm from "./Form";
import * as S from "./style";

import SkillService from "../../services/SkillService";
import {TableContainerLayout} from "../../components/TableContainerLayout";
import {ButtonDS} from "../Login/style";
import {ReportType, SummaryBy} from "../../services/ReportService";
import {generateQueryParamsStringFilterReportPage} from "../../components/utils/http";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import {convertToLocalDate} from "../../components/utils/masks";
import {t} from "i18next";

export default function Skills() {
  const [page, setPage] = React.useState<number>(1);
  const [rows, setRows] = React.useState<any[]>([]);
  const [action, setAction] = React.useState("list");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [selected, setSelected] = React.useState<any>();
  const [deleted, setDeleted] = React.useState(false);
  const [search, setSearch] = React.useState<string>("");
  const [totalPages, setTotalPages] = React.useState(0);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    const resultSkills = await SkillService.getSearchSkillManagement(page - 1, search);
    setTotalPages(resultSkills.totalPages);
    setRows(
      resultSkills.content.map((row: any) => {
        return {
          id: row.id,
          companyId: row.companyId,
          name: row.name,
          content: row.content,
          companyImg: row?.companyLogo,
          company: row?.company,
          editable: row?.editable,
          updatedAt: (
            <UpdatedAt>
              <span>{row?.lastEditUser}</span>
              <span>
                {row?.lastEditDate &&
                  convertToLocalDate(row?.lastEditDate)}
              </span>
            </UpdatedAt>
          ),
        };
      })
    );
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleEdit = (id) => {
    setSelected({id});
    setAction("form");
    setDeleted(false);
    setOpenEditDialog(false);
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
    setDeleted(false);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const handleFeedbackAction = async (
    type: string,
    newData: any,
    submitAction: string
  ) => {
    setShowAlert(true);
    setAction(type === "success" ? "list" : "form");
    setAlertType(type === "success" ? "success" : "error");
    if (submitAction === "edit") {
      setRows(
        rows.map((row) => {
          if (row.id === newData.id) {
            row = newData;
          }
          return row;
        })
      );
    } else {
      const newRows = [...rows, newData];
      setRows(newRows);
    }
    await fetchData();
  };

  const handleOpenEditDialog = (id) => {
    setSelected(id);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setSelected(undefined);
    setOpenEditDialog(false);
  };

  const handleFollowToReportPage = (id, userName) => {
    const queryParams = generateQueryParamsStringFilterReportPage(
      SummaryBy.Skill,
      id,
      null,
      ReportType.SkillFocus,
      userName
    );
    navigate(`/management/performance-report?${queryParams}`);
  };


  return (
    <S.ContainerFullPage>
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("skills.areYouSureYouWantToEditThisSkill")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{t("skills.editingMayAffectHowTheRelatedCoursesWork")}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions data-cy="confirmEditionButtonSkills">
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("skills.cancel")}
            onClick={handleCloseEditDialog}
          />
          <ButtonDS
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("skills.edit")}
            onClick={() => handleEdit(selected)}
          />
        </DialogActions>
      </Dialog>
      <T.Display data-cy="skillsH1">{t("skills.skills")}</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{width: "100%", background: "white"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: '30%'}}>
                    <S.FontHeaderTable>{t("skills.name")}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: '30%'}}>
                    <S.FontHeaderTable>{t("skills.content")}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: '15%'}}>
                    <S.FontHeaderTable>{t("skills.company")}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: '10%'}}>
                    <S.FontHeaderTable>
                      {t("skills.lastEditedBy")}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: '10%'}}>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length > 0 &&
                  rows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell data-cy="skillTableNameField">
                        <span style={{whiteSpace: "normal"}}>{item.name}</span>
                      </TableCell>
                      <TableCell data-cy="skillTableContentField">
                        {item.content}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <S.CompanyIcon
                            src={item?.companyImg || skillHunterPositionIcon}
                            alt=""
                          />
                          {item?.company ||
                            "SkillHunter"}
                        </div>
                      </TableCell>
                      <TableCell style={{width: "20%"}}>{item.updatedAt}</TableCell>
                      <TableCell style={{textAlign: 'end', width: "10%"}}>
                        <div>
                          <IconButton
                            onClick={() => handleFollowToReportPage(item.id, item.name)}
                          >
                            <EqualizerIcon/>
                          </IconButton>
                          {item?.editable && (
                            <IconButton
                              data-cy="editIconSkills"
                              onClick={() => handleOpenEditDialog(item.id)}
                            >
                              <EditOutlinedIcon/>
                            </IconButton>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}

                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{textAlign: "center"}}>
                      {t("skills.noResultsFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (
        <SkillsForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          dataToEdit={selected}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType === "success" ? "success" : "error"}
          sx={{width: "100%"}}
          data-cy="skillsAlerts"
        >
          {alertType === "success"
            ? deleted
              ? "Habilidade deletada com sucesso"
              : selected
                ? "Habilidade editada com sucesso"
                : "Habilidade inserida com sucesso"
            : "Erro ao inserir habilidade"}
        </Alert>
      </Snackbar>
    </S.ContainerFullPage>
  );
}
