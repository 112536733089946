import styled from "styled-components";

export const ContainerCurrentPage = styled.div`
p {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #696969;
}

`