import styled from "styled-components";

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const ContainerCards = styled.div`
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

export const ContainerLabel = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1rem;
  color: #131212;
`;

export const ContainerGap = styled.div`
  margin-top: 2.3rem;

  p {
    font-size: 14px;
  }
`;
