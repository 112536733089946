/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import * as S from "./style";

import {
  Alert,
  Box,
  Chip,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import * as T from "../../Global/Typography";
// import QuestionsForm from "./Form";
import {GradingOutlined} from "@mui/icons-material";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import {TableContainerLayout} from "../../components/TableContainerLayout";
import AIChallengesReviewForm from "./ReviewForm";
import QuestionsBatchesService from "../../services/QuestionsBatchesService";
import {useEffect} from "react";

const montaMensagemDeAlerta = (
  alertType: String,
  deleted: boolean,
  customMessage: String,
  submitAction: String | null
) => {
  if (alertType === "success") {
    if (deleted) {
      return "Desafio deletado com sucesso";
    }
    return submitAction === "add"
      ? "Desafio inserido com sucesso"
      : "Desafio editado com sucesso";
  }

  return customMessage;
};

export interface IQuestionBatch {
  id: number;
  formation: {
    id: number;
    title: string;
  };
  company: {
    id: number;
    companyName: string;
    avatar: string;
  };
  status: string;
  progress: number;
}

const testData: IQuestionBatch[] = [
  {
    id: 1,
    formation: {
      id: 123,
      title: "Desenvolvedor de Software Jr",
    },
    company: {
      id: 456,
      companyName: "Firedev",
      avatar:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hzu6wh4cig5rhuge9xgh",
    },
    status: "Aguardando Avaliação",
    progress: 100,
  },
  {
    id: 2,
    formation: {
      id: 789,
      title: "Gestor de Marketing",
    },
    company: {
      id: 456,
      companyName: "Firedev",
      avatar:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hzu6wh4cig5rhuge9xgh",
    },
    status: "Avaliado",
    progress: 100,
  },
  {
    id: 3,
    formation: {
      id: 987,
      title: "Analista de Finanças Jr",
    },
    company: {
      id: 456,
      companyName: "Firedev",
      avatar:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hzu6wh4cig5rhuge9xgh",
    },
    status: "Em Progresso",
    progress: 20,
  },
  {
    id: 4,
    formation: {
      id: 654,
      title: "Analista de Negócios Pl",
    },
    company: {
      id: 456,
      companyName: "Firedev",
      avatar:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hzu6wh4cig5rhuge9xgh",
    },
    status: "Em Progresso",
    progress: 50,
  },
  {
    id: 5,
    formation: {
      id: 321,
      title: "Engenheiro De Software Sr",
    },
    company: {
      id: 456,
      companyName: "Firedev",
      avatar:
        "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/hzu6wh4cig5rhuge9xgh",
    },
    status: "Em Progresso",
    progress: 80,
  },
];

export default function AIChallenges() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState<number>(1);
  const [search, setSearch] = React.useState<string>("");
  const [action, setAction] = React.useState("list");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState<String>("");
  const [selected, setSelected] = React.useState<any>();
  const [deleted, setDeleted] = React.useState(false);

  const fetchData = async () => {
    const resultQuestion = await QuestionsBatchesService.getQuestionsBatches(
      search,
      page - 1
    );
    // const resultQuestion = { totalPages: 1, content: testData };
    setTotalPages(resultQuestion.totalPages);
    setRows(resultQuestion?.content);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [page, action]);

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const handleFeedbackAction = async (
    type: string,
    newData: any,
    submitAction: string,
    alertMessage: string | null
  ) => {
    setShowAlert(true);
    setAction(type === "success" ? "list" : "form");
    setAlertType(type === "success" ? "success" : "error");
    setAlertMessage(
      montaMensagemDeAlerta(type, deleted, alertMessage, submitAction)
    );
    if (type === "success") {
      if (submitAction == "edit") {
        setRows(
          rows.map((row) => {
            if (row.id === newData.id) {
              row.status = newData.status;
            }
            return row;
          })
        );
      }
    }
  };

  const handleEdit = (id: number) => {
    setSelected({id});
    setAction("form");
  };

  const getStatusBadge = (status) => {
    const badgeColor = {
      FINISHED: "success",
      IN_PROGRESS: "info",
      NOT_INITIATED: "warning",
      ERROR: "error",
    };

    const statusText = {
      FINISHED: "Finalizado",
      ERROR: "Falha na geração",
      IN_PROGRESS: "Em progresso",
      NOT_INITIATED: "Não iniciado",
    };

    const defaultColor = "success";

    return (
      <Chip
        label={statusText[status]}
        color={badgeColor[status] || defaultColor}
      ></Chip>
    );
  };

  return (
    <S.ContainerFullPage>
      <T.Display data-cy="questionsH1">Aprovar Desafios Automáticos</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          hideAddButton
        >
          <TableContainer sx={{width: "100%", background: "white"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{width: "20%"}}>
                    <S.FontHeaderTable>Cargo</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "15%"}}>
                    <S.FontHeaderTable>Empresa</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "15%"}}>
                    <S.FontHeaderTable>Status</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "15%"}}>
                    <S.FontHeaderTable>
                      Questões
                      <br />
                      Avaliadas/Total
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{width: "25%"}}>
                    <S.FontHeaderTable>Progresso</S.FontHeaderTable>
                  </TableCell>

                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>{row.formation?.title || row.trail?.name}</TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <S.CompanyIcon
                            src={
                              row?.company?.avatar?.fileUrl ||
                              skillHunterPositionIcon
                            }
                            alt={skillHunterPositionIcon}
                          />
                          {row?.company?.tradeName ||
                            row?.company?.name ||
                            row?.company?.companyName ||
                            "SkillHunter"}
                        </div>
                      </TableCell>
                      <TableCell data-cy="questionsH1">
                        {getStatusBadge(row.status)}
                      </TableCell>
                      <TableCell>
                        {row.evaluatedChallenges}/{row.totalChallenges}
                      </TableCell>
                      <TableCell>
                        <S.ContainerProgressBar>
                          <Box sx={{width: "100%", mr: 1}}>
                            <S.BorderLinearProgress
                              variant="determinate"
                              value={row.progress}
                            />
                          </Box>
                          <Box sx={{minWidth: 35}}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                            >{`${Math.round(row.progress)}%`}</Typography>
                          </Box>
                        </S.ContainerProgressBar>
                      </TableCell>
                      <TableCell data-cy="questionTableEditButton">
                        {row.status !== "Em Progresso" && (
                          <IconButton>
                            <GradingOutlined
                              color={
                                row.status === "Avaliado"
                                  ? "success"
                                  : "warning"
                              }
                              onClick={() => handleEdit(row.id)}
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{textAlign: "center"}}>
                      Nenhum resultado encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (
        <AIChallengesReviewForm
          dataToEdit={selected}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          feedbackAction={handleFeedbackAction}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alertType === "success" ? "success" : "error"}
          sx={{width: "100%"}}
          data-cy="questionAlerts"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </S.ContainerFullPage>
  );
}
