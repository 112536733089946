import styled from "styled-components";
import { IInputPasswordProps } from ".";

export const ContainerPassword = styled.div<IInputPasswordProps>`
  margin-bottom: 1rem;
  display: flex;
  width: ${({ width }) => (width ? `${width}rem` : "100%")};
  height: ${({ height }) => (height ? `${height}rem` : "3rem")};
`;

export const ContainerInputPassword = styled.div<IInputPasswordProps>`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "1px solid #8C8C8C"};
  border-radius: 5px;
`;

export const ContainerInputAndIcon = styled.div<IInputPasswordProps>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ButtonToggleVisibilityOfPassword = styled.button<IInputPasswordProps>`
  width: auto;
  height: auto;
  background: transparent;
  border: 0;

  svg {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
    color: ${({ colorIcon }) => (colorIcon ? colorIcon : "#8C8C8C")};
  }
`;

export const InputPassword = styled.input<IInputPasswordProps>`
  padding-left: 1rem;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
  margin-right: 50px;
  height: 100%;
  background-color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  color: #131212;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
`;

export const ErrorMessagem = styled.p<IInputPasswordProps>`
  margin-top: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: ${({ errorMessageColor }) =>
    errorMessageColor ? `${errorMessageColor}` : "#F5222D"};
`;

export const Label = styled.p`
  width: 100%;
  margin: 0.3rem 0;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;
