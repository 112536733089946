import { Box, Switch, alpha, styled } from "@mui/material";

export const DashboardCard = styled(Box)({
  backgroundColor: "#FFFFFF",
  border: "1px solid #aeaeae",
  borderRadius: "3px",
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 15,
});

export const DashboardContinueCard = styled(Box)({
  backgroundColor: "#FFFFFF",
  border: "1px solid #aeaeae",
  borderRadius: "3px",
  paddingTop: 15,
});

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  // Estilização para quando o switch está ATIVADO
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#ff026f",
    "&:hover": {
      backgroundColor: alpha("#ff026f", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#ff026f",
  },

  // Estilização para quando o switch está DESATIVADO
  "& .MuiSwitch-switchBase": {
    color: "#ff026f",
    "&:hover": {
      backgroundColor: alpha("#ff026f", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#ff026f",
  },
}));
