import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {t} from "i18next";
import {Divider} from "@mui/material";
import {SelectOrange} from "../../../components/SelectOrange";
import TrailCategoryService from "../../../services/TrailCategoryService";

export default function Courses({
                                  filter,
                                  setFilter,
                                  categoriesData
                                }) {

  return (
    <S.ContainerDropdowns>
      <S.ContainerDropdownsCenter>
        <S.ContainerDropdownsMain>
          <SelectOrange
            placeholder={t("home.todosCursos")}
            options={categoriesData.length > 0 ? categoriesData : []}
            selected={filter?.courseCategoryCourseSelected}
            onSelect={(e) => {
              setFilter({...filter, courseCategoryCourseSelected: e});
            }}
            style={{maxWidth: 625}}
            onChange={(e) => {e}}
            listAbsolute
          />
        </S.ContainerDropdownsMain>
      </S.ContainerDropdownsCenter>
    </S.ContainerDropdowns>
  );
}
