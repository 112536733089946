import styled from "styled-components";

export const ContainerDropdown = styled.div`
  margin-top: 37px;
  margin-bottom: 37px;
`;

export const ContainerGap = styled.div`
  margin-top: 2.3rem;
`;

export const ContainerToResponsiveInput = styled.div`
  margin-top: 2.3rem;

  @media (max-width: 1024px) {
  }
`;

export const Form = styled.form`
  button[type="button"] {
    margin-top: 2.3rem;
  }
`;
