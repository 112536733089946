import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Button } from "design-system-firedev";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import zod from "zod";
import AvatarInput from "../../../components/AvatarInput";
import { Button as ButtonComp } from "../../../components/Button";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { ResponsiveInputText } from "../../../components/ResponsiveInputText";
import { Select } from "../../../components/Select";
import { TableContainerLayout } from "../../../components/TableContainerLayout";
import { cnpjMask, cuitMask, noAccents } from "../../../components/utils/masks";
import { Company } from "../../../models/Company";
import { CompanyParent } from "../../../models/CompanyParent";
import CompanyService from "../../../services/CompanyService";
import UserService from "../../../services/UserService";
import { axiosApi } from "../../../services/api";
import * as S from "./style";
import { ContainerGap } from "./style";

interface ICompanyForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: Company | undefined;
}

const formKeys: (keyof Company)[] = [
  "cnpj",
  "companyName",
  "emailDomain",
  "tradeName",
  "parent",
  "about",
  "avatar",
];

const MAX_FILE_SIZE = 200000;
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export default function CompanyForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: ICompanyForm) {
  const cepRegex = /^\d{5}-\d{3}|\d{8}$/;
  const codigoPostalRegex = /^\d{4}$/;
  const addressSchema = zod.object({
    country: zod.any().refine(
      (value) => {
        return value;
      },
      {
        message: t("empresas.typeInValidCountry"),
      }
    ),
    cep: zod
      .string()
      .min(1, t("users.validZipCode"))
      .optional()
      .refine(
        (value) => {
          return addressCountrySelected?.id === 10
            ? codigoPostalRegex.test(value)
            : cepRegex.test(value);
        },
        {
          message: t("users.validZipCode"),
        }
      ),
    city: zod
      .any({
        required_error: t("signUp.selectCity"),
        invalid_type_error: t("signUp.selectCity"),
      })
      .refine(
        (value) => {
          return value;
        },
        {
          message: t("signUp.selectCity"),
        }
      ),
    state: zod
      .any({
        required_error: t("signUp.selectState"),
        invalid_type_error: t("signUp.selectState"),
      })
      .refine(
        (value) => {
          return value;
        },
        {
          message: t("signUp.selectState"),
        }
      ),
    street: zod
      .string({
        required_error: t("empresas.validStreet"),
      })
      .min(1, t("empresas.validStreet"))
      .max(255, t("empresas.maxFieldSize")),
    complement: zod.string().max(255, t("empresas.maxFieldSize")).optional().nullable(),
  });

  const formSchema = zod.object({
    country: zod.any().refine(
      (value) => {
        return value != null;
      },
      {
        message: t("empresas.typeInValidCountry"),
      }
    ),
    cnpj: zod
      .string()
      .optional()
      .refine(
        (value) => {
          if (!countrySelected) {
            return false;
          }
          if (countrySelected?.id === 10) {
            return true;
          } else {
            return value?.length === 18;
          }
        },
        {
          message: t("empresas.typeInValidCRN"),
        }
      ),
    cuit: zod
      .string()
      .optional()
      .refine(
        (value) => {
          if (!countrySelected) {
            return false;
          }
          if (countrySelected?.id !== 10) {
            return true;
          } else {
            return value?.length === 13;
          }
        },
        {
          message: t("empresas.typeInValidCUIT"),
        }
      ),
    companyName: zod
      .string()
      .min(1, t("empresas.typeInValidCompanyLegalName"))
      .max(255, t("empresas.companyName")),
    tradeName: zod
      .string()
      .refine((value) => value.trim()?.length > 0, {
        message: t("empresas.typeInValidCompanyTradeName"),
      })
      .refine((value) => value?.length <= 255, {
        message: t("empresas.fantasyNameMax"),
      }),
    emailDomain: zod
      .string()
      .max(255, t("empresas.emailMax"))
      .nullable()
      .optional(),
    parent: zod.any(),
    about: zod.string().max(500, t("empresas.aboutCompanyMax")).optional(),
    avatar: zod.any(),
    coursePublishing: zod.boolean().optional(),
    internalRecruitment: zod.boolean().optional(),
  });

  const [parents, setParents] = React.useState<CompanyParent[]>([]);
  const [isSysAdmin, setIsSysAdmin] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [addresses, setAddresses] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);
  const [loadingCep, setLoadingCep] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [stateSelected, setStateSelected] = React.useState<any>();
  const [countrySelected, setCountrySelected] = React.useState<any>();
  const [addressCountrySelected, setAddressCountrySelected] =
    React.useState<any>(null);
  const [disableAddress, setDisableAddress] = React.useState(true);
  const [editingId, setEditingId] = React.useState();
  const [excludeIndex, setExcludeIndex] = React.useState<number>();
  const [openExcludeModal, setOpenExcludeModal] = React.useState(false);
  const [openExcludeCompanyModal, setOpenExcludeCompanyModal] =
    React.useState(false);
  const [disableCity, setDisableCity] = React.useState(true);
  const [coursePublishing, setCoursePublishing] =
    React.useState<boolean>(false);

  const labels = {
    country: t("empresas.country"),
    goback: t("empresas.back"),
    add: t("empresas.add"),
    save: t("empresas.save"),
    company: {
      id: "ID",
      cnpj: countrySelected == 10 ? t("empresas.cuit") : t("empresas.crn"),
      cuit: "CUIT",
      companyName: t("empresas.companyLegalName"),
      tradeName: t("empresas.companyTradeName"),
      emailDomain: t("empresas.emailDomain"),
      parent: t("empresas.parentCompany"),
      about: t("empresas.aboutTheCompany"),
      avatar: t("empresas.OrganizationsPhoto"),
      coursePublishing: t("empresas.publishCoursePermission"),
      internalRecruitment: t("empresas.publishCoursePermission"),
    },
    address: {
      cep: t("companyAddress.cep"),
      state: t("companyAddress.state"),
      city: t("companyAddress.city"),
      street: t("users.address"),
      complement: t("users.addressSecond"),
    },
  };
  const isEditing = typeof dataToEdit !== "undefined";

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Company>({
    resolver: zodResolver(formSchema),
  });

  const {
    control: controlAddAddress,
    handleSubmit: handleSubmitAddAddress,
    setValue: setValueAddAddress,
    getValues: getValuesAddAddress,
    formState: { errors: errorsAddAddress },
  } = useForm({
    resolver: zodResolver(addressSchema),
  });

  async function getCities(stateParam?: any) {
    try {
      const citiesResponse = await axiosApi.get(
        `/address/cities?stateId=${stateParam}`
      );
      await setCities(citiesResponse?.data);
      if (getValuesAddAddress("cep")?.length > 7) {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${getValuesAddAddress("cep")}`
        );
        const cityFound = await citiesResponse?.data.find(
          (item) =>
            item.name?.toLowerCase() == response.data?.city?.toLowerCase()
        );

        const citySelectedFilter = citiesResponse?.data.find((option) => {
          return (
            option.id ==
            (cityFound?.id.toString() || getValuesAddAddress("city"))
          );
        });

        if (citySelectedFilter) {
          await setDisableCity(true);
        } else {
          await setDisableCity(false);
        }
        await setValueAddAddress("city", citySelectedFilter);
      }
      return citiesResponse?.data;
    } catch (err) {
      return false;
    }
  }

  async function getStates(countryId) {
    try {
      let statesResponse;
      if (!countryId) {
        statesResponse = await axiosApi.get(`/address/states`);
      } else {
        statesResponse = await axiosApi.get(`/address/states/${countryId}`);
      }
      setStates(statesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  async function getCountries() {
    try {
      const countriesResponse = await axiosApi.get(`/address/countries`);
      setCountries(countriesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  function clearAllModalFields() {
    setDisableAddress(false);
    setAddressCountrySelected(null)
    setValueAddAddress("country", null, { shouldValidate: false });
    setValueAddAddress("street", null, { shouldValidate: false });
    setValueAddAddress("complement", null, { shouldValidate: false });
    setValueAddAddress("state", null, { shouldValidate: false });
    setValueAddAddress("city", null, { shouldValidate: false });
    setValueAddAddress("cep", "", { shouldValidate: false });
  }

  async function getCep(text) {
    try {
      const cep = text.replace("-", "");
      const prevCep = String(getValuesAddAddress("cep")).replace("-", "");

      if (cep?.length === 8) {
        setLoadingCep(true);
        const response = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`
        );

        const state = states.find(
          (item) =>
            item.fu?.toLowerCase() === response.data?.state?.toLowerCase()
        );
        setStateSelected(state || getValuesAddAddress("state"));
        setValueAddAddress("state", state || getValuesAddAddress("state"));

        const citiesResponse = await getCities(state?.id.toString());
        await setCities(citiesResponse);

        const cityFound = await citiesResponse.find(
          (item) =>
            item.name?.toLowerCase() == response.data?.city?.toLowerCase()
        );

        const citySelectedFilter = citiesResponse.find((option) => {
          return (
            option.id ==
            (cityFound?.id.toString() || getValuesAddAddress("city"))
          );
        });

        setValueAddAddress("city", citySelectedFilter);
        if (!citySelectedFilter) {
          setDisableCity(false);
        } else {
          setDisableCity(true);
        }
        setDisableAddress(true);
        setLoadingCep(false);
        return true;
      } else if (cep?.length <= 7) {
        if (prevCep?.length === 8) {
          await clearAllModalFields();
        }
        return false;
      }
    } catch (err) {
      console.error(err);
      setDisableAddress(false);
      setLoadingCep(false);
      return false;
    }
  }

  const fetchData = async () => {
    const resultCurrentUser = await UserService.getUser();
    if (
      resultCurrentUser?.roles.some((item) => item.name === "ROLE_SYSADMIN")
    ) {
      setIsSysAdmin(true);
    }
    const resultById = await CompanyService.getById(dataToEdit.id);
    if (resultById?.addresses != null) {
      setAddresses(
        resultById.addresses.map((address) => {
          return {
            id: address?.id,
            country: address?.country,
            cep: address?.postalCode,
            city: address?.city,
            state: address?.state,
            street: address?.street,
            complement: address?.complement,
          };
        })
      );
    }
    if (!resultById.parent) {
      resultById.parent = null;
    }
    if (!resultById.about) {
      resultById.about = "";
    }
    formKeys.forEach((key) => {
      setValue(key, resultById[key]);
    });
    setValue("country", {
      id: resultById?.country?.id,
      name: resultById?.country?.name,
    });
    if (resultById?.country?.id === 10) {
      setValue("cuit", cuitMask(resultById?.cnpj));
    }
    setCountrySelected(getValues("country"));
    setValue("parent", {
      id: resultById?.parent?.id,
      name: resultById?.parent?.name,
    });
    setValue("coursePublishing", resultById.coursePublishing);
    setValue("internalRecruitment", resultById.internalRecruitment)
  };

  const fetchDataSave = async () => {
    const resultCurrentUser = await UserService.getUser();
    if (resultCurrentUser.company.parent) {
      setValue("parent", {
        id: resultCurrentUser?.company?.parent?.id,
        name: resultCurrentUser?.company?.parent?.name,
      });
      return;
    }
    if (
      resultCurrentUser?.roles.some((item) => item.name === "ROLE_SYSADMIN")
    ) {
      setIsSysAdmin(true);
      setValue("parent", {
        id: resultCurrentUser?.company?.id,
        name: resultCurrentUser?.company?.tradeName,
      });
      return;
    }
    setValue("parent", {
      id: resultCurrentUser?.company?.id,
      name: resultCurrentUser?.company?.tradeName,
    });
  };

  const fetchParents = async () => {
    const result = await CompanyService.getAll("");
    let parentsArr = result.content
      .map(({ id, name }) => {
        return { id, name };
      })
      .filter((item) => item?.id !== dataToEdit?.id);
    setParents([...parentsArr]);
  };

  async function addAddress(data) {
    if (typeof editingId != "undefined") {
      data.id = editingId;
    }
    if (editingIndex !== null) {
      const updatedAddresses = addresses.map((address, i) =>
        i === editingIndex ? data : address
      );
      await setAddresses(updatedAddresses);
    } else {
      await setAddresses([data, ...addresses]);
    }
    await clearAllModalFields();
    setEditingIndex(null);
    setShowModal(false);
    setDisableAddress(true);
    setDisableCity(true);
    setEditingId(undefined);
  }

  useEffect(() => {
    if (isEditing) {
      fetchData();
    } else {
      fetchDataSave();
      setValue("about", "");
    }
    fetchParents();
  }, [isEditing]);

  useEffect(() => {
    setStateSelected(null);
    getStates(addressCountrySelected?.id);
    if (addressCountrySelected?.id === 26) {
      setDisableAddress(true);
      setDisableCity(true);
    } else {
      setDisableAddress(false);
    }
  }, [addressCountrySelected]);

  function format(text) {
    return noAccents(String(text))?.toUpperCase();
  }

  function handleChangeCountry(value) {
    setCountrySelected(value);
  }

  function handleChangeAddressCountry(value) {
    setValueAddAddress("state", null, { shouldValidate: false });
    setValueAddAddress("city", null, { shouldValidate: false });
    setValueAddAddress("cep", "", { shouldValidate: false });
    setAddressCountrySelected(value);
  }

  const handleSave = async (data: any) => {
    try {
      if (data?.cuit) {
        data.cnpj = data?.cuit;
      }
      let result = {};
      let submitAction = "add";
      if (!data.parent?.id) {
        data.parent = null;
      } else {
        data.parentId = data.parent?.id;
      }
      if (!data.avatar) {
        data.avatar = null;
      }

      data.addresses = addresses.map((address) => {
        return {
          id: address?.id,
          country: address?.country,
          postalCode: address?.cep,
          cityId: address?.city?.id,
          stateId: address?.state?.id,
          street: address?.street,
          complement: address?.complement,
        };
      });
      if (isEditing) {
        await CompanyService.update(dataToEdit?.id, data);
        submitAction = "edit";
        enqueueSnackbar(t("empresas.companyUpdatedSuccessfully"), {
          variant: "success",
        });
      } else {
        await CompanyService.create(data);
        enqueueSnackbar(t("empresas.companyAddedSuccessfully"), {
          variant: "success",
        });
      }
      feedbackAction("success", result, submitAction);
    } catch (err: any) {
      console.error(err);
    }
  };

  const renderParams = (field, address = false) => {
    return {
      ...field,
      label: address ? labels.address[field.name] : labels.company[field.name],
      height: 3.375,
      placeholder: address
        ? labels.address[field.name]
        : labels.company[field.name],
      errorMessage: errors[field.name]?.message,
      borderColor: errors[field.name] ? "#F5222D" : "#8c8c8c",
    };
  };

  const inputParams = (control, fieldName: string) => {
    return {
      name: fieldName,
      control: control,
    };
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    setValueAddAddress("city", null);
    if (stateSelected?.id) {
      getCities(stateSelected?.id);
    } else {
      setCities([]);
    }
  }, [stateSelected]);

  function removeAddress() {
    setAddresses(
      addresses
        .map((address, index) => {
          if (index !== excludeIndex) {
            return address;
          }
        })
        .filter((address) => address !== undefined)
    );
    setOpenExcludeModal(false);
  }

  function removeCompany() {
    CompanyService.deleteById(dataToEdit?.id);
    enqueueSnackbar("Empresa deletada com sucesso", {
      variant: "success",
    });
    feedbackAction("success", {}, "edit");
    onBackPress();
  }

  return (
    <S.Form onSubmit={handleSubmit(handleSave)}>
      <Button
        type="button"
        onClick={onBackPress}
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text={labels.goback}
      />
      <S.Row>
        <Controller
          {...inputParams(control, "avatar")}
          render={({ field }) => (
            <AvatarInput
              legend={labels.company[field.name]}
              value={field?.value || ""}
              onChange={field.onChange}
            />
          )}
        />
        <S.RemainSpace>
          <S.ContainerDropDownSimpleSelect>
            <Controller
              name="country"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <Select
                  selected={value}
                  onSelect={(newValue) => {
                    handleChangeCountry(newValue);
                    onChange(newValue);
                  }}
                  placeholder={labels[name]}
                  error={errors[name]?.message}
                  label={labels[name]}
                  options={countries}
                />
              )}
            />
          </S.ContainerDropDownSimpleSelect>
          <ContainerGap>
            <Controller
              {...inputParams(
                control,
                countrySelected?.id === 10 ? "cuit" : "cnpj"
              )}
              render={({ field }) => (
                <ResponsiveInputText
                  {...renderParams(field)}
                  value={
                    countrySelected?.id === 10
                      ? cuitMask(field?.value || "")
                      : cnpjMask(field?.value || "")
                  }
                  onChange={(value) =>
                    countrySelected?.id === 10
                      ? field.onChange(cuitMask(value?.target.value))
                      : field.onChange(cnpjMask(value?.target.value))
                  }
                  maxLength={countrySelected?.id === 10 ? "13" : "18"}
                />
              )}
            />
          </ContainerGap>
          <ContainerGap data-cy="companyFormNameInput">
            <Controller
              {...inputParams(control, "companyName")}
              render={({ field }) => (
                <ResponsiveInputText
                  {...renderParams(field)}
                  errorMessage={
                    errors?.companyName?.message
                      ? t("empresas.typeInValidCompanyLegalName")
                      : ""
                  }
                />
              )}
            />
          </ContainerGap>
          <ContainerGap data-cy="companyFormTradeName">
            <Controller
              {...inputParams(control, "tradeName")}
              render={({ field }) => (
                <ResponsiveInputText
                  {...renderParams(field)}
                  errorMessage={
                    errors?.tradeName?.message
                      ? t("empresas.typeInValidCompanyTradeName")
                      : ""
                  }
                />
              )}
            />
          </ContainerGap>
        </S.RemainSpace>
      </S.Row>
      <ContainerGap data-cy="companyFormEmailInput">
        <Controller
          {...inputParams(control, "emailDomain")}
          render={({ field }) => (
            <ResponsiveInputText {...renderParams(field)} />
          )}
        />
      </ContainerGap>
      <S.ContainerDropDownSimpleSelect>
        <Controller
          name="parent"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <Select
              showSearch
              selected={value}
              disabled={!isSysAdmin}
              onSelect={(newValue) => onChange(newValue)}
              placeholder={labels.company[name]}
              error={errors[name]?.message}
              label={labels.company[name]}
              options={parents}
            />
          )}
        />
      </S.ContainerDropDownSimpleSelect>
      <ContainerGap data-cy="companyFormAboutInput">
        <Controller
          {...inputParams(control, "about")}
          render={({ field: { name, value, onChange } }) => (
            <div>
              <S.Label>{labels.company[name]}</S.Label>
              <S.TextArea
                value={value || ""}
                onChange={onChange}
                placeholder={labels.company[name]}
                borderColor={errors[name]?.message}
              />
              <S.ErrorMessage data-cy="errorMessage">
                {errors[name]?.message}
              </S.ErrorMessage>
            </div>
          )}
        />
      </ContainerGap>

      <TableContainerLayout
        search={search}
        hideFilter={true}
        setSearch={setSearch}
        hidePagination
        onClickAddButton={() => {
          setShowModal(true);
          setDisableAddress(addressCountrySelected?.id === 26);
          setDisableCity(true);
        }}
      >
        <TableContainer sx={{ width: "100%", background: "white" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("companyAddress.cep")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>{t("users.address")}</S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("users.addressSecond")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("companyAddress.city")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("companyAddress.state")}
                  </S.FontHeaderTable>
                </TableCell>
                <TableCell>
                  <S.FontHeaderTable>
                    {t("companyAddress.country")}
                  </S.FontHeaderTable>
                </TableCell>

                <TableCell>
                  <S.FontHeaderTable>{null}</S.FontHeaderTable>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {addresses.map((address, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{address?.cep?.replace("-", "")}</TableCell>
                    <TableCell>{address?.street}</TableCell>
                    <TableCell>{address?.complement}</TableCell>
                    <TableCell>{address?.city?.name}</TableCell>
                    <TableCell>{address?.state?.name}</TableCell>
                    <TableCell>{address?.country?.name}</TableCell>
                    <TableCell sx={{ textAlign: "end" }}>
                      <IconButton
                        onClick={() => {
                          setEditingIndex(i);
                          setShowModal(true);
                          if (address?.id != null) {
                            setEditingId(address?.id);
                          }
                          setValueAddAddress("country", address?.country);
                          setAddressCountrySelected(address?.country);
                          setValueAddAddress("cep", address?.cep);
                          setValueAddAddress("state", address?.state);
                          setValueAddAddress("city", address?.city);
                          setValueAddAddress("street", address?.street);
                          setValueAddAddress(
                            "complement",
                            address?.complement
                          );
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setExcludeIndex(i);
                          setOpenExcludeModal(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {addresses?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    Nenhum endereço encontrado
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainerLayout>
      
      <Controller
        control={control}
        name="internalRecruitment"
        defaultValue={false}
        render={({ field }) => (
          <Tooltip
            title={t("empresas.adminOnlyField")}
            placement="top"
            arrow
            disableHoverListener={isSysAdmin}
            followCursor={true}
          >
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  disabled={!isSysAdmin}
                />
              }
              label={labels.company.coursePublishing}
              style={{ marginTop: "5px" }}
            />
          </Tooltip>
        )}
      />

      {isSysAdmin && (
        <ConfirmationModal
          title={"Tem certeza que deseja remover esta empresa?"}
          message={
            "Esta empresa terá todos os seus usuários removidos e sua carteira zerada."
          }
          open={openExcludeCompanyModal}
          onAgree={removeCompany}
          onDisagree={() => {
            setOpenExcludeCompanyModal(false);
          }}
        />
      )}

      <ConfirmationModal
        title={"Tem certeza que deseja remover este endereço?"}
        message={
          "Este endereço será removido de todos os cargos que estão associados a ele."
        }
        open={openExcludeModal}
        onAgree={removeAddress}
        onDisagree={() => {
          setOpenExcludeModal(false);
        }}
      />

      {isSysAdmin && isEditing && dataToEdit?.id !== -1 && (
        <Alert severity="warning" variant="standard">
          <AlertTitle>
            <strong>Atenção!</strong>
          </AlertTitle>
          Funcionalidade exclusiva para <strong>sysadmin!</strong>
          <br />
          Excluir uma empresa significa{" "}
          <strong>remover todos os acessos dos usuários desta empresa!</strong>
          <S.ContainerGap data-cy="companyFormSubmitButton">
            <Button
              type="button"
              onClick={() => setOpenExcludeCompanyModal(true)}
              typeOfButton="secondary"
              size="custom"
              height={52}
              width={217}
              fontVariant="body1"
              text={"Excluir Empresa"}
            />
          </S.ContainerGap>
        </Alert>
      )}

      <S.ContainerGap data-cy="companyFormSubmitButton">
        <Button
          type="submit"
          onClick={handleSubmit(handleSave)}
          typeOfButton="primary"
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text={isEditing ? labels.save : labels.add}
        />
      </S.ContainerGap>

      <Modal
        open={showModal}
        onClose={() => {
          setEditingIndex(null);
          clearAllModalFields();
          setShowModal(false);
        }}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <S.FullModal>
          <S.Title>
            {editingIndex !== null
              ? t("companyAddress.saveAddress")
              : t("companyAddress.addAddress")}
          </S.Title>
          <S.RowModal>
            <S.InputRow
              style={{ justifyItems: "start", gap: 0 }}
              rowsSize={[1]}
            >
              <Controller
                name="country"
                control={controlAddAddress}
                render={({ field: { name, value, onChange } }) => (
                  <Select
                    selected={value}
                    onSelect={(newValue) => {
                      handleChangeAddressCountry(newValue);
                      onChange(newValue);
                    }}
                    placeholder={labels[name]}
                    label={labels[name]}
                    options={countries}
                  />
                )}
              />
              {errorsAddAddress?.country?.message && (
                <S.ErrorMessage>
                  {errorsAddAddress?.country?.message}
                </S.ErrorMessage>
              )}
            </S.InputRow>
          </S.RowModal>
          <S.RowModal>
            <S.InputRow rowsSize={[1]}>
              <Controller
                {...inputParams(controlAddAddress, "street")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...renderParams(field, true)}
                    errorMessage={errorsAddAddress?.street?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    value={field.value}
                  />
                )}
              />
            </S.InputRow>
          </S.RowModal>
          <S.RowModal>
            <S.InputRow rowsSize={[1]}>
              <Controller
                {...inputParams(controlAddAddress, "complement")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...renderParams(field, true)}
                    errorMessage={errorsAddAddress?.complement?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    value={field.value}
                  />
                )}
              />
            </S.InputRow>
          </S.RowModal>
          <S.RowModal>
            <S.InputRow rowsSize={[4, 4, 4]}>
              <Controller
                {...inputParams(controlAddAddress, "cep")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...field}
                    label={
                      addressCountrySelected?.id === 10
                        ? t("companyAddress.postalCode")
                        : t("companyAddress.cep")
                    }
                    placeholder={
                      addressCountrySelected?.id === 10
                        ? t("companyAddress.postalCode")
                        : t("companyAddress.cep")
                    }
                    disabled={loadingCep || addressCountrySelected === null}
                    errorMessage={errorsAddAddress?.cep?.message}
                    onChange={(e) => {
                      if (addressCountrySelected?.id === 26) {
                        getCep(e.target.value);
                      }
                      field.onChange(e.target.value);
                    }}
                    value={field.value}
                    maxLength={
                      addressCountrySelected?.id === 10
                        ? 4
                        : field?.value?.includes("-")
                        ? 9
                        : 8
                    }
                  />
                )}
              />
              <Controller
                {...inputParams(controlAddAddress, "state")}
                render={({ field }) => {
                  const selectedState = field.value || null;

                  const handleStateChange = (event, value) => {
                    field.onChange(value || null);
                    setStateSelected(value || null);
                  };

                  const filterStateOptions = (options, { inputValue }) => {
                    const filteredOptions = options.filter(
                      (option) =>
                        format(option.fu).includes(format(inputValue)) ||
                        format(option.name).includes(format(inputValue))
                    );

                    return filteredOptions;
                  };

                  return (
                    <div>
                      <S.DefaultFormLabel>
                        {t("companyAddress.state")}
                      </S.DefaultFormLabel>
                      <Autocomplete
                        id="state"
                        options={states}
                        getOptionLabel={(option) => {
                          return addressCountrySelected?.id === 10
                            ? option.name
                            : option.fu.toUpperCase();
                        }}
                        value={selectedState}
                        onChange={handleStateChange}
                        filterOptions={filterStateOptions}
                        noOptionsText={t("noOptionsMui")}
                        autoComplete={false}
                        disabled={disableAddress || addressCountrySelected === null}
                        loading={loadingCep}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              borderRadius: 0,
                              width: "100%",
                              height: "100%",
                              fontSize: 16,
                              fontWeight: 500,
                              outline: "none",
                              verticalAlign: "middle",
                              color: "#131212",
                              background: "#ffffff",
                            }}
                            placeholder={t("companyAddress.state")}
                            autoComplete="off"
                          />
                        )}
                      />
                      <S.ErrorMessage>
                        {errorsAddAddress?.state?.message}
                      </S.ErrorMessage>
                    </div>
                  );
                }}
              />
              <Controller
                {...inputParams(controlAddAddress, "city")}
                render={({ field }) => {
                  const selectedCity = field.value || null;

                  const handleCityChange = (event, value) => {
                    field.onChange(value || null);
                  };

                  const filterCityOptions = (options, { inputValue }) => {
                    const filteredOptions = options.filter((option) =>
                      format(option.name).includes(format(inputValue))
                    );

                    return filteredOptions;
                  };

                  return (
                    <div>
                      <S.DefaultFormLabel>
                        {t("companyAddress.city")}
                      </S.DefaultFormLabel>
                      <Autocomplete
                        id="cities"
                        options={cities}
                        getOptionLabel={(option) => option.name}
                        value={selectedCity}
                        onChange={handleCityChange}
                        filterOptions={filterCityOptions}
                        noOptionsText={t("noOptionsMui")}
                        autoComplete={false}
                        loading={loadingCep}
                        disabled={
                          (disableAddress && disableCity) || !stateSelected
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              borderRadius: 0,
                              width: "100%",
                              height: "100%",
                              fontSize: 16,
                              fontWeight: 500,
                              outline: "none",
                              verticalAlign: "middle",
                              opacity: 1,
                              color: "#131212 !important",
                              background: "#ffffff",
                            }}
                            placeholder={t("companyAddress.city")}
                            autoComplete="off"
                          />
                        )}
                      />
                      <S.ErrorMessage>
                        {errorsAddAddress?.city?.message}
                      </S.ErrorMessage>
                    </div>
                  );
                }}
              />
            </S.InputRow>
          </S.RowModal>
          <S.RowModalGap>
            <ButtonComp
              style={{
                background: "#fff0",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "#131212",
                color: "#131212",
              }}
              onClick={() => {
                setEditingIndex(null);
                setValueAddAddress("cep", "", { shouldValidate: false });
                clearAllModalFields();
                setShowModal(false);
              }}
            >
              Cancelar
            </ButtonComp>
            <ButtonComp onClick={handleSubmitAddAddress(addAddress)}>
              {editingIndex !== null ? t("coursesManagement.save") : t("add")}
            </ButtonComp>
          </S.RowModalGap>
        </S.FullModal>
      </Modal>
    </S.Form>
  );
}
