import { Dispatch, SetStateAction, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";

import { t } from "i18next";

import UserService from "../../../../services/UserService";
import AuthService from "../../../../services/AuthService";

import {
  ModalContainer,
  ContainerBodyModal,
  ContainerButtonsFooterModal,
} from "./styles";

interface ModalDeleteAccountProps {
  modalDeleteAccount: boolean;
  setModalDeleteAccount: Dispatch<SetStateAction<boolean>>;
  userId?: string;
}

const ModalDeleteAccount = (props: ModalDeleteAccountProps) => {
  const { modalDeleteAccount, setModalDeleteAccount, userId } = props;

  const [loading, setLoading] = useState(false);

  async function deleteClientAccount() {
    try {
      setLoading(true);
      const responseDeleteClient = await UserService.deleteClient(userId);

      console.warn(responseDeleteClient);

      if (responseDeleteClient.status === 200) {
        AuthService.logout();
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }

  return (
    <Modal
      open={modalDeleteAccount}
      onClose={() => setModalDeleteAccount(false)}
      sx={{
        backdropFilter: "blur(2px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalContainer>
        <ContainerBodyModal>
          <Typography variant="h4">
            {t("signUp.modalDeleteAccount.title")}
          </Typography>
          <Typography align="center">
            {t("signUp.modalDeleteAccount.subTitle")}
          </Typography>
        </ContainerBodyModal>
        <ContainerButtonsFooterModal>
          <Button
            variant="contained"
            size="large"
            onClick={() => setModalDeleteAccount(false)}
            sx={{
              background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
              color: "white",
              fontWeight: "700",
              width: "100%",
              height: "53px",
            }}
          >
            {t("signUp.modalDeleteAccount.buttonNo")}
          </Button>
          <Box sx={{ position: "relative", width: "100%" }}>
            <Button
              variant="contained"
              size="large"
              disabled={loading}
              onClick={() => deleteClientAccount()}
              sx={{
                background: "#696969",
                color: "white",
                fontWeight: "700",
                width: "100%",
                height: "53px",
              }}
            >
              {t("signUp.modalDeleteAccount.buttonYes")}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </ContainerButtonsFooterModal>
      </ModalContainer>
    </Modal>
  );
};

export default ModalDeleteAccount;
