import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { Button as ButtonD } from "design-system-firedev";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import zod from "zod";
import * as T from "../../Global/Typography";
import Unlock from "../../assets/unlock.png";
import { ResponsiveInputText } from "../../components/ResponsiveInputText";
import { PagSeguro } from "../../components/utils/pagseguro";
import * as Checkout from "../../services/CheckoutService";
import * as S from "./style";
import { Packages } from "./style";

export default function Payment() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const labels = {
    send: t("users.confirmPayment"),
    user: {
      id: "ID",
      name: t("signUp.firstName"),
      email: t("signUp.personalEmail"),
      cpf: t("signUp.cpf"),
      cardNumber: t("users.cardNumber"),
      expirationDate: t("users.expirationDate"),
      verificationCode: t("users.verificationCode"),
    },
  };

  const expirationDateRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;

  const checkoutFormSchema = zod.object({
    cardNumber: zod.string().min(17, t("users.invalidCard")),
    expirationDate: zod
      .string()
      .refine((value) => expirationDateRegex.test(value), {
        message: t("users.invalidExpirationDate"),
      })
      .refine(
        (value) => {
          const [expMonth, expYear] = value?.split("/");
          return expMonth >= "01" && expMonth <= "12";
        },
        {
          message: t("users.invalidExpirationMonth"),
        }
      )
      .refine(
        (value) => {
          const [expMonth, expYear] = value?.split("/");
          return expYear >= "2023" && expYear <= "2099";
        },
        {
          message: t("users.invalidExpirationYear"),
        }
      ),
    securityCode: zod.string().min(3, t("users.invalidVerificationCode")),
    name: zod.string().min(1, t("categories.validName")),
    email: zod
      .string()
      .email({ message: t("users.validEmail") })
      .max(255, { message: t("users.emailMaxCharacters") })
      .regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, {
        message: t("users.validEmail"),
      })
      .nonempty(t("users.validEmail")),
    cpf: zod
      .string()
      .regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, { message: t("users.invalidCpf") }),
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(checkoutFormSchema),
  });

  const handleSend = async (data: any) => {
    data.cardNumber = data?.cardNumber?.replace(/\s/g, "");
    const [expMonth, expYear] = data?.expirationDate.split("/");
    data.expMonth = expMonth;
    data.expYear = expYear;
    setLoading(true);

    try {
      //backend faz a requisição para o pagseguro para pegar o public token
      //o backend DEVE executar todas as requisições com o PagSeguro para evitar erro de CORS
      const result = await Checkout.getPagSeguroPublicKey();

      // @ts-ignore
      var card = await PagSeguro.encryptCard({
        publicKey: result?.publicKey,
        holder: `${data.name} ${data.lastName}`,
        number: data.cardNumber,
        expMonth: data.expMonth,
        expYear: data.expYear,
        securityCode: data.securityCode,
      });

      await Checkout.payment({
        value: location.state.value,
        quantity: location.state.quantity,
        encryptedCard: card.encryptedCard,
        cpf: getValues("cpf"),
        name: getValues("name"),
        email: getValues("email"),
      });

      enqueueSnackbar(t("payment.paymentWaiting"), {
        variant: "success",
      });
      setLoading(false);
      navigate("/home");
    } catch (err) {
      setLoading(false);
    }
    /*A partir daqui a variavel card contém o cartão criptografado pronto para executar as ações
    com o backend*/
  };

  const renderParams = (field) => {
    return {
      ...field,
      height: 3.375,
      placeholder: labels.user[field.name],
      label: labels.user[field.name],
      errorMessage: errors[field.name]?.message,
      borderColor: errors[field.name] ? "#F5222D" : "#8c8c8c",
    };
  };

  const inputParams = (control, fieldName: string) => {
    return {
      name: fieldName,
      defaultValue: "",
      control: control,
    };
  };

  function onBackPress() {
    navigate("/management/credits");
  }

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <S.ContainerFullPage>
        <T.Display>{t("users.checkout")}</T.Display>
        <S.ContainerGap>
          <S.TextInfo>{t("users.checkoutInfo")}</S.TextInfo>
        </S.ContainerGap>

        <ButtonD
          onClick={() => onBackPress()}
          background="transparent"
          borderColor="transparent"
          height={40}
          width={80}
          color="#FF026F"
          colorIcon="#FF026F"
          fontVariant="body2"
          pathIcon="arrow"
          size="custom"
          text={t("skills.back")}
          type="button"
        />
        {/*<Box sx={{width: "90%"}}>*/}
        {/*  */}
        {/*</Box>*/}
        <S.Form>
          <Packages>
            <S.ContainerPackage>
              <S.PackageLabel>Pacote selecionado</S.PackageLabel>
              <S.FirstLabelPackage>
                <img
                  src={Unlock}
                  alt="Unlock_Back_Icon"
                  style={{ marginRight: 5, marginTop: 3 }}
                />
                {location.state.quantity} {t("payment.unclocks")}
              </S.FirstLabelPackage>
            </S.ContainerPackage>
            <S.ContainerPackage>
              <S.PackageLabel>Valor</S.PackageLabel>
              <div>
                <S.SecondLabelPackage>R$</S.SecondLabelPackage>
                <S.ThirdLabelPackage>
                  {location.state.value.toString()},00
                </S.ThirdLabelPackage>
              </div>
            </S.ContainerPackage>
          </Packages>
          <S.ContainerGap>
            <S.InputRow rowsSize={[3, 2, 1]}>
              <Controller
                {...inputParams(control, "cardNumber")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...renderParams(field)}
                    title="cardNumber"
                    value={field.value}
                    mask="9999 9999 9999 9999"
                    maskChar=""
                  />
                )}
              />
              <Controller
                {...inputParams(control, "expirationDate")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...renderParams(field)}
                    title="expirationDate"
                    mask="99/9999"
                  />
                )}
              />
              <Controller
                {...inputParams(control, "securityCode")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...renderParams(field)}
                    label={labels.user.verificationCode}
                    mask="9999"
                    maskChar=""
                  />
                )}
              />
            </S.InputRow>
            <S.InputRow rowsSize={[1]}>
              <Controller
                {...inputParams(control, "name")}
                render={({ field }) => (
                  <ResponsiveInputText
                    {...renderParams(field)}
                    value={field.value}
                    title="name"
                    maxLength="255"
                  />
                )}
              />
            </S.InputRow>

            <S.InputRow rowsSize={[1, 1]}>
              <S.ContainerGap>
                <Controller
                  {...inputParams(control, "email")}
                  render={({ field }) => (
                    <ResponsiveInputText
                      {...renderParams(field)}
                      title="email"
                      value={field.value}
                    />
                  )}
                />
              </S.ContainerGap>

              <S.ContainerGap>
                <Controller
                  {...inputParams(control, "cpf")}
                  render={({ field }) => (
                    <ResponsiveInputText
                      {...renderParams(field)}
                      title="cpf"
                      mask="999.999.999-99"
                      value={field.value}
                    />
                  )}
                />
              </S.ContainerGap>
            </S.InputRow>
          </S.ContainerGap>

          <S.ContainerButton>
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmit(handleSend)}
              sx={{
                background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
                color: "white",
                fontWeight: "700",
                width: "155px",
                height: "53px",
              }}
            >
              {labels.send}
            </Button>
          </S.ContainerButton>
        </S.Form>
      </S.ContainerFullPage>
    </div>
  );
}
