import { ThumbDown, ThumbUp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Button } from "design-system-firedev";
import { useEffect, useState } from "react";
import TreeWhiteIcon from "../../../assets/TreeWhiteIcon.svg";
import SetaGray from "../../../assets/setaGray.svg";
import { ButtonDS } from "../../Login/style";
import { IOption } from "../../Questions/Form";
import { IQuestionBatch } from "../Index";
import * as S from "./style";
import QuestionsBatchesService from "../../../services/QuestionsBatchesService";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { red } from "@mui/material/colors";

interface IChallengesForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: { id: number };
}

export interface IQuestion {
  id: number;
  content: string;
  input: string;
  options: IOption[];
  approved?: Boolean;
}

interface IQuestionBatchSkill {
  id: number;
  name: string;
  status: string;
  questions: IQuestion[];
}

interface IQuestionBatchFull extends IQuestionBatch {
  skills: IQuestionBatchSkill[];
}

export default function AIChallengesReviewForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: IChallengesForm) {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [skills, setSkills] = useState<IQuestionBatchSkill[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const navigate = useNavigate();
  const fetchData = async () => {
    const resultQuestionBatch =
      await QuestionsBatchesService.findIdQuestionBatch(dataToEdit.id);
    setSkills(resultQuestionBatch.skills);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    fetchData();
  }, []);

  const [likes, setLikes] = useState({});
  const [dislikes, setDislikes] = useState({});

  const handleLike = (questionId) => {
    setDislikes((prevDislikes) => {
      delete prevDislikes[questionId];
      return { ...prevDislikes };
    });
    setLikes((prevLikes) => ({ ...prevLikes, [questionId]: true }));
  };

  const handleDislike = (questionId) => {
    setLikes((prevLikes) => {
      delete prevLikes[questionId];
      return { ...prevLikes };
    });
    setDislikes((prevDislikes) => ({ ...prevDislikes, [questionId]: true }));
  };

  const getBackgroundColor = (question: IQuestion) => {
    if (question.approved === true || likes[question.id]) {
      return "#dce9da";
    } else if (question.approved === false || dislikes[question.id]) {
      return "#ffe0d9";
    } else {
      return "inherit";
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const reviewGeneratedQuestions = async () => {
    const lArr = Object.keys(likes).map((i) => parseInt(i, 10));
    const dArr = Object.keys(dislikes).map((i) => parseInt(i, 10));
    if (lArr.length || dArr.length) {
      await QuestionsBatchesService.reviewGeneratedQuestions(lArr, dArr);
      enqueueSnackbar("Avaliação salva.", { variant: "success" });
      onBackPress();
    }
  };

  const getChipText = (status: string, questionsSize: number) => {
    switch (status) {
      case "FINISHED":
        return questionsSize > 0 ? "Finalizado" : "Erro na geração";
      case "NOT_INITIATED":
        return "Não inicializado";
      case "ERROR":
        return "Erro na geração";
      case "IN_PROGRESS":
        return "Em progresso";
    }
  };

  const getChipCollor = (status: string, questionsSize: number) => {
    switch (status) {
      case "FINISHED":
        return questionsSize > 0 ? "success" : "error";
      case "NOT_INITIATED":
        return "info";
      case "ERROR":
        return "error";
      case "IN_PROGRESS":
        return "warning";
    }
  };

  return (
    <S.Form onSubmit={() => {}}>
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Você tem certeza que deseja salvar estas avaliações?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao avaliar um desafio, lembre-se de que sua avaliação é{" "}
            <b>definitiva</b>. Desafios que você avaliar negativamente serão{" "}
            <b>removidos</b> da sua lista, enquanto os que receberem{" "}
            <b>feedback positivo</b> serão associados à sua habilidade.
            <br />
            <br />É <b>importante</b> avaliar cada desafio com <b>atenção</b> e{" "}
            <b>cuidado</b>, considerando se ele é <b>relevante</b> e{" "}
            <b>desafiador</b> para você. Lembre-se de que sua <b>opinião</b> é{" "}
            <b>valiosa</b> e ajudará a melhorar o processo de geração de
            desafios para todos os usuários.
            <br />
            <br />
            Por favor, note que, uma vez que um desafio foi avaliado, ele{" "}
            <b>não poderá ser reavaliado</b>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text="Cancelar"
            onClick={handleCloseEditDialog}
          />
          <ButtonDS
            data-cy="questionScreenEditButton"
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text="Salvar"
            onClick={reviewGeneratedQuestions}
          />
        </DialogActions>
      </Dialog>
      <Button
        type="button"
        onClick={() => onBackPress()}
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text="Voltar"
      />
      {skills?.length > 0 &&
        skills?.map((skill) => (
          <Accordion
            disableGutters
            expanded={expanded === skill?.id.toString()}
            onChange={handleChange(skill?.id.toString())}
          >
            <AccordionSummary
              aria-controls={`${skill?.id}d-content`}
              id={`${skill?.id}d-header`}
            >
              <S.ContainerAccordionSummary>
                <S.ContainerAccordionSummaryLeft>
                  <S.AccordionSummaryCircleImg color="#302382">
                    <S.AccordionSummaryImg src={TreeWhiteIcon} alt="TreeIcon" />
                  </S.AccordionSummaryCircleImg>
                  <S.ContainerAccordionSummaryLeftTexts>
                    <S.TitleAccordionSummary>
                      {skill.name}
                    </S.TitleAccordionSummary>
                    <Chip
                      sx={{ marginLeft: "15px" }}
                      label={getChipText(
                        skill?.status,
                        skill?.questions.length
                      )}
                      color={getChipCollor(
                        skill?.status,
                        skill?.questions.length
                      )}
                    />
                  </S.ContainerAccordionSummaryLeftTexts>
                </S.ContainerAccordionSummaryLeft>
                <S.AccordionSummarySetaImg up src={SetaGray} alt="Seta" />
              </S.ContainerAccordionSummary>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, m: 0 }}>
              <S.ContainerAccordionDetails>
                {skill?.questions.length > 0 ? (
                  skill?.questions.map((question) => (
                    <div>
                      <Grid
                        container
                        style={{
                          backgroundColor: getBackgroundColor(question),
                        }}
                      >
                        <Grid item xs={10}>
                          <S.ValidateQuestionBox>
                            <span>{question.content}</span>
                            <br />
                            <br />
                            {question.options.map((option) => (
                              <div key={option.content}>
                                {(option.rightOption && (
                                  <b>{option.content}</b>
                                )) || <div>{option.content}</div>}
                              </div>
                            ))}
                          </S.ValidateQuestionBox>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleLike(question.id)}
                            disabled={
                              question.approved !== null || likes[question.id]
                            }
                          >
                            <ThumbUp
                              color={
                                question.approved === true || likes[question.id]
                                  ? "success"
                                  : "inherit"
                              }
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDislike(question.id)}
                            disabled={
                              question.approved !== null ||
                              dislikes[question.id]
                            }
                          >
                            <ThumbDown
                              color={
                                question.approved === false ||
                                dislikes[question.id]
                                  ? "error"
                                  : "inherit"
                              }
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <hr />
                    </div>
                  ))
                ) : (
                  <Grid container>
                    <Grid item xs={12} height={25} padding={5}>
                      <span>Geração automática de desafios falhou.</span>
                    </Grid>
                  </Grid>
                )}
              </S.ContainerAccordionDetails>
            </AccordionDetails>
          </Accordion>
        ))}

      <S.ContainerGap>
        <Button
          type="button"
          typeOfButton="primary"
          onClick={() => setOpenEditDialog(true)}
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text="Salvar"
          data-cy="submitButtonChallengeForm"
        />
      </S.ContainerGap>
    </S.Form>
  );
}
