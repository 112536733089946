import styled from "styled-components";
import { Button as LocalButton } from "../components/Button";

export const Button = styled.button`
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 3px;
  padding: 10px 65px 10px 65px;
  /* H2 - Heading 2 */

  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  text-align: center;

  /* White */

  color: #ffffff;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000021;

  &:active {
    /* background-color: #3e8e41; */
    box-shadow: 0px 0px 0px 0px;

    transform: translateY(2px);
  }
`;

export default Button;

export const AlternateLocalButton = styled(LocalButton)({
  background: "#fff0",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#131212",
  color: "#131212",
});
