/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import * as React from "react";

import {
  Alert,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useNavigate } from "react-router-dom";
import * as T from "../../Global/Typography";
import "dayjs/locale/pt-br";

import UsersForm from "./Form";
import UserService from "../../services/UserService";

import * as S from "./style";
import { TableContainerLayout } from "../../components/TableContainerLayout";
import { ReportType, SummaryBy } from "../../services/ReportService";
import { generateQueryParamsStringFilterReportPage } from "../../components/utils/http";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import { t } from "i18next";
interface Role {
  id: number;
  name: string;
  createdAt: string;
  authority: string;
}
export default function Users() {
  const [rows, setRows] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [action, setAction] = React.useState("list");
  const [selected, setSelected] = React.useState(undefined);
  const [addButton, setAddButton] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(0);
  const [page, setPage] = React.useState<number>(1);
  const [idSelected, setIdSelected] = React.useState<number>();
  const navigate = useNavigate();

  const handleFollowToReportPage = (id) => {
    const queryParams = generateQueryParamsStringFilterReportPage(
      SummaryBy.Formation,
      null,
      id,
      null,
      ReportType.UserFocus
    );
    navigate(`/management/performance-report?${queryParams}`);
  };

  const fetchData = async () => {
    const resultFormation = await UserService.getUsers(search, page - 1);
    setTotalPages(resultFormation.totalPages);
    setRows(
      resultFormation.content.map((item: any) => {
        return {
          id: item?.id,
          icon: <S.ImageTable src={item?.file?.fileUrl} alt="" />,
          firstName: item?.name,
          lastName: item?.lastName,
          email: item?.email,
          roles: item?.roles,
          createdAt: item?.createdAt,
          positionCount: item?.subscribedPositions,
          company: item?.company,
          actionsButtons: (
            <div>
              {/*<IconButton onClick={() => handleFollowToReportPage(item.id)}>*/}
              {/*  <EqualizerIcon />*/}
              {/*</IconButton>*/}
              <IconButton>
                <EditOutlinedIcon
                  onClick={() => handleEdit(item.id, item.name)}
                />
              </IconButton>
            </div>
          ),
        };
      })
    );
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      fetchData();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  React.useEffect(() => {
    fetchData();
  }, [page]);

  const handleFeedbackAction = async (
    type: string,
    newData: any,
    submitAction: string
  ) => {
    setAction(type === "success" ? "list" : "form");
    await fetchData();
  };

  const handleEdit = (id, title) => {
    setSelected({ id, title });
    setTimeout(() => {
      setAction("form");
      setIdSelected(id);
    }, 150);
  };

  const onClickAddButton = () => {
    setSelected(undefined);
    setAction("form");
    setAddButton(true);
  };

  const formatRoles = (roles: Role[]): string => {
    if (typeof roles == "undefined") {
      return "Sem roles";
    }
    const roleNames: string[] = roles
      .map((role) => {
        if (role.name === "ROLE_CLIENT") {
          return "Cliente";
        } else if (role.name === "ROLE_STAFF") {
          return "Staff";
        } else if (role.name === "ROLE_ADMIN") {
          return "Administrador";
        } else if (role.name === "ROLE_SYSADMIN") {
          return "Sys Admin";
        }
        return "";
      })
      .filter((roleName) => roleName !== "");

    const formattedRoles = roleNames.join(", "); // "Cliente, Staff"
    return formattedRoles;
  };

  return (
    <S.ContainerFullPage>
      <T.Display data-cy="usersH1">{t("users.myUsers")}</T.Display>
      {action === "list" && (
        <TableContainerLayout
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          onClickAddButton={onClickAddButton}
        >
          <TableContainer sx={{ width: "100%", background: "white" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 70 }}>
                    <S.FontHeaderTable>Id</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: 300 }}>
                    <S.FontHeaderTable>{t("users.userName")}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: 300 }}>
                    <S.FontHeaderTable>{t("users.emailMin")}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>
                    <S.FontHeaderTable>
                      {t("users.registeredPositions")}
                    </S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: 300 }}>
                    <S.FontHeaderTable>{t("users.company")}</S.FontHeaderTable>
                  </TableCell>
                  <TableCell sx={{ width: 300 }}>
                    <S.FontHeaderTable>
                      {t("users.levelOfAccess")}
                    </S.FontHeaderTable>
                  </TableCell>

                  <TableCell>
                    <S.FontHeaderTable>{null}</S.FontHeaderTable>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length > 0 &&
                  rows.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>
                        <S.NameTableRow>
                          {item.firstName} {item.lastName}
                        </S.NameTableRow>
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item?.positionCount || "0"}</TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 5,
                          }}
                        >
                          <S.CompanyIcon
                            src={
                              item?.company?.avatar || skillHunterPositionIcon
                            }
                            alt={skillHunterPositionIcon}
                          />
                          {item?.company?.tradeName ||
                            item?.company?.name ||
                            item?.company?.companyName ||
                            "SkillHunter"}
                        </div>
                      </TableCell>
                      <TableCell>{formatRoles(item.roles)}</TableCell>
                      <TableCell>{item.actionsButtons}</TableCell>
                    </TableRow>
                  ))}

                {rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      Nenhum resultado encontrado
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </TableContainerLayout>
      )}
      {action === "form" && (selected || addButton) && (
        <UsersForm
          feedbackAction={handleFeedbackAction}
          onBackPress={() => {
            setAction("list");
            setSelected(undefined);
          }}
          dataToEdit={selected}
        />
      )}
    </S.ContainerFullPage>
  );
}
