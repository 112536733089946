import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Typography } from "@mui/material";

import * as S from "./styles";
import {t} from "i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectNew({
  disabled = false,
  options,
  label,
  onChange,
  value,
  loading = false,
  noOptionsText = null,
  onChangeTextField = (e) => {}
}) {
  const handleOnChange = (e, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      disabled={disabled}
      defaultValue={value}
      options={options}
      getOptionLabel={(option) => option.name}
      value={value}
      loading={loading}
      noOptionsText={
        noOptionsText || t("noOptionsMui")
      }
      onChange={handleOnChange}
      autoComplete={false}
      renderInput={(params) => (
        <div>
          <S.Label>{label}</S.Label>
          <TextField
            {...params}
            sx={{
              borderRadius: "5px",
              width: "100%",
              fontSize: 16,
              fontWeight: 500,
              outline: "none",
              color: "#131212",
              background: disabled ? "transparent" : "#ffffff",
              ".MuiInputBase-root": {
                background: disabled ? "transparent" : "#ffffff",
              },
              ".MuiAutocomplete-endAdornment": {
                background: disabled ? "transparent" : "#ffffff",
              },
            }}
            onChange={onChangeTextField}
            autoComplete="off"
          />
        </div>
      )}
      renderOption={(params, opt) => (
        <Typography {...params} key={opt.value}>
          {opt?.name}
        </Typography>
      )}
    />
  );
}
