import styled from "styled-components";

export const ContainerFullPage = styled.div`
  padding: 48px 4%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitlePage = styled.h1`
  font-size: 36px;
  font-weight: bold;
`;
export const Subtitle = styled.p`
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
`;

export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const ContainerUpDown = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  flex-wrap: nowrap;
  width: min-content;
  gap: 8px;
  align-items: center;
`;

export const ContainerCardBase = styled.div`
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 3px;

  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerCardTopUsers = styled.div`
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 3px;

  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  max-width: 380px;
  @media screen and (max-width: 832px) {
    flex-direction: column;
    max-width: none;
  }
`;

export const PaddingHorizontal = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export const UsersSeparator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const UserTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const UserName = styled.p<any>`
  color: #ff026f;
  cursor: ${({underline}) => (underline ? "default" : "pointer")};
  font-size: 16px;
  font-weight: 500;
  text-decoration: ${({underline}) => (underline ? "none" : "underline")};
`;

export const UserDesc = styled.p`
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  span {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const NoData = styled.p`
  color: #131212;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: center;
`;

export const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const CardButtonContainer = styled.div`
  margin-top: 32px;
  padding: 10px 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 100px;
  border-top: 1px solid #aeaeae;
`;

export const PaddingCargoDesafio = styled.div<any>`
  padding: 8px 1rem;
  padding-top: ${({isFirst}) => (isFirst ? 0 : 8)}px;
  width: 260px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ContainerCards = styled.div`
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerAllCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 22px;
  margin-top: 16px;

  @media screen and (max-width: 832px) {
    flex-direction: column;
  }
`;

export const ContainerTwoCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 22px;

  @media screen and (max-width: 832px) {
    flex-direction: column;
  }
`;

export const ContainerLabel = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1rem;
  color: #131212;
`;

export const ContainerTextsCard = styled.div`
  width: 100%;
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 12px;
  column-gap: 8px;
`;

export const ContainerTextsNumberCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ContainerTextsNumberCardBase = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-right: 1rem;
  padding-bottom: 3px;
  border-bottom: ${({isLast}) => (!isLast ? "1px solid #aeaeae" : null)};
`;

export const NumberCard = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: #ff026f;
`;

export const TextCard = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
`;

export const TextCardNoWrap = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
  white-space: nowrap;
`;
