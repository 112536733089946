import skillHunterPositionIcon from "../assets/skillHunterPositionIcon.png";
import { objectToURLSearchParams } from "../components/utils/http";
import { Formation } from "../models/Formation";
import IUserStatistic from "../models/UserStatistic";
import api from "./api";
import {Trail} from "../models/Trail";

export enum SummaryBy {
  Formation = "FORMATIONS",
  Trail = "TRAILS",
  Skill = "SKILLS",
  SkillTree = "SKILL_TREES",
}

export function getSortOptionFromString(str: string): SortOptions | undefined {
  return Object.values(SortOptions).find((sortOption) => sortOption === str);
}

export function getReportTypeFromString(str: string): ReportType | undefined {
  return Object.values(ReportType).find((reportType) => reportType === str);
}

export function getSummaryByFromString(str: string): SummaryBy | undefined {
  return Object.values(SummaryBy).find((summaryBy) => summaryBy === str);
}

export enum ReportType {
  SkillFocus = "SKILL_FOCUS",
  UserFocus = "USER_FOCUS",
}

export enum SortOptions {
  CompletionAsc = "progress,asc",
  CompletionDesc = "progress,desc",
  GradeAsc = "grade,asc",
  GradeDesc = "grade,desc",
}

interface IQueryReportRequest {
  formationId: number | null;
  page: number;
  reportType: ReportType;
  size: number;
  skillId: number | null;
  skillTreeId: number | null;
  sort: SortOptions;
  summarizeBy: SummaryBy;
  trailId: number | null;
  userId: number | null;
  userName: string | null;
  cityId: string | null;
  stateId: string | null;
  district: string | null;
  onlyLockedUsers: boolean | null;
}

class ReportService {
  async getUserDashboardStatistics(): Promise<IUserStatistic[]> {
    const response = await api.get(`reports/user-statistics`);

    return response.data;
  }

  async getUserBestFitFormationsData(): Promise<Formation[]> {
    const response = await api.get(`reports/best-fit-formations`);

    return response.data.map((formation) => {
      return {
        id: formation.formationId,
        title: formation.title,
        percentage: formation.percentage,
        company: {
          id: formation.company.id,
          avatar: {
            fileUrl: formation?.company?.avatar ?? skillHunterPositionIcon,
          },
          companyName: formation.company.name,
          tradeName: formation.company.name,
          name: formation.company.name,
          slug: formation.company.slug,
        },
        slug: formation.slug,
      };
    });
  }
  async getUserBestFitTrailsData(): Promise<Trail[]> {
    const response = await api.get(`reports/best-fit-trails`);

    return response.data.map((trail) => {
      return {
        id: trail.trailId,
        name: trail.name,
        percentage: trail.percentage,
        company: {
          id: trail.company.id,
          avatar: {
            fileUrl: trail?.company?.avatar ?? skillHunterPositionIcon,
          },
          companyName: trail.company.name,
          tradeName: trail.company.name,
          name: trail.company.name,
          slug: trail.company.slug,
        },
        slug: trail.slug,
      };
    });
  }

  async queryReport(
    summarizeBy: SummaryBy | null,
    skillFocusId: number | null,
    userId: number | null,
    userName: string | null,
    size: number | null,
    page: number | null,
    sort: SortOptions | null,
    reportType: ReportType,
    stateId: string | null,
    cityId: string | null,
    district: string | null,
    onlyLockedUsers: boolean | null
  ) {
    const queryParams: IQueryReportRequest = {
      formationId: summarizeBy === SummaryBy.Formation ? skillFocusId : null,
      page: page !== null ? page : 0,
      reportType: reportType,
      size: size !== null ? size : 5,
      skillId: summarizeBy === SummaryBy.Skill ? skillFocusId : null,
      skillTreeId: summarizeBy === SummaryBy.SkillTree ? skillFocusId : null,
      sort: sort !== null ? sort : SortOptions.CompletionDesc,
      summarizeBy: summarizeBy !== null ? summarizeBy : SummaryBy.Formation,
      trailId: summarizeBy === SummaryBy.Trail ? skillFocusId : null,
      userId: reportType === ReportType.UserFocus ? userId : null,
      userName: reportType === ReportType.UserFocus ? userName : null,
      stateId,
      cityId,
      district,
      onlyLockedUsers,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value != null)
    );

    const resultReports = await api.get(
      `reports?${objectToURLSearchParams(filteredParams)}`
    );
    return resultReports.data;
  }
}

export default new ReportService();
