import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.p`
  margin-bottom: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const useStyles = makeStyles({
  MuiAutocompleteRoot: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: '#8c8c8c',
        opacity: 1
      },
    },
  }
});

export const inputStyles = {
  fontSize: '18px',
  fontWeight: '700',
  fontFamily: 'Ubuntu',
  cursor: 'pointer',
  padding: '5.5px 4px 5.5px 5px',
  opacity: 1,
  color: 'rgb(19, 18, 18)',
};

export const customInputStyle = {
  // border: "1px solid #F5222D",
  borderRadius: '5px',
  fontSize: '18px',
  fontWeight: 700,
  padding: 0,
};

export const OptionText = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
`;

export const ErrorText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  color: #f5222d;
`;