
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, useRef, CSSProperties } from "react";
import * as S from "./styles";
import MenuItem from "@mui/material/MenuItem";
import { t } from "i18next";
import { Autocomplete, TextField } from "@mui/material";
interface ISelect {
  options: {
    id?: number | string;
    value?: string | number;
    name?: string;
  }[];
  selected?: {
    id?: number | string;
    value?: string | number;
    name?: string;
  };
  onSelect: (_: any) => any;
  placeholder: string;
  showSearch?: boolean;
  selectOne?: boolean;
  error?: any;
  sortAlphabetical?: boolean;
  listAbsolute?: boolean;
  label?: any;
  disabled?: boolean;
  style?: CSSProperties;
}
function Select({
  options,
  selected,
  onSelect,
  showSearch = true,
  placeholder,
  error,
  sortAlphabetical = true,
  listAbsolute = true,
  selectOne = true,
  label,
  disabled,
  style,
}: ISelect) {
  let newPlaceholder = placeholder;
  if (
    newPlaceholder?.endsWith("s") &&
    newPlaceholder.toLocaleLowerCase() !== "status" &&
    newPlaceholder.toLocaleLowerCase() !== "país"
  ) {
    newPlaceholder = newPlaceholder.slice(0, -1);
  }
  const stylesPlaceholder = S.useStyles();
  selectOne ? options = [
    {
      id: null,
      value: `${t("courses.selectOne")} ${newPlaceholder.toLowerCase()}`,
    },
    ...options,
  ] : options = [...options];
  const convertItem = (item) => item?.name || item?.value;
  const isError = typeof error === "string" && error?.length > 0;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const equalObject = (obj1, obj2) => {
    function convertObj(obj) {
      return {
        ...(obj?.id ? { id: obj.id } : {}),
        ...(obj?.name ? { name: obj.name } : {}),
        ...(obj?.value ? { value: obj.value } : {}),
      };
    }
    return (
      JSON.stringify(convertObj(obj1)) === JSON.stringify(convertObj(obj2))
    );
  };
  const [selectedFilter, setSelectedFilter] = useState<any>(selected);
  const [text, setText] = useState("");
  const ref = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isFocused && ref.current && !ref.current.contains(e.target)) {
        inputRef.current.blur();
        setIsFocused(false);
        setText("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isFocused]);
  function sortAlphabeticalOptions(a, b) {
    if (sortAlphabetical) {
      return convertItem(a).toString().localeCompare(convertItem(b).toString());
    }
  }
  useEffect(() => {
    setSelectedFilter(selected);
  }, [selected]);
  const handleOptionSelect = (event, newValue) => {
    setSelectedFilter(newValue);
    onSelect(newValue);
  };
  const filteredOptions = options
    ?.filter((opt) => (text.length > 0 ? !(opt.id === null) : !!opt))
    ?.filter((opt) =>
      convertItem(opt)
        ?.toString()
        ?.trim()
        ?.toLocaleUpperCase()
        ?.includes(text?.trim()?.toLocaleUpperCase())
    )
    ?.sort((a, b) => sortAlphabeticalOptions(a, b))
    ?.sort((a) => (equalObject(selectedFilter, a) ? -1 : 1))
    ?.sort((a) => (a.id === null ? -1 : 1))
  return (
    <>
      <S.Label>{label}</S.Label>
      <S.Container style={{ ...style, height: undefined }} ref={ref}>
        <Autocomplete
          options={filteredOptions}
          value={selectedFilter}
          disabled={disabled}
          getOptionLabel={(filteredOptions) => convertItem(filteredOptions) || ''}
          onChange={handleOptionSelect}
          disablePortal
          classes={{ root: stylesPlaceholder.MuiAutocompleteRoot }}
          getOptionDisabled={(option) => option.id === null}
          style={{
            ...S.customInputStyle,
            border: `1px solid ${isError ? "#F5222D" : "#8c8c8c"}`,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              ref={inputRef}
              value={text}
              onMouseOver={() => inputRef.current.blur()}
              placeholder={convertItem(selectedFilter) || placeholder}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '5px',
                borderColor: 'rgb(140, 140, 140)',
                "& fieldset": {
                  boxShadow: '0 0 0 0',
                  border: '0 none',
                  outline: 0,
                },
                "&.Mui-focused fieldset": {
                  boxShadow: '0 0 0 0',
                  borderColor: 'rgb(140, 140, 140)',
                  outline: 0,
                },
              }}
              inputProps={{
                ...params.inputProps,
                style: {
                  ...S.inputStyles,
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, options) => (
            <MenuItem
              {...props}
              sx={{
                p: 0,
                m: 0,
                color:
                  options.id === null && !selectedFilter ? "#0000" : "default",
                ":hover": {
                  background:
                    options.id === null && !selectedFilter
                      ? "#0000"
                      : "default",
                },
              }}
              style={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: 19,
                backgroundColor: equalObject(options, selectedFilter)
                  ? "#00000012"
                  : "auto",
                gap: 13,
                opacity: options.id === null && !selectedFilter ? 0.6 : 1,
              }}
            >
              <S.OptionText>{convertItem(options)}</S.OptionText>
            </MenuItem>
          )}
        />
        {isError && (
          <S.ErrorText data-cy="selectErrorMessage">{error}</S.ErrorText>
        )}
      </S.Container>
    </>
  );
}
export { Select };
