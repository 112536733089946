import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {t} from 'i18next'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {useEffect, useState} from "react";
import * as S from "./styles";

interface INotesModal {
  title: string
  notes: string
  open: boolean
  onClose: () => void
  onAgree: (data) => void
}

export default function NotesModal(options: INotesModal) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [value, setValue] = useState('')

  const handleAgreed = () => {
    options.onAgree(value)
  }

  const modules = {
    toolbar: [
      [{font: []}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ["bold", "italic", "underline", "strike"],
      [{color: []}, {background: []}],
      [{script: "sub"}, {script: "super"}],
      ["blockquote", "code-block"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}, {align: []}],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula'
    // 'image'
    // 'video'
  ];

  useEffect(() => {
    setValue(options?.notes)
  }, [options])

  return (
    <Dialog
      fullScreen={fullScreen}
      open={options?.open}
      onClose={options.onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{options?.title}</DialogTitle>
      <DialogContent>
        <S.ContainerInput>
          <ReactQuill
            theme="snow"
            modules={modules}
            onChange={(value) => {
              setValue(value);
            }}
            placeholder={options?.title}
            value={value}
            formats={formats}
          />
        </S.ContainerInput>
      </DialogContent>
      <DialogActions>
        <S.ContainerButton>
          <S.ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={200}
            fontVariant="body1"
            text={t("performanceReport.cancel")}
            onClick={options.onClose}
          />
          <S.ButtonDS
            typeOfButton="primary"
            size="custom"
            height={40}
            width={200}
            fontVariant="body1"
            text={t('users.save')}
            onClick={handleAgreed}
          />
        </S.ContainerButton>
      </DialogActions>
    </Dialog>
  )
}
