//Function that return the svg Success
export const ImageSuccess = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.968 0C14.304 0 0 14.336 0 32C0 49.664 14.304 64 31.968 64C49.664 64 64 49.664 64 32C64 14.336 49.664 0 31.968 0ZM32 57.6C17.856 57.6 6.4 46.144 6.4 32C6.4 17.856 17.856 6.4 32 6.4C46.144 6.4 57.6 17.856 57.6 32C57.6 46.144 46.144 57.6 32 57.6Z"
        fill="#00A676"
      />
      <path
        d="M27.7332 39.6501L20.7082 32.625L17.6917 35.6416L27.7332 45.6832L48.4415 24.9749L45.425 21.9584L27.7332 39.6501Z"
        fill="#00A676"
      />
    </svg>
  );
};
