import api, {axiosApi} from "./api";

class PositionCategoryService {
  async getPositionCategoryPaginated(page, search) {
    const filter = encodeURIComponent(search);
    const resultPositionsPaginated = await api.get(
      `formation-category/management?page=${page}&size=5&filter=${filter}`
    );
    return resultPositionsPaginated.data;
  }

  async approvePositionCategory(id: any) {
    const result = await api.patch(`formation-category/approve/${id}`);
    return result;
  }

  async getPositionCategoryToDropdown(search: string) {
    const filter = encodeURIComponent(search);
    const resultPositionCategoryToDropdown = await api.get(
      `formation-category?filter=${filter}&size=99999&sort=id%2Cdesc`
    );
    return resultPositionCategoryToDropdown.data.content.map(
      ({ id, name }) => ({
        id,
        value: name,
      })
    );
  }

  async getPositionCategoryNamesToList(search: string) {
    const filter = encodeURIComponent(search);
    const resultPositionCategoryToDropdown = await api.get(
      `formation-category?filter=${filter}&size=15&sort=id%2Cdesc`
    );
    return resultPositionCategoryToDropdown.data.content.map(
      ({ id, name }) => ({
        id,
        value: name,
      })
    );
  }

  async getCategoriesWithPositions() {

    const resultPositionCategoryToDropdownWithFormation = await api.get(`formation-category/findAllWithFormation`);
    const result = resultPositionCategoryToDropdownWithFormation.data.map(
      ({ id, name }) => ({
        id,
        value: name,
      })
    )
    return result;
  }
  
  async putPositionCategory(data: any, id: string) {
    return await api.put(`formation-category/${id}`, data);
  }

  async postPositionCategory(data: any) {
    return await api.post(`formation-category`, data);
  }

  async postUnofficialCategory(data: any) {
    const result = await api.post(`formation-category/unofficial`, data);
    return result.data;
  }

  async getPositionCategoryById(id: string) {
    const result = await api.get(`formation-category/${id}`);
    return result.data;
  }

  async getAllPosition() {
    const result = await axiosApi.get(`formation-category/findAll`);
    return result?.data;
  }
}
export default new PositionCategoryService();
