import styled from "styled-components";
import {styled as styledMui} from "@mui/material/styles";
import {TextField} from "@mui/material";

interface IPropsTextArea {
  borderColor: any;
}

export const FullScreen = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormCenter = styled.form`
  width: 90%;
  max-width: 1300px;
  /* padding: 40px 4%; */
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 25px;
  background: white;
  border: 1px solid
    ${({ borderColor }) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #8c8c8c;
  }
`;

export const ContainerGap = styled.div``;
export const ContainerButton = styled.div`
  display: flex;
  margin: 30px 0;
  width: 100%;
  gap: 1rem;


  @media screen and (max-width: 1049px) {
    width: 100%;
  }
`;

export const ContainerDropDownSimpleSelect = styled.div`
  margin-bottom: 0.3rem;
  /* 
  img {
    position: relative;
    left: 60px;
    top: 5px;
  } */
`;

export const BoxSelect = styled.div`
  min-height: 40px;
  width: 42%;
  display: flex;
  margin: 10px;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 38px;
  p {
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 10px;
    color: #696969;
    border: 1px solid #8c8c8c;
    margin-top: 10px;
    display: flex;
    gap: 6px;
    margin-bottom: 12px;
    span {
      margin-top: 2px;
    }
  }
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
  gap: 5px;
`;

export const PageTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  margin-top: 30px;
`;

export const TextInfo = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 12px;
`;

export const PageSubtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
  margin-top: 16px;
  margin-bottom: 45px;
`;

export const Row = styled.div`
  margin: 1px solid black;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
  @media screen and (max-width: 1049px) {
    flex-direction: column;
    align-items: none;
    justify-content: center;
  }
`;

export const InputRow = styled.div<{ rowsSize: number[] }>`
  margin-top: 20px;
  display: grid;
  grid-template-columns: ${({ rowsSize }) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 36px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const RemainSpace = styled.div`
  width: 100%;
`;

export const ErrorMessage = styled.p`
  margin: 0.3rem 0;
  font-size: 14px;
  font-weight: 400;
  color: #f5222d;
`;

export const HeaderFullContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
`;

export const CssTextField = styledMui(TextField)({
  borderRadius: "5px",
  fontWeight: "700",
  backgroundColor: "#fff",

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Ubuntu",
      },
    },

  },
});

export const CenterByMaxSize = styled.div`
  width: 100%;
  padding: 0 4%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px;
`;

export const Logo = styled.img`
  height: 32px;
  @media screen and (max-width: 999px) {
    display: none;
  }
`;

export const LogoMin = styled.img`
  display: none;
  height: 32px;
  align-self: center;
  justify-self: center;
  @media screen and (max-width: 999px) {
    display: block;
  }
`;

export const UploadInput = styled.input`
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 10;
  opacity: 0;
  top: 0;
  left: 0;
`;

export const UploadCurriculoButton = styled.button`
  display: flex;
  gap: 14px;
  flex-direction: row;
  margin-top: 31.5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  position: relative;
  border: solid #8c8c8c 1px;
  border-radius: 5px;

  .uploadIcon {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 425px) {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const UploadButtonText = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #131212;
`;

export const removeImage = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  margin-left: 3px;
  color: red;
  font-size: 14px;
  cursor: pointer;
`;

export const IconRemoveImage = styled.img`
  cursor: pointer;
  width: 15px;
`;

export const ContainerRemoveImage = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
`;

export const containerCurriculo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: space-between;
`;

export const ButtonVisualizar = styled.button`
  cursor: pointer;
  display: flex;
  gap: 14px;
  flex-direction: row;
  margin-top: 31.5px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  position: relative;
  border: solid #8c8c8c 1px;
  border-radius: 5px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #131313;
`;

export const FullModalCodePassword = styled.div`
  width: 580px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 34px;
  border-radius: 3px;
  max-height: 96%;
  overflow-y: scroll;
  outline: none;
  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const formForgetPassword = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1px solid black;
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  text-align: center;
  max-width: 100%;
  padding-top: 39px;
`;

export const ModalSubtitleCodeSecurity = styled.p`
  font-family: "Ubuntu";
  margin-top: 34px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  width: 80%;
  color: #131212;
`;

export const LabelCode = styled.p`
  margin-top: 39px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  width: 75%;
  color: #8c8c8c;

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ContainerButtons = styled.p`
  margin-top: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

export const buttonPink = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  line-height: 32px;
  width: 290px;
  height: 52px;
  color: #ffffff;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
`;

export const buttonWhite = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  line-height: 32px;
  width: 290px;
  height: 52px;
  border: 0.6px solid #131212;
  color: #131212;
  background: white;
`;

export const inputCode = styled.input`
  margin-top: 6px;
  border-radius: 5px;
  width: 80%;
  height: 55px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  color: #131212;
  border: 1px solid #8c8c8c;

  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 500px) {
    width: 290px;
  }
`;
