export const colors = {
  primaryPink: "#FF026F",
  primaryOrange: "#FF6424",
  primaryGradient: "linear-gradient(to right, #FF6424,#FF026F)",
  secondaryGradient: "linear-gradient( #FF3867, #FFA630)",
  error: "#F5222D",
  done: "#00A676",
  inProgress: "#302382",
  alerts: "#FF9900",
  backgroundAlert: "##FFE8E6",
  backgroundAlertGreen: "#C4FAEA",
  backgroundAlertBlue: "#E6EDFF",
  backgroundAlertYellow: "#FFF1DD",
  supportLightPink: "#EC4990",
  supportMediumPink: "#E80266",
  supportDarkPink: "#B5024F",
  supportBurgundy: "#850E23",
  supportDarkOrange: "#B52C19",
  supportMediumOrange: "#E85C20",
  supportLightOrange: "#EC8F68",
  black: "#000000",
  blackText: "#131212",
  darkGray300: "#696969",
  gray200: "#8C8C8C",
  gray100: "#AEAEAE",
  lightGray: "#C4C4C4",
  white: "#FFFFFF",
};
