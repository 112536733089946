import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import WrittenLogo from "../../assets/logo-escrita.svg";
import MobileLogo from "../../assets/logo-firedev.png";
import * as S from "./style";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

function Maintence() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const refresh = async () => {
    let tempApi = axios.create({
      baseURL: apiUrl,
    })
    try {
      const response = await tempApi.get("/healthCheck")
      if (response?.data?.status == "Alive") {
        navigate("/home")
      } else {
        setTimeout(refresh, 5000);
      }
    } catch (e) {
      setTimeout(refresh, 5000);
    }
  }

  useEffect(() => {
    setTimeout(refresh, 5000);
  }, [])

  return (
    <div>
      <S.MainDiv>
        <S.LoginFormDiv>
          <S.Logo src={WrittenLogo} alt="Logo Firedev"/>
          <S.MobileLogo src={MobileLogo} alt="Logo Firedev"/>
          <h1>Estamos realizando uma manutenção rápida, em poucos minutos estaremos de volta!</h1>
          <CircularProgress sx={{margin: "auto", marginTop: "50px"}}/>
        </S.LoginFormDiv>
      </S.MainDiv>
    </div>
  );
}

export default Maintence;
