import styled from "styled-components";
import { TextField, styled as estyledMui } from "@mui/material";

export const CssTextField = estyledMui(TextField)({
  "& .MuiInput-underline:after": {
    borderBottomColor: "#8C8C8C",
    border: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#8C8C8C",
      border: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8C8C8C",
    },
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Ubuntu",
      },
    },
  },
});

export const ContainerTableMUI = styled.div`
  width: 100%;

  tbody tr,
  tbody td {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 155px !important;
  }
`;

export const TableLabel = styled.p`
  white-space: nowrap;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #131212;
`;

export const TableRowsInfo = styled.p`
  color: #131212;
  @media screen and (max-width: 800px) {
    font-size: 13px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const ContainerButtonDesktop = styled.div`
  @media (max-width: 482px) {
    display: none;
  }
`;
export const TableHeader = styled.div`
  padding: 10px 30px 10px 30px;
  background-color: white;
  margin-top: 16px;
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 9px 23px;
    gap: 21px;
  }
`;

export const ContainerTableFooter = styled.div`
  width: 100%;
  height: 65px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ul li button {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #131212;

    padding-left: 2px;
    padding-right: 2px;
    margin: 0;
    height: 32px;
    margin-left: 1px;
    margin-right: 1px;
    position: relative;
    border-radius: 1px;
  }

  .Mui-selected {
    background: #ff026f !important;
    border-radius: 0 !important;
    color: white !important;
    border-radius: 2px !important;
  }
`;

export const ButtonAddDesktop = styled.button`
  border: 0;
  width: 155px;
  height: 53px;
  cursor: pointer;
  text-align: center;

  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 36px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;

  color: #ffffff;
`;

export const ButtonSolicitationDesktop = styled.button`
  border: 0;
  width: 325px;
  height: 53px;
  cursor: pointer;
  text-align: center;

  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 36px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  text-align: center;

  color: #ffffff;
`;


export const ButtonAddResponsive = styled.button`
  border: 0;
  border-radius: 50%;
  min-width: 34px;
  width: 34px;
  min-height: 34px;
  height: 34px;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 482px) {
    display: flex;
  }

  svg {
    filter: invert(99%) sepia(6%) saturate(187%) hue-rotate(268deg) brightness(119%) contrast(100%);
  }
`;

export const ContainerInputDesktop = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
  margin-right: 15px;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const ContainerInputResponsive = styled.div`
  display: none;
  @media (max-width: 428px) {
    display: flex;
    input {
      &::placeholder {
        font-size: 14px !important;
      }
    }

    svg {
      width: 13px;
    }
  }
`;

export const ContainerIconsTableHeader = styled.div`
  width: auto;
  display: flex;
  gap: 20px;

  svg:nth-of-type(2) {
    margin-top: 0.2rem;
  }

  @media (max-width: 428px) {
    gap: 9px;
  }
`;
