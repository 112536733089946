import styled from "styled-components";

export const ModalTitleEmailVerify = styled.p`
  margin-top: 39px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 700;
  font-size: 36px;
  color: #ff00;
  text-align: center;
  max-width: 100%;
`;

export const ModalSubtitleCodeSecurity = styled.p`
  font-family: "Ubuntu";
  margin-top: 34px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  width: 80%;
  color: #131212;
`;

export const buttonPink = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  line-height: 32px;
  width: 290px;
  height: 52px;
  color: #ffffff;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
`;

export const buttonWhite = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  line-height: 32px;
  width: 290px;
  height: 52px;
  border: 0.6px solid #131212;
  color: #131212;
  background: white;
`;

export const EmailRegistred = styled.p`
  margin-top: 24px;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 18px;
  text-align: center;
  max-width: 100%;
  color: #8c8c8c;
`;

export const ContainerButtons = styled.p`
  margin-top: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

export const ModalSubtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
  line-height: 20px;
  width: 80%;
  color: #131212;
`;

export const FullModal = styled.div`
  width: 580px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 60px;
  border-radius: 3px;
  text-align: center;
  max-height: 96%;
  overflow-y: scroll;
  outline: none;
  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const FullModalPJ = styled.div`
  width: 580px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  border-radius: 3px;
  text-align: center;
  max-height: 96%;
  overflow-y: scroll;
  outline: none;
  @media screen and (max-width: 600px) {
    padding: 26px 18px;
  }
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  text-align: center;
  max-width: 100%;
  padding-top: 39px;
`;

export const ModalTitlePJ = styled.p`
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  text-align: center;
  max-width: 100%;
  padding-top: 10px;
  margin-bottom: 28px;
`;
