import {Box, CircularProgress, Slider, TextField} from "@mui/material";
import * as T from "../../Global/Typography";
import {t} from "i18next";
import * as S from "./style";
import {Button} from "design-system-firedev";
import React, {useEffect, useState} from "react";
import UserService from "../../services/UserService";
import ConfigurationsService from "../../services/ConfigurationsService";
import {enqueueSnackbar} from "notistack";

export default function Configurations() {
  const [averageGrade, setAverageGrade] = useState<number>()
  const [maxAttemptsCandidate, setMaxAttemptsCandidate] = useState(5);
  const [maxAttemptsCollaborator, setMaxAttemptsCollaborator] = useState(5);
  const [daysNextAttemptsCandidate, setDaysNextAttemptsCandidate] = useState(30);
  const [daysNextAttemptsCollaborator, setDaysNextAttemptsCollaborator] = useState(1);
  const [companyId, setCompanyId] = useState<string>()
  const [loading, setLoading] = useState(false)

  function handleGradeChange(e) {
    setAverageGrade(e.target.value)
  }

  const sendData = async () => {
    const ConfigurationDTO = {
      approvalGrade: averageGrade,
      triesInternal: maxAttemptsCollaborator,
      triesExternal: maxAttemptsCandidate,
      lockTimeInternal: daysNextAttemptsCollaborator,
      lockTimeExternal: daysNextAttemptsCandidate
    }
    try {
      if (maxAttemptsCandidate > 5 || maxAttemptsCandidate < 1) {
        enqueueSnackbar(t("configurations.maxAttemptsCandidateErr"), {
          variant: "error",
        });
        return;
      }
      if (maxAttemptsCollaborator < 1) {
        enqueueSnackbar(t("configurations.maxAttemptsCollaboratorErr"), {
          variant: "error",
        });
        return;
      }
      if (daysNextAttemptsCandidate > 180 || daysNextAttemptsCandidate < 30) {
        enqueueSnackbar(t("configurations.daysNextAttemptsCandidateErr"), {
          variant: "error",
        });
        return;
      }
      if (daysNextAttemptsCollaborator > 180 || daysNextAttemptsCollaborator < 1) {
        enqueueSnackbar(t("configurations.daysNextAttemptsCollaboratorErr"), {
          variant: "error",
        });
        return;
      }
      await ConfigurationsService.putConfigurations(companyId, ConfigurationDTO)
      enqueueSnackbar(t("configurations.configurationsSaved"), {variant: "success"});
    } catch (err: any) {
      console.error(err.data.response.message);
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const companyId = await UserService.getUser();
      setCompanyId(companyId.company?.id)
      const result = await ConfigurationsService.getConfigurations(companyId.company?.id)
      setAverageGrade(result.approvalGrade)
      setMaxAttemptsCandidate(result.triesExternal)
      setMaxAttemptsCollaborator(result.triesInternal)
      setDaysNextAttemptsCandidate(result.lockTimeExternal)
      setDaysNextAttemptsCollaborator(result.lockTimeInternal)
    } catch (err: any) {
      console.error(err.data.response.message);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  const grades = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ];

  return (
    <S.ContainerFullPage>
      <T.Display>{t("sidebar.configurations")}</T.Display>
      <S.ContainerCards>
        <S.ContainerLabel>{t("configurations.averageGrade")}</S.ContainerLabel>
        <Box sx={{width: 300}}>
          {
            (loading && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={80} color="info"/>
              </Box>
            )) ||
              <Slider
                  value={averageGrade}
                  onChange={(e) => {
                    handleGradeChange(e);
                  }}
                  step={1}
                  marks={grades}
                  min={1}
                  max={10}
              />
          }
        </Box>
      </S.ContainerCards>
      <S.ContainerCards>
        <S.ContainerLabel>{t("configurations.triesLimit")}</S.ContainerLabel>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1, marginRight: "8px" }}>
            <TextField
              label={t("configurations.maxAttemptsCandidate")}
              type="number"
              fullWidth={true}
              inputProps={{ min: 1, max: 5 }}
              sx={{ marginBottom: 2 }}
              value={maxAttemptsCandidate}
              onChange={(e) => setMaxAttemptsCandidate(parseInt(e.target.value))}
            />
          </div>
          <div style={{ flex: 1, marginLeft: "8px" }}>
            <TextField
              label={t("configurations.maxAttemptsCollaborator")}
              type="number"
              fullWidth={true}
              inputProps={{ min: 1 }}
              sx={{ marginBottom: 2 }}
              value={maxAttemptsCollaborator}
              onChange={(e) => setMaxAttemptsCollaborator(parseInt(e.target.value))}
            />
          </div>
        </div>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flex: 1, marginRight: "8px" }}>
            <TextField
              label={t("configurations.daysNextAttemptsCandidate")}
              fullWidth={true}
              type="number"
              inputProps={{ min: 30, max: 180 }}
              sx={{ marginBottom: 2 }}
              value={daysNextAttemptsCandidate}
              onChange={(e) => setDaysNextAttemptsCandidate(parseInt(e.target.value))}
            />
          </div>
          <div style={{ flex: 1, marginLeft: "8px" }}>
            <TextField
              label={t("configurations.daysNextAttemptsCollaborator")}
              type="number"
              fullWidth={true}
              inputProps={{ min: 1, max: 180 }}
              sx={{ marginBottom: 2 }}
              value={daysNextAttemptsCollaborator}
              onChange={(e) => setDaysNextAttemptsCollaborator(parseInt(e.target.value))}
            />
          </div>
        </div>


      </S.ContainerCards>

      <S.ContainerGap>
        <Button
          type="submit"
          onClick={sendData}
          typeOfButton="primary"
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text={t('positionsManagement.save')}
        />
      </S.ContainerGap>
    </S.ContainerFullPage>
  );
}
