import styled from "styled-components";
import { makeStyles } from "@mui/styles";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;;
  display: flex;
  flex-direction: column;

  > div {
    
    > div {
      ::placeholder {
          color: rgb(255, 255, 255);
        }
      > div {
        height: ${({ style }) => style?.height || '42px'};
        background-color: #E85C20;

        > div {
            background-color: #E85C20;
            &:focus-visible {
              box-shadow: 0 0 0 0;
              border: 0 none;
              outline: 0;
            }
            ::placeholder {
              color: rgb(255, 255, 255);
            }
        }

        :focus-visible {
          box-shadow: 0 0 0 0;
          border: 0 none;
          outline: 0;
        }

        &:hover {
          box-shadow: 0 0 0 0;
          border: 0 none;
          outline: 0;
        }
      }
    }
  }
`;

export const OptionText = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #131212;
`;

export const OverrideStylesMui = makeStyles({
  MuiAutocompleteRoot: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: '#FFF',
        opacity: 1
      }
    },
    '& .MuiSvgIcon-root': {
      fill: '#FFF',
    }
  }
});

export const customInputStyle = {
  fontSize: '18px',
  fontWeight: 700,
  padding: 0,
  fontFamily: 'Ubuntu',
  borderRadius: '5px',
  maxHeight: `${props => props.style?.height || '42px'}`,
};

export const customInputPropsStyle = {
  fontSize: '18px',
  padding: '0',
  color: "rgb(255, 255, 255)",
};

export const ErrorText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  color: #f5222d;
`;