import { CSSProperties } from "react";

import * as S from "./styles";

interface IProgressBar {
  value: number;
  max?: number;
  isChallenge?: boolean;
  smallSize?: boolean;
  style?: CSSProperties;
}

export const ProgressBar = ({
  value,
  max = 100,
  isChallenge,
  style,
  smallSize: halfSize,
}: IProgressBar) => {
  return (
    <>
      <S.Container style={style} smallSize={halfSize} isChallenge={isChallenge}>
        <progress value={value} max={max} style={{ backgroundImage: "pink" }} />
        {!isChallenge && <S.Span>{((value / max) * 100).toFixed(0)}%</S.Span>}
      </S.Container>
    </>
  );
};
