import styled from "styled-components";
import { Button, Fonts } from "design-system-firedev";

export const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

export const formForgetPassword = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1px solid black;
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const InputEmail = styled.input<{ borderColor?: string }>`
  border-radius: 5px;
  width: 100%;
  height: 52px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  vertical-align: middle;
  color: #131212;
  border: 1px solid ${({ borderColor }) => borderColor};

  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
`;

export const LoginFormDiv = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  padding: 8.75rem 13rem 0 8.75rem;

  flex-direction: column;

  @media (max-width: 1124px) {
    padding: 8.75rem 7rem 0 4.75rem;
  }

  @media (max-width: 767px) {
    padding: 2.875rem 2.5rem 0 2.5rem;
  }
`;

export const LoginFormWrapper = styled.div`
  margin: auto;
  width: 100%;

  @media (min-width: 0px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    width: 328px;
  }

  @media (min-width: 1440px) {
    width: 380px;
  }
`;

export const FormDiv = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: flex-end;
  height: 100vh;
  min-width: 50%;

  /* @media (max-width: 850px) {
    width: 45%;
  } */
`;

export const LoginArtDiv = styled.div`
  width: 50%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 50;
  box-shadow: -2px 0px 5px #0005;

  @media (max-width: 850px) {
    width: 40%;
  }

  @media (max-width: 767px) {
    width: 100vw;
    height: 16rem;
    position: static;
    margin-right: 0;
  }

  img {
    object-fit: cover;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
  }
`;

export const Logo = styled.img`
  width: 306px;
  height: 68px;
  display: block;
  margin-bottom: 2.7rem;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  @media only screen and (max-width: 767px) {
    display: block;
    width: 35px;
    height: 45px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Title = styled(Fonts)`
  margin-bottom: 0;
`;

export const inputCode = styled.input`
  margin-top: 6px;
  border-radius: 5px;
  width: 80%;
  height: 55px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  color: #131212;
  border: 1px solid #8c8c8c;

  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const Subtitle = styled(Fonts)`
  color: #8c8c8c;
  margin-top: 1rem;
  margin-bottom: 3.2rem;
  white-space: nowrap;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
`;

export const FastAccessTitle = styled(Fonts)`
  height: auto;
  color: #8c8c8c;
  margin-top: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 1rem;
  white-space: nowrap;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
`;

export const ErrorMessageLoginFailed = styled(Fonts)`
  color: #f5222d;
`;

export const ContainerInputs = styled.div`
  width: 23.5rem;

  @media (max-width: 425px) {
    width: 21rem;
  }

  @media (max-width: 375px) {
    width: 16rem;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #8c8c8c;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 36px;
  width: 185px;
`;

export const ContainerLinkedin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 260px;
`;


export const Line1 = styled.div`
  flex: 1;
  height: 1.5px;
  width: 20px;
  background-color: #ccc;
`;

export const FastAccess2 = styled.div`
  display: flex;
  align-items: center;
  width: 270px;
`;

export const FastAccess = styled.div`
  margin-top: 16px;
`;


export const ButtonDS = styled(Button)`
  text-align: center;
  width: 100%;
`;

export const SignUpDiv = styled.div`
  margin-top: 16px;
  display: flex;
  alignitems: center;
  cursor: pointer;
  color: #ff026f;
`;

export const SignForgetPassword = styled.div`
  display: flex;
  alignitems: center;
  cursor: pointer;
  color: #f5222d;
`;

export const containerErrorAndForgetPassword = styled.div`
  display: flex;
  alignitems: center;
`;

export const SignUpLink = styled(Fonts)`
  white-space: nowrap;
  padding-top: 10px;
`;

export const FontForgetPassword = styled(Fonts)`
  text-decoration: underline;
  padding-left: 4px;
  white-space: nowrap;
  font-weight: 600;
`;

export const LinkIcon = styled.div`
  margin-left: 5px;
  margin-top: 8px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const ButtonIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 46px;
  margin-top: 26px;
  img {
    cursor: pointer;
  }
`;

export const ContainerQuickAcess = styled.div`
  margin-top: 2em;
  width: 250px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  hr {
    flex: 1;
  }

  span {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #8c8c8c;
  }
`;

export const EmailLength = styled.p`
  width: 377px;
  text-align: end;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const PasswordLength = styled.p`
  margin-top: 4px;
  width: 377px;
  text-align: end;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const ButtonLogin = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  width: 185px;
  height: 52px;

  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 3px;

  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
`;

export const InputLoginResponsive = styled.input<any>`
  width: 100%;
  height: 3.375rem;
  outline: none;
  background: white;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "1px solid #8c8c8c"};
  border-radius: 5px;
  padding-left: 1rem;

  &::placeholder {
    color: #8c8c8c;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;

    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }
`;

export const FullModal = styled.div`
  width: 580px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 60px;
  border-radius: 3px;
  text-align: center;
  max-height: 96%;
  overflow-y: scroll;
  outline: none;
  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const FullModalCodePassword = styled.div`
  width: 580px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 34px;
  border-radius: 3px;
  max-height: 96%;
  overflow-y: scroll;
  outline: none;
  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const imageApproved = styled.img`
  width: 86px;
`;

export const FullModalEmailVerify = styled.div`
  width: 466px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 27px;
  border-radius: 3px;
  text-align: center;
  max-height: 96%;
  overflow-y: scroll;
  outline: none;
  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const LabelCode = styled.p`
  margin-top: 39px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  width: 75%;
  color: #8c8c8c;

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const buttonPink = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  border-radius: 3px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  line-height: 32px;
  width: 290px;
  height: 52px;
  color: #ffffff;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
`;

export const buttonWhite = styled.button`
  cursor: pointer;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  line-height: 32px;
  width: 290px;
  height: 52px;
  border: 0.6px solid #131212;
  color: #131212;
  background: white;
`;

export const LabelPassword = styled.p`
  margin-top: 55px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  width: 80%;
  color: #8c8c8c;

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ErrorMessage = styled(Fonts)`
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  width: 75%;
  text-align: left;
  color: #f5222d;

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ModalTitleEmailVerify = styled.p`
  margin-top: 39px;
  margin-bottom: 30px;
  text-align: left;
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  text-align: center;
  max-width: 100%;
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  text-align: center;
  max-width: 100%;
  padding-top: 39px;
`;

export const ModalSubtitleCodeSecurity = styled.p`
  font-family: "Ubuntu";
  margin-top: 34px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  width: 80%;
  color: #131212;
`;

export const ModalSubtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
  line-height: 20px;
  width: 80%;
  color: #131212;
`;

export const ModalSubtitleTwo = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  max-width: 100%;
  color: #131212;
`;

export const EmailRegistred = styled.p`
  margin-top: 24px;
  word-wrap: break-word;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  max-width: 100%;
  color: #8c8c8c;
`;

export const ContainerButtons = styled.p`
  margin-top: 39px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 40px;
`;

export const inputNewPassword = styled.input`
  margin-top: 6px;
  border-radius: 5px;
  align-items: center;
  width: 377px;
  height: 55px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
  color: #131212;
  border: 1px solid #8c8c8c;

  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;
    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ContainerPassword = styled.div`
  display: flex;
  width: 80%;
  max-width: 80%;
  height: 3rem;

  @media (max-width: 500px) {
    width: 290px;
    max-width: 290px;
  }
`;

export const ContainerInputPassword = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #8c8c8c;
  border-radius: 5px;

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ContainerInputAndIcon = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ButtonToggleVisibilityOfPassword = styled.button`
  width: auto;
  height: auto;
  background: transparent;
  border: 0;

  svg {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
    color: #8c8c8c;
  }
`;

export const InputPassword = styled.input`
  padding-left: 1rem;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
  margin-right: 50px;
  height: 100%;
  background-color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  color: #131212;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
  &::placeholder {
    color: #8c8c8c;
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 375px) {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 500px) {
    width: 290px;
  }
`;

export const ErrorMessagem = styled.p`
  margin-top: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: #f5222d;
`;
