import styled from "styled-components";

interface ILinkProperties {
  linkDisable?: boolean;
}

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 1rem;
  width: 100%;
`;

export const AlreadySent = styled.p`
  color: #FF026F;
  margin-bottom: 10px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  height: 100%;
  padding: 14px 16px;
  background: white;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;

export const FullModal = styled.div`
  font-family: "Ubuntu";
  width: 652px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 75px;
  border-radius: 3px;
  text-align: center;
  max-height: 98%;
  overflow-y: auto;
  transition: height 0.5s ease-in-out;

  & > * {
    max-height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  &:focus {
    outline: none !important;
  }

  &.expanded {
    height: 100%;
  }

  &.collapsed {
    height: 750px;
  }

  @media screen and (max-width: 600px) {
    padding: 40px 32px;
  }
`;

export const TitleWrapper = styled.div`
  padding-bottom: 36px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-top: 34px;
`;

export const AvatarWrapper = styled.div`
  text-align: center;
  width: 400px;
  margin-bottom: 60px;
`;

export const AvatarImageWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 150px;
  height: 150px;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const AvatarPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const LineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const UnlockButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const LabelText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  text-align: left;
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const ButtonText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000
`;

export const LinkText = styled.a<ILinkProperties>`
cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ linkDisable }) => (linkDisable ? "#AEAEAE" : "#FF026F")};
  text-decoration-line: underline;
`;
