import styled from "styled-components";

export const Card = styled.div<{
  borderColor: string;
  hasQuestions: boolean;
  draggable: boolean;
}>`
  overflow: hidden;
  justify-content: space-between;
  cursor: ${({ draggable }) => (draggable ? "grab" : "pointer")};
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffe8e6;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 3px;
  padding: 0.75rem;
  height: 128px;
  width: 267px;
  opacity: ${({ hasQuestions }) => (hasQuestions ? 1 : 0.4)};
  min-width: 267px;
  max-width: 100%;
  .dragging {
    opacity: 0.5;
  }
  .over {
    border: 1px dashed black;
  }
`;

export const ContainerAllCards = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 2rem 1.5rem;
  min-width: 302px;
  gap: 1.5rem;
  background: linear-gradient(
      180deg,
      rgba(255, 93, 40, 0.18) 48.93%,
      rgba(255, 0, 80, 0.18) 100%
    ),
    url(.jpg);
`;

export const AbaLineRowCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const ContainerLineCircle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: visible;
  margin-top: 55px;
`;


export const LineStatusCircle = styled.div<{ color?: string }>`
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  z-index: 2;
  background-color: ${({ color }) => color};
`;

export const VerticalLine = styled.div`
  position: absolute;
  left: 50%;
  top: 9px;
  transform: translate(-50%, 0);
  height: calc(100% + 80px);
  width: 2px;
  z-index: 0;
  background-color: #696969;
`;

export const CardsLineContainer = styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ff6423aa;
  }
  max-width: 100%;
  gap: 1rem;
  align-items: center;
`;

export const WithoutSkills = styled.p`
  color: #131212;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const NewLevel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 2rem;
  border: 2px solid #696969;
  font-size: 1rem;
`;

export const RearrangeMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 242px;
`;

export const CardTitle = styled.p<{ color: any }>`
  font-size: 1rem;
  text-align: left;
  font-weight: 500;
  color: ${({ color }) => color || "#131212"};
`;

export const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    border-radius: 50%;
    opacity: 0.5;
    background-color: solid rgba(0, 0, 0, 0.05);
  }
`;

export const CardDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 300;
  color: ${({ color }) => color || "#696969"};
  text-align: left;
  max-width: 267px;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const CardStatus = styled.p<{ color: string }>`
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: ${({ color }) => color};
`;
