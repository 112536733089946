import { InfoOutlined } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import { t } from "i18next";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import treeBlackIcon from "../../assets/TreeBlackIcon.svg";
import TreeWhiteIcon from "../../assets/TreeWhiteIcon.svg";
import SetaGray from "../../assets/setaGray.svg";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import Breadcrumb from "../../components/Breadcrumb/index";
import { Button } from "../../components/Button";
import CardTree from "../../components/CardTree";
import DefaultLoading from "../../components/Loading/defaultLoading";
import ModalSubscribe from "../../components/ModalSubscribe";
import { ProgressBar } from "../../components/Progress";
import rightButton from "../../components/assets/rightButton.svg";
import FormationService from "../../services/FormationService";
import SkillTreesService from "../../services/SkillTreeService";
import UserService from "../../services/UserService";
import { ButtonDS } from "../Login/style";
import star from "./images/star.svg";
import * as S from "./style";
import { TextCompany } from "./style";
import {useContainerDimensions} from "../../hooks/useContainerDimensions";

export default function PositionTrail() {
  const ref = useRef();
  const { width } = useContainerDimensions(ref);
  const { positionSlug } = useParams();
  const navigate = useNavigate();
  const [isSubscribed, setIsSubscribed] = useState<any>(false);
  const [resultDashBoard, setResultDashBoard] = useState<any>();
  const [expanded, setExpanded] = useState<string | false>(null);
  const [showWarnModal, setShowWarnModal] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [subscribing, setSubscribing] = useState<boolean>(false);
  const [treeLoading, setTreeLoading] = useState(false);
  const [competence, setCompetence] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [moreLess, setMoreLess] = useState(t("positions.seeMore"));

  const progress = resultDashBoard?.progress;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getColor = (status: any) => {
    switch (status) {
      case "ACHIEVED":
        return "#00A676";
      case "UNLOCKED":
      case "STARTED":
        return "#302382";
      case "WAITING_EVALUATION":
        return "#FF9900";
      default:
        return "#C4C4C4";
    }
  };

  function handleSeeMore() {
    if (seeMore) {
      setSeeMore(false);
      setMoreLess(t("positions.seeMore"));
    } else {
      setSeeMore(true);
      setMoreLess(t("positions.seeLess"));
    }
  }

  async function subscribe() {
    if (!localStorage.getItem("token")) {
      setShowWarnModal(true);
      return;
    }
    const isComplete = await UserService.verifyIfProfileIsComplete();
    if (!isComplete) {
      setOpenEditDialog(true);
    } else {
      setSubscribing(true);
      const restIsSubscribed = await FormationService.subscribe(
        positionSlug
      ).then(() => setSubscribing(false));
      setIsSubscribed(restIsSubscribed);
    }
  }

  const fetchData = async () => {
    const restDashboard = await FormationService.getDashboard(positionSlug);
    setResultDashBoard(restDashboard?.data);
    setIsSubscribed(restDashboard?.data?.isSubscribed ?? false);
  };

  useEffect(() => {
    fetchData();
  }, [isSubscribed]);

  useEffect(() => {
    (async () => {
      try {
        setTreeLoading(true);
        if (expanded && expanded !== null) {
          const result = await SkillTreesService.getSkillTreeSkillsByFormation(
            expanded,
            positionSlug
          );
          setCompetence(result.skills);
        }
      } catch (err) {
        setTreeLoading(false);
        console.error(err);
      }
      setTreeLoading(false);
    })();
  }, [expanded, isSubscribed]);

  function formatCurrency(value) {
    if (value == null) {
      return "";
    }
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  }

  useEffect(() => {
    if (resultDashBoard?.skillTrees.length > 0) {
      setExpanded(resultDashBoard.skillTrees[0]?.slug);
    }
  }, [resultDashBoard]);


  return (
    <div>
      <S.Container>
        <S.ContainerTotal>
            <Breadcrumb
              currentPage={resultDashBoard?.title}
              previousPage={[{ value: t("positions.position"), link: "/home" }]}
            />
          <S.ContainerButtonAndTitle>
            <S.TitlePosition>{resultDashBoard?.title}</S.TitlePosition>
            <S.MiniButtonContainer data-cy="trailPageSubscribeButton">
              {!isSubscribed && (
                <Button
                  loading={subscribing}
                  onClick={subscribe}
                  data-cy="subscribeButton"
                >
                  {t("positions.enroll")}
                </Button>
              )}
              {isSubscribed && progress !== 100 && (
                <Button disable data-cy="subscribeButton">
                  {t("positions.enrolled")}
                </Button>
              )}
              {progress === 100 && isSubscribed && (
                <S.ButtonConclude data-cy="subscribeButton">
                  <span>
                    <img src={rightButton} alt="" />
                  </span>
                  {t("positions.concluded")}
                </S.ButtonConclude>
              )}
            </S.MiniButtonContainer>
          </S.ContainerButtonAndTitle>
          <TextCompany>
            {t("positions.modality")}: {t(resultDashBoard?.jobModality)}
          </TextCompany>
          <br />
          {resultDashBoard?.company?.id != -1 ? (
            <S.EmployPosition>
              <S.ImageCompany
                src={
                  resultDashBoard?.company?.avatar || skillHunterPositionIcon
                }
                alt=""
              />
              <TextCompany>{resultDashBoard?.company?.name}</TextCompany>
            </S.EmployPosition>
          ) : (
            <S.EmployPosition>
              <S.ImageCompany src={skillHunterPositionIcon} alt="" />
              <TextCompany>Skill Hunter</TextCompany>
              <Tooltip
                title={t("positions.skillhunterTooltip")}
                placement="top"
              >
                <InfoOutlined sx={{ opacity: 0.5 }} />
              </Tooltip>
            </S.EmployPosition>
          )}

          <S.ContainerButton>
            {!isSubscribed && (
              <Button
                loading={subscribing}
                onClick={subscribe}
                data-cy="subscribeButton"
              >
                {t("positions.enroll")}
              </Button>
            )}
            {isSubscribed && progress !== 100 && (
              <Button disable>{t("positions.enrolled")}</Button>
            )}
            {progress === 100 && isSubscribed && (
              <S.ButtonConclude>
                <span>
                  <img src={rightButton} alt="" />
                </span>
                {t("positions.concluded")}
              </S.ButtonConclude>
            )}
          </S.ContainerButton>

          {resultDashBoard?.categories?.map((item) => {
            return <S.CategoriesPosition>{item?.name}</S.CategoriesPosition>;
          })}

          <S.ContainerIconTreeWithCompetences>
            <img
              src={treeBlackIcon}
              alt="Imagem da competência"
            />
            <S.containerCompetences>
              <S.NumberSkillTree>
                {resultDashBoard?.skillTreesCount}
              </S.NumberSkillTree>
              <S.FooterItemLabel>
                {t("positions.competences")}
              </S.FooterItemLabel>
            </S.containerCompetences>
          </S.ContainerIconTreeWithCompetences>

          {
            !isSubscribed && resultDashBoard?.userAchievedSkills > 0 &&
              <Typography variant="h6" component="h2">{t('positions.achievedSkills1') +
                resultDashBoard?.userAchievedSkills?.toString() + t('positions.achievedSkills2')}
              </Typography>
          }

          <S.TitleAboutPosition>{t("positions.about")}</S.TitleAboutPosition>

          <S.DescriptionOfPosition open={seeMore} ref={ref} dangerouslySetInnerHTML={{ __html: resultDashBoard?.description }}/>
          {resultDashBoard?.description &&
            (resultDashBoard?.description.split("\n").length > 6 ||
              resultDashBoard?.description?.length / (width / 6) > 6) && (
              <S.SeeMoreButtonDiv>
                <Button
                  onClick={handleSeeMore}
                  children={moreLess}
                  hoverDisabled
                  style={{
                    background: "none",
                    color: "#FF026F",
                    width: "auto"
                }}
                />
              </S.SeeMoreButtonDiv>
            )}

          {(resultDashBoard?.positionMinSalary ||
            resultDashBoard?.positionMaxSalary) && (
              <>
                <S.TitleAboutPosition>{t("positionsManagement.salaryRange")}</S.TitleAboutPosition>
                <p>
                  {formatCurrency(resultDashBoard?.positionMinSalary)} -{" "}
                  {formatCurrency(resultDashBoard?.positionMaxSalary)}
                </p>
              </>
            )}
          <S.TitleAboutPosition>
            {t("positions.jobLocations")}
          </S.TitleAboutPosition>
          {resultDashBoard?.adressess &&
            resultDashBoard?.adressess.map((address) => {
              return <S.TextCompany>{address}</S.TextCompany>;
            })}

          <S.ContainerProgressCard>
            <S.ContainerProgressStarText>
              <img src={star} alt="star_progress_icon" style={{ width: 50 }} />
              <S.ContainerTitlePercent>
                <S.ProgressTitleSubtitle>
                  <S.Title>{t("positions.overallProgress")}</S.Title>
                  <S.ProgressSubtitle>
                    {t("positions.complete")}
                  </S.ProgressSubtitle>
                </S.ProgressTitleSubtitle>
                <S.PercentText>{resultDashBoard?.progress}%</S.PercentText>
              </S.ContainerTitlePercent>
            </S.ContainerProgressStarText>
            <ProgressBar
              value={resultDashBoard?.progress || 0}
              max={100}
              isChallenge
            />
          </S.ContainerProgressCard>
          <S.ContainerAccordions>
            {resultDashBoard?.skillTrees.map((comp, i) => (
              <Accordion
                disableGutters
                key={comp?.id}
                expanded={expanded === comp?.slug}
                onChange={handleChange(comp?.slug)}
              >
                <AccordionSummary
                  data-cy="positionAcordion"
                  aria-controls={`${comp?.id}d-content`}
                  id={`${comp?.id}d-header`}
                >
                  <S.ContainerAccordionSummary>
                    <S.ContainerAccordionSummaryLeft>
                      <S.AccordionSummaryCircleImg
                        color={getColor(comp?.status || "UNLOCKED")}
                      >
                        <S.AccordionSummaryImg
                          src={TreeWhiteIcon}
                          alt="TreeIcon"
                        />
                      </S.AccordionSummaryCircleImg>
                      <S.ContainerAccordionSummaryLeftTexts>
                        <S.TitleAccordionSummary>
                          {comp?.name}
                        </S.TitleAccordionSummary>

                        <S.SubtitleAccordionSummary
                          color={getColor(comp?.status || "UNLOCKED")}
                        >
                          {t("positions.progress")}: {comp?.progress || 0}%
                        </S.SubtitleAccordionSummary>
                      </S.ContainerAccordionSummaryLeftTexts>
                    </S.ContainerAccordionSummaryLeft>
                    <div
                      style={{ display: "flex", gap: 12, alignItems: "center" }}
                    >
                      {comp?.mandatory && (
                        <Tooltip
                          title={t("positions.mandatory")}
                          placement="top"
                        >
                          <span>
                            <StarIcon sx={{ color: "gold" }} />
                          </span>
                        </Tooltip>
                      )}
                      <S.AccordionSummarySetaImg
                        up={expanded === comp?.id}
                        src={SetaGray}
                        alt="Seta"
                      />
                    </div>
                  </S.ContainerAccordionSummary>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, m: 0 }}>
                  {treeLoading ? (
                    <S.ContainerAccordionDetailsLoading>
                      <DefaultLoading color="#898989" />
                      <S.LoadingText>{t("positions.loading")}...</S.LoadingText>
                    </S.ContainerAccordionDetailsLoading>
                  ) : (
                    <S.ContainerAccordionDetails>
                      {competence?.length > 0 ? (
                        <CardTree
                          skills={competence}
                          onClick={(skill) => {
                            if (localStorage.getItem("token")) {
                              navigate(
                                `/position/${resultDashBoard.company?.slug}/${resultDashBoard.slug}/skill-tree/${expanded}/skill/${skill.slug}`
                              );
                            } else {
                              setShowWarnModal(true);
                            }
                          }}
                        />
                      ) : (
                        <S.WithoutSkills>
                          {t("positions.noChallenge")}
                        </S.WithoutSkills>
                      )}
                    </S.ContainerAccordionDetails>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </S.ContainerAccordions>
        </S.ContainerTotal>
      </S.Container>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("positions.completeProfile")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>{t("positions.doThisNow")}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonDS
            typeOfButton="secondary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("no")}
            onClick={() => setOpenEditDialog(false)}
          />
          <ButtonDS
            typeOfButton="primary"
            size="custom"
            height={40}
            width={140}
            fontVariant="body1"
            text={t("yes")}
            onClick={() => navigate("/EditUser")}
            data-cy="acceptEdit"
          />
        </DialogActions>
      </Dialog>
      <Modal
        open={showWarnModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowWarnModal(false);
        }}
      >
        <ModalSubscribe
          onClose={() => {
            setShowWarnModal(false);
          }}
          company={resultDashBoard?.company?.slug}
          id={positionSlug}
        />
      </Modal>
    </div>
  );
}
