import api, { acessToken, axiosApi } from "./api";
import { saveAs } from 'file-saver';
class FileService {
  async sendFile(file: any) {
    const formData = new FormData();
    formData.append("file", file);
    const resultUpload = await axiosApi({
      method: "post",
      url: "file-upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: acessToken(),
      },
    });
    return resultUpload.data;
  }

  async sendFileNoToken(images: File[]) {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("images", image, image.name);
    });
    const response = await axiosApi.post(`file-upload/image/no-auth`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async sendFileDocument(files: File[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file, file.name);
    });
    const response = await axiosApi.post(`file-upload/file-document`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }

  async fileUpload(file: FormData) {
    const response = await api.post(`file-upload`, { file });
    return response;
  }

 async downloadFile(fileId: number, fileName?: string) {
    const resultUpload = await api.get(`file-upload/base64?fileId=${fileId}`);
    saveAs(resultUpload.data?.base64, fileName || resultUpload.data?.fileName)
  }

  async imagesUpload(images: any[]) {
    const formData = new FormData();
    images.forEach((image) => {
      if(!image?.id) {
        formData.append("images", image, image.name);
      }
    });
    let response;
    if(!formData.entries().next().done) {
      response = await api.post(`file-upload/images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      response = images.map((image) => {
        return Number(image.id);
      });
      response = { data: Array.from(response) };
    }

    return response;
  }
}
export default new FileService();
