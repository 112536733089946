import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Collapse, Divider, Fade, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Ok500 from "../../../assets/ok-500.svg";
import SpinnerLoading from "../../../assets/spinner-loading.gif";
import FailedImage from "../../../assets/triste.svg";
import waitingEvalImage from "../../../assets/waiting-evaluation.svg";
import { ProgressBar } from "../../../components/Progress";
import StudyArticleItem from "../../../components/StudyArticleItem";
import { IStudyArticle } from "../../../models/StudyArticle";
import * as S from "./styles";

// Em milisegundos
const timeToFade = 1000;

interface IChallengeResponse {
  status: "ACHIEVED" | "FAILED" | "WAITING_EVALUATION";
  questionsResults: any[];
  formationName: string;
  skillTreeName: string;
  formationProgress: number;
  skillTreeProgress: number;
  skillGrade: number;
  numberOfTries?: number;
  triesLeft?: number;
  daysToNextAttempt?: number;
  studyArticles?: IStudyArticle[];
}

export function ChallengeResponse(data: IChallengeResponse) {
  const {
    status,
    formationName,
    skillTreeName,
    questionsResults,
    formationProgress,
    skillTreeProgress,
    skillGrade,
    studyArticles,
    numberOfTries,
    triesLeft,
    daysToNextAttempt
  } = data;
  const navigate = useNavigate();
  const [results, setResults] = useState(questionsResults);
  const [showSkillFeedback, setShowSkillFeedback] = useState(false);

  const setTimeoutToShowQuestionFeedback = () => {
    results.map((currentResult, i) => {
      setTimeout(() => {
        setResults((prevResults) =>
          prevResults.map((resultToUpdate) => {
            if (currentResult.id !== resultToUpdate.id) {
              return resultToUpdate;
            }
            return { ...resultToUpdate, show: true };
          })
        );
      }, (i + 1) * timeToFade);
    });
  };

  const setTimeoutToShowSkillFeedback = () => {
    setTimeout(() => {
      setShowSkillFeedback(true);
    }, results.length * timeToFade);
  };

  useEffect(() => {
    setTimeoutToShowQuestionFeedback();
    setTimeoutToShowSkillFeedback();
  }, []);

  const hasAchieved = () => {
    return status === "ACHIEVED";
  }

  return (
    (status === "WAITING_EVALUATION" && (
      <S.Container>
        <S.Image src={waitingEvalImage} />
        <S.Title data-cy="challengeResponseTitle">
          {t("challenge.challengeAnswered")}
        </S.Title>
        <S.Subtitle>{t("challenge.sentAnswer")}</S.Subtitle>
        <S.ContainerButton>
          <S.ButtonBack
            data-cy="challengeResponseBackButton"
            onClick={() => navigate(-2)}
          >
            {t("challenge.lastButton")}
          </S.ButtonBack>
        </S.ContainerButton>
      </S.Container>
    )) || (
      <Grid container justifyContent={"space-between"}>
        <Grid item sm={6}>
          <Grid container height={"100%"}>
            <Grid item xs={10}>
              <Typography variant="h5" fontWeight={500} marginBottom={5}>
                {t("challenge.yourPerformance")}
              </Typography>
              {results.map((result, i) => {
                return (
                  <Fade key={result.id} timeout={2000} in={result.show}>
                    <Box>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        {result.title}{" "}
                        {result.success ? <CheckCircle
                          style={{ marginLeft: 10 }}
                          color={"success"}
                        /> : <Cancel
                          style={{ marginLeft: 10 }}
                          color={"error"}
                        />}

                      </Typography>
                      <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                    </Box>
                  </Fade>
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
          sm={5}
          style={{ borderLeft: "1px solid gray" }}
        >
          {showSkillFeedback === false ? (
            <>
              <img width={150} src={SpinnerLoading} />
            </>
          ) : (
            <Fade in={showSkillFeedback} timeout={timeToFade}>
              <img
                style={{ maxWidth: "311px", maxHeight: "311px" }}
                src={hasAchieved() ? Ok500 : FailedImage}
              />
            </Fade>
          )}
        </Grid>
        <Collapse in={showSkillFeedback} timeout={timeToFade}>
          <Grid container marginTop={5}>
            <Grid item xs={12}>
              <Divider />
              <Typography
                marginTop={2}
                variant="h6"
                fontWeight={500}
                textAlign={"center"}
              >
                {hasAchieved() ? t("challenge.congratsTitle") : t("challenge.keepGoingTitle")}
              </Typography>
              <Typography variant="subtitle2" textAlign={"center"}>
                {hasAchieved() ? t("challenge.congratsSubTitle") : t("challenge.keepGoingSubTitle")}
              </Typography>
            </Grid>
            <Grid item container columnSpacing={6} marginTop={5} xs={12}>
              <Grid item md={4}>
                <Typography variant={hasAchieved() ? "caption" : "subtitle1"} fontWeight={500}>
                  {hasAchieved() ? formationName : t("challenge.numberOfTries")}
                </Typography>
                {hasAchieved() ? <ProgressBar smallSize value={formationProgress} max={100} /> : <Typography variant="body2" mt={2}>
                  {t("challenge.numberOfTriesMessage", { numberOfTries: numberOfTries })}
                </Typography>}
              </Grid>
              <Grid item md={4}>
                <Typography variant={hasAchieved() ? "caption" : "subtitle1"} fontWeight={500}>
                  {hasAchieved() ? skillTreeName : t("challenge.futureTries")}
                </Typography>
                {hasAchieved() ? <ProgressBar smallSize value={skillTreeProgress} max={100} /> : <Typography variant="body2" mt={2}>
                  {t("challenge.futureTriesMessage", { triesLeft: triesLeft, daysToNextAttempt: daysToNextAttempt })}
                </Typography>}
              </Grid>
              <Grid item md={4}>
                <Typography variant={hasAchieved() ? "caption" : "subtitle1"} fontWeight={500}>
                  {hasAchieved() ? t("challenge.yourPerformanceInSkill") : t("challenge.observation")}
                </Typography>
                {hasAchieved() ? <ProgressBar smallSize value={skillGrade} max={100} /> : <Typography variant="body2" mt={2}>
                  {t("challenge.observationMessage")}
                </Typography>}
              </Grid>
            </Grid>
            {!hasAchieved() && studyArticles.length > 0 && (
              <Grid item container columnSpacing={6} marginTop={5} xs={12}>
                <Grid item xs={12}>
                  <Divider />
                  <Typography
                    marginTop={2}
                    variant="h6"
                    fontWeight={500}
                    textAlign={"center"}
                  >
                    {t("challenge.studyResources")}
                  </Typography>
                  <Typography variant="subtitle2" textAlign={"center"}>
                    {t("challenge.studyResourcesMessage")}
                  </Typography>
                </Grid>
                <Grid display={"flex"} justifyContent={"center"} item container xs={12} mt={5}>
                  {studyArticles && studyArticles.map((studyArticle => (
                    <Grid item lg={5}>
                      <StudyArticleItem
                        article={studyArticle}
                      />
                    </Grid>
                  )))}
                </Grid>
              </Grid>
            )}
            <S.ContainerButton>
              <S.ButtonBack
                data-cy="challengeResponseBackButton"
                onClick={() => navigate(-2)}
              >
                {t("challenge.lastButton")}
              </S.ButtonBack>
            </S.ContainerButton>
          </Grid>
        </Collapse>
      </Grid>
    )
  );
}
