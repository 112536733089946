import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {Button, Checkbox, FormControlLabel} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, {ChangeEvent, useEffect, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as T from "../../../Global/Typography";
import BackButton from "../../../components/BackButton";
import {Button as ButtonDS} from "../../../components/Button";
import {ResponsiveInputText} from "../../../components/ResponsiveInputText";
import {Select} from "../../../components/Select";
import ChallengeService from "../../../services/ChallengeService";
import FileService from "../../../services/FileService";
import QuestionService from "../../../services/QuestionService";
import api from "../../../services/api";
import ImageIcon from "./assets/ImageIcon";
import SucessOutlined from "./assets/SuceessOutlined";
import UploadIcon from "./assets/UploadIcon";
import wrong from "./assets/wrong.png";
import * as S from "./styles";
import {enqueueSnackbar} from "notistack";
import {t} from "i18next";
import CheckboxMultiselect from "../../../components/Checkboxes";
import {options} from "../../../components/utils/languageOptions";

export interface IQuestions {
  id: number;
  content: string;
  challengeId: string;
  input: string;
  listingOrder: number;
  needsApproval: boolean;
  rightAnswer: string;
  options: { id: number; content: string }[];
}

export interface IOption {
  content: string;
  rightOption: boolean;
}

interface IChallenge {
  id: any;
  questions: IQuestion;
  skillId: any;
  title: string;
}

export interface IQuestion {
  id: number;
  content: string;
  challenge: IChallenge;
  input: string;
  listingOrder: number;
  needsApproval: boolean;
  rightAnswer: string;
  options: IOption[];
  languages: any[];
}

export interface IFormData {
  id: number;
  content: string;
  challenges: { id: number; value: string };
  input: { id: string; value: string };
  challengeId: any;
  listingOrder: number;
  needsApproval: boolean;
  rightAnswer: string;
  numberOfOptions: number;
  options: any[];
}

interface IDataDropDowns {
  id: number | string;
  value: string;
}

interface IQuestionsForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: IQuestions | undefined;
}

const InputTypes = {
  FILE: "FILE",
  TEXTAREA: "TEXTAREA",
  CHECKBOX: "CHECKBOX",
  NUMERIC: "NUMERIC",
  RADIO: "RADIO",
};

const questionInputTypeDropdown = Object.entries(InputTypes).map((entry) => {
  return {id: entry[0], value: entry[1]};
});

const hasUnsavedFiles = (imagesArray: Object[]) => {
  let hasUnsavedFiles = false;
  imagesArray.forEach((image) => {
    if (image !== null && typeof image !== "undefined") {
      if (isUnsavedFile(image)) {
        hasUnsavedFiles = true;
      }
    }
  });

  return hasUnsavedFiles;
};

const isUnsavedFile = (file: Object) => {
  return "id" in file === false;
};

const filterUnsavedFiles = (imagesArray) => {
  return imagesArray
  .filter((image) => image !== null && typeof image !== "undefined")
  .filter((image) => {
    return isUnsavedFile(image);
  });
};

export default function StudyArticlesForm({
                                            onBackPress,
                                            feedbackAction,
                                            dataToEdit,
                                          }: IQuestionsForm) {
  const errors = {
    challengeSelected: {
      min: t("questions.selectSkill"),
    },
    typeOfInputSelected: {
      min: t("questions.oneOption"),
    },
    minTime: {
      min: t("challenge.minTime")
    },
    content: {
      min: t("questions.contentLengthErrorMessage"),
    },
    numberOfOptions: {
      select: t("questions.selectMin3"),
      radio: t("questions.radioMin2"),
    },
    numeric: t("questions.addNumberMessage"),
    rightOption: t("questions.addCorrectAlternativeMessage"),
    optionContent: t("questions.leastSomeData"),
    equalOptions: t("questions.cantHaveTwoEqual"),
  };

  const isEditing = typeof dataToEdit !== "undefined";
  const [originalData, setOriginalData] = useState<IQuestion>({} as IQuestion);
  const [challengesDataToDropdown, setChallengesDataToDropdown] = useState<
    IChallenge[]
  >([]);

  const [searchDropdown, setSearchDropdown] = useState("");
  const [content, setContent] = useState<string>("");
  const [challengeSelected, setChallengeSelected] = useState<IDataDropDowns>();
  const [typeOfInputSelected, setTypeOfInputSelected] =
    useState<IDataDropDowns>();
  const [optionsCount, setOptionsCount] = useState<number>(1);
  const [questionOptions, setQuestionOptions] = useState<any>([]);
  const [rightAnswerNumeric, setRightAnswerNumeric] = useState("");
  const [selectedNeedsApproval, setSelectedNeedsApproval] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [images, setImages] = useState<File[]>([]);
  const [imagesNames, setImagesNames] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newOptions, setNewOptions] = useState(options);
  const [days, setDays] = useState("0");
  const [hours, setHours] = useState("0");
  const [minutes, setMinutes] = useState("0");
  const [averageTime, setAverageTime] = useState(0);
  const [localLanguage, setLocalLanguage] = useState(localStorage.getItem("language"));
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const filterRightOptionInQuestionOptionsToValidateThePOSTandPUT =
    typeOfInputSelected?.id != "NUMERIC" &&
    typeOfInputSelected?.id != "FILE" &&
    typeOfInputSelected?.id != "TEXTAREA" &&
    questionOptions.filter((item) => item.rightOption === true);

  const filterImageOrContentOption = questionOptions.filter(
    (item) => item.image != null && item.content != null
  );

  const rules = {
    challengeSelected: {
      min: challengeSelected === undefined,
    },
    typeOfInputSelected: {
      min: typeOfInputSelected == null,
    },
    minTime: {
      min: averageTime === 0
    },
    content: {
      min: content.length <= 30,
    },
    numberOfOptions: {
      removeItem: optionsCount > 1,
      select:
        validateToggleTypeOfQuestion() &&
        typeOfInputSelected?.id !== "RADIO" &&
        optionsCount < 4,
      radio: typeOfInputSelected?.id === "RADIO" && optionsCount < 3,
    },
    numeric: rightAnswerNumeric === "",
    rightOption:
      filterRightOptionInQuestionOptionsToValidateThePOSTandPUT.length === 0,
    optionContent: questionOptions.some((opt, index) => {
      const hasImage =
        typeof images[index] !== "undefined" && images[index] !== null;
      return !hasImage && opt?.content?.length === 0;
    }),
    equalOptions: questionOptions.some(
      (opt) =>
        questionOptions?.filter((i) =>
          opt?.content?.length > 0 ? i?.content == opt?.content : false
        ).length > 1
    ),
  };

  const fetchDataById = async () => {
    const result = await api.get(`questions/${dataToEdit.id}`);

    setOriginalData(result.data);
    setTypeOfInputSelected({value: result.data.input, id: result.data.input});
    setContent(result.data.content);
    setChallengeSelected({
      value: result.data.challenge.title,
      id: result.data.challenge.id,
    });
    setQuestionOptions(result.data?.options);
    setImages(result.data?.options?.map((opt) => opt?.image));
    setImagesNames(
      result.data?.options?.map((opt) => opt?.image?.fileName.split("-").pop())
    );
    setOptionsCount(result.data.options.length + 1);
    setRightAnswerNumeric(result.data.rightAnswer);
    const newOptionsLanguage = newOptions.filter(
      option => result?.data?.languages?.includes(option?.value) && option?.value !== localLanguage
    );
    setSelectedLanguages(newOptionsLanguage)
    convertMinutes(result?.data?.averageTime)
  };

  const fetchDataChallenges = async () => {
    const result = await ChallengeService.getChallengeToDropdown(
      searchDropdown
    );

    setChallengesDataToDropdown(result);
  };

  function validateToggleTypeOfQuestion() {
    switch (typeOfInputSelected?.id) {
      case "CHECKBOX":
        return true;
      case "RADIO":
        return true;
      case "TEXTAREA":
        return false;
      case "FILE":
        return false;
      case "NUMERIC":
        return false;
    }
  }

  function convertMinutes(totalMinutes: number) {
    const days = Math.floor(totalMinutes / 1440);
    const hours = Math.floor((totalMinutes % 1440) / 60);
    const minutes = totalMinutes % 60;

    setDays(days.toString());
    setHours(hours.toString());
    setMinutes(minutes.toString());

    return;
  }

  useEffect(() => {
    fetchDataChallenges();
    setNewOptions(options.filter((language) => language.value !== (localLanguage || 'pt')));
  }, []);

  useEffect(() => {
    setLocalLanguage(localStorage.getItem("language"))
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    fetchDataChallenges();
  }, [searchDropdown]);

  useEffect(() => {
    if (dataToEdit?.id) {
      fetchDataById();
    }
  }, [isEditing]);

  useEffect(() => {
    validateToggleTypeOfQuestion();
  }, [typeOfInputSelected]);

  useEffect(() => {
    let totalMinutes = 0;

    if (days && +days >= 0) {
      const numericDays = +days;
      totalMinutes += numericDays * 24 * 60;
    }

    if (hours && +hours >= 0) {
      const numericHours = +hours;
      totalMinutes += numericHours * 60;
    }

    if (minutes && +minutes >= 0) {
      const numericMinutes = +minutes;
      totalMinutes += numericMinutes;
    }

    setAverageTime(totalMinutes);
  }, [days, hours, minutes]);

  const handleAddClick = () => {
    if (optionsCount <= 5) {
      setOptionsCount(optionsCount + 1);
      questionOptions.push({
        content: "",
        rightOption: false,
      });
      setQuestionOptions(questionOptions);
    }
  };

  const handleRemoveClick = () => {
    if (optionsCount === 1) {
      return;
    }
    delete images[optionsCount - 2];
    delete imagesNames[optionsCount - 2];
    setOptionsCount(optionsCount - 1);
    questionOptions.pop();
    setQuestionOptions(questionOptions);
  };
  let questions = questionOptions.map((option) => option.content);
  const handleChangeOptionContent = (value: string, index: number) => {
    const newQuestionOptions = [...questionOptions];
    newQuestionOptions[index].content = value;
    setQuestionOptions(newQuestionOptions);
  };

  const handleChangeOptionCorrectTypeSelect = (index: number) => {
    setQuestionOptions(
      questionOptions.map((option, i) =>
        i === index
          ? {...option, rightOption: !questionOptions[index].rightOption}
          : option
      )
    );
  };

  const handleChangeOptionCorrectInInputTypeRadio = (index: number) => {
    setQuestionOptions(
      questionOptions
      .map((option) => {
        return {...option, rightOption: false};
      })
      .map((option, i) =>
        i === index ? {...option, rightOption: true} : option
      )
    );
  };

  function handleSetRightAwnserNumeric(event: ChangeEvent<HTMLInputElement>) {
    setRightAnswerNumeric(event.target.value);
  }

  function handleSetDays(event: ChangeEvent<HTMLInputElement>) {
    setDays(event.target.value);
  }

  function handleSetHours(event: ChangeEvent<HTMLInputElement>) {
    setHours(event.target.value);
  }

  function handleSetMinutes(event: ChangeEvent<HTMLInputElement>) {
    setMinutes(event.target.value);
  }

  function appendImages(files: FileList | null, index: number) {
    if (files) {
      const fileRef = files[0];

      let newImagesNames = [...imagesNames];
      let newImages = [...images];
      newImagesNames[index] = fileRef.name;
      newImages[index] = fileRef;

      setImagesNames(newImagesNames);
      setImages(newImages);
    }
    files = null;
  }

  function removeImages(index: number) {
    let newImagesNames = [...imagesNames];
    let newImages = [...images];

    newImagesNames[index] = undefined;
    newImages[index] = undefined;

    setImagesNames(newImagesNames);
    setImages(newImages);
  }

  const handleCreateNewQuestion = async (event) => {
    let newLanguages = selectedLanguages.map((language) => language.value)
    setIsLoading(true);
    event.preventDefault();
    setFormSubmitted(true);
    if (typeOfInputSelected?.id === ("TEXTAREA" || "FILE")) {
      setSelectedNeedsApproval(true);
    } else {
      setSelectedNeedsApproval(false);
    }

    try {
      const challengeId = challengeSelected.id;
      const input = typeOfInputSelected?.id;
      let options = questionOptions;
      const needsApproval = selectedNeedsApproval;
      const rightAnswer = rightAnswerNumeric;
      const time = averageTime;
      const listingOrder = 0;

      if (input !== "RADIO" && input !== "CHECKBOX") {
        options = [];
      }

      let createQuestionDTO;
      let result;

      if (
        isEditing &&
        typeOfInputSelected?.id === "NUMERIC" &&
        content.length >= 30 &&
        rightAnswer !== ""
      ) {
        const updateQuestionRequest = {
          ...originalData,
          listingOrder,
          content,
          input,
          challengeId,
          options,
          rightAnswer,
          needsApproval,
          languages: newLanguages,
        };
        result = await QuestionService.editQuestion(
          dataToEdit.id,
          updateQuestionRequest
        );
        feedbackAction("success", result, "edit");
        enqueueSnackbar(t("questions.questionEdited"), {variant: "success"});
      }

      // #####################
      // #
      // EDITANDO UMA PERGUNTA DO TIPO CHECKBOX
      // #
      // ######################
      if (
        isEditing &&
        typeOfInputSelected?.id === "CHECKBOX" &&
        optionsCount >= 4 &&
        content.length >= 30 &&
        filterRightOptionInQuestionOptionsToValidateThePOSTandPUT.length !==
        0 &&
        !rules.equalOptions &&
        !rules.optionContent
      ) {
        let newoptions;

        if (images.length > 0) {
          if (hasUnsavedFiles(images)) {
            const unsavedFiles = filterUnsavedFiles(images);
            const newSavedFilesResponse: any = await FileService.imagesUpload(
              unsavedFiles
            );

            const newSavedFilesIds = newSavedFilesResponse.data;

            let unsavedFilesCounter = 0;

            const mashImages: any = images.map((image, index) => {
              if (image !== null && typeof image !== "undefined") {
                if (isUnsavedFile(image)) {
                  const mashedImage = {
                    id: newSavedFilesIds[unsavedFilesCounter],
                  };

                  unsavedFilesCounter += 1;

                  return mashedImage;
                }
              }

              return image;
            });

            newoptions = options.map((opt, i) => {
              return {
                ...opt,
                imageId: mashImages[i]?.id,
              };
            });
          } else {
            newoptions = options.map((opt, i) => {
              return {
                ...opt,
                imageId:
                  images[i] !== null && typeof images[i] !== "undefined"
                    ? opt.image?.id
                    : null,
              };
            });
          }
        } else {
          newoptions = options;
        }

        const updateQuestionRequest = {
          ...originalData,
          listingOrder,
          content,
          input,
          challengeId,
          options: newoptions,
          rightAnswer,
          needsApproval,
          languages: newLanguages,
        };

        result = await QuestionService.editQuestion(
          dataToEdit.id,
          updateQuestionRequest
        );
        feedbackAction("success", result, "edit");
        enqueueSnackbar(t("questions.questionEdited"), {variant: "success"});
      }
      // #####################
      // #
      // EDITANDO UMA PERGUNTA DO TIPO RÁDIO
      // #
      // ######################
      if (
        isEditing &&
        typeOfInputSelected?.id === "RADIO" &&
        optionsCount >= 3 &&
        content.length >= 30 &&
        filterRightOptionInQuestionOptionsToValidateThePOSTandPUT.length !==
        0 &&
        !rules.equalOptions &&
        !rules.optionContent
      ) {
        let newoptions;

        if (images.length > 0) {
          if (hasUnsavedFiles(images)) {
            const unsavedFiles = filterUnsavedFiles(images);
            const newSavedFilesResponse: any = await FileService.imagesUpload(
              unsavedFiles
            );

            const newSavedFilesIds = newSavedFilesResponse.data;

            let unsavedFilesCounter = 0;

            const mashImages: any = images.map((image, index) => {
              if (image !== null && typeof image !== "undefined") {
                if (isUnsavedFile(image)) {
                  const mashedImage = {
                    id: newSavedFilesIds[unsavedFilesCounter],
                  };

                  unsavedFilesCounter += 1;

                  return mashedImage;
                }
              }

              return image;
            });

            newoptions = options.map((opt, i) => {
              return {
                ...opt,
                imageId: mashImages[i]?.id,
              };
            });
          } else {
            newoptions = options.map((opt, i) => {
              return {
                ...opt,
                imageId:
                  images[i] !== null && typeof images[i] !== "undefined"
                    ? opt.image?.id
                    : null,
              };
            });
          }
        } else {
          newoptions = options;
        }

        const updateQuestionRequest = {
          ...originalData,
          listingOrder,
          content,
          input,
          challengeId,
          options: newoptions,
          rightAnswer,
          needsApproval,
          languages: newLanguages,
        };

        result = await QuestionService.editQuestion(
          dataToEdit.id,
          updateQuestionRequest
        );
        feedbackAction("success", result, "edit");
        enqueueSnackbar(t("questions.questionEdited"), {variant: "success"});
      }

      if (
        isEditing &&
        typeOfInputSelected?.id === "FILE" &&
        content.length >= 30
      ) {
        const updateQuestionRequest = {
          ...originalData,
          listingOrder,
          content,
          input,
          challengeId,
          options,
          rightAnswer,
          needsApproval,
          languages: newLanguages,
          averageTime: time,
        };
        result = await QuestionService.editQuestion(
          dataToEdit.id,
          updateQuestionRequest
        );
        feedbackAction("success", result, "edit");
        enqueueSnackbar(t("questions.questionEdited"), {variant: "success"});
      }

      if (
        isEditing &&
        typeOfInputSelected?.id === "TEXTAREA" &&
        content.length >= 30
      ) {
        const updateQuestionRequest = {
          ...originalData,
          listingOrder,
          content,
          input,
          challengeId,
          options,
          rightAnswer,
          needsApproval,
          languages: newLanguages,
          averageTime: time,
        };
        result = await QuestionService.editQuestion(
          dataToEdit.id,
          updateQuestionRequest
        );
        feedbackAction("success", result, "edit");
        enqueueSnackbar(t("questions.questionEdited"), {variant: "success"});
      }

      if (
        !isEditing &&
        typeOfInputSelected?.id === "NUMERIC" &&
        !rules.challengeSelected.min &&
        !rules.typeOfInputSelected.min &&
        content.length >= 30 &&
        rightAnswer !== ""
      ) {
        createQuestionDTO = {
          input,
          challengeId,
          options,
          rightAnswer,
          needsApproval,
          content,
          listingOrder,
          languages: newLanguages,
        };
        result = await QuestionService.addQuestion(createQuestionDTO);
        feedbackAction("success", result.data, "add");
        enqueueSnackbar(t("questions.questionCreated"), {variant: "success"});
      }
      // #####################
      // #
      // CRIANDO UMA PERGUNTA DO TIPO CHECKBOX
      // #
      // ######################
      if (
        !isEditing &&
        typeOfInputSelected?.id === "CHECKBOX" &&
        optionsCount >= 4 &&
        content.length >= 30 &&
        !rules.challengeSelected.min &&
        !rules.typeOfInputSelected.min &&
        filterRightOptionInQuestionOptionsToValidateThePOSTandPUT.length !==
        0 &&
        !rules.equalOptions &&
        !rules.optionContent
      ) {
        createQuestionDTO = {
          input,
          challengeId,
          options,
          needsApproval,
          content,
          listingOrder,
          languages: newLanguages,
        };

        if (images.length > 0) {
          let resultImages: any = await FileService.imagesUpload(images);
          let savedImageCounter = 0;
          options = options.map((opt, i) => {
            const optionHasSavedImage = typeof images[i] !== "undefined";
            if (optionHasSavedImage) {
              savedImageCounter += 1;
            }

            return {
              ...opt,
              imageId: !optionHasSavedImage
                ? null
                : resultImages?.data?.[savedImageCounter - 1],
            };
          });
        }

        createQuestionDTO = {...createQuestionDTO, options};
        result = await QuestionService.addQuestion(createQuestionDTO);
        feedbackAction("success", result.data, "add");
        enqueueSnackbar(t("questions.questionCreated"), {variant: "success"});
      }
      // #####################
      // #
      // CRIANDO UMA PERGUNTA DO TIPO RÁDIO
      // #
      // ######################
      if (
        !isEditing &&
        typeOfInputSelected?.id === "RADIO" &&
        !(optionsCount < 3) &&
        content.length >= 30 &&
        !rules.challengeSelected.min &&
        !rules.typeOfInputSelected.min &&
        filterRightOptionInQuestionOptionsToValidateThePOSTandPUT.length !==
        0 &&
        !rules.equalOptions &&
        !rules.optionContent
      ) {
        createQuestionDTO = {
          input,
          challengeId,
          options,
          needsApproval,
          content,
          listingOrder,
          languages: newLanguages,
        };

        if (images.length > 0) {
          let resultImages: any = await FileService.imagesUpload(images);
          let savedImageCounter = 0;
          options = options.map((opt, i) => {
            const optionHasSavedImage = typeof images[i] !== "undefined";
            if (optionHasSavedImage) {
              savedImageCounter += 1;
            }

            return {
              ...opt,
              imageId: !optionHasSavedImage
                ? null
                : resultImages?.data?.[savedImageCounter - 1],
            };
          });
        }
        createQuestionDTO = {...createQuestionDTO, options};
        result = await QuestionService.addQuestion(createQuestionDTO);
        feedbackAction("success", result.data, "add");
        enqueueSnackbar(t("questions.questionCreated"), {variant: "success"});
      }

      if (
        !isEditing &&
        typeOfInputSelected?.id === "FILE" &&
        content.length >= 30 &&
        !rules.challengeSelected.min &&
        !rules.typeOfInputSelected.min &&
        !rules.minTime.min
      ) {
        createQuestionDTO = {
          input,
          challengeId,
          options,
          needsApproval,
          content,
          listingOrder,
          languages: newLanguages,
          averageTime: time,
        };

        result = await QuestionService.addQuestion(createQuestionDTO);
        feedbackAction("success", result.data, "add");
        enqueueSnackbar(t("questions.questionCreated"), {variant: "success"});
      }

      if (
        !isEditing &&
        typeOfInputSelected?.id === "TEXTAREA" &&
        content.length >= 30 &&
        !rules.challengeSelected.min &&
        !rules.typeOfInputSelected.min &&
        !rules.minTime.min
      ) {
        createQuestionDTO = {
          input,
          challengeId,
          options,
          needsApproval,
          content,
          listingOrder,
          languages: newLanguages,
          averageTime: time,
        };

        result = await QuestionService.addQuestion(createQuestionDTO);
        feedbackAction("success", result.data, "add");
        enqueueSnackbar(t("questions.questionCreated"), {variant: "success"});
      }

      setIsLoading(false);
    } catch (error: any) {
      if (error.response.status === 400) {
        feedbackAction(
          "error",
          null,
          "add",
          error.response.data.message || error.response.data.detail
        );
      } else {
        feedbackAction(
          "error",
          null,
          "add",
          t("questions.feedbackActionTextError")
        );
      }
      setIsLoading(false);
      console.error(error);
    }
  };

  const modules = {
    toolbar: [
      [{font: []}],
      [{header: [1, 2, 3, 4, 5, 6, false]}],
      ["bold", "italic", "underline", "strike"],
      [{color: []}, {background: []}],
      [{script: "sub"}, {script: "super"}],
      ["blockquote", "code-block"],
      [{list: "ordered"}, {list: "bullet"}],
      [{indent: "-1"}, {indent: "+1"}, {align: []}],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <S.ContainerFormQuestions>
      <BackButton style={{marginTop: 40}} onClick={() => onBackPress()}>
        Voltar
      </BackButton>

      <form onSubmit={handleCreateNewQuestion}>
        <S.ContainerDropdown data-cy="challengeDropdownQuestionForm">
          <Select
            data-cy="challengeDropdownQuestionForm"
            showSearch
            selected={challengeSelected}
            onSelect={setChallengeSelected}
            placeholder={t("questions.skill")}
            label={t("questions.skill")}
            options={challengesDataToDropdown}
            error={
              rules?.challengeSelected?.min && errors?.challengeSelected?.min
            }
          />
        </S.ContainerDropdown>
        <S.ContainerInput>
          <ReactQuill
            modules={modules}
            theme="snow"
            onChange={(value) => setContent(value)}
            placeholder={t("questions.content")}
            value={content}
          />
          {rules?.content?.min && (
            <S.ErrorMessage data-cy="questionFormErrorMessage">
              {errors?.content?.min}
            </S.ErrorMessage>
          )}
        </S.ContainerInput>
        <S.ContainerDropdown data-cy="typeOfInputQuestionForm">
          <Select
            data-cy="typeOfInputQuestionForm"
            selected={typeOfInputSelected}
            onSelect={(e) => setTypeOfInputSelected(e)}
            placeholder={t("questions.inputType")}
            label={t("questions.inputType")}
            options={questionInputTypeDropdown}
            error={
              rules.typeOfInputSelected.min
                ? errors?.typeOfInputSelected?.min
                : ""
            }
          />
        </S.ContainerDropdown>
        <S.ContainerDropdown>
          {selectedLanguages?.length > 0 ?
            <CheckboxMultiselect options={newOptions} label={t("languages")}
                                 onChange={setSelectedLanguages} value={selectedLanguages}/>
            : <CheckboxMultiselect options={newOptions} label={t("languages")}
                                   onChange={setSelectedLanguages} value={[]}/>}
        </S.ContainerDropdown>
        {validateToggleTypeOfQuestion() && (
          <S.ContainerOfTypeOfQuestions>
            <T.Heading4>{t("questions.options")}</T.Heading4>
            <S.ErrorMessage data-cy="questionFormOptionsErrorMessage">
              {rules.numberOfOptions.select
                ? errors?.numberOfOptions.select
                : rules.numberOfOptions.radio
                  ? errors?.numberOfOptions.radio
                  : rules.rightOption
                    ? errors?.rightOption
                    : rules.optionContent
                      ? errors?.optionContent
                      : rules.equalOptions
                        ? errors?.equalOptions
                        : ""}
            </S.ErrorMessage>
            <S.ContainerButtonsAddAndRemove>
              <Button
                data-cy="questionFormAddOptionButton"
                className="addButton"
                onClick={handleAddClick}
                variant="contained"
              >
                <AddCircle/>
              </Button>
              {rules.numberOfOptions.removeItem && (
                <Button
                  className="removeButton"
                  onClick={handleRemoveClick}
                  variant="contained"
                >
                  <RemoveCircle/>
                </Button>
              )}
            </S.ContainerButtonsAddAndRemove>
            {questionOptions &&
              optionsCount > 1 &&
              (typeOfInputSelected?.id === "CHECKBOX" ||
                typeOfInputSelected?.id === "RADIO") && (
                <S.UploadImageText>
                  {t("questions.selectOptionImagesMessage")}
                  <br/> {t("questions.imageSizeLimitMessage")}
                </S.UploadImageText>
              )}
            <div>
              {questionOptions &&
                questionOptions.map((questionOption, indexOption) => {
                  return (
                    <S.ContainerOptions>
                      <S.ButtonImageAndFileSection>
                        <S.UploadImageButton>
                          <S.UploadInput
                            data-cy="questionFormImageUpload"
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                              appendImages(e.target.files, indexOption);
                              e.target.value = null;
                            }}
                            id="enviar-arquivo"
                          />
                          <UploadIcon className="uploadIcon"/>
                          <S.UploadButtonText>
                            {t("questions.uploadFile")}
                          </S.UploadButtonText>
                        </S.UploadImageButton>
                        {images[indexOption] && (
                          <div>
                            <S.ImageSelected>
                              <ImageIcon className="imageIcon"/>
                              <p className="fileName">
                                {imagesNames?.[indexOption]}
                              </p>
                              <SucessOutlined className="success"/>
                            </S.ImageSelected>
                            <S.ContainerRemoveImage
                              onClick={() => {
                                removeImages(indexOption);
                              }}
                            >
                              <S.IconRemoveImage
                                src={wrong}
                                alt={t("questions.deleteImage")}
                              />
                              <S.removeImage>
                                {t("questions.deleteImage")}
                              </S.removeImage>
                            </S.ContainerRemoveImage>
                          </div>
                        )}
                      </S.ButtonImageAndFileSection>

                      <S.InputAndCheckboxSection>
                        <ResponsiveInputText
                          data-cy="questionFormOptionInput"
                          aria-label="empty textarea"
                          defaultValue={questionOption.content}
                          placeholder={t("questions.writeOptionContent")}
                          onChange={(event) =>
                            handleChangeOptionContent(
                              event.target.value,
                              indexOption
                            )
                          }
                          className="inputQuestionContent"
                        />
                        {typeOfInputSelected?.id !== "RADIO" && (
                          <S.ContainerCheckbox className="containerChebox">
                            <FormControlLabel
                              data-cy="questionFormCorrectOptionCheckbox"
                              className="checkboxInputControl"
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#8c8c8c",
                                    "&.Mui-checked": {
                                      color: "#FF026F",
                                    },
                                  }}
                                  defaultChecked={questionOption.rightOption}
                                />
                              }
                              onChange={() => {
                                handleChangeOptionCorrectTypeSelect(
                                  indexOption
                                );
                              }}
                              label={t("questions.correctAnswer")}
                            />
                          </S.ContainerCheckbox>
                        )}
                        {typeOfInputSelected?.id === "RADIO" && (
                          <S.ContainerRadio>
                            <S.Checkbox
                              data-cy="questionFormCorrectOptionRadio"
                              name="Resposta correta"
                              type="radio"
                              defaultChecked={questionOption?.rightOption}
                              value={questionOption?.rightOption}
                              onChange={() => {
                                handleChangeOptionCorrectInInputTypeRadio(
                                  indexOption
                                );
                              }}
                            />
                            <S.LabelCheckbox>
                              {t("questions.correctAnswer")}
                            </S.LabelCheckbox>
                          </S.ContainerRadio>
                        )}
                      </S.InputAndCheckboxSection>
                    </S.ContainerOptions>
                  );
                })}
            </div>
          </S.ContainerOfTypeOfQuestions>
        )}
        {typeOfInputSelected?.id === "NUMERIC" && (
          <S.ContainerNumericOption>
            <S.InputTypeNumber
              data-cy="questionsFormNumericInput"
              type="number"
              placeholder={t("questions.numericalField")}
              borderColor={rules.numeric ? "#F5222D" : "#8C8C8C"}
              onChange={(event) => handleSetRightAwnserNumeric(event)}
              value={rightAnswerNumeric}
            />
            <S.ErrorMessage data-cy="questionFormNumericErrorMessage">
              {rules.numeric ? errors.numeric : ""}
            </S.ErrorMessage>
          </S.ContainerNumericOption>
        )}

        {(typeOfInputSelected?.id === "TEXTAREA"
          || typeOfInputSelected?.id === "FILE") && (
          <S.ContainerAverageTime>
            <h4>{t("questions.defineAverageTime")}</h4>
            <S.InputRow rowsSize={[1, 1, 1]}>
              <S.ContainerTimeSetting>
                <S.Label>{t("questions.days")}</S.Label>
                <S.InputTypeNumberTime
                  type="number"
                  placeholder={t("questions.days")}
                  borderColor={"#8C8C8C"}
                  onChange={(event) => handleSetDays(event)}
                  value={days}
                  min={"0"}
                />
              </S.ContainerTimeSetting>
              <S.ContainerTimeSetting>
                <S.Label>{t("questions.hours")}</S.Label>
                <S.InputTypeNumberTime
                  type="number"
                  placeholder={t("questions.hours")}
                  borderColor={"#8C8C8C"}
                  onChange={(event) => handleSetHours(event)}
                  value={hours}
                  min={"0"}
                />
              </S.ContainerTimeSetting>
              <S.ContainerTimeSetting>
                <S.Label>{t("questions.minutes")}</S.Label>
                <S.InputTypeNumberTime
                  type="number"
                  placeholder={t("questions.minutes")}
                  borderColor={"#8C8C8C"}
                  onChange={(event) => handleSetMinutes(event)}
                  value={minutes}
                  min={"0"}
                />
              </S.ContainerTimeSetting>
            </S.InputRow>
            <S.ErrorMessage>
              {rules.minTime.min
                ? errors?.minTime.min
                : ""}
            </S.ErrorMessage>
          </S.ContainerAverageTime>
        )}

        <Grid marginTop={2} container spacing={2}>
          <Grid item xs={10} sm={10} md={4} lg={3} xl={3}>
            <ButtonDS
              type="submit"
              loading={isLoading}
              data-cy="submitQuestionForm"
              disable={
                ((typeOfInputSelected?.id == "TEXTAREA" ||
                  typeOfInputSelected?.id == "FILE") && rules.minTime.min) ||
                rules.challengeSelected.min ||
                rules.content.min ||
                typeOfInputSelected == null ||
                (rules.equalOptions &&
                  (typeOfInputSelected?.id == "RADIO" ||
                    typeOfInputSelected?.id == "CHECKBOX")) ||
                (rules.numberOfOptions.radio &&
                  typeOfInputSelected?.id == "RADIO") ||
                (rules.numberOfOptions.select &&
                  typeOfInputSelected?.id == "CHECKBOX") ||
                (rules.optionContent &&
                  (typeOfInputSelected?.id == "RADIO" ||
                    typeOfInputSelected?.id == "CHECKBOX")) ||
                (rules.rightOption && typeOfInputSelected?.id == "RADIO") ||
                (rules.typeOfInputSelected.min &&
                  typeOfInputSelected?.id == "CHECKBOX")
              }
            >
              {t("questions.confirmButton")}
            </ButtonDS>
          </Grid>
        </Grid>
      </form>
    </S.ContainerFormQuestions>
  );
}
