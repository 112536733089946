import moment from 'moment';
import { t } from "i18next";

export function noAccents(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function cnpjMask(value) {
  return value
  .replace(/\D+/g, "")
  .replace(/(\d{2})(\d)/, "$1.$2")
  .replace(/(\d{3})(\d)/, "$1.$2")
  .replace(/(\d{3})(\d)/, "$1/$2")
  .replace(/(\d{4})(\d)/, "$1-$2")
  .replace(/(-\d{2})\d+?$/, "$1");
}

export function cuitMask(value) {
  return value
    .replace(/\D+/g, "")
    .replace(/^(\d{2})(\d)/, "$1-$2")
    .replace(/^(\d{2})-(\d{8})(\d)/, "$1-$2-$3")
    .replace(/^(\d{2})-(\d{8})-(\d{1})/, "$1-$2-$3");
}

export function convertToLocalDate(dataString) {
  //Cria um objeto Date a partir da string, identificando conforme os formatos específicados
  //Subtrai o fuso horário de acordo com o padrão usado localmente
  //Formata a data para o padrão dia/mês/ano - hora:minuto
  const formattedDate = moment(dataString, ['YYYY/MM/DD HH:mm:ss', 'MM/DD/YYYY HH:mm:ss']).subtract(new Date().getTimezoneOffset(), 'minutes').format(t("dateFormat"))

  // Retorna a string formatada
  return formattedDate;

}

export function minutesToHours(minutes, type?: 'minutes' | 'hours') {
  let horas = (minutes / 60).toString();
  let newMinutes = ((0 + +horas.toString().substr(1)) * 60).toFixed(0)
  horas = Math.floor(+horas) > 9 ? Math.floor(+horas).toString() : Math.floor(+horas).toString()
  newMinutes = +newMinutes > 9 ? newMinutes : newMinutes.padStart(2, '0')

  if (type === "minutes") {
    return newMinutes
  } else if (type === 'hours') {
    return horas
  }

  return `${horas}:${newMinutes}`
}
