import {
  Box,
  LinearProgress,
  styled as MUIStyled,
  Switch,
  alpha,
  linearProgressClasses,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import styled from "styled-components";
import { colors } from "../../../components/utils/colors";

interface IPropsTextArea {
  borderColor: any;
}

export const ContainerProgressBar = MUIStyled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
  marginTop: "20px",
});

export const PinkSwitch = MUIStyled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

export const BorderLinearProgress = MUIStyled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ff026f" : "#308fe8",
  },
}));

export const Label = styled.p`
  margin: 0.3rem 0;
  font-size: 14px;
  color: #8c8c8c;
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  outline: none !important;
  width: 100%;
  height: 150px;
  padding: 14px 16px;
  background: white;
  border: 1px solid
    ${({ borderColor }) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;

export const ErrorText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #f5222d;
  margin: 3px 0;
`;

export const ContainerPlansForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CheckboxOutCircle = styled.div<{
  selected: boolean;
  isTable?: boolean;
}>`
  position: relative;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  background: ${({ selected, isTable }) =>
    selected
      ? "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box"
      : `linear-gradient(#FFE7E1, #FFE7E1) padding-box, linear-gradient(90deg, #696969 0%, #696969 100%) border-box`};
  border-radius: 2px;
  border: 2px solid transparent;
`;
export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;

export const UpdatedAt = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  span#date {
    color: #8c8c8c;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0;
  gap: 6px;
`;

export const CheckboxText = styled.p`
  font-weight: 400;
  font-size: 14px;
`;

export const ListButtonContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-direction: row;
`;

export const ButtonContainer = styled.div`
  width: 329px;
  max-width: 100%;
`;
export const ContainerFullTree = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  flex-direction: column;
  border: 1px solid ${colors.darkGray300};
  border-radius: 3px;
`;

export const ContainerAba = styled.div<any>`
  border: none;
  outline: none !important;
  cursor: default;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #ff6423;
  padding: 1rem 2rem;
  border-bottom: none;
`;

export const AbaImageTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const AbaImageContainer = styled.div`
  width: 47px;
  height: 47px;
  border-radius: 47px;
  background-color: #fff;
  position: relative;
`;

export const AbaImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AbaTitleSubtitle = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.125rem;
`;

export const AbaTitle = styled.p`
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const WithoutSkills = styled.p`
  color: #131212;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const AbaSubtitle = styled.p`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const ImageSeta = styled.img<any>`
  transform: ${(props) => (props.up ? "" : "rotate(180deg)")};
`;

export const ContainerAccordions = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const AccordionsLabelDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`;

export const ContainerAccordionSummary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 6px 0;

  @media screen and (max-width: 767px) {
    height: 50px;
  }
`;

export const InputRow = styled.div<{ rowsSize: number[] }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ rowsSize }) =>
    rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 16px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const ContainerAccordionSummaryLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;

  @media screen and (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const AccordionSummaryCircleImg = styled.div<{ color?: string }>`
  width: 36px;
  height: 36px;
  background-color: ${({ color }) => color};
  border-radius: 999px;
  position: relative;
`;

export const AccordionSummaryImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
`;

export const AccordionSummarySetaImg = styled.img<{ up?: boolean }>`
  width: 20px;
  margin-right: 1%;
  transform: ${({ up }) => (up ? "" : "rotate(180deg)")};
`;

export const ContainerAccordionSummaryLeftTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleAccordionSummary = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #131212;
`;

export const LoadingText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #131212;
`;

export const SubtitleAccordionSummary = styled.p<{ color?: string }>`
  font-size: 16px;
  font-weight: 500;
  color: ${({ color }) => color};
`;

export const ContainerAccordionDetails = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 302px;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 0;
  background: linear-gradient(
      180deg,
      rgba(255, 93, 40, 0.18) 48.93%,
      rgba(255, 0, 80, 0.18) 100%
    ),
    url(.jpg);
`;

export const ContainerAccordionDetailsLoading = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px 16px 16px;
  gap: 12px;
  background: #fff0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerInput = styled.div<IPropsTextArea>`
  resize: none;
  outline: none !important;
  width: 100%;
  margin-bottom: 10px;
  background: white;
  border: 1px solid
    ${({ borderColor }) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #131313;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;
