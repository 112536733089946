import api from "./api";

interface IHelpContactMessage {
  subject: string,
  message: string
}

class HelpContactService {

  async sendMessage(data: IHelpContactMessage) {
    await api.post(`help/`, data);
  }
}

export default new HelpContactService();