import {useEffect, useReducer, useState} from "react";
import {useNavigate} from "react-router-dom";

import UserStatusFormation from "../../types/enums/UserStatusFormation";

import {InfoOutlined, ModeEdit} from "@mui/icons-material";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  Alert,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton, ListItemIcon, ListItemText, Menu, MenuList,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {Button as ButtonComponent} from '../../components/Button'
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import {t} from "i18next";
import {enqueueSnackbar} from "notistack";
import {ArrowsDownUp, SortAscending, SortDescending, X} from "phosphor-react";

import BackButton from "../../components/BackButton";
import {BlackListModal} from "../../components/BlackListModal";
import CheckboxMultiselect from "../../components/Checkboxes";
import {ProgressBar} from "../../components/Progress";
import {ProgressModal} from "../../components/ProgressModal";
import SelectNew from "../../components/SelectNew";
import {TableContainerLayout} from "../../components/TableContainerLayout";
import {UserDetailsModal} from "../../components/UserDetailsModal";
import {cities, ICity} from "../../components/utils/cities";
import NotesModal from "../../components/NotesModal";
import ModalStatus from "./modalStatus";

import BlackListService from "../../services/BlackListService";
import CompanyService from "../../services/CompanyService";
import {getSummaryByFromString, SortOptions, SummaryBy,} from "../../services/ReportService";
import SkillService from "../../services/SkillService";
import SkillTreeService from "../../services/SkillTreeService";
import UserService from "../../services/UserService";
import api from "../../services/api";

import {filtersReducer, initialFiltersState, StateFilters,} from "./filtersReducer";

import debounce from "lodash/debounce";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import * as S from "./styles";
import {SuggestPositionModal} from "../../components/SuggestPositionModal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuItem from "@mui/material/MenuItem";

interface IDataToDropdown {
  usersMainFilter: any;
  userData: any;
  skillData: any;
  trailData: any;
  skillTreeData: any;
  positionData: any;
  sumarizeData: any;
  currentFilter: { paramName: string; filterNameGet: string; nextFilter: any };
}

export default function PerformanceReport() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openIconMenu = Boolean(anchorEl);

  const [filtersValues, dispatchFilters] = useReducer(
    filtersReducer,
    initialFiltersState
  );

  const {
    onlyUnlockedUser,
    selectedPosition,
    selectedCourse,
    selectedSkill,
    citySelected,
    stateSelected,
    selectStatus,
    userSelected,
    selectedCompetence,
  } = filtersValues;

  const queryParams = new URLSearchParams(location.search);
  const tenant = JSON.parse(localStorage.getItem("tenantObj"));
  const tenantName = tenant?.name;

  const summarizeBy =
    getSummaryByFromString(queryParams.get("summarizeBy")) ?? null;

  const defaultSkillFocusId = !isNaN(
    parseInt(queryParams.get("skillFocusId"), 10)
  )
    ? parseInt(queryParams.get("skillFocusId"), 10)
    : null;
  const defaultUserId = !isNaN(parseInt(queryParams.get("userId"), 10))
    ? parseInt(queryParams.get("userId"), 10)
    : null;
  const defaultSelectedPool =
    queryParams.get("selectedPool") != null
      ? parseInt(queryParams.get("selectedPool"), 10)
      : localStorage.getItem("selectedPool") != null
        ? parseInt(localStorage.getItem("selectedPool"), 10)
        : null;
  const defaultSelectedPositionVisibility =
    queryParams.get("filterType") != null
      ? parseInt(queryParams.get("filterType"), 10)
      : localStorage.getItem("selectedPositionVisibility") != null
        ? parseInt(localStorage.getItem("selectedPositionVisibility"), 10)
        : 2;
  const defaultFilterOptions =
    localStorage.getItem("performanceReportFilterOptions") != null
      ? JSON.parse(localStorage.getItem("performanceReportFilterOptions"))
      : null;

  const defaultFilter = () => {
    return {
      userSelected: undefined,
      skillSelected: undefined,
      trailSelected: undefined,
      skillTreeSelected: undefined,
      positionSelected: undefined,
      sumarizeSelected: undefined,
    };
  };

  const defaultDataToDropdown = {
    userData: [],
    skillData: [],
    trailData: [],
    usersMainFilter: [],
    skillTreeData: [],
    positionData: [],
    sumarizeData: [
      {
        id: SummaryBy.Formation,
        value: t("performanceReport.position"),
      },
      {
        id: SummaryBy.Trail,
        value: t("performanceReport.course"),
      },
      {
        id: SummaryBy.SkillTree,
        value: t("performanceReport.competence"),
      },
      {
        id: SummaryBy.Skill,
        value: t("performanceReport.skill"),
      },
    ],
    currentFilter: {
      paramName: "skillFocusId",
      filterNameGet: "positions",
      nextFilter: "selectedSkill",
    },
  };

  const getNextFilter = (valueFilter: string) =>
    ({
      selectedSkill: {
        paramName: "skillFocusId",
        filterNameGet: "",
        nextFilter: null,
      },
    }[valueFilter] || null);

  const [rows, setRows] = useState<any>([]);
  const [dataToDropdown, setDataToDropdown] = useState<IDataToDropdown>(
    defaultDataToDropdown
  );
  const [isCurrentUserSysAdmin, setIsCurrentUserSysAdmin] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(null);
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [openCustomPositionModal, setOpenCustomPositionModal] = useState(false);
  const [skillFocusId, setSkillFocusId] = useState<number>(defaultSkillFocusId);
  const [userId, setUserId] = useState<number>(defaultUserId);
  const [dataStatusModal, setDataStatusModal] = useState<any>();
  const [sortOption, setSortOption] = useState<SortOptions>(
    SortOptions.CompletionDesc
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showBlackListModal, setShowBlackListModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [unlockFilterData, setUnlockFilterData] = useState([]);
  const [unlockStatusData, setUnlockStatusData] = useState([]);
  const [pool, setPool] = useState<number>(defaultSelectedPool);
  const [progressInfo, setProgressInfo] = useState({});

  const [visibility, setVisibility] = useState<number>(
    defaultSelectedPositionVisibility
  );
  const [onlyLockedUsers, setOnlyLockedUsers] = useState<boolean>(
    defaultFilterOptions?.onlyLockedUsers ?? null
  );
  const [filteredCities, setFilteredCities] = useState<ICity[]>([]);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [rowsPerPageTable, setRowsPerPageTable] = useState<10 | 25 | 50 | 100>(
    10
  );
  const [skillTreeData, setSkillTreeData] = useState([]);
  const [notesModal, setNotesModal] = useState(false)
  const [notes, setNotes] = useState('')
  const [list, setList] = useState([]);
  const [balance, setBalance] = useState(0);
  const [skillDropDownData, setSkillDropDownData] = useState<any>([]);
  const [isTrail, setIsTrail] = useState(false)
  const [openSuggestPositionModal, setOpenSuggestPositionModal] = useState(false)
  const [solicitationName, setSolicitationName] = useState<string>()

  const [statesOptions, setStatesOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [skillTextField, setSkillTextField] = useState("");
  const [open, setOpen] = useState(false);
  const [userUnlockId, setUserUnlockId] = useState(null);
  const [userUnlockName, setUserUnlockName] = useState('');

  const getSkillsDropDown = debounce(async (value) => {
    if (
      value?.length <= 2 ||
      !(!selectedCourse || !selectedPosition || !selectedCompetence)
    ) {
      setSkillTextField(value);
      return;
    }

    setLoading(true);
    await getSkills(value);
    setSkillTextField(value);
    setLoading(false);
  }, 500);

  async function getPerformanceReports() {
    setLoading(true);
    const payloadData = {
      trailId: selectedCourse?.value,
      formationId: selectedPosition?.value,
      skillTreeId: selectedCompetence?.value,
      skillId: selectedSkill?.value,
      userIds: userSelected?.map((item) => item.value),
      stateIds: stateSelected?.map((item) => item.value),
      cityIds: citySelected?.map((item) => item.value),
      userStatus: selectStatus?.value,
      onlyUnlockedUser: onlyUnlockedUser?.value,
      manualSort: sortOption,
    };

    const response = await api.post(
      `/v2/performance/report?size=${rowsPerPageTable}&page=${page - 1}`,
      payloadData
    );

    if (response?.data?.filters) {
      if (response?.data?.filters?.users.length > 0) {
        let newUsersSelected = [];
        userSelected.forEach((selected) => {
          if (
            response?.data?.filters?.users.some(
              (item) => item.value == selected.value
            )
          ) {
            newUsersSelected.push(selected);
          }
        });
        setDataToDropdown((prevState) => ({
          ...prevState,
          usersMainFilter: sortBy(response?.data?.filters.users, "name"),
        }));
        if (JSON.stringify(newUsersSelected) != JSON.stringify(userSelected)) {
          dispatchFilters({
            type: "onChangeValue",
            payload: {name: "userSelected", value: newUsersSelected},
          });
        }
      }

      if (response?.data?.filters?.states.length > 0) {
        let newStateSelected = [];
        stateSelected.forEach((selected) => {
          if (
            response?.data?.filters?.states.some(
              (item) => item.value == selected.value
            )
          ) {
            newStateSelected.push(selected);
          }
        });

        setStatesOptions(sortBy(response.data.filters.states, "name"));

        if (JSON.stringify(newStateSelected) != JSON.stringify(stateSelected)) {
          dispatchFilters({
            type: "onChangeValue",
            payload: {name: "stateSelected", value: newStateSelected},
          });
          dispatchFilters({
            type: "onChangeValue",
            payload: {name: "citySelected", value: []},
          });
        }
      }
      if (response?.data?.filters?.cities.length > 0) {
        let newCitySelected = [];
        citySelected.forEach((selected) => {
          if (
            response?.data?.filters?.cities.some(
              (item) => item.value == selected.value
            )
          ) {
            newCitySelected.push(selected);
          }
        });

        setFilteredCities(sortBy(response.data.filters.cities, "name"));

        if (JSON.stringify(newCitySelected) != JSON.stringify(citySelected)) {
          dispatchFilters({
            type: "onChangeValue",
            payload: {name: "citySelected", value: newCitySelected},
          });
        }
      }

      if (response?.data?.filters?.status.length > 0) {
        let newDataToStatus = [];
        response?.data?.filters?.status.forEach((i) => {
          if (i.name === "HIRED") {
            newDataToStatus.push({
              value: "HIRED",
              name: "Contratado",
            });
          }
          if (i.name === "EVALUATION_APPROVED") {
            newDataToStatus.push({
              value: "EVALUATION_APPROVED",
              name: "Aprovado",
            });
          }
          if (i.name === "IN_EVALUATION") {
            newDataToStatus.push({
              value: "IN_EVALUATION",
              name: "Em análise",
            });
          }
          if (i.name === "REPROVED") {
            newDataToStatus.push({
              value: "REPROVED",
              name: "Reprovado",
            });
          }
          if (i.name === "NOT_EVALUATED") {
            newDataToStatus.push({
              value: "NOT_EVALUATED",
              name: "Não analisado",
            });
          }
        });

        setUnlockStatusData(sortBy(newDataToStatus, "name"));
      }
      if (response?.data?.filters?.unlockStatus.length > 0) {
        let newDataToUnlock = [];
        response?.data?.filters?.unlockStatus.forEach((i) => {
          if (i.name === "Unlocked") {
            newDataToUnlock.push({
              name: t("performanceReport.justUnlockedOnes"),
              value: false,
            });
          }
          if (i.name === "Locked") {
            newDataToUnlock.push({
              name: t("performanceReport.justNotUnlockedOnes"),
              value: true,
            });
          }
        });

        setUnlockFilterData(sortBy(newDataToUnlock, "name"));
      }
    }
    if (response?.data?.report) {
      setRows(response.data.report.content);
      setTotalPages(response.data.report.totalPages);
      setTotalItems(response.data.report.totalElements);
    } else {
      setRows([]);
      setStatesOptions([]);
      setFilteredCities([]);
      setUnlockFilterData([]);
      setTotalPages(0);
      setTotalItems(0);
      setDataToDropdown((prevState) => ({
        ...prevState,
        usersMainFilter: [],
      }));
    }

    setLoading(false);
  }

  useEffect(() => {
    getPerformanceReports();
  }, [filtersValues, page, rowsPerPageTable, sortOption]);

  const messageSent = () => {
    setToastMessage(t("performanceReport.successSendEmail"));
    setOpenToast(true);
  };

  function usersUnlocked() {
    setToastMessage(t("performanceReport.unblockerUser"));
    setOpenToast(true);
  }

  const handleOpenModal = (data: string) => {
    data === 'trail' && setIsTrail(true)
    setOpenSuggestPositionModal(true);
  };

  useEffect(() => {
    if (openToast) {
      const timer = setTimeout(() => {
        setOpenToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [openToast]);

  async function getDataToDropdown() {
    let newDataToDropdown = {...dataToDropdown};
    dispatchFilters({type: "resetState", payload: null});

    const filterFormationVisibility =
      visibility === 2 ? null : visibility !== 1;

    const filterSkillHunterPool = pool === 0;

    let filters = await api.get(
      `/v2/performance/filters?onlyExternalFormations=${
        filterFormationVisibility == null ? "" : filterFormationVisibility
      }&skillHunterPool=${filterSkillHunterPool || "false"}`
    );

    newDataToDropdown.positionData =
      sortBy(filters?.data?.positions, "name") ?? [];
    newDataToDropdown.trailData = sortBy(filters?.data?.trails, "name") ?? [];
    newDataToDropdown.usersMainFilter = [];
    setStatesOptions([]);
    setFilteredCities([]);

    setDataToDropdown((prevState) => ({...prevState, ...newDataToDropdown}));

    if (dataToDropdown?.currentFilter) {
      newDataToDropdown.currentFilter = null;
      await getDefaults(filters, dataToDropdown.currentFilter);
    }
  }

  async function getDefaults(
    filterValue,
    currentFilter: {
      paramName: string;
      filterNameGet: string;
      nextFilter: "string";
    }
  ) {
    if (!summarizeBy) return;

    const defaultValue = getDefaultValueByParams(
      get(currentFilter, "paramName", "")
    );

    if (summarizeBy === SummaryBy.Formation) {
      const defaultSelectedPosition =
        get(filterValue, `data[${currentFilter.filterNameGet}]`, null)?.find(
          (item) => item.value === defaultValue
        ) || null;
      onChangeValue("selectedPosition", defaultSelectedPosition);
    }

    if (summarizeBy === SummaryBy.SkillTree) {
      const defaultSelectedCompetence =
        get(filterValue, `data[${currentFilter.filterNameGet}]`, null)?.find(
          (item) => item.value === defaultValue
        ) || null;
      onChangeValue("selectedCompetence", defaultSelectedCompetence);
    }

    if (summarizeBy === SummaryBy.Skill) {
      const defaultNameSkillValue = getDefaultValueByParams(
        "userName",
        "string"
      );

      if (typeof defaultNameSkillValue !== "string") return;

      const responseData = await getSkills(defaultNameSkillValue);

      const defaultSelectedSkill =
        responseData?.find((item) => item.value === defaultValue) || null;

      dispatchFilters({
        type: "onChangeValue",
        payload: {name: "selectedSkill", value: defaultSelectedSkill},
      });
    }

    if (getNextFilter(get(currentFilter, "nextFilter", null))) {
      await getDefaults(
        filterValue,
        getNextFilter(get(currentFilter, "nextFilter", null))
      );
    }
  }

  function getDefaultValueByParams(
    paramName: string,
    typeReturn: "number" | "string" = "number"
  ): number | string | null {
    const paramValue = queryParams.get(paramName);

    if (typeReturn === "string") {
      return paramValue !== null ? paramValue : null;
    } else {
      const parsedNumber = parseInt(paramValue || "", 10);
      return !isNaN(parsedNumber) ? parsedNumber : null;
    }
  }

  async function getSkills(filter = "") {
    const filterFormationVisibility =
      visibility === 2 ? null : visibility !== 1;
    const filterSkillHunterPool = pool === 0;

    const restResult = await SkillService.getSkillToDropdownAquarium(
      selectedCourse?.value,
      selectedPosition?.value,
      selectedCompetence?.value,
      filterFormationVisibility,
      filterSkillHunterPool,
      filter
    );

    const resultTreated = sortBy(restResult, "name");

    setSkillDropDownData(resultTreated);

    return resultTreated;
  }

  async function getCompetenceData() {
    const data = {
      formationId: selectedPosition?.value || "",
      trailId: selectedCourse?.value || "",
      skillId: selectedSkill?.value || "",
    };

    const newSkillTreeData = await SkillTreeService.getSkillTreeToDropdown(
      data
    );

    setSkillTreeData(sortBy(newSkillTreeData, "name"));
  }

  useEffect(() => {
    getCompetenceData();
    getSkills();
  }, [selectedCourse, selectedPosition]);

  useEffect(() => {
    getSkills();
  }, [selectedCompetence]);

  useEffect(() => {
    getCompetenceData();
  }, [selectedSkill]);

  const getCurrentUser = async () => {
    try {
      const response = await UserService.getUser();
      setCurrentCompany(response.company?.id);
      setIsCurrentUserSysAdmin(response.roles.find((item) => item?.id === 7));
    } catch (err) {
      console.error(err);
    }
  };

  const getCurrentBalance = async () => {
    try {
      const response = await CompanyService.getCurrentCompanyBalance();
      setBalance(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDataToDropdown();
  }, [pool, visibility]);

  useEffect(() => {
    getCurrentUser();
    getCurrentBalance();
    getSkills();
  }, []);

  const changeOrder = (field) => {
    if (field === "completion") {
      setSortOption(
        sortOption === SortOptions.CompletionDesc
          ? SortOptions.CompletionAsc
          : SortOptions.CompletionDesc
      );
    }

    if (field === "grade") {
      setSortOption(
        sortOption === SortOptions.GradeDesc
          ? SortOptions.GradeAsc
          : SortOptions.GradeDesc
      );
    }

    setPage(1);
  };

  const sortIconCompletion = () => {
    if (sortOption === SortOptions.CompletionAsc) {
      return <SortDescending size={19}/>;
    } else if (sortOption === SortOptions.CompletionDesc) {
      return <SortAscending size={19}/>;
    } else {
      return <ArrowsDownUp size={19}/>;
    }
  };

  function getTranslationStatus(status: UserStatusFormation) {
    if (!status) {
      return t("performanceReport.statusNull");
    }
    switch (status) {
      case UserStatusFormation.HIRED:
        return t("performanceReport.statusHired");
      case UserStatusFormation.EVALUATION_APPROVED:
        return t("performanceReport.statusEvaluationApproved");
      case UserStatusFormation.IN_EVALUATION:
        return t("performanceReport.statusInEvaluation");
      case UserStatusFormation.REPROVED:
        return t("performanceReport.statusReproved");
      case UserStatusFormation.NOT_EVALUATED:
        return t("performanceReport.statusNull");
      default:
        return "";
    }
  }

  const sortIconGrade = () => {
    if (sortOption === SortOptions.GradeAsc) {
      return <SortDescending size={19}/>;
    } else if (sortOption === SortOptions.GradeDesc) {
      return <SortAscending size={19}/>;
    } else {
      return <ArrowsDownUp size={19}/>;
    }
  };

  const renderLockIcon = (unlocked) => {
    if (unlocked === false) {
      return <LockIcon fontSize="medium" color="error"/>;
    }

    return <LockOpenIcon fontSize="medium" color="success"/>;
  };

  const unlockUser = async (id: number) => {
    try {
      await CompanyService.unlockUserById(id);
      setBalance(balance - 1);
      usersUnlocked();
      getPerformanceReports();
      setOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const openUnlockUserModal = (id, name) => {
    setUserUnlockName(name);
    setUserUnlockId(id);
    setOpen(true);
  };

  const handleCloseUnlock = () => {
    setOpen(false);
    setUserUnlockId(null);
    setUserUnlockName(null);
  }

  const handleChangePool = (poolId) => {
    localStorage.setItem("selectedPool", poolId);
    if (poolId === 0) {
      localStorage.setItem("selectedPositionVisibility", "0");
    } else {
      localStorage.setItem("selectedPositionVisibility", "2");
    }
    setPool(poolId);
    setRows([]);
    setVisibility(poolId === 0 ? 0 : 2);
  };

  const handleChangeVisibility = (visibilityId) => {
    localStorage.setItem("selectedPositionVisibility", visibilityId);
    setVisibility(visibilityId);
    setRows([]);
  };

  const handleAddBlackList = async (id) => {
    await BlackListService.addBlackList(id);
    enqueueSnackbar(t("performanceReport.blacklistAdded"), {
      variant: "success",
    });
    setShowBlackListModal(false);
    setSelectedId(null);
  };

  useEffect(() => {
    setList([]);
    setSelectAll(false);
  }, [rows]);

  const handleCheckboxChange = (event, id) => {
    const value = event.target.checked;
    if (value) {
      setList([...list, id]);
    } else {
      setList(list.filter((item) => item !== id));
    }
  };

  const handleSelectAll = (event) => {
    const value = event.target.checked;
    if (value) {
      const ids = rows
      ?.filter((item) => !item.userUnlocked)
      ?.map((item) => item?.userId);
      setList(ids);
    } else {
      setList([]);
    }
    setSelectAll(value);
  };

  const unlockListUsers = async () => {
    try {
      await CompanyService.unlockedListUsers(list);
      setBalance(balance - list.length);
      usersUnlocked();
      getPerformanceReports();
    } catch (err) {
      console.error(err);
    }
  };

  const fromDashboard = !!localStorage.getItem("fromDashboard");

  function onChangeValue(
    name: keyof StateFilters,
    value: StateFilters[keyof StateFilters]
  ) {
    switch (name) {
      default:
    }

    if (name === "selectedPosition") {
      if (!value)
        dispatchFilters({
          type: "onChangeValue",
          payload: {name: "selectStatus", value: null},
        });

      dispatchFilters({
        type: "onChangeValues",
        payload: [
          {name: "selectedCourse", value: null},
          {name: "selectedSkill", value: null},
          {name: "selectedCompetence", value: null},
          {name: "onlyUnlockedUser", value: null},
          {name: "selectStatus", value: null},
        ],
      });
    }

    if (name === "selectedCourse") {
      dispatchFilters({
        type: "onChangeValues",
        payload: [
          {name: "selectedPosition", value: null},
          {name: "selectedSkill", value: null},
          {name: "selectedCompetence", value: null},
          {name: "onlyUnlockedUser", value: null},
          {name: "selectStatus", value: null},
        ],
      });
    }

    if (name === "stateSelected") {
      dispatchFilters({
        type: "onChangeValues",
        payload: [{name: "citySelected", value: []}],
      });
    }

    if (name === "selectedCompetence" && value !== null)
      dispatchFilters({
        type: "onChangeValues",
        payload: [
          {name: "selectStatus", value: null},
          {name: "onlyUnlockedUser", value: null},
        ],
      });

    if (name === "selectedSkill" && value != null)
      dispatchFilters({
        type: "onChangeValues",
        payload: [
          {name: "selectStatus", value: null},
          {name: "onlyUnlockedUser", value: null},
        ],
      });

    dispatchFilters({type: "onChangeValue", payload: {name, value}});
    setPage(1);
  }

  const handleNotesModal = async (userId, positionId) => {
    try {
      setLoading(true);
      const result = await UserService.getUserNotes(userId, positionId);
      setNotes(result?.userNotes)
      setNotesModal(true)
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleSaveNotes = async (data) => {
    try {
      setLoading(true);
      await UserService.updateUserNotes({userId: selectedId, userNotes: data, positionId: selectedPosition?.value});
      setNotesModal(false)
      enqueueSnackbar(t("performanceReport.notesAdded"), {
        variant: "success",
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenIconMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleCloseIconMenu = () => {
    setAnchorEl(null);
  }

  return (
    <div>
      {showModalStatus && (
        <ModalStatus
          data={dataStatusModal}
          formationId={selectedPosition?.value}
          setShowModalStatus={setShowModalStatus}
          refetchReport={getPerformanceReports}
        ></ModalStatus>
      )}
      <S.Container>
        <S.Title>{t("sidebar.pool")}</S.Title>
        {fromDashboard && (
          <BackButton
            style={{marginBottom: 24}}
            onClick={() => navigate("/management/dashboard")}
          >
            {t("performanceReport.backToDashboard")}
          </BackButton>
        )}
        {/*<S.Subtitle>TODO Implementar texto aqui</S.Subtitle>*/}
        <S.UpperFilterContainer>
          <FormControl>
            <FormLabel color="error" id="demo-row-radio-buttons-group-label">
              {t("performanceReport.selectTalentPool")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <S.FormControlTooltipWrapper>
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      color="error"
                      onChange={() => handleChangePool(0)}
                      checked={pool === 0}
                    />
                  }
                  label="SkillHunter"
                />
                <Tooltip
                  sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                  title={t("performanceReport.skillHunterAquarium")}
                  placement="top"
                >
                  <InfoOutlined/>
                </Tooltip>
              </S.FormControlTooltipWrapper>

              <S.FormControlTooltipWrapper>
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      color="error"
                      onChange={() => handleChangePool(1)}
                      checked={pool === 1}
                    />
                  }
                  label={tenantName}
                />
                <Tooltip
                  sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                  title={t("performanceReport.internalAquarium")}
                  placement="top"
                >
                  <InfoOutlined/>
                </Tooltip>
              </S.FormControlTooltipWrapper>
            </RadioGroup>
          </FormControl>
        </S.UpperFilterContainer>
        {pool !== null && (
          <div>
            <S.UpperFilterContainer>
              <FormControl>
                <FormLabel
                  color="error"
                  id="demo-row-radio-buttons-group-label"
                >
                  {t("performanceReport.specifyPositionType")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <S.FormControlTooltipWrapper>
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          color="error"
                          onChange={() => handleChangeVisibility(0)}
                          checked={visibility === 0}
                        />
                      }
                      label={t("performanceReport.externalRecruitment")}
                    />
                    <Tooltip
                      sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                      title={t("performanceReport.publicPositions")}
                      placement="top"
                    >
                      <InfoOutlined/>
                    </Tooltip>
                  </S.FormControlTooltipWrapper>
                  <S.FormControlTooltipWrapper>
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          disabled={pool === 0}
                          color="error"
                          onChange={() => handleChangeVisibility(1)}
                          checked={visibility === 1}
                        />
                      }
                      label={t("performanceReport.internalRecruitment")}
                    />
                    <Tooltip
                      sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                      title={t("performanceReport.privatePositions")}
                      placement="top"
                    >
                      <InfoOutlined/>
                    </Tooltip>
                  </S.FormControlTooltipWrapper>
                  <S.FormControlTooltipWrapper>
                    <FormControlLabel
                      value={2}
                      control={
                        <Radio
                          disabled={pool === 0}
                          color="error"
                          onChange={() => handleChangeVisibility(2)}
                          checked={visibility === 2}
                        />
                      }
                      label={t("performanceReport.unspecified")}
                    />
                    <Tooltip
                      sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                      title={t("performanceReport.notSpecifiedOption")}
                      placement="top"
                    >
                      <InfoOutlined/>
                    </Tooltip>
                  </S.FormControlTooltipWrapper>
                </RadioGroup>
              </FormControl>
            </S.UpperFilterContainer>
            <S.ContainerFilter>
              <S.ContainerInputs>
                <S.ContainerSelectInput>
                  <SelectNew
                    options={dataToDropdown?.positionData}
                    label={t("performanceReport.position")}
                    value={selectedPosition}
                    onChangeTextField={e => setSolicitationName(e.target.value)}
                    onChange={(newValue) => {
                      onChangeValue("selectedPosition", newValue);
                      const filter = defaultFilter();
                      filter.positionSelected = newValue;
                    }}
                    noOptionsText={
                      <ButtonComponent onClick={() => handleOpenModal('position')}>Solicitar cargo personalizado</ButtonComponent>
                    }
                  />
                </S.ContainerSelectInput>

                <S.ContainerSelectInput>
                  <SelectNew
                    options={dataToDropdown?.trailData}
                    label={t("performanceReport.course")}
                    value={selectedCourse}
                    onChangeTextField={e => setSolicitationName(e.target.value)}
                    onChange={(newValue) => {
                      onChangeValue("selectedCourse", newValue);
                    }}
                    noOptionsText={
                      <ButtonComponent onClick={() => handleOpenModal('trail')}>Solicitar trilha personalizada</ButtonComponent>
                    }
                  />
                </S.ContainerSelectInput>

                <S.ContainerSelectInput>
                  <SelectNew
                    disabled={!selectedCourse && !selectedPosition}
                    value={selectedCompetence}
                    onChange={(newValue) => {
                      onChangeValue("selectedCompetence", newValue);
                    }}
                    label={t("performanceReport.competence")}
                    options={skillTreeData}
                  />
                </S.ContainerSelectInput>
                <S.ContainerSelectInput>
                  <SelectNew
                    value={selectedSkill}
                    onChange={(newValue) => {
                      onChangeValue("selectedSkill", newValue);
                    }}
                    loading={loading}
                    label={t("performanceReport.skill")}
                    options={skillDropDownData}
                    noOptionsText={
                      skillTextField?.length < 3 ? t("typeToSearch") : null
                    }
                    onChangeTextField={(e) => getSkillsDropDown(e.target.value)}
                  />
                </S.ContainerSelectInput>
                <S.ContainerSelectInput>
                  <SelectNew
                    value={selectStatus}
                    onChange={(newValue) => {
                      onChangeValue("selectStatus", newValue);
                    }}
                    label="Status do RH"
                    options={unlockStatusData}
                    disabled={
                      selectedPosition == null || unlockStatusData.length == 0
                    }
                  />
                </S.ContainerSelectInput>
                <S.ContainerSelectInput>
                  <CheckboxMultiselect
                    disabled={statesOptions?.length == 0}
                    value={stateSelected}
                    onChange={(newValue) => {
                      onChangeValue("stateSelected", newValue);
                      setFilteredCities(
                        cities.filter((city) => city.state_id === newValue?.id)
                      );
                    }}
                    label={t("performanceReport.state")}
                    options={statesOptions}
                  />
                </S.ContainerSelectInput>
                <S.ContainerSelectInput>
                  <CheckboxMultiselect
                    disabled={
                      stateSelected?.length == 0 || filteredCities?.length == 0
                    }
                    value={citySelected}
                    onChange={(newValue) => {
                      onChangeValue("citySelected", newValue);
                    }}
                    label={t("performanceReport.city")}
                    options={filteredCities}
                  />
                </S.ContainerSelectInput>

                <S.ContainerSelectInput>
                  <CheckboxMultiselect
                    options={dataToDropdown?.usersMainFilter}
                    label={t("performanceReport.user")}
                    disabled={dataToDropdown?.usersMainFilter.length == 0}
                    value={userSelected}
                    onChange={(newValue) => {
                      onChangeValue("userSelected", newValue);
                    }}
                  />
                </S.ContainerSelectInput>

                <S.ContainerSelectInput>
                  <SelectNew
                    value={onlyUnlockedUser}
                    onChange={(newValue) => {
                      onChangeValue("onlyUnlockedUser", newValue);
                    }}
                    disabled={unlockFilterData.length == 0}
                    label={"Desbloqueio"}
                    options={unlockFilterData}
                  />
                </S.ContainerSelectInput>
              </S.ContainerInputs>
              <S.ContainerButtons>
                <Button
                  style={{
                    display: "flex",
                    gap: 8,
                    width: 142,
                    height: 42,
                    background: "#fff0",
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "#131212",
                    color: "#131212",
                  }}
                  onClick={() => {
                    dispatchFilters({type: "resetState", payload: null});
                  }}
                >
                  <X size={17}/> {t("performanceReport.clean")}
                </Button>
              </S.ContainerButtons>
            </S.ContainerFilter>
            {t("performanceReport.remainingUnblocks")}: <b>{balance}</b>
            <br/>
            Candidatos: <b>{totalItems}</b>
            <S.StyledTableContainerLayout>
              <TableContainerLayout
                marginTop={15}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                rowsPerPage={rowsPerPageTable}
                setRowsPerPage={(newRowsPerPage) => {
                  setPage(1);
                  setRowsPerPageTable(newRowsPerPage);
                }}
                // totalItems={totalItems}
                withHeader={false}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{whiteSpace: "nowrap", width: "1%"}}>
                          <Tooltip
                            title={t("performanceReport.selectAll")}
                            arrow
                            placement="top"
                          >
                            <span>
                              <Checkbox
                                value="valor-do-checkbox"
                                sx={{
                                  color: "#8c8c8c",
                                  "&.Mui-checked": {
                                    color: "#FF026F",
                                  },
                                }}
                                checked={selectAll}
                                disabled={
                                  !!(
                                    rows.length === 0 ||
                                    isCurrentUserSysAdmin ||
                                    rows.filter((item) => !item.userUnlocked)
                                      .length === 0
                                  )
                                }
                                onChange={handleSelectAll}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              list.length <= 0
                                ? t("performanceReport.noUserSelected")
                                : t("performanceReport.unlockUserSelected")
                            }
                            arrow
                            placement="top"
                          >
                            <span>
                              <Button
                                onClick={unlockListUsers}
                                disabled={list.length <= 0}
                              >
                                <GroupsIcon
                                  fontSize="medium"
                                  color={
                                    list.length <= 0 ? "disabled" : "success"
                                  }
                                />
                                <LockOpenIcon
                                  fontSize="medium"
                                  color={
                                    list.length <= 0 ? "disabled" : "success"
                                  }
                                />
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{width: "8%"}}>
                          <S.FontHeaderTable>
                            {t("performanceReport.user")}
                          </S.FontHeaderTable>
                        </TableCell>
                        {selectedPosition !== null && (
                          <TableCell sx={{width: "6%"}}>
                            <S.FontHeaderTable>Status do RH</S.FontHeaderTable>
                          </TableCell>
                        )}
                        {selectedSkill == null && (
                          <TableCell>
                            <S.FontHeaderTable
                              onClick={() => changeOrder("completion")}
                            >
                              <S.MaskOfFontHeaderTable>
                                <span>{t("performanceReport.progress")}</span>
                                {sortIconCompletion()}
                              </S.MaskOfFontHeaderTable>
                            </S.FontHeaderTable>
                          </TableCell>
                        )}
                        <TableCell>
                          <S.FontHeaderTable
                            onClick={() => changeOrder("grade")}
                          >
                            <S.MaskOfFontHeaderTable>
                              <span>{t("performanceReport.performance")}</span>
                              {sortIconGrade()}
                            </S.MaskOfFontHeaderTable>
                          </S.FontHeaderTable>
                        </TableCell>
                        <TableCell
                          sx={{
                            minWidth: 290,
                            background: "white",
                            position: "sticky",
                            right: 0,
                          }}
                        >
                          <S.FontHeaderTable>{null}</S.FontHeaderTable>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rows.length > 0 &&
                        rows?.map((item) => (
                          <TableRow sx={{position: "relative"}}>
                            <TableCell>
                              <Checkbox
                                value="valor-do-checkbox"
                                sx={{
                                  color: "#8c8c8c",
                                  "&.Mui-checked": {
                                    color: "#FF026F",
                                  },
                                }}
                                disabled={
                                  !!(
                                    isCurrentUserSysAdmin || item?.userUnlocked
                                  )
                                }
                                onChange={(event) =>
                                  handleCheckboxChange(event, item?.userId)
                                }
                                checked={list.includes(item?.userId)}
                              />
                            </TableCell>
                            <TableCell sx={{minWidth: 200, width: "100%"}}>
                              <S.AnchorName>{item.userName}</S.AnchorName>
                            </TableCell>
                            {selectedPosition !== null && (
                              <TableCell sx={{minWidth: 200, width: "100%"}}>
                                <S.RegularRow>
                                  {getTranslationStatus(
                                    item.userStatusFormation?.status
                                  )}
                                </S.RegularRow>
                              </TableCell>
                            )}
                            {selectedSkill == null && (
                              <TableCell sx={{width: "100%"}}>
                                <S.MaskOfPercentageRow>
                                  <S.RegularRow>{`${item.progress}%`}</S.RegularRow>
                                  <ProgressBar
                                    isChallenge
                                    value={item.progress}
                                    max={100}
                                  />
                                </S.MaskOfPercentageRow>
                              </TableCell>
                            )}
                            <TableCell sx={{width: "100%"}}>
                              <S.MaskOfPercentageRow>
                                <S.RegularRow>{`${item.grade}%`}</S.RegularRow>
                                <ProgressBar
                                  isChallenge
                                  value={item.grade}
                                  max={100}
                                />
                              </S.MaskOfPercentageRow>
                            </TableCell>

                            {!userId ? (
                              <TableCell
                                sx={{
                                  minWidth: 290,
                                  textAlign: "end",
                                  width: "100%",
                                  position: "sticky",
                                  right: 0,
                                  background: "white",
                                }}
                              >
                                <div
                                  style={{
                                    display: "inline-block",
                                    height: "80px",
                                    width: "1px",
                                    background: "rgba(224, 224, 224, 1)",
                                    marginTop: "-21px",
                                    marginBottom: "-35px",
                                  }}
                                ></div>
                                <Tooltip
                                  title={t("performanceReport.moreActions")}
                                  arrow
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      handleOpenIconMenu(e)
                                      setSelectedItem(item)
                                    }}
                                  >
                                    <MoreHorizIcon fontSize="medium"/>
                                  </IconButton>
                                </Tooltip>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={openIconMenu}
                                  onClose={handleCloseIconMenu}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setSelectedId(selectedItem?.userId);
                                        setShowModal(true);
                                        handleCloseIconMenu()
                                      }}
                                    >
                                      <ListItemIcon>
                                        <PermIdentityIcon fontSize="medium" />
                                      </ListItemIcon>
                                      <ListItemText>{t("performanceReport.seeUserData")}</ListItemText>
                                    </MenuItem>
                                    {(selectedPosition !== null ||
                                      selectedCourse !== null) && (
                                      <MenuItem
                                        onClick={() => {
                                          setSelectedId(selectedItem?.userId);
                                          setProgressInfo({
                                            userSkillsAcquired:
                                            selectedItem?.userSkillsAcquired,
                                            skillRequiredForCompletion:
                                            selectedItem?.skillRequiredForCompletion,
                                            subscribedAt: selectedItem?.subscribedAt,
                                            updatedAt: selectedItem?.updatedAt,
                                          });
                                          setOpenProgressModal(true);
                                          handleCloseIconMenu()
                                        }}
                                      >
                                        <ListItemIcon>
                                          <InfoOutlinedIcon fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>{t("performanceReport.seeUserProgress")}</ListItemText>
                                      </MenuItem>
                                    )}
                                    {!isCurrentUserSysAdmin &&
                                    item.company?.id !== currentCompany ? (
                                      <MenuItem
                                        onClick={() => {
                                          setSelectedId(selectedItem?.userId);
                                          setShowBlackListModal(true);
                                          handleCloseIconMenu()
                                        }}
                                      >
                                        <ListItemIcon>
                                          <BlockOutlinedIcon fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>{t("performanceReport.addIntoBlacklist")}</ListItemText>
                                      </MenuItem>
                                    ) : null}
                                    {
                                      isCurrentUserSysAdmin || selectedPosition !== null ? (
                                        <MenuItem
                                          onClick={() => {
                                            setSelectedId(selectedItem?.userId);
                                            handleNotesModal(selectedItem?.userId, selectedPosition?.value)
                                            handleCloseIconMenu()
                                          }}
                                        >
                                          <ListItemIcon>
                                            <InsertCommentOutlinedIcon fontSize="medium" />
                                          </ListItemIcon>
                                          <ListItemText>{t("performanceReport.notes")}</ListItemText>
                                        </MenuItem>
                                      ) : null
                                    }
                                    {!isCurrentUserSysAdmin &&
                                    selectedPosition !== null ? (
                                      <MenuItem
                                        onClick={() => {
                                          setDataStatusModal({
                                            id: selectedItem?.userStatusFormation?.id,
                                            value:
                                            selectedItem?.userStatusFormation?.status,
                                            userId: selectedItem?.userId,
                                            formationId: skillFocusId,
                                          });
                                          setShowModalStatus(true);
                                          handleCloseIconMenu()
                                        }}
                                      >
                                        <ListItemIcon>
                                          <ModeEdit fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText>{t("performanceReport.changeStatus")}</ListItemText>
                                      </MenuItem>
                                    ) : null}
                                  </MenuList>
                                </Menu>
                                <Tooltip
                                  title={
                                    isCurrentUserSysAdmin || item?.userUnlocked
                                      ? t("performanceReport.unblockedUser")
                                      : t("performanceReport.blockedUser")
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <IconButton
                                    sx={{cursor: "auto"}}
                                    onClick={() => {
                                      if (!isCurrentUserSysAdmin && !item?.userUnlocked) {
                                        openUnlockUserModal(item.userId, item.userName);
                                      }
                                    }}
                                  >
                                    {renderLockIcon(
                                      isCurrentUserSysAdmin ||
                                      item?.userUnlocked
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        ))}

                      {rows.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8} sx={{textAlign: "center"}}>
                            {t("performanceReport.noResultFound")}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableContainerLayout>
            </S.StyledTableContainerLayout>
          </div>
        )}
      </S.Container>
      <UserDetailsModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedId(null);
          setSelectedItem(null)
          getPerformanceReports();
        }}
        messageSent={messageSent}
        isSysAdmin={isCurrentUserSysAdmin}
        userId={selectedId}
        positionId={selectedPosition?.value}
        trailId={selectedCourse?.value}
        onAdd={() => {
          setBalance(balance - 1);
        }}
      />
      <Dialog
        open={open}
        onClose={handleCloseUnlock}
      >
        <DialogTitle>
          {`${t("performanceReport.unlockUser")} ${userUnlockName}? `}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("performanceReport.unlockConsume")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUnlock}>{t("no")}</Button>
          <Button onClick={() => {
            unlockUser(userUnlockId)
          }} autoFocus>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <NotesModal
        title={t('performanceReport.notes')}
        onClose={() => {
          setNotesModal(false)
          setNotes('')
        }}
        notes={notes}
        open={notesModal}
        onAgree={handleSaveNotes}
      />
      <ProgressModal
        open={openProgressModal}
        onClose={() => {
          setOpenProgressModal(false);
          setSelectedId(null);
          setSelectedItem(null)
        }}
        progressInfo={progressInfo}
        userId={selectedId}
        summarizeBy={selectedPosition != null ? "FORMATIONS" : "TRAIL"}
        positionId={selectedPosition?.value}
        courseId={selectedCourse?.value}
      />
      <SuggestPositionModal
        trail={isTrail}
        positionName={solicitationName}
        open={openSuggestPositionModal}
        onClose={() => setOpenSuggestPositionModal(false)}
      />
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
      >
        <Alert
          onClose={() => setOpenToast(false)}
          severity="success"
          sx={{width: "100%"}}
          data-cy="performanceScreenAlert"
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <BlackListModal
        open={showBlackListModal}
        onClose={() => {
          setShowBlackListModal(false);
          setSelectedId(null);
          setSelectedItem(null)
        }}
        title={t("performanceReport.areYouSureYouWantAddUserIntoBlacklist")}
        content={t("performanceReport.usersInBlacklistWontShow")}
        handleBlackList={handleAddBlackList}
        confirmContent={t("add")}
        id={selectedId}
      />
      <Backdrop
        sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}
        onClick={null}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
    </div>
  );
}
