import styled from "styled-components";
import { makeStyles } from "@mui/styles";


export const Label = styled.p`
  margin-bottom: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  border-radius: 999px;
  padding: 3px 8px;
  right: 36px;
  top: 27px;
  transform: translate(0, -50%);
  background-color: #737373;
`;

export const AmountText = styled.p`
  font-size: 18;
  font-weight: 700;
  color: #fff;
  text-align: center;
`;

export const OptionText = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #8c8c8c;
`;

export const CheckboxOutCircle = styled.div<{ selected: boolean }>`
  position: relative;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box"
      : "linear-gradient(white, white) padding-box, linear-gradient(90deg, #696969 0%, #696969 100%) border-box"};
  border-radius: 2px;
  border: 2px solid transparent;
`;

export const ErrorText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  color: #f5222d;
`;

export const OverrideMuiStyles = makeStyles({
  checkbox: {
    '& .MuiSvgIcon-root': {
      color: "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box"
    },
    '&.Mui-checked': {
      color: "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box",
      background: 'linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box',
    },
  },
  MuiAutocompleteRoot: {
    '& .MuiInputBase-input': {
      "& input[type='checkbox']": {
        border: "1px solid red",
      },
      '&::placeholder': {
        color: 'rgb(140, 140, 140)',
        opacity: 1
      }
    },
  }
});

export const customInputStyle = {
  border: '1px solid rgb(140, 140, 140)',
  borderRadius: '5px',
  fontSize: '18px',
  fontWeight: 700,
  padding: 0,
};

export const inputStyles = {
  fontSize: '18px',
  fontWeight: 700,
  fontFamily: 'Ubuntu',
  cursor: 'pointer',
  padding: '5.5px 4px 5.5px 5px',
  color: 'rgb(19, 18, 18)',
}