/* eslint-disable no-useless-escape */
import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input/input";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import SkillHunterBookLogo from "../../assets/logo-book-skillhunter.svg";
import SkillHunterLogo from "../../assets/sh-logo.svg";
import AvatarInput from "../../components/AvatarInput";
import { Select as CountrySelect } from "../../components/Select";
import { User } from "../../models/User";
import FileService from "../../services/FileService";
import UserService from "../../services/UserService";
import EmailVerify from "./components/EmailVerify";
import * as S from "./style";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import ArrowBackIcon from "../../assets/arrowback.svg";
import { cnpjMask, cuitMask } from "../../components/utils/masks";
import ContactPJ from "./components/ContactPJ";
import api, { axiosApi } from "../../services/api";

export default function UserSignUp() {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(false);
  const [showModalPJ, setShowModalPJ] = useState(false);
  const [data, setData] = useState<any>(location?.state?.data ?? {});
  const [isCheckedUseTerms, setIsCheckedUseTerms] = useState(false);
  const [receiveEmails, setReceiveEmails] = useState(true);
  const [countries, setCountries] = React.useState([]);
  const [countrySelected, setCountrySelected] = React.useState<any>();
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "pt"
  );
  const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);

  enum ITypeInterest {
    TRAIL = "TRAIL",
    POSITION = "POSITION",
  }

  const labels = {
    goBack: "Voltar para Login",
    add: "Criar conta",
    user: {
      id: "ID",
      name: "Nome",
      lastName: "Sobrenome",
      email: "E-mail",
      password: "Senha",
      confirmPassword: "Confirmar senha",
      avatarId: "Foto de perfil",
    },
  };

  const handleChangeLenguage = (value) => {
    setLanguage(value.target.value);
    localStorage.setItem("language", value.target.value);
    window.location.reload();
  };

  const schema = Yup.object({
    name: Yup.string()
      .min(1, t("signUp.validFirstName"))
      .max(255, t("signUp.validFirstName"))
      .required(t("signUp.validFirstName")),
    lastName: Yup.string()
      .min(1, t("signUp.validLastName"))
      .max(255, t("signUp.validLastName"))
      .required(t("signUp.validLastName")),
    email: Yup.string()
      .email(t("signUp.validEmail"))
      .max(255, t("signUp.validEmail"))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("signUp.validEmail"))
      .required(t("signUp.validEmail")),
    password: Yup.string()
      .min(8, t("signUp.validPasswordLength"))
      .max(255, t("signUp.validPassword"))
      .required(t("signUp.validPassword")),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("signUp.passwordMismatch")
    ),
  });

  const schemaPJ = Yup.object({
    answerable: Yup.string()
      .min(1, "Informe um responsável válido.")
      .required("Informe um responsável válido."),
    name: Yup.string()
      .min(1, t("signUp.validFirstName"))
      .required(t("signUp.validFirstName")),
    phone: Yup.string()
      .min(13, t("signUp.validPhoneNumber"))
      .max(15, t("signUp.validPhoneNumber"))
      .test("signUp.is-valid-phone", t("signUp.validPhoneNumber"), (value) => {
        if (!countrySelected) {
          return false;
        }
        if (countrySelected?.id === 10) {
          return (
            value &&
            isPossiblePhoneNumber(value, "AR") &&
            isValidPhoneNumber(value, "AR")
          );
        } else {
          return (
            value &&
            isPossiblePhoneNumber(value, "BR") &&
            isValidPhoneNumber(value, "BR")
          );
        }
      }),
    email: Yup.string()
      .email(t("signUp.validEmail"))
      .max(255, t("signUp.validEmail"))
      .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("signUp.validEmail"))
      .required(t("signUp.validEmail")),
    corporateName: Yup.string()
      .min(1, "Informe uma razão social válida.")
      .required("Informe uma razão social válida."),
    cnpj: Yup.string().test(
      "signUp.is-valid-cnpj",
      t("empresas.typeInValidCRN"),
      (value) => {
        if (!countrySelected) {
          return false;
        }
        if (countrySelected?.id === 10) {
          return true;
        } else {
          return value?.length === 18;
        }
      }
    ),
    cuit: Yup.string().test(
      "signUp.is-valid-cuit",
      t("empresas.typeInValidCUIT"),
      (value) => {
        if (!countrySelected) {
          return false;
        }
        if (countrySelected?.id !== 10) {
          return true;
        } else {
          return value?.length === 13;
        }
      }
    ),
    country: Yup.object().test(
      "signUp.is-valid-country",
      t("empresas.typeInValidCountry"),
      (value) => {
        return value != null;
      }
    ),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
  });

  const {
    control: controlPJ,
    handleSubmit: handleSubmitPJ,
    setValue: setValuePJ,
    getValues: getValuesPJ,
    formState: { errors: errorsPJ },
  } = useForm<any>({
    resolver: yupResolver(schemaPJ),
  });

  const handleSave = async (data: any) => {
    try {
      const token = captchaRef.current.getValue();

      if (!token) {
        enqueueSnackbar(t("login.errorCaptcha"), { variant: "warning" });
        return;
      }

      data.token = token;

      setCreateDisabled(true);
      if (!data?.curriculoId) {
        data.curriculoId = null;
      } else {
        const [curriculoResponse] = await FileService.sendFileDocument([
          data.curriculoId.newBlob,
        ]);
        data.curriculoId = curriculoResponse;
      }

      if (data.avatarId) {
        const [resultImages]: any = await FileService.sendFileNoToken([
          await getImage(data.avatarId),
        ]);
        data.avatarId = resultImages;
      }

      setEmail(data.email);
      data.acceptedTerms = isCheckedUseTerms;
      data.receiveEmails = receiveEmails;
      await UserService.signUp(data);
      setCreateDisabled(false);
      setShowModal(true);
      window.location.hash = "signUpCompleted";
    } catch (err: any) {
      setCreateDisabled(false);
      console.error(err);
      captchaRef.current.reset();
    }
  };

  async function getImage(avatarId: any) {
    if (
      location?.state?.data &&
      location.state.data.avatarId === avatarId.fileUrl
    ) {
      const response = await axios.get(avatarId.fileUrl, {
        responseType: "blob",
      });

      return new File([response.data], "image.jpg", {
        type: response.headers["content-type"],
      });
    }
    return avatarId;
  }

  const renderParams = (field) => {
    return {
      ...field,
      height: 3.375,
      placeholder: labels.user[field.name],
      errorMessage: errors[field.name]?.message,
      borderColor: errors[field.name] ? "#F5222D" : "#8c8c8c",
    };
  };

  const inputParams = (control, fieldName: string) => {
    return {
      name: fieldName,
      defaultValue: getDefaultValue(fieldName),
      control: control,
    };
  };

  const getDefaultValue = (fieldName: string) => {
    if (data) {
      if (fieldName === "avatarId" && data[fieldName]) {
        return { fileUrl: data[fieldName] };
      }
      return data[fieldName];
    }
    return "";
  };

  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");

  const onBackPress = () => {
    navigate("/login");
  };

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCheckboxChange = () => {
    setIsCheckedUseTerms(!isCheckedUseTerms);
  };

  const getImageDataUrl = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  async function getCountries() {
    try {
      const countriesResponse = await axiosApi.get(`/address/countries`);
      setCountries(countriesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  const [personType, setPersonType] = useState(0);

  function handleRadio(e) {
    setPersonType(e);
  }

  function handleChangeCountry(value) {
    setCountrySelected(value);
  }

  async function handleSubmitPjForm(data: any) {
    const token = captchaRef.current.getValue();

    if (!token) {
      enqueueSnackbar(t("login.errorCaptcha"), { variant: "warning" });
      return;
    }
    if (data?.cuit) {
      const newData = {
        ...data,
        phone: data.phone.replace("+54", ""),
        token: token,
        cnpj: data.cuit,
      };
      try {
        await UserService.sendCorporateLead(newData);
        setShowModalPJ(true);
      } catch (error) {
        captchaRef.current.reset();
      }
    } else {
      const newData = {
        ...data,
        token: token,
        phone: data.phone.replace("+55", ""),
      };
      try {
        await UserService.sendCorporateLead(newData);
        setShowModalPJ(true);
      } catch (error) {
        captchaRef.current.reset();
      }
    }

    setShowModalPJ(true);
  }

  useEffect(() => {
    getCountries();
  }, []);

  async function onBlurCNPJ(cnpj: string) {
    try {
      if (countrySelected?.id === 10 || !cnpj || cnpj.replace(/\D/g, '').length < 14) {
        return;
      }
      const apiUrl = 'https://api.cnpja.com/office/' + cnpj.replace(/\D/g, '');
      const headers = {
        Authorization: process.env.REACT_APP_CNPJ_SITE_KEY
      };

      setLoading(true);
      const { data } = await axios.get(apiUrl, { headers });

      if (data) {
        setValuePJ("name", data.company?.name);
        setValuePJ("corporateName", data.company?.name);
        setValuePJ("email", data.emails?.length > 0 ? data.emails[0].address : "");
        setValuePJ("phone", data.phones?.length > 0 ? "+55" + data.phones[0].area + data.phones[0].number : "");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  return (
    <>
     <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={null}
      >
         <CircularProgress color="inherit" />
      </Backdrop>
      <S.HeaderFullContainer>
        <S.CenterByMaxSize>
          <S.Logo src={SkillHunterLogo} />
          <S.LogoMin src={SkillHunterBookLogo} />
        </S.CenterByMaxSize>
        <Select
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            ".MuiSelect-select": {
              display: "flex !important",
              gap: "5px !important",
              alignItems: "center !important",
            },
            width: "100px",
            color: "white",
            display: "flex !important",
            gap: "5px !important",
            alignItems: "center !important",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          label="Age"
          onChange={handleChangeLenguage}
        >
          <MenuItem
            sx={{ display: "flex", gap: "5px", alignItems: "center" }}
            value="pt"
          >
            <span>PT-BR</span>
          </MenuItem>
          <MenuItem
            sx={{ display: "flex", gap: "5px", alignItems: "center" }}
            value="es"
          >
            <span>ES</span>
          </MenuItem>
          <MenuItem
            sx={{ display: "flex", gap: "5px", alignItems: "center" }}
            value="en"
          >
            <span>EN-US</span>
          </MenuItem>
        </Select>
      </S.HeaderFullContainer>
      <S.FullScreen>
        <S.FormCenter
          onSubmit={
            !personType
              ? handleSubmit(handleSave)
              : handleSubmitPJ(handleSubmitPjForm)
          }
        >
          <S.ButtonContainer onClick={onBackPress}>
            <img src={ArrowBackIcon} alt="Arrow_Back_Icon" />
            <S.ButtonText>{labels.goBack}</S.ButtonText>
          </S.ButtonContainer>
          <S.PageTitle data-cy="singUpTitle">
            {t("signUp.registration")}
          </S.PageTitle>
          <S.PageSubtitle>
            {!personType
              ? t("signUp.editGeneralInfo")
              : t("signUp.editCompanyInfo")}
          </S.PageSubtitle>
          <S.TextInfo>{t("signUp.basicInfo")}</S.TextInfo>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <S.FormControlTooltipWrapper>
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    color="error"
                    onChange={() => handleRadio(0)}
                    checked={personType === 0}
                  />
                }
                label={t("signUp.wantToBeHired")}
              />
            </S.FormControlTooltipWrapper>

            <S.FormControlTooltipWrapper>
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    color="error"
                    onChange={() => handleRadio(1)}
                    checked={personType === 1}
                    data-cy="ScreenSignUpCompanyRegister"
                  />
                }
                label={t("signUp.wantToHire")}
              />
            </S.FormControlTooltipWrapper>
          </RadioGroup>

          {!personType ? (
            <>
              <S.Row>
                <Controller
                  {...inputParams(control, "avatarId")}
                  render={({ field }) => (
                    <AvatarInput
                      legend={t("signUp.addProfilePhoto")}
                      value={field.value}
                      onChange={field.onChange}
                      signUp
                    />
                  )}
                />
                <S.RemainSpace>
                  <S.InputRow rowsSize={[1, 1]}>
                    <S.ContainerGap>
                      <Controller
                        {...inputParams(control, "name")}
                        render={({ field }) => (
                          <S.FormMui error={Boolean(errors?.name?.message)}>
                            <S.Label>{t("signUp.firstName")}</S.Label>
                            <S.CssTextField
                              data-cy="ScreenSignUpName"
                              {...renderParams(field)}
                              variant="outlined"
                              error={!!errors?.name?.message}
                              placeholder={t("signUp.firstName")}
                              value={field.value}
                              inputProps={{
                                maxLength: "30",
                              }}
                              sx={{
                                border:
                                  errors?.name?.message?.length > 0
                                    ? "1px solid #F5222D"
                                    : "1px solid #C4C4C4",
                              }}
                            />
                            {errors?.name?.message && (
                              <FormHelperText
                                sx={{ marginLeft: "0", fontSize: "14px" }}
                                data-cy="errorMessageName"
                              >
                                {errors?.name?.message}
                              </FormHelperText>
                            )}
                          </S.FormMui>
                        )}
                      />
                    </S.ContainerGap>
                    <S.ContainerGap>
                      <Controller
                        {...inputParams(control, "lastName")}
                        render={({ field }) => (
                          <S.FormMui error={Boolean(errors?.lastName?.message)}>
                            <S.Label>{t("signUp.lastName")}</S.Label>
                            <S.CssTextField
                              data-cy="ScreenSignUpLastName"
                              {...renderParams(field)}
                              variant="outlined"
                              error={!!errors?.lastName?.message}
                              placeholder={t("signUp.lastName")}
                              value={field.value}
                              inputProps={{
                                maxLength: "30",
                              }}
                              sx={{
                                border:
                                  errors?.lastName?.message?.length > 0
                                    ? "1px solid #F5222D"
                                    : "1px solid #C4C4C4",
                              }}
                            />
                            {errors?.lastName?.message && (
                              <FormHelperText
                                data-cy="errorMessageLastName"
                                sx={{ marginLeft: "0", fontSize: "14px" }}
                              >
                                {errors?.lastName?.message}
                              </FormHelperText>
                            )}
                          </S.FormMui>
                        )}
                      />
                    </S.ContainerGap>
                  </S.InputRow>
                  <S.ContainerGap>
                    <Controller
                      {...inputParams(control, "email")}
                      render={({ field }) => (
                        <S.FormMui error={Boolean(errors?.email?.message)}>
                          <S.Label>
                            {t("signUp.emailInput")}
                            <Tooltip
                              sx={{ fontSize: "14px" }}
                              arrow
                              title={t("users.keyEmail")}
                              placement="top"
                            >
                              <InfoOutlinedIcon />
                            </Tooltip>
                          </S.Label>
                          <S.CssTextField
                            data-cy="ScreenSignUpEmail"
                            {...renderParams(field)}
                            variant="outlined"
                            error={!!errors?.email?.message}
                            placeholder={t("signUp.email")}
                            sx={{
                              border:
                                errors?.email?.message?.length > 0
                                  ? "1px solid #F5222D"
                                  : "1px solid #C4C4C4",
                            }}
                          />
                          {errors?.email?.message && (
                            <FormHelperText
                              data-cy="errorMessageEmail"
                              sx={{ marginLeft: "0", fontSize: "14px" }}
                            >
                              {errors?.email?.message}
                            </FormHelperText>
                          )}
                        </S.FormMui>
                      )}
                    />
                  </S.ContainerGap>
                  <S.InputRow rowsSize={[1, 1]}>
                    <S.ContainerGap>
                      <Controller
                        {...inputParams(control, "password")}
                        render={({ field }) => (
                          <S.FormMui error={Boolean(errors?.password?.message)}>
                            <S.Label>{t("signUp.password")}</S.Label>
                            <S.CssTextField
                              data-cy="ScreenSignUpPassword"
                              {...renderParams(field)}
                              variant="outlined"
                              type={showPassword ? "text" : "password"}
                              error={!!errors?.password?.message}
                              placeholder={t("signUp.password")}
                              sx={{
                                border:
                                  errors?.password?.message?.length > 0
                                    ? "1px solid #F5222D"
                                    : "1px solid #C4C4C4",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {errors?.password?.message && (
                              <FormHelperText
                                data-cy="errorMessagePassword"
                                sx={{ marginLeft: "0", fontSize: "14px" }}
                              >
                                {errors?.password?.message}
                              </FormHelperText>
                            )}
                          </S.FormMui>
                        )}
                      />
                    </S.ContainerGap>
                    <S.ContainerGap>
                      <Controller
                        {...inputParams(control, "confirmPassword")}
                        render={({ field }) => (
                          <S.FormMui
                            error={Boolean(errors?.confirmPassword?.message)}
                          >
                            <S.Label>{t("signUp.confirmPassword")}</S.Label>
                            <S.CssTextField
                              data-cy="ScreenSignUpConfirmPassword"
                              {...renderParams(field)}
                              variant="outlined"
                              type={showConfirmPassword ? "text" : "password"}
                              error={!!errors?.confirmPassword?.message}
                              placeholder={t("signUp.confirmPassword")}
                              sx={{
                                border:
                                  errors?.confirmPassword?.message?.length > 0
                                    ? "1px solid #F5222D"
                                    : "1px solid #C4C4C4",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {errors?.confirmPassword?.message && (
                              <FormHelperText
                                data-cy="errorMessageConfirmPassword"
                                sx={{ marginLeft: "0", fontSize: "14px" }}
                              >
                                {errors?.confirmPassword?.message}
                              </FormHelperText>
                            )}
                          </S.FormMui>
                        )}
                      />
                    </S.ContainerGap>
                  </S.InputRow>
                  <S.containerTermosDeUso>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={receiveEmails}
                          onChange={() =>
                            setReceiveEmails((prevState) => !prevState)
                          }
                        />
                      }
                      label={
                        <Typography data-cy="ScreenSignUpReceiveEmails">
                          {t("signUp.receiveEmails")}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedUseTerms}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography data-cy="ScreenSignUpTerms">
                          {t("signUp.readAndAccept")}
                          <a
                            href="https://skillhunter.com.br/use-terms.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("signUp.termsOfUse")}
                          </a>{" "}
                          {t("signUp.andThe")}
                          <a
                            href="https://skillhunter.com.br/privacy-policy.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("signUp.privacyPolicy")}
                          </a>
                        </Typography>
                      }
                    />
                  </S.containerTermosDeUso>
                </S.RemainSpace>
              </S.Row>
              <S.InputRow rowsSize={[1, 2]}></S.InputRow>
            </>
          ) : (
            <>
              <S.RemainSpace>
                <S.InputRow rowsSize={[1]}>
                  <Controller
                    name="country"
                    control={controlPJ}
                    render={({ field: { name, value, onChange } }) => (
                      <S.FormMui error={Boolean(errorsPJ?.country?.message)}>
                        <CountrySelect
                          selected={value}
                          onSelect={(newValue) => {
                            handleChangeCountry(newValue);
                            onChange(newValue);
                          }}
                          placeholder={t("empresas.country")}
                          label={t("empresas.country")}
                          options={countries}
                        />
                        {errorsPJ?.country?.message && (
                          <FormHelperText
                            data-cy="errorMessageAnswerable"
                            sx={{ marginLeft: "0", fontSize: "14px" }}
                          >
                            {errorsPJ?.country?.message}
                          </FormHelperText>
                        )}
                      </S.FormMui>
                    )}
                  />
                </S.InputRow>
                <S.InputRow rowsSize={[1, 1]}>

                <S.ContainerGap>
                    <Controller
                      {...inputParams(
                        controlPJ,
                        countrySelected?.id === 10 ? "cuit" : "cnpj"
                      )}
                      render={({ field }) => (
                        <S.FormMui
                          error={Boolean(
                            countrySelected?.id === 10
                              ? errorsPJ?.cuit?.message
                              : errorsPJ?.cnpj?.message
                          )}
                        >
                          <S.Label>
                            {countrySelected?.id === 10 ? "CUIT" : "CNPJ"}
                          </S.Label>
                          <S.CssTextField
                            data-cy="ScreenSignUpCnpj"
                            {...renderParams(field)}
                            variant="outlined"
                            onChange={(value) => {
                              countrySelected?.id === 10
                                ? field.onChange(cuitMask(value?.target.value))
                                : field.onChange(cnpjMask(value?.target.value))
                            }}
                            onBlur={(value) => { onBlurCNPJ(value?.target.value)}}
                            error={
                              countrySelected?.id === 10
                                ? !!errorsPJ?.cuit?.message
                                : !!errorsPJ?.cnpj?.message
                            }
                            placeholder={
                              countrySelected?.id === 10 ? "CUIT" : "CNPJ"
                            }
                            sx={{
                              border:
                                errorsPJ?.cuit?.message ||
                                errorsPJ?.cnpj?.message
                                  ? "1px solid #F5222D"
                                  : "1px solid #C4C4C4",
                            }}
                            inputProps={{
                              maxLength: countrySelected?.id === 10 ? 13 : 18,
                            }}
                          />
                          {(errorsPJ?.cuit?.message ||
                            errorsPJ?.cnpj?.message) && (
                            <FormHelperText
                              data-cy="errorMessageCnpj"
                              sx={{ marginLeft: "0", fontSize: "14px" }}
                            >
                              {countrySelected?.id === 10
                                ? errorsPJ?.cuit?.message
                                : errorsPJ?.cnpj?.message}
                            </FormHelperText>
                          )}
                        </S.FormMui>
                      )}
                    />
                  </S.ContainerGap>

                  <S.ContainerGap>
                    <Controller
                      {...inputParams(controlPJ, "name")}
                      render={({ field }) => (
                        <S.FormMui error={Boolean(errorsPJ?.name?.message)}>
                          <S.Label>{t("signUp.companyName")}</S.Label>
                          <S.CssTextField
                            data-cy="ScreenSignUpNameCompany"
                            {...renderParams(field)}
                            variant="outlined"
                            error={!!errorsPJ?.name?.message}
                            placeholder={t("signUp.companyName")}
                            value={field.value}
                            sx={{
                              border: errorsPJ?.name?.message
                                ? "1px solid #F5222D"
                                : "1px solid #C4C4C4",
                            }}
                          />
                          {errorsPJ?.name?.message && (
                            <FormHelperText
                              data-cy="errorMessageNameCompany"
                              sx={{ marginLeft: "0", fontSize: "14px" }}
                            >
                              {errorsPJ?.name?.message}
                            </FormHelperText>
                          )}
                        </S.FormMui>
                      )}
                    />
                  </S.ContainerGap>
                </S.InputRow>
                <S.InputRow rowsSize={[1, 1]}>
                  <S.ContainerGap>
                    <Controller
                      {...inputParams(controlPJ, "email")}
                      render={({ field }) => (
                        <S.FormMui error={Boolean(errorsPJ?.email?.message)}>
                          <S.Label>{t("signUp.companyEmail")}</S.Label>
                          <S.CssTextField
                            data-cy="ScreenSignUpEmailCompany"
                            {...renderParams(field)}
                            variant="outlined"
                            error={!!errorsPJ?.email?.message}
                            placeholder={t("signUp.email")}
                            sx={{
                              border: errorsPJ?.email?.message
                                ? "1px solid #F5222D"
                                : "1px solid #C4C4C4",
                            }}
                          />
                          {errorsPJ?.email?.message && (
                            <FormHelperText
                              data-cy="errorMessageEmailCompany"
                              sx={{ marginLeft: "0", fontSize: "14px" }}
                            >
                              {errorsPJ?.email?.message}
                            </FormHelperText>
                          )}
                        </S.FormMui>
                      )}
                    />
                  </S.ContainerGap>
                  <S.ContainerGap>
                    <S.Label>{t("signUp.phoneNumber")}</S.Label>
                    <Controller
                      {...inputParams(controlPJ, "phone")}
                      render={({ field }) => (
                        <PhoneInput
                          data-cy="ScreenSignUpPhoneCompany"
                          {...renderParams(field)}
                          country={countrySelected?.id === 10 ? "AR" : "BR"}
                          defaultCountry="BR"
                          placeholder={t("signUp.phoneNumber")}
                          style={{
                            width: "100%",
                            height: "3.5rem",
                            border: errorsPJ?.phone?.message
                              ? "2px solid #F5222D"
                              : "2px solid #C4C4C4",
                            borderRadius: "5px",
                            fontSize: "16px",
                            padding: "0 1rem",
                            fontWeight: "500",
                            outline: "none",
                          }}
                        />
                      )}
                    />
                    <S.ErrorMessage data-cy="errorMessagePhoneCompany">
                      {errorsPJ?.phone?.message}
                    </S.ErrorMessage>
                  </S.ContainerGap>
                </S.InputRow>
                <S.InputRow rowsSize={[1, 1]}>
                  <S.ContainerGap>
                    <Controller
                      {...inputParams(controlPJ, "corporateName")}
                      render={({ field }) => (
                        <S.FormMui
                          error={Boolean(errorsPJ?.corporateName?.message)}
                        >
                          <S.Label>{t("signUp.corporateName")}</S.Label>
                          <S.CssTextField
                            data-cy="ScreenSignUpCorporateName"
                            {...renderParams(field)}
                            variant="outlined"
                            error={!!errorsPJ?.corporateName?.message}
                            placeholder={t("signUp.corporateName")}
                            value={field.value}
                            sx={{
                              border: errorsPJ?.corporateName?.message
                                ? "1px solid #F5222D"
                                : "1px solid #C4C4C4",
                            }}
                          />
                          {errorsPJ?.corporateName?.message && (
                            <FormHelperText
                              data-cy="errorMessageCorporateName"
                              sx={{ marginLeft: "0", fontSize: "14px" }}
                            >
                              {errorsPJ?.corporateName?.message}
                            </FormHelperText>
                          )}
                        </S.FormMui>
                      )}
                    />
                  </S.ContainerGap>
                  
                  <S.ContainerGap>
                    <Controller
                      {...inputParams(controlPJ, "answerable")}
                      render={({ field }) => (
                        <S.FormMui
                          error={Boolean(errorsPJ?.answerable?.message)}
                        >
                          <S.Label>{t("signUp.answerable")}</S.Label>
                          <S.CssTextField
                            data-cy="ScreenSignUpAnswerable"
                            {...renderParams(field)}
                            variant="outlined"
                            error={!!errorsPJ?.answerable?.message}
                            placeholder={t("signUp.answerable")}
                            value={field.value}
                            sx={{
                              border: errorsPJ?.answerable?.message
                                ? "1px solid #F5222D"
                                : "1px solid #C4C4C4",
                            }}
                          />
                          {errorsPJ?.answerable?.message && (
                            <FormHelperText
                              data-cy="errorMessageAnswerable"
                              sx={{ marginLeft: "0", fontSize: "14px" }}
                            >
                              {errorsPJ?.answerable?.message}
                            </FormHelperText>
                          )}
                        </S.FormMui>
                      )}
                    />
                  </S.ContainerGap>
                  
                </S.InputRow>
              </S.RemainSpace>

              <S.containerTermosDeUso>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedUseTerms}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <Typography data-cy="ScreenSignUpTerms">
                      Li e aceito os{" "}
                      <a
                        href={
                          personType === 0
                            ? "https://skillhunter.com.br/use-terms.html"
                            : "https://skillhunter.com.br/company-use-terms.html"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Termos de Uso
                      </a>{" "}
                      e as{" "}
                      <a
                        href="https://skillhunter.com.br/privacy-policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Políticas de Privacidade
                      </a>
                    </Typography>
                  }
                />
              </S.containerTermosDeUso>
            </>
          )}

          <S.ContainerButton>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
            />
            <S.buttonPink
              data-cy="ScreenSignUpButtonCreate"
              disabled={createDisabled || !isCheckedUseTerms}
            >
              {!personType
                ? t("signUp.createAccount")
                : t("signUp.requestDemonstration")}
            </S.buttonPink>
          </S.ContainerButton>
        </S.FormCenter>
      </S.FullScreen>
      {showModal && (
        <EmailVerify
          openModal={showModal}
          onClose={handleClose}
          emailparam={email || ""}
        />
      )}

      {showModalPJ && (
        <ContactPJ
          openModal={showModalPJ}
          onClose={() => setShowModalPJ(false)}
        />
      )}
    </>
  );
}
