import api from "./api";

export async function getTransactions(page: number, size: number): Promise<any> {
  const response = await api.get(`v2/transactions/?page=${page}&size=${size}`);
  return response?.data;
}

export async function sendCustomValue(value): Promise<any> {
  const response = await api.post(`v2/payment/custom-unlock?quantity=${value}`);
  return response?.data;
}
