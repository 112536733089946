import styled from "styled-components";

export const ContainerGap = styled.div`
  margin-top: 2.3rem;

  p {
    font-size: 14px;
  }
`;

export const Form = styled.form`
  button[type="button"] {
    margin-top: 2.3rem;
  }
`;
