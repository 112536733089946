import { Button } from "@mui/material";
import styled, {css} from "styled-components";
import backgroundImage from "../../assets/backgroundSkillTree.png";

interface Props {
  subscribed?: boolean;
  hoverSubscribe?: boolean;
  up?: boolean;
}

export const DescriptionOfPosition = styled.p.attrs(
  (props: { open: boolean }) => props
)`
  width: 100%;
  max-width: 260ch;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 25px;
  color: #696969;
  overflow-wrap: break-word;

  ${(props) =>
  !props.open &&
  css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    `}
`;

export const SeeMoreButtonDiv = styled.div`
  display: flex;
  justify-content: center;

  button {
    text-decoration: underline #ff026f;
  }
`;
export const ContainerIconTreeWithCompetences = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`;

export const NumberSkillTree = styled.span`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const FooterItemLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #131212;
`;

export const containerCompetences = styled.div`
  padding-left: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

export const ButtonAdd = styled(Button)`
  padding-right: 50px;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  color: white !important;
  font-family: "Ubuntu" !important;
`;

export const ContainerButton = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  display: none;
  width: 196px;
  @media (max-width: 767px) {
    display: inherit;
    width: 100%;
    margin-bottom: 23px;
  }

  & button {
    text-transform: none;
  }
`;

export const ContainerTotal = styled.div`
  padding: 2.5rem 4rem;
  display: flex;
  flex-direction: column;
  width: 1300px;
  max-width: 100%;
  @media screen and (max-width: 767px) {
    padding: 2.5rem 1rem;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
`;

export const ContainerButtonAndTitle = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const TitlePosition = styled.h1`
  margin-top: 23px;
  margin-bottom: 23px;
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 36px;
  line-height: 32px;
  color: #131212;
`;

export const TitleAboutPosition = styled.h4`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #131212;
  margin-bottom: 14px;
`;

export const DescriptionPosition = styled.div`
  font-family: "Ubuntu";
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #696969;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  display: flex;
  flex-direction: column;

  iframe {
    display: flex;
    align-self: center;
    max-width: 100%;
    max-height: 400px;
    margin: 16px 0;
  }

  ul,
  ol {
    margin-left: 20px;
  }
`;

export const ContainerAccordions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  overflow: hidden;
`;

export const ContainerAccordionSummary = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 6px 0;
`;

export const ContainerAccordionSummaryLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`;

export const AccordionSummaryCircleImg = styled.div<{ color?: string }>`
  width: 36px;
  height: 36px;
  background-color: ${({ color }) => color};
  border-radius: 999px;
  position: relative;
`;

export const AccordionSummaryImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
`;

export const AccordionSummarySetaImg = styled.img<{ up?: boolean }>`
  width: 20px;
  margin-right: 1%;
  transform: ${({ up }) => (up ? "" : "rotate(180deg)")};
`;

export const ContainerAccordionSummaryLeftTexts = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleAccordionSummary = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #131212;
`;

export const LoadingText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #131212;
`;

export const SubtitleAccordionSummary = styled.p<{ color?: string }>`
  font-size: 16px;
  font-weight: 500;
  color: ${({ color }) => color};
`;

export const ContainerAccordionDetails = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  min-width: 302px;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  padding: 0;
  background: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position-y: center;
`;

export const ContainerAccordionDetailsLoading = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px 16px 16px;
  gap: 12px;
  background: #fff0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CategoriesPosition = styled.h3`
  font-family: "Ubuntu";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #8c8c8c;
  margin-bottom: 23px;
`;

export const EmployPosition = styled.div`
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextCompany = styled.h2`
  font-family: "Ubuntu";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  //text-decoration-line: underline;
  //color: #ff026f;
`;

export const ImageCompany = styled.img`
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
`;

export const ContainerProgressCard = styled.div`
  padding: 1.75rem 2rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin: 2rem 0;
  border: 1px solid #c4c4c4;
  gap: 1rem;
`;

export const ContainerProgressStarText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
`;

export const ProgressTitleSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-right: 15px;
`;

export const Title = styled.h1`
  color: #131212;
  font-size: 1.25rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const Subtitle = styled.p`
  color: #8c8c8c;
  font-size: 0.875rem;
  font-weight: 400;
`;

export const PercentText = styled.p`
  color: #ff026f;
  font-weight: 700;
  font-size: 1.5rem;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
`;

export const ProgressSubtitle = styled.p`
  color: #696969;
  font-size: 0.875rem;
  font-weight: 300;
`;

export const ContainerTitlePercent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const CardTitle = styled.p<{ color: any }>`
  font-size: 1rem;
  text-align: left;
  font-weight: 500;
  color: ${({ color }) => color || "#131212"};
`;;

export const WithoutSkills = styled.p`
  color: #131212;
  font-size: 1.125rem;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
`;

export const MiniButtonContainer = styled.div`
  width: 196px;
  @media screen and (max-width: 767px) {
    display: none;
  }

  button {
    text-transform: none;
  }
`;

export const ButtonConclude = styled.button<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 65px;
  gap: 10px;

  width: ${({ mobile }) => (mobile ? "100%" : "196px")};
  height: 52px;

  background: #00a676;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  text-align: center;
  color: #ffffff;
`;
