import {
  Alert,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StyledLink, Subtitle } from "../../Global/Typography";
import FormationProgressRow from "./localComponents/FormationProgressRow";

import { BusinessCenter, School } from "@mui/icons-material";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlternateLocalButton } from "../../Global/Button";
import { Formation } from "../../models/Formation";
import { ILastAccessedFormation } from "../../models/LastAccessedFormation";
import { ILastAccessedTrail } from "../../models/LastAccessedTrail";
import { Trail } from "../../models/Trail";
import IUserStatistic from "../../models/UserStatistic";
import FormationService from "../../services/FormationService";
import ReportService from "../../services/ReportService";
import TrailService from "../../services/TrailService";
import ContinueWhereYouLeft from "./localComponents/ContinueWhereYouLeft";
import FormationProgress from "./localComponents/FormationProgress";
import UserStatisticsCard from "./localComponents/UserStatistics";
import * as S from "./style";

function ClientDashboard() {
  const theme = useTheme();
  const lowerThanMD = useMediaQuery(theme.breakpoints.down("md"));
  const [bestFitFormations, setBestFitFormations] = useState<Formation[]>([]);
  const [bestFitTrails, setBestFitTrails] = useState<Trail[]>([]);
  const [summaryProgressTrails, setSummaryProgressTrails] = useState<Trail[]>(
    []
  );
  const [lastAccessedFormation, setLastAccessedFormation] =
    useState<ILastAccessedFormation>(null);
  const [lastAccessedTrail, setLastAccessedTrail] =
    useState<ILastAccessedTrail>(null);
  const [summaryProgressFormations, setSummaryProgressFormations] = useState<
    Formation[]
  >([]);
  const [userStatisticsData, setUserStatisticsData] = useState<
    IUserStatistic[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progressDataType, setProgressDataType] = useState<
    "trail" | "position"
  >("position");

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const fetchPendingData = async () => {
      const promises = [
        await ReportService.getUserBestFitFormationsData(),
        await ReportService.getUserBestFitTrailsData(),
        await FormationService.getFormationProgress(0, lowerThanMD ? 3 : 6),
        await FormationService.getLastAccessedFormation(),
        await ReportService.getUserDashboardStatistics(),
      ];

      return Promise.all(promises);
    };

    const [
      bestFitFormationsData,
      bestFitTrailsData,
      formationProgressData,
      lastAccessedFormationData,
      userDashboardStatisticsData,
    ] = await fetchPendingData();

    setBestFitFormations(bestFitFormationsData);
    setBestFitTrails(bestFitTrailsData);
    setSummaryProgressFormations(formationProgressData);
    setLastAccessedFormation(lastAccessedFormationData);
    setUserStatisticsData(userDashboardStatisticsData);

    setIsLoading(false);
  }, []);

  const fetchProgressData = useCallback(async (type) => {
    setIsLoading(true);

    if (type === "position") {
      const data = await FormationService.getFormationProgress(
        0,
        lowerThanMD ? 3 : 6
      );
      setSummaryProgressFormations(data);
    } else {
      const data = await TrailService.getTrailProgress(0, lowerThanMD ? 3 : 6);
      setSummaryProgressTrails(data);
    }

    setIsLoading(false);
  }, []);

  const fetchLastAccessedData = useCallback(async (type) => {
    setIsLoading(true);

    if (type === "position") {
      const data = await FormationService.getLastAccessedFormation();
      setLastAccessedFormation(data);
    } else {
      const data = await TrailService.getLastAccessedTrail();
      setLastAccessedTrail(data);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchProgressData(progressDataType);
    fetchLastAccessedData(progressDataType);
  }, [progressDataType]);

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  const isTrail = () => {
    return progressDataType === "trail";
  };

  return (
    <Container maxWidth={"lg"}>
      <Typography marginTop={2} marginBottom={2} variant="h1">
        {t("clientDashboard.dashboard")}
      </Typography>
      <Subtitle>{t("clientDashboard.checkYourProgressSubtitle")}</Subtitle>
      <Grid marginY={3} container columnSpacing={2} rowSpacing={2}>
        {lowerThanMD && (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <S.DashboardContinueCard>
              <Box
                borderBottom={"1px solid #aeaeae"}
                paddingLeft={"20px"}
                paddingRight={"20px"}
                paddingBottom={
                  isTrail()
                    ? summaryProgressTrails.length > 0
                      ? "inherit"
                      : 2
                    : summaryProgressFormations.length > 0
                    ? "inherit"
                    : 2
                }
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={500}>
                      {t("clientDashboard.continueWhereYouLeft")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <School
                        style={{
                          color: progressDataType === "trail" ? "#ff026f" : "",
                        }}
                        color="primary"
                      />
                      <S.CustomSwitch
                        onChange={() =>
                          setProgressDataType(
                            progressDataType === "position"
                              ? "trail"
                              : "position"
                          )
                        }
                        checked={progressDataType === "position"}
                      />
                      <BusinessCenter
                        color="primary"
                        style={{
                          color:
                            progressDataType === "position" ? "#ff026f" : "",
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={1}>
                    <></>
                  </Grid>
                </Grid>
                {(!isLoading &&
                  ((progressDataType === "position" &&
                    summaryProgressFormations.length === 0) ||
                    (progressDataType === "trail" &&
                      summaryProgressTrails.length === 0)) && (
                    <Alert severity="info">
                      <Typography variant="body2" fontWeight={500}>
                        {t(
                          "clientDashboard.noDataTexts.continueWhereYouLeft.title"
                        )}
                      </Typography>
                      <Typography marginTop={2}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "clientDashboard.noDataTexts.continueWhereYouLeft.text"
                            ),
                          }}
                        ></div>
                        <StyledLink to={"/home"}>
                          {t(
                            "clientDashboard.noDataTexts.continueWhereYouLeft.link"
                          )}
                        </StyledLink>
                      </Typography>
                    </Alert>
                  )) || (
                  <ContinueWhereYouLeft
                    isLoading={isLoading}
                    formation={lastAccessedFormation}
                    trail={lastAccessedTrail}
                    dataType={progressDataType}
                  />
                )}
              </Box>
            </S.DashboardContinueCard>
          </Grid>
        )}
        <Grid
          columnSpacing={2}
          rowSpacing={2}
          item
          container
          xl={6}
          md={6}
          sm={12}
          xs={12}
        >
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <S.DashboardCard height={"100%"}>
              <Typography variant="body1" fontWeight={500}>
                {!isTrail() ?
                    t("clientDashboard.recommendationsText") :
                    t("clientDashboard.recommendationsTextTrails")}
              </Typography>
              <Box
                marginTop={3}
                marginBottom={
                  summaryProgressFormations.length === 0 && !isLoading
                    ? 2
                    : "inherit"
                }
              >
                {(((bestFitFormations?.length === 0 && !isTrail()) || (bestFitTrails?.length === 0 && isTrail())) && !isLoading && (
                  <Alert severity="info">
                    <Typography variant="body2" fontWeight={500}>
                      {t("clientDashboard.noDataTexts.recommendations.title")}
                    </Typography>
                    <Typography marginTop={2}>
                      <StyledLink style={{ marginTop: 20 }} to={"/home"}>
                        {t("clientDashboard.noDataTexts.recommendations.link")}
                      </StyledLink>{" "}
                      {isTrail() ? t("clientDashboard.noDataTexts.recommendations.textTrails") : t("clientDashboard.noDataTexts.recommendations.text")}
                    </Typography>
                  </Alert>
                )) || (
                  <FormationProgress
                    formations={bestFitFormations}
                    trails={bestFitTrails}
                    isLoading={isLoading}
                    dataType={isTrail() ? "trail" : "position"}
                  />
                )}
              </Box>
            </S.DashboardCard>
          </Grid>
          {lowerThanMD && (
            <>
              {(isTrail() &&
                summaryProgressTrails.length === 0 &&
                !isLoading) ||
                (summaryProgressFormations.length === 0 && !isLoading && (
                  <></>
                )) || (
                  <Grid item xl={12} lg={12} sm={12} xs={12}>
                    <S.DashboardCard height={"100%"}>
                      <Typography variant="body1" fontWeight={500}>
                        {t("clientDashboard.generalProgress")}
                      </Typography>
                      <Grid marginTop={3} container>
                        {(isLoading && (
                          <>
                            <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                              <FormationProgressRow
                                halfSize={!lowerThanMD}
                                isLoading
                                dataType={progressDataType}
                              />
                            </Grid>
                            <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                              <FormationProgressRow
                                halfSize={!lowerThanMD}
                                isLoading
                                dataType={progressDataType}
                              />
                            </Grid>
                            <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                              <FormationProgressRow
                                halfSize={!lowerThanMD}
                                isLoading
                                dataType={progressDataType}
                              />
                            </Grid>
                          </>
                        )) ||
                        isTrail()
                          ? summaryProgressTrails.map((trail) => (
                              <Grid item lg={6} xl={6} md={6} sm={12}>
                                <FormationProgressRow
                                  halfSize={!lowerThanMD}
                                  trail={trail}
                                  dataType={progressDataType}
                                />
                              </Grid>
                            ))
                          : summaryProgressFormations.map((formation) => (
                              <Grid item lg={6} xl={6} md={6} sm={12}>
                                <FormationProgressRow
                                  halfSize={!lowerThanMD}
                                  formation={formation}
                                  dataType={progressDataType}
                                />
                              </Grid>
                            ))}
                      </Grid>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        paddingY={4}
                      >
                        <Grid item sm={4}>
                          <AlternateLocalButton
                            onClick={() =>
                              isTrail()
                                ? navigate("/course-list")
                                : navigate("/position-list")
                            }
                          >
                            {t("clientDashboard.exploreSubscriptions")}
                          </AlternateLocalButton>
                        </Grid>
                      </Box>
                    </S.DashboardCard>
                  </Grid>
                )}
            </>
          )}
          <Grid item xl={12} lg={12} sm={12} xs={12}>
            <S.DashboardCard
              height={"100%"}
              marginBottom={
                summaryProgressFormations.length === 0 && !isLoading
                  ? 2
                  : "inherit"
              }
            >
              <Typography variant="body1" fontWeight={500}>
                {t("clientDashboard.statistics")}
              </Typography>
              {(summaryProgressFormations?.length === 0 && summaryProgressTrails?.length === 0 && !isLoading && (
                <Alert severity="info">
                  <Typography variant="body2" fontWeight={500}>
                    {t("clientDashboard.noDataTexts.statistics.title")}
                  </Typography>
                  <Typography marginTop={2}>
                    {t("clientDashboard.noDataTexts.statistics.text")}
                  </Typography>
                </Alert>
              )) || (
                <UserStatisticsCard
                  isLoading={isLoading}
                  statistics={userStatisticsData}
                />
              )}
            </S.DashboardCard>
          </Grid>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          {!lowerThanMD && (
            <S.DashboardContinueCard>
              <Box
                borderBottom={"1px solid #aeaeae"}
                paddingLeft={"20px"}
                paddingRight={"20px"}
                paddingBottom={
                  (progressDataType === "position" &&
                    summaryProgressFormations.length > 0) ||
                  (progressDataType === "trail" &&
                    summaryProgressTrails.length > 0)
                    ? "inherit"
                    : 2
                }
              >
                <Grid container justifyContent={"space-between"}>
                  <Grid item md={5}>
                    <Typography variant="body1" fontWeight={500}>
                      {t("clientDashboard.continueWhereYouLeft")}
                    </Typography>
                  </Grid>
                  <Grid item md={6} display={"flex"} justifyContent={"end"}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <School
                        style={{
                          color: progressDataType === "trail" ? "#ff026f" : "",
                        }}
                        color="primary"
                      />
                      <S.CustomSwitch
                        disabled={isLoading}
                        onChange={() =>
                          setProgressDataType(
                            progressDataType === "position"
                              ? "trail"
                              : "position"
                          )
                        }
                        checked={progressDataType === "position"}
                      />
                      <BusinessCenter
                        color="primary"
                        style={{
                          color:
                            progressDataType === "position" ? "#ff026f" : "",
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item md={1}>
                    <></>
                  </Grid>
                </Grid>

                {(((progressDataType === "position" &&
                  summaryProgressFormations.length === 0) ||
                  (progressDataType === "trail" &&
                    summaryProgressTrails.length === 0)) &&
                  !isLoading && (
                    <Alert severity="info">
                      <Typography variant="body2" fontWeight={500}>
                        {t(
                          "clientDashboard.noDataTexts.continueWhereYouLeft.title"
                        )}
                      </Typography>
                      <Typography marginTop={2}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "clientDashboard.noDataTexts.continueWhereYouLeft.text"
                            ),
                          }}
                        ></div>
                        <StyledLink to={"/home"}>
                          {t(
                            "clientDashboard.noDataTexts.continueWhereYouLeft.link"
                          )}
                        </StyledLink>
                      </Typography>
                    </Alert>
                  )) || (
                  <ContinueWhereYouLeft
                    isLoading={isLoading}
                    formation={lastAccessedFormation}
                    trail={lastAccessedTrail}
                    dataType={progressDataType}
                  />
                )}
              </Box>

              {(((progressDataType === "position" &&
                summaryProgressFormations.length === 0) ||
                (progressDataType === "trail" &&
                  summaryProgressTrails.length === 0)) &&
                !isLoading && <></>) || (
                <Box
                  borderBottom={"1px solid #aeaeae"}
                  paddingLeft={"20px"}
                  marginTop={3}
                >
                  <Grid container>
                    {(isLoading && (
                      <>
                        <Grid item lg={6} xl={6} md={6} sm={12}>
                          <FormationProgressRow
                            halfSize={!lowerThanMD}
                            isLoading
                            dataType={progressDataType}
                          />
                        </Grid>
                        <Grid item lg={6} xl={6} md={6} sm={12}>
                          <FormationProgressRow
                            halfSize={!lowerThanMD}
                            isLoading
                            dataType={progressDataType}
                          />
                        </Grid>
                        <Grid item lg={6} xl={6} md={6} sm={12}>
                          <FormationProgressRow
                            halfSize={!lowerThanMD}
                            isLoading
                            dataType={progressDataType}
                          />
                        </Grid>
                      </>
                    )) || (
                      <>
                        {isTrail()
                          ? summaryProgressTrails.map((trail) => (
                              <Grid item lg={6} xl={6} md={6} sm={12}>
                                <FormationProgressRow
                                  halfSize={!lowerThanMD}
                                  trail={trail}
                                  dataType={progressDataType}
                                />
                              </Grid>
                            ))
                          : summaryProgressFormations.map((formation) => (
                              <Grid item lg={6} xl={6} md={6} sm={12}>
                                <FormationProgressRow
                                  halfSize={!lowerThanMD}
                                  formation={formation}
                                  dataType={progressDataType}
                                />
                              </Grid>
                            ))}
                      </>
                    )}
                  </Grid>
                </Box>
              )}

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                paddingY={4}
              >
                <Grid item md={5}>
                  <AlternateLocalButton
                    onClick={() =>
                      isTrail()
                        ? navigate("/course-list")
                        : navigate("/position-list")
                    }
                  >
                    {t("clientDashboard.exploreSubscriptions")}
                  </AlternateLocalButton>
                </Grid>
              </Box>
            </S.DashboardContinueCard>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ClientDashboard;
