import { ChangeEvent, useEffect, useState } from "react";
import api from "../../../services/api";
import { zodResolver } from "@hookform/resolvers/zod";
import zod from "zod";
import { Controller, useForm } from "react-hook-form";
import * as S from "./styles";
import {
  InputDefault,
  Button as ButtonDS,
  Button,
} from "design-system-firedev";
import { ResponsiveInputText } from "../../../components/ResponsiveInputText";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";

export interface ICategory {
  id: string;
  name: string;
}

interface ICategoriesForm {
  onBackPress: CallableFunction;
  feedbackAction: CallableFunction;
  dataToEdit: ICategory | undefined;
}

export default function CategoriesForm({
  onBackPress,
  feedbackAction,
  dataToEdit,
}: ICategoriesForm) {
  const isEditing = typeof dataToEdit !== "undefined";

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get(`trail_category/${dataToEdit.id}`);
      setValue("name", result.data.name);
    };
    if (isEditing) {
      fetchData();
    }
  }, [dataToEdit]);

  const newTrailCategoryFormSchema = zod.object({
    name: zod.string().min(1, t('categories.validName')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICategory>({
    resolver: zodResolver(newTrailCategoryFormSchema),
  });

  const handleCreateOrUpdateTrailCategory = async (data) => {
    const createTrailCategoryRequest = {
      name: data.name,
    };
    const updateTrailCategoryRequest = {
      name: data.name,
    };
    try {
      let result;
      let submitAction = "add";
      if (isEditing) {
        result = await api.put(
          `trail_category/${dataToEdit.id}`,
          updateTrailCategoryRequest
        );
        submitAction = "edit";
        enqueueSnackbar(t('categories.courseEdited'), { variant: "success" });
      } else {
        result = await api.post("trail_category", createTrailCategoryRequest);
        submitAction = "add";
        enqueueSnackbar(t('categories.courseAdded'), { variant: "success" });
      }

      feedbackAction("success", result.data, submitAction);
    } catch (err: any) {
      console.error(err);
    }
  };

  const controlledName = watch("name");

  return (
    <S.Form onSubmit={handleSubmit(handleCreateOrUpdateTrailCategory)}>
      <Button
        onClick={() => onBackPress()}
        type="button"
        background="transparent"
        borderColor="transparent"
        height={40}
        width={80}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body2"
        pathIcon="arrow"
        size="custom"
        text={t('categories.goBack')}
      />
      <S.ContainerGap>
        <Controller
          name="name"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <ResponsiveInputText
              {...field}
              placeholder={t('categories.name')}
              label={t('categories.name')}
              errorMessage={errors.name?.message}
              borderColor={errors.name ? "#F5222D" : "#8C8C8C"}
              data-cy="categoryFormInputName"
            />
          )}
        />
      </S.ContainerGap>

      <S.ContainerGap>
        <Button
          type="submit"
          typeOfButton="primary"
          size="custom"
          height={52}
          width={217}
          fontVariant="body1"
          text={isEditing ? "Salvar" : t('categories.add')}
          data-cy="categoryFormAddButton"
        />
      </S.ContainerGap>
    </S.Form>
  );
}
