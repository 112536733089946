import Visibility from "../types/enums/Visibility";
import api from "./api";

export async function getAllAccountants() {
  const result = await api.get(`formation/accountants`);
  return result?.data;
}

export async function summaryUsers() {
  const response = await api.get("dashboard/users-summary");
  return response?.data;
}

export async function getTopUsers() {
  const response = await api.get("dashboard/top-users");
  return response?.data;
}

export async function getTopPositions(
  visibility?: "PUBLIC" | "PRIVATE" | "PROTECTED"
) {
  const response = await api.get(
    `dashboard/top-positions?visibility=${visibility}`
  );
  return response?.data;
}

export async function getSuccessRate() {
  const response = await api.get(`dashboard/success-rate`);
  return response?.data;
}
