/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
import StarIcon from "@mui/icons-material/Star";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal, Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import { t } from "i18next";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import SkillGrayIcon from "../../assets/SkillGrayIcon.svg";
import TreeWhiteIcon from "../../assets/TreeWhiteIcon.svg";
import SetaGray from "../../assets/setaGray.svg";
import skillHunterPositionIcon from "../../assets/skillHunterPositionIcon.png";
import { ButtonDS } from "../../pages/Login/style";
import star from "../../pages/PositionTrail/images/star.svg";
import FormationService from "../../services/FormationService";
import SkillTreesService from "../../services/SkillTreeService";
import TrailService from "../../services/TrailService";
import UserService from "../../services/UserService";
import { Button } from "../Button";
import CardTree from "../CardTree";
import DefaultLoading from "../Loading/defaultLoading";
import ModalSubscribe from "../ModalSubscribe";
import { ProgressBar } from "../Progress";
import rightButton from "../assets/rightButton.svg";
import * as S from "./styles";
import {useContainerDimensions} from "../../hooks/useContainerDimensions";

function InfoPosition({ item, isCourse, mobile, user }) {
  const ref = useRef();
  const { width } = useContainerDimensions(ref);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<string | false>(null);
  const [treeLoading, setTreeLoading] = useState(false);
  const [competence, setCompetence] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const isSubscribed = data?.isSubscribed;
  const [noData, setNoData] = useState<any>(false);
  const [subscribing, setSubscribing] = useState<boolean>(false);
  const progress = data?.progress;
  const [seeMore, setSeeMore] = useState(false);
  const [moreLess, setMoreLess] = useState(t("positions.seeMore"));
  const [size, setSize] = useState(1)

  const navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
  };
  const handleClickCard = () => {
    const link = !isCourse ? "/position/" : "/course/";
    navigate(`${link}${item?.company?.slug}/${item?.slug}`);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const fetchData = async () => {
    setLoading(true);
    let response;
    if (!isCourse) {
      response = await FormationService.getDashboard(item?.slug);
    } else {
      response = await TrailService.getTrailDashboard(item?.slug);
    }
    setData(response?.data);
    setNoData(false);
    setLoading(false);
  };

  async function subscribe() {
    if (!localStorage.getItem("token")) {
      setShowModal(true);
      return;
    }
    const isComplete = await UserService.verifyIfProfileIsComplete();
    if (!isComplete) {
      setOpenEditDialog(true);
    } else {
      setSubscribing(true);
      if (!isCourse) {
        await FormationService.subscribe(item?.slug).then(() =>
          setSubscribing(false)
        );
      } else {
        await TrailService.subscribe(item?.slug).then(() =>
          setSubscribing(false)
        );
      }
    }

    await fetchData();
    await loadCompetence();
  }

  const getColor = (status: any) => {
    switch (status) {
      case "ACHIEVED":
        return "#00A676";
      case "UNLOCKED":
      case "STARTED":
        return "#302382";
      case "WAITING_EVALUATION":
        return "#FF9900";
      default:
        return "#C4C4C4";
    }
  };

  async function loadCompetence() {
    try {
      setTreeLoading(true);
      if (expanded && expanded !== null) {
        if (!isCourse) {
          const result = await SkillTreesService.getSkillTreeSkillsByFormation(
            expanded,
            item?.slug
          );
          setCompetence(result.skills);
        } else {
          const result = await SkillTreesService.getSkillTreeSkillsByTrail(
            expanded,
            item?.slug
          );
          setCompetence(result.skills);
        }
      }
    } catch (err) {
      setTreeLoading(false);
      console.error(err);
    } finally {
      setTreeLoading(false);
    }
  }

  function formatCurrency(value) {
    if (value == null) {
      return "";
    }
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  }

  function handleSeeMore() {
    if (seeMore) {
      setSeeMore(false);
      setMoreLess(t("positions.seeMore"));
    } else {
      setSeeMore(true);
      setMoreLess(t("positions.seeLess"));
    }
  }

  useEffect(() => {
    if (expanded) {
      loadCompetence();
    }
  }, [expanded]);

  useEffect(() => {
    if (
      (data?.isSubscribed !== isSubscribed || item?.id !== data?.id) &&
      !!item?.id
    ) {
      fetchData();
    }
    if (typeof item?.id !== "number") {
      setData(undefined);
      setNoData(true);
    }
  }, [item, isSubscribed]);

  useEffect(() => {
    if (data?.skillTrees.length > 0) {
      setExpanded(data.skillTrees[0]?.slug);
    }
  }, [data]);

  useEffect(() => {
    //Verifica quantas linhas o texto possui, para verificar qual o valor a ser passado para o size para que
    //possa ser configurado corretamente o min-height para que o texto não suma
    (Math.ceil(data?.description?.length / (width / 6)) * 25) > 150 ?
    setSize(150) :
      setSize(Math.ceil(data?.description?.length / (width / 6)) * 25)
  }, [width, data]);

  return (
    <S.FullCardContainer mobile={mobile} exist={false} isCourse={isCourse}>
      <>
        {noData ? (
          <S.NoDataText>
            Nenhum {isCourse ? "curso" : "cargo"} selecionado
          </S.NoDataText>
        ) : (
          <>
            {loading ? (
              <S.LoadingContainer>
                <DefaultLoading color="#696969" />
              </S.LoadingContainer>
            ) : (
              <>
                <S.CardHeaderContainer>
                  <S.LineFlex>
                    <S.ColumnFlex>
                      <S.Title onClick={handleClickCard}>
                        {!isCourse ? data?.title : data?.name}
                      </S.Title>
                      <S.CompanyGap>
                        <S.CompanyImage
                          src={
                            !isCourse
                              ? item?.company?.avatar || skillHunterPositionIcon
                              : item?.company?.icon?.fileUrl ||
                              skillHunterPositionIcon
                          }
                        />
                        <S.CompanyName>
                          {item?.company?.name || "Skill Hunter"}
                        </S.CompanyName>
                      </S.CompanyGap>
                    </S.ColumnFlex>
                    <S.LineFlex>
                      <S.ContainerIconTreeWithCompetences>
                        <img
                          src={SkillGrayIcon}
                          alt="skill"
                          style={{ height: 20 }}
                        />
                        <S.ContainerCompetences>
                          <S.NumberSkillTree>
                            {data?.skillsCount}
                          </S.NumberSkillTree>
                          <p>
                            {+data?.skillsCount > 1
                              ? t("courses.abilities")
                              : t("positionsManagement.skill")}
                          </p>
                        </S.ContainerCompetences>
                      </S.ContainerIconTreeWithCompetences>

                      {!isSubscribed && (
                        <Button
                          style={{
                            fontSize: "98%",
                            whiteSpace: "nowrap",
                            height: 46,
                          }}
                          loading={subscribing}
                          onClick={subscribe}
                          data-cy="subscribeButton"
                        >
                          {t("positions.enroll")}
                        </Button>
                      )}
                      {isSubscribed && progress !== 100 && (
                        <Button
                          style={{
                            fontSize: "98%",
                            whiteSpace: "nowrap",
                            height: 46,
                          }}
                          disable
                        >
                          {t("positions.enrolled")}
                        </Button>
                      )}
                      {progress === 100 && isSubscribed && (
                        <S.ButtonConclude
                          style={{
                            fontSize: "98%",
                            whiteSpace: "nowrap",
                            height: 46,
                          }}
                        >
                          <img src={rightButton} alt="right" />
                          {t("positions.concluded")}
                        </S.ButtonConclude>
                      )}
                    </S.LineFlex>
                  </S.LineFlex>
                </S.CardHeaderContainer>
                <S.CardContent>
                  {
                    !isSubscribed && data?.userAchievedSkills > 0 && !isCourse &&
                      <Typography variant="h6" component="h2">{t('positions.achievedSkills1') +
                        data?.userAchievedSkills?.toString() + t('positions.achievedSkills2')}
                      </Typography>
                  }
                  <S.CardContentTitle>
                    {!isCourse
                      ? t("positions.about")
                      : t("courseDetails.aboutCourse")}
                  </S.CardContentTitle>
                  <S.DescriptionOfPosition size={size} open={seeMore} ref={ref} dangerouslySetInnerHTML={{ __html: data?.description }}/>
                  {data?.description &&
                    (data?.description.split("\n").length > 6 ||
                      data?.description?.length / (width / 6) > 6) && (
                      <S.SeeMoreButtonDiv>
                        <Button
                          onClick={handleSeeMore}
                          children={moreLess}
                          hoverDisabled
                          style={{
                            background: "none",
                            color: "#FF026F",
                            width: "auto"
                          }}
                        />
                      </S.SeeMoreButtonDiv>
                    )}
                  {(data?.positionMinSalary || data?.positionMaxSalary) && (
                    <>
                      <S.TitleAboutPosition>
                        {t("positionsManagement.salaryRange")}
                      </S.TitleAboutPosition>
                      <p>
                        {formatCurrency(data?.positionMinSalary)} -{" "}
                        {formatCurrency(data?.positionMaxSalary)}
                      </p>
                    </>
                  )}
                  {
                    data?.addressess &&
                      <S.TitleAboutPosition>
                        {t("positions.jobLocations")}
                      </S.TitleAboutPosition>
                  }
                  {data?.adressess &&
                    data?.adressess.map((address) => {
                      return <S.TextCompany>{address}</S.TextCompany>;
                    })}
                  <S.ContainerProgressCard>
                    <S.ContainerProgressStarText>
                      <img
                        src={star}
                        alt="star_progress_icon"
                        style={{ width: 40 }}
                      />
                      <S.ContainerTitlePercent>
                        <S.ProgressTitleSubtitle>
                          <S.TitleProgress>
                            {t("positions.overallProgress")}
                          </S.TitleProgress>
                          <S.ProgressSubtitle>
                            {t("positions.complete")}
                          </S.ProgressSubtitle>
                        </S.ProgressTitleSubtitle>
                        <S.PercentText>{data?.progress}%</S.PercentText>
                      </S.ContainerTitlePercent>
                    </S.ContainerProgressStarText>
                    <ProgressBar
                      value={data?.progress || 0}
                      max={100}
                      isChallenge
                    />
                  </S.ContainerProgressCard>

                  {/* ? Competencias */}
                  <S.ContainerAccordions>
                    {data?.skillTrees.map((comp, i) => (
                      <Accordion
                        disableGutters
                        key={comp?.id}
                        expanded={expanded === comp?.slug}
                        onChange={handleChange(comp?.slug)}
                      >
                        <AccordionSummary
                          data-cy="positionAcordion"
                          aria-controls={`${comp?.id}d-content`}
                          id={`${comp?.id}d-header`}
                        >
                          <S.ContainerAccordionSummary>
                            <S.ContainerAccordionSummaryLeft>
                              <S.AccordionSummaryCircleImg
                                color={getColor(comp?.status || "UNLOCKED")}
                              >
                                <S.AccordionSummaryImg
                                  src={TreeWhiteIcon}
                                  alt="TreeIcon"
                                />
                              </S.AccordionSummaryCircleImg>
                              <S.ContainerAccordionSummaryLeftTexts>
                                <S.TitleAccordionSummary>
                                  {comp?.name}
                                </S.TitleAccordionSummary>

                                <S.SubtitleAccordionSummary
                                  color={getColor(comp?.status || "UNLOCKED")}
                                >
                                  {t("positions.progress")}:{" "}
                                  {comp?.progress || 0}%
                                </S.SubtitleAccordionSummary>
                              </S.ContainerAccordionSummaryLeftTexts>
                            </S.ContainerAccordionSummaryLeft>
                            <div
                              style={{
                                display: "flex",
                                gap: 12,
                                alignItems: "center",
                              }}
                            >
                              {comp?.mandatory && (
                                <Tooltip
                                  title={t("positions.mandatory")}
                                  placement="top"
                                >
                                  <span>
                                    <StarIcon sx={{ color: "gold" }} />
                                  </span>
                                </Tooltip>
                              )}
                              <S.AccordionSummarySetaImg
                                up={expanded === comp.slug}
                                src={SetaGray}
                                alt="Seta"
                              />
                            </div>
                          </S.ContainerAccordionSummary>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0, m: 0 }}>
                          {treeLoading ? (
                            <S.ContainerAccordionDetailsLoading>
                              <DefaultLoading color="#898989" />
                              <S.LoadingText>
                                {t("positions.loading")}...
                              </S.LoadingText>
                            </S.ContainerAccordionDetailsLoading>
                          ) : (
                            <S.ContainerAccordionDetails>
                              {competence?.length > 0 ? (
                                <CardTree
                                  skills={competence}
                                  onClick={(skill) => {
                                    if (!user?.id) {
                                      setShowModal(true);
                                    } else {
                                      navigate(
                                        `/${isCourse ? "course" : "position"}/${item?.company?.slug
                                        }/${item?.slug
                                        }/skill-tree/${expanded}/skill/${skill.slug
                                        }`
                                      );
                                    }
                                  }}
                                />
                              ) : (
                                <S.WithoutSkills>
                                  {t("positions.noChallenge")}
                                </S.WithoutSkills>
                              )}
                            </S.ContainerAccordionDetails>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </S.ContainerAccordions>
                </S.CardContent>
                <Dialog
                  open={openEditDialog}
                  onClose={() => setOpenEditDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {isCourse
                      ? t("courses.completeProfile")
                      : t("positions.completeProfile")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <b>{t("positions.doThisNow")}</b>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <ButtonDS
                      typeOfButton="secondary"
                      size="custom"
                      height={40}
                      width={140}
                      fontVariant="body1"
                      text={t("no")}
                      onClick={() => setOpenEditDialog(false)}
                    />
                    <ButtonDS
                      typeOfButton="primary"
                      size="custom"
                      height={40}
                      width={140}
                      fontVariant="body1"
                      text={t("yes")}
                      onClick={() => navigate("/EditUser")}
                      data-cy="acceptEdit"
                    />
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        )}
        <Modal
          open={showModal}
          onClose={handleClose}
          sx={{
            backdropFilter: "blur(2px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ModalSubscribe
            company={item?.company?.slug}
            onClose={handleClose}
            isCourse={isCourse}
            id={item?.id}
          />
        </Modal>
      </>
    </S.FullCardContainer>
  );
}

export default InfoPosition;
