import api from "./api";

class ChallengeService {
  async sendChallengeAnswers(data) {
    try {
      const response = await api.post("challenges/answer-challenge", data);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  async getChallenge() {
    const resultChallenge = await api.get("challenges");
    return resultChallenge.data;
  }

  async patchChallengeCount(challengeId) {
    await api.patch(`challenges/count/${challengeId}`);
  }

  async getAllChallenge(page: number, search: string) {
    const filter = encodeURIComponent(search);
    const response = await api.get(
      `challenges/page?page=${page}&size=5&filter=${filter}`
    );
    return response.data;
  }

  async getSearchChallenge(search: string) {
    const response = await api.get(
      `challenges/page?filter=${search}&page=0&size=5`
    );
    return response.data;
  }

  async deleteChallenge(id: string) {
    await api.delete(`challenges/${id}`);
  }

  async findIdChallenge(id: string) {
    const response = await api.get(`challenges/${id}`);
    return response.data;
  }

  async editChallenge(id: string, ChallengeDTO: any) {
    const response = await api.put(`challenges/${id}`, ChallengeDTO);
    return response.data;
  }

  async addChallenge(challenge: string, ChallengeDTO: any) {
    const response = await api.post(challenge, ChallengeDTO);
    return response;
  }

  async getChallengeToDropdown(search: string) {
    const filter = encodeURIComponent(search);
    const resultChallengeToDropdown = await api.get(
      `challenges/summary?filter=${filter}&size=1000&sort=id%2Cdesc`
    );
    return resultChallengeToDropdown.data.content.map(({id, title}) => ({
      id,
      value: title,
    }));
  }

  async getChallengeByID(id: string | Number) {
    try {
      const {data} = await api.get(`challenges/get-questions/${id}`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async postEvaluationChallenge(
    postDTOEvaluationChallenge,
    questionCorrections
  ) {
    const postEvaluationChallenge = await api.post("challenges/correction", {
      challengeID: postDTOEvaluationChallenge.challengeID,
      feedback: postDTOEvaluationChallenge.feedback,
      userId: postDTOEvaluationChallenge.userId,
      questionCorrections,
    });
    return postEvaluationChallenge;
  }
}

export default new ChallengeService();
