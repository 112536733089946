import React, {useEffect, useState} from "react";
import {Select} from "../../components/Select";
import EvaluationService from "../../services/EvaluationService";
import SkillService from "../../services/SkillService";
import TrailService from "../../services/TrailService";
import UserService from "../../services/UserService";
import * as S from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {useNavigate} from "react-router-dom";
import ChallengeService from "../../services/ChallengeService";
import LoadingTable from "../../components/Loading/LoadingTable";
import SetaFillDownIcon from "../../assets/setaFillDown.svg";
import SetaFillUpIcon from "../../assets/setaFillUp.svg";
import {Button} from "../../components/Button";
import FormationService from "../../services/FormationService";
import {convertToLocalDate} from "../../components/utils/masks";
import {t} from "i18next";
import {Chip, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";

interface Column {
  id: "user" | "trail" | "skill" | "result" | "finishDate" | "evaluationDate";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface IPropsDropdowns {
  id?: number | string;
  value?: string;
}

interface IFilter {
  finishDateSelected: IPropsDropdowns;
  userSelected: IPropsDropdowns;
  trailSelected: IPropsDropdowns;
  skillSelected: IPropsDropdowns;
  challengeSelected: IPropsDropdowns;
  resultSelected: IPropsDropdowns;
  positionSelected: IPropsDropdowns;
  visibilitySelected: boolean;
}

interface IEvaluationReturn {
  data: IEvaluationData[];
  totalPages: number;
  totalElements: number;
  currentPage: number;
}

interface IEvaluationData {
  skillChallengeId: number;
  challengeId: any;
  userId: any;
  user: string;
  challenge: string;
  skill: string;
  trail: string;
  result: string;
  finishData: string;
  evaluationDate: string;
}


const columns: readonly Column[] = [
  {id: "user", label: "evaluations.user", minWidth: 170},
  {id: "trail", label: "evaluations.coursePosition", minWidth: 191},
  {
    id: "skill",
    label: "evaluations.skill",
    minWidth: 170,
  },
  {
    id: "result",
    label: "evaluations.result",
    minWidth: 120,
  },
  {
    id: "evaluationDate",
    label: "evaluations.evaluationDate",
    minWidth: 170,
  },
  {
    id: "finishDate",
    label: "evaluations.submissionDate",
    minWidth: 170,
  },
];


export default function Evaluation() {

  const defaultFilter = () => {
    return {
      userSelected: undefined,
      trailSelected: undefined,
      skillSelected: undefined,
      challengeSelected: undefined,
      resultSelected: {id: 'WAITING_EVALUATION', value: resultTypes.WAITING_EVALUATION},
      finishDateSelected: undefined,
      positionSelected: undefined,
      visibilitySelected: null
    };
  };

  const resultTypes = {
    ACHIEVED: t("evaluations.approved"),
    FAILED: t("evaluations.reproved"),
    WAITING_EVALUATION: t("evaluations.pending"),
  };

  const typesOfResults: any = Object.entries(resultTypes).map((entry) => {
    return {id: entry[0], value: entry[1]};
  });

  const [nextFilter, setNextFilter] = useState<IFilter>(defaultFilter);
  const [listOfAllUsers, setListOfAllUsers] = useState<IPropsDropdowns[]>([]);
  const [listofEvaluations, setListofEvaluations] = useState<IEvaluationReturn>(
    {} as IEvaluationReturn
  );
  const [listofEvaluationsFinishDate, setListofEvaluationsFinishDate] =
    useState([]);
  const [listOfAllTrails, setListOfAllTrails] = useState<IPropsDropdowns[]>([]);
  const [listOfAllSkills, setListOfAllSkills] = useState<IPropsDropdowns[]>([]);
  const [listOfAllChallenges, setListOfAllChallenges] = useState<
    IPropsDropdowns[]
  >([]);
  const [listOfAllPositions, setListOfAllPositions] = useState<
    IPropsDropdowns[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [dateASC, setDateASC] = useState(true);
  const [dateDESC, setDateDESC] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [visibility, setVisibility] = useState<number>(
    2
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    localStorage.setItem(
      "filter",
      JSON.stringify({
        ...nextFilter,
        pageNumber: newPage,
        pageSize: rowsPerPage,
      })
    );
    setPage(newPage);
  };

  async function OrderByDate() {
    let newASC = dateASC;
    let newDESC = dateDESC;

    if (
      (dateASC == false && dateDESC == false) ||
      (dateASC == false && dateDESC == true)
    ) {
      newASC = true;
      newDESC = false;
      setDateASC(true);
      setDateDESC(false);
    }
    if (dateASC == true && dateDESC == false) {
      newASC = false;
      newDESC = true;
      setDateASC(false);
      setDateDESC(true);
    }

    let currentFilter = JSON.parse(localStorage.getItem("filter"));

    let Evaluations = await EvaluationService.getFilteredEvaluations({
      userSelected: currentFilter?.userSelected?.id,
      trailSelected: currentFilter?.trailSelected?.id,
      resultSelected: currentFilter?.resultSelected?.id,
      skillSelected: currentFilter?.skillSelected?.id,
      challengeSelected: currentFilter?.challengeSelected?.id,
      visibilitySelected: currentFilter?.visibilitySelected,
      finishDateSelected: returnDateFormat(
        nextFilter?.finishDateSelected?.value
      ),
      positionSelected: nextFilter?.positionSelected?.id,
      pageNumber: page,
      pageSize: currentFilter?.pageSize || rowsPerPage,
      dateASC: newASC,
      dateDESC: newDESC,
    });

    setListofEvaluations(Evaluations);
    setTimeout(() => {
      setLoading(false);
      setPage(0);
    }, 250);
  }

  async function getListOfEvaluations() {
    let currentFilter = JSON.parse(localStorage.getItem("filter"));

    let result = await EvaluationService.getFilteredEvaluations({
      userSelected: currentFilter?.userSelected?.id,
      trailSelected: currentFilter?.trailSelected?.id,
      resultSelected: currentFilter?.resultSelected?.id,
      skillSelected: currentFilter?.skillSelected?.id,
      challengeSelected: currentFilter?.challengeSelected?.id,
      visibilitySelected: currentFilter?.visibilitySelected,
      finishDateSelected: returnDateFormat(
        nextFilter?.finishDateSelected?.value
      ),
      positionSelected: nextFilter?.positionSelected?.id,
      pageNumber: page,
      pageSize: currentFilter?.pageSize || rowsPerPage,
      dateASC: dateASC,
      dateDESC: dateDESC,
    });

    setListofEvaluations(result);
  }

  useEffect(() => {
    getListOfEvaluations();
  }, [page, rowsPerPage]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    localStorage.setItem(
      "filter",
      JSON.stringify({
        ...nextFilter,
        pageNumber: page,
        pageSize: +event.target.value,
      })
    );
    setRowsPerPage(+event.target.value);
  };

  const navigate = useNavigate();

  async function handleFilterUsingDropdownsSelections() {
    setLoading(true);
    const result = await EvaluationService.getFilteredEvaluations({
      userSelected: nextFilter?.userSelected?.id,
      trailSelected: nextFilter?.trailSelected?.id,
      resultSelected: nextFilter?.resultSelected?.id,
      skillSelected: nextFilter?.skillSelected?.id,
      challengeSelected: nextFilter?.challengeSelected?.id,
      visibilitySelected: nextFilter?.visibilitySelected,
      finishDateSelected: returnDateFormat(
        nextFilter?.finishDateSelected?.value
      ),
      positionSelected: nextFilter?.positionSelected?.id,
      pageNumber: page,
      pageSize: rowsPerPage,
      dateASC: dateASC,
      dateDESC: dateDESC,
    });

    setListofEvaluations(result);
    localStorage.setItem(
      "filter",
      JSON.stringify({...nextFilter, pageNumber: page, pageSize: rowsPerPage})
    );
    setTimeout(() => {
      setLoading(false);
      setPage(0);
    }, 250);
  }

  function returnDateFormat(DateForFormat: string) {
    if (
      DateForFormat != null &&
      DateForFormat != undefined &&
      DateForFormat != ""
    ) {
      const [day, mouth, year] = DateForFormat.split("/");
      return `${year}-${mouth}-${day}`;
    }
  }

  async function handleClearFiltersOfDropdowns() {
    localStorage.removeItem("filter");
    setNextFilter(defaultFilter);
    setVisibility(2)
    setPage(() => 0);
    const result = await EvaluationService.getAllEvaluations({
      pageSize: rowsPerPage,
      pageNumber: page,
    });
    setListofEvaluations(result);
  }

  function getPageSize() {
    let currentFilter = JSON.parse(localStorage.getItem("filter"));
    return currentFilter?.pageSize || 5;
  }

  const getSkills = async () => {
    const filterFormationVisibility =
      visibility === 2 ? null : visibility === 1 ? false : true;
    const resultSkills = await SkillService.getSkillToDropdownAquarium(nextFilter.trailSelected?.id, nextFilter.positionSelected?.id, null, filterFormationVisibility, null);
    setListOfAllSkills(resultSkills);
  }

  useEffect(() => {
    if(nextFilter.trailSelected != null || nextFilter.positionSelected != null){
      setListOfAllSkills([])
      getSkills()
    }else{
      setListOfAllSkills([])
    }
  }, [nextFilter])

  async function fetchData() {
    const filterFormationVisibility =
      visibility === 2 ? null : visibility === 1 ? false : true;

    const resultUsers = await UserService.getUserToDropdown(filterFormationVisibility);
    setListOfAllUsers(resultUsers);

    const resultTrails = await TrailService.getTrailToDropdown(filterFormationVisibility);
    setListOfAllTrails(resultTrails);

    const resultPositions = await FormationService.getFormationToDropdown(filterFormationVisibility);
    setListOfAllPositions(resultPositions);

    const resultListofEvaluationsFinishDate =
      await EvaluationService.getEvaluations();

    const setListOfFinishDate = new Set();
    const listOfFinishDate = resultListofEvaluationsFinishDate.content
      .map(function (challenge, i) {
        const data = challenge?.finishDate?.split(" ")?.[0];
        if (!data) {
          return undefined;
        }
        if (data?.split("/")?.length === 3) {
          return data;
        }
        const [month, day, year] = data.split("-");
        const result = `${day}/${month}/${year}`;
        return {
          id: i,
          value: result,
        };
      })
      .filter((item) => item && item);

    const filterDateNotRepeat = listOfFinishDate.filter((date) => {
      const duplicatedDates = setListOfFinishDate.has(date.value);
      setListOfFinishDate.add(date.value);
      return !duplicatedDates;
    });

    setListofEvaluationsFinishDate(filterDateNotRepeat);
    let currentFilter = JSON.parse(localStorage.getItem("filter"));
    currentFilter = currentFilter || defaultFilter();
    setNextFilter(currentFilter);
    const resultEvaluations = await EvaluationService.getFilteredEvaluations({
      userSelected: currentFilter?.userSelected?.id,
      trailSelected: currentFilter?.trailSelected?.id,
      resultSelected: currentFilter?.resultSelected?.id,
      skillSelected: currentFilter?.skillSelected?.id,
      challengeSelected: currentFilter?.challengeSelected?.id,
      visibilitySelected: currentFilter?.visibilitySelected,
      finishDateSelected: returnDateFormat(
        nextFilter?.finishDateSelected?.value
      ),
      positionSelected: nextFilter?.positionSelected?.id,
      pageNumber: page,
      pageSize: currentFilter?.pageSize || rowsPerPage,
      dateASC: dateASC,
      dateDESC: dateDESC,
    });
    setListofEvaluations(resultEvaluations);
  }

  const getCellValue = (column, value) => {
    switch (column.label) {
      case "evaluations.submissionDate":
        return convertToLocalDate(value)
      case "evaluations.evaluationDate":
        return value !== null ? convertToLocalDate(value) : '-'
      default:
        switch (value) {
          case "Aprovado":
            return (<Chip label={t('avaliacoes.aprovado')} color="success" />);
          case "Pendente":
            return (<Chip label={t('avaliacoes.pendente')} color="warning" />)
          case "Reprovado":
            return ((<Chip label="Reprovado" color="error" />))
          default:
            return value;
        }
    }
  }

  const handleChangeVisibility = async (visibilityId) => {
    localStorage.setItem("selectedPositionVisibilityEvaluation", visibilityId);
    setVisibility(visibilityId)
    const filterFormationVisibility =
      visibilityId === 2 ? null : visibilityId === 1 ? false : true;
    setNextFilter({...nextFilter, visibilitySelected: filterFormationVisibility})
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
    handleFilterUsingDropdownsSelections()
  }, [visibility]);

  return (
    <div>
      <S.ContainerEvaluation>
        <S.Title>{t("evaluations.reviews")}</S.Title>
        <S.Subtitle>{t("evaluations.rateUserSkillHunter")}</S.Subtitle>

        <S.UpperFilterContainer>
          <FormControl>
            <FormLabel
              color="error"
              id="demo-row-radio-buttons-group-label"
            >
              {t("performanceReport.specifyPositionType")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <S.FormControlTooltipWrapper>
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      color="error"
                      onChange={() => handleChangeVisibility(0)}
                      checked={visibility === 0}
                    />
                  }
                  label={t("performanceReport.externalRecruitment")}
                />
                <Tooltip
                  sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                  title={t("evaluations.publicPositions")}
                  placement="top"
                >
                  <InfoOutlined/>
                </Tooltip>
              </S.FormControlTooltipWrapper>
              <S.FormControlTooltipWrapper>
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      // disabled={pool === 0}
                      color="error"
                      onChange={() => handleChangeVisibility(1)}
                      checked={visibility === 1}
                    />
                  }
                  label={t("performanceReport.internalRecruitment")}
                />
                <Tooltip
                  sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                  title={t("evaluations.privatePositions")}
                  placement="top"
                >
                  <InfoOutlined/>
                </Tooltip>
              </S.FormControlTooltipWrapper>
              <S.FormControlTooltipWrapper>
                <FormControlLabel
                  value={2}
                  control={
                    <Radio
                      // disabled={pool === 0}
                      color="error"
                      onChange={() => handleChangeVisibility(2)}
                      checked={visibility === 2}
                    />
                  }
                  label={t("performanceReport.unspecified")}
                />
                <Tooltip
                  sx={{p: 0.4, m: 0, ml: -1.5, mb: -1}}
                  title={t("evaluations.notSpecifiedOption")}
                  placement="top"
                >
                  <InfoOutlined/>
                </Tooltip>
              </S.FormControlTooltipWrapper>
            </RadioGroup>
          </FormControl>
        </S.UpperFilterContainer>

        <S.ContainerFilter>
          <S.TitleContainerInputs>
            {t("evaluations.filterChallenges")}
          </S.TitleContainerInputs>
          <S.ContainerInputs>
            <S.ContainerSelect>
              <Select
                selected={nextFilter?.userSelected}
                onSelect={(e) =>
                  setNextFilter({...nextFilter, userSelected: e})
                }
                label={t("evaluations.user")}
                placeholder={t("evaluations.user")}
                options={listOfAllUsers}
                listAbsolute
              />
            </S.ContainerSelect>
            <S.ContainerSelect>
              <Select
                selected={nextFilter?.positionSelected}
                onSelect={(e) =>
                  setNextFilter({...nextFilter, positionSelected: e, trailSelected: undefined})
                }
                label={t("evaluations.position")}
                placeholder={t("evaluations.position")}
                options={listOfAllPositions}
                listAbsolute
              />
            </S.ContainerSelect>
            <S.ContainerSelect>
              <Select
                selected={nextFilter?.trailSelected}
                onSelect={(e) =>
                  setNextFilter({...nextFilter, trailSelected: e, positionSelected: undefined})
                }
                label={t("evaluations.course")}
                placeholder={t("evaluations.course")}
                options={listOfAllTrails}
                listAbsolute
              />
            </S.ContainerSelect>

            <S.ContainerSelect>
              <Select
                selected={nextFilter?.skillSelected}
                disabled={listOfAllSkills.length == 0}
                onSelect={(e) =>
                  setNextFilter({...nextFilter, skillSelected: e})
                }
                label={t("evaluations.skill")}
                placeholder={t("evaluations.skill")}
                options={listOfAllSkills}
                listAbsolute
              />
            </S.ContainerSelect>

            <S.ContainerSelect>
              <Select
                selected={nextFilter?.resultSelected}
                onSelect={(e) =>
                  setNextFilter({...nextFilter, resultSelected: e})
                }
                label={t("evaluations.result")}
                placeholder={t("evaluations.result")}
                options={typesOfResults}
                listAbsolute
              />
            </S.ContainerSelect>

            <S.ContainerSelect>
              <Select
                selected={nextFilter?.finishDateSelected}
                onSelect={(e) =>
                  setNextFilter({...nextFilter, finishDateSelected: e})
                }
                label={t("evaluations.submissionDate")}
                placeholder={t("evaluations.submissionDate")}
                options={listofEvaluationsFinishDate}
                listAbsolute
              />
            </S.ContainerSelect>

          </S.ContainerInputs>
          <S.ContainerButtons>
            <Button
              style={{width: 182, height: 42}}
              onClick={handleFilterUsingDropdownsSelections}
            >
              {t('avaliacoes.pesquisar')}
            </Button>
            <Button
              style={{
                width: 182,
                height: 42,
                background: "#fff0",
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: "#131212",
                color: "#131212",
              }}
              onClick={handleClearFiltersOfDropdowns}
            >
              {t("evaluations.clear")}
            </Button>
          </S.ContainerButtons>
        </S.ContainerFilter>
        <S.ContainerStyleTable>
          <Paper sx={{width: "100%", overflow: "hidden"}}>
            <TableContainer sx={{maxHeight: 440}}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="titleColumn">
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                        }}
                      >
                        <S.ContainerTableRow>
                          {t(column.label)}
                          {column.label == "evaluations.submissionDate" && (
                            <S.ButtonImg>
                              <S.imgArrow
                                onClick={() => OrderByDate()}
                                src={
                                  dateDESC ? SetaFillUpIcon : SetaFillDownIcon
                                }
                                alt={t("evaluations.sortDateArrow")}
                              />
                            </S.ButtonImg>
                          )}
                        </S.ContainerTableRow>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listofEvaluations.totalElements === 0 && (
                    <TableRow>
                      <TableCell colSpan={8} sx={{textAlign: "center"}}>
                        {loading ? (
                          <LoadingTable/>
                        ) : (
                          t("evaluations.noResultsFound")
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {listofEvaluations.totalElements > 0 &&
                    listofEvaluations.data.map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                onClick={() =>
                                  navigate(
                                    `/evaluation-challenge/${row.skillChallengeId}`
                                  )
                                }
                                scope={value}
                                key={column.id}
                                align={column.align}
                                className="tableRowCell"
                              >
                                {
                                  getCellValue(column, value)
                                }
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="pagination"
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={listofEvaluations.totalElements}
              rowsPerPage={getPageSize()}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </S.ContainerStyleTable>
      </S.ContainerEvaluation>
    </div>
  );
}
