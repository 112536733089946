import createTheme from "@mui/material/styles/createTheme";

const skillHunterTheme = createTheme({
  palette: {
    primary: {
      light: "#878787",
      dark: "#494949",
      main: "#696969",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF026F"
    }
  },
  typography: {
    fontFamily: "Ubuntu",
    h1: {
      fontSize: "36px",
      fontWeight: 700,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1316,
      xl: 1536,
    },
  },
});

export default skillHunterTheme;
