import React, { createElement, useRef, useState } from "react";
import * as Icons from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from "@mui/material";
import * as S from "./styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UserService from "../../services/UserService";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export function SidebarItem({ item, index, showChilds, setShowChilds }) {
  const childs = item?.childs?.filter((item) =>
    UserService.hasAccess(item.accessLevel, item.internalRecruitment)
  );
  const isThereChild = childs?.length > 0;

  const location = useLocation();

  return (
    <S.FullItemContainer key={index}>
      <ListItemButton
        selected={
          isThereChild && !showChilds?.[index]
            ? childs?.some((child) => location?.pathname === child?.link)
            : location?.pathname === item?.link
        }
        onClick={() => {
          if (isThereChild) {
            setShowChilds(
              showChilds.map((it, ind) => (ind === index ? !it : false))
            );
          } else {
            item?.onClick();
          }
        }}
      >
        <ListItemText primary={item?.label} />
        {isThereChild && (
          <div>{showChilds?.[index] ? <ExpandLess /> : <ExpandMore />}</div>
        )}
      </ListItemButton>
      {isThereChild &&
        childs?.map((child, childIndex) => (
          <Collapse
            in={showChilds?.[index]}
            onClick={child?.onClick}
            key={childIndex}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                selected={location?.pathname === child?.link}
                sx={{ pl: 4 }}
              >
                <ListItemText primary={child?.label} />
              </ListItemButton>
            </List>
          </Collapse>
        ))}
    </S.FullItemContainer>
  );
}
