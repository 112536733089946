import { type } from "os";
import styled from "styled-components";

interface IPropsTextArea {
  borderColor?: string;
  colorError?: string;
}

export const Label = styled.p`
  margin-bottom: 0.3rem;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const ContainerAverageTime = styled.div`
  max-width: 80%;
`;

export const InputRow = styled.div<{rowsSize: number[]}>`
  max-width: 80%;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
  rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 16px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const InputTypeNumberTime = styled.input<InputTypeNumberProps>`
  height: 44px;
  background: #ffffff;
  padding: 10px;

  border: ${({ borderColor }) =>
  borderColor ? `1px solid ${borderColor}` : "#8C8C8C"};
  border-radius: 3px;

  &::placeholder {
    color: #8c8c8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }

  &:focus {
    outline: 1px solid #ff026f;
  }
`;

export const ContainerTimeSetting = styled.div`
  margin-top: 10px;
`;

export const ContainerForm = styled.div`
  display: grid;
  grid-template-areas:
    "collumForm description"
    "collumForm description"
    "collumForm description"
    "collumForm description";
  grid-column-gap: 2rem;
`;

export const ContainerButtonSendFile = styled.div`
  margin-top: 37px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 0;
`;

export const ContainerDropdown = styled.div`
  margin-top: 37px;
  margin-bottom: 37px;

  max-width: 80%;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

export const ContainerInput = styled.div`
  margin-bottom: 37px;
  width: 80%;
  border: 1px solid #8c8c8c;
  border-radius: 3px;
  background: #ffffff;

  p {
    font-size: 14px;
    margin: 0;
  }

  .ql-snow.ql-snow img {
    display: flex;
    max-width: 100%;
    max-height: 800px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 25px;
  background: white;
  border: 1px solid
    ${({ borderColor }) => (borderColor ? borderColor : "#8c8c8c")};
  border-radius: 3px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #131313;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:focus {
    outline: 1px solid #ff026f;
  }
  &::placeholder {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    color: #8c8c8c;
  }
`;

export const ErrorMessage = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Ubuntu;
  color: #f5222d;
`;

export const ContainerOfTypeOfQuestions = styled.div`
  margin-left: 10px;
`;

export const ContainerButtonsAddAndRemove = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 25px;

  .addButton {
    background: #00a676;
    &:hover {
      background: #00a676;
      transform: scale(1.1);
    }
  }

  .removeButton {
    background: #f5222d;
    &:hover {
      background: #f5222d;
      transform: scale(1.1);
    }
  }
`;

export const ContainerOfInputsOfTypeOfQuestions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ImageUploadContainerCheckboxAndRadio = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UploadInput = styled.input`
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
  z-index: 10;
  opacity: 0;
`;

export const UploadButtonText = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #131212;
`;

export const UploadImageText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #696969;
  margin-bottom: 1rem;
`;

export const ImageSelected = styled.div`
  width: 250px;
  height: 42px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .success {
    min-width: 30px;
    min-height: 24px;
  }

  .wrong {
    display: inline;
  }

  .fileName {
    margin-right: 8px;
    margin-left: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .imageIcon {
    min-width: 42px;
    min-height: 42px;
    background: transparent;
    > path {
      min-width: 42px;
      min-height: 42px;
      width: 42px;
      height: 42px;
      background: #ffffff;
    }
  }
`;

export const removeImage = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  margin-left: 3px;
  color: red;
  font-size: 14px;
  cursor: pointer;
`;

export const IconRemoveImage = styled.img`
  cursor: pointer;
  width: 15px;
`;

export const ContainerRemoveImage = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ContainerOfButtonSubmit = styled.div`
  display: grid;
  grid-area: auto auto auto auto;
  margin-top: 67px;
`;

export const ContainerRadioGroup = styled.div`
  position: relative;

  display: flex;
  width: 112px;
  height: fit-content;
  justify-content: space-between;
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-left: 2rem;

  @media (max-width: 1000px) {
    margin-left: 0;
  }

  accent-color: #ff026f;
`;

export const LabelCheckbox = styled.span`
  padding-left: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const ContainerCheckbox = styled.div`
  margin-left: 1.25rem;

  span {
    font-weight: 400;
    font-size: 14px;
  }

  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

interface InputTypeNumberProps {
  borderColor: string;
}

export const InputTypeNumber = styled.input<InputTypeNumberProps>`
  width: 350px;
  height: 44px;
  background: #ffffff;
  padding: 10px;

  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "#8C8C8C"};
  border-radius: 3px;

  &::placeholder {
    color: #8c8c8c;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }

  &:focus {
    outline: 1px solid #ff026f;
  }
`;

export const ContainerNumericOption = styled.div`
  margin-top: 30px;
`;

export const ContainerFormQuestions = styled.div`
  button[color="#FF026F"] {
    margin-top: 40px;
  }
`;

export const ContentSize = styled.p<IPropsTextArea>`
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${({ colorError }) => colorError};
  text-align: end;
`;

export const UploadImageButton = styled.button`
  display: flex;
  gap: 14px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 52px;
  border: 1px solid #131212;
  position: relative;
  background-color: #0000;

  .uploadIcon {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 425px) {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const ContainerOptions = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  grid-template-areas:
    "imageAndButton inputAndCheckbox"
    "imageAndButton inputAndCheckbox";
  margin-bottom: 2rem;

  .inputQuestionContent {
    width: 20.5rem;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 0.8fr 1.2fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1.2fr 0.8fr;
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonImageAndFileSection = styled.div`
  grid-area: imageAndButton;

  @media (max-width: 425px) {
    margin-bottom: 1.625rem;
  }
`;

export const InputAndCheckboxSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    display: none;
  }
  grid-area: inputAndCheckbox;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    gap: 1.25rem;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  flex-direction: row;
  height: 54px;
  align-items: center;
`;
