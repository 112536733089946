import { Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultUserIcon from "../../assets/defaultUserIcon.png";
import { Button } from "../Button";
import * as S from "./styles";
import {enqueueSnackbar} from "notistack";
import {t} from "i18next";

interface IData {
  user?: any;
  pages?: any;
  settings?: any;
  preview?: boolean;
}

export function ResponsiveSideBarMobile({ user, pages, settings, preview }: IData) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePreview = () => {
    enqueueSnackbar(t("positions.itsPreview"), {variant: "warning"})
  }

  return (
    <S.ContainerResponsiveSideBarMobile>
      {user !== null &&
        pages?.map((item) => (
          <S.ContainerTrails onClick={item?.onClick}>
            {React.createElement(item?.icon?.type, {
              style: {
                color: "#696969",
              },
            })}
            <S.Description>{item?.title}</S.Description>
          </S.ContainerTrails>
        ))}

      {user?.id ? (
        <S.ContainerUser onClick={(e) => {
          preview ? handlePreview() : handleOpenUserMenu(e)
        }}>
          <img src={user?.avatar?.fileUrl || defaultUserIcon} alt="Usuário" />
          <S.Description>Usuário</S.Description>
        </S.ContainerUser>
      ) : (
        <Button style={{ width: "auto" }} onClick={() => (preview ? handlePreview() : navigate("/login"))}>
          {t("sidebar.login")}
        </Button>
      )}
      <Menu
        sx={{ mb: "50px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings?.map((setting) => (
          <MenuItem key={setting?.label} onClick={setting?.callback}>
            <Typography textAlign="center">{setting?.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </S.ContainerResponsiveSideBarMobile>
  );
}
