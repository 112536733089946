import styled from "styled-components";

export const FullModal = styled.div`
  font-family: "Ubuntu";
  position: relative;
  width: 652px;
  max-width: 94%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 37.5px;
  gap: 28px;
  border-radius: 3px;
  text-align: center;
  max-height: 98%;
  overflow-y: auto;
  transition: height 0.5s ease-in-out;

  & > * {
    max-height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  &:focus {
    outline: none !important;
  }

  &.expanded {
    height: 100%;
  }

  &.collapsed {
    height: 750px;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 16px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #131212;
`;

export const Subtitle = styled.p`
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  width: 80%;
  color: #131212;
`;

export const RowContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  gap: 8px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
