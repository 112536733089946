import {TextFieldProps} from "@mui/material";
import accounting from "accounting";
import * as S from "./styles";
import {useState} from "react";
let timeOut = null;
const OrangeInputMoney = (props: TextFieldProps) => {
  const [localValue, setLocalValue] = useState("")

  const handleChange = (event: any) => {


    const inputValue = event.target.value
    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, '')) / 100
    const formattedValue: any = accounting.formatMoney(numericValue, 'R$ ', 2, '.', ',')
    setLocalValue(formattedValue);
    if(timeOut != null){
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      props.onChange(formattedValue)
    }, 500)
  }
  return (
    <div style={{...props.style}}>
      <S.Label>{props.label}</S.Label>
      <S.ContainerInput {...props} style={{}} type="text" value={localValue} onChange={handleChange} placeholder="R$ 0,00"/>
    </div>
  )
}
export default OrangeInputMoney;