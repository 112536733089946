import styled from "styled-components";

interface IPropsTextArea {
  borderColor: any;
}


export const ContainerForm = styled.div`
  display: grid;
  grid-template-areas:
    "collumForm description"
    "collumForm description"
    "collumForm description"
    "collumForm description";
  grid-column-gap: 2rem;
`;

export const ContainerButtonSendFile = styled.div`
  margin-top: 37px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 0;

  p {
    font-size: 14px;
  }
`;

export const ContainerDropdown = styled.div`
  margin-top: 2.3rem;
  max-width: 100%;
`;

export const ContainerGap = styled.div`
  margin-top: 2.3rem;

  p {
    font-size: 14px;
  }
`;

export const InputRow = styled.div<{ rowsSize: number[] }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({rowsSize}) =>
          rowsSize.map((i) => `${i}fr`).join(" ")};
  gap: 16px;
  @media screen and (max-width: 1049px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin-top: 0px;
  }
`;

export const ImageDescription = styled.p`
  font-size: 12px;
`;

export const Form = styled.form`
`;

export const Label = styled.p`
  width: 100%;
  margin-bottom: 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8c8c8c;
`;

export const TextArea = styled.textarea<IPropsTextArea>`
  resize: none;
  width: 100%;
  height: 150px;
  padding: 14px 16px;
  background: white;
  outline: none !important;
  border: 1px solid ${({borderColor}) => (borderColor ? "#F5222D" : "#8c8c8c")};
  border-radius: 3px;
  font-weight: 500;
  font-size: 16px;
  color: #131212;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &::placeholder {
    font-weight: 700;
    font-size: 18px;
    color: #8c8c8c;
  }
`;

export const ErrorMessage = styled.p`
  color: #f5222d;
  font-size: 14px;
  position: relative;
`;
