/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-constructor-return */
import { t } from "i18next";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import defaultUserIcon from "../../assets/defaultUserIcon.png";
import SkillHunterBookLogo from "../../assets/logo-book-skillhunter.svg";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SkillHunterLogo from "../../assets/sh-logo.svg";
import { Company } from "../../models/Company";
import TenantService from "../../services/TenantService";
import UserService from "../../services/UserService";
import RoleType from "../../types/enums/RoleType";
import * as S from "./styles";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Autocomplete,
  createFilterOptions,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AuthService from "../../services/AuthService";
import { ResponsiveSideBar } from "../ResponsiveSideBar";

import { BusinessCenter, Home, Settings } from "@mui/icons-material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { IFiltersHome } from "../../pages/Home";
import api, { axiosApi } from "../../services/api";
import { Button } from "../Button";
import { ResponsiveSideBarMobile } from "../ResponsiveSideBarMobile";
import { UserAcceptTermsModal } from "../UserAcceptTermsModal";
import { enqueueSnackbar } from "notistack";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

interface ISidebarOption {
  name?: string | number;
  value?: string | number;
  id?: number;
  any;
}

interface ILayout {
  children: ReactElement;
  mainScreen?: boolean;
  sidebarActive?: boolean;
  sidebarOptions?: ISidebarOption[] | any;
  sidebarOnClick?: (any) => any;
  setCityState?: (data: { city: string; state: string }) => void;
  setFilters?: any;
  sidebarSelected?: ISidebarOption | string;
  onlyHeader?: boolean;
  preview?: boolean;
  isTrainingPlans?: boolean;
  withSidebar?: boolean;
  noScroll?: boolean;
  screen?: number;
  setScreen?: any;
  disableLocalization?: boolean;
  resetPositions?: any;
  resetCourses?: any;
  filters?: any;
  advancedFilters?: boolean;
  setAdvancedFilters?: any
}

function PositionsLayout({
  children,
  mainScreen = false,
  sidebarActive = true,
  withSidebar,
  onlyHeader = false,
  preview = false,
  noScroll = false,
  setFilters,
  filters,
  advancedFilters,
  screen,
  setScreen,
}: ILayout) {
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState<any>(false);
  const [tenants, setTenants] = useState<Array<Company>>([]);
  const [tenant, setTenant] = useState<Company>();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showModalAcceptedTerms, setShowModalAcceptedTerms] = useState(false);
  const [states, setStates] = useState([]);
  const [positionsCoursesCompanies, setPositionsCoursesCompanies] = useState(
    []
  );
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchState, setSearchState] = useState("");

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "pt"
  );
  const filter = createFilterOptions();
  const clearRef = useRef(null);
  const stateRef = useRef(null);
  const [disable, setDisable] = useState(false);
  const location = useLocation();

  const fetchUser = async () => {
    const result = await UserService.getUser();
    setUser(result);
    setShowModalAcceptedTerms(!result?.acceptedTerms);
    return user;
  };

  const fetchDataTenant = async () => {
    const tenantsRes = await TenantService.getMyCompanies();
    const tenantObj = JSON.parse(localStorage.getItem("tenantObj"));
    if (tenantObj?.id) {
      setTenant(tenantObj);
    } else if (tenantsRes.length) {
      localStorage.setItem("tenant", tenantsRes[0].id.toString());
      localStorage.setItem("tenantObj", JSON.stringify(tenantsRes[0]));
    }
    setTenants(tenantsRes);
  };
  const onChangeTenant = (tenant) => {
    if (tenant) {
      localStorage.setItem("tenant", tenant?.id?.toString());
      localStorage.setItem("tenantObj", JSON.stringify(tenant));
    } else {
      localStorage.removeItem("tenant");
      localStorage.removeItem("tenantObj");
    }
    setTenant(tenant);
    window.location.reload();
  };

  const pages = [
    {
      title: "Dashboard",
      icon: Home,
      onClick: () => {
        preview ? handlePreview() :
          navigate("/dashboard");
      },
      accessLevel: [RoleType.ROLE_SYSADMIN, RoleType.ROLE_CLIENT],
    },
    {
      title: t("sidebar.positions"),
      icon: BusinessCenter,
      onClick: () => {
        preview ? handlePreview() :
          navigate("/home");
        if (location.pathname === '/home') {
          handleChange({ target: { value: 0 } })
        }
      },
      accessLevel: [RoleType.ROLE_SYSADMIN, RoleType.ROLE_CLIENT],
    },
    {
      title: t("sidebar.myCourses"),
      icon: LibraryBooksIcon,
      onClick: () => {
        {
          preview ? handlePreview() :
            navigate("/home");
          localStorage.setItem("prevPage", "trail")
        }
      },

      accessLevel: [RoleType.ROLE_SYSADMIN, RoleType.ROLE_CLIENT],
    },
    {
      title: t("sidebar.management"),
      icon: Settings,
      onClick: () => {
        preview ? handlePreview() :
          navigate("/management");
      },
      accessLevel: [RoleType.ROLE_SYSADMIN],
    },
  ].filter((page) => UserService.hasAccessFiltered(page.accessLevel));

  const settings = [
    {
      label: t("sidebar.editData"),
      callback: () => {
        navigate("/EditUser");
      },
    },
    {
      label: t("sidebar.logout"),
      callback: () => {
        AuthService.logout();
        localStorage.removeItem("token");
        navigate("/login");
      },
    },
  ];

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (localStorage.getItem("token")) {
        await fetchUser();
        fetchDataTenant();
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (noScroll) {
      window.document.body.style.overflowY = "hidden";
    } else {
      window.document.body.style.overflowY = "auto";
    }
  }, [noScroll]);

  useEffect(() => {
    if (localStorage.getItem("noAccess") == "1") {
      localStorage.setItem("noAccess", "0");
    } else {
      localStorage.setItem("lastPageAccessed", location.pathname);
    }
  }, [location.pathname]);

  const handleChangeLenguage = (value) => {
    setLanguage(value.target.value);
    localStorage.setItem("language", value.target.value);
    window.location.reload();
  };

  function filterData(data: any[]) {
    return data?.reduce((uniqueList, currentItem) => {
      const isNameUnique = uniqueList.every(item => item.name !== currentItem.name);
      if (isNameUnique) {
        uniqueList.push(currentItem);
      }
      return uniqueList;
    }, []);
  }

  async function getPositionCompany() {
    try {
      if (!localStorage.getItem("token")) {
        const positionCompanyResponse = await axiosApi.get(
          "/formation/filter-home-no-login"
        );
        setPositionsCoursesCompanies(filterData(positionCompanyResponse?.data));
        return;
      } else {
        const positionCompanyResponse = await api.get("/formation/filter-home");
        setPositionsCoursesCompanies(filterData(positionCompanyResponse?.data));
        return;
      }
    } catch (err) {
      return false;
    }
  }

  async function getCourseCompany() {
    try {
      if (!localStorage.getItem("token")) {
        const positionCompanyResponse = await axiosApi.get(
          "/trails/filter-home-no-login"
        );
        setPositionsCoursesCompanies(filterData(positionCompanyResponse?.data));
        return;
      } else {
        const positionCompanyResponse = await api.get("/trails/filter-home");
        setPositionsCoursesCompanies(filterData(positionCompanyResponse?.data));
        return;
      }
    } catch (err) {
      return false;
    }
  }

  //TODO /address
  useEffect(() => {
    if (searchState.length > 2) {
      getStates();
    }
  }, [searchState]);

  async function getStates() {
    try {
      const statesResponse = await axiosApi.get(
        `/address?filter=${searchState}&onlyCities=false`
      );
      setStates(statesResponse?.data);
    } catch (err) {
      return false;
    }
  }

  const handleStateChange = async (event, value) => {
    setFilters((prevState: IFiltersHome) => ({
      ...prevState,
      citySelected: value?.cityId || null,
      stateSelected: value?.stateId || null,
      countrySelected: value?.countryId || null,
      positionCompanySelectedPosition: null,
      positionCategorySelectedId: null,
      positionFilterTrainingPlans: null,
      positionPositionSelected: null,
      positionModality: null,
      positionMaxSalary: null,
      positionMinSalary: null,
      positionGetNullSalary: true,
      courseCategoryCourseSelected: null,
      courseSearch: null,
    }));
  };

  const handlePositionCourseCompanyChange = async (event, value) => {
    if (value) {
      setSearchInputValue(value?.name);
    } else {
      setSearchInputValue("");
    }
    setFilters({
      ...filters,
      headerPositionSelected:
        value?.formationId || value?.inputValue
          ? value?.name
          : typeof value == "string"
            ? value
            : null,
      companySelected: value?.companyId || null,
      courseSelected:
        value?.trailId || value?.inputValue
          ? value?.name
          : typeof value == "string"
            ? value
            : null,
      positionCompanySelectedPosition: null,
      positionCategorySelectedId: null,
      positionFilterTrainingPlans: null,
      positionPositionSelected: null,
      positionModality: null,
      positionMaxSalary: null,
      positionMinSalary: null,
      positionGetNullSalary: true,
      courseCategoryCourseSelected: null,
      courseSearch: null,
    });
  };


  useEffect(() => {
    const page = localStorage.getItem('prevPage')

    if (page === 'trail' && location.pathname === '/home') {
      handleChange({ target: { value: 1 } })
    }
    const fetch = async () => {
      if (screen === 0) {
        await getPositionCompany();
      } else if (screen === 1) {
        await getCourseCompany();
      }
      const clearIndicator = clearRef?.current?.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )?.[0];
      const clearStateIndicator = stateRef?.current?.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )?.[0];
      if (!!clearIndicator) {
        await clearIndicator?.click();
      }
      if (!!clearStateIndicator) {
        await clearStateIndicator?.click();
      }
      setDisable(!!screen);
      localStorage.removeItem('prevPage')
    };
    fetch();
  }, [screen, localStorage.getItem('prevPage')]);

  const handleChange = async (event) => {
    await setScreen(event.target.value);
  };

  const handlePreview = () => {
    enqueueSnackbar(t("positions.itsPreview"), { variant: "warning" })
  }
  return (
    <S.FullScreen>
      <S.HeaderFullContainer style={{ height: (!onlyHeader && advancedFilters && !disable) ? '120px' : '70px' }}>
        <S.CenterByMaxSize>
          {/* // ! DESKTOP HEADER */}
          <S.DesktopHeader>
            <S.Logo src={SkillHunterLogo} onClick={() => {
              preview ?
                handlePreview() :
                navigate("/")
            }} />
            <S.MenusContainer>
              {user !== null &&
                pages?.map((page) => {
                  return (
                    <MenuItem
                      key={`page-${page?.title}`}
                      sx={{
                        p: 0.6,
                        borderRadius: 1,
                        color: "#fff",
                      }}
                      onClick={preview ? handlePreview : page?.onClick}
                    >
                      <ListItemIcon>
                        <page.icon
                          style={{ color: "#fff" }}
                          fontSize="medium"
                        />
                      </ListItemIcon>
                      {!mainScreen && <ListItemText>{page.title}</ListItemText>}
                    </MenuItem>
                  );
                })}
              {mainScreen && (
                <div
                  style={{
                    display: "flex",
                    flex: 0.9,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Select
                    value={screen}
                    style={{ color: "#131212" }}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{
                      ".MuiSelect-outlined": {
                        background: "#FFF",
                        maxHeight: "21px",
                        minWidth: "55px",
                        padding: "8px 8px 9px 8px",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                      },
                    }}
                  >
                    <MenuItem value={0}>{t("positions.position")}</MenuItem>
                    <MenuItem value={1}>{t("courses.courses")}</MenuItem>
                  </Select>
                  <Autocomplete
                    id="positionsCoursesCompanies"
                    options={positionsCoursesCompanies}
                    ref={clearRef}
                    getOptionLabel={(option) => option.name}
                    onChange={handlePositionCourseCompanyChange}
                    autoComplete={false}
                    freeSolo
                    sx={{ flex: 1 }}
                    noOptionsText={t("noOptionsMui")}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      setSearchInputValue(inputValue);
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: inputValue,
                          id: -2,
                        });
                      }
                      return filtered;
                    }}
                    renderInput={(params) => {
                      params.inputProps.value = searchInputValue;
                      return (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <div>
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </div>
                            ),
                          }}
                          size="small"
                          sx={{
                            borderRadius: "5px",
                            width: "100%",
                            fontSize: 16,
                            fontWeight: 500,
                            outline: "none",
                            color: "#131212",
                            background: "#ffffff",

                            "& .MuiOutlinedInput-root": {
                              borderRadius: "0px",
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              borderRadius: "0px",
                            },
                          }}
                          placeholder={
                            screen === 0
                              ? t("searchPositionsCompany")
                              : t("searchCourses")
                          }
                          autoComplete="off"
                        />
                      );
                    }}
                  />
                  {!disable && (
                    <Autocomplete
                      id="states"
                      filterOptions={(x) =>
                        searchState.length > 2 ? states : []
                      }
                      options={searchState.length > 2 ? states : []}
                      getOptionLabel={(option) => option.name}
                      onChange={handleStateChange}
                      ref={stateRef}
                      disabled={disable}
                      sx={{ flex: 0.75 }}
                      autoComplete={false}
                      noOptionsText={
                        searchState.length > 2
                          ? t("noOptionsMui")
                          : t("typeToSearch")
                      }
                      renderOption={(params, opt) => (
                        <Typography
                          {...params}
                          key={`${opt.countryId}&${opt.stateId}&${opt.cityId}`}
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "19px",
                            paddingBottom: "19px",
                            backgroundColor: "auto",
                            gap: 13,
                            opacity: 1,
                            fontFamily: "Ubuntu",
                            fontWeight: 500,
                            fontSize: 16,
                            color: "#8c8c8c",
                          }}
                        >
                          {opt?.name}
                        </Typography>
                      )}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            value={searchState}
                            onChange={(e) => setSearchState(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <div>
                                  <InputAdornment position="start" style={{ position: 'absolute' }}>
                                    <RoomOutlinedIcon />
                                  </InputAdornment>
                                  {params.InputProps.startAdornment}
                                </div>
                              ),
                            }}
                            size="small"
                            sx={{
                              borderRadius: "5px",
                              width: "100%",
                              fontSize: 16,
                              fontWeight: 500,
                              outline: "none",
                              color: "#131212",
                              background: "#ffffff",

                              "& .MuiOutlinedInput-root": {
                                borderRadius: "0 5px 5px 0",
                              },
                              "& .MuiAutocomplete-inputRoot": {
                                borderRadius: "0 5px 5px 0",
                              },
                              "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
                                padding: '2.5px 4px 2.5px 39px'
                              }
                            }}
                            placeholder={t("positionsManagement.address")}
                            autoComplete="off"
                          />
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </S.MenusContainer>
            {user ? (
              <>
                <S.UserContainer onClick={preview ? handlePreview : handleOpenUserMenu}>
                  <S.UserImage src={user?.avatar?.fileUrl || defaultUserIcon} />
                  <div>
                    <S.UserText>
                      {t("sidebar.hello")}, {user?.name}!
                    </S.UserText>
                    <S.CompanyText>{tenant?.tradeName}</S.CompanyText>
                  </div>
                </S.UserContainer>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {tenants
                    .filter((i) => i.id !== tenant?.id)
                    .map((tenant: Company) => (
                      <MenuItem
                        key={`company-${tenant?.id}`}
                        onClick={() => {
                          preview ? handlePreview :
                            (onChangeTenant(tenant),
                              handleCloseUserMenu())
                        }}
                      >
                        <Typography textAlign="center">
                          {tenant.tradeName}
                        </Typography>
                      </MenuItem>
                    ))}
                  {settings?.map((setting) => (
                    <MenuItem key={setting?.label} onClick={preview ? handlePreview : setting?.callback}>
                      <Typography textAlign="center">
                        {setting?.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <>
                {!loading && (
                  <Button
                    style={{
                      width: 170,
                      background: "#fff",
                    }}
                    onClick={() => {
                      preview ? handlePreview :
                        navigate("/login")
                    }}
                  >
                    <p
                      style={{
                        color: "#0000",
                        background:
                          "-webkit-linear-gradient(45deg, #ff6423, #ff026f)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "#0000",
                      }}
                    >
                      {t("sidebar.login")}
                    </p>
                  </Button>
                )}
              </>
            )}
            <S.BlankSpace>
              <Select
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                  ".MuiSelect-select": {
                    display: "flex !important",
                    gap: "5px !important",
                    alignItems: "center !important",
                  },
                  width: "100px",
                  color: "white",
                  display: "flex !important",
                  gap: "5px !important",
                  alignItems: "center !important",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                label="Age"
                onChange={handleChangeLenguage}
              >
                <MenuItem
                  sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  value="pt"
                >
                  <span>PT-BR</span>
                </MenuItem>
                <MenuItem
                  sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  value="es"
                >
                  <span>ES</span>
                </MenuItem>
                <MenuItem
                  sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  value="en"
                >
                  <span>EN-US</span>
                </MenuItem>
              </Select>
            </S.BlankSpace>
          </S.DesktopHeader>
          {/* // ! TABLET / MOBILE HEADER */}
          <S.TabletMobileHeader>
            {sidebarActive && !onlyHeader && (
              <S.MenuIconContainer onClick={() => {
                preview ? handlePreview :
                  setShowSideBar(!showSideBar)
              }}>
                <MenuIcon style={{ color: "#fff", height: 40, width: 40 }} />
              </S.MenuIconContainer>
            )}
               {advancedFilters && !disable ? (
                 <Tooltip title={""} onClick={()=>navigate("/")}>
                 <KeyboardBackspaceIcon fontSize={"large"} sx={{color:"white", cursor: "pointer", marginBottom: "55px"}} />
                 </Tooltip>
               )
            : (
              <S.TabletMobileLogo
              src={SkillHunterBookLogo}
              onClick={() => {
                preview ? handlePreview() :
                  navigate("/")
              }}
            />
            )}
            {!showSideBar && !onlyHeader && (
              <div style={{ width: '30rem' }}>
                <Autocomplete
                  id="positionsCoursesCompanies"
                  options={positionsCoursesCompanies}
                  ref={clearRef}
                  getOptionLabel={(option) => option.name}
                  onChange={handlePositionCourseCompanyChange}
                  autoComplete={false}
                  freeSolo
                  sx={{ flex: 1 }}
                  noOptionsText={t("noOptionsMui")}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    setSearchInputValue(inputValue);
                    const isExisting = options.some(
                      (option) => inputValue === option.name
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: inputValue,
                        id: -2,
                      });
                    }
                    return filtered;
                  }}
                  renderInput={(params) => {
                    params.inputProps.value = searchInputValue;
                    return (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <div >
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                              {params.InputProps.startAdornment}
                            </div>
                          ),
                        }}
                        size="small"
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          fontSize: 16,
                          fontWeight: 500,
                          outline: "none",
                          color: "#131212",
                          background: "#ffffff",

                          "& .MuiOutlinedInput-root": {
                            borderRadius: "0px",
                          },
                          "& .MuiAutocomplete-inputRoot": {
                            borderRadius: "0px",
                          },
                        }}
                        placeholder={
                          screen === 0
                            ? t("searchPositionsCompany")
                            : t("searchCourses")
                        }
                        autoComplete="off"
                      />
                    );
                  }}
                />
                {!disable && advancedFilters && (
                  <Autocomplete
                    id="states"
                    filterOptions={(x) =>
                      searchState.length > 2 ? states : []
                    }
                    options={searchState.length > 2 ? states : []}
                    getOptionLabel={(option) => option.name}
                    onChange={handleStateChange}
                    ref={stateRef}
                    sx={{ flex: 1 }}
                    autoComplete={false}
                    noOptionsText={
                      searchState.length > 2
                        ? t("noOptionsMui")
                        : t("typeToSearch")
                    }
                    renderOption={(params, opt) => (
                      <Typography
                        {...params}
                        key={`${opt.countryId}&${opt.stateId}&${opt.cityId}`}
                        sx={{
                          p: 0,
                          m: 0,
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingTop: "19px",
                          paddingBottom: "19px",
                          backgroundColor: "auto",
                          gap: 13,
                          opacity: 1,
                          fontFamily: "Ubuntu",
                          fontWeight: 500,
                          fontSize: 16,
                          color: "#8c8c8c",
                        }}
                      >
                        {opt?.name}
                      </Typography>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          value={searchState}
                          onChange={(e) => setSearchState(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <div>
                                <InputAdornment position="start" style={{ position: 'absolute' }} >
                                  <RoomOutlinedIcon />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                              </div>
                            ),
                          }}
                          size="small"
                          sx={{
                            borderRadius: "5px",
                            width: "100%",
                            marginTop: "15px",
                            fontSize: 16,
                            fontWeight: 500,
                            outline: "none",
                            color: "#131212",
                            background: "#ffffff",

                            "& .MuiOutlinedInput-root": {
                              borderRadius: "0 5px 5px 0",
                            },
                            "& .MuiAutocomplete-inputRoot": {
                              borderRadius: "0 5px 5px 0",
                            },
                            "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
                              padding: '2.5px 4px 2.5px 39px'
                            }
                          }}
                          placeholder={t("positionsManagement.address")}
                          autoComplete="off"
                        />
                      );
                    }}
                  />
                )}
              </div>
            )}
            <Select
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
                ".MuiSelect-select": {
                  display: "flex !important",
                  gap: "5px !important",
                  alignItems: "center !important",
                },
                width: "100px",
                color: "white",
                display: "flex !important",
                gap: "5px !important",
                alignItems: "center !important",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={language}
              label="Age"
              onChange={handleChangeLenguage}
            >
              <MenuItem
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                value="pt"
              >
                <span>PT-BR</span>
              </MenuItem>
              <MenuItem
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                value="es"
              >
                <span>ES</span>
              </MenuItem>
              <MenuItem
                sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                value="en"
              >
                <span>EN-US</span>
              </MenuItem>
            </Select>

          </S.TabletMobileHeader>
        </S.CenterByMaxSize>
      </S.HeaderFullContainer>
      {!onlyHeader && (<div style={{ paddingTop: (advancedFilters && !disable) ? '50px' : ''}}></div>)}
      {/* // ! SIDEBAR */}
      {withSidebar && sidebarActive && !onlyHeader && (
        <S.ContentSidebar data-cy="sidebar-items" show={showSideBar}>
          <S.ContainerTemporaryInput>
            <S.TemporaryInput
              type="text"
              placeholder={t("sidebar.filter")}
            // onChange={(e: any) => setFilter(e.target.value)}
            />
          </S.ContainerTemporaryInput>
        </S.ContentSidebar>
      )}
      {/* // ! Sidebar Tablet */}
      {!onlyHeader && (
        <ResponsiveSideBar user={user} pages={pages} settings={settings} preview={preview}  />
      )}

      {/* // ! Sidebar Mobile */}

      <ResponsiveSideBarMobile user={user} pages={pages} settings={settings} preview={preview} />

      <S.ChildrenContainer withSidebar={withSidebar} onlyHeader={onlyHeader} advancedFilters={advancedFilters}>
        {showSideBar && !onlyHeader && (
          <S.ChildrenBlack onTouchStart={() => setShowSideBar(!showSideBar)}/>
        )}
        <S.CenterChildren>{children}</S.CenterChildren >
      </S.ChildrenContainer>
      <UserAcceptTermsModal
        open={showModalAcceptedTerms}
        onClose={() => setShowModalAcceptedTerms(false)}
      />
    </S.FullScreen>
  );
}

export default PositionsLayout;
