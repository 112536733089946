import api from "./api";

/** ******************HABILIDADES******************* */
export async function getAllSkillsPending() {
  const result = await api.get(`/v2/translations-skill/pending-evaluation`);
  return result?.data;
}

export async function approveSkillTranslation(data) {
  const result = await api.put(
    `/v2/translations-skill/aprove/${data.translation.id}`,
    data,
  );
  return result?.data;
}

export async function reproveSkillTranslation(id) {
  const result = await api.put(`/v2/translations-skill/reprove/${id}`);
  return result?.data;
}

export async function retrySkillTranslation(id) {
  const result = await api.post(`/v2/translations-skill/retry/${id}`);
  return result?.data;
}

/** ******************CURSOS******************* */
export async function getAllSkillTreesPending() {
  const result = await api.get(
    `/v2/translations-skill-tree/pending-evaluation`,
  );
  return result?.data;
}

export async function approveSkillTreesTranslation(data) {
  const result = await api.put(
    `/v2/translations-skill-tree/aprove/${data.translation.id}`,
    data,
  );
  return result?.data;
}

export async function reproveSkillTreesTranslation(id) {
  const result = await api.put(`/v2/translations-skill-tree/reprove/${id}`);
  return result?.data;
}

export async function retrySkillTreesTranslation(id) {
  const result = await api.post(`/v2/translations-skill-tree/retry/${id}`);
  return result?.data;
}

/** ******************ARTIGOS******************* */
export async function getAllArticlesPending() {
  const result = await api.get(`/v2/translations-article/pending-evaluation`);
  return result?.data;
}

export async function approveArticlesTranslation(data) {
  const result = await api.put(
    `/v2/translations-article/aprove/${data.translation.id}`,
    data,
  );
  return result?.data;
}

export async function reproveArticlesTranslation(id) {
  const result = await api.put(`/v2/translations-article/reprove/${id}`);
  return result?.data;
}

export async function retryArticlesTranslation(id) {
  const result = await api.post(`/v2/translations-article/retry/${id}`);
  return result?.data;
}

/** ******************POSITIONS******************* */
export async function getAllFormationsPending() {
  const result = await api.get(`/v2/translations-formation/pending-evaluation`);
  return result?.data;
}

export async function approveFormationsTranslation(data) {
  const result = await api.put(
    `/v2/translations-formation/aprove/${data.translation.id}`,
    data,
  );
  return result?.data;
}

export async function reproveFormationsTranslation(id) {
  const result = await api.put(`/v2/translations-formation/reprove/${id}`);
  return result?.data;
}

export async function retryFormationsTranslation(id) {
  const result = await api.post(`/v2/translations-formation/retry/${id}`);
  return result?.data;
}

/** ******************QUESTIONS******************* */
export async function getAllQuestionsPending() {
  const result = await api.get(`/v2/translations-question/pending-evaluation`);
  return result?.data;
}

export async function approveQuestionsTranslation(data) {
  const result = await api.put(
    `/v2/translations-question/aprove/${data.translation.id}`,
    data,
  );
  return result?.data;
}

export async function reproveQuestionsTranslation(id) {
  const result = await api.put(`/v2/translations-question/reprove/${id}`);
  return result?.data;
}

export async function retryQuestionsTranslation(id) {
  const result = await api.post(`/v2/translations-question/retry/${id}`);
  return result?.data;
}
