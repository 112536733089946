import { Checkbox, Modal } from "@mui/material";
import { t } from "i18next";
import MoneyInputOrange from "../../../../components/MoneyInputOrange";
import { SelectOrange } from "../../../../components/SelectOrange";
import { IFiltersHome } from "../../index";
import * as S from "./styles";
import Button from "../../../../Global/Button";
import { useEffect } from "react";


export default function AdvancedFilters({
    filters,
    setFilters,
    mobile,
    possibleFilters,
    advancedFilters,
    setAdvancedFilters,
}: {
    filters: IFiltersHome;
    setFilters: any;
    mobile: any;
    possibleFilters: any;
    advancedFilters?: boolean;
    setAdvancedFilters?: any;
}) {
    const showPositionFilter = mobile || !filters.headerPositionSelected;
    
    useEffect(() => {
      if(filters.screen === 1){
        setAdvancedFilters()
      }
      }, [filters.screen]);

    return (

        <S.FullContainer style={{display: !advancedFilters ? "none" : ""}}>
            {advancedFilters && (
                <>
                    <S.Title >{t("home.advancedSearches")}</S.Title>
                    {!filters.companySelected && (
                        <SelectOrange
                            style={{ minWidth: "100%", width: "calc((100% / 6) - 16px)" }}
                            placeholder={t("home.companies")}
                            options={possibleFilters?.companies || []}
                            selected={filters?.positionCompanySelectedPosition}
                            onSelect={(e) => {
                                setFilters({ ...filters, positionCompanySelectedPosition: e });
                            }}
                            onChange={(e) => { e }}
                            listAbsolute
                        />
                    )}
                    {showPositionFilter && (
                        <SelectOrange
                            style={{ minWidth: "100%", width: "calc((100% / 6) - 16px)" }}
                            placeholder={t("positions.position")}
                            options={possibleFilters?.formations || []}
                            selected={filters?.positionPositionSelected}
                            onSelect={(e) => {
                                setFilters({ ...filters, positionPositionSelected: e });
                            }}
                            onChange={(e) => { e }}
                            listAbsolute
                        />
                    )}
                    <SelectOrange
                        style={{ minWidth: "100%", width: "calc((100% / 6) - 16px)"}}
                        placeholder={t("home.modalities")}
                        options={
                            possibleFilters?.modalities?.map((m: { value: any; name: any; }) => ({
                                value: m.value, name: t(m.name),
                            })) || []
                        }
                        selected={filters?.positionModality}
                        onSelect={(e) => {
                            setFilters({ ...filters, positionModality: e });
                        }}
                        onChange={(e) => { e }}
                        listAbsolute
                    />
                    <div style={{ display: 'flex', flexDirection: "row" }}>
                        <MoneyInputOrange
                            style={{ minWidth: "45%", width: 'calc((100% / 6) - 16px)' }}
                            label={t("positionsManagement.startMoney")}
                            value={filters.positionMinSalary} onChange={(e) => {
                                setFilters({ ...filters, positionMinSalary: e });
                            }}
                        />
                        <MoneyInputOrange label={t("positionsManagement.endMoney")}
                            style={{ minWidth: "45%", width: 'calc((100% / 6) - 16px)', marginLeft: "10%" }}
                            value={filters.positionMaxSalary} onChange={(e) => {
                                setFilters({ ...filters, positionMaxSalary: e });
                            }}
                        />
                    </div>
                    <span style={{
                        minWidth: "200px",
                        width: "calc((100% / 6) - 16px)",
                        display: "flex",
                    }}
                    >
                        <Checkbox
                            checked={filters.positionGetNullSalary}
                            value={filters.positionGetNullSalary}
                            onChange={(e) => {
                                setFilters({
                                    ...filters, positionGetNullSalary: e.target.checked,
                                });
                            }}
                        /><div style={{ maxWidth: "155px" }}>{t("includeNullValueTranslation")}</div>
                    </span>

                    <S.RowContainerButtons>
                        <Button onClick={setAdvancedFilters}>
                            {t("home.findCompanies")}
                        </Button>
                    </S.RowContainerButtons>
                </>
            )}
        </S.FullContainer>
    );
}