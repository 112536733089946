import styled from "styled-components";

export const ContainerCheckboxs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxContainer = styled.div`
  margin: 2px 0;
  margin-left: -6px;

  cursor: pointer;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const CheckboxLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 400; 
  display: flex; 
  flex-direction: column;
  img { 
    width: 300px; 
    max-height: 300px;
    margin-bottom: 0.5rem; 
  }
`;

export const CheckboxOutCircle = styled.div<{ selected: boolean }>`
  position: absolute;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(90deg, #ff6423 0%, #ff026f 100%) border-box"
      : "linear-gradient(white, white) padding-box, linear-gradient(90deg, #696969 0%, #696969 100%) border-box"};
  border-radius: 2px;
  border: 2px solid transparent;
`;

export const CheckboxInnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  border-radius: 50%;
`;
