import {InputHTMLAttributes} from "react";
import * as S from "./styles";

export interface IInputResponsiveProps
  extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: any;
  errorMessageColor?: string;
  label?: string;
  mask?: any;
  borderColor?: string;
  backgroundColor?: string;
  size?: any;
  customContainerHeight?: string;
  disabled?: boolean;
}

export function ResponsiveInputText({
  errorMessage,
  errorMessageColor,
  backgroundColor,
  borderColor,
  mask,
  label,
  className,
  customContainerHeight,
  disabled,
  ...props
}: IInputResponsiveProps) {
  return (
    <div>
      <S.Label>{label}</S.Label>
      <S.ContainerResponsiveInputText
        className={className}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        customContainerHeight={customContainerHeight}
        disabled={disabled}
      >
        <S.InputResponsiveText
          {...props}
          {...(disabled ? {disabled: "disabled"} : {})}
          mask={mask}
        />
      </S.ContainerResponsiveInputText>
      <S.ErrorMessage data-cy="errorMessage">{errorMessage}</S.ErrorMessage>
    </div>
  );
}
