import api from "./api";

class UserFormationStatusService {

  async save(body: any, id?: number) {
    if (id) {
      return await api.put(`formation/status/${id}`, { status: body.status });
    }
    await api.post(`formation/status`, body);
  }
}

export default new UserFormationStatusService();