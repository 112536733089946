import styled from "styled-components";

interface IStatusProps {
  status: "complete" | "pending";
}

export const FullModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 652px;
  max-width: 94%;
  height: 80vh;
  max-height: 98%;;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 3px;
  padding: 40px 32px;

  & > * {
    //max-height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  &:focus {
    outline: none !important;
  }

  &.expanded {
    height: 100%;
  }

  &.collapsed {
    height: 750px;
  }

  @media (max-width: 425px) {
    padding: 40px 20px;
  }
`;

const SecondaryTypography = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 4rem;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  color: #131212;
  line-height: 32px;
  margin-bottom: 17px;
`;

const ColumnFixed = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #131212;
`;
export const SubtitleModal = styled(SecondaryTypography)`
  color: #131212;
`;

export const ColumnUser = styled(ColumnFixed)`
  grid-area: columnUser;
`;
export const ColumnFormation = styled(ColumnFixed)`
  grid-area: columnFormation;
`;
export const ColumnProgress = styled(ColumnFixed)`
  grid-area: columnProgress;
`;
export const ColumnCompletedSkills = styled(ColumnFixed)`
  grid-area: columnCompletedSkills;
`;
export const ColumnSubscribedAt = styled(ColumnFixed)`
  grid-area: columnSubscribedAt;
`;
export const ColumnUpdatedAt = styled(ColumnFixed)`
  grid-area: columnUpdatedAt;
`;

export const UserName = styled(SecondaryTypography)`
  grid-area: userName;
  color: #8c8c8c;
`;
export const Position = styled(SecondaryTypography)`
  grid-area: position;
  color: #131212;
`;
export const CompletedSkills = styled(SecondaryTypography)`
  grid-area: completedSkills;
  color: #8c8c8c;
`;
export const SubscribedAt = styled(SecondaryTypography)`
  grid-area: subscribedAt;
  color: #8c8c8c;
`;
export const UpdatedAt = styled(SecondaryTypography)`
  grid-area: updatedAt;
  color: #8c8c8c;
`;
export const Progress = styled.div`
  grid-area: progress;
`;
export const GridSection = styled.div`
  margin-top: 2.25rem;
  position: relative;
  display: grid;
  grid-template-areas:
    "columnUser userName"
    "columnFormation position"
    "columnCompletedSkills completedSkills"
    "columnSubscribedAt subscribedAt"
    "columnUpdatedAt updatedAt"
    "columnProgress progress";
  grid-column-gap: 3rem;
  grid-row-gap: 0.75rem;
  grid-template-columns: 1fr 1.25fr;

  @media (max-width: 425px) {
    grid-column-gap: 1rem;
  }

  progress[value] {
    height: 7px;

    ::-webkit-progress-bar {
      height: 7px;
    }

    ::-webkit-progress-value {
      height: 7px;
    }
  }
`;
export const ContainerTitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  margin-left: 0.75rem;  
`;

export const ContainerTitleAndSubtitleExpanded = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0.75rem;  
`;
export const TitleRowExpanded = styled(SecondaryTypography)`
  color: #131212;
`;
export const SubtitleRowExpanded = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #131212;
`;
export const Status = styled.p<IStatusProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ status }) => (status === "complete" ? "#00A676" : "#c4c4c4")};
`;
export const ContainerOfProgressBarInAccordionRow = styled.div`
  display: flex;
  align-items: center;

  progress[value] {
    height: 7px;
    width: 13vw;
    margin-right: 1rem;

    ::-webkit-progress-bar {
      height: 7px;
    }

    ::-webkit-progress-value {
      height: 7px;
    }
    @media (max-width: 425px) {
      width: 45vw;
    }
  }
  
`;
export const TitleOfAccordionRow = styled(SecondaryTypography)`
  word-break: break-word;
  width: 100%;
  color: #8c8c8c;
`;
export const Skills = styled(SecondaryTypography)`
  flex: 1;
  color: #8c8c8c;
`;
export const NumberOfAccordionRow = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #ff026f;
  margin-right: 0.5rem;
`;

export const ContainterToResponsiveness = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  cursor: pointer;
  @media (max-width: 425px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;