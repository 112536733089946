import React, { useState, useEffect } from "react";
import * as S from "./styles";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";

function Radio({ options, setChange, change, questionIndex, type }: any) {
  const [checkbox, setCheckbox] = useState<any>(change || []);
  function handleMultiselect(choice) {
    if (checkbox.includes(choice)) {
      const newSelected = checkbox.filter(
        (option: any) => option.id != choice.id
      );
      setCheckbox(newSelected);
      setChange(newSelected);
    } else {
      setCheckbox([...checkbox, choice]);
      setChange([...checkbox, choice]);
    }
  }

  useEffect(() => {
    setCheckbox(change || []);
  }, [type, questionIndex]);

  return (
    <S.ContainerCheckboxs data-cy="challengePageCheckboxOptions">
      {options?.map((quest, index) => (
        <S.CheckboxContainer
          key={index}
          onClick={() => handleMultiselect(quest)}
        >
          <MenuItem
            style={{
              minWidth: 32,
              maxWidth: 32,
              minHeight: 32,
              maxHeight: 32,
              borderRadius: 32,
            }}
            sx={{
              color: "rgba(236, 73, 144)",
              p: 0,
              m: 0,
              ":hover": {
                backgroundColor: "rgba(236, 73, 144, 0.15)",
              },
            }}
          >
            <S.CheckboxOutCircle selected={checkbox?.includes(quest)}>
              {checkbox?.includes(quest) && (
                <CheckIcon
                  sx={{ fontSize: 16, color: "#fff" }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    padding: 0,
                    margin: 0,
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </S.CheckboxOutCircle>
          </MenuItem>
          {quest.image?.fileUrl ?
            <S.CheckboxLabel>
              <img src={quest.image.fileUrl} alt="" />
              {quest.content}
            </S.CheckboxLabel>
            :
            <S.CheckboxLabel>{quest.content}</S.CheckboxLabel>
          }
        </S.CheckboxContainer>
      ))}
    </S.ContainerCheckboxs>
  );
}

export default Radio;
