import React, { useState } from 'react';
import * as S from "./styles";
import { Button } from "@mui/material";
import { t } from "i18next";

export function SolicitationScreen({
  trail,
  open,
}) {
  const [label, setLabel] = useState(trail == true ? t("positionsManagement.solicitationLabelTrail") : t("positionsManagement.solicitationLabelPosition"));

  return (
    <S.Container>
      <S.Title>
        {label}<br />
      </S.Title>
      <S.Subtitle>
        {t("positionsManagement.solicitationSubtitle")}
      </S.Subtitle>
      <S.ContainerButton>
        <Button
          variant="contained"
          size="large"
          onClick={() => { open(true) }}
          sx={{
            background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
            color: "white",
            fontWeight: "700",
            width: "325px",
            height: "57px",
            transition: "background 0.3s ease",
            '&:hover': {
              background: "linear-gradient(90deg, #FF026F 0%, #FF6423 100%)",
            },
          }}
        >
          {!trail ? t("positionsManagement.solicitationPosition") : t("positionsManagement.solicitationTrail")}
        </Button>
      </S.ContainerButton>
    </S.Container>
  );
}

