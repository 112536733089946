import * as React from 'react'
import {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {t} from 'i18next'
import "react-quill/dist/quill.snow.css";
import * as S from "./styles";
import {Backdrop, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {TextareaAutosize} from '@mui/base/TextareaAutosize';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {enqueueSnackbar} from "notistack";
import HelpContactService from "../../services/HelpContactService";

interface IContactModal {
  open: boolean
  onClose: () => void
}

export default function ContactModal(options: IContactModal) {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const contactSchema = Yup.object({
    subject: Yup.string().min(1, t('helpMenu.subjectError')),
    message: Yup.string().min(1, t('helpMenu.messageError')),
  })

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const handleClose = () => {
    options.onClose()
    setMessageSent(false)
    setValue('subject', '')
    setValue('message', '')
  }

  const handleAgreed = async () => {
    const newMessage = {subject: getValues('subject'), message: getValues('message')}
    try {
      setLoading(true)
      await HelpContactService.sendMessage(newMessage)
      setMessageSent(true)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={options?.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" sx={{width: {xs: 'auto', lg: '600px'}}}>
        {messageSent ? t("helpMenu.messageSent") : t("helpMenu.getInTouch")}
      </DialogTitle>
      {
        messageSent ?
          <DialogContent>
            <Typography variant="body1">{t("payment.contactSoon")}</Typography>
          </DialogContent>
          :
          <DialogContent>
            <Controller
              name="subject"
              defaultValue=""
              control={control}
              render={({field: {onChange, value}}) => (
                <TextField
                  value={value}
                  onChange={(value) => onChange(value || "")}
                  sx={{width: '100%'}}
                  placeholder={t("helpMenu.subject")}
                />
              )}
            />
            {errors?.subject?.message &&
                <Typography sx={{fontSize: '0.8rem', color: '#f5222d'}}>{errors?.subject?.message}</Typography>
            }
            <Controller
              name="message"
              defaultValue=""
              control={control}
              render={({field: {onChange, value}}) => (
                <TextareaAutosize
                  value={value}
                  onChange={(value) => onChange(value || "")}
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    padding: '0.75rem',
                    marginTop: '1rem'
                  }}
                  minRows={4}
                  maxRows={32}
                  placeholder={t("helpMenu.message")}
                />
              )}
            />
            {errors?.message?.message &&
                <Typography sx={{fontSize: '0.8rem', color: '#f5222d'}}>{errors?.message?.message}</Typography>
            }
          </DialogContent>
      }
      <DialogActions>
        {
          messageSent ?
            <S.ContainerButton>
              <Button variant="contained"
                      color="primary"
                      sx={{
                        background: "linear-gradient(90deg, #FF6423 0%, #FF026F 100%)",
                        color: "white",
                        marginTop: "10px",
                      }}
                      onClick={handleClose}>
                {t("evaluate.confirm")}
              </Button>
            </S.ContainerButton>
            :
            <S.ContainerButton>
              <S.ButtonDS
                typeOfButton="secondary"
                size="custom"
                height={40}
                width={200}
                fontVariant="body1"
                text={t("performanceReport.cancel")}
                onClick={handleClose}
              />
              <S.ButtonDS
                typeOfButton="primary"
                size="custom"
                height={40}
                width={200}
                fontVariant="body1"
                text={t('evaluate.send')}
                disabled={loading}
                onClick={handleSubmit(handleAgreed)}
              />
            </S.ContainerButton>
        }
      </DialogActions>
      {
        loading &&
          <Backdrop
              sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
              open={loading}
              onClick={null}
          >
              <CircularProgress color="inherit"/>
          </Backdrop>
      }
    </Dialog>
  )
}
