/* eslint-disable react/no-unstable-nested-components */
import { zodResolver } from "@hookform/resolvers/zod";
import { InfoOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button as ButtonDS } from "design-system-firedev";
import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { File } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as zod from "zod";
import SetaGray from "../../../assets/setaGray.svg";
import TreeWhiteIcon from "../../../assets/TreeWhiteIcon.svg";
import { ImageSuccess as ImageSuccessDS } from "../../../components/assets/icon/alerts/verified";
import BackButton from "../../../components/BackButton";
import { Button } from "../../../components/Button";
import CardTree from "../../../components/CardTree";
import CheckboxMultiselect from "../../../components/Checkboxes";
import { Select } from "../../../components/Select";
import { options } from "../../../components/utils/languageOptions";
import AddressService from "../../../services/AddressService";
import SkillTreeService from "../../../services/SkillTreeService";
import TrailCategoryService from "../../../services/TrailCategoryService";
import TrailService from "../../../services/TrailService";
import ConfirmationModal from "../confirmModal";
import SkillsModal from "../skillsModal";
import SkillTreeNameModal from "../skillTreeNameModal";
import * as S from "./styles";
import UserService from "../../../services/UserService";
import CompanyService from "../../../services/CompanyService";

interface IPlansForm {
  onClickBack: () => any;
  action?: {
    data?: any;
    type?: "add" | "edit";
  };
  feedbackAction: CallableFunction;
}

function TrailsForm({
  onClickBack,
  action,
  feedbackAction,
}: IPlansForm) {
  const [showSkillsModal, setShowSkillsModal] = useState<boolean>(false);
  const [showSkillTreeNameModal, setShowSkillTreeNameModal] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [modalData, setModalData] = useState();
  const [search, setSearch] = useState<string>("");
  const [selectedSkillTree, setSelectedSkillTree] = useState<any>({});
  const [subtractedValue, setSubtractedValue] = useState(-2);
  const [allCompetencesOptions, setAllCompetencesOptions] = useState([]);
  const [loadingCompetences, setLoadingCompetences] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isContract, setIsContract] = useState(false);
  const [searchCompetences, setSearchCompetences] = useState("");
  const [openSkillTrees, setOpenSkillTrees] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(null);
  const [allTrees, setAllTrees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formationPayloadToSave, setFormationPayloadToSave] = useState(null);
  const [selectedSkillsWithoutChallenges, setSelectedSkillsWithoutChallenges] =
    useState([]);
  const [isGeneratingChallenges, setIsGeneratingChallenges] = useState(false);
  const [AIChallengeGenerationCompletion, setAIChallengeGenerationCompletion] =
    useState(0);
  const [hasMinCharacters, setHasMinCharacters] = useState(false);
  const [allPositions, setAllPositions] = useState([]);
  const [companyAddresses, setCompanyAddresses] = useState([]);
  const serviceRef = useRef(null);
  const [
    optionsSelectedDropdownMultiSelect,
    setOptionsSelectedDropdownMultiSelect,
  ] = useState([]);
  const [newOptions, setNewOptions] = useState(options);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [localLanguage, setLocalLanguage] = useState(
    localStorage.getItem("language")
  );
  const [openModal, setOpenModal] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [companies, setCompanies] = useState([]);

  const filter = createFilterOptions();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getColor = (status: any) => {
    switch (status) {
      case "ACHIEVED":
        return "#00A676";
      case "UNLOCKED":
        return "#302382";
      case "WAITING_EVALUATION":
        return "#FF9900";
      default:
        return "#C4C4C4";
    }
  };


  const user = JSON.parse(localStorage.getItem("userRoles"));
  const isSysAdmin = user[0].authority === "ROLE_SYSADMIN";

  const getAllCategory = async () => {
    const trailCategoryRest =
      await TrailCategoryService.getTrailCategoryToDropdown("");
    setCategoryData(trailCategoryRest);
  };

  const schema = zod.object({
    company: zod.any().refine((val) => {
      if (isSysAdmin) {
        return val !== undefined && val !== null && val.id !== null;
      }
      return true;
    }, {
      message: t("positionsManagement.selectAddCompany"),
    }),
    title: zod
      .union([
        zod.object({
          id: zod.number().optional(),
          title: zod.string().refine((val) => val.trim().length > 0, {
            message: t("positionsManagement.selectAddPosition"),
          }),
        }),
        zod.null(),
      ])
      .refine(
        (val) => val !== null,
        t("positionsManagement.selectAddPosition")
      ),
    category: zod.object(
      {
        id: zod.number(),
        value: zod.string().optional(),
      },
      { required_error: t("coursesManagement.selectCategoryErrorMessage") }
    ),
    about: zod
      .string()
      .min(30, t("positionsManagement.descriptionMinLength")),
    published: zod.boolean(),
    languages: zod.any(),
    visibility: zod.object({
      id: zod.number(),
      name: zod.string(),
    }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    setError,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      company: { id: null, value: null, name: "" },
      title: { id: null, title: "" },
      about: "",
      category: null,
      competences: [],
      skillTreeIds: [],
      published: null,
      visibility: {
        id: null,
        name: "",
      },
      skills: [],
      languages: [],
    },
  });

  function formatSalaryValue(salary) {
    const salaryWithoutSymbol = salary.replace("R$", "").trim();
    const salaryWithoutSeparator = salaryWithoutSymbol
      .replaceAll(".", "")
      .replace(",", ".");
    const salaryNumber = parseFloat(salaryWithoutSeparator);
    return salaryNumber;
  }

  function formatCurrency(value) {
    if (value == null) {
      return "";
    }
    const formattedValue = value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  }

  function Checkbox({
    visible,
    onClick,
    disabled = false,
  }: {
    visible: boolean;
    onClick?: (any) => any;
    disabled?: boolean;
  }) {
    return (
      <MenuItem
        onClick={onClick && onClick}
        disabled={disabled}
        style={{
          minWidth: 32,
          maxWidth: 32,
          minHeight: 32,
          maxHeight: 32,
          borderRadius: 32,
        }}
        sx={{
          color: "rgba(236, 73, 144)",
          p: 0,
          m: 0,
          ":hover": {
            backgroundColor: "rgba(236, 73, 144, 0.15)",
          },
        }}
      >
        <S.CheckboxOutCircle selected={visible}>
          {visible && (
            <CheckIcon
              sx={{ fontSize: 16, color: "#fff" }}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                padding: 0,
                margin: 0,
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
        </S.CheckboxOutCircle>
      </MenuItem>
    );
  }

  async function getAllCompetences() {
    setLoadingCompetences(true);
    const response = await SkillTreeService.getSkillTreeToDropdownWithCompany(
      search
    );

    const responseMap =
      response?.map((item) => ({
        ...item,
        label: item?.value,
        id: item.id,
        description: item?.description,
      })) || [];
    setAllCompetencesOptions(responseMap.sort((a, b) => b.id - a.id));
    setLoadingCompetences(false);
  }

  async function getAllPositionsToDropdown() {
    const response = await TrailService.getBaseTrail();
    setAllPositions(response.sort((a, b) => b.id - a.id));
  }

  function decreaseSubtractedValue() {
    setSubtractedValue((value) => value - 1);
  }

  const handleCloseModal = () => {
    setIsChecked(false);
    setOpenModal(false);
  };

  async function addSkills(competencesSelected) {
    if (selectedSkillTree.id === subtractedValue) {
      decreaseSubtractedValue();
    }

    if (allTrees.some((tree) => tree.id === selectedSkillTree?.id)) {
      let newAllTrees = allTrees.map((tree) =>
        tree.id === selectedSkillTree?.id
          ? {
            ...tree,
            skills: competencesSelected,
          }
          : tree
      );
      setAllTrees(newAllTrees);
    } else {
      allTrees.push({ ...selectedSkillTree, skills: competencesSelected });
    }

    if (!openSkillTrees) {
      setOpenSkillTrees(true);
    }
  }

  function changeCompetenceName(newName) {
    let newCompetenceName = allTrees.map((tree) =>
      tree.id === selectedSkillTree?.id
        ? {
          ...tree,
          value: newName,
          label: newName,
        }
        : tree
    );
    setAllTrees(newCompetenceName);
  }

  function removeSkill(skillToRemove, tree) {
    const updatedSkills = tree.skills.filter(
      (skill) => skill.id !== skillToRemove.id
    );
    let newSkillTrees = allTrees.map((t) =>
      t.id === tree.id ? { ...t, skills: updatedSkills } : t
    );
    setAllTrees(newSkillTrees);
  }

  async function mandatorySkill(skillMandatory, tree) {
    const updatedSkills = tree.skills.map((skill) =>
      skill.id === skillMandatory?.id
        ? {
          ...skill,
          mandatory: !skill.mandatory,
        }
        : skill
    );
    let newSkillTrees = allTrees.map((t) =>
      t.id === tree.id ? { ...t, skills: updatedSkills } : t
    );
    setAllTrees(newSkillTrees);
  }

  async function sendData(data) {
    try {
      data.languages = data?.languages.map((language) => language.value);
      if (allTrees.length <= 0) {
        enqueueSnackbar(t("positionsManagement.addCompetence"), {
          variant: "warning",
        });

        return;
      }

      allTrees.forEach((t) => {
        if (t.skills.length <= 0) {
          enqueueSnackbar(
            `${t("positionsManagement.competence")} ${t.label} ${t(
              "positionsManagement.noSelectedSkills"
            )}`,
            { variant: "warning" }
          );
          throw new Error(t("positionsManagement.competenceNoSelectedSkills"));
        }
      });

      let skillsWithoutChallenge = [];

      allTrees.map((st) => {
        skillsWithoutChallenge = skillsWithoutChallenge.concat(
          st.skills.filter(
            (s) =>
              s.numberQuestions === 0 ||
              s.numberQuestions === null ||
              s.id === -2
          )
        );
      });

      setIsLoading(true);
      const formationDTO = {
        title: data.title?.title,
        baseId: data?.title?.id >= 0 ? data.title.id : null,
        description: data.about,
        skillTrees: allTrees.map((tree) => ({
          id: tree?.id,
          name: tree?.value || tree.label,
          skills: tree.skills.map((skill) => ({
            ...skill,
            id: skill?.id < 0 ? null : skill?.id,
            mandatory: skill?.mandatory || false,
          })),
          description: tree.description,
          mandatory: tree?.skills?.every((sk) => sk.mandatory) || false,
        })),
        category: data.category,
        visibility: data.visibility.id === 1 ? "PUBLIC" : "PRIVATE",
        published: data?.published,
        hide: isHidden,
        languages: data?.languages,
        generateByAI: false,
        companyId: isSysAdmin ? data?.company?.id : null,
      };

      if (skillsWithoutChallenge.length > 0) {
        setSelectedSkillsWithoutChallenges(skillsWithoutChallenge);
        setShowConfirmationModal(true);
        setFormationPayloadToSave(formationDTO);
        return;
      }
      await saveFormation(formationDTO);
      feedbackAction("success", action?.type);
      onClickBack();
    } catch (err: any) {
      console.error(err);
      setIsLoading(false);
    }
  }

  async function saveFormation(formationDTO: {
    companyId: any;
    title: any;
    description: any;
    baseId: any;
    skillTrees: { id: any; name: any; skills: any; description: any }[];
    visibility: string;
    published: boolean;
    hide: boolean;
    generateByAI?: boolean;
    category: any;
  }) {
    setIsLoading(true);
    if (action?.type === "add") {
      await TrailService.addTrail(formationDTO);
      enqueueSnackbar(t("positionsManagement.positionSuccessfullyAdded"), {
        variant: "success",
      });
    } else if (action?.type === "edit") {
      await TrailService.editTrail(action?.data?.id, formationDTO);
      enqueueSnackbar(t("positionsManagement.positionSuccessfullyEdited"), {
        variant: "success",
      });
    }

    setIsLoading(false);
    onClickBack();
  }

  async function setEditData() {
    const response = await TrailService.findIdTrail(action?.data?.id);
    setIsGeneratingChallenges(response?.generatingChalenges);
    setAIChallengeGenerationCompletion(response?.generationPercentage);
    setValue(
      "title",
      response?.base || { id: response?.base?.id, title: response.title }
    );
    setValue("category", response?.category);
    setValue("about", response?.description);
    setValue("visibility", {
      id: response?.visibility === "PUBLIC" ? 1 : 0,
      name:
        response?.visibility === "PUBLIC"
          ? t("performanceReport.externalRecruitment")
          : t("performanceReport.internalRecruitment"),
    });
    setValue("company", {
      id: response?.company.id,
      name: isSysAdmin ? response?.company?.name : null,
      value: response?.company.id,
    });
    setIsDraft(!response.published);
    setIsHidden(response?.hide);
    setIsContract(response?.hiring);
    setAllTrees(
      response?.skillTrees.map((tree) => ({
        id: tree?.id,
        label: tree?.name,
        skills: tree?.skills,
        description: tree?.description,
        mandatory: tree?.mandatory,
      }))
    );
    const newOptionsLanguage = newOptions.filter(
      (option) =>
        response?.languages?.includes(option?.value) &&
        option?.value !== localLanguage
    );
    setValue("languages", newOptionsLanguage);

    setOpenSkillTrees(true);
  }

  const allCompanies = async () => {
    const result = await CompanyService.getAllMapped("");
    setCompanies(result);
  }

  async function getBaseSkillTrees(id: number) {
    let response;
    response = await TrailService.findIdTrail(id);
    setAllTrees(
      response?.skillTrees.map((tree) => ({
        id: tree?.id,
        label: tree?.name,
        skills: tree?.skills,
        description: tree?.description,
        mandatory: tree?.mandatory,
      }))
    );
    setOpenSkillTrees(true);
  }

  async function getAllAddresses() {
    const response = await AddressService.getCurrentCompanyAddresses();
    setCompanyAddresses(response);
  }

  const handleClosePrivate = () => {
    setOpenModal(false);
    setValue("visibility", { id: 0, name: t("performanceReport.internalRecruitment") });
  };

  const isEditing = action?.type === "edit";

  useEffect(() => {
    setLocalLanguage(localStorage.getItem("language"));
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    if(isSysAdmin){
      allCompanies();
    }
    if(!isSysAdmin && !action?.data) {
      setValue("visibility", {
        id: 0,
        name: t("performanceReport.internalRecruitment")
      })
    }
    getAllAddresses();
    getAllCompetences();
    getAllPositionsToDropdown();
    getAllCategory();
    if (action?.type === "edit") {
      setEditData();
    }
    setNewOptions(
      options.filter((language) => language.value !== (localLanguage || "pt"))
    );
  }, []);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };


  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "script",
    "underline",
    "blockquote",
    "header",
    "indent",
    "list",
    "align",
    "direction",
    "code-block",
    "formula",
    // 'image'
    // 'video'
  ];

  useEffect(() => {
    if (allTrees.length > 0) {
      setExpanded(allTrees[0]?.id);
    }
  }, [allTrees]);

  return (
    <S.ContainerPlansForm>
      <BackButton
        style={{ marginTop: 36, marginBottom: 36, width: 30 }}
        onClick={onClickBack}
      >
        {t("positionsManagement.back")}
      </BackButton>
      {isGeneratingChallenges === true && (
        <div>
          <Alert
            style={{ width: "100%", textAlign: "left", marginTop: "20px" }}
            severity="warning"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t("positionsManagement.cannotEditOrPublish", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />{" "}
            <b>{t("positionsManagement.generationProgress")}</b>
          </Alert>
          <S.ContainerProgressBar>
            <Box sx={{ width: "100%", mr: 1 }}>
              <S.BorderLinearProgress
                variant="buffer"
                value={AIChallengeGenerationCompletion}
                valueBuffer={AIChallengeGenerationCompletion}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                AIChallengeGenerationCompletion
              )}%`}</Typography>
            </Box>
          </S.ContainerProgressBar>
        </div>
      )}
      {isSysAdmin && (
        <Controller
          name="company"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div >
              <Select
                showSearch
                options={companies}
                selected={value}
                onSelect={(newValue) => onChange(newValue)}
                placeholder={t("positionsManagement.company")}
                label={t("positionsManagement.company")}
                error={errors?.company?.message}
              />
            </div>
          )}
        />
      )}
      <S.ContainerDropDownSimpleSelect>
        <Controller
          name="category"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              showSearch
              selected={value}
              onSelect={(newValue) => onChange(newValue)}
              placeholder={t("coursesManagement.category")}
              label={t("coursesManagement.category")}
              error={errors?.category?.message}
              options={categoryData}
            />
          )}
        />
      </S.ContainerDropDownSimpleSelect>
      <div>
        <S.Label>
          {t("evaluations.course")}
        </S.Label>
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              disablePortal
              data-cy="positionTitle"
              id="title"
              noOptionsText={t("noOptionsMui")}
              options={allPositions.sort((a, b) => b.id - a.id)}
              value={value}
              sx={{
                "& .MuiInputBase-root": {
                  background: "#fff",
                  fontWeight: 700,
                  fontSize: 18,
                  color: "#000",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    border: "1px solid",
                    borderColor: "#8C8C8C",
                  },
                  "& fieldset": {
                    border: "1px solid",
                    borderColor: "#8C8C8C",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: "#8C8C8C",
                  },
                },
              }}
              onChange={(event, newValue) => {
                if (newValue.id !== -2) {
                  getBaseSkillTrees(newValue.id);
                }
                onChange(
                  newValue?.id !== -2
                    ? newValue
                    : { id: newValue.id, title: newValue.inputValue }
                );
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `${t("add")} ${inputValue}`,
                    id: -2,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => option.title}
              renderOption={(params, opt) => (
                <Typography
                  {...params}
                  key={opt.id}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: "19px",
                    paddingBottom: "19px",
                    backgroundColor: "auto",
                    gap: 13,
                    opacity: 1,
                    fontFamily: "Ubuntu",
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#8c8c8c",
                  }}
                >
                  {opt?.title}
                </Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    borderRadius: 0,
                    width: "100%",
                    height: "100%",
                    fontSize: 16,
                    fontWeight: 500,
                    outline: "none",
                    verticalAlign: "middle",
                    color: "#131212",
                    background: "#ffffff",
                  }}
                  placeholder={t("positionsManagement.positionName")}
                  value={value ? value.title : ""}
                />
              )}
            />
          )}
        />
        <S.ErrorText>
          {errors?.title?.title?.message || errors?.title?.message}
        </S.ErrorText>
      </div>
      <div>
        <Controller
          name="about"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <S.Label>
                {t("coursesManagement.description")}
              </S.Label>
              <S.ContainerInput borderColor={errors?.about}>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  onChange={(value) => {
                    setValue("about", value);
                    setHasMinCharacters(value.trim().length > 30);
                  }}
                  placeholder={t("coursesManagement.description")}
                  value={value}
                  formats={formats}
                />
              </S.ContainerInput>
            </div>
          )}
        />
        {!hasMinCharacters && (
          <S.ErrorText>{errors?.about?.message}</S.ErrorText>
        )}
      </div>
      <Controller
        name="languages"
        control={control}
        render={({ field: { value, onChange } }) => (
          <div>
            {value ? (
              <CheckboxMultiselect
                options={newOptions}
                label={t("languages")}
                onChange={onChange}
                value={value}
              />
            ) : (
              <CheckboxMultiselect
                options={newOptions}
                label={t("languages")}
                onChange={onChange}
                value={[]}
              />
            )}
          </div>
        )}
      />
      <Controller
        name="visibility"
        control={control}
        render={({ field: { onChange, value } }) => (
          <div style={{ marginTop: "5px", marginBottom: "2px" }}>
            <Select
              showSearch
              selected={value}
              onSelect={(newValue) => onChange(newValue)}
              placeholder={t("positionsManagement.recruitmentType")}
              label={t("positionsManagement.recruitmentType")}
              disabled={!isSysAdmin}
              error={errors?.visibility?.message}
              options={[
                {
                  id: 0,
                  name: t("performanceReport.internalRecruitment"),
                },
                {
                  id: 1,
                  name: t("performanceReport.externalRecruitment"),
                },
              ]}
            />
          </div>
        )}
      />
      <div>
        <S.Label>{t("positionsManagement.addCompetence")}</S.Label>
        <Autocomplete
          data-cy="positionCompetence"
          id="competence"
          ref={serviceRef}
          handleHomeEndKeys
          selectOnFocus
          clearOnBlur
          disabled={isGeneratingChallenges}
          noOptionsText={t("noOptionsMui")}
          onChange={(e, value) => {
            setModalData(value);
            if (value?.label?.length > 0) {
              setSelectedSkillTree(value);
              setShowSkillsModal(true);
            }
          }}
          open={searchCompetences?.length > 0}
          options={allCompetencesOptions.sort((a, b) => b.id - a.id)}
          loading={loadingCompetences}
          filterOptions={(options, params) => {
            let filtered = filter(options, params);

            let { inputValue } = params;
            let isExisting = options.some(
              (option) => inputValue === option.value
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                label: `${t("add")} ${inputValue}`,
                id: subtractedValue,
              });
            }
            return filtered;
          }}
          sx={{
            "& .MuiInputBase-root": {
              background: "#fff",
              fontWeight: 700,
              fontSize: 18,
              color: "#000",
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
              "& fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
              "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: "#8C8C8C",
              },
            },
          }}
          renderOption={(params, opt) => (
            <Typography
              {...params}
              sx={{
                p: 0,
                m: 0,
              }}
              style={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "19px",
                paddingBottom: "19px",
                backgroundColor: "auto",
                gap: 13,
                opacity: 1,
                fontFamily: "Ubuntu",
                fontWeight: 500,
                fontSize: 16,
                color: "#8c8c8c",
              }}
            >
              {opt?.label}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => setSearchCompetences(e.target.value)}
              value={searchCompetences}
              placeholder={t("positionsManagement.addCompetence")}
            />
          )}
        />
        <S.ErrorText>{errors?.competences?.message}</S.ErrorText>
      </div>

      <S.ContainerAccordions>
        {allTrees.length > 0 && (
          <p style={{ marginBottom: 20, fontSize: "1rem" }}>
            {t("positionsManagement.competenceLinked")}
          </p>
        )}
        {allTrees.map((comp, i) => (
          <Accordion
            disableGutters
            expanded={expanded === comp?.id}
            onChange={handleChange(comp?.id)}
          >
            <AccordionSummary
              aria-controls={`${comp?.id}d-content`}
              id={`${comp?.id}d-header`}
            >
              <S.ContainerAccordionSummary>
                <S.ContainerAccordionSummaryLeft>
                  <S.AccordionSummaryCircleImg color={getColor("UNLOCKED")}>
                    <S.AccordionSummaryImg src={TreeWhiteIcon} alt="TreeIcon" />
                  </S.AccordionSummaryCircleImg>
                  <S.ContainerAccordionSummaryLeftTexts>
                    <S.TitleAccordionSummary>
                      {comp?.value || comp?.label}
                    </S.TitleAccordionSummary>
                  </S.ContainerAccordionSummaryLeftTexts>
                </S.ContainerAccordionSummaryLeft>
                <Tooltip
                  title={t("positionsManagement.markCompetenceMandatory")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setAllTrees(
                        allTrees.map((item) =>
                          item.id === comp?.id
                            ? {
                              ...item,
                              skills: item?.skills?.every(
                                (sk) => sk.mandatory
                              )
                                ? item?.skills?.map((sk) => ({
                                  ...sk,
                                  mandatory: false,
                                }))
                                : item?.skills?.map((sk) => ({
                                  ...sk,
                                  mandatory: true,
                                })),
                            }
                            : item
                        )
                      );
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 198 },
                      left: { xs: 55, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    {comp?.skills?.every((sk) => sk.mandatory) ? (
                      <StarIcon />
                    ) : (
                      <StarOutlineIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t("positionsManagement.editCompetenceName")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalData(comp);
                      if (comp?.label?.length > 0) {
                        setSelectedSkillTree(comp);
                        setShowSkillTreeNameModal(true);
                      }
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 152 },
                      left: { xs: 101, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t("positionsManagement.addSkills")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalData(comp);
                      if (comp?.label?.length > 0) {
                        setSelectedSkillTree(comp);
                        setShowSkillsModal(true);
                      }
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 106 },
                      left: { xs: 147, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t("positionsManagement.deleteCompetence")}
                  arrow
                  placement="top"
                >
                  <IconButton
                    onClick={() => {
                      setAllTrees(
                        allTrees.filter((item) => item.id !== comp?.id)
                      );
                    }}
                    sx={{
                      position: "absolute",
                      right: { xs: "initial", md: 60 },
                      left: { xs: 193, md: "initial" },
                      top: { xs: 31, md: 16 },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <S.AccordionSummarySetaImg
                  up={expanded === comp?.id}
                  src={SetaGray}
                  alt="Seta"
                />
              </S.ContainerAccordionSummary>
            </AccordionSummary>
            {openSkillTrees && (
              <AccordionDetails sx={{ p: 0, m: 0 }}>
                <S.ContainerAccordionDetails>
                  <Controller
                    name="competences"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CardTree
                        skills={comp?.skills}
                        draggable
                        onRemove={(sk) => removeSkill(sk, comp)}
                        onMandatory={(sk) => mandatorySkill(sk, comp)}
                        onChange={onChange}
                      />
                    )}
                  />
                </S.ContainerAccordionDetails>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </S.ContainerAccordions>
      <S.CheckboxContainer data-cy="activatePosition">
        <Checkbox
          disabled={isGeneratingChallenges}
          visible={isHidden}
          onClick={() => setIsHidden(!isHidden)}
        />
        <S.CheckboxText>
          {t("coursesManagement.hide")}
          <Tooltip
            sx={{ p: 0.4, m: 0, ml: 0.4, mb: -1 }}
            title={t("coursesManagement.hideTooltip")}
            placement="top"
          >
            <InfoOutlined />
          </Tooltip>
        </S.CheckboxText>
      </S.CheckboxContainer>
      <S.ListButtonContainer>
        <Grid container gap={2}>
          <Grid item lg={2} xl={2} md={5} xs={12} sm={12}>
            <Button
              data-cy="savePosition"
              onClick={() => {
                setValue("published", true);
                handleSubmit(sendData)();
              }}
              disable={isLoading || isGeneratingChallenges}
            >
              {t("positionsManagement.save")}
            </Button>
          </Grid>
          {!isLoading && !isGeneratingChallenges && (
            <Grid item lg={2} xl={2} md={5} xs={12} sm={12}>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#fff0",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "#131212",
                  color: "#131212",
                }}
                onClick={() => {
                  setValue("published", false);
                  handleSubmit(sendData)();
                }}
              >
                {t("positionsManagement.saveAsDraft")}
              </Button>
            </Grid>
          )}
        </Grid>
      </S.ListButtonContainer>
      <Modal
        open={showSkillsModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowSkillsModal(false);
          setSearchCompetences("");
          setModalData(undefined);
          setSelectedSkillTree(null);
          const clearIndicator = serviceRef?.current?.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )?.[0];
          if (clearIndicator) {
            clearIndicator?.click();
          }
        }}
      >
        <SkillsModal
          onClose={() => {
            setShowSkillsModal(false);
            setSearchCompetences("");
            setModalData(undefined);
            setExpanded(selectedSkillTree.id);
            setSelectedSkillTree(null);
            const clearIndicator = serviceRef?.current?.getElementsByClassName(
              "MuiAutocomplete-clearIndicator"
            )?.[0];
            if (clearIndicator) {
              clearIndicator?.click();
            }
          }}
          onAdd={addSkills}
          data={modalData}
          skillTree={selectedSkillTree}
        />
      </Modal>
      <Modal
        open={showConfirmationModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowConfirmationModal(false);
          setModalData(undefined);
          setIsLoading(false);
        }}
      >
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
            setModalData(undefined);
          }}
          isCurrentUserSysAdmin={isSysAdmin}
          onAdd={saveFormation}
          data={selectedSkillsWithoutChallenges}
          formationPayload={formationPayloadToSave}
        />
      </Modal>
      <Modal
        open={showSkillTreeNameModal}
        sx={{
          backdropFilter: "blur(2px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setShowSkillTreeNameModal(false);
          setModalData(undefined);
        }}
      >
        <SkillTreeNameModal
          onClose={() => {
            setShowSkillTreeNameModal(false);
            setModalData(undefined);
          }}
          onSend={changeCompetenceName}
          data={modalData}
        />
      </Modal>
      <Dialog
        open={openModal}
        onClose={handleClosePrivate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("coursesManagement.cannotPublish")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("coursesManagement.contactSysAdmin")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrivate} autoFocus>
            {t("coursesManagement.makePrivate")}
          </Button>
        </DialogActions>
      </Dialog>
    </S.ContainerPlansForm>
  );
}

export default TrailsForm;
