import {Button} from "design-system-firedev";
import {FormEvent, ReactElement, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button as SHButton} from "../../components/Button";
import * as S from "./styles";

import PsychologyIcon from "@mui/icons-material/Psychology";
import {EvaluationFormTypeFile} from "./components/EvaluationFormTypeFile";
import {EvaluationFormTypeText} from "./components/EvaluationFormTypeText";
import {EvaluationChallengeLastPage} from "./components/LastPage";
import {ResultOfChallenge} from "./components/ResultOfChallenge";

import {ThumbDown, ThumbUp} from "@mui/icons-material";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import {useMultiStepForm} from "../../hooks/useMultistepForm";
import ChallengeService from "../../services/ChallengeService";
import EvaluationService from "../../services/EvaluationService";
import {enqueueSnackbar} from "notistack";
import {t} from "i18next";

export interface IFormData {
  rightAnswer?: boolean;
  feedback?: string;
  questionId: number;
}

interface IEvaluationChallenge {
  origin: string;
  objectiveEvaluation: string;
  appraiser: any;
  filter: any;
  feedback: string;
  challenge: {
    id: number;
    title: string;
  };
  user: {
    id: number;
    name: string;
  };
  trail: {
    name: string;
  };
  skill: {
    name: string;
  };
  questions:
    | [
    {
      questionId: number | string;
      content: string;
      textResponse: string;
      input: "FILE" | "TEXTAREA";
      uploadFile: string;
    }
  ]
    | any;
  status: string;
}

const defaultFormData: IFormData = {
  rightAnswer: undefined,
  feedback: "",
  questionId: undefined,
};

export interface IAGeneratedEvaluation {
  success: boolean;
  remainingUsages: number;
  errorInfo: string;
  questions: IFormData[];
  generalFeedback: string;
}

export default function EvaluationChallenge() {
  const navigate = useNavigate();
  const {skillChallengeId} = useParams();

  const [dataGetEvaluationRequest, setDataGetEvaluationRequest] =
    useState<IEvaluationChallenge>({} as IEvaluationChallenge);

  const [dataToMultiStepForm, setDataToMultiStepForm] = useState<
    ReactElement[]
  >([]);

  const [formDataByStep, setFormDataByStep] = useState<IFormData[]>([]);
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const {
    step,
    currentStepIndex,
    nextStep,
    backStep,
    isFirstStep,
    isLastStep,
    isPenultimate,
  } = useMultiStepForm(dataToMultiStepForm);
  const [wasEvaluated, setWasEvaluated] = useState(null);
  const [feedback, setFeedback] = useState<string>("");
  const [openIAUsageAlert, setOpenIAUsageAlert] = useState<boolean>(false);
  const [checkedIAAlertNotShow, setCheckedIAAlertNotShow] =
    useState<boolean>(false);
  const [userConsentIA, setUserConsentIA] = useState<boolean>(
    localStorage.getItem("userConsentIA") == "true"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [modalContentType, setModalContentType] = useState<"info" | "feedback">(
    "info"
  );
  const [serverResposeIA, setServerResponseIA] =
    useState<IAGeneratedEvaluation>(null);
  const [rightAnswerCount, setRightAnswerCount] = useState(0)
  const [gradeSummary, setGradeSummary] = useState('')

  async function fetchData() {
    const resultEvaluation = await EvaluationService.getChallengeByIdAndUserId(
      skillChallengeId
    );
    let rightSubjective = 0
    resultEvaluation?.questions.map(question => question?.rightAnswer && (rightSubjective++))
    let rightObjective = 0
    let totalObjective = 0
    if (resultEvaluation?.objectiveEvaluation) {
      const [right, total] = resultEvaluation?.objectiveEvaluation?.split("/")
      rightObjective = parseInt(right)
      totalObjective = parseInt(total)
    }
    setGradeSummary(`${rightObjective + rightAnswerCount + rightSubjective}/${totalObjective + resultEvaluation?.questions?.length}`)
    setDataGetEvaluationRequest(resultEvaluation);
    setWasEvaluated(
      resultEvaluation.status !== "WAITING_EVALUATION" ? true : false
    );

    // if (resultEvaluation.status !== "WAITING_EVALUATION") {
      setFormData({
        rightAnswer: resultEvaluation.questions[0].rightAnswer,
        feedback: resultEvaluation.questions[0].feedback,
        questionId: resultEvaluation.questions[0].questionId,
      });
      setFormDataByStep(
        resultEvaluation.questions.map((item) => {
          return {
            rightAnswer: item.rightAnswer,
            feedback: item.feedback,
            questionId: item.questionId,
          };
        })
      );
    // }
    //
    // if (resultEvaluation.status === "WAITING_EVALUATION") {
    //   setFormData({
    //     questionId: resultEvaluation.questions[0].questionId,
    //   });
    // }

    const resultAccordingTypeOfQuestion = resultEvaluation.questions
    .filter((item) => item.input === "TEXTAREA" || item.input === "FILE")
    .map((item, index) => {
      const elementReact =
        item.input === "TEXTAREA" ? (
          <EvaluationFormTypeText
            id={index + 1}
            content={item.content.replace(/&nbsp;/g, " ")}
            textResponse={item.textResponse}
          />
        ) : (
          <EvaluationFormTypeFile
            id={index + 1}
            content={item.content.replace(/&nbsp;/g, " ")}
            uploadFile={item.uploadFile}
          />
        );

      return elementReact;
    });

    setDataToMultiStepForm(
      resultAccordingTypeOfQuestion.concat(
        <ResultOfChallenge
          wasEvaluted={
            resultEvaluation.status !== "WAITING_EVALUATION" ? true : false
          }
          feedback={
            isPenultimate
              ? serverResposeIA?.generalFeedback
              : resultEvaluation.feedback !== ""
                ? resultEvaluation.feedback
                : feedback
          }
          setFeedback={setFeedback}
        />,
        <EvaluationChallengeLastPage/>
      )
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function loadCurrentQuestion() {
    if (formDataByStep[currentStepIndex]) {
      setFormData(formDataByStep[currentStepIndex]);
    } else {
      const questionId = await dataGetEvaluationRequest?.questions?.at(
        currentStepIndex
      )?.questionId;
      setFormData({...defaultFormData, questionId});
    }
  }

  useEffect(() => {
    loadCurrentQuestion();
  }, [currentStepIndex]);

  function onNextStepOfQuestionAndSubmit(event: FormEvent) {
    event.preventDefault();

    if (!isPenultimate && formDataByStep[currentStepIndex]) {
      setFormDataByStep((oldArray) => {
        oldArray[currentStepIndex] = {...formData};
        return oldArray;
      });
      nextStep();
    }

    if (
      !isPenultimate &&
      !formDataByStep[currentStepIndex] &&
      formData.rightAnswer !== undefined
    ) {
      setFormDataByStep((oldArray) => oldArray.concat(formData));
      nextStep();
    }

    if (
      !isPenultimate &&
      !formDataByStep[currentStepIndex] &&
      formData.rightAnswer === undefined
    ) {
      enqueueSnackbar(t("evaluate.tickCorrectOrIncorrect"), {
        variant: "error",
      });
    }

    if (isPenultimate && wasEvaluated) {
      nextStep();
    }

    if (isPenultimate && !wasEvaluated) {
      const postDTOEvaluationChallengeWithouQuestions = {
        challengeID: dataGetEvaluationRequest?.challenge?.id,
        feedback: feedback,
        userId: dataGetEvaluationRequest?.user?.id,
      };
      ChallengeService.postEvaluationChallenge(
        postDTOEvaluationChallengeWithouQuestions,
        formDataByStep
      );
      nextStep();
    }
  }

  function updateFields(fields: Partial<IFormData>) {
    setFormData((prevData) => {
      return {...prevData, ...fields};
    });
    fields?.rightAnswer ?
      setRightAnswerCount(rightAnswerCount + 1) :
      rightAnswerCount > 0 &&
        setRightAnswerCount(rightAnswerCount - 1)
  }

  const handleClickIAUsage = () => {
    if (!userConsentIA) {
      setOpenIAUsageAlert(true);
      return;
    }

    setOpenIAUsageAlert(true);
    handleConfirmIAUsageAlert();
  };

  const handleConfirmIAUsageAlert = async () => {
    if (checkedIAAlertNotShow) {
      localStorage.setItem("userConsentIA", "true");
      setUserConsentIA(true);
    }
    setLoading(true);

    const serverResposeData =
      await EvaluationService.getAIResponsesForEvaluation(
        dataGetEvaluationRequest?.challenge?.id,
        dataGetEvaluationRequest?.user?.id
      );

    setModalContentType("feedback");
    setServerResponseIA(serverResposeData);
    if (serverResposeData.success) {
      setFormData(
        serverResposeData.questions.find(
          (question) => question.questionId === formData.questionId
        )
      );
      setFormDataByStep(
        dataGetEvaluationRequest.questions.map((originalQuestion) => {
          return serverResposeData.questions.find(
            (question) => question.questionId === originalQuestion.questionId
          );
        })
      );
      setFeedback(serverResposeData.generalFeedback);
      setDataToMultiStepForm(
        dataToMultiStepForm.map((component) => {
          if (component.type.toString().includes("ResultOfChallenge")) {
            return (
              <ResultOfChallenge
                wasEvaluted={
                  dataGetEvaluationRequest.status !== "WAITING_EVALUATION"
                    ? true
                    : false
                }
                feedback={
                  dataGetEvaluationRequest.status !== "WAITING_EVALUATION"
                    ? dataGetEvaluationRequest.feedback
                    : serverResposeData.generalFeedback
                }
                setFeedback={setFeedback}
              />
            );
          }
          return component;
        })
      );
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    if (!loading) {
      setOpenIAUsageAlert(false);
      setTimeout(() => {
        setServerResponseIA(null);
        setModalContentType("info");
      }, 500);
    }
  };

  const handleGradeSummary = () => {
    let rightObjective = 0
    let totalObjective = 0
    if (dataGetEvaluationRequest?.objectiveEvaluation) {
      const [right, total] = dataGetEvaluationRequest?.objectiveEvaluation?.split("/")
      rightObjective = parseInt(right)
      totalObjective = parseInt(total)
    }
      setGradeSummary(`${rightObjective + rightAnswerCount}/${totalObjective + dataGetEvaluationRequest?.questions?.length}`)
  }

  return (
    <S.Container>
      <Button
        onClick={() => navigate("/management/evaluation")}
        background="transparent"
        borderColor="transparent"
        height={30}
        width={180}
        color="#FF026F"
        colorIcon="#FF026F"
        fontVariant="body1"
        pathIcon="arrow"
        size="custom"
        text={t("evaluate.backToEvaluation")}
      />
      <S.Title>{dataGetEvaluationRequest?.challenge?.title}</S.Title>
      <S.Subtitle>
        {dataGetEvaluationRequest?.user?.name} <br/>
        {dataGetEvaluationRequest?.origin} |{" "}
        {dataGetEvaluationRequest?.skill?.name} <br/>
        {t("evaluate.objectiveEvaluation")}
        {dataGetEvaluationRequest?.objectiveEvaluation} <br/>
        {t("evaluate.finalEvaluation")}
        {gradeSummary}
        {!wasEvaluated && (
          <div>
            <Grid mt={3} container>
              <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                <SHButton
                  style={{
                    background: "#2061ac",
                    color: "#fff",
                  }}
                  onClick={handleClickIAUsage}
                  disable={wasEvaluated}
                >
                  {t("evaluate.automaticCorrection")}
                </SHButton>
              </Grid>
            </Grid>
          </div>
        )}
      </S.Subtitle>
      <S.ContainerEvaluationChallenge>
        <form onSubmit={onNextStepOfQuestionAndSubmit}>
          {!isLastStep && !isPenultimate && (
            <S.ContainerButtonsRightOrWrong rightAnswer={formData?.rightAnswer}>
              <Button
                text={t("evaluate.correct")}
                type="button"
                background="transparent"
                borderColor={
                  formData?.rightAnswer === true ? "#00A676" : "#C4C4C4"
                }
                color={formData?.rightAnswer === true ? "#00A676" : "#C4C4C4"}
                colorIcon={
                  formData?.rightAnswer === true ? "#00A676" : "#C4C4C4"
                }
                fontVariant="body1"
                pathIcon="input_checkbox_checked"
                size="custom"
                sizeIcon={13}
                height={22}
                width={130}
                onClick={() => updateFields({rightAnswer: true})}
                disabled={wasEvaluated ? true : false}
              />
              <Button
                text={t("evaluate.incorrect")}
                type="button"
                background="transparent"
                borderColor={
                  formData?.rightAnswer === false ? "#F5222D" : "#C4C4C4"
                }
                color={formData?.rightAnswer === false ? "#F5222D" : "#C4C4C4"}
                fontVariant="body1"
                height={22}
                pathIcon="close"
                size="custom"
                sizeIcon={13}
                width={130}
                onClick={() => updateFields({rightAnswer: false})}
                disabled={wasEvaluated ? true : false}
              />
            </S.ContainerButtonsRightOrWrong>
          )}
          {step}
          {!isLastStep && (
            <div>
              {!isPenultimate && !wasEvaluated && (
                <div>
                  <S.TextArea
                    placeholder={t("evaluate.comment")}
                    onChange={(event) =>
                      updateFields({feedback: event.target.value})
                    }
                    value={formData.feedback === null ? "" : formData.feedback}
                    rows={5}
                  />
                </div>
              )}
              {!isPenultimate && wasEvaluated && (
                <S.ContainerTextAreaEvaluated>
                  <S.Comment>{t("evaluate.evaluateFeedback")}</S.Comment>
                  <S.FeedbackEvaluated>
                    {formData?.feedback}
                  </S.FeedbackEvaluated>
                  <S.UserEvaluted>
                    {t("evaluate.evaluator")}:{" "}
                    {dataGetEvaluationRequest.appraiser?.name ||
                      t("evaluate.noInfo")}
                  </S.UserEvaluted>
                </S.ContainerTextAreaEvaluated>
              )}
              <S.ContainerButtonsPrevandNext>
                {!isFirstStep && (
                  <Button
                    borderColor="#131212"
                    height={52}
                    rounded={3}
                    size="custom"
                    text={t("evaluate.back")}
                    typeOfButton="secondary"
                    width={182}
                    type="button"
                    onClick={backStep}
                  />
                )}
                <Button
                  height={52}
                  size="custom"
                  text={
                    isPenultimate ? t("evaluate.send") : t("evaluate.advance")
                  }
                  disabled={isPenultimate && wasEvaluated}
                  type="submit"
                  typeOfButton="primary"
                  width={182}
                  onClick={handleGradeSummary}
                />
              </S.ContainerButtonsPrevandNext>
            </div>
          )}
        </form>
      </S.ContainerEvaluationChallenge>
      <Dialog onClose={handleCloseModal} open={openIAUsageAlert} fullWidth>
        <DialogTitle display="flex" alignItems="center" justifyContent="center">
          <PsychologyIcon fontSize="large"/>
          Utilização de IA (Inteligência Artificial)
        </DialogTitle>
        <hr/>
        <DialogContent>
          {(loading && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={80} color="info"/>
            </Box>
          )) || (
            <DialogContentText>
              {(modalContentType === "info" && (
                <div>
                  <p>
                    <b>{t("evaluate.disclaimer")}:</b>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("evaluate.weUseIAToGenerateAutomaticAnswers"),
                      }}
                    ></div>
                  </p>
                  <br/>
                  <p>
                    <b>{t("evaluate.important")}:</b>
                    <u> {t("evaluate.evaluatorJustCanEvaluateTreeTimes")}</u>
                  </p>
                  <br/>
                  <p>
                    {t("evaluate.doYouKnowYouWantUseIAToCorrectAChallenge")}
                  </p>
                  <Alert style={{marginTop: 20}} color="warning">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("evaluate.onSelectAotomaticCorrection"),
                      }}
                    ></div>
                  </Alert>
                  <FormControlLabel
                    style={{marginTop: "20px", marginBottom: "20px"}}
                    checked={checkedIAAlertNotShow}
                    onChange={(e, checked) => setCheckedIAAlertNotShow(checked)}
                    control={<Checkbox/>}
                    label={t("evaluate.dontAskAgain")}
                  />
                </div>
              )) || (
                <div>
                  <p style={{display: "flex", alignItems: "center"}}>
                    <b style={{marginRight: 10}}>{t("evaluate.result")}:</b>{" "}
                    {(serverResposeIA?.success && (
                      <ThumbUp color="success"/>
                    )) || <ThumbDown color="error"/>}
                  </p>
                  {serverResposeIA?.remainingUsages != null && (
                    <div>
                      <br/>
                      <p>
                        <b>{t("evaluate.remainingUsage")}:</b>{" "}
                        {serverResposeIA?.remainingUsages}
                      </p>
                    </div>
                  )}

                  <br/>
                  <Alert color={serverResposeIA?.success ? "success" : "error"}>
                    <b>
                      {serverResposeIA?.success
                        ? `${t("evaluate.success")}: `
                        : `${t("evaluate.errorGeneratingCorrection")}: `}
                    </b>
                    {serverResposeIA?.success
                      ? t("evaluate.correctBeforeSend")
                      : serverResposeIA?.errorInfo}
                  </Alert>
                </div>
              )}
            </DialogContentText>
          )}
        </DialogContent>
        <hr/>
        <DialogActions>
          <Grid
            columnGap={2}
            mt={3}
            mb={1}
            container
            justifyContent="flex-end"
            alignItems="end"
          >
            <Grid item>
              <Button
                borderColor="#131212"
                height={52}
                rounded={3}
                size="custom"
                text={
                  modalContentType === "info" ? t("evaluate.cancel") : "Fechar"
                }
                typeOfButton="secondary"
                width={182}
                type="button"
                onClick={handleCloseModal}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              {modalContentType !== "feedback" && (
                <Button
                  height={52}
                  size="custom"
                  text={t("evaluate.confirm")}
                  type="submit"
                  typeOfButton="primary"
                  width={182}
                  onClick={handleConfirmIAUsageAlert}
                  disabled={loading}
                />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </S.Container>
  );
}
