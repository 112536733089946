import { Button } from "@mui/material";
import styled from "styled-components";
export const UpdatedAt = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  span:nth-of-type(2) {
    color: #8c8c8c;
  }
`;

export const ButtonAdd = styled(Button)`
  padding-right: 50px;
  background: linear-gradient(90deg, #ff6423 0%, #ff026f 100%);
  color: white !important;
  font-family: "Ubuntu" !important;
`;
export const FontHeaderTable = styled.div`
  color: black !important;
  font-family: "Ubuntu" !important;
  font-weight: 700;
  font-size: 18px;
`;
export const containerForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyIcon = styled.img`
  width: 25px;
  height: 25px;
  left: 0px;
  top: 29.48px;
  object-fit: cover;
  border-radius: 50%;
`;

export const ContainerFullPage = styled.div`
  margin: 48px 4%;
  height: 100%;
`;

export const textArea = styled.textarea`
  margin-top: 20px;
  border-radius: 10px;
  border: none;
  resize: none;
  outline: none;
  height: 200px;
  width: 83%;
  grid-area: description;
  border: 1px solid #8c8c8c;
  &:focus {
    border: solid #ff026f 2px;
  }
`;
