import styled from "styled-components";

export const AvatarWrapper = styled.div`
  padding: 2px 20px 2px 20px;
  text-align: center;
  margin-top: 2.3rem;
  width: 400px;
`;

export const removeImage = styled.p`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  margin-left: 3px;
  color: red;
  font-size: 14px;
  cursor: pointer;
`;

export const AvatarImageWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 150px;
  height: 150px;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const AvatarPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarFileInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const LegendDiv = styled.div`
  color: #ff026f;
  font-size: 16px;
  font-weight: 500;
  margin-top: 22px;
  cursor: pointer;
`;

export const RecomendedSizeDiv = styled.div`
  color: #696969;
  font-size: 14px;
  margin-top: 10px;
  line-height: 20px;
`;