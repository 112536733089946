import api from "./api";

export async function getPagSeguroPublicKey() {
  const response =  await api.get("/v2/pagseguro/public-token")
  return response.data;
}

export async function payment(data: any) {
  const response =  await api.post("/v2/payment/process-payment", data)
  return response.data;
}
